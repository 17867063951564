import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Cancel from '@material-ui/icons/Cancel';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import projectStatus from '../../lib/ProjectStatus';
import { selectStatus, selectOffice, selectUser, clearFilter } from '../../actions/Projects';
import AutocompleteSelect from '../AutocompleteSelect';
import './ProjectFilter.css';

const style = () => ({
    formControl: {},
});

const initialState = {
    selectedStatus: 0,
    selectedOffice: 0,
    selectedUser: 0,
};

export class ProjectFilter extends Component {
    constructor(props) {
        super(props);

        let initialStatusValue = 0;
        if (props.selectedStatus) {
            initialStatusValue = props.selectedStatus;
        }

        this.state = {
            selectedStatus: props.selectedStatus || initialStatusValue,
            selectedOffice: props.selectedOffice || props.selectedOffice,
            selectedUser: props.selectedUser || props.defaultUserId,
        };

        this.onStatusChange = this.onStatusChange.bind(this);
        this.onOfficeChange = this.onOfficeChange.bind(this);
        this.onUserChange = this.onUserChange.bind(this);
    }

    componentWillMount() {
        this.props.onChange(this.state);
    }

    onStatusChange(e) {
        if (e.target.value === projectStatus.Deleted) {
            this.props.history.push('/projects/deleted');
        } else {
            this.props.dispatch(selectStatus(e.target.value));
            this.setState({ selectedStatus: e.target.value }, () =>
                this.props.onChange(this.state));
        }
    }

    onOfficeChange(office) {
        if (office === null) {
            this.props.dispatch(selectOffice(0));
            this.setState({ selectedOffice: { title: 'All', value: 0 } }, () =>
                this.props.onChange(this.state));
            return;
        }
        this.props.dispatch(selectOffice(office.value));
        this.setState({ selectedOffice: office }, () =>
            this.props.onChange(this.state));
    }

    onUserChange(user) {
        this.props.dispatch(selectUser(user));
        this.setState({ selectedUser: user }, () =>
            this.props.onChange(this.state));
    }

    getSelectStatusItems = () => (Object.entries(projectStatus).map(status => ({
        value: status[0],
        label: status[1],
    })));

    getSelectOfficeItems = () => (this.props.officeOptions.map(office => ({
        value: office[0],
        label: office[1],
    })));

    getSelectUserItems = () => {
        const items = [{ value: 0, label: 'All' }];
        const users = this.props.userOptions.map(user => ({
            value: user[0],
            label: user[1],
        }));
        return items.concat(users);
    };

    getOfficeSelectItems = () => this.getSelectOfficeItems().map(i => (
        { title: i.label, value: i.value }
    ));

    clearFilter = () => {
        this.props.dispatch(clearFilter());
        this.setState(initialState, () => this.props.onChange(this.state));
    };

    filterApplied = () =>
        this.state.selectedStatus !== initialState.selectedStatus ||
        this.state.selectedOffice !== initialState.selectedOffice ||
        this.state.selectedUser !== initialState.selectedUser;

    statusSelectItems = this.getSelectStatusItems().map(i => (
        <MenuItem key={i.value} value={i.value}>{i.label}</MenuItem>
    ));

    render() {
        const { classes } = this.props;
        const {
            selectedStatus,
            selectedUser,
        } = this.props;
        let projectStatusValue = 0;
        if (selectedStatus) {
            projectStatusValue = selectedStatus;
        }
        return (
            <Grid container spacing={3}>
                <Grid item xs>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <FormControl
                                className={classes.formControl}
                                fullWidth
                            >
                                <InputLabel shrink htmlFor="projectStatus">
                                    Status
                                </InputLabel>
                                <Select
                                    value={projectStatusValue}
                                    onChange={this.onStatusChange}
                                    displayEmpty
                                    name="projectStatus"
                                    inputProps={{
                                        name: 'projectStatus',
                                        id: 'projectStatus',
                                    }}
                                >
                                    <MenuItem value={0}>
                                        <em>All</em>
                                    </MenuItem>
                                    {this.statusSelectItems}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl
                                className={classes.formControl}
                                fullWidth
                            >
                                <Autocomplete
                                    name="office"
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    options={this.getOfficeSelectItems()}
                                    onChange={(event, newValue) => {
                                        this.onOfficeChange(newValue);
                                    }}
                                    getOptionLabel={option => option.title}
                                    renderInput={params => <TextField {...params} label="Office" variant="outlined" />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl
                                className={classes.formControl}
                                style={{ zindex: 1000 }}
                                fullWidth
                            >
                                <InputLabel shrink htmlFor="user">User</InputLabel>
                                <Input
                                    id="user"
                                    value={selectedUser}
                                    onChange={this.onUserChange}
                                    inputComponent={AutocompleteSelect}
                                    inputProps={{
                                        name: 'user',
                                        instanceId: 'user',
                                        simpleValue: true,
                                        options: this.getSelectUserItems(),
                                        disableItemRemoval: true,
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    {this.filterApplied() && (
                        <IconButton
                            tooltip="Clear Filters"
                            onClick={this.clearFilter}
                        >
                            <Cancel />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        );
    }
}

ProjectFilter.propTypes = {
    officeOptions: PropTypes.arrayOf(PropTypes.shape),
    userOptions: PropTypes.arrayOf(PropTypes.shape),
    onChange: PropTypes.func.isRequired,
    defaultUserId: PropTypes.number.isRequired,
    selectedStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedOffice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.shape({
        formControl: PropTypes.string.isRequired,
    }).isRequired,
};

ProjectFilter.defaultProps = {
    officeOptions: [],
    userOptions: [],
    selectedStatus: '',
    selectedOffice: { title: 'All', value: 0 },
    selectedUser: 0,
};
function mapStateToProps(state) {
    const { projects } = state;
    const { selectedStatus, selectedOffice, selectedUser } = projects;

    return {
        selectedStatus,
        selectedOffice,
        selectedUser,
    };
}

export default withRouter(connect(mapStateToProps)(withStyles(style)(ProjectFilter)));

