import React from 'react';
import { Icon, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        imageIcon: {
            display: 'flex',
            height: 'inherit',
            width: 'inherit',
        },
        iconRoot: {
            fontSize: undefined,
            width: '30px',
            height: '17px',
            cursor: 'pointer',
        },
    })
);

const InfoIcon = () => {
    const classes = useStyles();

    return (
        <Icon classes={{ root: classes.iconRoot }}>
            <img src='/assets/InfoIcon.svg' alt='information' />
        </Icon>
    );
};

export default InfoIcon;
