import { BoxProps } from '@material-ui/core';

// Use this type to exclude members which interfere with your child's props
// For example FilterSelector has it's own onChange method so it is omitted
export type PartialBoxProps = Omit<BoxProps, 'onChange'>;

const useBox = <TChildProps extends PartialBoxProps>(
  childProps: TChildProps
) => {
  return {
    ...childProps,
    onChange: undefined,
    keyValueMap: undefined,
    selectedKey: undefined,
  } as BoxProps;
};

export default useBox;