import { SET_NO_MATCH_ROUTE } from '../actions/NoMatchActions';

function noMatch(
    state = {
        route: '',
    },
    action,
) {
    switch (action.type) {
    case SET_NO_MATCH_ROUTE:
        return Object.assign({}, state, {
            route: action.route,
        });
    default:
        return state;
    }
}

export default noMatch;
