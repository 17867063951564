import { CALL_API } from '../middleware/api';

export const GET_PROJECT_ESTIMATE_ITEMS_REQUEST = 'GET_PROJECT_ESTIMATE_ITEMS_REQUEST';
export const GET_PROJECT_ESTIMATE_ITEMS_SUCCESS = 'GET_PROJECT_ESTIMATE_ITEMS_SUCCESS';
export const GET_PROJECT_ESTIMATE_ITEMS_FAILURE = 'GET_PROJECT_ESTIMATE_ITEMS_FAILURE';

export const getProjectEstimateItems = (projectId, estimateId, page = 1, getAllPages = false) => ({
    [CALL_API]: {
        types: [GET_PROJECT_ESTIMATE_ITEMS_REQUEST, GET_PROJECT_ESTIMATE_ITEMS_SUCCESS, GET_PROJECT_ESTIMATE_ITEMS_FAILURE],
        endpoint: `projects/${projectId}/estimates/${estimateId}/estimateitems`,
        getAllPages,
        params: {
            page,
        },
    },
});
