import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormGroup from '@material-ui/core/FormGroup';
import { isEqual } from 'lodash';
import DownloadProjectCsvIconButton from '@roofsnap/DownloadProjectCsvIconButton.tsx';
import Measurements from './index';
import OverrideMeasurementsSwitch from '../OverrideMeasurementsSwitch';
import {
    getProjectDrawingById,
    updateProjectDrawing,
    createProjectDrawing,
} from '../../actions/ProjectDrawingActions';
import PageToolbarActions, { ToolbarSaveButton } from '../NavigationComponents/PageToolbarActions';

function calculateWastePercentage(actualSquares = 0, totalSquares = 0) {
    return ((totalSquares - actualSquares) * 100) / actualSquares;
}

function calculateTotalSquares(actualSquares = 0, wastePercentage = 0) {
    return actualSquares + (actualSquares * (wastePercentage / 100));
}

function buildCreateProjectDrawingRequest() {
    return {
        faces: [],
        edges: [],
        vertices: [],
        pins: [],
        measurements: {
            actualSquares: 0,
            wastePercentage: 10, // by default wastePercentage needs to be set to 10
            totalSquares: 0,
            lowSlope: 0,
            twoStory: 0,
            secondLayer: 0,
            eaves: 0,
            rakes: 0,
            ridges: 0,
            hips: 0,
            valleys: 0,
            step: 0,
            wall: 0,
            pitchChange: 0,
            iceWaterShield: 0,
            ridgeVent: 0,
            rakeEdge: 0,
            eaveEdge: 0,
            stepFlashing: 0,
            apronFlashing: 0,
            gutters: 0,
            gutterToppers: 0,
            downSpouts: 0,
            pitch1In12: 0,
            pitch2In12: 0,
            pitch3In12: 0,
            pitch4In12: 0,
            pitch5In12: 0,
            pitch6In12: 0,
            pitch7In12: 0,
            pitch8In12: 0,
            pitch9In12: 0,
            pitch10In12: 0,
            pitch11In12: 0,
            pitch12In12: 0,
            pitch0In12: 0,
            pitch13In12: 0,
            pitch14In12: 0,
            pitch15In12: 0,
            pitch16In12: 0,
            pitch17In12: 0,
            pitch18In12: 0,
            pitch19In12: 0,
            pitch20In12: 0,
            pitch21In12: 0,
            pitch22In12: 0,
            pitch23In12: 0,
            pitch24In12: 0,
            starterOnEavesOnly: false,
        },
    };
}

const style = {
    root: {
        padding: 24,
    },
};

class MeasurementsPage extends Component {
    /* eslint-disable react/forbid-prop-types */
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        match: PropTypes.object.isRequired,
        drawing: PropTypes.shape({
            version: PropTypes.string,
        }),
        measurements: PropTypes.shape({
            actualSquares: PropTypes.number.isRequired,
            wastePercentage: PropTypes.number.isRequired,
            totalSquares: PropTypes.number.isRequired,
            lowSlope: PropTypes.number.isRequired,
            twoStory: PropTypes.number.isRequired,
            secondLayer: PropTypes.number.isRequired,
            eaves: PropTypes.number.isRequired,
            rakes: PropTypes.number.isRequired,
            ridges: PropTypes.number.isRequired,
            hips: PropTypes.number.isRequired,
            valleys: PropTypes.number.isRequired,
            step: PropTypes.number.isRequired,
            wall: PropTypes.number.isRequired,
            pitchChange: PropTypes.number.isRequired,
            iceWaterShield: PropTypes.number.isRequired,
            ridgeVent: PropTypes.number.isRequired,
            rakeEdge: PropTypes.number.isRequired,
            eaveEdge: PropTypes.number.isRequired,
            stepFlashing: PropTypes.number.isRequired,
            apronFlashing: PropTypes.number.isRequired,
            gutters: PropTypes.number.isRequired,
            gutterToppers: PropTypes.number.isRequired,
            downSpouts: PropTypes.number.isRequired,
            pitch0In12: PropTypes.number.isRequired,
            pitch1In12: PropTypes.number.isRequired,
            pitch2In12: PropTypes.number.isRequired,
            pitch3In12: PropTypes.number.isRequired,
            pitch4In12: PropTypes.number.isRequired,
            pitch5In12: PropTypes.number.isRequired,
            pitch6In12: PropTypes.number.isRequired,
            pitch7In12: PropTypes.number.isRequired,
            pitch8In12: PropTypes.number.isRequired,
            pitch9In12: PropTypes.number.isRequired,
            pitch10In12: PropTypes.number.isRequired,
            pitch11In12: PropTypes.number.isRequired,
            pitch12In12: PropTypes.number.isRequired,
            pitch13In12: PropTypes.number.isRequired,
            pitch14In12: PropTypes.number.isRequired,
            pitch15In12: PropTypes.number.isRequired,
            pitch16In12: PropTypes.number.isRequired,
            pitch17In12: PropTypes.number.isRequired,
            pitch18In12: PropTypes.number.isRequired,
            pitch19In12: PropTypes.number.isRequired,
            pitch20In12: PropTypes.number.isRequired,
            pitch21In12: PropTypes.number.isRequired,
            pitch22In12: PropTypes.number.isRequired,
            pitch23In12: PropTypes.number.isRequired,
            pitch24In12: PropTypes.number.isRequired,
            overrideDrawingMeasurements: PropTypes.bool.isRequired,
        }),
    };

    static defaultProps = {
        drawing: {
            version: '',
        },
        measurements: {
            actualSquares: 0,
            wastePercentage: 10, // by default wastePercentage needs to be set to 10
            totalSquares: 0,
            lowSlope: 0,
            twoStory: 0,
            secondLayer: 0,
            eaves: 0,
            rakes: 0,
            ridges: 0,
            hips: 0,
            valleys: 0,
            step: 0,
            wall: 0,
            pitchChange: 0,
            iceWaterShield: 0,
            ridgeVent: 0,
            rakeEdge: 0,
            eaveEdge: 0,
            stepFlashing: 0,
            apronFlashing: 0,
            gutters: 0,
            gutterToppers: 0,
            downSpouts: 0,
            pitch1In12: 0,
            pitch2In12: 0,
            pitch3In12: 0,
            pitch4In12: 0,
            pitch5In12: 0,
            pitch6In12: 0,
            pitch7In12: 0,
            pitch8In12: 0,
            pitch9In12: 0,
            pitch10In12: 0,
            pitch11In12: 0,
            pitch12In12: 0,
            pitch0In12: 0,
            pitch13In12: 0,
            pitch14In12: 0,
            pitch15In12: 0,
            pitch16In12: 0,
            pitch17In12: 0,
            pitch18In12: 0,
            pitch19In12: 0,
            pitch20In12: 0,
            pitch21In12: 0,
            pitch22In12: 0,
            pitch23In12: 0,
            pitch24In12: 0,
            overrideDrawingMeasurements: false,
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            measurements: props.measurements,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props
            .dispatch(getProjectDrawingById(this.props.match.params.id))
            .then((result) => {
                // if we fail, try to create a new project drawing request
                if (result && result.error) {
                    // build out the request
                    const projectDrawing = buildCreateProjectDrawingRequest();

                    // create the project drawing
                    this.props
                        .dispatch(createProjectDrawing(
                            this.props.match.params.id,
                            projectDrawing,
                        ))
                        .then(() => {
                            // once we create the drawing, try to get it again
                            this.props
                                .dispatch(getProjectDrawingById(this.props.match.params.id));
                        });
                }
            });
    }

    componentWillReceiveProps(nextProps) {
        if (isEqual(nextProps.measurements, this.props.measurements)) return;
        this.setState({ measurements: nextProps.measurements });
    }

    changeOverrideDrawingMeasurements = () => {
        const projectDrawingToUpdate = Object.assign({}, this.props.drawing);
        const updateProjectDrawingMeasurements = Object.assign(
            {},
            this.props.measurements,
        );
        updateProjectDrawingMeasurements.overrideDrawingMeasurements = !updateProjectDrawingMeasurements.overrideDrawingMeasurements;

        projectDrawingToUpdate.measurements = updateProjectDrawingMeasurements;

        this.saveProjectDrawing(projectDrawingToUpdate);
    };

    handleSaveButtonClick = () => {
        const projectDrawingToUpdate = Object.assign({}, this.props.drawing);
        const updateProjectDrawingMeasurements = Object.assign(
            {},
            this.state.measurements,
        );

        projectDrawingToUpdate.measurements = updateProjectDrawingMeasurements;

        this.saveProjectDrawing(projectDrawingToUpdate);
    };

    saveProjectDrawing = (projectDrawing) => {
        this.props
            .dispatch(updateProjectDrawing(
                this.props.match.params.id,
                this.props.drawing.version,
                projectDrawing,
            ))
            .then(() => {
                this.props
                    .dispatch(getProjectDrawingById(this.props.match.params.id));
            });
    };

    handleChange = (event) => {
        const newValue = event.target.value
            ? parseFloat(event.target.value)
            : 0;

        const propertyToUpdate = event.target.id;

        const updatedMeasurements = Object.assign({}, this.state.measurements);
        updatedMeasurements[propertyToUpdate] = newValue;

        if (
            propertyToUpdate === 'actualSquares' ||
            propertyToUpdate === 'wastePercentage'
        ) {
            updatedMeasurements.totalSquares = calculateTotalSquares(
                updatedMeasurements.actualSquares,
                updatedMeasurements.wastePercentage,
            );
        } else if (propertyToUpdate === 'totalSquares') {
            updatedMeasurements.wastePercentage = calculateWastePercentage(
                updatedMeasurements.actualSquares,
                updatedMeasurements.totalSquares,
            );
        }

        this.setState({ measurements: updatedMeasurements });
    };


    render() {
        const overrideDrawingMeasurements = this.props.measurements.overrideDrawingMeasurements || false;
        return (
            <div style={style.root}>
                <PageToolbarActions triggers={[this.props.measurements.overrideDrawingMeasurements]}>
                    <FormGroup row>
                        <OverrideMeasurementsSwitch
                            checked={overrideDrawingMeasurements}
                            handleConfirm={this.changeOverrideDrawingMeasurements}
                        />
                    </FormGroup>
                    <DownloadProjectCsvIconButton projectId={this.props.match.params.id} projectName={this.props.projectName} />
                    <ToolbarSaveButton onClick={this.handleSaveButtonClick} tooltip="Save Measurements" />
                </PageToolbarActions>
                <Measurements
                    measurements={this.state.measurements}
                    handleChange={this.handleChange}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { projectDrawing, projectHome } = state;
    const { projectDrawing: drawing } = projectDrawing;
    const { measurements } = projectDrawing.projectDrawing;
    const { currentProject: project } = projectHome;
    const { projectName } = project;

    return {
        drawing,
        measurements,
        projectName,
    };
};

export default connect(mapStateToProps)(MeasurementsPage);
