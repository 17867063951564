import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import grey from '@material-ui/core/colors/grey';
import List from '@material-ui/core/List';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Typography from '@material-ui/core/Typography';
import NotificationListItem from './NotificationListItem';
import PriorityLevelIcon from './PriorityLevelIcon';
import {
    drawerProps,
    drawerHeaderHeight,
    notificationsDrawerWidth,
} from './';

const styles = theme => ({
    drawer: {
        paddingTop: theme.spacing(1) + drawerHeaderHeight,
    },
    notificationsDrawer: {
        zIndex: 2,
        width: notificationsDrawerWidth,
    },
    notificationDetailsDrawer: {
        zIndex: 3,
    },
    drawerTitle: {
        fontSize: 18,
    },
    drawerHeader: {
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
    },
    drawerOpen: {
        width: notificationsDrawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: (theme.spacing(7)) + 1,
        [theme.breakpoints.up('sm')]: {
            width: (theme.spacing(9)) + 1,
        },
    },
    drawerDivider: {
        backgroundColor: grey[300],
        marginBottom: theme.spacing(2),
    },
    detailsRoot: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
    },
    detailsHeader: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    detailsDescription: {
        // fontSize: 13,
    },
    detailsTitle: {
        fontSize: 18,
    },
    acknowledgeButton: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        textTransform: 'initial',
    },
    detailsIcon: {
        marginRight: theme.spacing(2),
    },
    additionalNotesTitle: {
        marginTop: theme.spacing(2),
        fontSize: 12,
        color: grey[600],
    },
    additionalNotes: {
        marginTop: theme.spacing(1),
    },
});

const SketchOrderNotificationsDrawer = (props) => {
    const {
        notifications,
        notification,
        detailsDrawerOpen,
        notificationsDrawerOpen,
        classes,
        onNotificationListItemClick,
        onCloseNotificationsButtonClick,
        onAcknowledgeButtonClick,
        onBackButtonClick,
    } = props;
    const detailsDrawerProps = {
        ...drawerProps,
        open: detailsDrawerOpen,
        classes: {
            paper: classNames(
                classes.drawer,
                classes.notificationsDrawer,
                classes.notificationDetailsDrawer,
            ),
        },
    };
    const notificationsDrawerProps = {
        ...drawerProps,
        open: notificationsDrawerOpen,
        classes: {
            paper: classNames(
                classes.drawer,
                classes.notificationsDrawer,
            ),
        },
    };
    const renderNotificationsHeader = () => (
        <Grid
            container
            alignItems="center"
            className={classes.drawerHeader}
        >
            <Grid item xs>
                <Typography className={classes.drawerTitle}>Order notifications</Typography>
            </Grid>
            <Grid>
                <IconButton onClick={onCloseNotificationsButtonClick}>
                    <CloseIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
    const renderNotifications = () => (
        notifications.map((item) => {
            const {
                notificationTemplate: template,
                notificationAcknowledgements,
            } = item;
            const {
                resourceList: acknowledgements,
            } = notificationAcknowledgements;

            const isAcknowledged = acknowledgements.length > 0;

            return (
                <NotificationListItem
                    id={item.id}
                    key={item.id}
                    description={template.description}
                    priorityLevel={template.priorityLevel}
                    acknowledged={isAcknowledged}
                    onClick={onNotificationListItemClick}
                />
            );
        })
    );
    const renderDetailsHeader = () => (
        <Grid
            container
            alignItems="center"
            className={classes.drawerHeader}
        >
            <Grid item xs>
                <IconButton onClick={onBackButtonClick}>
                    <ArrowBackIcon />
                </IconButton>
            </Grid>
            <Grid>
                <IconButton onClick={onCloseNotificationsButtonClick}>
                    <CloseIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
    const renderDetails = () => {
        if (!notification) return null;

        // Don't render if we don't have a template or embedded acknowledgements object
        const {
            notificationTemplate: template,
            notificationAcknowledgements,
        } = notification;
        if (!template || !notificationAcknowledgements) return null;

        // Don't render if we don't have the resource list of acknowledgements
        const {
            resourceList: acknowledgements,
        } = notificationAcknowledgements;
        if (!acknowledgements) return null;

        const { customNotes } = notification;
        const {
            priorityLevel,
            label,
            description,
        } = template;
        const isAcknowledged = acknowledgements.length > 0;

        return (
            <div className={classes.detailsRoot}>
                <Grid container className={classes.detailsHeader}>
                    <Grid>
                        <PriorityLevelIcon
                            priorityLevel={priorityLevel}
                            className={classes.detailsIcon}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography className={classes.detailsTitle}>{label}</Typography>
                    </Grid>
                </Grid>
                <Typography className={classes.detailsDescription}>{description}</Typography>
                {
                    customNotes && (
                        <React.Fragment>
                            <Typography className={classes.additionalNotesTitle}>Additional notes</Typography>
                            <Typography className={classes.additionalNotes}>{customNotes}</Typography>
                        </React.Fragment>
                    )
                }
                <Button
                    color="primary"
                    variant="outlined"
                    fullWidth
                    size="large"
                    className={classes.acknowledgeButton}
                    onClick={onAcknowledgeButtonClick}
                    disabled={isAcknowledged}
                >
                    {isAcknowledged ? 'Acknowledged' : 'Acknowledge'}
                </Button>
            </div>
        );
    };

    return (
        <React.Fragment>
            <Drawer {...detailsDrawerProps}>
                {
                    renderDetailsHeader()
                }
                <Divider className={classes.drawerDivider} />
                {
                    renderDetails()
                }
            </Drawer>

            <Drawer {...notificationsDrawerProps}>
                {
                    renderNotificationsHeader()
                }
                <Divider className={classes.drawerDivider} />

                <List>
                    {
                        renderNotifications()
                    }
                </List>

            </Drawer>

        </React.Fragment>
    );
};

SketchOrderNotificationsDrawer.propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        notificationTemplate: PropTypes.shape({
            description: PropTypes.string.isRequired,
            infoLink: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            notificationType: PropTypes.string.isRequired,
            priorityLevel: PropTypes.string.isRequired,
        }).isRequired,
    })).isRequired,
    notification: PropTypes.shape({
        id: PropTypes.number.isRequired,
        notificationTemplate: PropTypes.shape({
            description: PropTypes.string.isRequired,
            infoLink: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            notificationType: PropTypes.string.isRequired,
            priorityLevel: PropTypes.string.isRequired,
        }).isRequired,
    }),
    detailsDrawerOpen: PropTypes.bool.isRequired,
    notificationsDrawerOpen: PropTypes.bool.isRequired,
    classes: PropTypes.shape({
        drawerHeader: PropTypes.string.isRequired,
        drawerTitle: PropTypes.string.isRequired,
        detailsRoot: PropTypes.string.isRequired,
        detailsHeader: PropTypes.string.isRequired,
        detailsIcon: PropTypes.string.isRequired,
        detailsTitle: PropTypes.string.isRequired,
        detailsDescription: PropTypes.string.isRequired,
        acknowledgeButton: PropTypes.string.isRequired,
        drawer: PropTypes.string.isRequired,
        notificationsDrawer: PropTypes.string.isRequired,
        notificationDetailsDrawer: PropTypes.string.isRequired,
        drawerDivider: PropTypes.string.isRequired,
        additionalNotesTitle: PropTypes.string.isRequired,
        additionalNotes: PropTypes.string.isRequired,
    }).isRequired,
    onNotificationListItemClick: PropTypes.func.isRequired,
    onCloseNotificationsButtonClick: PropTypes.func.isRequired,
    onAcknowledgeButtonClick: PropTypes.func.isRequired,
    onBackButtonClick: PropTypes.func.isRequired,
};

SketchOrderNotificationsDrawer.defaultProps = {
    notification: null,
};

export default withStyles(styles)(SketchOrderNotificationsDrawer);
