import httpRequestMethods from './httpRequestMethods';
import httpStatusCodes from './httpStatusCodes';
import RoofSnapApiClient from './roofSnapApiClient';

export const httpMethod = httpRequestMethods;
export const httpStatus = httpStatusCodes;

export const CALL_API = Symbol('Call API');

export default () => next => (action) => {
    const callAPI = action[CALL_API];

    // So the middleware doesn't get applied to every single action
    if (typeof callAPI === 'undefined') {
        return next(action);
    }

    const {
        endpoint,
        types,
        method,
        params,
        body,
        header,
        getAllPages,
        authRequired,
        responseBodyType,
        apiVersion,
    } = callAPI;

    const actionWith = (data) => {
        const finalAction = Object.assign({}, action, data);
        delete finalAction[CALL_API];
        return finalAction;
    };

    const [requestType, successType, errorType, reduxErrorMessageType, customErrorMessage] = types;
    next(actionWith({ type: requestType }));

    const roofSnapApi = new RoofSnapApiClient();

    return roofSnapApi
        .callApi({
            endpoint, method, params, body, header, getAllPages, authRequired, responseBodyType, apiVersion,
        })
        .then(
            response =>
                next({
                    response,
                    type: successType,
                }),
            (error) => {
                if (reduxErrorMessageType) {
                    next({
                        message: customErrorMessage ||
                            'RoofSnap has encountered an error. Please contact us at (877) 766-3762 or support@roofsnap.com',
                        type: reduxErrorMessageType,
                    });
                }
                return next({
                    error:
                        error.message || error.Message || 'There was an error.',
                    type: errorType,
                });
            },
        );
};
