import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Container } from '@mui/material';
import {
    getSketchOSOrdersByOrganization,
    getRequestedOrOwnedSketchOrders,
} from '../../actions/SketchOSActions';
import SketchOrderList from './SketchOrderList';
import FloatingActionButton from '../FloatingActionButton/FloatingActionButton';
import SketchOsCompleteActivationDialog from '../SketchOsCompleteActivationDialog/SketchOsCompleteActivationDialog';
import BillingFailedSketchOrdersAlert from '../BillingFailedSketchOrdersAlert';
import FreeSketchOrderIndicator from '../FreeSketchOrders';
import {
    getBillingFailedSketchOrdersByOrganization,
    getRequestedOrOwnedBillingFailedSketchOrders,
} from '../../actions/BillingFailedSketchOrdersActions';
import {
    getOrganizationsFreeSketchOrdersBalance,
    getOrganizationOpenSketchOrders,
} from '../../actions/OrganizationFreeSketchOrdersActions';
import { getSingularOrPlural } from '../../lib/HelperFunctions';
import { DocumentCategoryNames } from '../../lib/DocumentsCategories.ts';
import { downloadDocument } from '../../actions/documentActions';
import { getDocuments } from '../../actions/documentsActions';
import { createDocumentSharedAccessToken } from '../../actions/documentSharedAccessTokenActions';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';
import { getProjectById } from '../../actions/ProjectActions';
import SketchOrderTypeFilter from './SketchOrderTypeFilter.tsx';

const style = {
    root: {
        padding: 24,
    },
    paper: {
        margin: 'auto',
    },
    flex: {
        flexGrow: 1,
    },
};

class SketchOrders extends Component {
    state = {
        showActivationDialog: false,
    };

    componentDidMount() {
        this.getOrders(1);
        this.getBillingFailedSketchOrders();
        this.getFreeSketchOrders();
    }

    onUnableToDownloadDocument = async (projectId) => {
        await this.props.dispatch(getProjectById(projectId));
        const project = _.isEmpty(this.props.currentProject)
            ? null
            : this.props.currentProject;
        if (project) {
            this.props.history.push(`projects/${projectId}/documents`);
            this.props.dispatch(
                setReduxMessage(
                    'There was a problem downloading your document. Redirecting to your documents page where you can create another Sketch Report.'
                )
            );
        } else {
            this.props.dispatch(
                setReduxMessage(
                    'Unable to find the project associated with this Sketch Order'
                )
            );
        }
    };

    getFreeSketchOrders() {
        this.props.dispatch(
            getOrganizationsFreeSketchOrdersBalance(this.props.organizationId)
        );
        this.props.dispatch(
            getOrganizationOpenSketchOrders(this.props.organizationId)
        );
    }

    getSketchOrderReport = async (projectId, projectClonedForDelivery) => {
        if (projectClonedForDelivery) {
            await this.props.dispatch(getDocuments(projectId));
            const documents = [...this.props.documents.data];
            const sketchOrderReportDocument = documents.filter(
                (d) =>
                    d.template.templateCategory.name ===
                    DocumentCategoryNames.SketchOrderReport
            )[0];
            return sketchOrderReportDocument;
        }
        return null;
    };

    getOrders = (pageNumber) => {
        if (this.props.isAccountAdmin) {
            this.props.dispatch(
                getSketchOSOrdersByOrganization(
                    this.props.organizationId,
                    pageNumber,
                    this.state.selectedReportType
                )
            );
        } else {
            this.props.dispatch(
                getRequestedOrOwnedSketchOrders(
                    this.props.currentUserId,
                    pageNumber,
                    this.state.selectedReportType
                )
            );
        }
    };

    getBillingFailedSketchOrders = () => {
        if (this.props.isAccountAdmin) {
            this.props.dispatch(
                getBillingFailedSketchOrdersByOrganization(
                    this.props.organizationId
                )
            );
        } else {
            this.props.dispatch(
                getRequestedOrOwnedBillingFailedSketchOrders(
                    this.props.currentUserId
                )
            );
        }
    };

    getNextPageOfOrders = () => {
        this.getOrders(this.props.currentPage + 1);
    };

    getPrevPageOfOrders = () => {
        this.getOrders(this.props.currentPage - 1);
    };

    downloadSketchOrderReport = async (document) => {
        await this.props.dispatch(
            createDocumentSharedAccessToken(document.id, document.renderingId)
        );
        await this.props.dispatch(
            downloadDocument(
                this.props.documentSharedAccessToken.sharedAccessSignatureUrl,
                document.renderingFileName
            )
        );
    };

    goToDocumentList = (projectId) => {
        this.props.history.push(`/projects/${projectId}/documents`);
    };

    goToSketchOrder = (id) => {
        this.props.history.push(`/orders/${id}`);
    };

    goToProject = (projectId) => {
        this.props.history.push(`projects/${projectId}`);
    };

    goToCreateSketchOrder = () => {
        this.props.history.push('/orders/create');
    };

    dismissDialog = () => {
        this.setState({ showActivationDialog: false });
    };

    goToBillingFailedSketchOrders = () => {
        // If there is only one billing failed sketch order, just go directly to that sketch order to retry billing
        // Otherwise, go the billing failed orders page
        if (this.props.totalBillingFailedSketchOrders === 1) {
            // Only one sketch order has failed to bill. Go pull it out of the array.
            const billingFailedSketchOrder =
                this.props.billingFailedSketchOrders[0];

            // Go to that sketch order
            this.props.history.push(`/orders/${billingFailedSketchOrder.id}`);
        } else {
            this.props.history.push('/billingfailedorders');
        }
    };

    remainingFreeSketchOrders = () =>
        this.props.freeSketchOrderBalance - this.props.openSketchOrders;

    handleReportTypeChange = (reportType) => {
        this.setState({ selectedReportType: reportType }, () => {
            this.getOrders(1);
        });
    };
    render() {
        const { sketchOSOrders, totalPages, currentPage, totalResults } =
            this.props;

        return (
            <div id='orders' style={style.root}>
                <BillingFailedSketchOrdersAlert
                    totalSketchOrders={
                        this.props.totalBillingFailedSketchOrders
                    }
                    onClick={this.goToBillingFailedSketchOrders}
                />
                {this.props.totalResults === 0 ? (
                    <Card style={style.paper}>
                        <CardContent>
                            <Grid container>
                                <Grid item style={style.flex}>
                                    <Typography variant='h6' gutterBottom>
                                        Welcome To SketchOS
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FreeSketchOrderIndicator
                                        freeSketchOrderBalance={
                                            this.props.freeSketchOrderBalance
                                        }
                                        openSketchOrders={
                                            this.props.openSketchOrders
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Typography gutterBottom>
                                SketchOS, the Sketch Ordering Service, allows
                                you to get turn key roof measurements. Try it!
                                {this.remainingFreeSketchOrders() > 0 &&
                                    ` You have ${this.remainingFreeSketchOrders()} Sketch ${getSingularOrPlural(
                                        'Order',
                                        this.remainingFreeSketchOrders()
                                    )} available for free!`}
                            </Typography>
                        </CardContent>
                    </Card>
                ) : (
                    <Container sx={{ maxWidth: 1100 }}>
                        <SketchOrderTypeFilter
                            onChange={this.handleReportTypeChange}
                        />
                        <SketchOrderList
                            sketchOrders={sketchOSOrders}
                            totalResults={totalResults}
                            onListItemClick={this.goToSketchOrder}
                            onOpenProjectClick={this.goToProject}
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onNextClick={this.getNextPageOfOrders}
                            onPrevClick={this.getPrevPageOfOrders}
                            isFreeAccount={this.props.isFreeAccount}
                            freeSketchOrderBalance={
                                this.props.freeSketchOrderBalance
                            }
                            openSketchOrders={this.props.openSketchOrders}
                            downloadSketchOrderReport={
                                this.downloadSketchOrderReport
                            }
                            getSketchOrderReport={this.getSketchOrderReport}
                            onUnableToDownloadDocument={
                                this.onUnableToDownloadDocument
                            }
                            onClickDocumentsList={this.goToDocumentList}
                        />
                    </Container>
                )}
                <FloatingActionButton
                    id='sketchos-add-button'
                    onClick={this.goToCreateSketchOrder}
                    variant='extended'
                />
                <SketchOsCompleteActivationDialog
                    open={this.state.showActivationDialog}
                    onDismiss={this.dismissDialog}
                />
            </div>
        );
    }
}

SketchOrders.propTypes = {
    dispatch: PropTypes.func.isRequired,
    organizationId: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalResults: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    sketchOSOrders: PropTypes.arrayOf(PropTypes.shape).isRequired,
    isAccountAdmin: PropTypes.bool.isRequired,
    currentUserId: PropTypes.number.isRequired,
    totalBillingFailedSketchOrders: PropTypes.number.isRequired,
    billingFailedSketchOrders: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
        })
    ).isRequired,
    isFreeAccount: PropTypes.bool.isRequired,
    freeSketchOrderBalance: PropTypes.number.isRequired,
    openSketchOrders: PropTypes.number.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

const mapState = (state) => {
    const {
        organization,
        sketchOS,
        currentUserRoles,
        currentUser,
        billingFailedSketchOrders: billingFailedSketchOrdersStore,
        organizationFreeSketchOrders,
        features,
        documents,
        documentSharedAccessToken,
        projectHome,
    } = state;
    const {
        id: organizationId,
        status: organizationStatus,
        isFreeAccount,
    } = organization;
    const { isAccountAdmin } = currentUserRoles;
    const { userId: currentUserId } = currentUser;
    const {
        totalResults: totalBillingFailedSketchOrders,
        sketchOrders: billingFailedSketchOrders,
    } = billingFailedSketchOrdersStore;
    const { freeSketchOrderBalance, openSketchOrders } =
        organizationFreeSketchOrders;
    const { currentProject } = projectHome;

    return {
        ...sketchOS,
        organizationId,
        organizationStatus,
        isAccountAdmin,
        currentUserId,
        totalBillingFailedSketchOrders,
        isFreeAccount,
        billingFailedSketchOrders,
        freeSketchOrderBalance,
        openSketchOrders,
        features,
        documents,
        documentSharedAccessToken,
        currentProject,
    };
};

export default connect(mapState)(SketchOrders);
