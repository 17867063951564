import { BASE_URL } from '../middleware/roofSnapApiClient';
import { HIDE_SKETCH, SHOW_SKETCH } from '../actions/SketchHost';

const defaultState = {
    show: false,
    apiKey: process.env.REACT_APP_API_KEY,
    baseApiUrl: `${BASE_URL}/v1`,
    baseFacetFindingApiUrl: process.env.REACT_APP_MAD_FACEFINDING_HOST,
    baseMeasurementApiUrl: process.env.REACT_APP_MEASUREMENT_HOST,
    basePitchApiUrl: process.env.REACT_APP_PITCH_HOST,
};

function sketchHost(state = defaultState, action) {
    switch (action.type) {
    case SHOW_SKETCH:
        return Object.assign({}, state, {
            show: true,
            projectId: action.projectId,
        });
    case HIDE_SKETCH:
        return Object.assign({}, defaultState);
    default:
        return state;
    }
}

export default sketchHost;
