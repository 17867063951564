import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import dateFormat from 'dateformat';
import CloseIcon from '@material-ui/icons/Close';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import {
    Grid,
    Typography,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    Slide,
    Button,
} from '@material-ui/core';
import Pager from '../Pager/Pager.tsx';
import {
    getOrganizationDeletedProjects,
    restoreOrganizationDeletedProject,
} from '../../actions/OrganizationDeletedProjectsActions';
import ProjectListGridItem from '../ProjectList/ProjectListGridItem';

const styles = () => ({
    projectListCard: {
        width: '100%',
        maxWidth: '1350px',
        margin: 'auto',
    },
    projectLiarBody: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: '20px',
        justifyContent: 'center',
    },
});

const transitionFunction = function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
};

const Transition = React.forwardRef(transitionFunction);

const DeletedProjects = (props) => {
    const organizationId = useSelector(
        (state) => state.currentUser.organizationId
    );
    const deletedProjects = useSelector(
        (state) => state.organizationDeletedProjects
    );
    const totalProjects = deletedProjects.totalResults;
    const totalProjectsPages = deletedProjects.totalPages;
    const currentProjectsPage = deletedProjects.currentPage;
    const [projects, setProjects] = useState(deletedProjects.data);
    const [selectedProject, setSelectedProject] = useState({});
    const [page, setPage] = useState(currentProjectsPage);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        setProjects(deletedProjects.data);
    }, [deletedProjects.data]);
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getOrganizationDeletedProjects(organizationId, page));
    }, [page, organizationId, dispatch]);

    const handleDetailsClick = (project) => {
        setSelectedProject(project);
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedProject(null);
        setOpen(false);
    };

    const handleRestore = async (id) => {
        await dispatch(restoreOrganizationDeletedProject(id));
        dispatch(
            getOrganizationDeletedProjects(organizationId, currentProjectsPage)
        );
    };

    const handleDialogRestoreClick = (id) => {
        handleRestore(id);
        setOpen(false);
    };

    const renderProject = (project) => {
        const deletedProjectMenus = [
            {
                id: 'project-details-button',
                Title: 'Details',
                OnClick: () => handleDetailsClick(project),
                Icon: <List />,
                Color: '#005CAA',
                isPrimary: true,
            },
            {
                id: 'project-restore-rebutton',
                Title: 'Restore',
                OnClick: () => handleRestore(project.id),
                Icon: <RestoreFromTrashIcon />,
                Color: '#005CAA',
                isPrimary: true,
            },
        ];
        return (
            <Grid key={project.id} item xs={12} md={6} lg={4}>
                <ProjectListGridItem
                    project={project}
                    onRestore={handleRestore}
                    primaryVisibleMenuItems={deletedProjectMenus}
                    onProjectListItemClick={() => handleDetailsClick(project)}
                />
            </Grid>
        );
    };

    const nextProjectsPage = () => {
        setPage(currentProjectsPage + 1);
    };

    const prevProjectsPage = () => {
        setPage(currentProjectsPage - 1);
    };

    return (
        <React.Fragment>
            <div className={props.classes.projectListCard}>
                <Grid container>
                    <Grid container>
                        <Grid item xs>
                            <Typography
                                style={{ width: '100%', margin: '20px 0' }}
                                align='right'
                            >
                                {totalProjects} Project(s)
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    className={props.classes.projectListBody}
                >
                    {projects.map((p) => renderProject(p))}
                </Grid>
                <Pager
                    totalPages={totalProjectsPages}
                    currentPage={page}
                    nextClick={nextProjectsPage}
                    prevClick={prevProjectsPage}
                />
            </div>
            {selectedProject && (
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    onClose={handleClose}
                    aria-describedby='alert-dialog-slide-description'
                    fullWidth
                    maxWidth='sm'
                >
                    <Grid
                        container
                        direction='row'
                        justify='space-between'
                        alignItems='center'
                    >
                        <Grid item>
                            <DialogTitle>
                                {selectedProject.projectName}
                            </DialogTitle>
                        </Grid>
                        <Grid item className={props.classes.closeIcon}>
                            <CloseIcon onClick={handleClose} />
                        </Grid>
                    </Grid>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-slide-description'>
                            Customer: {selectedProject.customerName}
                        </DialogContentText>
                        <DialogContentText id='alert-dialog-slide-description'>
                            Address: {selectedProject.customerAddress}
                        </DialogContentText>
                        <DialogContentText id='alert-dialog-slide-description'>
                            City: {selectedProject.customerCity}
                        </DialogContentText>
                        <DialogContentText id='alert-dialog-slide-description'>
                            State: {selectedProject.customerState}
                        </DialogContentText>
                        <DialogContentText id='alert-dialog-slide-description'>
                            Zip: {selectedProject.customerZip}
                        </DialogContentText>
                        <DialogContentText>
                            Created:{' '}
                            {dateFormat(
                                selectedProject.UpdatedAt ||
                                    selectedProject.createdAt,
                                'mmm d, yyyy h:MM TT'
                            )}
                        </DialogContentText>
                        <DialogContentText id='alert-dialog-slide-description'>
                            Owner: {selectedProject.projectOwner}
                        </DialogContentText>
                        <DialogContentText id='alert-dialog-slide-description'>
                            Status: {selectedProject.projectStatus}
                        </DialogContentText>
                        <DialogContentText id='alert-dialog-slide-description'>
                            Notes: {selectedProject.notes}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() =>
                                handleDialogRestoreClick(selectedProject.id)
                            }
                        >
                            Restore
                        </Button>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            )}
        </React.Fragment>
    );
};

DeletedProjects.defaultProps = {
    currentProjectsPage: 1,
};
export default withStyles(styles, { withTheme: true })(DeletedProjects);
