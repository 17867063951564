import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/Auth';
import ForbiddenDialog from './ForbiddenDialog';

const AccountExpiredForbiddenDialog = ({ dispatch }) => {
    const logOut = () => {
        dispatch(logoutUser());
    };

    return (<ForbiddenDialog
        confirmationAction={logOut}
        forbiddenDialogTitle="Subscription Expired"
        forbiddenDialogContentText={['The subscription for your organization has expired. Please ', <a href={`${process.env.REACT_APP_ROOFSNAP_ONLINE_HOST}/Account/UpdateCreditCard`} target="_blank" rel="noopener noreferrer">update your payment method</a>, ' or contact your account administrator for assistance.']}
    />);
};

AccountExpiredForbiddenDialog.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default connect(null)(AccountExpiredForbiddenDialog);
