import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Onboarding from '../Onboarding';
import { signUp } from '../../actions/signUpActions';
import useQuery from '../../hooks/useQueryHook.ts';


function Welcome({ dispatch }) {
    const query = useQuery();
    const password = atob(query.get('password').replace(/_/g, '/').replace(/-/g, '+').replace(/~/g, '='));
    const registrationForm = {
        firstName: query.get('firstName'),
        lastName: query.get('lastName'),
        email: query.get('email'),
        companyName: query.get('companyName'),
        phone: query.get('phone'),
        country: query.get('country'),
        password,
    };

    useEffect(() => {
        dispatch(signUp({
            firstName: registrationForm.firstName,
            lastName: registrationForm.lastName,
            email: registrationForm.email,
            companyName: registrationForm.companyName,
            phone: registrationForm.phone,
            country: registrationForm.country,
            password: registrationForm.password,
        }));
    }, [registrationForm, dispatch]);

    return <Onboarding />;
}

export default connect()(Welcome);
