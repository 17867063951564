export const DocumentTemplateV1TypeToCategory = {
    'Sketch Report': 'sketchreport',
    Estimate: 'estimate',
    Contract: 'contract',
    'Pre-Start Checklist': 'checklist',
    'Material Order': 'materialorder',
    'Summary Report': 'summaryreport',
    'Labor Report': 'laborreport',
    Proposal: 'proposaldocument',
    Order: 'orderdocument',
    'Comparison Estimate': 'estimateAlt',
};

export const getDocumentTemplateCategoryByV1Type = v1Type => DocumentTemplateV1TypeToCategory[v1Type];

export default DocumentTemplateV1TypeToCategory;
