import React from 'react';
import { Chip, ChipVariants } from '@ui';
import { SketchOrderStatus } from 'lib';
import { SketchOrderStatusType } from 'lib/SketchOrderStatus';

type OrderStatusChipProps = {
    status: SketchOrderStatus;
};

const statusToPropsMap: {
    [key in SketchOrderStatusType]: {
        chipVariant: ChipVariants;
        label?: string;
    };
} = {
    [SketchOrderStatus.Cancelled]: { chipVariant: 'gray' },
    [SketchOrderStatus.Complete]: { chipVariant: 'green' },
    [SketchOrderStatus.Pending]: { chipVariant: 'blue' },
    [SketchOrderStatus.Incomplete]: { chipVariant: 'gray' },
    [SketchOrderStatus.Billed]: { chipVariant: 'green' },
    [SketchOrderStatus.BillingFailed]: { chipVariant: 'red' },

    [SketchOrderStatus.InProgress]: { chipVariant: 'orange' },
    [SketchOrderStatus.Exception]: {
        chipVariant: 'orange',
        label: 'In Progress',
    },
    [SketchOrderStatus.InReview]: {
        chipVariant: 'orange',
        label: 'In Progress',
    },
    [SketchOrderStatus.PendingGroupBilling]: {
        chipVariant: 'orange',
        label: 'In Progress',
    },
    [SketchOrderStatus.PendingRejection]: {
        chipVariant: 'orange',
        label: 'In Progress',
    },
    [SketchOrderStatus.PendingReview]: {
        chipVariant: 'orange',
        label: 'In Progress',
    },
    [SketchOrderStatus.Queued]: {
        chipVariant: 'orange',
        label: 'In Progress',
    },
    [SketchOrderStatus.RevisionRequested]: {
        chipVariant: 'blue',
        label: 'Revising',
    },
};

export const OrderStatusChip = ({ status }: OrderStatusChipProps) => {
    return (
        <Chip
            variant={statusToPropsMap[status]?.chipVariant ?? 'gray'}
            label={
                statusToPropsMap[status]?.label ??
                status.split(/(?<![A-Z])(?=[A-Z])/).join(' ')
            }
        />
    );
};

export default OrderStatusChip;
