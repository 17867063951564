import { CALL_API } from '../middleware/api';

export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE';
export const SELECT_STATUS = 'SELECT_STATUS';
export const SELECT_OFFICE = 'SELECT_OFFICE';
export const SELECT_USER = 'SELECT_USER';
export const CLEAR_FILTER = 'CLEAR_FILTER';

export const getProjects = (realm, id, params) => ({
    [CALL_API]: {
        types: [
            GET_PROJECTS_REQUEST,
            GET_PROJECTS_SUCCESS,
            GET_PROJECTS_FAILURE,
        ],
        endpoint: `${realm}/${id}/projects`,
        params,
    },
});

export const projectParams = {
    Search: 'search',
    Page: 'page',
    Status: 'projectstatus',
    Office: 'officeId',
};

export const projectRealm = {
    Organization: 'organizations',
    User: 'userprofiles',
};

export const selectStatus = status => ({
    type: SELECT_STATUS,
    status,
});

export const selectOffice = office => ({
    type: SELECT_OFFICE,
    office,
});

export const selectUser = user => ({
    type: SELECT_USER,
    user,
});

export const clearFilter = () => ({
    type: CLEAR_FILTER,
});
