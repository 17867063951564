import React from 'react';
import { Grid, Toolbar, Typography } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles'
import roofsnapTheme from '../../../lib/theme';
import { Link } from 'react-router-dom';

interface LockedSettingsToolbarProps extends WithStyles<typeof styles> {
}

const styles = (theme: any) =>
    createStyles({
        toolbar: {
            display: 'flex',
            backgroundColor: '#ECECEC',
            [theme.breakpoints.down('md')]: {
                paddingBottom: theme.spacing(1),
            },
            borderTop: '1px solid #dbdbdb',
            borderBottom: '1px solid #dbdbdb',
            minHeight: '46px',
            textAlign:'center',
            width: '100%'
        },
        activateHeading: {
            textAlign: 'center',
            fontSize: '14px',
            color: 'rgb(0,0,0,0.54)',
        },
        activateLink: {
            color: roofsnapTheme.palette.roofsnapBlue['500'],
            textDecoration: 'inherit',
            fontWeight: 'bold',
        },
    })

const LockedSettingsToolbar = (props: LockedSettingsToolbarProps) => {
    const { classes } = props;
    return (
            <Toolbar className={classes.toolbar}>
                <Grid item container spacing={1} justify="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography className={classes.activateHeading}>
                            Document settings are customizable with a subscription,&nbsp;
                            <Link to="/settings/billing" href="/settings/billing" className={classes.activateLink}>
                                subscribe
                            </Link> to unlock this feature.
                        </Typography>
                    </Grid>
                </Grid>
            </Toolbar>
    )
}

export default withStyles(styles)(LockedSettingsToolbar);