import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Link, withRouter } from 'react-router-dom';
import MobileBadges from '../MobileBadges';
import getMobileOperatingSystem from '../../lib/MobileOperatingSystemChecker';
import WebAppBadge from './web-badge.png';

const style = {
    root: {
        padding: 24,
    },
    card: {
        padding: 20,
        maxWidth: 450,
        margin: 'auto',
    },
    completeCheckWrapper: {
        display: 'flex',
    },
    logInButtonWrapper: {
        display: 'flex',
        width: '100%',
    },
    logInButton: {
        flex: 1,
        '&:hover': {
            background: 'none',
        },
    },
    logInBadge: {
        width: 200,
    },
    mobileBadges: {
        paddingTop: 20,
    },
};

class SignUpComplete extends Component {
    render() {
        const isMobile = getMobileOperatingSystem();
        return (
            <div style={style.root}>
                <Card className={this.props.classes.card} id="signUpComplete">
                    <div className={this.props.classes.completeCheckWrapper}>
                        <CheckCircleIcon
                            style={{
                                flex: 1,
                                fill: this.props.theme.palette.primary['500'],
                                width: 100,
                                height: 100,
                            }}
                        />
                    </div>
                    <CardHeader
                        title="Snap! Check your email for next steps!"
                    />
                    <CardActions>
                        {
                            !isMobile &&
                            <Link
                                to="/"
                                href="/"
                                className={this.props.classes.logInButtonWrapper}
                            >
                                <Button
                                    id="logInButton"
                                    className={this.props.classes.logInButton}
                                    disableRipple
                                    disableFocusRipple
                                >
                                    <img
                                        alt="Open Web App"
                                        src={WebAppBadge}
                                        className={this.props.classes.logInBadge}
                                    />
                                </Button>
                            </Link>
                        }
                    </CardActions>
                    <div className={this.props.classes.mobileBadges}>
                        <MobileBadges />
                    </div>
                </Card>
            </div>
        );
    }
}

SignUpComplete.propTypes = {
    classes: PropTypes.shape({
        card: PropTypes.string.isRequired,
        completeCheckWrapper: PropTypes.string.isRequired,
        logInButtonWrapper: PropTypes.string.isRequired,
        logInBadge: PropTypes.string.isRequired,
        mobileBadges: PropTypes.string.isRequired,
        logInButton: PropTypes.string.isRequired,
    }).isRequired,
    theme: PropTypes.shape({
        palette: PropTypes.shape({
            primary: PropTypes.shape({
                500: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default withRouter((withStyles(style, { withTheme: true })(SignUpComplete)));
