import { CALL_API } from '../middleware/api';

export const GET_FEATURES_REQUEST = 'GET_FEATURES_REQUEST';
export const GET_FEATURES_SUCCESS = 'GET_FEATURES_SUCCESS';
export const GET_FEATURES_FAILURE = 'GET_FEATURES_FAILURE';

export const getFeatures = () => ({
    [CALL_API]: {
        types: [
            GET_FEATURES_REQUEST,
            GET_FEATURES_SUCCESS,
            GET_FEATURES_FAILURE,
        ],
        endpoint: 'features',
        apiVersion: '',
    },
});
