const UpdatePaymentVerificationErrorCode = {
    General: 'General',
    PostalCode: 'PostalCode',
    Cvv: 'Cvv',
};

export const verificationErrorMessages = {
    [UpdatePaymentVerificationErrorCode.General]: 'Please verify your payment information was entered correctly and try again.',
    [UpdatePaymentVerificationErrorCode.PostalCode]: 'Postal code is invalid',
    [UpdatePaymentVerificationErrorCode.Cvv]: 'CVV code is invalid',
};

export default UpdatePaymentVerificationErrorCode;
