export const SHOW_SKETCH = Symbol('SHOW_SKETCH');
export const HIDE_SKETCH = Symbol('HIDE_SKETCH');

export const showSketch = projectId => ({
    type: SHOW_SKETCH,
    projectId,
});

export const hideSketch = () => ({
    type: HIDE_SKETCH,
});
