import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = theme => ({
    wrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: '100%',
        width: '100%',
        position: 'fixed',
        top: 0,
    },
    card: {
        maxWidth: 400,
        padding: 20,
        margin: theme.spacing(2),
    },
    completeCheckWrapper: {
        display: 'flex',
    },
    link: {
        textDecoration: 'none',
    },
});

const ConfirmAccountInformationComplete = props => (
    <div className={props.classes.wrapper}>
        <Card className={props.classes.card}>
            <div className={props.classes.completeCheckWrapper}>
                <CheckCircleIcon
                    style={{
                        flex: 1,
                        fill: props.theme.palette.primary['500'],
                        width: 100,
                        height: 100,
                    }}
                />
            </div>
            <CardHeader
                title="Registration Complete!"
                subheader="Your RoofSnap account has been registered."
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            You can activate your subscription now or get started using RoofSnap by viewing your projects or ordering a sketch for us to measure for you.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Link className={props.classes.link} to="/activate/billinginformation">
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                            >
                                Activate Subscription
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={6}>
                        <Link className={props.classes.link} to="/projects">
                            <Button variant="outlined" color="secondary" fullWidth>
                                View Projects
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={6}>
                        <Link className={props.classes.link} to="/orders/create">
                            <Button variant="outlined" color="secondary" fullWidth>
                                Order A Sketch
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </div>
);

export default withStyles(styles, { withTheme: true })(ConfirmAccountInformationComplete);
