import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import BraintreeDropinUI from '../BraintreeDropinUI';
import { generatePaymentClientToken } from '../../actions/Payment';

class PaymentCollectionDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentValid: false,
        };
        this.brainTreeDropinRef = null;
    }
    componentDidMount() {
        this.generatePaymentClientToken();
    }

    onCreditCardValid = valid => () => {
        this.updateStateAndNotify({ paymentValid: valid });
    }

    setBraintreeInstance = (i) => {
        this.brainTreeDropinRef = i;
        if (i.isPaymentMethodRequestable()) {
            this.updateStateAndNotify({ paymentValid: true });
        }
    }

    getNonceFromBraintreeDropin = async () => {
        const { nonce } = await this.brainTreeDropinRef.requestPaymentMethod();
        return nonce;
    }

    generatePaymentClientToken() {
        return this.props.dispatch(generatePaymentClientToken(this.props.currentUserId));
    }

    updateStateAndNotify(newState) {
        this.setState(newState, () => {
            this.props.onPaymentChange(this.state.paymentValid, this.getNonceFromBraintreeDropin);
        });
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                classes={{
                    paper: this.props.root,
                }}
            >
                <DialogTitle>
                    Enter Payment Info
                </DialogTitle>
                <DialogContent>
                    <DialogContentText variant="subtitle2">
                        {this.props.memo}
                    </DialogContentText>
                    <BraintreeDropinUI
                        options={{
                            authorization: this.props.paymentClientToken,
                        }}
                        onInstance={this.setBraintreeInstance}
                        onPaymentMethodRequestable={this.onCreditCardValid(true)}
                        onNoPaymentMethodRequestable={this.onCreditCardValid(false)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" onClick={() => this.props.onSubmit()}>Submit</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

PaymentCollectionDialog.propTypes = {
    paymentClientToken: PropTypes.string,
};

PaymentCollectionDialog.defaultProps = {
    paymentClientToken: '',
};

const mapStateToProps = state => state.payment || {};

export default connect(mapStateToProps)(PaymentCollectionDialog);
