import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { appInitialize } from '../../actions/AppInit';

const styles = theme => ({
    root: {
        height: '100%',
        textAlign: 'center',
        margin: 'auto',
        padding: theme.spacing(2),
    },
    spacing: {
        marginBottom: theme.spacing(2),
    },
    image: {
        maxWidth: 300,
    },
});

const SomethingWentWrong = (props) => {
    const reinitialize = () => {
        // Call app initizalize.
        props.dispatch(appInitialize());
    };

    return (
        <Grid
            container
            alignItems="center"
            justify="center"
            className={props.classes.root}
        >
            <Grid
                item
            >
                <img
                    src="/assets/error-image.png"
                    alt="error"
                    className={`${props.classes.spacing} ${props.classes.image}`}
                />
                <Typography
                    variant="h5"
                    gutterBottom
                    className={props.classes.spacing}
                >
                    Something went wrong
                </Typography>
                <Button
                    color="primary"
                    variant="contained"
                    className={props.classes.spacing}
                    onClick={reinitialize}
                >
                    Try Again
                </Button>
                <Typography
                    className={props.classes.spacing}
                >
                    We keep track of these errors but if the problem persists, feel free to <a href={`${process.env.REACT_APP_SUBMIT_SUPPORT_REQUEST_URL}`} rel="noopener noreferrer" target="_blank">contact support.</a>
                </Typography>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    const { auth } = state;
    return {
        accessToken: auth.accessToken,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(SomethingWentWrong));
