import RoofSnapApiClient from '../middleware/roofSnapApiClient';
import responseBodyTypes from '../middleware/responseBodyTypes';
import httpRequestMethods from '../middleware/httpRequestMethods';
import { createProjectDocument } from '../lib/Factories/projectDocumentFactory';
import { CALL_API } from '../middleware/api';
import { downloadBlobAsFile, convertUrlToHttps } from '../lib/HelperFunctions';
import { setReduxMessage } from './ReduxMessagesActions';

export const GET_DOCUMENT_REQUEST = 'GET_DOCUMENT_REQUEST';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_FAILURE = 'GET_DOCUMENT_FAILURE';

const getDocumentRequest = () => ({
    type: GET_DOCUMENT_REQUEST,
});

const getDocumentSuccess = (document) => ({
    type: GET_DOCUMENT_SUCCESS,
    document,
});

const getDocumentFailure = () => ({
    type: GET_DOCUMENT_FAILURE,
});

export const getDocument = (renderingId, document) => async (dispatch) => {
    dispatch(getDocumentRequest());

    try {
        const roofsnapApiClient = new RoofSnapApiClient();
        const rendering = await roofsnapApiClient.callApi({
            endpoint: `documents/${document.id}/renderings/${renderingId}`,
            method: httpRequestMethods.GET,
            getAllPages: true,
            apiVersion: 'v2',
        });

        const massagedDocument = createProjectDocument(document, rendering);
        return dispatch(getDocumentSuccess(massagedDocument));
    } catch (error) {
        return dispatch(getDocumentFailure());
    }
};

export const CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST';
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_FAILURE = 'CREATE_DOCUMENT_FAILURE';

export const createDocument = ({
    organizationId,
    templateId,
    dataContextEntityId,
    estimateOptionIds,
    autoCreateRendering,
}) => ({
    [CALL_API]: {
        types: [
            CREATE_DOCUMENT_REQUEST,
            CREATE_DOCUMENT_SUCCESS,
            CREATE_DOCUMENT_FAILURE,
        ],
        method: httpRequestMethods.POST,
        endpoint: `documents?autoCreateRendering=${autoCreateRendering}`,
        body: {
            organizationId,
            templateId,
            dataContextEntityId,
            estimateOptionIds,
        },
        apiVersion: 'v2',
    },
});

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAILURE = 'DELETE_DOCUMENT_FAILURE';

export const deleteDocument = (documentId) => ({
    [CALL_API]: {
        types: [
            DELETE_DOCUMENT_REQUEST,
            DELETE_DOCUMENT_SUCCESS,
            DELETE_DOCUMENT_FAILURE,
        ],
        endpoint: `documents/${documentId}`,
        method: httpRequestMethods.DELETE,
        apiVersion: 'v2',
    },
});

export const DOWNLOAD_DOCUMENT_REQUEST = 'DOWNLOAD_DOCUMENT_REQUEST';
export const DOWNLOAD_DOCUMENT_SUCCESS = 'DOWNLOAD_DOCUMENT_SUCCESS';
export const DOWNLOAD_DOCUMENT_FAILURE = 'DOWNLOAD_DOCUMENT_FAILURE';

const downloadDocumentRequest = () => ({
    type: DOWNLOAD_DOCUMENT_REQUEST,
});

const downloadDocumentSuccess = () => ({
    type: DOWNLOAD_DOCUMENT_SUCCESS,
});

const downloadDocumentFailure = () => ({
    type: DOWNLOAD_DOCUMENT_FAILURE,
});

export const downloadDocument =
    (documentUrl, documentName) => async (dispatch) => {
        const secureDocumentUrl = documentUrl.startsWith('http')
            ? convertUrlToHttps(documentUrl)
            : documentUrl;
        const errorMessage =
            'Sorry, the document failed to download. Please try again. If problem persists, please contact us at (877) 766-3762.';

        try {
            dispatch(downloadDocumentRequest());
            const response = await fetch(secureDocumentUrl);

            if (!response.ok) {
                dispatch(downloadDocumentFailure());
                dispatch(setReduxMessage(errorMessage));
                return;
            }

            const body = await response.blob();
            downloadBlobAsFile(body, documentName);
            dispatch(downloadDocumentSuccess());
        } catch (error) {
            dispatch(downloadDocumentFailure());
            dispatch(setReduxMessage(errorMessage));
        }
    };

export const GET_DOCUMENT_FORM_HTML_REQUEST = 'GET_DOCUMENT_FORM_HTML_REQUEST';
export const GET_DOCUMENT_FORM_HTML_SUCCESS = 'GET_DOCUMENT_FORM_HTML_SUCCESS';
export const GET_DOCUMENT_FORM_HTML_FAILURE = 'GET_DOCUMENT_FORM_HTML_FAILURE';

export const getDocumentFormHtml = (documentId, esignHtml) => ({
    [CALL_API]: {
        types: [
            GET_DOCUMENT_FORM_HTML_REQUEST,
            GET_DOCUMENT_FORM_HTML_SUCCESS,
            GET_DOCUMENT_FORM_HTML_FAILURE,
        ],
        endpoint: !esignHtml
            ? `documents/${documentId}/formhtml`
            : `documents/${documentId}/esignhtml`,
        apiVersion: 'v2',
        responseBodyType: responseBodyTypes.TEXT,
    },
});
