import { CALL_API } from '../middleware/api';
import LocalStorageWrapper from '../lib/LocalStorageWrapper';
import decode from '../lib/decodeRoofSnapAuthToken';

export const GET_ORGANIZATION_REQUEST = 'GET_ORGANIZATION_REQUEST';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_FAILURE = 'GET_ORGANIZATION_FAILURE';

export const getOrganization = () => {
    const accessToken = LocalStorageWrapper.getItem('accessToken');
    const { organizationId } = decode(accessToken);

    if (!organizationId) {
        return {
            type: GET_ORGANIZATION_FAILURE,
        };
    }

    return {
        [CALL_API]: {
            types: [GET_ORGANIZATION_REQUEST, GET_ORGANIZATION_SUCCESS, GET_ORGANIZATION_FAILURE],
            endpoint: `organizations/${organizationId}`,
        },
    };
};
