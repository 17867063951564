export const SET_APPBAR_BUTTONS = 'SET_APPBAR_BUTTONS';
export const SET_APPBAR_TITLE = 'SET_APPBAR_TITLE';
export const RESET_APPBAR = 'RESET_APPBAR';

export function setAppBarButtons(element) {
    return (dispatch) => {
        dispatch({
            type: SET_APPBAR_BUTTONS,
            buttons: element,
        });
    };
}

export function setAppBarTitle(element) {
    return (dispatch) => {
        dispatch({
            type: SET_APPBAR_TITLE,
            titleText: element,
        });
    };
}

export function resetAppBar() {
    return (dispatch) => {
        dispatch({
            type: RESET_APPBAR,
        });
    };
}
