import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography/Typography';

const styles = theme => ({
    thumb: {
        height: 60,
        width: 100,
        marginRight: theme.spacing(2),
        '& img': {
            maxHeight: '100%',
            maxWidth: '100%',
        },
    },
});

const PaymentInfo = props => (
    <Grid container>
        <Grid item>
            <div className={props.classes.thumb}>
                <img src={props.imageUrl} alt="payment-method" />
            </div>
        </Grid>
        <Grid item xs>
            <Typography variant="body1">Mastercard **** 6788</Typography>
            <Typography variant="caption">Expires 10/22</Typography>
        </Grid>
    </Grid>
);

PaymentInfo.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    classes: PropTypes.shape({
        thumb: PropTypes.string.isRequired,
    }).isRequired,
};

export default withStyles(styles)(PaymentInfo);
