import React from 'react';
import PropTypes from 'prop-types';
import GridListTile from '@material-ui/core/GridListTile';
import { Link } from 'react-router-dom';
import some from 'lodash/some';
import DeleteIconComponent from '../DeleteIconComponent';

const style = {
    cursor: 'default',
    overflow: 'inherit',
    listStyle: 'none',
};

const ImageGrid = props => (
    <GridListTile
        className="gridTile"
        onClick={() => props.selectImage(props.image)}
        actionIcon={
            <DeleteIconComponent
                deleteCallBack={props.deleteClick}
                disabled={!some(props.image.attributes, 'value')}
                disabledText="Unable to delete this image."
                color="#ffffff"
            />
        }
        actionPosition="right"
        titlePosition="top"
        titleBackground="linear-gradient(to bottom, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0.3) 70%,rgba(0,0,0,0) 100%)"
        title=" "
        style={style}
    >
        <Link
            className="cursor-pointer"
            to={{
                pathname: `/projects/${props.image.projectId}/images/${
                    props.image.id
                }`,
            }}
            href={`/projects/${props.image.projectId}/images/${props.image.id}`}
        >
            <img
                className="gridTileImage"
                src={`${props.image.imageUrl}?cacheBuster9000=${Date.now()}`}
                alt="Project"
            />
        </Link>
    </GridListTile>
);

const ImageGridShape = {
    imageUrl: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
};

ImageGrid.propTypes = {
    image: PropTypes.shape(ImageGridShape).isRequired,
    selectImage: PropTypes.func.isRequired,
    deleteClick: PropTypes.func.isRequired,
};

export default ImageGrid;
