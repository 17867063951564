class EditEstimateItemModel {
    id;
    description;
    materialItemId;
    units;
    unitType;
    coveragePerUnit;
    laborCost;
    materialCost;
    subItemMaterialCost;
    materialOrderDescription;
    hideOnEstimate;
    hideOnContract;
    hideOnMaterialOrder;
    hideOnLaborReport;
    totalPerUnit;
    version;
    unitsVisible;
    unitsEnabled;
    canEditItem;
    laborCostVisible;
    materialCostVisible;
    estimateId;
    colorOptionsCount;
}

export default EditEstimateItemModel;
