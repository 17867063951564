import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';

const style = theme => ({
    card: {
        padding: 20,
    },
    formControl: {
        margin: theme.spacing(1),
        marginLeft: 0,
    },
    resetPasswordButton: {
        width: '100%',
    },
});

class ForgotPasswordForm extends Component {
    state = {
        email: '',
        emailIsValid: true,
    }

    handleEmailInputChange = (event) => {
        const newEmailValue = event.target.value;
        this.setState({ email: newEmailValue, emailIsValid: true });
    };

    requestResetPassword = () => {
        const emailIsValid = (this.state.email);

        this.setState({ emailIsValid });

        if (!emailIsValid) {
            return;
        }

        this.props.onResetPasswordClick(this.state.email);
    };

    render() {
        return (
            <Card className={this.props.classes.card}>
                <CardHeader
                    title="Reset Password"
                    subheader="Use this form to reset your password"
                />
                <CardContent>
                    <FormControl fullWidth className={this.props.classes.formControl} error={!this.state.emailIsValid}>
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <Input
                            id="email"
                            type="text"
                            value={this.state.email}
                            onChange={this.handleEmailInputChange}
                        />
                        {
                            !this.state.emailIsValid &&
                                <FormHelperText id="emailErrorText">Email is required</FormHelperText>
                        }
                    </FormControl>
                </CardContent>
                <CardActions>
                    <Button
                        id="resetPassword"
                        variant="contained"
                        color="primary"
                        className={this.props.classes.resetPasswordButton}
                        onClick={this.requestResetPassword}
                    >
                        Reset Password
                    </Button>
                </CardActions>
            </Card>
        );
    }
}

ForgotPasswordForm.propTypes = {
    classes: PropTypes.shape({
        card: PropTypes.string.isRequired,
        formControl: PropTypes.string.isRequired,
        resetPasswordButton: PropTypes.string.isRequired,
    }).isRequired,
    onResetPasswordClick: PropTypes.func.isRequired,
};

export default withStyles(style)(ForgotPasswordForm);
