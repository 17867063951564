import GenericApiClient, {
    ApiHeadersMap,
} from '../../lib/apiClients/genericApiClient';

import { DateTime } from 'luxon';
import LoggerWrapper from 'lib/Logger';
interface Schedule {
    startTime: string;
    endTime: string;
}

interface ScheduleApiResponse {
    currentServerTime: string;
    schedule: Schedule[];
}

const SCHEDULING_API_BASE_URL = `${process.env.REACT_APP_SKETCHOS_SCHEDULING_API}`;

const sketchOsSchedulingApi = (endpoint: string = '', useAuth = true) => {
    const client = new GenericApiClient(SCHEDULING_API_BASE_URL, useAuth);

    return {
        get: <TResponse>(headers?: ApiHeadersMap) =>
            client.get<TResponse>(endpoint, headers),
        post: <TResponse>(body: any, headers?: ApiHeadersMap) =>
            client.post<BodyInit, TResponse>(endpoint, body, headers),
        put: <TResponse>(body: any, headers?: ApiHeadersMap) =>
            client.put<BodyInit, TResponse>(endpoint, body, headers),
    };
};

export const sketchTechAvailability = async (
    override?: boolean | undefined
) => {
    if (override) return override;

    const isAvailable = await isBusinessHours();

    if (isAvailable) return true;

    return false;
};

export const getTodaysSchedule = async () => {
    const schedulingApi = sketchOsSchedulingApi();
    try {
        const response = await schedulingApi.get<ScheduleApiResponse>();
        const currentServerTime = response.currentServerTime;
        const todaysSchedule = response.schedule.find((s) => {
            return (
                s.startTime.substring(0, 10) ===
                currentServerTime.substring(0, 10)
            );
        });

        const startTime = todaysSchedule?.startTime;
        const endTime = todaysSchedule?.endTime;
        return todaysSchedule && { startTime, endTime, currentServerTime };
    } catch (error) {
        LoggerWrapper.log('Error getting todays schedule', error);
        return null;
    }
};

export const isBusinessHours = async () => {
    const schedulingApi = sketchOsSchedulingApi();

    const response = await schedulingApi.get<ScheduleApiResponse>();

    // TODO: I'm not sure how I feel about doing this here in the web app.
    // Should the azure function just return a boolean along with the schedule?
    // I just don't like that I have to convert startTime twice to accomplish this.
    // I could also spend time rewriting this as a loop or something so it converts
    // and returns the object we need from the get go. Just thought that might
    // look more complex and be harder to maintain. -JN
    const currentServerTime = DateTime.fromISO(response.currentServerTime);

    const todaysSchedule = response.schedule.find((s) => {
        const startDateTime = DateTime.fromISO(s.startTime);

        const result = currentServerTime.hasSame(startDateTime, 'day');

        return result;
    });

    return (
        todaysSchedule &&
        currentServerTime >= DateTime.fromISO(todaysSchedule.startTime) &&
        currentServerTime < DateTime.fromISO(todaysSchedule.endTime)
    );
};
