import { connect } from 'react-redux';
import ProjectBoard from './ProjectBoard';
import { projectRealm } from '../../actions/Projects';
import { putProjectDetails, createProject, deleteProjectById } from '../../actions/ProjectActions';
import {
    getOrganizationOffices,
    handleMissingOffices,
} from '../../actions/Offices';
import { toggleToolbar } from '../../actions/RoofSnapPageToolbarActions';
import RoofSnapApiClient from '../../middleware/roofSnapApiClient';

const getProjectsInformation = async (realm, orgId) => {
    const roofSnapApi = new RoofSnapApiClient();
    const response = await roofSnapApi.callApi(
        `${realm}/${orgId}/projects`,
        'GET',
    );

    return response.resourceList;
};


const mapStateToProps = state => ({
    organizationId: state.currentUser.organizationId,
    offices: state.organizationOffices.data,
    userName: state.currentUser.userName,
});

const mapDispatchToProps = dispatch => ({
    getProjects: orgId =>
        getProjectsInformation(projectRealm.Organization, orgId),
    createProject: project => dispatch(createProject(project)),
    deleteProjectById: id => dispatch(deleteProjectById(id)),
    getOrganizationOffices: orgId =>
        dispatch(getOrganizationOffices(orgId, null, true)),
    updateProjectStatus: project => dispatch(putProjectDetails(project)),
    handleMissingOffices,
    toggleToolbar: () => dispatch(toggleToolbar()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProjectBoard);
