import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const RenameEstimateDialog = ({
    estimate, onChange, onSaveClick, onCancelClick, open,
}) => (
    <Dialog
        open={open}
    >
        <DialogTitle>Rename Estimate</DialogTitle>
        <DialogContent>
            <TextField id="name" name="name" value={estimate.name} onChange={onChange} />
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancelClick}>
                Cancel
            </Button>
            <Button onClick={onSaveClick} color="primary">
                Save
            </Button>
        </DialogActions>
    </Dialog>
);

RenameEstimateDialog.propTypes = {
    estimate: PropTypes.shape({
        name: PropTypes.string,
    }).isRequired,
    open: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
};

RenameEstimateDialog.defaultProps = {
    open: false,
};

export default RenameEstimateDialog;
