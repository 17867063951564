import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const style = {
    iframe: {
        width: '100%',
        height: '82vh',
        border: 'none',
    },
};

const Visualizer = ({ currentProject }) => {
    const url = `https://roofsnap-visualizer.azurewebsites.net/?projectid=${currentProject.id}`;

    return (
        <div>
            <iframe title="Visualizer" src={url} style={style.iframe} />
        </div>
    );
};

Visualizer.propTypes = {
    currentProject: PropTypes.shape({
        id: PropTypes.string,
        hasDrawing: PropTypes.bool,
    }).isRequired,
};

Visualizer.defaultProps = {
};

const mapStateToProps = (state) => {
    const { projectHome } = state;
    const { currentProject } = projectHome;

    return { currentProject };
};

export default connect(mapStateToProps)(Visualizer);
