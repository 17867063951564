export const OPEN_SEARCH_APP_BAR = 'OPEN_SEARCH_APP_BAR';
export const CLOSE_SEARCH_APP_BAR = 'CLOSE_SEARCH_APP_BAR';

export const openSearchAppBar = () => ({
    type: OPEN_SEARCH_APP_BAR,
});

export const closeSearchAppBar = () => ({
    type: CLOSE_SEARCH_APP_BAR,
});
