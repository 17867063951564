import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import DocumentTemplateSelection from '../DocumentTemplateSelection';

const styles = theme => ({
    dialog: {
        maxWidth: 415,
    },
    dialogMobile: {
        minWidth: 0,
    },
    title: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(5),
    },
});

const CreateDocumentDialog = (props) => {
    const {
        open,
        fullScreen,
        documentTemplateCategories,
        onDocumentTemplateClick,
        onDismissClick,
        disabledDocumentDataContextTypes,
        currentUserRoleIds,
        officeId,
        classes,
        subscriptionExpired,
    } = props;

    return (
        <Dialog
            open={open}
            fullScreen={fullScreen}
            classes={{
                paper: classes.dialog,
                paperFullScreen: classes.dialogMobile,
            }}
            id="chooseDocumentDialog"
        >
            <DialogTitle className={classes.title}>
                Choose Document
            </DialogTitle>
            <DialogContent>
                <DocumentTemplateSelection
                    documentTemplateCategories={documentTemplateCategories}
                    onDocumentTemplateClick={onDocumentTemplateClick}
                    disabledDocumentDataContextTypes={disabledDocumentDataContextTypes}
                    currentUserRoleIds={currentUserRoleIds}
                    officeId={officeId}
                    subscriptionExpired={subscriptionExpired}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onDismissClick}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

CreateDocumentDialog.propTypes = {
    open: PropTypes.bool,
    fullScreen: PropTypes.bool.isRequired,
    documentTemplateCategories: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        templates: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            documentDataContextType: PropTypes.string.isRequired,
            thumbnailUrl: PropTypes.string.isRequired,
        })).isRequired,
    })).isRequired,
    onDocumentTemplateClick: PropTypes.func,
    onDismissClick: PropTypes.func,
    disabledDocumentDataContextTypes: PropTypes.arrayOf(PropTypes.string),
    currentUserRoleIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    officeId: PropTypes.number.isRequired,
    classes: PropTypes.shape({
        dialog: PropTypes.string.isRequired,
        dialogMobile: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired,
    subscriptionExpired: PropTypes.bool,
};

CreateDocumentDialog.defaultProps = {
    open: false,
    onDocumentTemplateClick: () => {},
    onDismissClick: () => {},
    disabledDocumentDataContextTypes: [],
    subscriptionExpired: false,
};

export default withStyles(styles)(withMobileDialog()(CreateDocumentDialog));
