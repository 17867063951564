import DocumentTemplateCategories from 'lib/DocumentTemplateCategories.ts';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import GetApp from '@material-ui/icons/GetApp';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import DeleteIconComponent from '../DeleteIconComponent';
import { getDocument } from '../../actions/documentActions';

const documentListItemStyle = {
    borderBottom: 'solid 1px #dbdbdb',
};

class DocumentListItem extends Component {
    state = {
        completed: 0,
        documentDeleted: false,
    };

    isPolling = false;
    pollRetry = 0;

    openFile = (document) => {
        this.props.onOpenDocumentClick(document);
    };

    downloadFile = async (document) => {
        this.props.onDownloadDocumentClick(document);
    };

    timeToGenerateIsWithinTimeLimit = () => {
        const { createdAt } = this.props.document;
        const documentCreatedDate = new Date(createdAt);
        const timeLimit = moment().subtract(30, 'seconds');
        const withInTimeRange = moment(documentCreatedDate).isAfter(timeLimit);
        return withInTimeRange;
    };

    pollDocument = async () => {
        await this.props.dispatch(
            getDocument(this.props.document.renderingId, this.props.document)
        );
        const diff = this.state.completed === 100 ? -99 : Math.random() * 8;
        this.pollRetry -= 1;
        this.setState({
            completed: Math.min(this.state.completed + diff, 100),
        });
        if (
            this.pollRetry <= 0 ||
            this.props.document.isGenerated ||
            !this.timeToGenerateIsWithinTimeLimit()
        ) {
            this.stopPolling();
        }
    };

    runPolling = () => {
        if (this.pollRetry > 0 && this.polling) {
            this.poll = setInterval(this.pollDocument, 1000);
        }
    };

    stopPolling = () => {
        if (this.poll) {
            clearInterval(this.poll);
            this.poll = null;
        }
        this.polling = false;
        this.pollRetry = 0;
        this.setState({
            completed: 0,
        });
    };

    timeToGenerateIsWithinTimeLimit = () => {
        const { createdAt } = this.props.document;
        const documentCreatedDate = new Date(createdAt);
        const timeLimit = moment().subtract(2, 'minutes');
        const withInTimeRange = moment(documentCreatedDate).isAfter(timeLimit);
        return withInTimeRange;
    };

    handleUngeneratedDocument = () => {
        this.polling = true;
        this.pollRetry = 20;
        this.runPolling();
    };

    handleOnDeleteClick = (document) => {
        this.setState({
            documentDeleted: true,
        });
        this.stopPolling();
        this.props.deleteDocument(document);
    };

    render() {
        const { document } = this.props;

        let fileName = document.name;
        if (
            document?.template?.templateCategoryId ===
                DocumentTemplateCategories.Contract &&
            document.template.hasHtmlForm
        ) {
            fileName = document?.renderingFileName.replace('.pdf', '');
        }
        const withinTimeLimit = this.timeToGenerateIsWithinTimeLimit();
        if (
            !document.isGenerated &&
            withinTimeLimit &&
            !this.poll &&
            !this.state.documentDeleted
        ) {
            this.handleUngeneratedDocument();
        }

        return (
            <div style={documentListItemStyle} className='documentListItem'>
                <ListItem
                    button
                    key={
                        document.renderingId
                            ? document.renderingId
                            : document.id
                    }
                    onClick={() => this.openFile(document)}
                    disabled={!document.isGenerated}
                    name={fileName}
                >
                    <ListItemText
                        primary={fileName}
                        id={
                            document.renderingId
                                ? document.renderingId
                                : document.id
                        }
                    />
                    <ListItemSecondaryAction>
                        <IconButton
                            disabled={!document.isGenerated}
                            onClick={() => this.openFile(document)}
                        >
                            <OpenInNewIcon />
                        </IconButton>
                        <IconButton
                            disabled={!document.isGenerated}
                            onClick={() => this.downloadFile(document)}
                        >
                            <GetApp />
                        </IconButton>
                        <DeleteIconComponent
                            deleteCallBack={() =>
                                this.handleOnDeleteClick(document)
                            }
                        />
                    </ListItemSecondaryAction>
                </ListItem>
                {!document.isGenerated && !withinTimeLimit && (
                    <Typography
                        color='error'
                        style={{
                            marginLeft: 16,
                            marginBottom: 8,
                        }}
                    >
                        Sorry, we had trouble generating this document. Please
                        try again.
                    </Typography>
                )}
                {!document.isGenerated && withinTimeLimit && (
                    <LinearProgress
                        variant='determinate'
                        value={this.state.completed}
                    />
                )}
            </div>
        );
    }
}

const DocumentProp = {
    id: PropTypes.string.isRequired,
    renderingId: PropTypes.string,
    projectId: PropTypes.string.isRequired,
    documentUrl: PropTypes.string,
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    isGenerated: PropTypes.bool.isRequired,
    templateId: PropTypes.string,
};

DocumentListItem.propTypes = {
    dispatch: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    onDownloadDocumentClick: PropTypes.func.isRequired,
    onOpenDocumentClick: PropTypes.func.isRequired,
    document: PropTypes.shape(DocumentProp).isRequired,
};

export default connect(null)(DocumentListItem);
