import React from 'react';
import { Icon, WithStyles, createStyles, withStyles } from '@material-ui/core';

const styles = () => createStyles({
    imageIcon: {
        display: 'flex',
        height: 'inherit',
        width: 'inherit'
      },
      iconRoot: {
        textAlign: 'center'
      }
});

type CommercialIconProps = {} & WithStyles<typeof styles>;

const CommercialIcon = ({ classes }: CommercialIconProps) => (
    <Icon classes={{root: classes.iconRoot}}>
        <img src='/assets/CommercialIcon.svg' alt='Commercial' />
    </Icon>
);

export default withStyles(styles)(CommercialIcon);
