import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const style = {
    card: {
        padding: 20,
        maxWidth: 450,
    },
    completeCheckWrapper: {
        display: 'flex',
    },
    confirmButton: {
        width: '100%',
    },
};

const ForgotPasswordComplete = props => (
    <Card className={props.classes.card}>
        <div className={props.classes.completeCheckWrapper}>
            <CheckCircleIcon
                style={{
                    flex: 1,
                    fill: props.theme.palette.primary['500'],
                    width: 100,
                    height: 100,
                }}
            />
        </div>
        <CardHeader
            title="Done!"
        />
        <CardContent>
            <Typography>
                An email has been sent to your inbox with a link to reset your password.
            </Typography>
        </CardContent>
        <CardActions>
            <Button
                id="confirmButton"
                variant="contained"
                color="primary"
                className={props.classes.confirmButton}
                onClick={props.onConfirmClick}
            >
                RETURN TO LOG IN
            </Button>
        </CardActions>
    </Card>
);

ForgotPasswordComplete.propTypes = {
    classes: PropTypes.shape({
        card: PropTypes.string.isRequired,
        completeCheckWrapper: PropTypes.string.isRequired,
    }).isRequired,
    theme: PropTypes.shape({
        palette: PropTypes.shape({
            primary: PropTypes.shape({
                500: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
};

export default withStyles(style, { withTheme: true })(ForgotPasswordComplete);
