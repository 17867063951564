import {
    PUT_ESTIMATE_ITEM_REQUEST,
    PUT_ESTIMATE_ITEM_SUCCESS,
    PUT_ESTIMATE_ITEM_FAILURE,
    CREATE_ESTIMATE_ITEM_REQUEST,
    CREATE_ESTIMATE_ITEM_SUCCESS,
    CREATE_ESTIMATE_ITEM_FAILURE,
    DELETE_ESTIMATE_ITEM_REQUEST,
    DELETE_ESTIMATE_ITEM_SUCCESS,
    DELETE_ESTIMATE_ITEM_FAILURE,
} from '../actions/ProjectEstimateItemActions';

function projectEstimateItem(
    state = {
        postIsLoading: false,
        editItemLoading: false,
    },
    action,
) {
    switch (action.type) {
    case CREATE_ESTIMATE_ITEM_REQUEST:
        return Object.assign({}, state, {
            postIsLoading: true,
        });
    case CREATE_ESTIMATE_ITEM_SUCCESS:
        return Object.assign({}, state, {
            postIsLoading: false,
        });
    case CREATE_ESTIMATE_ITEM_FAILURE:
        return Object.assign({}, state, {
            postIsLoading: false,
        });
    case PUT_ESTIMATE_ITEM_REQUEST:
        return Object.assign({}, state, {
            editItemLoading: true,
        });
    case PUT_ESTIMATE_ITEM_SUCCESS:
        return Object.assign({}, state, {
            editItemLoading: false,
        });
    case PUT_ESTIMATE_ITEM_FAILURE:
        return Object.assign({}, state, {
            editItemLoading: false,
        });
    case DELETE_ESTIMATE_ITEM_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case DELETE_ESTIMATE_ITEM_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case DELETE_ESTIMATE_ITEM_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
        });
    default:
        return state;
    }
}

export default projectEstimateItem;
