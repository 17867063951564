import { ButtonProps, Button } from '@material-ui/core';
import { Color as AlertColor } from '@material-ui/lab';
import HTMLEditor from 'components/HTMLEditor/HTMLEditor';
import UploadConfiguration from 'components/HTMLEditor/UploadConfiguration';
import useRoofSnapApi from 'hooks/useApiHook';
import React, { useContext, useEffect, useRef, useState } from 'react';
import OfficeContext from '../OfficeContext';
import LocalStorageWrapper from '../../../lib/LocalStorageWrapper';
import SaveSnackBar from '../SaveSnackbar'
import urlFactory from 'url-factory';

interface IContractData {
    contractTerms: string;
    version: string;
}

const failureMessages: { [key: string | number]: string } = {
    get: 'An error occured while getting your contract terms.',
    save: 'An error occured while saving your contract terms.',
    412: 'Your contract terms have been edited since this page was loaded. Please backup your changes, refresh, and try again.',
};

const successMessage: string = 'Your contract terms have been successfully saved.';

const apiFactory = urlFactory(process.env.REACT_APP_API_HOST as string);

const ContractEditor = () => {
    const { office } = useContext(OfficeContext);
    const officeRef = useRef<number>(office.id)

    const versionRef = useRef<string>('');
    const [contractHtml, setContractHtml] = useState<string>('');

    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const ckEditorInstance = useRef<any | null>(null);

    const [snackBarMessage, setSnackBarMessage] = useState<{
        severity: AlertColor;
        message: string;
        retryAction?: JSX.Element;
        autoHideDuration?: number;
    } | null>(null);

    const token = LocalStorageWrapper.getItem('accessToken') || null;
    const uploadConfiguration: UploadConfiguration = {
        uploadUrl: apiFactory(`v1/organizations/${office.organizationId}/uploadimage`),
        headers: {
            'X-Api-Key': `${process.env.REACT_APP_API_KEY}`,
            'Accept': 'application/json',
            'RSClient': 'DeceptiveDolphin',
            'Authorization': `Bearer ${token}`,
        }
    }

    const api = useRoofSnapApi(`v1/offices/${office.id}/contractterms`);

    const getContractAsync = async () => {
        let response: IContractData;

        try {
            response = await api.get();

            return response;
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    };

    const handleSaved = (response: any) => {
        setSnackBarMessage({
            severity: 'success',
            message: successMessage,
            autoHideDuration: 6000,
        });
        setIsAlertOpen(true);
    };

    const handleSaveAsync = async (
        content: string
    ): Promise<IContractData | undefined | null> => {

        if (!versionRef.current) {
            throw new Error('No contract data to save!');
        }

        if (!content) {
            content = '<p></p>';
        }

        const headers = { 'If-Match': `"${versionRef.current}"` };

        const response = await api.put<IContractData>(
            { termsAndConditions: content },
            headers
        );

        versionRef.current = response.version;

        return response;
    };

    const retryCount = useRef(0);

    const handleSaveError = (response: Response) => {
        const { status } = response;
        retryCount.current++;
        if (status === 412) {
            setSnackBarMessage({
                severity: 'error',
                message: failureMessages[412],
            });
            setIsAlertOpen(true);
            retryCount.current = 0;

            return Promise.resolve(response);
        }

        setSnackBarMessage({
            severity: 'error',
            message: failureMessages[status] ?? failureMessages['save'],
            retryAction: retrySaveButton,
        });
        setIsAlertOpen(true);

        if (retryCount.current > 2) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    };

    const handleEditorReady = (ckeditor: any) => {
        ckEditorInstance.current = ckeditor;
    };

    const handleSnackbarClose = (
        event?: React.SyntheticEvent,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsAlertOpen(false);
    };

    useEffect(() => {
        getContractAsync()
            .then(response => {
                versionRef.current = response.version;
                setContractHtml(response.contractTerms);
            })
            .catch(() => {
                setSnackBarMessage({
                    severity: 'error',
                    message: failureMessages['get'],
                    retryAction: retryGetButton,
                });
                setIsAlertOpen(true);
            });
        
        officeRef.current = office.id
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [office.id]);

    const RetryAction = (props: ButtonProps) => (
        <Button
            {...props}
            style={{
                color: 'red',
            }}
            size='small'
        >
            Retry
        </Button>
    );

    const retrySaveButton = (
        <RetryAction
            onClick={() => {
                setIsAlertOpen(false);
                if (ckEditorInstance.current) {
                    const editor: any = ckEditorInstance.current;
                    editor.plugins.get('Autosave').save();
                }
            }}
        />
    );

    const retryGetButton = (
        <RetryAction
            onClick={() => getContractAsync().then(() => setIsAlertOpen(false))}
        />
    );

    return (
        <>
            {(contractHtml && office.id === officeRef.current) && (
                <HTMLEditor
                    onSave={(content) => handleSaveAsync(content)}
                    onSaved={handleSaved}
                    onSaveError={handleSaveError}
                    content={contractHtml}
                    onReady={handleEditorReady}
                    uploadConfiguration={uploadConfiguration}
                />
            )}
            <SaveSnackBar
                isAlertOpen={isAlertOpen}
                handleSnackbarClose={handleSnackbarClose}
                snackBarMessage={snackBarMessage}
            />
        </>
    );
};

export default ContractEditor;
