import {
    REQUEST_RESET_PASSWORD_REQUEST,
    REQUEST_RESET_PASSWORD_SUCCESS,
    REQUEST_RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
} from '../actions/ResetPassword';

function resetPassword(state = {
    isFetching: false,
}, action) {
    switch (action.type) {
    case (REQUEST_RESET_PASSWORD_REQUEST):
        return Object.assign({}, state, {
            isFetching: true,
        });
    case (REQUEST_RESET_PASSWORD_SUCCESS):
        return Object.assign({}, state, {
            isFetching: false,
        });
    case (REQUEST_RESET_PASSWORD_FAILURE):
        return Object.assign({}, state, {
            isFetching: false,
        });
    case (RESET_PASSWORD_REQUEST):
        return Object.assign({}, state, {
            isFetching: true,
        });
    case (RESET_PASSWORD_SUCCESS):
        return Object.assign({}, state, {
            isFetching: false,
        });
    case (RESET_PASSWORD_FAILURE):
        return Object.assign({}, state, {
            isFetching: false,
        });
    default:
        return state;
    }
}

export default resetPassword;
