import * as ActionTypes from '../actions/OrganizationFreeSketchOrdersActions';

const initState = {
    isFetching: false,
    freeSketchOrderBalance: 0,
    freeSketchOrderRate: 0,
    openSketchOrders: 0,
};

const organizationFreeSketchOrdersReducer = (state = initState, action) => {
    switch (action.type) {
    case ActionTypes.GET_ORGANIZATIONS_FREE_SKETCH_ORDERS_RATE_REQUEST:
    case ActionTypes.GET_ORGANIZATIONS_FREE_SKETCH_ORDER_BALANCE_REQUEST:
    case ActionTypes.GET_ORGANIZATIONS_OPEN_SKETCH_ORDERS_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case ActionTypes.GET_ORGANIZATIONS_FREE_SKETCH_ORDER_BALANCE_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            freeSketchOrderBalance: action.response.sketchOrderBalance,
        });
    case ActionTypes.GET_ORGANIZATIONS_FREE_SKETCH_ORDERS_RATE_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            freeSketchOrderRate: action.response.sketchOrderRate,
        });
    case ActionTypes.GET_ORGANIZATIONS_OPEN_SKETCH_ORDERS_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            openSketchOrders: action.response.totalResults,
        });
    case ActionTypes.GET_ORGANIZATIONS_FREE_SKETCH_ORDERS_RATE_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            freeSketchOrderRate: 0,
        });
    case ActionTypes.GET_ORGANIZATIONS_OPEN_SKETCH_ORDERS_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            openSketchOrders: 0,
        });
    case ActionTypes.GET_ORGANIZATIONS_FREE_SKETCH_ORDER_BALANCE_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            freeSketchOrderBalance: 0,
        });
    default:
        return state;
    }
};

export default organizationFreeSketchOrdersReducer;
