import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import some from 'lodash/some';
import GetApp from '@material-ui/icons/GetApp';
import ImageView from './ImageView';
import PageToolbarActions, { ToolbarIconButton, ToolbarDeleteButton } from '../NavigationComponents/PageToolbarActions';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import {
    getImageById,
    showNextImage,
    getProjectImages,
    showPreviousImage,
    deleteImage,
} from '../../actions/ProjectImagesActions';
import { downloadBlobAsFile, convertUrlToHttps } from '../../lib/HelperFunctions';

const styles = {
    imageViewContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
};

class ImageViewContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }
    componentDidMount() {
        this.props.dispatch(getImageById(
            this.props.match.params.id,
            this.props.match.params.imageId,
        ));
    }
    componentWillReceiveProps(nextProps) {
        if (
            nextProps.selectedImage.id !== this.props.selectedImage.id &&
            nextProps.selectedImage.id !== undefined
        ) {
            this.props.history.replace({
                pathname: `/projects/${this.props.match.params.id}/images/${
                    nextProps.selectedImage.id
                }`,
            });
        }
    }

    handleOpenDialog = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleDelete = () => {
        this.props
            .dispatch(deleteImage(this.props.selectedImage))
            .then((response) => {
                if (response.error) {
                    this.handleClose();
                } else {
                    this.props.history.replace({
                        pathname: `/projects/${this.props.match.params.id}/images`,
                        state: { currentPage: this.props.currentPage },
                    });
                    this.props.dispatch(setReduxMessage('The image was successfully deleted'));
                }
            });
    };

    handleArrowLeftClick = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (this.props.images.length === 0) {
            return this.props.dispatch(getProjectImages(
                this.props.match.params.id,
                this.props.currentPage,
            ));
        }
        return this.props.dispatch(showPreviousImage());
    };

    handleArrowRightClick = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (this.props.images.length === 0) {
            return this.props.dispatch(getProjectImages(
                this.props.match.params.id,
                this.props.currentPage,
            ));
        }
        return this.props.dispatch(showNextImage());
    };

    handleKeyClick = (e) => {
        if (e.keyCode === 37) {
            this.handleArrowLeftClick(e);
        } else if (e.keyCode === 39) {
            this.handleArrowRightClick(e);
        } else if (e.keyCode === 27) {
            this.handleBackArrowClick(e);
        }
    };

    handleBackArrowClick = (e) => {
        e.stopPropagation();
        this.props.history.replace({
            pathname: `/projects/${this.props.match.params.id}/images`,
            state: { currentPage: this.props.currentPage },
        });
    };

    handleDownloadClick = async () => {
        const httpsUrl = convertUrlToHttps(this.props.selectedImage.imageUrl);
        const response = await fetch(httpsUrl);

        if (!response.ok) {
            this.props.dispatch(setReduxMessage(<span>Sorry, the image failed to download. Please try again or contact support at <a href="mailto:support@roofsnap.com" style={{ color: 'white' }}>support@roofsnap.com.</a></span>));
            return;
        }

        const body = await response.blob();
        downloadBlobAsFile(body, this.props.selectedImage.id);
    };

    render() {
        return (
            <div
                style={styles.imageViewContainer}
                onKeyDown={this.handleKeyClick}
                role="button"
                tabIndex={0}
            >
                <PageToolbarActions triggers={[this.props.selectedImage.id]}>
                    <ToolbarIconButton
                        icon={<GetApp />}
                        onClick={this.handleDownloadClick}
                        tooltip="Download Image"
                    />
                    <ToolbarDeleteButton
                        onClick={this.handleOpenDialog}
                        tooltip="Delete Image"
                        disabled={!some(this.props.selectedImage.attributes, 'value')}
                        disabledTooltip="You can not delete images that are used by sketch"
                    />
                </PageToolbarActions>
                <ImageView
                    arrowRight={this.handleArrowRightClick}
                    arrowLeft={this.handleArrowLeftClick}
                    image={this.props.selectedImage}
                />
                <div />
                <ConfirmationModal
                    title="Delete Image"
                    description="Are you sure you want to delete this image?"
                    handleConfirm={this.handleDelete}
                    confirmText="Delete"
                    isOpen={this.state.open}
                    handleClose={this.handleClose}
                />
            </div>
        );
    }
}

const ImageViewContainerProps = {
    imageUrl: PropTypes.string,
    id: PropTypes.string,
    projectId: PropTypes.string,
};

/* eslint-disable react/forbid-prop-types */
ImageViewContainer.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    selectedImage: PropTypes.shape(ImageViewContainerProps),
    images: PropTypes.array,
    dispatch: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
};

ImageViewContainer.defaultProps = {
    selectedImage: {},
    images: [],
};

const mapState = state => ({
    ...state.projectImages,
});

export default connect(mapState)(ImageViewContainer);
