import {
    GET_SKETCH_ORDER_NOTIFICATIONS_REQUEST,
    GET_SKETCH_ORDER_NOTIFICATIONS_SUCCESS,
    GET_SKETCH_ORDER_NOTIFICATIONS_FAILURE,
} from '../actions/sketchOrderNotificationsActions';

const initialState = {
    isFetching: false,
    notifications: [],
    totalResults: 0,
    totalPages: 0,
    currentPage: 1,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_SKETCH_ORDER_NOTIFICATIONS_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case GET_SKETCH_ORDER_NOTIFICATIONS_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            notifications: action.response.resourceList,
            totalResults: action.response.totalResults,
            totalPages: action.response.totalPages,
            currentPage: action.response.page,
        });
    case GET_SKETCH_ORDER_NOTIFICATIONS_FAILURE:
        return Object.assign({}, state, initialState);
    default:
        return state;
    }
};

export default reducer;
