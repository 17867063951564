export const SET_REDUX_MESSAGE = 'SET_REDUX_MESSAGE';
export const HIDE_REDUX_MESSAGE = 'HIDE_REDUX_MESSAGE';

export const setReduxMessage = (message, link) => ({
    type: SET_REDUX_MESSAGE,
    message,
    link,
});

export const hideReduxMessage = () => ({
    type: HIDE_REDUX_MESSAGE,
});
