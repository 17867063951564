import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const FreeSketchOrderIndicator = (props) => {
    const { freeSketchOrderBalance, openSketchOrders } = props;
    const remainingSketchOrders = freeSketchOrderBalance - openSketchOrders;
    return (
        <React.Fragment>
            { (freeSketchOrderBalance > 0 && remainingSketchOrders > 0) &&
                <Typography variant="body1" align="right">{`Free Sketch Orders Remaining: ${remainingSketchOrders}`}</Typography>
            }
        </React.Fragment>
    );
};

FreeSketchOrderIndicator.propTypes = {
    freeSketchOrderBalance: PropTypes.number.isRequired,
    openSketchOrders: PropTypes.number.isRequired,
};

export default FreeSketchOrderIndicator;
