import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import ProjectHome from '../ProjectHome';
import { ProjectHome as ProjectHomeV2 } from '@views';
import ProjectNotes from '../ProjectNotes/ProjectNotes';
import ImageViewContainer from '../ProjectImages/ImageViewContainer';
import ProjectImages from '../ProjectImages';
import ProjectDetails from '../ProjectDetails';
import ProjectEstimates from '../ProjectEstimates';
import Documents from '../Documents';
import DocumentDownload from '../DocumentDownload';
import MeasurementsPage from '../Measurements/MeasurementsPage';
import SketchRoute from '../SketchComponentHost/SketchRoute';
import Estimate from '../Estimate/Estimate';
import VisualizerHost from '../Visualizer/VisualizerHost';
import { getProjectById } from '../../actions/ProjectActions';
import { projectRoutes } from '../../lib/ProjectMenu';
import MapImageryOptionsHost from '../MapImageryOptionsHost';
import ProjectNotFoundDialog from '../ProjectNotFoundDialog';
import ProjectLockedDownDialog from '../ProjectLockedDownDialog';
import ESignatures from '../ESignatures';
import CreateESignature from '../CreateESignature';
import featureFlags from 'lib/FeatureFlags';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RoofSnapState } from 'lib/Models/RoofSnapState';
import { useDispatch } from 'react-redux';

type ProjectProps = {
    history: {
        push: (path: string) => void;
    };
    match: { path: string; params: { id: string } };
};

const Project = ({ history, match }: ProjectProps) => {
    const [openProjectNotFoundDialog, setOpenProjectNotFoundDialog] =
        useState(false);
    const [openProjectLockedDownDialog, setOpenProjectLockedDownDialog] =
        useState(false);
    const dispatch = useDispatch();

    const { currentProject } = useSelector(
        (store: RoofSnapState) => store.project
    );
    const isEasierEstimatesEnabled: boolean = useSelector(
        (store: RoofSnapState) =>
            store.organizationFeatures.enableEasierEstimates
    );

    useEffect(() => {
        if (!currentProject || match.params.id !== currentProject.id) {
            const result: any = dispatch(getProjectById(match.params.id));
            if (result && result.error) {
                setOpenProjectNotFoundDialog(true);
                return;
            }

            if (result.response && result.response.locked) {
                setOpenProjectLockedDownDialog(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.id, dispatch]);

    const handleClose = () => {
        history.push('/projects');
    };

    const { path } = match;

    return (
        <div id='project-home'>
            <Switch>
                {featureFlags.easierEstimates && isEasierEstimatesEnabled ? (
                    <Route
                        exact
                        path='/projects/:projectId'
                        component={ProjectHomeV2}
                    />
                ) : (
                    <Route exact path={`${path}`} component={ProjectHome} />
                )}

                <Route
                    exact
                    path={`${path}/${projectRoutes.Details}`}
                    component={ProjectDetails}
                />
                <ProtectedRoute
                    exact
                    path={`${path}/${projectRoutes.Sketch}`}
                    showMobileWarning
                    component={SketchRoute}
                    location={{ pathname: '', search: '' }}
                    computedMatch={{ params: { id: '' } }}
                />
                <Route
                    exact
                    path={`${path}/${projectRoutes.Measurements}`}
                    component={MeasurementsPage}
                />
                <Route
                    exact
                    path={`${path}/${projectRoutes.ESignatures}`}
                    component={ESignatures}
                />
                <Route
                    exact
                    path={`${path}/${projectRoutes.ESignatures}/create`}
                    component={CreateESignature}
                />
                <Route
                    exact
                    path={`${path}/${projectRoutes.Documents}`}
                    component={Documents}
                />
                <Route
                    exact
                    path={`${path}/${projectRoutes.Documents}/:documentId/:renderingId`}
                    component={DocumentDownload}
                />
                <Route
                    exact
                    path={`${path}/${projectRoutes.Notes}`}
                    component={ProjectNotes}
                />
                <Route
                    exact
                    path={`${path}/${projectRoutes.Imagery}`}
                    component={MapImageryOptionsHost}
                />
                <Route
                    exact
                    path={`${path}/${projectRoutes.Images}`}
                    component={ProjectImages}
                />
                <Route
                    exact
                    path={`${path}/${projectRoutes.Images}/:imageId`}
                    component={ImageViewContainer}
                />
                <ProtectedRoute
                    exact
                    path={`${path}/${projectRoutes.Estimates}`}
                    component={ProjectEstimates}
                    estimatingFeatureRequired
                    location={{ pathname: '', search: '' }}
                    computedMatch={{ params: { id: '' } }}
                />
                <ProtectedRoute
                    exact
                    path={`${path}/${projectRoutes.Estimates}/:estimateId`}
                    // This type was set to "any" until we can convert the "Estimate" component to a functional component.
                    //  app/src/components/Estimate/Estimate.js
                    component={Estimate as any}
                    estimatingFeatureRequired
                    location={{ pathname: '', search: '' }}
                    computedMatch={{ params: { id: '' } }}
                />
                <Route
                    exact
                    path={`${path}/${projectRoutes.Visualizer}`}
                    component={VisualizerHost}
                />
            </Switch>

            <ProjectNotFoundDialog
                open={openProjectNotFoundDialog}
                handleClose={handleClose}
            />

            <ProjectLockedDownDialog
                open={openProjectLockedDownDialog}
                handleClose={handleClose}
            />
        </div>
    );
};

export default Project;
