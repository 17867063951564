import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImageGrid from './ImageGrid';
import ProjectImageImport from './ProjectImageImport';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import Pager from '../Pager/Pager';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';
import {
    getProjectImages,
    selectImage,
    deleteImage,
} from '../../actions/ProjectImagesActions';
import './ProjectImages.css';
import { RoofSnapState } from 'lib/Models/RoofSnapState';
import { useRouteMatch } from 'react-router';
import ProjectImage from 'lib/Models/ProjectImage';

const style = {
    root: {
        padding: 24,
    },
};

const ProjectImages = () => {
    const dispatch = useDispatch();
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
        useState<boolean>(false);

    const match = useRouteMatch<{ id: string }>();


    const {
        projectImages: { currentPage, totalPages, images, selectedImage },
    } = useSelector((state: RoofSnapState) => state);

    const handleDelete = async () => {
        const response = await (dispatch(deleteImage(selectedImage)) as any);

        if (response.error) {
            setDeleteConfirmationOpen(false);
        } else {
            setDeleteConfirmationOpen(false);
            dispatch(setReduxMessage('The image was successfully deleted'));
            dispatch(getProjectImages(match.params.id, currentPage));
        }
    };

    const changePage = (pageNumber: number) => {
        dispatch(getProjectImages(match.params.id, pageNumber));
    };

    useEffect(() => {
        if (!match.params.id) return;

        window.scrollTo(0, 0);
        changePage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match]);

    return (
        <div style={style.root}>
            <Pager
                totalPages={totalPages}
                currentPage={currentPage}
                nextClick={() => changePage(currentPage + 1)}
                prevClick={() => changePage(currentPage - 1)}
            />
            <div className='gridList'>
                {images.length > 0 ? (
                    images.map((item) => (
                        <ImageGrid
                            key={item.id}
                            image={item}
                            selectImage={(image: ProjectImage) => {
                                dispatch(selectImage(image));
                            }}
                            deleteClick={() => {
                                setDeleteConfirmationOpen(true);
                            }}
                        />
                    ))
                ) : (
                    <div>You have no images for this project</div>
                )}
            </div>
            <ConfirmationModal
                title='Delete Image'
                description='Are you sure you want to delete this image?'
                handleConfirm={handleDelete}
                confirmText='Delete'
                isOpen={deleteConfirmationOpen}
                handleClose={() => setDeleteConfirmationOpen(false)}
            />
            <ProjectImageImport projectId={match.params.id} />
        </div>
    );
};

export default ProjectImages;
