import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import NumberFormat from 'react-number-format';
import MoneyInput from '../../InputComponents/MoneyInput';

const style = {
    labelMargin: {
        marginTop: '10px',
    },
};

const EditEstimateItemDialog = props => (
    <div>
        <Dialog
            fullScreen={props.fullScreen}
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">Edit Item</DialogTitle>
            {props.loading && <LinearProgress />}
            <DialogContent>
                <form
                    noValidate
                    onSubmit={props.handleSubmit}
                    autoComplete="off"
                >
                    <TextField
                        id="description"
                        value={props.description ? props.description : ''}
                        onChange={props.handleChange('description')}
                        label="Description"
                        type="text"
                        multiline
                        fullWidth
                        style={style.labelMargin}
                        disabled={!props.canEditItem}
                    />
                    <FormControl
                        style={{ width: '100%', ...style.labelMargin }}
                    >
                        <InputLabel shrink htmlFor="quantity">Quantity</InputLabel>
                        <Input
                            id="units"
                            value={props.units ? props.units : ''}
                            onChange={props.handleChange('units')}
                            inputComponent={NumberFormat}
                            label="Quantity"
                            disabled={!props.unitsEnabled}
                        />
                        {!props.unitsEnabled &&
                            <FormHelperText>
                                To edit this item's quantity, you must first turn on the Override RoofSnap Measurements switch
                            </FormHelperText>
                        }
                    </FormControl>
                    {
                        props.laborCostVisible &&
                        <MoneyInput
                            value={props.laborCost}
                            handleChange={props.handleChange}
                            type="laborCost"
                            label="Labor Cost Per Unit"
                            disabled={!props.canEditItem}
                        />
                    }
                    {
                        props.materialCostVisible &&
                        <MoneyInput
                            value={props.materialCost}
                            handleChange={props.handleChange}
                            type="materialCost"
                            label="Material Cost Per Unit"
                            disabled={!props.canEditItem}
                        />
                    }
                    <MoneyInput
                        value={props.totalPerUnit}
                        handleChange={props.handleChange}
                        type="totalPerUnit"
                        label="Total Price Per Unit"
                        disabled={!props.canEditItem}
                    />
                    <DialogContentText style={style.labelMargin}>
                        <NumberFormat
                            value={props.grossProfitMargin}
                            displayType="text"
                            thousandSeparator
                            decimalScale={2}
                            suffix="%"
                            renderText={value => (
                                <span>
                                    Current Gross Profit Margin: {value}
                                </span>
                            )}
                        />
                    </DialogContentText>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={props.hideOnEstimate}
                                    onChange={props.switchInput('hideOnEstimate')}
                                    disabled={!props.canEditItem}
                                />
                            }
                            label="Show on Estimate"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={props.hideOnContract}
                                    onChange={props.switchInput('hideOnContract')}
                                    disabled={!props.canEditItem}
                                />
                            }
                            label="Show on Contract"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={props.hideOnMaterialOrder}
                                    onChange={props.switchInput('hideOnMaterialOrder')}
                                    disabled={!props.canEditItem}
                                />
                            }
                            label="Show on Material Order"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={props.hideOnLaborReport}
                                    onChange={props.switchInput('hideOnLaborReport')}
                                    disabled={!props.canEditItem}
                                />
                            }
                            label="Show on Labor Report"
                        />
                    </FormGroup>
                    <TextField
                        id="materialOrderDescription"
                        value={props.materialOrderDescription ? props.materialOrderDescription : ''}
                        onChange={props.handleChange('materialOrderDescription')}
                        label="Material Order Description"
                        type="text"
                        style={style.labelMargin}
                        multiline
                        fullWidth
                        disabled={!props.canEditItem}
                    />
                    <FormControl
                        style={{ width: '100%', ...style.labelMargin }}
                    >
                        <InputLabel shrink htmlFor="coveragePerUnit">
                            Coverage
                        </InputLabel>
                        <Input
                            id="coveragePerUnit"
                            value={
                                props.coveragePerUnit
                                    ? props.coveragePerUnit
                                    : ''
                            }
                            onChange={props.handleChange('coveragePerUnit')}
                            inputComponent={NumberFormat}
                            label="Coverage"
                            disabled={!props.canEditItem}
                        />
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Cancel
                </Button>
                <Button color="primary" onClick={props.handleSubmit}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    </div>
);

EditEstimateItemDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    switchInput: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    description: PropTypes.string,
    materialOrderDescription: PropTypes.string,
    hideOnEstimate: PropTypes.bool,
    hideOnContract: PropTypes.bool,
    hideOnLaborReport: PropTypes.bool,
    loading: PropTypes.bool,
    hideOnMaterialOrder: PropTypes.bool,
    grossProfitMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    units: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    laborCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    materialCost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalPerUnit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    coveragePerUnit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    canEditItem: PropTypes.bool,
    unitsEnabled: PropTypes.bool,
    laborCostVisible: PropTypes.bool,
    materialCostVisible: PropTypes.bool,
};
EditEstimateItemDialog.defaultProps = {
    description: '',
    materialOrderDescription: '',
    units: '',
    laborCost: '',
    materialCost: '',
    totalPerUnit: '',
    coveragePerUnit: '',
    grossProfitMargin: 0,
    hideOnEstimate: false,
    hideOnMaterialOrder: false,
    hideOnContract: false,
    hideOnLaborReport: false,
    loading: false,
    canEditItem: false,
    unitsEnabled: false,
    laborCostVisible: false,
    materialCostVisible: false,
};

export default withMobileDialog()(EditEstimateItemDialog);
