import { CALL_API } from '../middleware/api';

export const GET_SUBSCRIPTION_PLANS_REQUEST = 'GET_SUBSCRIPTION_PLANS_REQUEST';
export const GET_SUBSCRIPTION_PLANS_SUCCESS = 'GET_SUBSCRIPTION_PLANS_SUCCESS';
export const GET_SUBSCRIPTION_PLANS_FAILURE = 'GET_SUBSCRIPTION_PLANS_FAILURE';

export const getSubscriptionPlans = () => ({
    [CALL_API]: {
        types: [
            GET_SUBSCRIPTION_PLANS_REQUEST,
            GET_SUBSCRIPTION_PLANS_SUCCESS,
            GET_SUBSCRIPTION_PLANS_FAILURE,
        ],
        endpoint: 'subscriptions/plans',
        apiVersion: 'v2',
    },
});
