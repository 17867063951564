import {
    GET_PROJECT_ESTIMATES_REQUEST,
    GET_PROJECT_ESTIMATES_SUCCESS,
    GET_PROJECT_ESTIMATES_FAILURE,
} from '../actions/ProjectEstimates';

function projectEstimates(state = {
    totalResults: 0,
    totalPages: 0,
    currentPage: 1,
    data: [],
    isFetching: false,
}, action) {
    switch (action.type) {
    case GET_PROJECT_ESTIMATES_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case GET_PROJECT_ESTIMATES_SUCCESS:
        return Object.assign({}, state, {
            totalResults: action.response.totalResults,
            totalPages: action.response.totalPages,
            currentPage: action.response.page,
            data: action.response.resourceList,
            isFetching: false,
        });
    case GET_PROJECT_ESTIMATES_FAILURE:
        return Object.assign({}, state, {
            totalResults: 0,
            totalPages: 0,
            currentPage: 1,
            data: [],
            isFetching: false,
        });
    default:
        return state;
    }
}

export default projectEstimates;
