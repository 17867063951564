import React from 'react';
import { Typography, Button } from '@mui/material';
import { useHistory } from 'react-router';

const OrderToolBar = () => {
    const history = useHistory();
    const onCancel = () => {
        history.push('/orders');
    };
    return (
        <React.Fragment>
            <Button
                sx={{ color: '#222', textTransform: 'none' }}
                variant='text'
                onClick={onCancel}
            >
                Cancel
            </Button>
            <Typography
                variant='h6'
                component='div'
                sx={{ flexGrow: 1, textAlign: 'center', color: '#222' }}
            >
                {'Measurement Order'}
            </Typography>
        </React.Fragment>
    );
};

export default OrderToolBar;
