const UpdatePaymentErrorType = {
    General: 'General',
    CreditCardDeclined: 'CreditCardDeclined',
    CreditCardNotVerified: 'CreditCardNotVerified',
};

export const paymentErrorMessages = {
    [UpdatePaymentErrorType.CreditCardDeclined]: 'Payment method has been declined. Please try a new payment method or contact your financial institution.',
    [UpdatePaymentErrorType.CreditCardNotVerified]: 'Payment method not verified.',
};

export default UpdatePaymentErrorType;
