import findIndex from 'lodash/findIndex';
import {
    GET_PROJECTS_REQUEST,
    GET_PROJECTS_SUCCESS,
    GET_PROJECTS_FAILURE,
} from '../actions/Projects';

const getBoardContractBase = () => ({
    lanes: [
        {
            id: 'NewLead',
            title: 'New Lead',
            cards: [],
        },
        {
            id: 'InspectionScheduled',
            title: 'Inspection Scheduled',
            cards: [],
        },
        {
            id: 'Inspected',
            title: 'Inspected',
            cards: [],
        },
        {
            id: 'AdjustmentScheduled',
            title: 'Adjustment Scheduled',
            cards: [],
        },
        {
            id: 'Approved',
            title: 'Approved',
            cards: [],
        },
        {
            id: 'ReadyToBuild',
            title: 'Ready to Build',
            cards: [],
        },
        {
            id: 'PunchOut',
            title: 'Punch Out',
            cards: [],
        },
        {
            id: 'WorkCompleted',
            title: 'Work Completed',
            cards: [],
        },
        {
            id: 'Dead',
            title: 'Dead',
            cards: [],
        },
        {
            id: 'Closed',
            title: 'Closed',
            cards: [],
        },
    ],
});
const massageProjectData = (projects) => {
    const boardContract = getBoardContractBase();
    projects.forEach((item) => {
        const project = Object.assign({}, item);
        const idx = findIndex(
            boardContract.lanes,
            element => element.id === project.projectStatus,
        );
        if (idx !== -1) {
            project.laneId = boardContract.lanes[idx].id;
            boardContract.lanes[idx].cards.push(project);
        }
    });
    return boardContract;
};

const projectBoard = (
    state = {
        projectBoardData: getBoardContractBase(),
        isFetching: false,
        isFetchingText: '',
    },
    action,
) => {
    switch (action.type) {
    case GET_PROJECTS_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
            isFetchingText: 'Fetching projects...',
        });
    case GET_PROJECTS_SUCCESS:
        return Object.assign({}, state, {
            projectBoardData: massageProjectData(action.response.resourceList),
            isFetching: false,
            isFetchingText: '',
        });
    case GET_PROJECTS_FAILURE:
        return Object.assign({}, state, {
            errorMessage: action.message,
            isFetching: false,
            isFetchingText: '',
        });
    default:
        return state;
    }
};

export default projectBoard;
