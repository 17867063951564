import { getCurrentUser } from './User';
import { getOrganization } from './Organization';
import { getOrganizationFeatures } from './OrganizationFeatures';
import { getSketchOrderPricing } from './SketchOrderPricingActions';
import { getCurrentUserRoles } from './UserRoles';

export const APP_INIT_REQUEST = 'APP_INIT_REQUEST';
export const APP_INIT_SUCCESS = 'APP_INIT_SUCCESS';
export const APP_INIT_FAILURE = 'APP_INIT_FAILURE';

const requestAppInit = () => ({
    type: APP_INIT_REQUEST,
});

const receiveAppInit = () => ({
    type: APP_INIT_SUCCESS,
});

const rejectAppInit = error => ({
    type: APP_INIT_FAILURE,
    error,
});

export const appInitialize = (requestInBackground = false) => async (dispatch) => {
    dispatch(requestAppInit());
    try {
        await Promise.all([
            dispatch(getCurrentUser(requestInBackground)),
            dispatch(getCurrentUserRoles(requestInBackground)),
            dispatch(getOrganization(requestInBackground)),
            dispatch(getOrganizationFeatures(requestInBackground)),
            dispatch(getSketchOrderPricing(requestInBackground)),
        ]);
        dispatch(receiveAppInit());
    } catch (error) {
        dispatch(rejectAppInit(error));
    }
};

export const SET_APP_REF = 'SET_APP_REF';
export const setAppRef = ref => ({
    type: SET_APP_REF,
    ref,
});
