import SketchOrderNotificationsDrawer from './SketchOrderNotificationsDrawer';

export const drawerProps = {
    variant: 'persistent',
    anchor: 'right',
};
export const notificationsDrawerWidth = 300;
export const notificationsDrawerToolbarWidth = 65;
export const drawerHeaderHeight = 120;
export default SketchOrderNotificationsDrawer;
