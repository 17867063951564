import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';

class SettingsPageBreadcrumb extends Component {
    settingsPage = () => (
        <li>
            <Grid container direction="row">
                <Button style={{ paddingTop: '4px' }} color="primary" onClick={() => { this.props.history.push('/settings'); }}>
                    <div><span>SETTINGS</span></div>
                </Button>
            </Grid>
        </li>
    );

    render() {
        const { path } = this.props.match;
        const crumbs = [
            <Route key="0" path={`${path}`} component={this.settingsPage} />,
        ];
        return (
            crumbs
        );
    }
}

SettingsPageBreadcrumb.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }).isRequired,
};

export default withRouter(SettingsPageBreadcrumb);
