import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import {
    red,
    lightBlue,
    green,
} from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const AlertVariants = {
    info: 'info',
    success: 'success',
    error: 'error',
};

const getAlertData = (variant) => {
    const textColor = '#FFFFFF';
    const iconStyle = {
        color: textColor,
        width: 45,
        height: 45,
    };

    switch (variant) {
    case AlertVariants.info:
        return {
            backgroundColor: lightBlue[500],
            icon: <InfoIcon style={iconStyle} />,
            textColor,
        };
    case AlertVariants.success:
        return {
            backgroundColor: green[500],
            icon: <CheckCircleIcon style={iconStyle} />,
            textColor,
        };
    case AlertVariants.error:
        return {
            backgroundColor: red[500],
            icon: <WarningIcon style={iconStyle} />,
            textColor,
        };
    default:
        return {
            backgroundColor: null,
            icon: null,
            textColor: null,
        };
    }
};

const Alert = (props) => {
    const alertData = getAlertData(props.variant);

    return (
        props.open &&
        <Card
            onClick={props.onClick}
            style={{
                maxWidth: 900,
                marginBottom: 15,
                marginLeft: 'auto',
                marginRight: 'auto',
                backgroundColor: alertData.backgroundColor,
                ...props.style.root,
            }}
            elevation={0}
        >
            <CardHeader
                avatar={
                    props.hasIcon &&
                    (props.icon || alertData.icon)
                }
                action={
                    props.dismissable &&
                    <IconButton
                        onClick={props.onDismiss}
                    >
                        <CloseIcon
                            style={{
                                color: (props.variant) ? '#FFFFFF' : null,
                            }}
                        />
                    </IconButton>
                }
                title={props.title}
                subheader={props.message}
                titleTypographyProps={{
                    variant: 'title',
                    style: {
                        color: alertData.textColor,
                    },
                    ...props.titleTypographyProps,
                }}
                subheaderTypographyProps={{
                    style: {
                        color: alertData.textColor,
                    },
                    ...props.subheaderTypographyProps,
                }}
            />
            {props.content}
        </Card>
    );
};

Alert.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    icon: PropTypes.node,
    hasIcon: PropTypes.bool,
    variant: PropTypes.oneOf(Object.keys(AlertVariants)),
    open: PropTypes.bool,
    dismissable: PropTypes.bool,
    onClick: PropTypes.func,
    onDismiss: PropTypes.func,
    content: PropTypes.node,
    titleTypographyProps: PropTypes.shape({
        variant: PropTypes.string,
        className: PropTypes.string,
    }),
    subheaderTypographyProps: PropTypes.shape({
        className: PropTypes.string,
    }),
    style: PropTypes.shape({
        root: PropTypes.shape({}),
    }),
};

Alert.defaultProps = {
    message: '',
    icon: null,
    hasIcon: true,
    variant: null,
    open: false,
    dismissable: false,
    onClick: () => {},
    onDismiss: () => {},
    content: '',
    titleTypographyProps: {},
    subheaderTypographyProps: {},
    style: {},
};

export default Alert;
