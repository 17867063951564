import React from 'react';
import { Box, Typography } from '@mui/material';
import SectionTitle from './SectionTitle';
import SectionContainer from './SectionContainer';

export const PaymentSection = () => {
    return (
        <>
            <Box mb={1.5}>
                <SectionTitle title='Payments' />
            </Box>
            <SectionContainer>
                <Typography>Payments</Typography>
            </SectionContainer>
        </>
    );
};
