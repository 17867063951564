import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';

const SubscriptionBillingLockedDownMessage = (props) => {
    const message = () => {
        const { organization, currentUserRoles } = props;
        const nonAccountAdminMessage = "Please contact your account administrator to unlock the ability to bill sketch orders to your organization's subscription.";
        // If the organization is a free account, they can not select their sketch orders to be billed to their subscriptions card
        if (organization.isFreeAccount === true) {
            return (
                <React.Fragment>
                    <a href={`${process.env.REACT_APP_ROOFSNAP_ONLINE_HOST}/Account`} target="_blank" rel="noopener noreferrer">Click here</a> to upgrade to a paid subscription to unlock the ability to bill Sketch Orders to your subscription&#39;s payment method.
                </React.Fragment>
            );
        }

        // If their add ons are disabled, then they can not use their subscription's card
        if (organization.addOnsDisabled === true) {
            return (currentUserRoles.isAccountAdmin) ?
                "Billing sketch orders to your subscription is currently disabled. This may be due to a problem with your subscription's payment method. Contact support at (877) 766-3762 or email support@roofsnap.com." :
                nonAccountAdminMessage;
        }

        // If the user does not have sketch os enabled, then they can not bill to their subscription's card
        if (currentUserRoles.sketchosEnabled === false) {
            return nonAccountAdminMessage;
        }

        return null;
    };

    return (
        <Typography
            paragraph
            align="center"
            variant="subtitle1"
        >
            {message()}
        </Typography>
    );
};

SubscriptionBillingLockedDownMessage.propTypes = {
    organization: PropTypes.shape({
        isFreeAccount: PropTypes.bool,
        addOnsDisabled: PropTypes.bool,
    }).isRequired,
    currentUserRoles: PropTypes.shape({
        isAccountAdmin: PropTypes.bool,
        isSketchosEnabled: PropTypes.bool,
    }).isRequired,
};

const mapStateToProps = state => ({
    features: state.features,
});

export default connect(mapStateToProps)(SubscriptionBillingLockedDownMessage);
