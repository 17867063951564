import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import ConfirmationModal from '../ConfirmationModal';

class OverrideMeasurementsSwitch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmationModalIsOpen: false,
            title: '',
            description: '',
        };
    }

    openConfirmationModal = () => {
        if (this.props.checked) {
            this.setState({
                confirmationModalIsOpen: true,
                title: 'Connect measurements to the roof sketch?',
                description: 'This will update your measurements based on the current sketch.',
            });
        } else {
            this.setState({
                confirmationModalIsOpen: true,
                title: 'Adjust measurements independent of the roof sketch?',
                description: 'Further changes to your sketch will not update the measurements.',
            });
        }
    };
    handleDismissConfirmationModal = () => {
        this.setState({ confirmationModalIsOpen: false });
    };

    handleConfirmFromComponent = () => {
        this.handleDismissConfirmationModal();
        this.props.handleConfirm();
    }

    render() {
        return (
            <div>
                <Tooltip title={this.props.toolTip}>
                    <FormControlLabel
                        key="override"
                        control={
                            <Switch
                                checked={this.props.checked}
                                onChange={this.openConfirmationModal}
                                disabled={this.props.disabled}
                            />
                        }
                        label="Override"
                        disabled={this.props.disabled}
                    />
                </Tooltip>
                <ConfirmationModal
                    title={this.state.title}
                    description={this.state.description}
                    handleConfirm={this.handleConfirmFromComponent}
                    confirmText="OK"
                    isOpen={this.state.confirmationModalIsOpen}
                    handleClose={this.handleDismissConfirmationModal}
                />
            </div>
        );
    }
}

OverrideMeasurementsSwitch.propTypes = {
    checked: PropTypes.bool.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    toolTip: PropTypes.string,
};

OverrideMeasurementsSwitch.defaultProps = {
    disabled: false,
    toolTip: 'Override sketch measurements',
};


export default OverrideMeasurementsSwitch;
