import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const style = () => ({
    openInNewIcon: {
        marginLeft: 10,
    },
});

export const ProjectLockedDownDialog = (props) => {
    const goToSubscriptionManagementPage = () => {
        window.open(`${process.env.REACT_APP_ROOFSNAP_ONLINE_HOST}/Account`, '_blank');
        props.handleClose();
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            ignoreBackdropClick
            ignoreEscapeKeyUp
        >
            <DialogTitle id="alert-dialog-title">Unable to Access Project</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This project can only be accessed if you have a paid subscription. Click the Upgrade Subscription button
                    to access this project. Or you can click the Go To Projects button to return to the projects page.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    id="go-to-projects-button"
                    onClick={props.handleClose}
                >
                    Go to Projects
                </Button>
                <Button
                    id="upgrade-subscription-button"
                    onClick={goToSubscriptionManagementPage}
                    variant="contained"
                    color="secondary"
                >
                    Upgrade Subscription
                    <OpenInNewIcon className={props.classes.openInNewIcon} />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ProjectLockedDownDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    classes: PropTypes.shape({
        openInNewIcon: PropTypes.string.isRequired,
    }).isRequired,
};

ProjectLockedDownDialog.defaultProps = {
    open: false,
};

export default withStyles(style)(ProjectLockedDownDialog);
