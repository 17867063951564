import { SET_DESIRED_ROUTE } from '../actions/Routes';

function routes(state = {
    route: '',
}, action) {
    switch (action.type) {
    case SET_DESIRED_ROUTE:
        return Object.assign({}, state, {
            route: action.route,
        });
    default:
        return state;
    }
}

export default routes;
