import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { NavigateBefore } from '@material-ui/icons';

class PaygoPricingPageCrumb extends Component {
    paygoPricingPage = () => (
        <li>
            <Button id="backBreadcrumb" style={{ verticalAlign: 'text-top', lineHeight: '4px' }} color="primary" onClick={() => { this.props.history.push('/'); }}>
                <div><NavigateBefore /></div>
            </Button>
            <Button style={{ paddingTop: '4px' }} color="primary" onClick={() => { this.props.history.push('/pricing'); }}>
                <div><span>PLANS & PRICING</span></div>
            </Button>
        </li>
    );

    render() {
        const { path } = this.props.match;
        const crumbs = [
            <Route key="0" path={`${path}`} component={this.paygoPricingPage} />,
        ];
        return (
            crumbs
        );
    }
}

PaygoPricingPageCrumb.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }).isRequired,
};

PaygoPricingPageCrumb.defaultProps = {
};

const mapStateToProps = (state) => {
    const { id, projectName, requestedDate } = state.sketchOrder;

    return {
        sketchOrderId: id,
        projectName,
        requestedDate,
    };
};

export default withRouter(connect(mapStateToProps)(PaygoPricingPageCrumb));
