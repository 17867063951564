import React from 'react';
import PropTypes from 'prop-types';
import {
    lightBlue,
    amber,
    green,
    grey,
    red,
} from '@material-ui/core/colors';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import WarningIcon from '@material-ui/icons/Warning';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import SketchOrderStatus from '../../lib/SketchOrderStatus.ts';
import ChipWrapper from '../../components/ChipWrapper';

const style = {
    pendingChipStyle: {
        backgroundColor: lightBlue[500],
        color: '#FFFFFF',
    },
    inProgressChipStyle: {
        backgroundColor: amber[500],
        color: '#FFFFFF',
    },
    completeChipStyle: {
        backgroundColor: green[500],
        color: '#FFFFFF',
    },
    inactiveChipStyle: {
        backgroundColor: grey[500],
        color: '#FFFFFF',
    },
    warningChipStyle: {
        backgroundColor: red[500],
        color: '#FFFFFF',
    },
};

const SketchOrderStatusChip = (props) => {
    switch (props.status) {
    case SketchOrderStatus.Pending:
        return (
            <ChipWrapper
                label="Pending"
                icon={ScheduleIcon}
                style={style.pendingChipStyle}
                classes={props.classes}
            />
        );
    case SketchOrderStatus.RevisionRequested:
        return (
            <ChipWrapper
                label="Revising"
                icon={AutorenewIcon}
                style={style.pendingChipStyle}
                classes={props.classes}
            />
        );
    case SketchOrderStatus.InProgress:
    case SketchOrderStatus.PendingReview:
    case SketchOrderStatus.PendingRejection:
    case SketchOrderStatus.Queued:
    case SketchOrderStatus.Exception:
    case SketchOrderStatus.InReview:
    case SketchOrderStatus.PendingGroupBilling:
        return (
            <ChipWrapper
                label="In Progress"
                icon={TimelapseIcon}
                style={style.inProgressChipStyle}
                classes={props.classes}
            />
        );
    case SketchOrderStatus.Complete:
        return (
            <ChipWrapper
                label="Complete"
                icon={CheckCircleIcon}
                style={style.completeChipStyle}
                classes={props.classes}
            />
        );
    case SketchOrderStatus.Incomplete:
        return (
            <ChipWrapper
                label="Incomplete"
                icon={RemoveCircleIcon}
                style={style.inactiveChipStyle}
                classes={props.classes}
            />
        );
    case SketchOrderStatus.Cancelled:
        return (
            <ChipWrapper
                label="Cancelled"
                icon={CancelIcon}
                style={style.inactiveChipStyle}
                classes={props.classes}
            />
        );
    case SketchOrderStatus.Billed:
        return (
            <ChipWrapper
                label="Billed"
                icon={CheckCircleIcon}
                style={style.completeChipStyle}
                classes={props.classes}
            />
        );
    case SketchOrderStatus.BillingFailed:
        return (
            <ChipWrapper
                label="Billing failed"
                style={style.warningChipStyle}
                icon={WarningIcon}
                classes={props.classes}
            />
        );
    default:
        return (
            <ChipWrapper
                label={props.status}
                icon={CheckCircleIcon}
                style={style.inactiveChipStyle}
                classes={props.classes}
            />
        );
    }
};

SketchOrderStatusChip.propTypes = {
    status: PropTypes.string.isRequired,
    classes: PropTypes.shape({}),
};

SketchOrderStatusChip.defaultProps = {
    classes: {},
};

export default SketchOrderStatusChip;
