import React, { Component } from 'react';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    setPageToolbarButtons,
    resetPageToolbar,
} from '../../../actions/RoofSnapPageToolbarActions';
import { createEstimateItem } from '../../../actions/ProjectEstimateItemActions';
import { getProjectEstimateItems } from '../../../actions/ProjectEstimateItemsActions';
import { getProjectEstimate } from '../../../actions/ProjectEstimate';
import CustomItemDialog from './CustomItemDialog';
import MarkupDialog from './MarkupDialog';

const customItemsConstants = {
    customItem: {
        dropDownText: 'Add Custom Item',
        itemType: 0,
    },
    markup: {
        dropDownText: 'Add Markup',
        itemType: 3,
        title: 'Markup',
        percentLabel: 'Markup %',
    },
    discount: {
        dropDownText: 'Add Discount',
        itemType: 1,
        title: 'Discount',
        percentLabel: 'Discount %',
    },
};

class CustomItems extends Component {
    state = {
        anchorEl: null,
        open: false,
        customDialogOpen: false,
        markupDialogOpen: false,
        discountDialogOpen: false,
        markupTitle: '',
        percentLabel: '',
        itemType: '',
        markupFields: {
            markupDescription: '',
            percentMarkUp: '',
        },
        customItemFields: {
            customItemDescription: '',
            customUnitPrice: '',
            customQuantity: '',
        },
        disabledCustomAdd: true,
        customTotal: 0,
    };
    componentDidMount() {
        this.props.dispatch(setPageToolbarButtons([
            {
                buttonText: customItemsConstants.customItem.dropDownText,
                buttonAction: this.openCustomItemDialog,
            },
            {
                buttonText: customItemsConstants.markup.dropDownText,
                buttonAction: this.openMarkupDialog,
            },
            {
                buttonText: customItemsConstants.discount.dropDownText,
                buttonAction: this.openDiscountDialog,
            },
        ]));
    }

    componentWillUnmount() {
        this.props.dispatch(resetPageToolbar());
    }

    openCustomItemDialog = () => {
        this.setState({ customDialogOpen: true });
    };

    openMarkupDialog = () => {
        this.setState({
            markupDialogOpen: true,
            markupTitle: customItemsConstants.markup.title,
            itemType: customItemsConstants.markup.itemType,
            percentLabel: customItemsConstants.markup.percentLabel,
        });
    };

    openDiscountDialog = () => {
        this.setState({
            markupDialogOpen: true,
            markupTitle: customItemsConstants.discount.title,
            itemType: customItemsConstants.discount.itemType,
            percentLabel: customItemsConstants.discount.percentLabel,
        });
    };

    handleDialogChange = (type, name) => (event) => {
        this.setState(
            {
                [type]: Object.assign({}, this.state[type], {
                    [name]: event.target.value,
                }),
            },
            () => {
                if (name === 'customUnitPrice' || name === 'customQuantity') {
                    this.calculateTotal(
                        this.state.customItemFields.customQuantity,
                        this.state.customItemFields.customUnitPrice,
                    );
                }
                this.areEmpty(this.state[type]);
            },
        );
    };

    calculateTotal = (customQuantity = '0', customUnitPrice = '0') => {
        const total =
            parseFloat(customQuantity) * this.parseCurrency(customUnitPrice);
        this.setState({
            customTotal: Number.isNaN(total) ? 0 : total,
        });
    };

    parseCurrency = currency =>
        (currency.indexOf('$') > -1
            ? parseFloat(currency.slice(1, currency.length).replace(/,/g, ''))
            : parseFloat(currency.replace(/,/g, '')));

    handleDialogAdd = (itemType) => {
        let submitObject;
        if (itemType === customItemsConstants.customItem.itemType) {
            submitObject = {
                Description: this.state.customItemFields.customItemDescription,
                Units: parseFloat(this.state.customItemFields.customQuantity),
                TotalPerUnit: this.parseCurrency(this.state.customItemFields.customUnitPrice),
                Total: this.state.customTotal,
                ItemType: itemType,
                UnitType: 4,
                CoveragePerUnit: 1,
                MaterialItemId: 0,
            };
        } else {
            submitObject = {
                Description: this.state.markupFields.markupDescription,
                ItemType: itemType,
                Total: parseFloat(this.state.markupFields.percentMarkUp),
                TotalPerUnit: parseFloat(this.state.markupFields.percentMarkUp),
                UnitType: 4,
                CoveragePerUnit: 1,
                MaterialItemId: 0,
                Units: 1,
            };
        }

        this.props
            .dispatch(createEstimateItem(
                this.props.match.params.id,
                this.props.match.params.estimateId,
                submitObject,
            ))
            .then((response) => {
                if (response.type !== 'POST_CUSTOM_ESTIMATE_ITEM_FAILURE') {
                    this.closeDialog();
                    this.props.dispatch(getProjectEstimate(
                        this.props.match.params.id,
                        this.props.match.params.estimateId,
                    ));
                    this.props.dispatch(getProjectEstimateItems(
                        this.props.match.params.id,
                        this.props.match.params.estimateId,
                        1,
                        true,
                    ));
                }
            });
    };

    areEmpty = (item) => {
        this.setState({ disabledCustomAdd: some(item, isEmpty) });
    };

    closeDialog = () => {
        this.setState({
            customDialogOpen: false,
            markupDialogOpen: false,
            discountDialogOpen: false,
            customTotal: 0,
            disabledCustomAdd: true,
            markupTitle: '',
            itemType: '',
            percentLabel: '',
            customItemFields: Object.assign({}, this.state.customItemFields, {
                customItemDescription: '',
                customUnitPrice: '',
                customQuantity: '',
            }),
            markupFields: Object.assign({}, this.state.markupFields, {
                markupDescription: '',
                percentMarkUp: '',
            }),
        });
    };

    render() {
        return (
            <div>
                <CustomItemDialog
                    open={this.state.customDialogOpen}
                    handleClose={this.closeDialog}
                    handleChange={this.handleDialogChange}
                    handleAdd={this.handleDialogAdd}
                    customItemDescription={
                        this.state.customItemFields.customItemDescription
                    }
                    customUnitPrice={
                        this.state.customItemFields.customUnitPrice
                    }
                    customQuantity={this.state.customItemFields.customQuantity}
                    customTotal={this.state.customTotal}
                    disabledCustomAdd={this.state.disabledCustomAdd}
                    itemType={customItemsConstants.customItem.itemType}
                />
                <MarkupDialog
                    open={this.state.markupDialogOpen}
                    title={this.state.markupTitle}
                    handleClose={this.closeDialog}
                    handleChange={this.handleDialogChange}
                    handleAdd={this.handleDialogAdd}
                    disabledCustomAdd={this.state.disabledCustomAdd}
                    textFieldDescription={
                        this.state.markupFields.markupDescription
                    }
                    percentMarkUp={this.state.markupFields.percentMarkUp}
                    type="markupFields"
                    itemType={this.state.itemType}
                    percentLabel={this.state.percentLabel}
                />
            </div>
        );
    }
}

CustomItems.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            estimateId: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
};

const mapStateToProps = state => ({
    ...state.projectEstimateItem,
});
export default withRouter(connect(mapStateToProps)(CustomItems));
