import { SET_ACTIVE_STEP } from '../actions/TrialActivationActions';

function trialActivation(
    state = {
        activeStep: 0,
    },
    action,
) {
    switch (action.type) {
    case SET_ACTIVE_STEP:
        return Object.assign({}, state, {
            activeStep: action.stepNumber,
        });
    default:
        return state;
    }
}

export default trialActivation;
