import { Typography } from '@material-ui/core';
import React from 'react'

type FormattedDateProps = {
    date: string | Date
}

export const FormattedDateNoTime = (props: FormattedDateProps) => {
    const dateObj = new Date(props.date);
    const formattedDate = dateObj.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    return(<Typography>{formattedDate}</Typography>)
}

const FormattedDate = (props: FormattedDateProps) => {
    const dateObj = new Date(props.date);
    const formattedDate = dateObj.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    const formattedTime = dateObj.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });

    return (<Typography>{formattedDate} — {formattedTime.toLowerCase()}</Typography>)
}

export default FormattedDate;