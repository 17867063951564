const UserRoles = {
    Admin: 'Admin',
    AccountAdmin: 'Account Admin',
    HideSummaryReports: 'Hide Summary Reports',
    NearmapDisabled: 'nearmap-disabled',
    SketchTech: 'sketch-tech',
    SketchosAdmin: 'sketchos-admin',
    SketchosEnabled: 'sketchos-enabled',
    EstimatesDisabled: 'Estimates Disabled',
};

export default UserRoles;
