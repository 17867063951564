import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import FloatingActionButton from '../FloatingActionButton';

const ESignatureStyle = {
    root: {
        padding: 24,
    },
    paperStyle: {
        width: '100%',
        maxWidth: 600,
        margin: 'auto',
    },
    eSignatureImageContainer: {
        maxWidth: 250,
        textAlign: 'center',
        margin: 'auto',
        padding: 20,
    },
    eSignatureImage: {
        maxWidth: '100%',
        marginTop: 20,
    },
    eSignatureMessage: {
        paddingBottom: 20,
    },
    eSignatureCaption: {
        marginTop: 20,
    },
};

class ESignatures extends Component {
    goToCreateESignature = () => {
        this.props.history.push('esignatures/create');
    }

    render() {
        return (
            <div style={ESignatureStyle.root}>
                <div>
                    <Paper style={ESignatureStyle.paperStyle}>
                        <div style={ESignatureStyle.eSignatureImageContainer}>
                            <img
                                src="/assets/esignature.svg"
                                alt="eSignature"
                                style={ESignatureStyle.eSignatureImage}
                            />
                            <Typography
                                variant="h6"
                                align="center"
                                style={ESignatureStyle.eSignatureCaption}
                            >
                                Do We Have A Deal?
                            </Typography>
                        </div>
                        <Typography
                            align="center"
                            style={ESignatureStyle.eSignatureMessage}
                        >
                            Use the green plus button below to create a signable document.
                        </Typography>
                    </Paper>
                </div>
                <FloatingActionButton
                    id="esignature-add-button"
                    onClick={this.goToCreateESignature}
                    variant="extended"
                />
            </div>
        );
    }
}

ESignatures.propTypes = {
    currentProject: PropTypes.shape({
        id: PropTypes.string,
    }),
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

ESignatures.defaultProps = {
    currentProject: { id: '' },
};

const mapStateToProps = (state) => {
    const {
        eSignatures,
        projectHome,
    } = state;
    const { currentProject } = projectHome;

    return {
        eSignatures,
        currentProject,
    };
};

export default connect(mapStateToProps)(ESignatures);
