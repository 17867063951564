import { Grid, Typography } from '@material-ui/core';
import React from 'react';

const styles = {
    root: {
        textAlign: 'center',
        fontFamily: 'Roboto',
    },
    listText: {
        color: '#10283E',
        fontSize: '11px',
        textOverflow: 'wrap',
    },
    listTextGray: {
        color: '#10283E',
        opacity: '0.5',
        fontSize: '12px',
        textOverflow: 'wrap',
    },
    listTextBold: {
        color: '#10283E',
        fontSize: '12px',
        fontWeight: 'bold',
    },
    perMonth: {
        color: '#10283E',
        fontSize: '12px',
    },
};

const commonOptions = [
    <Typography style={styles.listText}>Unlimited DIY Projects</Typography>,
    <Typography style={styles.listText}>Unlimited Estimates</Typography>,
    <Typography style={styles.listText}>Material Ordering Documents</Typography>,
    <Typography style={styles.listText}>
        Branded Contracts and Reports
    </Typography>,
    <Typography style={styles.listText} zeroMinWidth>
        Exclusive Roof Measurement Order Pricing
    </Typography>,
];

const PriceOptionsModel = [
    {
        name: 'Non-Subscriber',
        id: 'Non-Subscriber',
        numberOfUsers: 1,
        monthlyPrice: 0,
        description: (
            <Grid item container direction="column" zeroMinWidth>
                <Typography style={styles.perMonth}>No monthly subscription</Typography>
                <Typography style={styles.perMonth}>Pay per order</Typography>
            </Grid>
        ),
        displayNotIncluded: true,
        includedOptions: [
            <Grid container item direction="column" zeroMinWidth>
                <Typography style={styles.listText}>
                    Access to Measurement Ordering Service Without a Subscription
                </Typography>
                <Typography style={styles.listTextGray}>
                    {' '}
                    (Non-Member Pricing)
                </Typography>
            </Grid>,
            <Typography style={styles.listText}>
                2 to 4 Hour Turn Around Time
            </Typography>,
        ],
        notIncludedOptions: [
            <Grid item container direction="column" zeroMinWidth>
                <Typography style={styles.listText}>
                    No Draw-It-Yourself Software
                </Typography>
                <Grid item container direction="row" alignItems="center">
                    <Typography style={styles.listTextBold}>0 Images&nbsp;</Typography>
                    <Typography style={styles.listText}> / mo. Per User</Typography>
                </Grid>
            </Grid>,
            <Typography style={styles.listText}>No DIY Projects</Typography>,
            <Typography style={styles.listText}>No Estimates</Typography>,
            <Typography style={styles.listText}>
                No Material Ordering Documents
            </Typography>,
            <Typography style={styles.listText}>
                No Branded Contracts and Reports
            </Typography>,
            <Typography style={styles.listText} zeroMinWidth>
                No Exclusive Measurement Order Pricing
            </Typography>,
        ],
        sketchOrderPriceRange: [32, 52],
        buttonText: 'Order Measurements',
    },
    {
        name: 'Monthly',
        id: 'Monthly',
        numberOfUsers: 1,
        description: (
            <Typography style={styles.perMonth}>Per user / per month</Typography>
        ),
        includedOptions: [
            <Grid item container direction="column" zeroMinWidth>
                <Typography style={styles.listText}>
                    Draw-It-Yourself Software{' '}
                </Typography>
                <Grid container item direction="row" alignItems="center">
                    <Typography style={styles.listTextBold}>5 HD Images&nbsp;</Typography>
                    <Typography style={styles.listText}>/ Per Month</Typography>
                </Grid>
            </Grid>,
            ...commonOptions,
        ],
        sketchOrderPriceRange: [10, 47],
        buttonText: 'Subscribe Now',
    },
    {
        name: 'Starter',
        id: 'Starter',
        numberOfUsers: 2,
        description: (
            <Grid item container direction="column" zeroMinWidth>
                <Typography style={styles.perMonth}>Per user / per month</Typography>
                <Typography style={styles.perMonth}>
                    ($1,776 billed annually)
                </Typography>
            </Grid>
        ),
        includedOptions: [
            <Grid item container direction="column" zeroMinWidth>
                <Typography style={styles.listText}>
                    Draw-It-Yourself Software
                </Typography>
                <Grid container item direction="row" alignItems="center">
                    <Typography style={styles.listTextBold}>
                        {' '}
                        480 HD Images&nbsp;
                    </Typography>
                    <Typography style={styles.listText}>/ Per Year</Typography>
                </Grid>
            </Grid>,
            ...commonOptions,
        ],
        sketchOrderPriceRange: [10, 47],
        buttonText: 'Contact Us',
    },
    {
        name: 'Pro',
        id: 'Pro',
        numberOfUsers: 5,
        description: (
            <Grid item container direction="column" zeroMinWidth>
                <Typography style={styles.perMonth}>Per user / per month</Typography>
                <Typography style={styles.perMonth}>
                    ($3,480 billed annually)
                </Typography>
            </Grid>
        ),
        includedOptions: [
            <Grid item container direction="column" zeroMinWidth>
                <Typography style={styles.listText}>
                    Draw-It-Yourself Software
                </Typography>
                <Grid item container direction="row" alignItems="center">
                    <Typography style={styles.listTextBold}>
                        1200 HD Images&nbsp;
                    </Typography>
                    <Typography style={styles.listText}>/ Per Year</Typography>
                </Grid>
            </Grid>,
            ...commonOptions,
        ],
        sketchOrderPriceRange: [10, 47],
        buttonText: 'Contact Us',
    },
    {
        name: 'Enterprise',
        id: 'Enterprise',
        numberOfUsers: 10,
        description: (
            <Grid item container direction="column" zeroMinWidth>
                <Typography style={styles.perMonth}>Per user / per month</Typography>
                <Typography style={styles.perMonth}>
                    ($5,880 billed annually)
                </Typography>
            </Grid>
        ),
        includedOptions: [
            <Grid zeroMinWidth>
                <Typography style={styles.listText}>
                    Draw-It-Yourself Software
                </Typography>
                <Grid item container direction="row" alignItems="center">
                    <Typography style={styles.listTextBold}>
                        2400 HD Images&nbsp;
                    </Typography>
                    <Typography style={styles.listText}>/ Per Year</Typography>
                </Grid>
            </Grid>,
            ...commonOptions,
        ],
        sketchOrderPriceRange: [10, 47],
        buttonText: 'Contact Us',
    },
];

export default PriceOptionsModel;
