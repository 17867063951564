import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Divider from '@material-ui/core/Divider';
import CreateProjectForm from './CreateProjectForm';
import projectStatus from '../../lib/ProjectStatus';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
    },
});

export class CreateProjectDialogComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isValid: true,
            project: {
                officeId: this.props.offices.length > 0 ? this.props.offices[0].id : null,
                projectStatus: props.projectStatus || 'NewLead',
                projectName: null,
                customerName: null,
                customerAddress: null,
                customerCity: null,
                customerState: null,
                customerZip: null,
                customerPhone: null,
                customerPhone2: null,
                customerEmail: null,
                billingName: null,
                billingCompany: null,
                billingAddress: null,
                billingCity: null,
                billingState: null,
                billingZip: null,
                billingPhone: null,
                billingPhone2: null,
                billingEmail: null,
                insurerName: null,
                adjusterName: null,
                adjusterContactNumber: null,
                claimNumber: null,
                claimsDeptContactNumber: null,
                adjusterEmail: null,
            },
        };
    }
    componentDidUpdate(prevProps) {
        if (this.props.offices !== prevProps.offices) this.setOfficeId();
    }
    setOfficeId = () => {
        this.setState({
            project: {
                ...this.state.project,
                officeId: this.props.offices.length > 0 ? this.props.offices[0].id : null,
            },
        });
    };
    getOffices = () => (
        this.props.offices.map(office => ({
            value: office.id,
            label: office.officeName,
        }))
    );
    getStatuses = () => (
        Object.entries(projectStatus).map(status => ({
            value: status[0],
            label: status[1],
        }))
    );
    handleInputChange = (event) => {
        this.setState({
            project: {
                ...this.state.project,
                [event.target.name]: event.target.value,
            },
        });
    };
    handleOfficeChange = (officeId) => {
        // Grab the first (default) office
        const defaultOfficeId = this.props.offices.length > 0 ? this.props.offices[0].id : null;
        // If the office id is bad (field was cleared out) then set the value to the default office
        const officeIdValue = officeId || defaultOfficeId;
        this.setState({
            project: {
                ...this.state.project,
                officeId: officeIdValue,
            },
        });
    };
    handleStatusChange = (status) => {
        this.setState({
            project: {
                ...this.state.project,
                projectStatus: status,
            },
        });
    };
    handleCreateClick = () => {
        this.props.onCreateClick(this.state.project);
    };
    validateForm = (isValid) => {
        this.setState({
            isValid,
        });
    };
    render() {
        const { classes } = this.props;
        const { project } = this.state;
        return (
            <div id="create-project-dialog">
                <Dialog fullWidth open={this.props.open} fullScreen={this.props.fullScreen}>
                    <DialogTitle>Create a New Project</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <CreateProjectForm
                            offices={this.getOffices()}
                            statuses={this.getStatuses()}
                            showMissingOfficesMessage={this.props.showMissingOfficesMessage}
                            onInputChange={this.handleInputChange}
                            onOfficeChange={this.handleOfficeChange}
                            onStatusChange={this.handleStatusChange}
                            officeId={project.officeId}
                            projectStatus={project.projectStatus}
                            validate={this.validateForm}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            id="cancel-button"
                            onClick={this.props.onCancelClick}
                            className={classes.button}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            className={classes.button}
                            id="create-button"
                            disabled={!this.state.isValid}
                            onClick={this.handleCreateClick}
                        >
                            Create
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

CreateProjectDialogComponent.propTypes = {
    classes: PropTypes.shape({
        button: PropTypes.string.isRequired,
    }).isRequired,
    open: PropTypes.bool.isRequired,
    onCreateClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    offices: PropTypes.arrayOf(PropTypes.shape).isRequired,
    showMissingOfficesMessage: PropTypes.func.isRequired,
    projectStatus: PropTypes.string,
};

CreateProjectDialogComponent.defaultProps = {
    projectStatus: '',
};

export default withMobileDialog()(withStyles(styles)(CreateProjectDialogComponent));
