import { Typography as MuiTypography, styled } from '@mui/material';

declare module '@mui/material/styles' {
    interface TypographyVariants {
        'title-medium': React.CSSProperties;
        'title-small': React.CSSProperties;
        'body-large': React.CSSProperties;
        'body-medium': React.CSSProperties;
        'body-small': React.CSSProperties;
        'new-caps': React.CSSProperties;
    }
    interface TypographyVariantsOptions {
        'title-medium'?: React.CSSProperties;
        'title-small': React.CSSProperties;
        'body-large': React.CSSProperties;
        'body-medium': React.CSSProperties;
        'body-small': React.CSSProperties;
        'new-caps': React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        'title-medium': true;
        'title-small': true;
        'body-large': true;
        'body-medium': true;
        'body-small': true;
        'new-caps': true;
    }
}

const Typography = styled(MuiTypography)(({ theme, variant }) => {
    switch (variant) {
        case 'title-medium':
            return {
                color: '#222',
                fontFamily: 'Roboto',
                fontSize: 16,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '0.15px',
            };
        case 'title-small':
            return {
                color: '#222',
                fontFamily: 'Roboto',
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',
                letterSpacing: '0.1px',
            };
        case 'body-large':
            return {
                fontFamily: 'Roboto',
                fontSize: '1rem',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '1.5rem',
                letterSpacing: '0.03125rem',
            };
        case 'body-medium':
            return {
                color: '#000',
                fontFamily: 'Roboto',
                fontSize: 14,
                fontWeight: 400,
                letterSpacing: '0.25px',
                lineHeight: '20px',
            };
        case 'body-small':
            return {
                color: '#555555',
                fontSize: 12,
                fontFamily: 'Roboto',
                fontWeight: '400',
                wordWrap: 'break-word',
            };
        case 'new-caps':
            return {
                fontFamily: 'Roboto',
                fontSize: '0.6875rem',
                fontWeight: '500',
                lineHeight: '1rem',
                letterSpacing: '0.03125rem',
                textTransform: 'uppercase',
            };

        default:
            return {
                fontSize: '1rem',
                fontWeight: 'normal',
                color: theme.palette.text.primary,
            };
    }
});

export default Typography;

export type TypographyProps = React.ComponentProps<typeof Typography>;
