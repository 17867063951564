import { CALL_API } from '../middleware/api';
import httpRequestMethod from '../middleware/httpRequestMethods';
import { SET_REDUX_MESSAGE } from './ReduxMessagesActions';

export const GET_PROJECT_DRAWING_REQUEST = 'GET_PROJECT_DRAWING_REQUEST';
export const GET_PROJECT_DRAWING_SUCCESS = 'GET_PROJECT_DRAWING_SUCCESS';
export const GET_PROJECT_DRAWING_FAILURE = 'GET_PROJECT_DRAWING_FAILURE';

export const getProjectDrawingById = id => ({
    [CALL_API]: {
        types: [GET_PROJECT_DRAWING_REQUEST, GET_PROJECT_DRAWING_SUCCESS, GET_PROJECT_DRAWING_FAILURE],
        endpoint: `projects/${id}/projectdrawing`,
        method: httpRequestMethod.GET,
    },
});

export const CREATE_PROJECT_DRAWING_REQUEST = 'CREATE_PROJECT_DRAWING_REQUEST';
export const CREATE_PROJECT_DRAWING_SUCCESS = 'CREATE_PROJECT_DRAWING_SUCCESS';
export const CREATE_PROJECT_DRAWING_FAILURE = 'CREATE_PROJECT_DRAWING_FAILURE';

export const createProjectDrawing = (id, projectDrawing) => ({
    [CALL_API]: {
        types: [CREATE_PROJECT_DRAWING_REQUEST, CREATE_PROJECT_DRAWING_SUCCESS, CREATE_PROJECT_DRAWING_FAILURE],
        endpoint: `projects/${id}/projectdrawing`,
        method: httpRequestMethod.POST,
        body: projectDrawing,
    },
});

export const UPDATE_PROJECT_DRAWING_REQUEST = 'UPDATE_PROJECT_DRAWING_REQUEST';
export const UPDATE_PROJECT_DRAWING_SUCCESS = 'UPDATE_PROJECT_DRAWING_SUCCESS';
export const UPDATE_PROJECT_DRAWING_FAILURE = 'UPDATE_PROJECT_DRAWING_FAILURE';

export const updateProjectDrawing = (id, version, projectDrawing) => ({
    [CALL_API]: {
        types: [UPDATE_PROJECT_DRAWING_REQUEST, UPDATE_PROJECT_DRAWING_SUCCESS, UPDATE_PROJECT_DRAWING_FAILURE, SET_REDUX_MESSAGE],
        endpoint: `projects/${id}/projectdrawing`,
        method: httpRequestMethod.PUT,
        header: {
            version,
        },
        body: projectDrawing,
    },
});
