import { CALL_API } from '../middleware/api';
import LocalStorageWrapper from '../lib/LocalStorageWrapper';
import decode from '../lib/decodeRoofSnapAuthToken';

export const GET_CURRENT_USER_ROLES_REQUEST = 'GET_CURRENT_USER_ROLES_REQUEST';
export const GET_CURRENT_USER_ROLES_SUCCESS = 'GET_CURRENT_USER_ROLES_SUCCESS';
export const GET_CURRENT_USER_ROLES_FAILURE = 'GET_CURRENT_USER_ROLES_FAILURE';

export const GET_CURRENT_USER_ROLES_REQUEST_BACKGROUND = 'GET_CURRENT_USER_ROLES_REQUEST_BACKGROUND';
export const GET_CURRENT_USER_ROLES_SUCCESS_BACKGROUND = 'GET_CURRENT_USER_ROLES_SUCCESS_BACKGROUND';
export const GET_CURRENT_USER_ROLES_FAILURE_BACKGROUND = 'GET_CURRENT_USER_ROLES_FAILURE_BACKGROUND';

export const getCurrentUserRoles = (requestInBackground = false) => {
    const accessToken = LocalStorageWrapper.getItem('accessToken');
    const { userId } = decode(accessToken);

    if (!userId) {
        return {
            type: GET_CURRENT_USER_ROLES_FAILURE,
        };
    }

    const requestTypes = [GET_CURRENT_USER_ROLES_REQUEST, GET_CURRENT_USER_ROLES_SUCCESS, GET_CURRENT_USER_ROLES_FAILURE];
    const backgroundRequestTypes = [GET_CURRENT_USER_ROLES_REQUEST_BACKGROUND, GET_CURRENT_USER_ROLES_SUCCESS_BACKGROUND, GET_CURRENT_USER_ROLES_FAILURE_BACKGROUND];

    return {
        [CALL_API]: {
            types: requestInBackground ? backgroundRequestTypes : requestTypes,
            endpoint: `userprofiles/${userId}/roles`,
        },
    };
};
