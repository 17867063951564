import { CALL_API } from '../middleware/api';
import httpRequestMethods from '../middleware/httpRequestMethods';

export const GENERATE_PAYMENT_CLIENT_TOKEN_REQUEST = 'GENERATE_PAYMENT_CLIENT_TOKEN_REQUEST';
export const GENERATE_PAYMENT_CLIENT_TOKEN_SUCCESS = 'GENERATE_PAYMENT_CLIENT_TOKEN_SUCCESS';
export const GENERATE_PAYMENT_CLIENT_TOKEN_FAILURE = 'GENERATE_PAYMENT_CLIENT_TOKEN_FAILURE';

export const generatePaymentClientToken = userId => ({
    [CALL_API]: {
        endpoint: `userprofiles/${userId}/braintree/clienttoken`,
        types: [GENERATE_PAYMENT_CLIENT_TOKEN_REQUEST, GENERATE_PAYMENT_CLIENT_TOKEN_SUCCESS, GENERATE_PAYMENT_CLIENT_TOKEN_FAILURE],
        method: httpRequestMethods.POST,
    },
});

export const UPDATE_PAYMENT_METHOD_REQUEST = 'UPDATE_PAYMENT_METHOD_REQUEST';
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCCESS';
export const UPDATE_PAYMENT_METHOD_FAILURE = 'UPDATE_PAYMENT_METHOD_FAILURE';

export const updatePaymentMethod = (userId, paymentMethodNonce) => ({
    [CALL_API]: {
        endpoint: `userprofiles/${userId}/updatepaymentmethod`,
        types: [UPDATE_PAYMENT_METHOD_REQUEST, UPDATE_PAYMENT_METHOD_SUCCESS, UPDATE_PAYMENT_METHOD_FAILURE],
        method: httpRequestMethods.POST,
        body: {
            paymentMethodNonce,
        },
    },
});
