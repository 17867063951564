import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { projectMenusVisible } from '../../lib/ProjectMenu';
import ProjectHomeCards from '../ProjectHome/ProjectHomeCards';

const styles = {
    blurb: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '50%',
    },
    red: {
        color: '#FF0000',
    },
    cardWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: 12,
        overflow: 'auto',
    },
    blurbWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
};

class ProjectDialog extends React.Component {
    getOfficeName = (offices, officeId) => {
        const office = offices.find(elem => elem.id === officeId);
        return office && office.officeName;
    };

    openDeleteConfirmation = (e) => {
        e.stopPropagation();
        this.props.openDeleteConfirmation(this.props.currentProject.id);
    };

    cardClick = (route) => {
        this.props.history.push(`/projects/${this.props.currentProject.id}/${route}`);
    };
    renderInfoBlurb = (label, value, isDate) =>
        value && (
            <Typography variant="caption" className={this.props.classes.blurb}>
                <b>{label} </b>
                {isDate ? new Date(value).toDateString() : value}
            </Typography>
        );
    renderCard = elem => (
        <ProjectHomeCards
            id={elem.id}
            key={elem.Title}
            title={elem.Title}
            description={elem.Description}
            innerDivStyle={{ backgroundColor: elem.Color }}
            callBack={() => this.cardClick(elem.Route)}
        >
            {elem.Icon}
        </ProjectHomeCards>
    );

    render() {
        const {
            fullScreen,
            isOpen,
            handleClose,
            currentProject,
            classes,
            offices,
        } = this.props;
        const {
            projectName,
            projectOwner,
            customerName,
            updatedAt,
            customerPhone,
            createdAt,
            officeId,
        } = currentProject;
        return (
            <Dialog fullScreen={fullScreen} open={isOpen} onClose={handleClose}>
                <DialogTitle id="responsive-dialog-title">
                    {projectName}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.blurbWrapper}>
                        {this.renderInfoBlurb('Project Owner: ', projectOwner)}
                        {this.renderInfoBlurb(
                            'Office: ',
                            this.getOfficeName(offices, officeId),
                        )}
                        {this.renderInfoBlurb('Customer Name: ', customerName)}
                        {this.renderInfoBlurb(
                            'Customer Phone Number: ',
                            customerPhone,
                        )}
                        {this.renderInfoBlurb('Created: ', createdAt, true)}
                        {this.renderInfoBlurb(
                            'Last Updated: ',
                            updatedAt,
                            true,
                        )}
                    </DialogContentText>
                </DialogContent>
                <div className={classes.cardWrapper}>
                    {projectMenusVisible.map(this.renderCard)}
                </div>
                <DialogActions>
                    <Button
                        size="small"
                        color="inherit"
                        className={classes.red}
                        onClick={this.openDeleteConfirmation}
                    >
                        Delete Project
                    </Button>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ProjectDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    currentProject: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    openDeleteConfirmation: PropTypes.func.isRequired,
};

ProjectDialog.default = {
    currentProject: {},
};
export default withMobileDialog()(withStyles(styles)(ProjectDialog));
