// These values were taken from the "Braintree" nuget package. There is an enum called TransactionStatus
export const BraintreeTransactionStatus = {
  AuthorizationExpired: "authorization_expired",
  Authorized: "authorized",
  Authorizing: "authorizing",
  Failed: "failed",
  GatewayRejected: "gateway_rejected",
  ProcessorDeclined: "processor_declined",
  Settled: "settled",
  Settling: "settling",
  SubmittedForSettlement: "submitted_for_settlement",
  Voided: "voided",
  Unrecognized: "unrecognized",
  SettlementConfirmed: "settlement_confirmed",
  SettlementDeclined: "settlement_declined",
  SettlementPending: "settlement_pending",
};

export const BraintreeTransactionStatusMap = {
  All: [],
  Processing: [
    BraintreeTransactionStatus.Authorized,
    BraintreeTransactionStatus.Authorizing,
    BraintreeTransactionStatus.Voided,
  ],
  Billed: [
    BraintreeTransactionStatus.Settled,
    BraintreeTransactionStatus.Settling,
    BraintreeTransactionStatus.SubmittedForSettlement,
    BraintreeTransactionStatus.SettlementPending,
    BraintreeTransactionStatus.SettlementConfirmed,
  ],
  'Billing Failed': [
    BraintreeTransactionStatus.AuthorizationExpired,
    BraintreeTransactionStatus.Failed,
    BraintreeTransactionStatus.GatewayRejected,
    BraintreeTransactionStatus.ProcessorDeclined,
    BraintreeTransactionStatus.Unrecognized,
    BraintreeTransactionStatus.SettlementDeclined,
  ],
};

export const ChipStatusMap = {
  "authorization_expired": 'BillingFailed',
  "authorized": 'Pending',
  "authorizing": 'Pending',
  "failed": 'BillingFailed',
  "gateway_rejected": 'BillingFailed',
  "processor_declined": 'BillingFailed',
  "settled": 'Billed',
  "settling": 'Billed',
  "submitted_for_settlement": 'Billed',
  "voided": 'Incomplete',
  "unrecognized": 'BillingFailed',
  "settlement_confirmed": 'Billed',
  "settlement_declined": 'BillingFailed',
  "settlement_pending": 'Billed',
};
