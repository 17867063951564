import find from 'lodash/find';

const config = {
    defaultHost: {
        host: 'app.roofsnap.com',
        siteName: 'RoofSnap',
        theme: {
            palette: {
                primary: {
                    50: '#E0EBF5',
                    100: '#B3CEE6',
                    200: '#80AED5',
                    300: '#4D8DC4',
                    400: '#2674B7',
                    500: '#005CAA',
                    600: '#0054A3',
                    700: '#004A99',
                    800: '#004190',
                    900: '#00307F',
                    A100: '#2D6AFF',
                    A200: '#2D6AFF',
                    A400: '#2D6AFF',
                    A700: '#2D6AFF',
                    contrastDefaultColor: 'light',
                },
                secondary: {
                    50: '#E0EBF5',
                    100: '#B3CEE6',
                    200: '#80AED5',
                    300: '#4D8DC4',
                    400: '#2674B7',
                    500: '#005CAA',
                    600: '#0054A3',
                    700: '#004A99',
                    800: '#004190',
                    900: '#00307F',
                    A100: '#2D6AFF',
                    A200: '#2D6AFF',
                    A400: '#2D6AFF',
                    A700: '#2D6AFF',
                    contrastDefaultColor: 'light',
                },
            },
        },
    },
    virtualHosts: [
        {
            host: 'xap.roofsnap.com',
            siteName: 'XAP360',
            externalAppUrl: 'https://cloud.kespry.com/app/inspection/missions',
            externalAppName: 'Kespry',
            theme: {
                palette: {
                    primary: {
                        50: '#E4ECED',
                        100: '#BCD0D2',
                        200: '#90B1B4',
                        300: '#639195',
                        400: '#417A7F',
                        500: '#206268',
                        600: '#1C5A60',
                        700: '#185055',
                        800: '#13464B',
                        900: '#0B343A',
                        A100: '#74EDFF',
                        A200: '#41E6FF',
                        A400: '#0EDFFF',
                        A700: '#00D3F3',
                        contrastDefaultColor: 'light',
                    },
                    secondary: {
                        50: '#E0E7E8',
                        100: '#B3C3C6',
                        200: '#809CA0',
                        300: '#4D747A',
                        400: '#26565E',
                        500: '#003841',
                        600: '#00323B',
                        700: '#002B32',
                        800: '#00242A',
                        900: '#00171C',
                        A100: '#5AD6FF',
                        A200: '#27C9FF',
                        A400: '#00B6F3',
                        A700: '#00A3DA',
                        contrastDefaultColor: 'light',
                    },
                },
            },
        },
    ],
};

const virtualHost = find(config.virtualHosts, ['host', window.location.hostname]);

const activeHost = virtualHost || config.defaultHost;

export default activeHost;
