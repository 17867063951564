import { CALL_API } from '../middleware/api';
import httpRequestMethods from '../middleware/httpRequestMethods';

export const CREATE_SKETCH_ORDER_TRANSACTION_BATCH_REQUEST = 'CREATE_SKETCH_ORDER_TRANSACTION_BATCH_REQUEST';
export const CREATE_SKETCH_ORDER_TRANSACTION_BATCH_SUCCESS = 'CREATE_SKETCH_ORDER_TRANSACTION_BATCH_SUCCESS';
export const CREATE_SKETCH_ORDER_TRANSACTION_BATCH_FAILURE = 'CREATE_SKETCH_ORDER_TRANSACTION_BATCH_FAILURE';

export const createSketchOrderTransactionBatch = (sketchOrderIds, paymentMethodNonce) => ({
    [CALL_API]: {
        types: [
            CREATE_SKETCH_ORDER_TRANSACTION_BATCH_REQUEST,
            CREATE_SKETCH_ORDER_TRANSACTION_BATCH_SUCCESS,
            CREATE_SKETCH_ORDER_TRANSACTION_BATCH_FAILURE,
        ],
        endpoint: 'sketchordertransactionsbatch',
        method: httpRequestMethods.POST,
        body: {
            sketchOrderIds,
            paymentMethodNonce,
        },
    },
});

export const createSketchOrderTransactionBatchV2 = (sketchOrderIds, paymentMethodNonce, useSubscriptionPaymentMethod = false) => ({
    [CALL_API]: {
        types: [
            CREATE_SKETCH_ORDER_TRANSACTION_BATCH_REQUEST,
            CREATE_SKETCH_ORDER_TRANSACTION_BATCH_SUCCESS,
            CREATE_SKETCH_ORDER_TRANSACTION_BATCH_FAILURE,
        ],
        endpoint: 'sketchordertransactionsbatch',
        method: httpRequestMethods.POST,
        body: {
            sketchOrderIds,
            paymentMethodNonce,
            useSubscriptionPaymentMethod,
        },
        apiVersion: 'v2',
    },
});
