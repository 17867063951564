import { CALL_API, httpMethod } from '../middleware/api';

export const CREATE_DOCUMENT_SHARED_ACCESS_TOKEN_REQUEST = 'CREATE_DOCUMENT_SHARED_ACCESS_TOKEN_REQUEST';
export const CREATE_DOCUMENT_SHARED_ACCESS_TOKEN_SUCCESS = 'CREATE_DOCUMENT_SHARED_ACCESS_TOKEN_SUCCESS';
export const CREATE_DOCUMENT_SHARED_ACCESS_TOKEN_FAILURE = 'CREATE_DOCUMENT_SHARED_ACCESS_TOKEN_FAILURE';

export const createDocumentSharedAccessToken = (
    documentId,
    renderingId,
) => ({
    [CALL_API]: {
        types: [
            CREATE_DOCUMENT_SHARED_ACCESS_TOKEN_REQUEST,
            CREATE_DOCUMENT_SHARED_ACCESS_TOKEN_SUCCESS,
            CREATE_DOCUMENT_SHARED_ACCESS_TOKEN_FAILURE,
        ],
        endpoint: `documents/${documentId}/renderings/${renderingId}/sharedaccesstokens`,
        method: httpMethod.POST,
        apiVersion: 'v2',
    },
});
