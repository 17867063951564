import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {
    DialogActions,
    DialogContent,
    DialogContentText,
    TextField,
} from '@mui/material';

export interface RequestRevisionDialogProps {
    open: boolean;
    onCancel: () => void;
    onRequestRevision: (value: string) => void;
    textValue: string;
    setTextValue: (value: string) => void;
}

function RequestRevisionDialog(props: RequestRevisionDialogProps) {
    const { open, onCancel, onRequestRevision, textValue, setTextValue } =
        props;

    return (
        <Dialog open={open}>
            <DialogTitle>Request Revisions</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please add notes on the measurement revisions you would like
                    to make.
                </DialogContentText>
                <TextField
                    autoFocus
                    id='Note'
                    label='Add Note'
                    multiline
                    rows={4}
                    onChange={(e) => setTextValue(e.target.value)}
                    fullWidth
                    value={textValue}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button
                    disabled={textValue === ''}
                    onClick={() => onRequestRevision(textValue)}
                >
                    Request Revision
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default RequestRevisionDialog;
