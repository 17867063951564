import React from "react";
import Office from '../../lib/Models/Office';


export interface IOfficeContext {
    office: Office,
    set: ( office: Office) => void,
    isFreeAccount: boolean,
};

const OfficeContext = React.createContext({} as IOfficeContext);

export default OfficeContext;