import { CALL_API, httpMethod } from '../middleware/api';
import SketchOrderStatus from '../lib/SketchOrderStatus.ts';

export const GET_ORGANIZATIONS_FREE_SKETCH_ORDER_BALANCE_REQUEST = 'GET_ORGANIZATIONS_FREE_SKETCH_ORDER_BALANCE_REQUEST';
export const GET_ORGANIZATIONS_FREE_SKETCH_ORDER_BALANCE_SUCCESS = 'GET_ORGANIZATIONS_FREE_SKETCH_ORDER_BALANCE_SUCCESS';
export const GET_ORGANIZATIONS_FREE_SKETCH_ORDER_BALANCE_FAILURE = 'GET_ORGANIZATIONS_FREE_SKETCH_ORDER_BALANCE_FAILURE';

export const GET_ORGANIZATIONS_FREE_SKETCH_ORDERS_RATE_REQUEST = 'GET_ORGANIZATIONS_FREE_SKETCH_ORDERS_RATE_REQUEST';
export const GET_ORGANIZATIONS_FREE_SKETCH_ORDERS_RATE_SUCCESS = 'GET_ORGANIZATIONS_FREE_SKETCH_ORDERS_RATE_SUCCESS';
export const GET_ORGANIZATIONS_FREE_SKETCH_ORDERS_RATE_FAILURE = 'GET_ORGANIZATIONS_FREE_SKETCH_ORDERS_RATE_FAILURE';

export const GET_ORGANIZATIONS_OPEN_SKETCH_ORDERS_REQUEST = 'GET_ORGANIZATIONS_OPEN_SKETCH_ORDERS_REQUEST';
export const GET_ORGANIZATIONS_OPEN_SKETCH_ORDERS_SUCCESS = 'GET_ORGANIZATIONS_OPEN_SKETCH_ORDERS_SUCCESS';
export const GET_ORGANIZATIONS_OPEN_SKETCH_ORDERS_FAILURE = 'GET_ORGANIZATIONS_OPEN_SKETCH_ORDERS_FAILURE';

export const getOrganizationsFreeSketchOrdersBalance = organizationId => ({
    [CALL_API]: {
        types: [
            GET_ORGANIZATIONS_FREE_SKETCH_ORDER_BALANCE_REQUEST,
            GET_ORGANIZATIONS_FREE_SKETCH_ORDER_BALANCE_SUCCESS,
            GET_ORGANIZATIONS_FREE_SKETCH_ORDER_BALANCE_FAILURE,
        ],
        method: httpMethod.GET,
        endpoint: `organizations/${organizationId}/freesketchorderbalance`,
    },
});

export const getOrganizationsFreeSketchOrdersRate = organizationId => ({
    [CALL_API]: {
        types: [
            GET_ORGANIZATIONS_FREE_SKETCH_ORDERS_RATE_REQUEST,
            GET_ORGANIZATIONS_FREE_SKETCH_ORDERS_RATE_SUCCESS,
            GET_ORGANIZATIONS_FREE_SKETCH_ORDERS_RATE_FAILURE,
        ],
        method: httpMethod.GET,
        endpoint: `organizations/${organizationId}/freesketchorderrate`,
    },
});

const openStatusQueryParam = `status=${SketchOrderStatus.Pending}&status=${SketchOrderStatus.InProgress}&status=${SketchOrderStatus.PendingReview}&status=${SketchOrderStatus.PendingRejection}&status=${SketchOrderStatus.Queued}&status=${SketchOrderStatus.Exception}&status=${SketchOrderStatus.BillingFailed}&status=${SketchOrderStatus.InReview}`;

// TODO: refactor - move this to SketchOSActions so we can access this information without pulling in the OrganizationFreeSketchOrders state object as well as the SketchOS state object
export const getOrganizationOpenSketchOrders = organizationId => ({
    [CALL_API]: {
        types: [
            GET_ORGANIZATIONS_OPEN_SKETCH_ORDERS_REQUEST,
            GET_ORGANIZATIONS_OPEN_SKETCH_ORDERS_SUCCESS,
            GET_ORGANIZATIONS_OPEN_SKETCH_ORDERS_FAILURE,
        ],
        endpoint: `organizations/${organizationId}/sketchorders?${openStatusQueryParam}`,
        method: httpMethod.GET,
    },
});
