import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Grid, Typography, Paper } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

const styles = () => ({
    customPlansGrid: {
        backgroundColor: '#FAFAFA',
        border: '1px solid #00000019',
        borderRadius: '5px',
    },
    bottomGrid: {
        padding: '28px 58px',
    },
    customPlansAvailable: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#005CAA',
        padding: '4px 0px',
    },
    moreThanTenUsers: {
        fontSize: '12px',
        padding: '4px 0px',
        color: '#10283E',
        textAlign: 'center',
    },
    emailIcon: {
        fontSize: '12px',
        color: '#005CAA',
        padding: '3px',
    },
    email: {
        fontSize: '13px',
        color: '#005CAA',
        padding: '3px',
    },
    bottomPhoneIcon: {
        fontSize: '12px',
        color: '#10283E',
        padding: '3px',
    },
    bottomPhoneNumber: {
        fontSize: '12px',
        color: '#10283E',
        padding: '3px',
        fontWeight: 'bold',
    },
    hours: {
        fontSize: '10px',
        color: '#012B50',
        opacity: '0.6',
    },
});

const BottomGridMobile = props => (

    <Grid container item>
        <Paper className={props.classes.customPlansGrid}>
            <Grid item container direction="column" xs={12} className={props.classes.bottomGrid} spacing={1}>
                <Grid container item justify="center">
                    <Typography className={props.classes.customPlansAvailable}>
                        Custom Plans Available
                    </Typography>
                </Grid>
                <Grid item container justify="center">
                    <Typography className={props.classes.moreThanTenUsers}>
                        Have more than 10 users? Contact us for custom plans and pricing.
                    </Typography>
                </Grid>
                <Grid container item direction="row" justify="center" alignItems="center" >
                    <Grid item alignItems="center">
                        <EmailIcon className={props.classes.emailIcon} />
                    </Grid>
                    <Grid item alignItems="center">
                        <Typography className={props.classes.email}>
                            Hello@roofsnap.com
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item direction="row" justify="center" alignItems="center">
                    <Grid item alignItems="center">
                        <PhoneIcon className={props.classes.bottomPhoneIcon} />
                    </Grid>
                    <Grid item alignItems="center">
                        <Typography className={props.classes.bottomPhoneNumber}>
                            887.766.3762
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row" item justify="center">
                    <Typography className={props.classes.hours}>
                        9AM - 5PM (EST)
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    </Grid>
);

BottomGridMobile.propTypes = {
    classes: PropTypes.shape({
    }).isRequired,
};

const mapStateToProps = (state) => {
    const {
        sketchOrderPricingReducer,
    } = state;

    const { fullSnapPriceGrid } = sketchOrderPricingReducer;

    return {
        fullSnapPriceGrid,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(BottomGridMobile));
