import {
    GET_ORGANIZATION_SUCCESS,
} from '../actions/Organization';
import LocalStorageWrapper from '../lib/LocalStorageWrapper';
import decode from '../lib/decodeRoofSnapAuthToken';

const accessToken = LocalStorageWrapper.getItem('accessToken');
const { organizationId } = decode(accessToken);

const defaultState = {
    id: organizationId,
    name: '',
    subscriptionExpires: null,
    subscriptionExpired: null,
    addOnsDisabled: false,
    primaryUserId: null,
    status: '',
};

function organization(state = defaultState, action) {
    switch (action.type) {
    case GET_ORGANIZATION_SUCCESS:
        return Object.assign({}, state, {
            ...action.response,
        });
    default:
        return state;
    }
}

export default organization;
