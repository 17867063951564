import { createStyles, Dialog, DialogTitle, DialogContent, withStyles, WithStyles, Typography, IconButton, Tooltip } from '@material-ui/core';
import { Close, Launch } from '@material-ui/icons';
import React, { useEffect } from 'react';

interface IframeDialogProps extends WithStyles<typeof styles> {
    open: boolean,
    setOpen: (open: boolean) => void,
    link: string,
    title?: string,
};

const styles = (theme: any) => createStyles({
    root: {
        height: '100%',
        width: '100%',
    },
    paper: {
        overflow: 'inherit',
        width: '50%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            overflow: 'inherit',
            width: '100%',
            height: '100%',
            margin: 0,
            maxHeight:'100%',
    }
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    buttonContainer: {
        marginRight: -12,
    },
    content: {
        overflowY: 'visible',
        padding: 0,
    },
    iframe: {
        border: 'none',
    },
})

const IframeDialog = (props: IframeDialogProps) => {
    const { classes, open, setOpen, title, link } = props;
    const handleClose = () => setOpen(false);

    useEffect(() => {
        setOpen(open);
    }, [open, setOpen]);

    return (
        <Dialog
            classes={{
                        root: classes.root,
                        paper: classes.paper,
                    }}  
            open={open}
            onClose={handleClose}>
            <DialogTitle className={classes.title} disableTypography>
                <Typography variant="h6">{title}</Typography>
                <div className={classes.buttonContainer}>
                    <Tooltip title="Open in new tab" aria-label="open in new tab">
                        <IconButton onClick={() => window.open(link)}>
                            <Launch />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Close" aria-label="close">
                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Tooltip>
                </div>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <iframe className={classes.iframe} height="100%" width="100%" title={title} src={link} />
            </DialogContent>
        </Dialog>
    );
};

export default withStyles(styles)(IframeDialog);
