import React, { ReactNode } from 'react';
import {
    ButtonBase,
    ITheme,
    Typography,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import classNames from 'classnames';

type BuildingTypeButtonProps = {
    active?: boolean;
    applyLeftRadius?: boolean;
    applyRightRadius?: boolean;
    icon: ReactNode;
    text: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    subText?: string;
    customStyle?: React.CSSProperties;
    disabled?: boolean;
};

const useStyles = makeStyles((theme: ITheme) =>
    createStyles({
        buttonRoot: {
            display: 'flex',
            height: '4.5rem',
            padding: '0.938rem 0rem 2.188rem 0rem',
            flexDirection: 'column',
            alignItems: 'center',
            borderTop: '0.063rem solid var(--color-grey-200, #D1D1D1)',
            borderBottom: '0.063rem solid var(--color-grey-200, #D1D1D1)',
            borderLeft: '0.063rem solid var(--color-grey-200, #D1D1D1)',
            background: '#FFF',
            [theme.breakpoints.up('sm')]: {
                width: '8.25rem',
            },
            [theme.breakpoints.down('sm')]: {
                width: '7.3125rem',
            },
        },
        active: {
            border: '0.125rem solid var(--color-primary-400, #056CF2) !important',
            background: 'var(--color-primary-100, #F4FAFE)',
            boxShadow: '0rem 0rem 0.75rem 0rem rgba(5, 108, 242, 0.30)',
        },
        icon: {
            display: 'flex',
            width: '8.25rem',
            height: '1.2rem',
            padding: '1.188rem 0rem 0.563rem 0rem',
            flexDirection: 'column',
            alignItems: 'center',
        },
        labelContainer: {
            display: 'flex',
            width: '8rem',
            padding: '0.25rem 0.625rem',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
        },
        label: {
            color: 'var(--color-black, #222)',
            textAlign: 'center',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
            letterSpacing: '0.00625rem',
        },
        sublabel: {
            color: '#555',
            textAlign: 'center',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '1.25rem',
            letterSpacing: '0.01563rem',
        },
        leftRadius: {
            borderRadius: '0.25rem 0rem 0rem 0.25rem',
        },
        rightRadius: {
            borderRadius: '0rem 0.25rem 0.25rem 0rem',
            border: '0.063rem solid var(--color-grey-200, #D1D1D1)',
        },
    })
);

const BuildingTypeButton = ({
    active,
    applyLeftRadius,
    applyRightRadius,
    icon,
    text,
    subText,
    onClick,
    customStyle,
    disabled,
}: BuildingTypeButtonProps) => {
    const classes = useStyles();

    return (
        <ButtonBase
            className={classNames(classes.buttonRoot, {
                [classes.active]: active,
                [classes.leftRadius]: applyLeftRadius,
                [classes.rightRadius]: applyRightRadius,
            })}
            onClick={onClick}
            style={customStyle}
            disabled={disabled}
        >
            <span className={classes.icon}>{icon}</span>
            <Typography className={classes.label}>{text}</Typography>
            <Typography className={classes.sublabel}>{subText}</Typography>
        </ButtonBase>
    );
};

export default BuildingTypeButton;
