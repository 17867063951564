import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';
import DocumentCategoryList from './DocumentCategoryList';

const DocumentsListStyle = {
    paperStyle: {
        width: '100%',
        maxWidth: 1500,
        margin: 'auto',
    },
    projectlistHeaderStyle: {
        padding: 8,
        borderBottom: 'solid 1px #dbdbdb',
    },
    projectListHeaderTitleStyle: {
        padding: 12,
        margin: 0,
        fontWeight: 300,
    },
    createDocumentsImageContainer: {
        maxWidth: 600,
        textAlign: 'center',
        margin: 'auto',
        padding: 20,
    },
    createDocumentsImage: {
        maxWidth: '100%',
        marginTop: 20,
    },
    createDocumentsMessage: {
        marginTop: 20,
    },
};

const DocumentsList = (props: ProjectDocumentProps) => {
    const {
        documents,
        deleteDocument,
        onDownloadDocumentClick,
        onOpenDocumentClick,
        categories,
        currentUserRoleIds,
        officeId,
    } = props;

    const viewableDocuments = documents.filter(
        (document: {
            template: { templateCategoryId: any };
            templateId: any;
        }) => {
            const { templateCategoryId: documentTemplateCategoryId } =
                document.template;

            const documentTemplateCategory = categories.find(
                (category: { id: any }) =>
                    category.id === documentTemplateCategoryId
            );
            if (!documentTemplateCategory) {
                return false;
            }

            const documentTemplate = documentTemplateCategory.templates.find(
                (template: { id: any }) => template.id === document.templateId
            );
            if (!documentTemplate) {
                return false;
            }

            const documentTemplatePermissions =
                documentTemplate.documentTemplatePermissions.resourceList;

            const applicableDocumentTemplatePermissions =
                documentTemplatePermissions.filter(
                    (permission: {
                        applyToAllRoles: any;
                        roleId: any;
                        officeId: number;
                    }) =>
                        permission.applyToAllRoles ||
                        currentUserRoleIds.includes(permission.roleId) ||
                        permission.officeId === officeId
                );

            const documentIsHidden = applicableDocumentTemplatePermissions.some(
                (x: string) => x === 'hideDocument'
            );

            return !documentIsHidden;
        }
    );

    const renderProjectDocumentList = (category: { id: number }) => {
        const documentsForTemplateCategory = viewableDocuments.filter(
            (document: { template: any }) => {
                const { template } = document;
                return template
                    ? template.templateCategoryId === category.id
                    : false;
            }
        );

        const compareDescFunc = (a: any, b: any) => {
            let sort = new Date(a.createdAt) >= new Date(b.createdAt);
            return sort ? -1 : 1;
        };

        const sortedDocumentsForTemplateCategory =
            documentsForTemplateCategory.sort(compareDescFunc);

        return (
            <div key={category.id}>
                {documentsForTemplateCategory && (
                    <DocumentCategoryList
                        category={category}
                        documents={sortedDocumentsForTemplateCategory}
                        deleteDocument={deleteDocument}
                        onDownloadDocumentClick={onDownloadDocumentClick}
                        onOpenDocumentClick={onOpenDocumentClick}
                    />
                )}
            </div>
        );
    };

    const plural = viewableDocuments.length > 1 && 's';

    return (
        <div>
            <Paper
                style={
                    viewableDocuments.length > 0
                        ? { ...DocumentsListStyle.paperStyle }
                        : {
                              ...DocumentsListStyle.paperStyle,
                              ...{ maxWidth: 600 },
                          }
                }
            >
                {viewableDocuments.length > 0 && (
                    <React.Fragment>
                        <div style={DocumentsListStyle.projectlistHeaderStyle}>
                            <Typography
                                id='documentTitle'
                                variant='h5'
                                style={
                                    DocumentsListStyle.projectListHeaderTitleStyle
                                }
                            >
                                {viewableDocuments.length} Document{plural}
                            </Typography>
                        </div>
                        {categories.map(renderProjectDocumentList)}
                    </React.Fragment>
                )}
                {viewableDocuments.length === 0 && (
                    <div style={DocumentsListStyle.createDocumentsImage}>
                        <img
                            src='/assets/documents.png'
                            alt='Documents'
                            style={DocumentsListStyle.createDocumentsImage}
                        />
                        <Typography
                            variant='h6'
                            align='center'
                            style={DocumentsListStyle.createDocumentsMessage}
                        >
                            {"Let's Get to Work!"}
                        </Typography>
                        <Typography align='center'>
                            Use the green plus button below to create your
                            documents
                        </Typography>
                    </div>
                )}
            </Paper>
        </div>
    );
};

type ProjectDocumentProps = {
    deleteDocument: (document: any) => void;
    onDownloadDocumentClick: (document: any) => void;
    onOpenDocumentClick: (document: any) => void;
    documents: any;
    categories: any;
    currentUserRoleIds: any;
    officeId: number;
};

export default DocumentsList;
