import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PAYMENT_TYPE from '../../lib/PaymentType.ts';
import BraintreeDropInUI from '../BraintreeDropinUI';

const style = (theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    topContentTitle: {
        marginTop: theme.spacing(1),
    },
    organizationBillingMessage: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(1),
    },
    errorMessage: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    freeMessage: {
        marginTop: theme.spacing(1),
    },
    freeOrderImage: {
        maxWidth: '100%',
        marginTop: '20px',
    },
    freeImageContainer: {
        maxWidth: '600px',
        textAlign: 'center',
        margin: 'auto',
    },
});

const PaymentSummaryContent = (props) => {
    const freeSketchOrdersRemaining =
        props.freeSketchOrderBalance - props.openSketchOrders;
    const leftOverOrders =
        props.numberOfOrdersBeingPlaced - freeSketchOrdersRemaining > 0
            ? props.numberOfOrdersBeingPlaced - freeSketchOrdersRemaining
            : 0;

    const getPlural = (number) => {
        if (number > 1) {
            return 's';
        }
        return '';
    };
    return (
        <div className={props.classes.root}>
            <Typography variant='h6' className={props.classes.topContentTitle}>
                Payment summary
            </Typography>
            {props.errorMessage && (
                <Typography
                    color='error'
                    gutterBottom
                    className={props.classes.errorMessage}
                >
                    {props.errorMessage}
                </Typography>
            )}
            {!props.isRushed && (
                <React.Fragment>
                    {(props.isSneakPeekUser || props.isOrderFree) && (
                        <div className={props.classes.freeImageContainer}>
                            <img
                                src='/assets/free-order.png'
                                alt='The order is free'
                                className={props.classes.freeOrderImage}
                            />
                            <Typography
                                variant='h6'
                                align='center'
                                className={props.classes.freeMessage}
                            >
                                You don't owe a SHINGLE dollar!
                            </Typography>
                        </div>
                    )}
                </React.Fragment>
            )}
            {!props.isSneakPeekUser &&
                !props.isOrderFree &&
                !props.isRushed &&
                freeSketchOrdersRemaining > 0 && (
                <div>
                    <Typography
                        variant='h6'
                        align='center'
                        className={props.classes.organizationBillingMessage}
                    >
                        {leftOverOrders === 0 ? (
                            <React.Fragment>
                                Placing {props.numberOfOrdersBeingPlaced}{' '}
                                Sketch Order
                                {getPlural(
                                    props.numberOfOrdersBeingPlaced
                                )}{' '}
                                using {props.numberOfOrdersBeingPlaced} free
                                Sketch Order
                                {getPlural(props.numberOfOrdersBeingPlaced)}
                                !
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                Placing {props.numberOfOrdersBeingPlaced}{' '}
                                Sketch Order
                                {getPlural(
                                    props.numberOfOrdersBeingPlaced
                                )}{' '}
                                using {freeSketchOrdersRemaining} free
                                Sketch Order
                                {getPlural(freeSketchOrdersRemaining)}!
                            </React.Fragment>
                        )}
                    </Typography>
                    <Typography variant='subtitle1' align='center'>
                        Free Sketch Orders will be redeemed as orders are
                        completed.
                        <br />
                        This may not be in the order that you place them.
                    </Typography>
                </div>
            )}
            {!props.isSneakPeekUser && !props.isOrderFree && (
                <React.Fragment>
                    {props.paymentType === PAYMENT_TYPE.ORGANIZATION && (
                        <React.Fragment>
                            <Typography
                                variant='h6'
                                align='center'
                                className={
                                    props.classes.organizationBillingMessage
                                }
                            >
                                We&#39;ll put it on your tab!
                            </Typography>
                            <Typography variant='body1' align='center'>
                                {leftOverOrders > 1 ? (
                                    <React.Fragment>
                                        {leftOverOrders} Sketch Orders will be
                                        billed to the card on file for your
                                        RoofSnap subscription.
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        This Sketch Order will be billed to the
                                        card on file for your RoofSnap
                                        subscription.
                                    </React.Fragment>
                                )}
                            </Typography>
                        </React.Fragment>
                    )}
                    {props.paymentType === PAYMENT_TYPE.USER && (
                        <BraintreeDropInUI
                            options={{
                                authorization: props.paymentClientToken,
                            }}
                            onInstance={props.onBraintreeInstance}
                            onError={props.onError}
                            onPaymentMethodRequestable={
                                props.onPaymentMethodRequestable
                            }
                            onNoPaymentMethodRequestable={
                                props.onNoPaymentMethodRequestable
                            }
                        />
                    )}
                </React.Fragment>
            )}
            {!props.isSneakPeekUser &&
                props.isRushed &&
                freeSketchOrdersRemaining > 0 && (
                <React.Fragment>
                    <Typography
                        variant='h6'
                        align='center'
                        className={props.classes.organizationBillingMessage}
                    >
                        <React.Fragment>
                            To continue and receive your sketch order in 60
                            minutes or less, add a payment method now. To
                            remove the $25 rush fee and redeem your free
                            sketch order, click Back and select standard
                            delivery.
                        </React.Fragment>
                    </Typography>
                    {props.paymentType === PAYMENT_TYPE.ORGANIZATION && (
                        <React.Fragment>
                            <Typography
                                variant='h6'
                                align='center'
                                className={
                                    props.classes.organizationBillingMessage
                                }
                            >
                                {leftOverOrders === 0 ? (
                                    <React.Fragment>
                                        We&#39;ll put the rush delivery cost
                                        on your tab!
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        We&#39;ll put it on your tab!
                                    </React.Fragment>
                                )}
                            </Typography>
                            <Typography variant='body1' align='center'>
                                {leftOverOrders === 0 ? (
                                    <React.Fragment>
                                        This Rush delivery will be billed to
                                        the card on file for your RoofSnap
                                        subscription.
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        The Rush delivery and{' '}
                                        {leftOverOrders} orders will be
                                        billed to the card on file for your
                                        RoofSnap subscription.
                                    </React.Fragment>
                                )}
                            </Typography>
                        </React.Fragment>
                    )}
                    {props.paymentType === PAYMENT_TYPE.USER && (
                        <BraintreeDropInUI
                            options={{
                                authorization: props.paymentClientToken,
                            }}
                            onInstance={props.onBraintreeInstance}
                            onError={props.onError}
                            onPaymentMethodRequestable={
                                props.onPaymentMethodRequestable
                            }
                            onNoPaymentMethodRequestable={
                                props.onNoPaymentMethodRequestable
                            }
                        />
                    )}
                </React.Fragment>
            )}
        </div>
    );
};

PaymentSummaryContent.propTypes = {
    paymentClientToken: PropTypes.string.isRequired,
    paymentType: PropTypes.oneOf([
        PAYMENT_TYPE.USER,
        PAYMENT_TYPE.ORGANIZATION,
        PAYMENT_TYPE.NONE,
    ]).isRequired,
    onBraintreeInstance: PropTypes.func.isRequired,
    onPaymentMethodRequestable: PropTypes.func.isRequired,
    onNoPaymentMethodRequestable: PropTypes.func.isRequired,
    onError: PropTypes.func,
    errorMessage: PropTypes.string,
    isSneakPeekUser: PropTypes.bool,
    isRushed: PropTypes.bool.isRequired,
    isOrderFree: PropTypes.bool.isRequired,
    freeSketchOrderBalance: PropTypes.number.isRequired,
    openSketchOrders: PropTypes.number.isRequired,
    numberOfOrdersBeingPlaced: PropTypes.number.isRequired,
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
        topContentTitle: PropTypes.string.isRequired,
        organizationBillingMessage: PropTypes.string.isRequired,
        errorMessage: PropTypes.string.isRequired,
    }).isRequired,
};

PaymentSummaryContent.defaultProps = {
    onError: () => {},
    errorMessage: null,
    isSneakPeekUser: false,
};

export default withStyles(style)(PaymentSummaryContent);
