import {
    GET_SKETCH_ORDER_BY_ID_REQUEST,
    GET_SKETCH_ORDER_BY_ID_SUCCESS,
    GET_SKETCH_ORDER_BY_ID_FAILURE,
    CHANGE_SKETCH_ORDER_STATUS_REQUEST,
    CHANGE_SKETCH_ORDER_STATUS_FAILURE,
    CHANGE_SKETCH_ORDER_STATUS_SUCCESS,
    UPDATE_SKETCH_ORDER_REQUEST,
    UPDATE_SKETCH_ORDER_SUCCESS,
    UPDATE_SKETCH_ORDER_FAILURE,
    CREATE_SKETCH_ORDER_REQUEST,
    CREATE_SKETCH_ORDER_SUCCESS,
    CREATE_SKETCH_ORDER_FAILURE,
    CREATE_BULK_SKETCH_ORDER_REQUEST,
    CREATE_BULK_SKETCH_ORDER_SUCCESS,
    CREATE_BULK_SKETCH_ORDER_FAILURE,
    GET_SKETCH_ORDER_BY_EXTERNAL_ID_REQUEST,
    GET_SKETCH_ORDER_BY_EXTERNAL_ID_SUCCESS,
    GET_SKETCH_ORDER_BY_EXTERNAL_ID_FAILURE,
    CREATE_REVISION_REQUEST,
    CREATE_REVISION_SUCCESS,
    CREATE_REVISION_FAILURE,
} from '../actions/SketchOrderActions';
import { SketchOrderStatus } from '../lib/SketchOrderStatus.ts';

const initialState = {
    id: 0,
    createdAt: '',
    updatedAt: '',
    version: '',
    address: '',
    city: '',
    region: '',
    postcode: '',
    country: '',
    requestedDate: '',
    cost: 0,
    notes: '',
    projectName: '',
    adminNotes: '',
    sketchOrderStatus: '',
    sketchReportType: '',
    projectId: '',
    requester: {
        userName: '',
    },
    owner: {
        userId: 0,
        userName: '',
    },
    location: {
        coordinates: [],
    },
    externalId: null,
    revisionNotes: [],
    isFetching: false,
};

const sketchOrderReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_SKETCH_ORDER_BY_ID_REQUEST:
    case CHANGE_SKETCH_ORDER_STATUS_REQUEST:
    case UPDATE_SKETCH_ORDER_REQUEST:
    case CREATE_SKETCH_ORDER_REQUEST:
    case CREATE_BULK_SKETCH_ORDER_REQUEST:
    case CREATE_REVISION_REQUEST:
    case GET_SKETCH_ORDER_BY_EXTERNAL_ID_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case CREATE_BULK_SKETCH_ORDER_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case GET_SKETCH_ORDER_BY_ID_SUCCESS:
    case CHANGE_SKETCH_ORDER_STATUS_SUCCESS:
    case UPDATE_SKETCH_ORDER_SUCCESS:
    case CREATE_SKETCH_ORDER_SUCCESS:
    case GET_SKETCH_ORDER_BY_EXTERNAL_ID_SUCCESS:
        return Object.assign({}, state, {
            ...action.response,
            location: action.response.location || initialState.location,
            revisionNotes: action.response.revisionNotes?.resourceList,
            isFetching: false,
        });
    case GET_SKETCH_ORDER_BY_ID_FAILURE:
    case CREATE_SKETCH_ORDER_FAILURE:
    case CREATE_BULK_SKETCH_ORDER_FAILURE:
        return Object.assign({}, state, initialState);
    case CHANGE_SKETCH_ORDER_STATUS_FAILURE:
    case UPDATE_SKETCH_ORDER_FAILURE:
    case GET_SKETCH_ORDER_BY_EXTERNAL_ID_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case CREATE_REVISION_SUCCESS:
        return {
            ...state,
            sketchOrderStatus: SketchOrderStatus.RevisionRequested,
            revisionNotes: [...state.revisionNotes, action.response],
            isFetching: false,
        };
    case CREATE_REVISION_FAILURE:
    default:
        return state;
    }
};

export default sketchOrderReducer;
