import { GET_FEATURES_SUCCESS } from '../actions/featuresActions';

const initialState = {
    useNewSignupFlow: false,
    freeTrialDurationInDays: '',
};

const features = (state = initialState, action) => {
    switch (action.type) {
    case GET_FEATURES_SUCCESS:
        return Object.assign({}, state, {
            useNewSignupFlow: action.response.useNewSignupFlow,
            freeTrialDurationInDays: action.response.freeTrialDurationInDays,
        });
    default:
        return state;
    }
};

export default features;
