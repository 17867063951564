import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TooltipIcon from '../../TooltipIcon/TooltipIcon';
import TileSelector from '../../Tile/TileSelector/TileSelector';

const styles = theme => ({
    tooltipIcon: {
        color: '#2d6aff',
        cursor: 'pointer',
        fontSize: '16px',
        lineHeight: '1.1em',
        [theme.breakpoints.up('lg')]: {
            marginBottom: -2,
        },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 2,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
        },
    },
    text: {
        lineHeight: '1.1em',
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
            marginLeft: -4,
        },
    },
    optionSelectionGrid: {
        marginBottom: 16,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
});

const FirstSketchOrderDetails = (props) => {
    const {
        classes,
        selectedSketchOption,
        handleUpdateSketchOption,
        notes,
        handleOnChange,
        firstSketchOrderTiles,
    } = props;

    return (
        <React.Fragment>
            <Grid container item spacing={1} alignContent="flex-start">
                <Grid container item xs={12} alignItems="center" className={classes.structurePromptContainer}>
                    <Grid item>
                        <Typography variant="body1" color="textSecondary" className={classes.text}>What kind of structure is at this address?</Typography>
                    </Grid>
                    <Grid item>
                        <TooltipIcon
                            icon={<InfoOutlinedIcon className={classes.tooltipIcon} />}
                            title="Choose the option that best describes the structure to be measured. Use the notes field below to convey special requests to our team. Ex. 'Please measure barn at back of the property'"
                        />
                    </Grid>
                </Grid>
                <TileSelector tiles={firstSketchOrderTiles} selectedTileId={selectedSketchOption} handleTileSelect={handleUpdateSketchOption} />
            </Grid>
            <Grid container item spacing={1} alignContent="flex-start">
                <Grid item xs={12}>
                    <Typography variant="body1" color="textSecondary" gutterBottom className={classes.text}>Anything else we should know?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        placeholder="Examples: 'Dormer pitch is 6/12,' 'Include detached shed,' 'Do not include metal awnings,' 'Flat section only'"
                        fullWidth
                        multiline
                        rows={2}
                        rowsMax={4}
                        variant="outlined"
                        name="notes"
                        value={notes}
                        onChange={handleOnChange}
                        className={classes.notesField}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default withStyles(styles)(FirstSketchOrderDetails);
