import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import { blue, grey, red, yellow } from '@material-ui/core/colors';

const styles = {
    criticalIcon: {
        fill: red[500],
    },
    warningIcon: {
        fill: yellow[700],
    },
    infoIcon: {
        fill: blue[500],
    },
    acknowledgedIcon: {
        fill: grey[400],
    },
};

const PriorityLevelIcon = ({
    classes,
    acknowledged,
    priorityLevel,
    ...otherProps
}) => {
    switch (priorityLevel) {
    case 'Critical':
        return (
            <ErrorIcon
                {...otherProps}
                className={classNames(
                    acknowledged ? classes.acknowledgedIcon : classes.criticalIcon,
                    otherProps.className,
                )}
            />
        );
    case 'Warning':
        return (
            <WarningIcon
                {...otherProps}
                className={classNames(
                    acknowledged ? classes.acknowledgedIcon : classes.warningIcon,
                    otherProps.className,
                )}
            />
        );
        // case 'Info':
    default:
        return (
            <InfoIcon
                {...otherProps}
                className={classNames(
                    acknowledged ? classes.acknowledgedIcon : classes.infoIcon,
                    otherProps.className,
                )}
            />
        );
    }
};

PriorityLevelIcon.propTypes = {
    acknowledged: PropTypes.bool,
    priorityLevel: PropTypes.oneOf(['Critical', 'Warning', 'Info']).isRequired,
    classes: PropTypes.shape({
        criticalIcon: PropTypes.string.isRequired,
        warningIcon: PropTypes.string.isRequired,
        infoIcon: PropTypes.string.isRequired,
        acknowledgedIcon: PropTypes.string.isRequired,
    }).isRequired,
    className: PropTypes.string,
};

PriorityLevelIcon.defaultProps = {
    acknowledged: false,
    className: null,
};

export default withStyles(styles)(PriorityLevelIcon);
