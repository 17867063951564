import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { Card, IconButton, Avatar, Grid, Button, useMediaQuery, useTheme, Typography, Slide } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { openWidget } from '../../lib/ZendeskWidgetFunctions';
// @ts-ignore
import GetHelpMenuButton from './GetHelpMenuButton.tsx';
// @ts-ignore
import IframeDialog from '../IframeDialog/IframeDialog.tsx';

interface GetHelpMenuProps extends WithStyles<typeof styles> {
    user: {
        name: string,
        userName: string,
        orgName: string,
    },
    isFreeAccount: boolean,
    enterFromLeft?: boolean,
};

const styles = (theme: any) => createStyles({
    root: {
        [theme.breakpoints.up('md')]: {
            width: '300px',
            height: '360px',
            display: 'flex',
            position: 'fixed',
            top: theme.mixins.toolbar.minHeight + theme.spacing(2),
        },
        width: '100vw',
        height: '100vh',
        padding: 0,
        position: 'absolute',
        top: theme.mixins.toolbar.minHeight,
        right: 0,
        zIndex: theme.zIndex.appBar + 1,

    },
    contactUsContainer: {
        width: '100%',
        height: '50px',
        backgroundColor: '#E2E3E5',
        textAlign: 'center',
        padding: 0,
    },
    contactUsText: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: 'Helvetica',
    },
    closeCard: {
        width: '30px',
        height: '30px',
        outline: 'none',
        color: 'black',
        padding: '0px 0px'
    },
    minimizeHyphen: {
        verticalAlign: 'top',
        padding: 0,
        fontSize: '24px',
        fontWeight: 'lighter',
        fontFamily: 'Helvetica',
    },
    buttonGrid: {
        height: '310px',
    },
    buttonContainer: {
        width: '100%',
        padding: '0px 22px',
    },
    icon: {
        width: '32px',
        height: '32px',
        padding: '0px 6px',
    },
    menuIcon: {
        verticalAlign: 'middle',
        color: '#ffffff !important',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 12,
        },
    },
    profileButton: {
        paddingLeft: theme.spacing(1),
        marginRight: -12,
    },
    iconText: {
        color: '#ffffff',
        fontWeight: 500,
        display: 'inline',
    },
})

const GetHelpMenu = (props: GetHelpMenuProps) => {
    const { classes, user, enterFromLeft } = props;
    const [open, setOpen] = useState(false);
    const [calendlyOpen, setCalendlyOpen] = useState(false);
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const handleGetHelpClick = () => {
        setOpen(false);
        openWidget(user);
    }
    const handleBookADemoClick = () => {
        setOpen(false);
        setCalendlyOpen(true);
    }
    
    return (
        <React.Fragment>
            {!mobile &&
                <Button onClick={() => setOpen(!open)} className={classes.profileButton}>
                    <Typography className={classes.iconText}>Help</Typography>
                    <HelpOutline className={classes.menuIcon} />
                </Button>
            }
            {mobile &&
                <IconButton onClick={() => setOpen(!open)} className={classes.profileButton}>
                    <HelpOutline className={classes.menuIcon} />
                </IconButton>
            }
            <Slide in={open} direction={mobile ? 'up' : (enterFromLeft ? 'right' : 'left')} mountOnEnter unmountOnExit>
                <Card className={classes.root} style={enterFromLeft ? {left: 10} : {right: 10}}>
                    <Grid container direction="column" alignItems="flex-start">
                        <Grid item container className={classes.contactUsContainer} justifyContent="center" alignItems="center">
                            <Grid item xs={2}>
                                <Typography className={classes.contactUsText}>

                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography className={classes.contactUsText}>
                                    Contact us
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton aria-label="Close" onClick={() => setOpen(!open)} className={classes.closeCard}>
                                    <Typography className={classes.minimizeHyphen}>-</Typography>
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid container item direction="column" justifyContent="space-evenly" className={classes.buttonGrid}>


                            <Grid item className={classes.buttonContainer}>
                                <GetHelpMenuButton
                                    icon={<Avatar variant="square" className={classes.icon} src="/assets/ChatIcon.svg" />}
                                    buttonText="Get Help"
                                    handleClick={handleGetHelpClick}
                                />
                            </Grid>
                            <Grid item className={classes.buttonContainer}>
                                <GetHelpMenuButton
                                    icon={<Avatar variant="square" className={classes.icon} src="/assets/CalendarIcon.svg" />}
                                    buttonText="Training and Setup"
                                    handleClick={() => handleBookADemoClick()}
                                />
                            </Grid>
                            <Grid item className={classes.buttonContainer}>
                                <GetHelpMenuButton
                                    icon={<Avatar variant="square" className={classes.icon} src="/assets/PhoneIcon.svg" />}
                                    buttonText="Call by Phone"
                                    handleClick={() => window.open("tel:877-766-3762")}
                                />
                            </Grid>
                            <Grid item className={classes.buttonContainer}>
                                <GetHelpMenuButton
                                    icon={<Avatar style={{height: '28px'}} variant="square" className={classes.icon} src="/assets/BookIcon.svg" />}
                                    buttonText="Reference Library"
                                    handleClick={() => window.open(process.env.REACT_APP_HELP_CENTER_URL)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Slide>
            <IframeDialog
                open={calendlyOpen}
                setOpen={setCalendlyOpen}
                title="Training and Setup"
                link={process.env.REACT_APP_NEW_USER_TRAINING_URL}    
            />
        </React.Fragment>
    )
};

const mapStateToProps = (state: any) => {
    const {
        organization,
        currentUser,
    } = state;
    const { userName, firstName, lastName } = currentUser;
    const { name: orgName, isFreeAccount } = organization;

    return {
        user: {
            name: `${firstName} ${lastName}`,
            userName,
            orgName,
        },
        isFreeAccount,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(GetHelpMenu));
