import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  withStyles,
  WithStyles,
  createStyles,
  useMediaQuery,
  useTheme,
  Modal,
  Card,
  CardActions,
  CardContent,
  Button,
  Grid,
  Typography,
  FormControl,
  Input,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import ChipWrapper from 'components/ChipWrapper';
import SketchOrderStatusChip from 'components/SketchOrderStatusChip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SketchOrder from 'lib/Models/SketchOrder';
import { getOrganizationUsers } from 'actions/Users';
import { updateSketchOrder } from 'actions/SketchOrderActions';
import { setReduxMessage } from 'actions/ReduxMessagesActions';
import SketchOrderStatus from 'lib/SketchOrderStatus';
import AutocompleteSelect from 'components/AutocompleteSelect';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';
import Map from 'components/SketchOrder/Map';
import LoggerWrapper from 'lib/Logger';

interface UpdateOrderModalProps extends WithStyles<typeof styles> {
  open: boolean;
  onClose: (response: SketchOrder) => void;
  sketchOrder: SketchOrder;
}

type PartialState = {
  organization: { id: number };
};

const styles = createStyles({
  modal: {
    width: 700,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
  },
  mobileModal: {
    width: '100%',
  },
  formControl: {
    margin: 10,
    marginLeft: 0,
  },
  readOnlyField: {
    margin: 10,
    marginLeft: 0,
    flex: 1,
    width: '100%',
  },
  scrollableContent: {
    maxHeight: 'calc(100vh - 100px)', // Adjust the max height as needed
    overflowY: 'auto',
    padding: 15,
  },
});

const UpdateOrderModal = (props: UpdateOrderModalProps) => {
  const { sketchOrder, classes, onClose, open } = props;

  const [users, setUsers] = useState<any[] | null>(null);
  const [updatedSketchOrder, setSketchOrder] =
    useState<SketchOrder>(sketchOrder);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const isMountedRef = useRef<boolean>(true);

  const {
    organization: { id: organizationId },
  } = useSelector((state: PartialState) => state);

  useEffect(() => {
    if (!open) {
      return;
    }

    isMountedRef.current = true;

    const fetchUsers = async () => {
      try {
        const response: any = await dispatch(
          getOrganizationUsers(organizationId, 1, true)
        );
        if (isMountedRef.current) {
          setUsers(response.response.resourceList);
        }
      } catch (error) {
        LoggerWrapper.log(error);
      }
    };

    fetchUsers();

    return () => {
      isMountedRef.current = false;
    };
  }, [organizationId, dispatch, open]);

  useEffect(() => {
    setSketchOrder(sketchOrder);
  }, [sketchOrder]);

  const handleOwnerChange = (ownerId: number) => {
    setSketchOrder({
      ...updatedSketchOrder,
      ownerUserId: ownerId,
    });
  };

  const formatDate = (date: string | Date) => {
    const dateObj = new Date(date);
    const formattedDate = dateObj.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
    const formattedTime = dateObj.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
    });

    return `${formattedDate} — ${formattedTime.toLowerCase()}`;
  };

  const getUsers = (users: any) =>
    users.map((user: any) => ({
      value: user.userId,
      label: user.userName,
    }));

  const handleOnChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setSketchOrder({
      ...updatedSketchOrder,
      [event.target.name as string]: event.target.value,
    });
  };

  const updateOrder = async () => {
    try {
      const response: any = await dispatch(
        updateSketchOrder(
          updatedSketchOrder.id,
          updatedSketchOrder,
          updatedSketchOrder.version
        )
      );
      props.onClose(response.response);
      dispatch(setReduxMessage('Order updated.'));
    } catch {
      dispatch(setReduxMessage('Error updating order'));
    }
  };

  const AutocompleteInput: React.FC<InputBaseComponentProps> = (props) => {
    const { inputRef, ...other } = props;
    return <AutocompleteSelect {...other} />;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={mobile ? classes.mobileModal : classes.modal}
    >
      <Card className={classes.scrollableContent}>
        <Grid container item xs={12}>
          <Typography variant='h6'>{sketchOrder.projectname}</Typography>
          <Grid container item xs={12}>
            <Grid item xs={8}>
              <Typography variant='body1'>
                {formatDate(sketchOrder.requesteddate)}
              </Typography>
            </Grid>
            <Grid
              item
              id='sketch-order-status'
              style={{ textAlign: 'center' }}
              xs={4}
              justifyContent='flex-end'
            >
              {sketchOrder.freeOrder ? (
                <ChipWrapper
                  label='Free'
                  style={{
                    backgroundColor: '#40ad48',
                    color: '#FFFFFF',
                  }}
                  icon={CheckCircleIcon}
                />
              ) : (
                <SketchOrderStatusChip status={sketchOrder.sketchOrderStatus} />
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            {users && (
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Requester</InputLabel>
                <Input
                  id='requester'
                  type='text'
                  disabled
                  value={
                    users.find(
                      (user: any) => user.userId === sketchOrder.requesterUserId
                    ).userName
                  }
                />
              </FormControl>
            )}
            {updatedSketchOrder.ownerUserId && users && (
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel shrink>Owner</InputLabel>
                <Input
                  fullWidth
                  value={updatedSketchOrder.ownerUserId}
                  onChange={(event: any) => handleOwnerChange(event)}
                  placeholder='Search email address'
                  id='react-select-single'
                  disabled={
                    sketchOrder.sketchOrderStatus !== SketchOrderStatus.Pending
                  }
                  inputComponent={AutocompleteInput}
                  inputProps={{
                    name: 'react-select-single',
                    instanceId: 'react-select-single',
                    simpleValue: true,
                    options: getUsers(users),
                    disableItemRemoval: true,
                  }}
                />
              </FormControl>
            )}
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel>Report Type</InputLabel>
              <Select
                value={updatedSketchOrder.sketchReportType}
                onChange={(
                  event: React.ChangeEvent<{
                    name?: string | undefined;
                    value: unknown;
                  }>
                ) => handleOnChange(event)}
                disabled={
                  sketchOrder.sketchOrderStatus !== SketchOrderStatus.Pending
                }
                input={<Input name='sketchReportType' />}
              >
                <MenuItem value='FullSnap'>FullSnap</MenuItem>
                <MenuItem value='HalfSnap'>HalfSnap</MenuItem>
              </Select>
            </FormControl>

            <CardContent style={{ width: '100%' }}>
              <div className={classes.readOnlyField}>
                <Typography variant='body1'>Address:</Typography>
                <Typography>{sketchOrder.address}</Typography>
              </div>
              <div className={classes.readOnlyField}>
                <Typography variant='body1'>City:</Typography>
                <Typography>{sketchOrder.city}</Typography>
              </div>
              <div className={classes.readOnlyField}>
                <Typography variant='body1'>Region:</Typography>
                <Typography>{sketchOrder.region}</Typography>
              </div>
              <div className={classes.readOnlyField}>
                <Typography variant='body1'>Postcode:</Typography>
                <Typography>{sketchOrder.postcode}</Typography>
              </div>
              <div className={classes.readOnlyField}>
                <Typography variant='body1'>Country:</Typography>
                <Typography>{sketchOrder.country}</Typography>
              </div>
              {sketchOrder.location?.coordinates &&
                sketchOrder.location?.coordinates?.length === 2 && (
                  <div className={classes.readOnlyField}>
                    <Map
                      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_SKETCHOS_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                      loadingElement={<div />}
                      containerElement={<div style={{ height: '350px' }} />}
                      mapElement={
                        <div id='googleMap' style={{ height: '100%' }} />
                      }
                      center={{
                        lat: sketchOrder.location.coordinates[1],
                        lng: sketchOrder.location.coordinates[0],
                      }}
                    />
                  </div>
                )}
            </CardContent>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel>Notes</InputLabel>
              <Input
                id='sketch-order-notes'
                type='text'
                multiline
                value={updatedSketchOrder.notes}
                name='notes'
                onChange={(event: any) => handleOnChange(event)}
                inputProps={{
                  maxLength: 4000,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <CardActions>
          <Button
            onClick={updateOrder}
            color='secondary'
            id='update-sketch-order-button'
          >
            Update Order
          </Button>
          <Button
            onClick={() => {
              props.onClose(updatedSketchOrder);
            }}
          >
            Close
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default withStyles(styles)(UpdateOrderModal);
