import GenericApiClient, { ApiHeadersMap } from "./genericApiClient";

const BASE_URL = `${process.env.REACT_APP_MICROSERVICE_BASE}`;
const ENVIRONMENT = `${process.env.REACT_APP_MICROSERVICE_ENV}`;

const ENVIRONMENTS: { [key: string]: { prefix: string, protocol: "http" | "https" } } = {
  development: { prefix: "dev-", protocol: "https" },
  local: { prefix: "", protocol: "https" },
  production: { prefix: "", protocol: "https" },
} as const;

export const MicroServiceClient = (
    service: "billing", // More services can be added by piping strings like `"billing" | "orders" | "materials"`
    version: number,
    path: string
  ) => {
    const env = ENVIRONMENTS[ENVIRONMENT];

    const url = `${env.protocol}://${env.prefix}${service}-api.${BASE_URL}/api/v${version}`;
    const client = new GenericApiClient(url, true);
  
    return {
      get: <TResponse>(endpoint: string, headers?: ApiHeadersMap) =>
        client.get<TResponse>(`${path}/${endpoint}`, headers),
      post: <TResponse>(endpoint: string, body: any, headers?: ApiHeadersMap) =>
        client.post<BodyInit, TResponse>(`${path}/${endpoint}`, body, headers),
      put: <TResponse>(endpoint: string, body: any, headers?: ApiHeadersMap) =>
        client.put<BodyInit, TResponse>(`${path}/${endpoint}`, body, headers),
    };
  };