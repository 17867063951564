import React, { Component } from 'react';
import dateFormat from 'dateformat';
import Grid from '@material-ui/core/Grid';
import { green } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';
import ExitToApp from '@material-ui/icons/ExitToApp';
// TODO: See PBI 10293
// import GetApp from '@material-ui/icons/GetApp';
import Description from '@material-ui/icons/Description';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ToolTip from '@material-ui/core/Tooltip';
import SketchOrderStatus from '../../lib/SketchOrderStatus.ts';
import SketchOrderStatusChip from '../SketchOrderStatusChip/SketchOrderStatusChip';
import ChipWrapper from '../ChipWrapper';
import SelectableListItem from '../SelectableListItem';

const style = (theme) => ({
    /*
        below are some customizations around how the chip works and just little
        tweaks to make the buttons not escape outside of the Paper and look silly.
     */
    statusIconRoot: {
        width: '100%',
    },
    listItemContentContainer: {
        [theme.breakpoints.down('xs')]: {
            borderBottom: '1px solid black',
            paddingBottom: 8,
        },
    },
    dateTypePriceContainer: {
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'space-around',
        },
    },
});

class SketchOrderListItem extends Component {
    getSketchOrderReport = async () => {
        if (
            this.props.sketchOrder &&
            this.props.sketchOrder.projectId &&
            this.props.sketchOrder.projectClonedForDelivery
        ) {
            const sketchOrderReportDocument =
                await this.props.getSketchOrderReport(
                    this.props.sketchOrder.projectId,
                    this.props.sketchOrder.projectClonedForDelivery
                );
            return sketchOrderReportDocument;
        }
        return null;
    };
    handleOnListItemClick = (sketchOrderId) => {
        this.props.onListItemClick(sketchOrderId);
    };

    handleOnOpenProjectClick = () => {
        this.props.onOpenProjectClick(this.props.sketchOrder.projectId);
    };

    handleOnSelectClick = (checked) => {
        this.props.onSelectSketchOrder(this.props.index, checked);
    };
    handleDownloadSketchOrderReportClick = async () => {
        const sketchOrderReport = await this.getSketchOrderReport();
        const sketchOrderProjectId = this.props.sketchOrder.projectId;
        if (sketchOrderReport && sketchOrderProjectId) {
            await this.props.downloadSketchOrderReport(sketchOrderReport);
            return;
        }
        this.props.onUnableToDownloadDocument(sketchOrderProjectId);
    };
    handleProjectDocumentListClick = () => {
        this.props.onClickDocumentsList(this.props.sketchOrder.projectId);
    };
    costUI = this.props.getPrice(
        this.props.sketchOrder.cost,
        this.props.sketchOrder.sketchOrderStatus
    );
    // Ensures the comma in the address line is properly placed when one or more address fields aren't present
    formattedAddress =
        this.props.sketchOrder.address &&
        (this.props.sketchOrder.city ||
            this.props.sketchOrder.region ||
            this.props.sketchOrder.postcode)
            ? `${this.props.sketchOrder.address},`
            : this.props.sketchOrder.address;
    listItemContent = () => (
        <Grid
            container
            spacing={1}
            alignItems='center'
            id={this.props.sketchOrder.id}
            className={this.props.classes.listItemContentContainer}
        >
            <Grid item sm={4} xs={12}>
                <ListItemText
                    primary={this.props.sketchOrder.projectname}
                    secondary={`${this.formattedAddress} ${this.props.sketchOrder.city} ${this.props.sketchOrder.region} ${this.props.sketchOrder.postcode}`}
                />
            </Grid>
            <Grid
                container
                item
                sm={5}
                xs={12}
                spacing={1}
                className={this.props.classes.dateTypePriceContainer}
            >
                <Grid item sm={6} xs={8}>
                    <ListItemText
                        secondary={dateFormat(
                            this.props.sketchOrder.requesteddate,
                            'mmm d, yyyy h:MM TT'
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={8}>
                    <ListItemText
                        secondary={this.props.sketchOrder.sketchReportType.replace(
                            /([a-z])([A-Z])/g,
                            '$1 $2'
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={4}>
                    {this.props.sketchOrder.freeOrder ? (
                        <Typography style={{ textDecoration: 'line-through' }}>
                            {this.costUI}
                        </Typography>
                    ) : (
                        <ListItemText secondary={this.costUI} />
                    )}
                </Grid>
            </Grid>
            <Grid item sm={2} alignItems='center'>
                {this.props.sketchOrder.rushed && (
                    <Grid container justifyContent='center' alignItems='center'>
                        <img
                            src='/assets/rushicon.svg'
                            alt='Rushed'
                            style={{ height: 8 }}
                        />
                        <Typography
                            variant='button'
                            display='inline'
                            style={{
                                fontStyle: 'oblique 65deg',
                                fontWeight: 600,
                                fontSize: 13,
                            }}
                        >
                            &nbsp;RUSH DELIVERY
                        </Typography>
                    </Grid>
                )}
                {this.props.sketchOrder.freeOrder &&
                (this.props.sketchOrder.sketchOrderStatus ===
                    SketchOrderStatus.Billed ||
                    this.props.sketchOrder.sketchOrderStatus ===
                        SketchOrderStatus.Complete) ? (
                            <ChipWrapper
                            id='freeChip'
                            label='Free'
                            style={{
                                backgroundColor: green[500],
                                color: '#FFFFFF',
                            }}
                            classes={{
                                root: this.props.classes.statusIconRoot,
                                label: this.props.classes.statusIconLabel,
                            }}
                            icon={CheckCircleIcon}
                        />
                    ) : (
                        <SketchOrderStatusChip
                            status={this.props.sketchOrder.sketchOrderStatus}
                            classes={{
                                root: this.props.classes.statusIconRoot,
                                label: this.props.classes.statusIconLabel,
                            }}
                        />
                    )}
            </Grid>
        </Grid>
    );
    render() {
        return (
            <div key={this.props.id}>
                {this.props.selectable ? (
                    <SelectableListItem
                        className='sketch-order'
                        key={this.props.sketchOrder.id}
                        onClick={() =>
                            this.handleOnListItemClick(
                                this.props.sketchOrder.id
                            )
                        }
                        button
                        onSelectClick={(checked) =>
                            this.handleOnSelectClick(this.props.index, checked)
                        }
                        selected={this.props.sketchOrder.selected}
                    >
                        {this.listItemContent()}
                        <ListItemSecondaryAction>
                            {this.props.projectClonedForDelivery && (
                                <ToolTip title='Open project'>
                                    <span>
                                        <IconButton
                                            onClick={() =>
                                                this.handleOnOpenProjectClick(
                                                    this.props.sketchOrder
                                                        .projectId
                                                )
                                            }
                                            aria-label='Open project'
                                        >
                                            <ExitToApp />
                                        </IconButton>
                                    </span>
                                </ToolTip>
                            )}
                            {/* TODO: See PBI 10293 */}
                            {/* {this.props.projectClonedForDelivery && (
                                <ToolTip title='Download Sketch Report'>
                                    <span>
                                        <IconButton
                                            onClick={() =>
                                                this.handleDownloadSketchOrderReportClick()
                                            }
                                            aria-label='Download Sketch Report'
                                        >
                                            <GetApp />
                                        </IconButton>
                                    </span>
                                </ToolTip>
                            )} */}
                            {this.props.projectClonedForDelivery && (
                                <ToolTip title='Project Document List'>
                                    <span>
                                        <IconButton
                                            onClick={() =>
                                                this.handleProjectDocumentListClick()
                                            }
                                            aria-label='Project Document List'
                                        >
                                            <Description />
                                        </IconButton>
                                    </span>
                                </ToolTip>
                            )}
                        </ListItemSecondaryAction>
                    </SelectableListItem>
                ) : (
                    <ListItem
                        className='sketch-order'
                        key={this.props.sketchOrder.id}
                        onClick={() =>
                            this.handleOnListItemClick(
                                this.props.sketchOrder.id
                            )
                        }
                        button
                    >
                        {this.listItemContent()}
                        <ListItemSecondaryAction>
                            {this.props.sketchOrder
                                .projectClonedForDelivery && (
                                <ToolTip title='Open project'>
                                    <span>
                                        <IconButton
                                            onClick={() =>
                                                this.handleOnOpenProjectClick(
                                                    this.props.sketchOrder
                                                        .projectId
                                                )
                                            }
                                            aria-label='Open project'
                                        >
                                            <ExitToApp />
                                        </IconButton>
                                    </span>
                                </ToolTip>
                            )}
                            {/* TODO: See PBI 10293 */}
                            {/* {this.props.sketchOrder
                                .projectClonedForDelivery && (
                                <ToolTip title='Download Sketch Report'>
                                    <span>
                                        <IconButton
                                            onClick={() =>
                                                this.handleDownloadSketchOrderReportClick()
                                            }
                                            aria-label='Download Sketch Report'
                                        >
                                            <GetApp />
                                        </IconButton>
                                    </span>
                                </ToolTip>
                            )} */}
                            {this.props.sketchOrder.projectClonedForDelivery && (
                                <ToolTip title='Project Document List'>
                                    <span>
                                        <IconButton
                                            onClick={() =>
                                                this.handleProjectDocumentListClick()
                                            }
                                            aria-label='Project Document List'
                                        >
                                            <Description />
                                        </IconButton>
                                    </span>
                                </ToolTip>
                            )}
                        </ListItemSecondaryAction>
                    </ListItem>
                )}
            </div>
        );
    }
}

export default withStyles(style)(SketchOrderListItem);
