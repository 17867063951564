import {
    FormControl,
    FormHelperText,
    TextField,
    Typography,
} from '@mui/material';
import React from 'react';
import Section, { SectionProps } from './Section';
import useId from '@mui/material/utils/useId';

const BillingSection = ({
    project,
    validationResults,
    onChange,
}: SectionProps) => {
    const billingEmailId = useId();

    const billingEmailIdHasError =
        validationResults && !validationResults.fields.billingEmail.isValid;

    return (
        <Section id='billing-section'>
            <Typography variant='subtitle2'>Billing Info</Typography>
            <FormControl fullWidth>
                <TextField
                    label='Billing Name'
                    name='billingName'
                    value={project.billingName || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 255,
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    label='Billing Address'
                    name='billingAddress'
                    value={project.billingAddress || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 255,
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    label='Billing City'
                    name='billingCity'
                    value={project.billingCity || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 255,
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    label='Billing State'
                    name='billingState'
                    value={project.billingState || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 100,
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    label='Billing Zipcode'
                    name='billingZip'
                    value={project.billingZip || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 50,
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    label='Billing Phone 1'
                    name='billingPhone'
                    value={project.billingPhone || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 50,
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    label='Billing Phone 2'
                    name='billingPhone2'
                    value={project.billingPhone2 || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 50,
                    }}
                />
            </FormControl>
            <FormControl
                fullWidth
                error={billingEmailIdHasError}
            >
                <TextField
                    id={billingEmailId}
                    error={billingEmailIdHasError}
                    label='Billing Email'
                    name='billingEmail'
                    value={project.billingEmail || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 255,
                    }}
                />
                {billingEmailIdHasError && (
                        <FormHelperText id={billingEmailId}>
                            Email is invalid
                        </FormHelperText>
                    )}
            </FormControl>
        </Section>
    );
};

export default BillingSection;
