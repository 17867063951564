/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import BlockIcon from '@material-ui/icons/Block';
import ApartmentIcon from '@material-ui/icons/Apartment';
import TextField from '@material-ui/core/TextField';
import Tile from '../../Tile/Tile';
import BuildingTypes from '../../../lib/BuildingTypes.ts';
import { ReactComponent as HomeWithGarageIcon } from '../home-with-garage.svg';
import { ReactComponent as HomeIcon } from '../home.svg';
import PinConfirmationMap from './PinConfirmationMap';
import CompactAddressVerification from '../../AddressVerification/CompactAddressVerification';

const styles = (theme) => ({
    icons: {
        width: 48,
        height: 48,
        fill: '#2d6aff',
    },
    roofSnapLogo: {
        height: 48,
        marginTop: `${theme.spacing(4)}px`,
    },
    confirmationMap: {
        border: `solid 1px ${theme.palette.text.secondary}`,
        borderRadius: theme.shape.borderRadius,
        height: 150,
        width: '100%',
        color: `${theme.palette.text.secondary}`,
        '&:hover': {
            backgroundColor: `${theme.palette.secondary[50]}`,
            borderColor: `${theme.palette.secondary.main}`,
            borderWidth: '3px',
            height: 146,
            width: 'calc(100% - 4px)',
            cursor: 'pointer',
            color: `${theme.palette.secondary.main}`,
            [theme.breakpoints.up('sm')]: {
                height: 296,
            },
        },
        [theme.breakpoints.up('sm')]: {
            height: 300,
        },
    },
});

const FirstSketchOrderConfirmation = (props) => {
    const {
        classes,
        selectedSketchOption,
        notes,
        markerLocation,
        handleOnChange,
        address,
        city,
        region,
        postcode,
        country,
        handleFormValidate,
        handlePinConfirmationMapClicked,
        handleSketchOptionConfirmationTileClicked,
    } = props;
    // TODO: look for better way to do this
    const renderOptionTile = (option) => {
        switch (option) {
        case BuildingTypes.MainBuildingOnly:
            return (
                <Tile
                    id={BuildingTypes.MainBuildingOnly}
                    title='Single Family'
                    icon={<HomeIcon className={classes.icons} />}
                    onClick={() =>
                        handleSketchOptionConfirmationTileClicked()
                    }
                />
            );
        case BuildingTypes.MainBuildingWithGarage:
            return (
                <Tile
                    id={BuildingTypes.MainBuildingWithGarage}
                    title='Single-Family With Detached Garage'
                    icon={<HomeWithGarageIcon className={classes.icons} />}
                    onClick={() =>
                        handleSketchOptionConfirmationTileClicked()
                    }
                />
            );
        case BuildingTypes.WholeStructure:
            return (
                <Tile
                    id={BuildingTypes.WholeStructure}
                    title='Commercial / Multi-Family Structure'
                    icon={<ApartmentIcon className={classes.icons} />}
                    onClick={() =>
                        handleSketchOptionConfirmationTileClicked()
                    }
                />
            );
        default:
            return (
                <Tile
                    id={BuildingTypes.None}
                    title='None Selected'
                    icon={<BlockIcon className={classes.icons} />}
                    onClick={() =>
                        handleSketchOptionConfirmationTileClicked()
                    }
                />
            );
        }
    };

    return (
        <React.Fragment>
            <Grid container item spacing={1} alignContent='flex-start'>
                <Grid item xs={12}>
                    <Typography
                        variant='h6'
                        color='secondary'
                        gutterBottom
                        className={classes.text}
                    >
                        Review Your Order
                    </Typography>
                </Grid>
                <Grid container item xs={12} sm={6}>
                    <Grid item xs={12}>
                        <CompactAddressVerification
                            onFormValidate={handleFormValidate}
                            onChange={handleOnChange}
                            address={address}
                            city={city}
                            region={region}
                            postcode={postcode}
                            country={country}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {renderOptionTile(selectedSketchOption)}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <PinConfirmationMap
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_SKETCHOS_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                        loadingElement={<div />}
                        containerElement={
                            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                            <div
                                className={classes.confirmationMap}
                                onClick={() =>
                                    handlePinConfirmationMapClicked()
                                }
                            />
                        }
                        mapElement={
                            <div id='googleMap' style={{ height: '100%' }} />
                        }
                        center={markerLocation}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant='body1'
                        color='textSecondary'
                        gutterBottom
                        className={classes.text}
                    >
                        Notes:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        rows={4}
                        rowsMax={6}
                        variant='outlined'
                        name='notes'
                        value={notes}
                        onChange={handleOnChange}
                        className={classes.notesField}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default withStyles(styles)(FirstSketchOrderConfirmation);
