import {
    GET_SUBSCRIPTION_PLANS_REQUEST,
    GET_SUBSCRIPTION_PLANS_SUCCESS,
    GET_SUBSCRIPTION_PLANS_FAILURE,
} from '../actions/subscriptionPlanActions';

const initialState = {
    plans: [],
};
function subscriptionPlanReducer(state = initialState, action) {
    switch (action.type) {
    case GET_SUBSCRIPTION_PLANS_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
            isFetchingText: 'Loading Plans...',
            ...state,
        });
    case GET_SUBSCRIPTION_PLANS_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            isFetchingText: '',
            plans: [...action.response.value],
        });

    case GET_SUBSCRIPTION_PLANS_FAILURE:
        return Object.assign({}, state, {
            ...state,
            isFetching: false,
        });
    default:
        return state;
    }
}
export default subscriptionPlanReducer;
