import React, { useState } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, Typography } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useRef } from 'react';

import { ReactElement } from 'react';

interface MenuDropDownProps extends WithStyles<typeof styles>{
    setOption: (component: any, index: number) => void,
    navButtons: NavButtonType[],
}

interface NavButtonType {
    index: number;
    title: string;
    component: ReactElement | null;
}

const styles = () => createStyles({
    dropDownText: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        textTransform: 'none',
    },
    menuButton: {
        padding: '3px 10px',
    },
});

export const MenuDropDown = withStyles(styles)((props: MenuDropDownProps) => {
    const [open, setOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0); 

    const {
        classes,
    } = props;

    const handleMenuItemClick = (event: any, index: number, component: ReactElement | null) => {
        setSelectedIndex(index);
        setOpen(false);
        props.setOption(component, index);
    };


    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const anchorRef = useRef<HTMLDivElement>(null);
    const handleClose = (event: React.MouseEvent<Document, MouseEvent> | React.SyntheticEvent) =>  {
        if (!anchorRef) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment>
            <ButtonGroup
                variant="text"
                ref={anchorRef}
                aria-label="split button"
            >
                <Button
                    onClick={handleToggle}
                    endIcon={<ExpandMore style={{ fontSize: '28px' }} />}
                    className={classes.menuButton}
                >
                    <Typography className={classes.dropDownText}>
                        {props.navButtons[selectedIndex].title}
                    </Typography>
                </Button>


            </ButtonGroup>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{zIndex: 10}}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper style={{ width: '170px'}}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {props.navButtons.map((navButton: NavButtonType) => (
                                        <MenuItem
                                            key={navButton.title}
                                            selected={navButton.index === selectedIndex}
                                            id={navButton.title.replace(/\s+/g, '')}

                                            onClick={event => handleMenuItemClick(event, navButton.index, navButton.component)}
                                        >
                                            {navButton.title}
                                        </MenuItem>)
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
});
