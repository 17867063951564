import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Tooltip,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';
import {
  generatePaymentClientToken,
  updatePaymentMethod,
} from 'actions/Payment';
import BraintreeDropinUI from 'components/BraintreeDropinUI';
import { useDispatch, useSelector } from 'react-redux';
import WarningIcon from '@material-ui/icons/Warning';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Alert from '../../../Alert';
import LoggerWrapper from 'lib/Logger';

const styles = createStyles({
  braintreeWrapper: {
    '& .braintree-methods': {
      backgroundColor: '#f2f2f2',
      width: '35em',
    },
  },
  alertIcon: {
    color: '#FFFFFF',
    height: 22,
    width: 22,
  },
});

type PaymentDialogProps = {
  open: boolean;
  useMakeDefaultCheckbox?: boolean | undefined;
  onCancel?: () => void;
  onChoose: (nonce: string, useSubscriptionPaymentMethod?: boolean | undefined) => void;
  dialogTitle: string;
} & WithStyles<typeof styles>;

const PaymentDialog = ({
  open,
  useMakeDefaultCheckbox,
  onCancel,
  onChoose,
  classes,
  dialogTitle,
}: PaymentDialogProps) => {
  const [clientToken, setClientToken] = useState<string>('');
  const [instance, setInstance] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [braintreeError, setBraintreeError] = useState<boolean>(false);
  const [braintreeErrorMessage, setBraintreeErrorMessage] = useState<string>('');
  // if we are using the makeDefault checkbox, have it default to true/checked
  const [makeDefault, setMakeDefault] = useState(!!useMakeDefaultCheckbox);

  const dispatch = useDispatch();

  const { currentUser } = useSelector((state: any) => {
    const { currentUser } = state;
    return {
      currentUser,
    };
  });

  const handlePaymentMethodRequestable = async () => setBraintreeError(false);

  const handleChooseClick = async () => {

    const result = await instance.requestPaymentMethod();

    if (useMakeDefaultCheckbox) {
      if (makeDefault) {
        await dispatch(updatePaymentMethod(currentUser.userId, result.nonce));
      }

      return onChoose(makeDefault ? '' : result.nonce, makeDefault);
    }
    return onChoose(result.nonce)
  };

  const handleCancelClick = () => {
    if (onCancel) onCancel();
  };

  const handleMakeDefaultChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMakeDefault(event.target.checked);
  };

  useEffect(() => {
    // Fetch client token from server to initialize Braintree
    const getClientToken = async () => {
      try {
        const response: any = await dispatch(
          generatePaymentClientToken(currentUser.userId)
        );
        const paymentClientToken = response.response.paymentClientToken;
        setClientToken(paymentClientToken);
      } catch (err) {
        console.error(err);
        setErrorMessage('Error fetching client token');
        setBraintreeError(true);
        setBraintreeErrorMessage(
          'There was an error fetching your payment information. If the problem persists, please contact Roofsnap Support.'
        );
        LoggerWrapper.log(err);
      }
    };

    getClientToken();
  }, [currentUser.userId, dispatch, errorMessage]);

  return (
    <Dialog open={open}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        {clientToken && (
          <div className={classes.braintreeWrapper}>
            <BraintreeDropinUI
              options={{ authorization: clientToken }}
              onInstance={(instance: any) => setInstance(instance)}
              onPaymentMethodRequestable={handlePaymentMethodRequestable}
            />
            {useMakeDefaultCheckbox &&
              <React.Fragment>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={makeDefault}
                      onChange={handleMakeDefaultChange}
                    />
                  }
                  label='Make this my default payment method'
                />
                <Tooltip title='This will enable you to make payments through a single card including your subscription, measurement orders and image purchases.'>
                  <InfoOutlined />
                </Tooltip>
              </React.Fragment>
            }
          </div>
        )}
        <Alert
          variant='error'
          icon={<WarningIcon className={classes.alertIcon} />}
          title={''}
          message={braintreeErrorMessage}
          open={braintreeError}
          style={{
            root: {
              maxWidth: 1000,
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button
          disabled={braintreeError}
          variant='contained'
          color='primary'
          onClick={handleChooseClick}
        >
          Choose
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(PaymentDialog);
