import React, {
    IframeHTMLAttributes,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { getDocumentFormHtml } from '../../actions/documentActions';

type FormHtmlDialogProps = {
    onRequestClose?: () => void;
    projectId: string;
    category?: string;
    documentId?: string;
    usesLegacySigning: boolean;
    open: boolean;
    isFetching?: boolean;
    estimateId?: string;
    usesESignHtml?: boolean;
};

type IFrameProps = Partial<IframeHTMLAttributes<HTMLIFrameElement>> | null;

const FormHtmlDialog = (props: FormHtmlDialogProps) => {
    const [iFrameProps, setIFrameProps] = useState<IFrameProps>(null);
    const iFrameRef = useRef<HTMLIFrameElement>(null);

    const dispatch = useDispatch();

    const {
        open,
        isFetching,
        projectId,
        category,
        documentId,
        estimateId,
        usesLegacySigning,
        usesESignHtml,
        onRequestClose,
    } = props;

    const handleRequestClose = () => {
        if (isFetching) {
            return;
        }

        if (onRequestClose) onRequestClose();
    };

    const handleIFrameLoad = () => {
        if (!usesESignHtml) return;

        const eSignWorkflowButton =
            iFrameRef.current?.contentDocument?.getElementById(
                'workflow-e-sign-link-button'
            );

        if (eSignWorkflowButton) eSignWorkflowButton.style.display = 'none';
    };

    useEffect(() => {
        const getIFrameSourceDocument = async () => {
            if (!documentId || iFrameProps !== null) {
                return;
            }

            const result: any = await dispatch(
                getDocumentFormHtml(documentId, usesESignHtml)
            );

            setIFrameProps({ srcDoc: result.response });
        };

        const getIFrameSourceUrl = () => {
            let iframeSrc = `${process.env.REACT_APP_ROOFSNAP_ONLINE_HOST}/projects/${category}?&projectId=${projectId}&Sign=Yes`;

            if (estimateId) {
                iframeSrc += `&estimateids=${estimateId}`;
            }

            setIFrameProps({ src: iframeSrc });
        };

        if (usesLegacySigning) {
            getIFrameSourceUrl();
        } else {
            getIFrameSourceDocument();
        }

        return () => {
            setIFrameProps(null);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentId, projectId]);

    return (
        <Dialog fullScreen onClose={handleRequestClose} open={open}>
            <AppBar>
                <Toolbar style={{ flex: 1 }}>
                    <IconButton
                        id='closeIconButton'
                        type='submit'
                        color='inherit'
                        onClick={handleRequestClose}
                        aria-label='Close'
                    >
                        <CloseIcon style={{ fill: 'white' }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent
                style={{
                    position: 'relative',
                    marginTop: 64,
                }}
            >
                {iFrameProps && (
                    <iframe
                        {...iFrameProps}
                        ref={iFrameRef}
                        title='Capture Signature'
                        allowFullScreen
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            top: '0',
                            left: '0',
                            right: '0',
                            bottom: '53px',
                            border: 0,
                        }}
                        onLoad={handleIFrameLoad}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default FormHtmlDialog;
