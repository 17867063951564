import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

const styles = {
    button: {
        margin: 8,
    },
};

const ToolbarButton = (props) => {
    const title = props.disabled ? props.disabledTooltip : props.tooltip;

    const button = (
        <div>
            <Button color={props.color} onClick={props.onClick} style={styles.button} disabled={props.disabled}>
                {props.text}
            </Button>
        </div>);

    return (title ? <Tooltip title={title}>{button}</Tooltip> : button);
};

ToolbarButton.propTypes = {
    text: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    disabled: PropTypes.bool,
    disabledTooltip: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string,
};

ToolbarButton.defaultProps = {
    tooltip: '',
    disabled: false,
    disabledTooltip: '',
    color: 'primary',
};

export default ToolbarButton;
