import React from 'react';
import AppStoreIcon from './AppStore.svg';
import GooglePlayBadge from './google-play-badge.svg';

const googlePlayStoreUrl = 'https://play.google.com/store/apps/details?id=com.roofsnap.schemingorca&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
const appleAppStoreUrl = 'https://itunes.apple.com/us/app/roofsnap-max/id869343232?mt=8';

const style = {
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 500,
    },
    googlePlayBadge: {
        flex: 1,
        paddingLeft: 10,
        paddingRight: 10,
    },
    googlePlayBadgeImg: {
        width: '100%',
        height: 65,
    },
    appleAppStoreBadge: {
        flex: 1,
        paddingLeft: 10,
        paddingRight: 10,
    },
    appleAppStoreBadgeImg: {
        width: '100%',
        height: 65,
    },
};

const MobileBadges = () => (
    <div style={style.wrapper}>
        <a
            href={googlePlayStoreUrl}
            style={style.googlePlayBadge}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img
                alt="Get it on Google Play"
                src={GooglePlayBadge}
                style={style.googlePlayBadgeImg}
            />
        </a>
        <a
            href={appleAppStoreUrl}
            style={style.appleAppStoreBadge}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img
                alt="Get it on App Store"
                src={AppStoreIcon}
                style={style.appleAppStoreBadgeImg}
            />
        </a>
    </div>
);

export default MobileBadges;
