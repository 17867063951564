import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { setNoMatchRoute } from '../../actions/NoMatchActions';

const styles = theme => ({
    root: {
        paddingTop: theme.spacing(3),
    },
    card: {
        minWidth: 275,
        maxWidth: '700px',
        margin: '0 auto',
        padding: '20px',
    },
    headline: {
        margin: '10px 0 20px',
    },
    message: {
        margin: '10px 0',
    },
});

class NoMatch extends Component {
    componentDidMount() {
        this.props.dispatch(setNoMatchRoute(`${this.props.location.pathname}${this.props.location.search}`));
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography className={classes.headline} variant="h2">
                            Oops!
                        </Typography>
                        <Typography className={classes.message} variant="h5">
                            We can't seem to find the page you're looking for.
                        </Typography>
                        <Typography type="paragraph">
                            Error code: 404
                        </Typography>
                        <Typography>
                            Click <a href="/projects">here</a> to return to the projects page.
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

NoMatch.propTypes = {
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
        card: PropTypes.string.isRequired,
        headline: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired,
    }).isRequired,
};

export default withRouter(connect(null)(withStyles(styles)(NoMatch)));
