import { SET_NO_MATCH_ROUTE } from '../actions/NoMatchActions';
import {
    CREATE_BULK_SKETCH_ORDER_SUCCESS,
    CREATE_SKETCH_ORDER_SUCCESS,
} from '../actions/SketchOrderActions';

export default ({ getState }) => next => (action) => {
    let event = {
        event: action.type,
    };

    switch (action.type) {
    case SET_NO_MATCH_ROUTE: {
        event = Object.assign({}, event, {
            payload: action.route,
        });
        break;
    }
    case CREATE_SKETCH_ORDER_SUCCESS:
    case CREATE_BULK_SKETCH_ORDER_SUCCESS: {
        const { app } = getState();
        const ref = app.ref ? app.ref : 'web';
        event = Object.assign({}, event, {
            event: CREATE_SKETCH_ORDER_SUCCESS,
            source: ref,
        });
        break;
    }
    default:
        break;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
    return next(action);
};
