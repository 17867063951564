import React, { PropsWithChildren } from 'react';
import { SettingsRowProps } from './SettingsRow';
import { Paper, createStyles, WithStyles, withStyles } from '@material-ui/core';

interface RowContainerProps extends WithStyles<typeof styles> {
    settingsRowProps?: SettingsRowProps;
    rowContainerStyle?: string;
}

const styles = () =>
    createStyles({
        rowContainer: {
            width: '100%',
            border: '1px solid #00112219',
            '& .last-child': {
                border: 'none'
            },
            marginTop: 10
        }
    });

const RowContainer = (props: PropsWithChildren<RowContainerProps>) => {
    const { classes } = props;

    return (
        <Paper className={`${classes.rowContainer} ${props.rowContainerStyle}`} elevation={0}>
            {props.children}
        </Paper>
    );
};

export default withStyles(styles)(RowContainer);