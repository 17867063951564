import find from 'lodash/find';
import * as ActionTypes from '../actions/ProjectImagesActions';

function projectImages(state = {
    isFetching: false,
    totalResults: 0,
    totalPages: 0,
    currentPage: 1,
    images: [],
    selectedImage: {},
    projectId: null,
    message: '',
}, action) {
    switch (action.type) {
    case ActionTypes.GET_PROJECT_IMAGES_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
            totalResults: 0,
            totalPages: 0,
            currentPage: 1,
            selectedImage: {},
            images: [],
            projectId: null,
        });
    case ActionTypes.GET_PROJECT_IMAGES_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            totalResults: action.response.totalResults,
            totalPages: action.response.totalPages,
            currentPage: action.response.page,
            images: action.response.resourceList,
            projectId: find(action.response.resourceList, 'projectId').projectId,
            selectedImage: action.response.resourceList[0],
        });
    case ActionTypes.GET_PROJECT_IMAGES_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            totalResults: 0,
            totalPages: 0,
            currentPage: 1,
            selectedImage: {},
            projectId: null,
            images: [],
        });
    case ActionTypes.GET_PROJECT_IMAGE_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
            selectedImage: {},
        });
    case ActionTypes.GET_PROJECT_IMAGE_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            projectId: action.response.projectId,
            selectedImage: {
                imageUrl: action.response.imageUrl,
                id: action.response.id,
                projectId: action.response.projectId,
            },
        });
    case ActionTypes.GET_PROJECT_IMAGE_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            totalResults: 0,
            totalPages: 0,
            currentPage: 1,
            selectedImage: {},
        });
    case ActionTypes.SELECT_IMAGE:
        return Object.assign({}, state, {
            selectedImage: action.image,
        });
    case ActionTypes.DELETE_PROJECT_IMAGE_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case ActionTypes.DELETE_PROJECT_IMAGE_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            images: [],
        });
    case ActionTypes.DELETE_PROJECT_IMAGE_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            message: action.error,
        });
    case ActionTypes.CREATE_PROJECT_IMAGE_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case ActionTypes.CREATE_PROJECT_IMAGE_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case ActionTypes.CREATE_PROJECT_IMAGE_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            message: action.error,
        });
    default:
        return state;
    }
}

export default projectImages;
