import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        height: '100%',
        textAlign: 'center',
        margin: 'auto',
        padding: theme.spacing(2),
        maxWidth: 600,
    },
    spacing: {
        marginBottom: theme.spacing(2),
    },
    image: {
        maxWidth: 300,
    },
    phone: {
        whiteSpace: 'nowrap',
    },
    buttons: {
        margin: `auto ${theme.spacing(1)}px`,
    },
});

const SignUpError = (props) => {
    const callRoofSnap = () => {
        window.open('tel:1-877-766-3762');
    };

    const emailRoofSnap = () => {
        window.open(`${process.env.REACT_APP_SUBMIT_SUPPORT_REQUEST_URL}`, '_blank');
    };

    return (
        <Grid
            container
            alignItems="center"
            justify="center"
            className={props.classes.root}
        >
            <Grid
                item
            >
                <img
                    src="/assets/error-image.png"
                    alt="error"
                    className={`${props.classes.spacing} ${props.classes.image}`}
                />
                <Typography
                    variant="h5"
                    gutterBottom
                    className={props.classes.spacing}
                >
                    Something Went Wrong
                </Typography>
                <Typography
                    gutterBottom
                    className={props.classes.spacing}
                >
                    So sorry. It seems something went wrong getting you started. Get in touch with us so we can help!
                </Typography>
                <Button
                    color="secondary"
                    variant="outlined"
                    className={props.classes.buttons}
                    onClick={callRoofSnap}
                >
                    Call <span className={props.classes.phone}> 1-877-766-3762</span>
                </Button>
                <Button
                    color="secondary"
                    variant="outlined"
                    className={props.classes.buttons}
                    onClick={emailRoofSnap}
                >
                    Contact Support
                </Button>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(SignUpError);
