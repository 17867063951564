import React, { useState } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { Button, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, Typography, useTheme, useMediaQuery, } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useRef } from 'react';
import Office from '../../lib/Models/Office';

interface OfficeDropDownButtonProps extends WithStyles<typeof styles> {
    setOffice: (index: number) => void,
    offices: Office[],
    selectedOfficeIndex: number,
};

const styles = () => createStyles({
    dropDownGrid: {
        zIndex: 10,
        width: '230px',
        paddingLeft: '10px',
    },
    dropDownMenu: {
        width: '260px',
        color: '#1E79D6',
        borderRadius: '28px',
        borderWidth: '2px',
        borderColor: '#1E79D6',
        justifyContent: 'space-between',
        padding: '8px 20px',
        marginLeft: '19px',
    },
    mobileDropDownMenu: {
        padding: '2px 5px',
    },
    dropDownOutline: {
        width: '245px',
        textAlignment: 'left',
        padding: '8px',
    },
    dropDownText: {
        fontFamily: 'Roboto',
        fontSize: '15px',
        fontWeight: 500,
        textTransform: 'none',
    },
    mobileDropDownText: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontWeight: 500,
        textTransform: 'none',
    },
});

const OfficeDropDownButton = (props: OfficeDropDownButtonProps) => {
    const [open, setOpen] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = useState(props.selectedOfficeIndex);

    const {
        classes,
    } = props;

    const handleMenuItemClick = (event: any, index: number) => {
        setSelectedIndex(index);
        setOpen(false);
        props.setOffice(index);
    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const anchorRef = useRef<HTMLDivElement>(null);
    const handleClose = (event: React.MouseEvent<Document, MouseEvent> | React.SyntheticEvent) => {
        if (!anchorRef) {
            return;
        }

        setOpen(false);
    };

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <React.Fragment>
            <ButtonGroup
                variant={mobile ? 'text' : 'outlined'}
                ref={anchorRef}
                aria-label="split button"
                className={mobile ?
                    undefined :
                    classes.dropDownOutline
                }
            >
                <Button
                    className={mobile ?
                        classes.mobileDropDownMenu :
                        classes.dropDownMenu
                    }
                    id="office-drop-down-menu"
                    onClick={handleToggle}
                    endIcon={<ExpandMore style={{ fontSize: '28px' }} />}
                >
                    <Typography noWrap className={mobile ?
                        classes.mobileDropDownText :
                        classes.dropDownText
                    }
                    >
                        {
                            props.offices[selectedIndex].officeName.length > 19 ?
                                props.offices[selectedIndex].officeName.slice(0, 19) :
                                props.offices[selectedIndex].officeName
                        }
                    </Typography>
                </Button>


            </ButtonGroup>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                className={classes.dropDownGrid}
                style={mobile ? { margin: 0, width: 170 } : undefined}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper style={mobile ? { width: '170px' } : undefined}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {props.offices.map((office, index) => (
                                        <MenuItem
                                            id={"office-" + index.toString()}
                                            key={office.id}
                                            selected={index === selectedIndex}
                                            onClick={event => handleMenuItemClick(event, index)}
                                        >
                                            {office.officeName}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
};

export default withStyles(styles)((OfficeDropDownButton));
