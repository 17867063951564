import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import grey from '@material-ui/core/colors/grey';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import Input from '@material-ui/core/Input';
import List from '@material-ui/core/List';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import { cloneProject } from '../../actions/ProjectActions';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';
import CloneProjectModalListItem from './CloneProjectModalListItem';

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    dialogPaper: {
        height: '100%',
        width: '100%',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    subheading: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    title: {
        marginTop: theme.spacing(2),
    },
    input: {
        boxSizing: 'border-box',
        padding: theme.spacing(1),
        backgroundColor: grey[100],
        borderRadius: '3px',
        marginBottom: theme.spacing(2),
        '&::before': {
            borderBottom: 'none',
        },
    },
});
class CloneProjectModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmCloneProjectOpen: false,
            cloneProjectSelectionIsOpen: props.cloneProjectSelectionIsOpen,
            userProjectIsBeingClonedTo: {
                userId: null,
                userDisplayName: null,
            },
            searchByText: '',
            clonedProjectName: '',
            successfullyClonedProject: [],
        };
    }

    componentDidMount() {
        this.setDefaultClonedProjectName();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.cloneProjectSelectionIsOpen !== this.props.cloneProjectSelectionIsOpen) {
            this.setCloneProjectSelectionIsOpen();
        }
        if (prevProps.projectName !== this.props.projectName) {
            this.setDefaultClonedProjectName();
        }
    }

    setDefaultClonedProjectName = () => {
        if (this.props.projectName) {
            this.setState({ clonedProjectName: `(copy) ${this.props.projectName}` });
        } else {
            this.setState({ clonedProjectName: '' });
        }
    };

    setCloneProjectSelectionIsOpen = () => {
        const { cloneProjectSelectionIsOpen } = this.props;
        this.setState({ cloneProjectSelectionIsOpen });
    };

    handleCloneSelectionClick = async (event, user) => {
        event.preventDefault();
        this.setState({
            userProjectIsBeingClonedTo: {
                userId: user.id,
                userDisplayName: user.displayString,
            },
            cloneProjectSelectionIsOpen: false,
            confirmCloneProjectOpen: true,
        });
    };

    handleCloneConfirmationClick = async (event) => {
        event.preventDefault();
        const response = await this.props.dispatch(cloneProject(this.props.projectId, this.state.userProjectIsBeingClonedTo.userId, this.state.clonedProjectName));
        if (response.error) {
            this.props.dispatch(setReduxMessage(`Sorry, we were unable to clone the project to ${this.state.userProjectIsBeingClonedTo.userDisplayName}.`));
        } else {
            // this.props.dispatch(setReduxMessage(`The project was successfully cloned to ${this.state.userProjectIsBeingClonedTo.userDisplayName}.`));
            this.setState({
                successfullyClonedProject: [...this.state.successfullyClonedProject, { userId: this.state.userProjectIsBeingClonedTo.userId, projectId: response.response.id }],
            });
        }
        this.setState({ confirmCloneProjectOpen: false, cloneProjectSelectionIsOpen: true });
    };

    handleCloneProjectConfirmationClose = (e) => {
        e.preventDefault();
        this.setState({ confirmCloneProjectOpen: false, cloneProjectSelectionIsOpen: true });
    };

    handleCloneProjectClose = (e) => {
        e.persist();
        this.setState({
            successfullyClonedProject: [],
        }, () => this.props.handleCloneProjectClose(e));
    };

    handleGoToClonedProject = (e, projectId) => {
        e.persist();
        this.handleCloneProjectClose(e);
        this.props.history.push(`/projects/${projectId}`);
    }

    SetClonedProjectName = (e) => {
        this.setState({ clonedProjectName: e.currentTarget.value });
    };

    SetSearchByText = (e) => {
        this.setState({ searchByText: e.currentTarget.value });
    };

    renderListItem = (option) => {
        const successfullyCloned = !!this.state.successfullyClonedProject.find(i => i.userId === option.id);
        const clonedProject = this.state.successfullyClonedProject.find(i => i.userId === option.id) || null;
        return (
            <CloneProjectModalListItem
                key={option.id}
                handleCloneSelectionClick={this.handleCloneSelectionClick}
                handleGoToClonedProject={this.handleGoToClonedProject}
                successfullyCloned={successfullyCloned}
                clonedProject={clonedProject}
                option={option}
            />
        );
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog
                    open={this.state.cloneProjectSelectionIsOpen}
                    onClose={e => this.handleCloneProjectClose(e)}
                    maxWidth="sm"
                    classes={{
                        paper: this.props.classes.dialogPaper,
                    }}
                >
                    <DialogTitle id="responsive-dialog-title" className={classes.title}>
                        Clone project
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="subtitle1" className={this.props.classes.subheading}>
                            Name the cloned project
                        </Typography>
                        <Input
                            placeholder="Type cloned project name here"
                            onChange={this.SetClonedProjectName}
                            value={this.state.clonedProjectName}
                            fullWidth
                            className={classes.input}
                        />
                        <Typography variant="subtitle1" className={this.props.classes.subheading}>
                            Clone the project to a user in your office
                        </Typography>
                        <Input
                            placeholder="Filter by user..."
                            onChange={this.SetSearchByText}
                            value={this.state.searchByText}
                            fullWidth
                            className={classes.input}
                        />
                        <div className={this.props.classes.root}>
                            <List component="nav">
                                {
                                    this.props.officeUsers.filter(o => o.displayString.toLowerCase().includes(this.state.searchByText.toLowerCase())).map(this.renderListItem)
                                }
                            </List>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={e => this.handleCloneProjectClose(e)} className={classes.button}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <ConfirmationModal
                    title="Clone Project"
                    description={this.state.userProjectIsBeingClonedTo ? `Are you sure you want to give a copy of this project to ${this.state.userProjectIsBeingClonedTo.userDisplayName}?` : ''}
                    handleConfirm={this.handleCloneConfirmationClick}
                    confirmText="Clone"
                    isOpen={this.state.confirmCloneProjectOpen}
                    handleClose={this.handleCloneProjectConfirmationClose}
                />
            </div>
        );
    }
}

CloneProjectModal.propTypes = {
    classes: PropTypes.shape({
        dialogPaper: PropTypes.string.isRequired,
        form: PropTypes.string.isRequired,
        root: PropTypes.string.isRequired,
        subheading: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        input: PropTypes.string.isRequired,
        listItem: PropTypes.string.isRequired,
        cloneButton: PropTypes.string.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    officeUsers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        displayString: PropTypes.string,
    })),
    cloneProjectSelectionIsOpen: PropTypes.bool.isRequired,
    projectId: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
    handleCloneProjectClose: PropTypes.func.isRequired,
};

CloneProjectModal.defaultProps = {
    officeUsers: [],
};

export default withRouter(withStyles(styles)(CloneProjectModal));
