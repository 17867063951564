import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const style = {
    container: {
        minWidth: '100%',
        marginTop: '25px',
    },
    textArea: {
        minWidth: '100%',
    },
    header: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    buttons: {
        marginTop: '5px',
    },
};

class ProjectNotesCard extends Component {
    getTitle = () => (this.props.modified ? `${this.props.cardTitle}*` : this.props.cardTitle);

    render() {
        const {
            cardText,
            cardTitle,
            type,
            textChange,
            handleReset,
            handleSave,
        } = this.props;

        return (
            <div style={style.container}>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">{this.getTitle()}</Typography>
                        <TextField
                            name={type}
                            style={style.textArea}
                            value={cardText}
                            multiline
                            onChange={textChange}
                            autoFocus
                        />
                    </CardContent>
                    <CardActions>
                        <Button
                            style={style.buttons}
                            onClick={() => handleReset(type)}
                        >
                            Reset
                        </Button>
                        <Button
                            style={style.buttons}
                            onClick={() => handleSave(type, cardTitle)}
                        >
                            Save
                        </Button>
                    </CardActions>
                </Card>
            </div>
        );
    }
}

ProjectNotesCard.propTypes = {
    cardTitle: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    cardText: PropTypes.string,
    handleReset: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    textChange: PropTypes.func.isRequired,
    modified: PropTypes.bool.isRequired,
};

ProjectNotesCard.defaultProps = {
    cardText: '',
};

export default ProjectNotesCard;
