import { CALL_API } from '../middleware/api';

export const GET_MATERIAL_ITEMS_REQUEST = 'GET_MATERIAL_ITEMS_REQUEST';
export const GET_MATERIAL_ITEMS_SUCCESS = 'GET_MATERIAL_ITEMS_SUCCESS';
export const GET_MATERIAL_ITEMS_FAILURE = 'GET_MATERIAL_ITEMS_FAILURE';

export const getMaterialItemsByOfficeId = (officeId, params) => ({
    [CALL_API]: {
        types: [GET_MATERIAL_ITEMS_REQUEST, GET_MATERIAL_ITEMS_SUCCESS, GET_MATERIAL_ITEMS_FAILURE],
        endpoint: `offices/${officeId}/searchmaterialitems`,
        params,
    },
});
