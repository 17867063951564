import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';

const isEmpty = prop => (
    prop === null ||
    prop === undefined ||
    prop === true ||
    (prop.constructor === Object && Object.keys(prop).length === 0)
);
const style = {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    height: '93vh',
};

const Loading = loadingProp => WrappedComponent => (class LoadingHOC extends Component {
    render() {
        return isEmpty(this.props[loadingProp]) ?
            <div style={style}>
                <CircularProgress size={150} thickness={3} />
            </div> :
            <WrappedComponent {...this.props} />;
    }
});


export default Loading;
