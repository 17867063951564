import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Tab, Tabs, Typography } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import PhoneIcon from '@material-ui/icons/Phone';
import PriceOptionsModel from '../../lib/Models/PriceOptionsModel';
import PriceOption from './PriceOption';
import BottomGridMobile from './BottomGridMobile';
import PricingDialog from './PricingDialog';


const styles = () => ({
    root: {
        overflow: 'hidden',
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: '100%',
    },
    mobileColumn: {
        width: '86vw',
    },
    title: {
        color: '#000000DE',
        textAlign: 'center',
        fontSize: '18px',
        fontWeight: 'light',
    },
    column: {
        height: '90vh',
        width: '90vw',
    },
    mobileContainer: {
        width: '310px',
        textAlign: 'center',
    },
    contact: {
        textAlign: 'right',
        width: '310px',
        padding: '65px 0px 18px 0px',
    },
    customPlans: {
        color: '#005CAA',
        fontSize: '10px',
        letterSpacing: '1.5',
    },
    phoneNumber: {
        color: '#012B50',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    phoneIcon: {
        color: '#012B50',
        fontSize: '16px',
        fontWeight: 'bold',
        verticalAlign: 'text-bottom',
    },
    tabContainer: {
        paddingBottom: '10px',
        position: 'fixed',
        top: '112px',
        zIndex: '100',
        width: '100%',
    },
    tabs: {
        color: '#005CAA',
        backgroundColor: '#ffffff',
        boxShadow: '2px 2px #88888826',
        border: '1px solid #0000001f',
        borderRadius: '2px',
        fontSize: '14px',
        width: '100%',
    },
    tabLabel: {
        fontSize: '10px',
    },
    inkBar: {
        background: '#005CAA',
        height: '4px',
        marginTop: '-4px',
    },
    bottomGrid: {
        padding: '10px 0px 15px 0px',
    },
});

const MobileSlider = (props) => {
    const [index, setIndex] = useState(0);
    const [dialogOpen, handleDialog] = useState(false);
    const [dialogId, setDialogId] = useState('');
    const handleMoreClick = (id) => {
        setDialogId(id);
        handleDialog(true);
    };
    const handlePricingDialogClose = () => {
        handleDialog(false);
        setDialogId('');
    };
    // eslint-disable-next-line no-unused-vars
    const handleChange = (event, value) => {
        setIndex(value);
    };
    const handleChangeIndex = (i) => {
        setIndex(i);
    };
    const renderDialog = () =>
        (
            <PricingDialog
                open={dialogOpen}
                onClose={handlePricingDialogClose}
                id={dialogId}
            />
        );

    const {
        classes,
        monthlyPrice,
    } = props;
    return (
        <Grid container direction="column" xs={12} justify="center" alignItems="center" style={{ width: '100%' }}>
            <Grid container item fullWidth className={classes.tabContainer} justify="center">
                <Tabs value={index} fullWidth onChange={handleChange} className={classes.tabs} classes={{ indicator: classes.inkBar }}>
                    <Tab className={classes.tabLabel} label="Non-Subscriber" />
                    <Tab className={classes.tabLabel} label="Monthly" />
                    <Tab className={classes.tabLabel} label="Starter" />
                    <Tab className={classes.tabLabel} label="Pro" />
                    <Tab className={classes.tabLabel} label="Enterprise" />
                </Tabs>
            </Grid>

            <Grid container item direction="column" className={classes.contact} xs={12} alignItems="right">
                <Grid item xs>
                    <Typography className={classes.customPlans}>FOR CUSTOM PLANS</Typography>
                </Grid>
                <Grid item container direction="row" justify="flex-end" spacing={1}>
                    <Grid item>
                        <PhoneIcon className={classes.phoneIcon} />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.phoneNumber}>
                            877.766.3762
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item container className={classes.mobileContainer} justify="center">
                <SwipeableViews enableMouseEvents ignoreNativeScroll index={index} onChangeIndex={handleChangeIndex}>
                    {PriceOptionsModel.map(opt =>
                        (
                            <Grid container item direction="column" className={classes.mobileContainer} justify="center">
                                <Grid container item justify="center" className={classes.mobileContainer}>
                                    <PriceOption {...opt} key={monthlyPrice} handleMoreClick={() => handleMoreClick(opt.id)} />
                                </Grid>
                                <Grid direction="row" container item alignContent="center" justify="center" className={classes.bottomGrid}>
                                    <BottomGridMobile />
                                </Grid>
                            </Grid>
                        ))}
                </SwipeableViews >
            </Grid>

            {renderDialog()}
        </Grid>
    );
};

MobileSlider.propTypes = {
    classes: PropTypes.shape({
    }).isRequired,
};

const mapStateToProps = (state) => {
    const {
        sketchOrderPricingReducer,
    } = state;

    const { fullSnapPriceGrid } = sketchOrderPricingReducer;

    return {
        fullSnapPriceGrid,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(MobileSlider));
