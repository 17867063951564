import { CALL_API, httpMethod } from '../middleware/api';
import { SET_REDUX_MESSAGE } from './ReduxMessagesActions';

export const PUT_ESTIMATE_ITEM_REQUEST = 'PUT_ESTIMATE_ITEM_REQUEST';
export const PUT_ESTIMATE_ITEM_SUCCESS = 'PUT_ESTIMATE_ITEM_SUCCESS';
export const PUT_ESTIMATE_ITEM_FAILURE = 'PUT_ESTIMATE_ITEM_FAILURE';
export const CREATE_ESTIMATE_ITEM_REQUEST = 'CREATE_ESTIMATE_ITEM_REQUEST';
export const CREATE_ESTIMATE_ITEM_SUCCESS = 'CREATE_ESTIMATE_ITEM_SUCCESS';
export const CREATE_ESTIMATE_ITEM_FAILURE = 'CREATE_ESTIMATE_ITEM_FAILURE';
export const DELETE_ESTIMATE_ITEM_REQUEST = 'DELETE_ESTIMATE_ITEM_REQUEST';
export const DELETE_ESTIMATE_ITEM_SUCCESS = 'DELETE_ESTIMATE_ITEM_SUCCESS';
export const DELETE_ESTIMATE_ITEM_FAILURE = 'DELETE_ESTIMATE_ITEM_FAILURE';

export const createEstimateItem = (projectId, estimateId, estimateItem) => ({
    [CALL_API]: {
        types: [
            CREATE_ESTIMATE_ITEM_REQUEST,
            CREATE_ESTIMATE_ITEM_SUCCESS,
            CREATE_ESTIMATE_ITEM_FAILURE,
            SET_REDUX_MESSAGE,
        ],
        endpoint: `projects/${projectId}/estimates/${estimateId}/estimateitems`,
        method: httpMethod.POST,
        body: estimateItem,
    },
});

export const putEstimateItem = (projectId, id, estimateItem, version) => ({
    [CALL_API]: {
        types: [
            PUT_ESTIMATE_ITEM_REQUEST,
            PUT_ESTIMATE_ITEM_SUCCESS,
            PUT_ESTIMATE_ITEM_FAILURE,
            SET_REDUX_MESSAGE,
        ],
        endpoint: `projects/${projectId}/estimateitems/${id}`,
        method: httpMethod.PUT,
        body: estimateItem,
        header: { version },
    },
});

export const deleteEstimateItem = (projectId, estimateItemId) => ({
    [CALL_API]: {
        types: [
            DELETE_ESTIMATE_ITEM_REQUEST,
            DELETE_ESTIMATE_ITEM_SUCCESS,
            DELETE_ESTIMATE_ITEM_FAILURE,
            SET_REDUX_MESSAGE,
        ],
        endpoint: `projects/${projectId}/estimateitems/${estimateItemId}`,
        method: httpMethod.DELETE,
    },
});
