import React, { useState, useEffect } from 'react';
import Input from '@material-ui/core/Input';

const MeasurementsInput = (props) => {
    const [value, setValue] = useState({ ...props.value });
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);
    const handleValueChange = (event) => {
        setValue(event.target.value);
        if (!Number.isNaN(event.target.value)) {
            props.handleChange(event);
        }
    };
    return (
        <Input
            id={props.id}
            style={props.style}
            value={value}
            disabled={props.disabled}
            onChange={handleValueChange}
        />
    );
};

export default MeasurementsInput;
