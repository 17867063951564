import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as EllipseSvg } from '../Icons/Ellipse.svg';

const blueVariant = {
    root: {
        borderColor: '#70BDFF',
        background: '#F4FAFE',
    },
    ellipse: {
        fill: '#056CF2',
    },
    label: {
        color: '#056CF2',
    },
};

const grayVariant = {
    root: {
        borderColor: '#D9D9D9',
        background: '#F3F3F3',
    },
    ellipse: {
        fill: '#555555',
    },
    label: {
        color: '#555555',
    },
};

const greenVariant = {
    root: {
        borderColor: '#9AE09F',
        background: '#F3FCF4',
    },
    ellipse: {
        fill: '#149718',
    },
    label: {
        color: '#149718',
    },
};

const orangeVariant = {
    root: {
        borderColor: '#EDCF7B',
        background: '#FFFCF5',
    },
    ellipse: {
        fill: '#E58F35',
    },
    label: {
        color: '#E58F35',
    },
};

const redVariant = {
    root: {
        borderColor: '#F38092',
        background: '#FFF2F4',
    },
    ellipse: {
        fill: '#B3261E',
    },
    label: {
        color: '#B3261E',
    },
};

const variants = {
    blue: blueVariant,
    gray: grayVariant,
    green: greenVariant,
    orange: orangeVariant,
    red: redVariant,
};

export type ChipVariant = keyof typeof variants;
export type ChipProps = { label: string; variant: ChipVariant };

export const Chip = ({ label, variant }: ChipProps) => {
    const Ellipse = styled(EllipseSvg)(() => ({
        width: '0.5rem',
        height: '0.5rem',
        ...variants[variant].ellipse,
    }));

    const Root = styled(Box)(() => ({
        border: '1px solid',
        display: 'flex',
        height: '2rem',
        padding: '0rem 0.1875rem 0rem 0.625rem',
        alignItems: 'center',
        borderRadius: '0.5rem',
        ...variants[variant].root,
    }));

    const StatusContainer = styled(Box)`
        display: flex;
        padding: 0.3125rem 0.625rem 0.3125rem 0.5rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
    `;

    const StatusLabel = styled(Typography)(() => ({
        textAlign: 'right',
        fontFamily: 'Roboto',
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
        letterSpacing: '0.00625rem',
        textTransform: 'capitalize',
        ...variants[variant].label,
    }));

    return (
        <Root>
            <Ellipse />
            <StatusContainer>
                <StatusLabel>{label}</StatusLabel>
            </StatusContainer>
        </Root>
    );
};

export default Chip;
