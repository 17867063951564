import {
  Grid,
  Typography,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { DataTable } from 'components/DataTable';
import FilterSelector from 'components/FilterSelector';
import Pager from 'components/Pager';
import {
  NearmapOrderStatusMapKey,
} from 'lib';
import NearmapOrder from 'lib/Models/NearmapOrder';
import { MicroServiceClient } from 'lib/apiClients/microServiceClient';
import { arrayToQueryString } from 'lib/util/ArrayUtilities';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NearmapRow from './NearmapRow';
import LoggerWrapper from 'lib/Logger';
import { NearmapOrderStatusMap } from 'lib/NearmapOrderStatus';

const styles = createStyles({
  orderType: {
    color: '#222222',
    fontSize: 18,
  },
  headerText: {
    color: '#999999',
    fontSize: 13,
    textTransform: 'uppercase',
  },
});

type PartialState = {
  currentUser: { userId: number };
  currentUserRoles: { isAccountAdmin: boolean };
  organization: { id: number };
};

const getBillingApi = (
  isAccountAdmin: boolean,
  organizationId?: number | undefined,
  userId?: number | undefined
) =>
  MicroServiceClient(
    'billing',
    1,
    isAccountAdmin ? `Orgs/${organizationId}` : `Users/${userId}`
  );

type NearmapBillingProps = {
  onReceivedFailedTransactions?: (transactionIds: string[]) => void;
  failedTransactionsResolved: boolean;
} & WithStyles<typeof styles>;

const NearmapBilling = ({ classes, onReceivedFailedTransactions, failedTransactionsResolved }: NearmapBillingProps) => {
  const {
    currentUser: { userId },
    currentUserRoles: { isAccountAdmin },
    organization: { id: organizationId },
  } = useSelector((state: PartialState) => state);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [failedNearmapOrderIds, setFailedNearmapOrderIds] = useState<string[]>([])

  const [nearmapOrders, setNearmapOrders] = useState<NearmapOrder[]>([]);

  const columnHeaders = [
    { title: 'Date', width: 3 },
    { title: 'Job Address', width: 4 },
    { title: 'Amount', width: 2 },
    { title: 'Status', width: 3 },
  ];

  const [status, setStatus] = useState<{
    key: NearmapOrderStatusMapKey;
    values: string[];
  }>({ key: 'All', values: [] });

  const handleStatusChange = (statusKey: NearmapOrderStatusMapKey) => {
    setStatus({
      key: statusKey,
      values: NearmapOrderStatusMap[statusKey],
    });
    setPage(1);
  };

  useEffect(() => {
    setIsLoading(true);

    const getTransactions = async () => {
      const statusQuery = arrayToQueryString(status.values, 'statuses', false);

      const data: any = await getBillingApi(
        isAccountAdmin,
        organizationId,
        userId
      ).get(`NearmapOrders?page=${page}${statusQuery}`);
      setNearmapOrders(data.resourceList as NearmapOrder[]);
      setFailedNearmapOrderIds(data.failedTransactionIds)
      setTotalPages(data.totalPages);
      setIsLoading(false);
    };

    getTransactions().catch((e) => {
      LoggerWrapper.captureException(e);
      console.error(e);
    });
  }, [page, isAccountAdmin, organizationId, userId, status, failedTransactionsResolved]);

  useEffect(() => {
    if (onReceivedFailedTransactions && failedNearmapOrderIds.length > 0)
      onReceivedFailedTransactions(failedNearmapOrderIds);
  }, [failedNearmapOrderIds, onReceivedFailedTransactions]);

  return (
    <>
      {/* TODO: Replace this loading thing with a spinner maybe? */}
      {isLoading && <Typography>Loading ...</Typography>}
      {!isLoading && (
        <Grid container xs={12}>
          <Grid item xs={12}>
            <FilterSelector
              keyValueMap={NearmapOrderStatusMap}
              selectedKey={status.key}
              onChange={handleStatusChange}
              sx={{ width: '10em', marginLeft: 'auto' }}
            />
          </Grid>
          <Grid item xs={12}>
            <DataTable
              columnHeaders={columnHeaders}
              headerStyle={classes.headerText}
            >
              {nearmapOrders.map((no) => (
                <NearmapRow nearmapOrder={no} />
              ))}
            </DataTable>
            <Pager
              totalPages={totalPages}
              currentPage={page}
              nextClick={() => {
                setPage(page + 1);
              }}
              prevClick={() => {
                setPage(page - 1);
              }}
              outerStyle={{}}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withStyles(styles)(NearmapBilling);
