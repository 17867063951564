import React from 'react';
import { PropTypes } from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';

const TooltipIcon = (props) => {
    const { icon, size, title } = props;
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const handleTooltipToggle = (event) => {
        event.stopPropagation();
        setTooltipOpen(!tooltipOpen);
    };
    const handleTooltipClose = (event) => {
        event.stopPropagation();
        setTooltipOpen(false);
    };
    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
                <Tooltip
                    arrow
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={tooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    title={title}
                >
                    <Icon fontSize={size} onClick={handleTooltipToggle}>
                        {icon}
                    </Icon>
                </Tooltip>
            </div>
        </ClickAwayListener>
    );
};

TooltipIcon.defaultProps = {
    size: 'small',
};

TooltipIcon.propTypes = {
    icon: PropTypes.element.isRequired,
    title: PropTypes.string.isRequired,
    size: PropTypes.string,
};

export default TooltipIcon;
