// https://braintree.github.io/braintree-web-drop-in/docs/current/Dropin.html
// https://developers.braintreepayments.com/guides/hosted-fields/events/javascript/v3

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dropin from 'braintree-web-drop-in';

class BraintreeDropinUI extends Component {
    constructor(props) {
        super(props);

        dropin.create({
            authorization: props.options.authorization,
            container: '#dropin-container',
            card: {
                cardholderName: {
                    required: true,
                },
            },
        }, (error, instance) => {
            props.onError(error);
            props.onInstance(instance);

            // Bind instance events to props
            instance.on('paymentMethodRequestable', (event) => {
                props.onPaymentMethodRequestable(event);
            });
            instance.on('noPaymentMethodRequestable', (event) => {
                props.onNoPaymentMethodRequestable(event);
            });
        });
    }

    render() {
        return (
            <div id="dropin-container" />
        );
    }
}

BraintreeDropinUI.defaultProps = {
    onInstance: () => {},
    onError: () => {},
    onPaymentMethodRequestable: () => {},
    onNoPaymentMethodRequestable: () => {},
};

BraintreeDropinUI.propTypes = {
    options: PropTypes.shape({
        authorization: PropTypes.string.isRequired,
    }).isRequired,
    onInstance: PropTypes.func,
    onError: PropTypes.func,
    onPaymentMethodRequestable: PropTypes.func,
    onNoPaymentMethodRequestable: PropTypes.func,
};

export default BraintreeDropinUI;
