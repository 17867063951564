import { PriceGrid } from 'lib/Models/PriceGrid';

export type PricingPartialState = {
    sketchOrderPricingReducer: {
        fullSnapPriceGrid: PriceGrid[];
        halfSnapPriceGrid: PriceGrid[];
        metalRoofPriceGrid: PriceGrid[];
        gutterReportPriceGrid: PriceGrid[];
    };
};

export const mapPricingStateToProps = (state: PricingPartialState) => {
    const { sketchOrderPricingReducer } = state;

    const {
        fullSnapPriceGrid,
        halfSnapPriceGrid,
        metalRoofPriceGrid,
        gutterReportPriceGrid,
    } = sketchOrderPricingReducer;

    return {
        priceGrids: {
            FullSnap: fullSnapPriceGrid,
            HalfSnap: halfSnapPriceGrid,
            MetalRoof: metalRoofPriceGrid,
            Gutters: gutterReportPriceGrid,
        },
    };
};
