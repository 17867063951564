import LoggerWrapper from '../lib/Logger';
import { LOGIN_SUCCESS } from '../actions/Auth';
import { GET_CURRENT_USER_SUCCESS, GET_CURRENT_USER_SUCCESS_BACKGROUND } from '../actions/User';

let loggerInitialized = false;

export default () => next => (action) => {
    if (!loggerInitialized) {
        LoggerWrapper.initialize();
        loggerInitialized = true;
    }

    if (action.type === LOGIN_SUCCESS) {
        LoggerWrapper.startNewSessison();
        const { userName, firstName, lastName } = action.user;
        LoggerWrapper.identify(userName, firstName, lastName);
    }

    if (action.type === GET_CURRENT_USER_SUCCESS || action.type === GET_CURRENT_USER_SUCCESS_BACKGROUND) {
        const { userName, firstName, lastName } = action.response;
        LoggerWrapper.identify(userName, firstName, lastName);
    }

    return next(action);
};
