import { CALL_API } from '../middleware/api';

export const GET_ORG_USERS_REQUEST = 'GET_ORG_USERS_REQUEST';
export const GET_ORG_USERS_SUCCESS = 'GET_ORG_USERS_SUCCESS';
export const GET_ORG_USERS_FAILURE = 'GET_ORG_USERS_FAILURE';

export const getOrganizationUsers = (
    organizationId,
    page = 1,
    getAllPages = false,
) => ({
    [CALL_API]: {
        types: [
            GET_ORG_USERS_REQUEST,
            GET_ORG_USERS_SUCCESS,
            GET_ORG_USERS_FAILURE,
        ],
        endpoint: `organizations/${organizationId}/userprofiles/`,
        getAllPages,
        params: {
            page,
        },
    },
});
