import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import UserLocaleIsUS from '../../lib/UserLocaleIsUS';

const styles = () => ({
    root: {
        flexGrow: 1,
        maxWidth: 1250,
        margin: '0 auto',
    },
    title: {
        margin: '20px 0 0 0',
    },
    subTitle: {
        margin: '0 0 30px 0',
        color: 'rgba(0,0,0,0.54)',
    },
    parentGrid: {
        margin: '30px 0 0',
    },
    gridHeading: {
        fontSize: '20px',
        textAlign: 'left',
    },
    gridSubHeading: {
        fontSize: '15px',
        color: 'rgba(0,0,0,0.54)',
        textAlign: 'left',
    },
    gridNumber: {
        fontSize: '20px',
        textAlign: 'center',
        paddingTop: '12px',
    },
    gridNumberCaption: {
        fontSize: '12px',
        textAlign: 'center',
        color: 'rgba(0,0,0,0.54)',
    },
    expansionDivider: {
        width: '100%',
        marginBottom: '30px',
        marginTop: '40px',
    },
    salesEmailLink: {
        textDecoration: 'none',
    },
    gridDescription: {
        paddingLeft: '20px',
        maxHeight: '80px',
    },
});
const PricingDialog = (props) => {
    const appendUSD = !UserLocaleIsUS();
    return (
        <Dialog open={props.open} onClose={props.onClose} fullScreen={props.fullScreen} fullWidth>

            <DialogContent>
                <div className={props.classes.root}>

                    <Typography variant="h5">
                        <Typography variant="h6" className={props.classes.title}>Full Snap pricing details</Typography>
                        <Typography variant="body1" className={props.classes.subTitle}>Pricing is based on roof size and complexity.</Typography>
                    </Typography>

                    {
                        props.id === 'Non-Subscriber' ?
                            <Grid container direction="column" className={props.classes.parentGrid}>
                                <Grid item >
                                    <Grid container direction="row" justify="space-between">
                                        <Grid item xs={12} lg={3} className={props.classes.gridDescription}>
                                            <Typography className={props.classes.gridHeading} align="left">Up to 80 squares</Typography>
                                            <Typography className={props.classes.gridSubHeading} align="left">Most residential roofs</Typography>
                                        </Grid>
                                        <Grid container item xs={12} lg={9} direction="row" justify="flex-end">
                                            <Grid item xs={6} lg={2}>
                                                <Typography className={props.classes.gridNumber} align="center">$32{appendUSD ? ' USD' : ''}</Typography>
                                                <Typography className={props.classes.gridNumberCaption} align="center">{'<'} 50 sq</Typography>
                                            </Grid>
                                            <Grid item xs={6} lg={2}>
                                                <Typography className={props.classes.gridNumber} align="center">$52{appendUSD ? ' USD' : ''}</Typography>
                                                <Typography className={props.classes.gridNumberCaption} align="center">50 - 80 sq</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider className={props.classes.expansionDivider} />
                                <Grid item>
                                    <Grid container direction="row" justify="space-between">
                                        <Grid item xs={12} lg={3} className={props.classes.gridDescription}>
                                            <Typography className={props.classes.gridHeading} align="center">Over 80 squares</Typography>
                                            <Typography className={props.classes.gridSubHeading} align="center">Large residential @ commercial buildings</Typography>
                                        </Grid>
                                        <Grid container item xs={12} lg={9} direction="row" justify="flex-end">
                                            <Grid item xs={6} lg={2}>
                                                <Typography className={props.classes.gridNumber} align="center">$32{appendUSD ? ' USD' : ''}</Typography>
                                                <Typography className={props.classes.gridNumberCaption} align="center">1 - 15</Typography>
                                                <Typography className={props.classes.gridNumberCaption} align="center">facets</Typography>

                                            </Grid>
                                            <Grid item xs={6} lg={2}>
                                                <Typography className={props.classes.gridNumber} align="center">$52{appendUSD ? ' USD' : ''}</Typography>
                                                <Typography className={props.classes.gridNumberCaption} align="center">{'>'} 15 facets</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> :
                            <Grid container direction="column" className={props.classes.parentGrid}>
                                <Grid item >
                                    <Grid container direction="row" justify="space-between">
                                        <Grid item xs={12} lg={3} className={props.classes.gridDescription}>
                                            <Typography className={props.classes.gridHeading} align="left">Up to 80 squares</Typography>
                                            <Typography className={props.classes.gridSubHeading} align="left">Most residential roofs</Typography>
                                        </Grid>
                                        <Grid container item xs={12} lg={9} direction="row" justify="flex-end">
                                            <Grid item xs={6} lg={2}>
                                                <Typography className={props.classes.gridNumber} align="center">$11{appendUSD ? ' USD' : ''}</Typography>
                                                <Typography className={props.classes.gridNumberCaption} align="center">{'<'} 20 sq</Typography>
                                            </Grid>
                                            <Grid item xs={6} lg={2}>
                                                <Typography className={props.classes.gridNumber} align="center">$17{appendUSD ? ' USD' : ''}</Typography>
                                                <Typography className={props.classes.gridNumberCaption} align="center">20 - 30 sq</Typography>
                                            </Grid>
                                            <Grid item xs={6} lg={2}>
                                                <Typography className={props.classes.gridNumber} align="center">$21{appendUSD ? ' USD' : ''}</Typography>
                                                <Typography className={props.classes.gridNumberCaption} align="center">30 -50 sq</Typography>
                                            </Grid>
                                            <Grid item xs={6} lg={2}>
                                                <Typography className={props.classes.gridNumber} align="center">$37{appendUSD ? ' USD' : ''}</Typography>
                                                <Typography className={props.classes.gridNumberCaption} align="center">50 - 80 sq</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider className={props.classes.expansionDivider} />
                                <Grid item>
                                    <Grid container direction="row" justify="space-between">
                                        <Grid item xs={12} lg={3} className={props.classes.gridDescription}>
                                            <Typography className={props.classes.gridHeading} align="center">Over 80 squares</Typography>
                                            <Typography className={props.classes.gridSubHeading} align="center">Large residential @ commercial buildings</Typography>
                                        </Grid>
                                        <Grid container item xs={12} lg={9} direction="row" justify="flex-end">
                                            <Grid item xs={6} lg={2}>
                                                <Typography className={props.classes.gridNumber} align="center">$11{appendUSD ? ' USD' : ''}</Typography>
                                                <Typography className={props.classes.gridNumberCaption} align="center">1 - 4 facets</Typography>
                                            </Grid>
                                            <Grid item xs={6} lg={2}>
                                                <Typography className={props.classes.gridNumber} align="center">$17{appendUSD ? ' USD' : ''}</Typography>
                                                <Typography className={props.classes.gridNumberCaption} align="center">5 - 15 facets</Typography>
                                            </Grid>
                                            <Grid item xs={6} lg={2}>
                                                <Typography className={props.classes.gridNumber} align="center">$21{appendUSD ? ' USD' : ''}</Typography>
                                                <Typography className={props.classes.gridNumberCaption} align="center">16 - 30 facets</Typography>
                                            </Grid>
                                            <Grid item xs={6} lg={2}>
                                                <Typography className={props.classes.gridNumber} align="center">$37{appendUSD ? ' USD' : ''}</Typography>
                                                <Typography className={props.classes.gridNumberCaption} align="center">31 - 50 facets</Typography>
                                            </Grid>
                                            <Grid item xs={6} lg={2}>
                                                <Typography className={props.classes.gridNumber} align="center">$47{appendUSD ? ' USD' : ''}</Typography>
                                                <Typography className={props.classes.gridNumberCaption} align="center">{'>'} 50 facets</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

PricingDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(withStyles(styles)(PricingDialog));
