import React from 'react';
import {
    Grid,
    ITheme,
    TextField,
    Typography,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import classNames from 'classnames';

type NotesProps = {
    onChange: (notes?: string) => void;
    required?: boolean;
    value?: string;
};

const useStyles = makeStyles((theme: ITheme) =>
    createStyles({
        sectionTitle: {
            color: 'var(--color-black, #222)',
            fontStyle: 'normal',
            lineHeight: 'normal',
            fontWeight: 700,   
            padding: '2.188rem 0rem 0.938rem 0rem',
            fontSize: '1.125rem',
        },
        required: {
            color: 'var(--color-negative-200, #EA1D3D)',
        },
        optional: {
            color: 'var(--color-grey-400, #555)',
            fontWeight: 400,
            fontSize: '1rem',
            lineHeight: '1.5rem',
            letterSpacing: '0.03125rem',
        },
        notesHelpText: {
            color: 'var(--color-grey-300, #888)',
            fontSize: '0.875rem',
            fontWeight: 400,
            letterSpacing: '0.006rem',
            paddingTop:'.4rem'
        },
        notesText:{
            paddingLeft: 10,
            paddingRight: 10,
            height:'4.49rem'            
        }
    })
);

const Notes = ({ onChange, required, value }: NotesProps) => {
    const classes = useStyles();

    const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    return (
        <Grid item container>
            <Typography className={classes.sectionTitle}>
                Notes
                <Typography
                    variant='inherit'
                    display='inline'
                    className={classNames('', {
                        [classes.required]: required,
                        [classes.optional]: !required,
                    })}
                >
                    {required ? '*' : ' (Optional)'}
                </Typography>
            </Typography>
            <TextField
                fullWidth
                minRows={2}
                multiline={true}
                variant='outlined'
                placeholder='Enter'
                onChange={handleNotesChange}
                value={value}   
                InputProps={{
                    className:classes.notesText
                }}            
                inputProps={{maxLength: 4000}}
            />
            <Typography className={classes.notesHelpText}>
                (i.e. dormer pitch 6/12, include detached shed, do not include
                metal awnings, flat section only, etc.)
            </Typography>
        </Grid>
    );
};

export default Notes;
