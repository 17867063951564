import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { getDocuments } from '../../actions/documentsActions';
import DocumentsList from './DocumentsList.tsx';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';
import { createDocumentSharedAccessToken } from '../../actions/documentSharedAccessTokenActions';
import CreateDocument from '../CreateDocument';
import { handleMissingOffices } from '../../actions/Offices';
import { deleteDocument, downloadDocument } from '../../actions/documentActions';
import { getProjectEstimates } from '../../actions/ProjectEstimates';
import { getDocumentTemplateCategories } from '../../actions/documentTemplateCategoriesActions';
import { getProjectById } from '../../actions/ProjectActions';

const style = {
    root: {
        padding: 24,
    },
};

class Documents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteDocumentModalOpen: false,
            document: {
                id: '',
                projectId: '',
                documentUrl: '',
                name: '',
                category: '',
            },
        };
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        await this.props.dispatch(getProjectById(this.props.match.params.id));
        this.props.dispatch(getDocumentTemplateCategories(this.props.officeId));
        this.props.dispatch(getDocuments(this.props.match.params.id));
        this.handleMissingOffices();
        this.props.dispatch(getProjectEstimates(this.props.match.params.id));
    }

    checkCreateDocumentOpen = () => {
        const queryParams = queryString.parse(this.props.location.search);
        return !!queryParams.createDocumentOpen;
    }

    handleMissingOffices = () => {
        if (this.props.offices.length === 0) {
            this.props.dispatch(handleMissingOffices());
        }
    };

    deleteProjectDocument = async () => {
        await this.props.dispatch(deleteDocument(this.state.document.id));
        await this.props.dispatch(getDocuments(this.state.document.projectId));
        this.setState({ deleteDocumentModalOpen: false });
        this.props.dispatch(setReduxMessage(`${this.state.document.name} was successfully deleted`));
    };

    openDocument = async (document) => {
        await this.props.dispatch(createDocumentSharedAccessToken(document.id, document.renderingId));
        window.open(this.props.documentSharedAccessToken.sharedAccessSignatureUrl, '_blank');
    };

    downloadDocument = async (document) => {
        await this.props.dispatch(createDocumentSharedAccessToken(document.id, document.renderingId));
        await this.props.dispatch(downloadDocument(this.props.documentSharedAccessToken.sharedAccessSignatureUrl, document.renderingFileName));
    };

    handleOpenDialog = (document) => {
        this.setState({
            deleteDocumentModalOpen: true,
            document,
        });
    };

    handleDismiss = () => {
        this.setState({
            deleteDocumentModalOpen: false,
            document: {
                id: '',
                projectId: '',
                documentUrl: '',
                name: '',
                category: '',
            },
        });
    };

    render() {
        return (
            <div style={style.root}>
                <DocumentsList
                    documents={this.props.documents.data}
                    deleteDocument={this.handleOpenDialog}
                    categories={this.props.documentTemplateCategories}
                    onOpenDocumentClick={this.openDocument}
                    onDownloadDocumentClick={this.downloadDocument}
                    currentUserRoleIds={this.props.currentUserRoleIds}
                    officeId={this.props.officeId}
                />
                <ConfirmationModal
                    title={`Delete ${this.state.document.name}`}
                    description="Are you sure you want to remove this project document?"
                    handleConfirm={this.deleteProjectDocument}
                    confirmText="Delete"
                    isOpen={this.state.deleteDocumentModalOpen}
                    handleClose={this.handleDismiss}
                />
                <CreateDocument
                    projectId={this.props.match.params.id}
                    showSummaryReports={this.props.canViewSummaryReports}
                    open={this.checkCreateDocumentOpen()}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        documents,
        currentUser,
        userOffices,
        documentSharedAccessToken,
        documentTemplateCategories: documentTemplateCategoriesState,
        projectHome,
    } = state;
    const { data: offices } = userOffices;

    const { documentTemplateCategories } = documentTemplateCategoriesState;

    const { currentProject } = projectHome;
    const { officeId } = currentProject;
    const { userRoleIds: currentUserRoleIds } = currentUser;

    return {
        documents,
        offices,
        documentSharedAccessToken,
        documentTemplateCategories,
        officeId,
        currentUserRoleIds,
    };
};

const projectDocumentProps = {
    id: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    documentUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
};

Documents.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    documents: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape(projectDocumentProps)).isRequired,
        totalResults: PropTypes.number.isRequired,
    }).isRequired,
    canViewSummaryReports: PropTypes.bool.isRequired,
    offices: PropTypes.arrayOf(PropTypes.shape),
    officeId: PropTypes.number.isRequired,
    currentUserRoleIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

Documents.defaultProps = {
    offices: [],
};

export default connect(mapStateToProps)(Documents);
