import { CALL_API } from '../middleware/api';
import { SET_REDUX_MESSAGE } from './ReduxMessagesActions';
import httpRequestMethods from '../middleware/httpRequestMethods';

export const GET_OFFICE_BY_ID_REQUEST = 'GET_OFFICE_BY_ID_REQUEST';
export const GET_OFFICE_BY_ID_SUCCESS = 'GET_OFFICE_BY_ID_SUCCESS';
export const GET_OFFICE_BY_ID_FAILURE = 'GET_OFFICE_BY_ID_FAILURE';

export const getOfficeByOfficeId = officeId => ({
    [CALL_API]: {
        types: [
            GET_OFFICE_BY_ID_REQUEST,
            GET_OFFICE_BY_ID_SUCCESS,
            GET_OFFICE_BY_ID_FAILURE,
            SET_REDUX_MESSAGE,
        ],
        endpoint: `offices/${officeId}`,
    },
});

export const UPDATE_OFFICE_REQUEST = 'UPDATE_OFFICE_REQUEST';
export const UPDATE_OFFICE_SUCCESS = 'UPDATE_OFFICE_SUCCESS';
export const UPDATE_OFFICE_FAILURE = 'UPDATE_OFFICE_FAILURE';

export const updateOffice = (officeId, office, version) => ({
    [CALL_API]: {
        types: [
            UPDATE_OFFICE_REQUEST,
            UPDATE_OFFICE_SUCCESS,
            UPDATE_OFFICE_FAILURE,
            SET_REDUX_MESSAGE,
        ],
        endpoint: `offices/${officeId}`,
        method: httpRequestMethods.PUT,
        body: office,
        header: {
            version,
        },
    },
});
