import React, { ChangeEvent } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

import Input from '@material-ui/core/Input';
import OfficeDropdown from './SketchOSOfficeDropdown';
import User from 'lib/Models/User';
import {
    Box,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { AllowedOrderTypes } from 'lib/MeasurementOrders';

const styles = () =>
    createStyles({
        selectInput: {
            fontSize: '.9rem',
            minHeight: '1rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginTop: '.2rem',
        },

        menuStyle: {
            fontSize: '.9rem',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },

        optionLabels: {
            color: '#939393',
            fontSize: '.7rem',
            lineHeight: 1,
            fontWeight: 500,
        },

        boxContainer: {
            border: 'solid',
            borderWidth: 1,
            borderRadius: 4,
            borderColor: '#d3d3d3',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            padding: '.3rem .7rem .3rem .7rem',
            height: '2.5rem',
            width: '90%',
        },

        sectionTitle: {
            color: 'var(--color-black, #222)',
            fontSize: '1.125rem',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
            padding: '2.188rem 0rem 0.938rem 0rem',
        },

        transparentFocusColor: {
            '&:focus': {
                backgroundColor: 'transparent',
            },
        },
    });

interface SketchOrderProjectDetailsProps extends WithStyles<typeof styles> {
    users: User[];
    projectName: string;
    ownerUserId: number;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onOwnerChange: (ownerId: number) => void;
    onMetalSizeOptionsChange?: (metalOptions: number | undefined) => void;
    onGutterOptionsChange?: (gutterOptions: number | undefined) => void;
    selectedMetalPanelSize: number | undefined;
    selectedGutterSize: number | undefined;
    showRequiredHelperText?: boolean;
    sketchReportType: AllowedOrderTypes;
    includesGutters: boolean;
}

const SketchOrderProjectDetails: React.FC<SketchOrderProjectDetailsProps> = ({
    projectName,
    onChange,
    classes,
    users,
    ownerUserId,
    onOwnerChange,
}) => {
    const getUsers = () =>
        users.map((user: User) => ({
            value: user.userId,
            label: user.userName,
        }));

    const currentUsers = getUsers();
    return (
        <Grid container direction='column'>
            <Grid item style={{ display: 'flex' }}>
                <Typography className={classes.sectionTitle}>
                    Project Details
                </Typography>
            </Grid>
            <Grid
                container
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    rowGap: 10,
                }}
                justifyContent='space-around'
            >
                <Grid item xs={12} md={4}>
                    <Box className={classes.boxContainer}>
                        <Typography className={classes.optionLabels}>
                            Project Name
                        </Typography>
                        <TextField
                            size='small'
                            value={projectName}
                            name='projectName'
                            variant='standard'
                            onChange={onChange}
                            InputProps={{
                                disableUnderline: true,
                                className: classes.selectInput,
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box className={classes.boxContainer}>
                        <Typography className={classes.optionLabels}>
                            Owner
                        </Typography>
                        <Select
                            fullWidth
                            value={ownerUserId}
                            variant='standard'
                            disableUnderline={true}
                            onChange={(e) => {
                                const ownerUserId = e.target.value as number;
                                onOwnerChange(ownerUserId);
                            }}
                            input={
                                <Input
                                    className={classes.selectInput}
                                    name='ownerId'
                                />
                            }
                            classes={{ root: classes.transparentFocusColor }}
                        >
                            {currentUsers &&
                                currentUsers.map((user) => (
                                    <MenuItem
                                        className={classes.menuStyle}
                                        key={user.value}
                                        value={user.value}
                                    >
                                        {user.label}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box className={classes.boxContainer}>
                        <Typography className={classes.optionLabels}>
                            Office
                        </Typography>
                        <OfficeDropdown
                            classNameMenu={classes.menuStyle}
                            className={classes.selectInput}
                            selectedUserId={ownerUserId}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(SketchOrderProjectDetails);
