import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { Stack, List, ListItem } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { green } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import GetApp from '@material-ui/icons/GetApp';
import ExitToApp from '@material-ui/icons/ExitToApp';
import ToolTip from '@material-ui/core/Tooltip';
import AutocompleteSelect from '../AutocompleteSelect';
import SketchOrderStatusChip from '../SketchOrderStatusChip';
import SketchOrderStatus from '../../lib/SketchOrderStatus';
import Map from './Map';
import ChipWrapper from '../ChipWrapper';
import { Box } from '@material-ui/core';

const style = (theme) => ({
    paper: {
        maxWidth: '600px',
        margin: '0 auto',
    },
    cardHeader: {
        padding: 16,
    },
    cardHeaderRow: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(1),
        marginLeft: 0,
    },
    readOnlyField: {
        margin: theme.spacing(1),
        marginLeft: 0,
        flex: 1,
        width: '100%',
    },
    billingFailedMessageSection: {
        marginBottom: 5,
    },
    billingFailedErrorMessage: {
        fontWeight: 500,
    },
    totalPrice: {
        padding: '10px 0px',
    },
    priceRow: {
        padding: '5px 0px',
    },
});

class SketchOrderCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sketchReportType: props.sketchReportType,
            ownerUserId: props.owner.userId,
            notes: props.notes,
        };
    }
    componentDidUpdate(prevProps) {
        this.updateSketchOrderFields(prevProps);
    }
    getUsers = () =>
        this.props.users.map((user) => ({
            value: user.userId,
            label: user.userName,
        }));
    updateSketchOrderFields = (prevProps) => {
        if (prevProps.sketchReportType !== this.props.sketchReportType) {
            this.setState({ sketchReportType: this.props.sketchReportType });
        }
        if (prevProps.owner.userId !== this.props.owner.userId) {
            this.setState({ ownerUserId: this.props.owner.userId });
        }
        if (prevProps.notes !== this.props.notes) {
            this.setState({ notes: this.props.notes });
        }
    };

    handleOnChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    handleOwnerChange = (ownerUserId) => {
        this.setState({ ownerUserId });
    };
    updateSketchOrder = () => {
        const sketchOrder = {
            sketchReportType: this.state.sketchReportType,
            ownerUserId: this.state.ownerUserId,
            notes: this.state.notes,
        };

        this.props.onUpdateOrder(sketchOrder);
    };

    handleDownloadSketchOrderReportClick = async () => {
        await this.props.downloadSketchOrderReport();
    };

    renderUserMenuItems = (user) => (
        <MenuItem key={user.userId} value={user.userId}>
            {user.userName}
        </MenuItem>
    );
    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getYear() % 100;

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        var hours = d.getHours();
        var minutes = d.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var datestring = [month, day, year].join('/');
        var timeString = `${hours}:${minutes} ${ampm}`;
        return `${datestring} - ${timeString}`;
    };

    render() {
        const ableToUpdateSketchOrder = () =>
            this.props.sketchOrderStatus === SketchOrderStatus.Pending;
        const showPrice =
            this.props.sketchOrderStatus === SketchOrderStatus.Complete ||
            this.props.sketchOrderStatus === SketchOrderStatus.Billed;
        return (
            <Card className={this.props.classes.paper}>
                <div className={this.props.classes.cardHeader}>
                    <div className={this.props.classes.cardHeaderRow}>
                        <Grid container alignItems='center'>
                            <Grid item xs>
                                <Typography variant='h6'>
                                    {this.props.projectName}
                                </Typography>
                                <Typography variant='body1'>
                                    {dateFormat(
                                        this.props.requestedDate,
                                        'mmm d, yyyy h:MM TT'
                                    )}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                id='sketch-order-status'
                                style={{ textAlign: 'center' }}
                            >
                                {this.props.freeOrder ? (
                                    <ChipWrapper
                                        label='Free'
                                        style={{
                                            backgroundColor: green[500],
                                            color: '#FFFFFF',
                                        }}
                                        icon={CheckCircleIcon}
                                    />
                                ) : (
                                    <SketchOrderStatusChip
                                        status={this.props.sketchOrderStatus}
                                    />
                                )}
                            </Grid>

                            <Grid item style={{ textAlign: 'center' }}>
                                {this.props.projectClonedForDelivery && (
                                    <ToolTip title='Open project'>
                                        <span>
                                            <IconButton
                                                onClick={
                                                    this.props.onOpenProject
                                                }
                                                disabled={
                                                    !this.props
                                                        .projectClonedForDelivery
                                                }
                                                aria-label='Open project'
                                            >
                                                <ExitToApp />
                                            </IconButton>
                                        </span>
                                    </ToolTip>
                                )}
                                {this.props.sketchOrderReport && (
                                    <ToolTip title='Download Sketch Report'>
                                        <span>
                                            <IconButton
                                                onClick={() =>
                                                    this.handleDownloadSketchOrderReportClick()
                                                }
                                                disabled={
                                                    !this.props
                                                        .sketchOrderReport
                                                }
                                                aria-label='Download Sketch Report'
                                            >
                                                <GetApp />
                                            </IconButton>
                                        </span>
                                    </ToolTip>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                    {this.props.sketchOrderStatus ===
                        SketchOrderStatus.BillingFailed && (
                        <div
                            className={
                                this.props.classes.billingFailedMessageSection
                            }
                        >
                            <Typography
                                color='error'
                                gutterBottom
                                className={
                                    this.props.classes.billingFailedErrorMessage
                                }
                            >
                                {this.props.billingErrorMessage}
                            </Typography>
                            <Button
                                size='small'
                                onClick={this.props.onOpenUpdatePaymentDialog}
                                color='primary'
                                variant='contained'
                            >
                                Retry Billing
                            </Button>
                        </div>
                    )}
                    {showPrice && (
                        <Grid
                            className={this.props.classes.totalPrice}
                            container
                            item
                            direction='column'
                            xs={12}
                        >
                            <Grid
                                item
                                container
                                direction='row'
                                className={this.props.classes.priceRow}
                            >
                                <Grid
                                    item
                                    container
                                    xs={6}
                                    justifyContent='flex-start'
                                >
                                    <Grid item>
                                        <Typography variant='body1'>
                                            {this.props.sketchReportType}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={6}
                                    justifyContent='flex-end'
                                >
                                    <Grid item>
                                        <Typography
                                            id='sketch-cost'
                                            variant='body1'
                                            style={
                                                this.props.freeOrder &&
                                                this.props.cost > 0
                                                    ? {
                                                          textDecoration:
                                                              'line-through',
                                                      }
                                                    : {}
                                            }
                                        >
                                            $
                                            {this.props.slaCompliance &&
                                            this.props.cost > 0
                                                ? this.props.cost -
                                                  this.props.rushCost
                                                : this.props.cost}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {this.props.rushed && (
                                <React.Fragment>
                                    <Grid
                                        item
                                        container
                                        direction='row'
                                        className={this.props.classes.priceRow}
                                        justifyContent='space-between'
                                        wrap='nowrap'
                                    >
                                        <Grid
                                            item
                                            container
                                            xs={6}
                                            justifyContent='flex-start'
                                        >
                                            <Grid item>
                                                <Typography variant='body1'>
                                                    Rush Delivery Charge
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            xs={6}
                                            justifyContent='flex-end'
                                        >
                                            <Grid item>
                                                <Typography
                                                    id='rush-cost'
                                                    variant='body1'
                                                    style={
                                                        !this.props
                                                            .slaCompliance
                                                            ? {
                                                                  textDecoration:
                                                                      'line-through',
                                                              }
                                                            : {}
                                                    }
                                                >
                                                    ${this.props.rushCost}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {!this.props.slaCompliance && (
                                        <Grid
                                            item
                                            container
                                            xs={12}
                                            justifyContent='flex-end'
                                        >
                                            <Grid item>
                                                <Typography
                                                    id='notSlaCompliant-message'
                                                    variant='caption'
                                                    color='textSecondary'
                                                    noWrap
                                                    paragraph
                                                >
                                                    Rush order not delivered on
                                                    time.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </React.Fragment>
                            )}

                            <Grid
                                item
                                container
                                direction='row'
                                style={{ borderTop: '1px solid', marginTop: 5 }}
                                className={this.props.classes.priceRow}
                            >
                                <Grid
                                    container
                                    item
                                    xs={6}
                                    justifyContent='flex-start'
                                >
                                    <Grid item>
                                        <Typography
                                            variant='body1'
                                            style={{ fontWeight: 600 }}
                                        >
                                            Total
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={6}
                                    justifyContent='flex-end'
                                >
                                    <Grid item>
                                        {this.props.freeOrder &&
                                        !this.props.rushed ? (
                                            <Typography
                                                id='cost-free'
                                                variant='body1'
                                                style={{ fontWeight: 600 }}
                                            >
                                                Free
                                            </Typography>
                                        ) : (
                                            <Typography
                                                id='total-cost'
                                                variant='body1'
                                                style={{ fontWeight: 600 }}
                                            >
                                                ${this.props.cost}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <FormControl
                        fullWidth
                        className={this.props.classes.formControl}
                    >
                        <InputLabel>Requester</InputLabel>
                        <Input
                            id='requester'
                            type='text'
                            disabled
                            value={this.props.requester.userName}
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        className={this.props.classes.formControl}
                    >
                        <InputLabel shrink>Owner</InputLabel>
                        <Input
                            fullWidth
                            value={this.state.ownerUserId}
                            onChange={this.handleOwnerChange}
                            placeholder='Search email address'
                            id='react-select-single'
                            disabled={!ableToUpdateSketchOrder()}
                            inputComponent={AutocompleteSelect}
                            inputProps={{
                                name: 'react-select-single',
                                instanceId: 'react-select-single',
                                simpleValue: true,
                                options: this.getUsers(),
                                disableItemRemoval: true,
                            }}
                            label='owner'
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        className={this.props.classes.formControl}
                    >
                        <InputLabel>Report Type</InputLabel>
                        <Select
                            value={this.state.sketchReportType}
                            onChange={this.handleOnChange}
                            disabled={!ableToUpdateSketchOrder()}
                            input={<Input name='sketchReportType' />}
                        >
                            <MenuItem value='FullSnap'>FullSnap</MenuItem>
                            <MenuItem value='HalfSnap'>HalfSnap</MenuItem>
                            <MenuItem value='Gutters'>Gutters</MenuItem>
                            <MenuItem value='MetalRoof'>Metal Roof</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <Divider />
                <CardContent>
                    <div className={this.props.classes.readOnlyField}>
                        <Typography variant='body1'>Address:</Typography>
                        <Typography>{this.props.address}</Typography>
                    </div>
                    <div className={this.props.classes.readOnlyField}>
                        <Typography variant='body1'>City:</Typography>
                        <Typography>{this.props.city}</Typography>
                    </div>
                    <div className={this.props.classes.readOnlyField}>
                        <Typography variant='body1'>Region:</Typography>
                        <Typography>{this.props.region}</Typography>
                    </div>
                    <div className={this.props.classes.readOnlyField}>
                        <Typography variant='body1'>Postcode:</Typography>
                        <Typography>{this.props.postCode}</Typography>
                    </div>
                    <div className={this.props.classes.readOnlyField}>
                        <Typography variant='body1'>Country:</Typography>
                        <Typography>{this.props.country}</Typography>
                    </div>
                    {this.props.coordinates.length === 2 && (
                        <div className={this.props.classes.readOnlyField}>
                            <Map
                                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_SKETCHOS_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                                loadingElement={<div />}
                                containerElement={
                                    <div style={{ height: '350px' }} />
                                }
                                mapElement={
                                    <div
                                        id='googleMap'
                                        style={{ height: '100%' }}
                                    />
                                }
                                center={{
                                    lat: this.props.coordinates[1],
                                    lng: this.props.coordinates[0],
                                }}
                                markers={
                                    this.props.markers &&
                                    this.props.markers.map((marker) => ({
                                        ...marker,
                                        label:
                                            marker.sketchOrderId ===
                                            this.props.id
                                                ? `${marker.ordinal}*`
                                                : `${marker.ordinal}`,
                                    }))
                                }
                            />
                        </div>
                    )}
                    <FormControl
                        fullWidth
                        className={this.props.classes.formControl}
                    >
                        <InputLabel>Notes</InputLabel>
                        <Input
                            id='sketch-order-notes'
                            type='text'
                            disabled={!ableToUpdateSketchOrder()}
                            multiline
                            value={this.state.notes}
                            name='notes'
                            onChange={this.handleOnChange}
                            inputProps={{
                                maxLength: 4000,
                            }}
                        />
                    </FormControl>
                    {(this.props.sketchOrderStatus ===
                        SketchOrderStatus.Complete ||
                        this.props.sketchOrderStatus ===
                            SketchOrderStatus.Incomplete ||
                        this.props.sketchOrderStatus ===
                            SketchOrderStatus.Billed) && (
                        <div className={this.props.classes.readOnlyField}>
                            <Typography variant='body1'>
                                Notes from Sketch Tech:
                            </Typography>
                            <Typography>{this.props.adminNotes}</Typography>
                        </div>
                    )}
                    {this.props.sketchReportType === 'MetalRoof' &&
                        this.props.revisionNotes.length > 0 && (
                            <>
                                <InputLabel sx={{ mb: 1 }}>
                                    Revision History
                                </InputLabel>
                                <Box mt={1}>
                                    <Divider mt={1}></Divider>
                                </Box>
                                <List>
                                    {this.props.revisionNotes.map(
                                        (item, index) => {
                                            return (
                                                <ListItem key={index}>
                                                    <Stack>
                                                        <Typography>
                                                            <strong>
                                                                {this.formatDate(
                                                                    item.createdAt
                                                                )}
                                                            </strong>
                                                        </Typography>
                                                        <Typography>
                                                            {item.note}
                                                        </Typography>
                                                    </Stack>
                                                </ListItem>
                                            );
                                        }
                                    )}
                                </List>
                            </>
                        )}
                </CardContent>
                {this.props.sketchOrderStatus === SketchOrderStatus.Pending && (
                    <CardActions>
                        <Button
                            onClick={this.props.onCancelOrder}
                            color='secondary'
                        >
                            Cancel Order
                        </Button>
                        <Button
                            onClick={this.updateSketchOrder}
                            color='secondary'
                            id='update-sketch-order-button'
                        >
                            Update Order
                        </Button>
                    </CardActions>
                )}
                {this.props.sketchReportType === 'MetalRoof' &&
                    (this.props.sketchOrderStatus ===
                        SketchOrderStatus.Complete ||
                        this.props.sketchOrderStatus ===
                            SketchOrderStatus.Billed) && (
                        <CardActions>
                            <Button
                                onClick={this.props.onRequestRevision}
                                color='secondary'
                            >
                                Request Revision
                            </Button>
                        </CardActions>
                    )}
            </Card>
        );
    }
}

SketchOrderCard.propTypes = {
    projectName: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    postCode: PropTypes.string.isRequired,
    country: PropTypes.string,
    notes: PropTypes.string.isRequired,
    sketchReportType: PropTypes.string.isRequired,
    sketchOrderStatus: PropTypes.string.isRequired,
    requestedDate: PropTypes.string.isRequired,
    adminNotes: PropTypes.string,
    cost: PropTypes.number,
    rushCost: PropTypes.number,
    requester: PropTypes.shape({
        userName: PropTypes.string.isRequired,
    }).isRequired,
    owner: PropTypes.shape({
        userId: PropTypes.number.isRequired,
        userName: PropTypes.string.isRequired,
    }).isRequired,
    onCancelOrder: PropTypes.func.isRequired,
    onUpdateOrder: PropTypes.func.isRequired,
    onOpenProject: PropTypes.func.isRequired,
    onOpenUpdatePaymentDialog: PropTypes.func.isRequired,
    coordinates: PropTypes.arrayOf(PropTypes.number).isRequired,
    freeOrder: PropTypes.bool.isRequired,
    projectClonedForDelivery: PropTypes.bool.isRequired,
    users: PropTypes.arrayOf(
        PropTypes.shape({
            userId: PropTypes.number.isRequired,
            userName: PropTypes.string.isRequired,
        })
    ).isRequired,
    billingErrorMessage: PropTypes.string,
    classes: PropTypes.shape({
        paper: PropTypes.string.isRequired,
        cardHeaderRow: PropTypes.string.isRequired,
        cardHeader: PropTypes.string.isRequired,
        formControl: PropTypes.string.isRequired,
        readOnlyField: PropTypes.string.isRequired,
        billingFailedMessageSection: PropTypes.string.isRequired,
        billingFailedErrorMessage: PropTypes.string.isRequired,
    }).isRequired,
};

SketchOrderCard.defaultProps = {
    country: '',
    adminNotes: '',
    cost: 0,
    billingErrorMessage: '',
    rushCost: 0,
};

export default withStyles(style)(SketchOrderCard);
