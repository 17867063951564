import React from "react"
import { Button, createStyles, Theme, WithStyles, withStyles } from "@material-ui/core"
import User from "lib/Models/User"
import UserDataChip from "./UserDataChip"

interface UserButtonProps extends WithStyles<typeof styles> {
    user: User,
    handleClick: (userId: number) => void,
    index: number,
    isActive: boolean,
}

const styles = (theme: Theme) => createStyles({
    button: {
        borderRadius: '8px',
        height: '80px',
        width: '100%',
        padding: theme.spacing(1),
        backgroundColor: '#FFF',
    },
})

const UserButton = (props: UserButtonProps) => {

    const { classes, handleClick, index, user, isActive } = props;
    const buttonClick = () => {
        handleClick(index)
    }

    return (
        <Button
            variant="contained"
            disableElevation
            value={user.userId}
            className={classes.button}
            onClick={buttonClick}
            style={isActive ? { backgroundColor: '#EEF5FF' } : {}}
        >
            <UserDataChip 
                firstName={user.firstName}
                lastName={user.lastName}
                userRole={user.position}
                isActive={isActive}
            />
        </Button >
    )
}

export default withStyles(styles)(UserButton)