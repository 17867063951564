import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
} from 'react-google-maps';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';

class Map extends Component {
    render() {
        return (
            <GoogleMap
                center={this.props.center}
                options={{
                    mapTypeId: 'hybrid',
                    mapTypeControl: false,
                    tilt: 0,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    panControl: false,
                    zoomControl: false,
                    gestureHandling: 'greedy',
                }}
                zoom={19}
            >
                {this.props.markers === null ?
                    <MarkerWithLabel
                        position={{ lat: this.props.center.lat, lng: this.props.center.lng }}
                        labelAnchor={{ x: 4.5, y: 35 }}
                        labelStyle={{
                            backgroundColor: '#ea4335',
                            color: 'White',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            margin: '2px',
                            borderRadius: '5px',
                        }}
                    >
                        <div />
                    </MarkerWithLabel> : this.props.markers.map(m => (
                        <MarkerWithLabel
                            key={m.key}
                            position={{ lat: m.point.coordinates[1], lng: m.point.coordinates[0] }}
                            labelAnchor={{ x: 4.5, y: 35 }}
                            labelStyle={{
                                backgroundColor: '#ea4335',
                                color: 'White',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                margin: '2px',
                                borderRadius: '5px',
                            }}
                        >
                            <div>
                                { m.label }
                            </div>
                        </MarkerWithLabel>))
                }
            </GoogleMap>
        );
    }
}


Map.propTypes = {
    center: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }).isRequired,
    markers: PropTypes.arrayOf(PropTypes.shape({
        coordinates: PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number,
        }),
    })),
};

Map.defaultProps = {
    markers: [],
};

export default withScriptjs(withGoogleMap(Map));
