import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Description, Layers, Storage, Build } from '@material-ui/icons';
import materialRootCategory from '../../lib/MaterialRootCategory';
import MaterialItemsList from '../MaterialItemsList';

const styles = {
    flex: {
        flex: 1,
    },
    doneButton: {
        marginLeft: 'auto',
        color: '#ffffff',
    },
    materialItems: {
        marginTop: '115px',
    },
};

const transition = props => <Slide direction="up" {...props} />;

const mapTabValueToRootCategoryValue = (tabValue) => {
    switch (tabValue) {
    case 0:
        return '';
    case 1:
        return materialRootCategory.Shingles;
    case 2:
        return materialRootCategory.Materials;
    case 3:
        return materialRootCategory.Services;
    default:
        return '';
    }
};

const MaterialItemsDialog = ({
    open,
    onDialogDismiss,
    materialItems,
    totalResults,
    rootCategoryFilter,
    onRootCategoryFilterSwitch,
    totalPages,
    currentPage,
    materielItemsFetching,
    onSearch,
    searchValue,
    onNextClick,
    onPrevClick,
    estimateItems,
    createEstimateItem,
    deleteEstimateItem,
    onColorOptionClick,
    colorOptions,
    totalColorOptions,
    totalColorOptionsPages,
    colorOptionsCurrentPage,
    onNextPageColorOptionsClick,
    onPrevPageColorOptionsClick,
}) => {
    const handleChange = (event, rootCategoryTabValue) => {
        event.preventDefault();
        const rootCategory = mapTabValueToRootCategoryValue(rootCategoryTabValue);
        onRootCategoryFilterSwitch(rootCategoryTabValue, rootCategory);
    };
    return (
        <Dialog fullScreen open={open} TransitionComponent={transition}>
            <AppBar>
                <Toolbar style={styles.flex}>
                    <IconButton
                        color="inherit"
                        onClick={onDialogDismiss}
                        aria-label="Close"
                    >
                        <CloseIcon style={{ fill: 'white' }} />
                    </IconButton>
                    <Typography variant="h6" color="inherit">
                        {totalResults} Material Item(s)
                    </Typography>
                    <Button
                        style={styles.doneButton}
                        onClick={onDialogDismiss}
                        aria-label="Done"
                    >
                        Done
                    </Button>
                </Toolbar>
                <Tabs
                    value={rootCategoryFilter}
                    centered
                    indicatorColor="primary"
                    onChange={handleChange}
                >
                    <Tab
                        icon={<Description style={{ fill: 'white' }} />}
                        label="All"
                    />
                    <Tab
                        icon={<Layers style={{ fill: 'white' }} />}
                        label={materialRootCategory.Shingles}
                    />
                    <Tab
                        icon={<Storage style={{ fill: 'white' }} />}
                        label={materialRootCategory.Materials}
                    />
                    <Tab
                        icon={<Build style={{ fill: 'white' }} />}
                        label={materialRootCategory.Services}
                    />
                </Tabs>
            </AppBar>
            <DialogContent style={styles.materialItems}>
                <MaterialItemsList
                    materialItems={materialItems}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    materielItemsFetching={materielItemsFetching}
                    onSearch={onSearch}
                    searchValue={searchValue}
                    onNextClick={onNextClick}
                    onPrevClick={onPrevClick}
                    estimateItems={estimateItems}
                    createEstimateItem={createEstimateItem}
                    deleteEstimateItem={deleteEstimateItem}
                    onColorOptionClick={onColorOptionClick}
                    colorOptions={colorOptions}
                    totalColorOptions={totalColorOptions}
                    totalColorOptionsPages={totalColorOptionsPages}
                    colorOptionsCurrentPage={colorOptionsCurrentPage}
                    onNextPageColorOptionsClick={onNextPageColorOptionsClick}
                    onPrevPageColorOptionsClick={onPrevPageColorOptionsClick}
                />
            </DialogContent>
        </Dialog>
    );
};

const materialItemProps = {
    id: PropTypes.number.isRequired,
    officeId: PropTypes.string.isRequired,
    rootCategoryName: PropTypes.string.isRequired,
    categoryName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string,
    colorOptionsCount: PropTypes.number.isRequired,
};

const colorOptionProps = {
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.string,
    materialItemId: PropTypes.number.isRequired,
    isDeleted: PropTypes.bool,
    isCustom: PropTypes.bool,
    isHidden: PropTypes.bool,
};

MaterialItemsDialog.propTypes = {
    open: PropTypes.bool,
    onDialogDismiss: PropTypes.func.isRequired,
    materialItems: PropTypes.arrayOf(PropTypes.shape(materialItemProps))
        .isRequired,
    totalPages: PropTypes.number.isRequired,
    totalResults: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    materielItemsFetching: PropTypes.bool.isRequired,
    rootCategoryFilter: PropTypes.number.isRequired,
    onRootCategoryFilterSwitch: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    searchValue: PropTypes.string,
    onNextClick: PropTypes.func.isRequired,
    onPrevClick: PropTypes.func.isRequired,
    estimateItems: PropTypes.arrayOf(PropTypes.shape({
        materialItemId: PropTypes.number,
    })),
    createEstimateItem: PropTypes.func.isRequired,
    deleteEstimateItem: PropTypes.func.isRequired,
    onColorOptionClick: PropTypes.func.isRequired,
    colorOptions: PropTypes.arrayOf(PropTypes.shape(colorOptionProps))
        .isRequired,
    totalColorOptions: PropTypes.number.isRequired,
    totalColorOptionsPages: PropTypes.number.isRequired,
    colorOptionsCurrentPage: PropTypes.number.isRequired,
    onNextPageColorOptionsClick: PropTypes.func.isRequired,
    onPrevPageColorOptionsClick: PropTypes.func.isRequired,
};

MaterialItemsDialog.defaultProps = {
    open: false,
    searchValue: '',
    estimateItems: [],
};

export default MaterialItemsDialog;
