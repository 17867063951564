import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

const ChipWrapper = (props) => {
    const Icon = props.icon;

    return (
        <Tooltip title={props.label}>
            <Chip
                classes={{ ...props.classes }}
                avatar={
                    <Avatar style={props.style}>
                        <Icon style={props.style} />
                    </Avatar>
                }
                style={props.style}
                label={props.label}
            />
        </Tooltip>
    );
};

ChipWrapper.propTypes = {
    label: PropTypes.string.isRequired,
    classes: PropTypes.shape({}),
    style: PropTypes.shape({}).isRequired,
    icon: PropTypes.func.isRequired,
};

ChipWrapper.defaultProps = {
    classes: {},
};

export default ChipWrapper;
