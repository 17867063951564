import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const ToolbarIconButton = (props) => {
    const title = props.disabled ? props.disabledTooltip : props.tooltip;

    const button = (
        <div>
            <IconButton onClick={props.onClick} disabled={props.disabled}>
                {React.cloneElement(props.icon, { style: { color: props.color } }) }
            </IconButton>
        </div>);

    return (title ? <Tooltip title={title}>{button}</Tooltip> : button);
};

ToolbarIconButton.propTypes = {
    icon: PropTypes.element.isRequired,
    tooltip: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    disabledTooltip: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string,
};

ToolbarIconButton.defaultProps = {
    disabled: false,
    disabledTooltip: '',
    color: 'rbga(0,0,0,.53)',
};

export default ToolbarIconButton;
