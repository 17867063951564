import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MoneyInput from '../../InputComponents/MoneyInput';

const style = {
    labelMargin: {
        marginTop: '5px',
    },
};

const CustomItemDialog = props => (
    <div>
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Custom Item</DialogTitle>
            <DialogContent>
                <TextField
                    id="customItemDescription"
                    value={props.customItemDescription}
                    onChange={props.handleChange(
                        'customItemFields',
                        'customItemDescription',
                    )}
                    label="Description"
                    type="text"
                    fullWidth
                    style={style.labelMargin}
                    required
                />
                <MoneyInput
                    value={props.customUnitPrice}
                    handleChange={props.handleChange}
                    type="customUnitPrice"
                    parentType="customItemFields"
                    label="Unit Price"
                    required
                />
                <TextField
                    id="customQuantity"
                    value={props.customQuantity}
                    onChange={props.handleChange(
                        'customItemFields',
                        'customQuantity',
                    )}
                    label="Quantity"
                    type="number"
                    fullWidth
                    style={style.labelMargin}
                    required
                />
                <DialogContentText style={style.labelMargin}>
                    <NumberFormat
                        value={props.customTotal}
                        displayType="text"
                        thousandSeparator
                        decimalScale={2}
                        prefix="$"
                        fixedDecimalScale
                        renderText={value => <span>Total: {value}</span>}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Cancel
                </Button>
                <Button
                    disabled={props.disabledCustomAdd}
                    onClick={() => props.handleAdd(props.itemType)}
                    color="primary"
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    </div>
);

CustomItemDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    disabledCustomAdd: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleAdd: PropTypes.func.isRequired,
    customItemDescription: PropTypes.string.isRequired,
    customUnitPrice: PropTypes.string.isRequired,
    customTotal: PropTypes.number.isRequired,
    itemType: PropTypes.number.isRequired,
    customQuantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
};

export default CustomItemDialog;
