import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
    },
    content: {
        marginTop: 0,
        marginRight: 'auto',
        marginBottom: theme.spacing(3),
        marginLeft: 'auto',
        maxWidth: 600,
    },
    button: {
        margin: '0 auto',
        display: 'block',
    },
});

const NonSubscriberContent = props => (
    <Card className={props.classes.root}>
        <Typography
            variant="h5"
            align="center"
            className={props.classes.content}
        >
            Activate your 14-day trial to manage your account&apos;s subscription and services.
        </Typography>
        <Button
            className={props.classes.button}
            variant="outlined"
            size="large"
            color="primary"
            onClick={props.onActivateButtonClick}
        >
            Activate
        </Button>
    </Card>
);

NonSubscriberContent.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        button: PropTypes.string.isRequired,
    }).isRequired,
    onActivateButtonClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(NonSubscriberContent);
