import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CreateEstimateDialog from './CreateEstimateDialog';
import { createProjectEstimate } from '../../actions/ProjectEstimate';
import FloatingActionButton from '../FloatingActionButton';
import LoggerWrapper from '../../lib/Logger';

class CreateEstimate extends Component {
    state = {
        createEstimateDialogOpen: false,
    };

    onCreateEstimate = (estimate) => {
        this.props
            .dispatch(createProjectEstimate(this.props.projectId, estimate))
            .then((result) => {
                if (result && result.response) {
                    try {
                        window.Appcues.track('Created Estimate');
                    } catch (error) {
                        LoggerWrapper.captureException(error);
                    }
                    this.props.history.push(
                        `/projects/${this.props.projectId}/estimates/${result.response.id}`
                    );
                }
            });
        this.setState({
            createEstimateDialogOpen: false,
        });
    };

    onCancelEstimate = () => {
        this.setState({
            createEstimateDialogOpen: false,
        });
    };

    openCreateEstimateDialog = () => {
        this.setState({
            createEstimateDialogOpen: true,
        });
    };

    render() {
        return (
            <div>
                <CreateEstimateDialog
                    open={this.state.createEstimateDialogOpen}
                    onCreateClick={this.onCreateEstimate}
                    onCancelClick={this.onCancelEstimate}
                />
                <FloatingActionButton
                    onClick={this.openCreateEstimateDialog}
                    disabled={this.props.disabled}
                    variant='extended'
                />
            </div>
        );
    }
}

CreateEstimate.propTypes = {
    dispatch: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
};

CreateEstimate.defaultProps = {
    disabled: false,
};

export default withRouter(connect(null)(CreateEstimate));
