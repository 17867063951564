import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ForbiddenDialog from './ForbiddenDialog';

const FeatureDisabledForbiddenDialog = ({ history, isAccountAdmin }) => {
    const navigateBack = () => {
        history.push('/projects');
    };

    const title = isAccountAdmin ? 'Unlock This Feature' : 'Feature Disabled';

    const content = isAccountAdmin ?
        'Upgrade your RoofSnap subscription to access this feature.  Call (877) 766-3762 or email support@roofsnap.com to upgrade today!' :
        'This feature is not enabled for you or your organization. Please contact your account administrator for assistance.';

    return (<ForbiddenDialog
        confirmationAction={navigateBack}
        forbiddenDialogTitle={title}
        forbiddenDialogContentText={content}
    />);
};

FeatureDisabledForbiddenDialog.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    isAccountAdmin: PropTypes.bool,
};

FeatureDisabledForbiddenDialog.defaultProps = {
    isAccountAdmin: false,
};

export default withRouter(FeatureDisabledForbiddenDialog);
