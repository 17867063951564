import { ObjectValues } from './util/typeUtilities';

export const OrderTypes = {
    HalfSnap: 'halfsnap',
    FullSnap: 'fullsnap',
    MetalRoof: 'metalroof',
    Gutters: 'gutters',
};
export type AllowedOrderTypes = ObjectValues<typeof OrderTypes>;
export const OrderTypeIds = {
    FullSnap: 1,
    HalfSnap: 2,
    MetalRoof: 3,
    Gutters: 4,
};
export type PriceGridType = 'FullSnap' | 'HalfSnap' | 'MetalRoof' | 'Gutters';

export const OrderTypeToString: { [key: string]: string } = {
    fullsnap: 'Full Snap',
    halfsnap: 'Half Snap',
    metalroof: 'Metal Roof',
    gutters: 'Gutters',
};

export const OrderTypeTitle: { [key: string]: string } = {
    fullsnap: 'Detailed Report',
    halfsnap: 'Basic Report',
    metalroof: 'Metal Roof Report',
    gutters: 'Gutter Report',
};
