import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        display: 'inline-block',
        marginRight: theme.spacing(2),
        '& svg': {
            fill: grey[600],
            marginRight: theme.spacing(1),
        },
        '& span': {
            display: 'inline-block',
            verticalAlign: 'super',
        },
    },
});

const FooterField = ({ icon, text, classes }) => (
    <div className={classes.root}>
        {icon}<Typography variant="caption" component="span">{text}</Typography>
    </div>
);

FooterField.propTypes = {
    icon: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
    }).isRequired,
};

export default withStyles(styles)(FooterField);
