import React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

const ProjectNotFoundDialog = props => (
    <Dialog
        open={props.open}
        onClose={props.handleClose}
        ignoreBackdropClick
        ignoreEscapeKeyUp
    >
        <DialogTitle id="alert-dialog-title">Project Not Found</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                This project does not exist. Click the button below to go back to view your projects.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose} color="primary">
                Go to Projects
            </Button>
        </DialogActions>
    </Dialog>
);

ProjectNotFoundDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
};

ProjectNotFoundDialog.defaultProps = {
    open: false,
};

export default ProjectNotFoundDialog;
