import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import Error from '@material-ui/icons/Error'
import Pending from '@mui/icons-material/Pending';
import Warning from '@material-ui/icons/Warning'
import SketchOrder from 'lib/Models/SketchOrder'
import NearmapOrder from 'lib/Models/NearmapOrder';
import { CheckCircle } from '@material-ui/icons';

interface OrderStatusChipProps {
    sketchOrder: SketchOrder | NearmapOrder | { sketchOrderStatus: string, freeOrder: boolean, rushed: boolean },
}

type StatusChip = {
    textColor: string,
    backgroundColor: string,
    icon: React.ReactElement,
    statusText: string | JSX.Element
}

const OrderStatusChip = (props: OrderStatusChipProps) => {
    const [colorsIconText, setColorsIconText] = useState<StatusChip>({
        textColor: '#FFFFFF',
        backgroundColor: '#FFFFFF',
        icon: <Error style={{width: 20}}/>, 
        statusText: '' 
    })

    useEffect(() => {
        const orderStatus = 'sketchOrderStatus' in props.sketchOrder ? props.sketchOrder.sketchOrderStatus : props.sketchOrder.status;

        switch (orderStatus) {
            case 'Billed':
            case 'Complete':
            case null:
                setColorsIconText({
                    textColor: '#149718',
                    backgroundColor: '#E7F8EB',
                    icon: <CheckCircle style={{width: 20}}/>,
                    statusText: 'sketchOrderStatus' in props.sketchOrder && props.sketchOrder.freeOrder ? 
                    <span>FREE $<span>0.00</span></span> : 
                    orderStatus ?? 'Nearmap Order'
                })
                break;
            case 'BillingFailed':
                setColorsIconText({
                    textColor: '#DB1F3C',
                    backgroundColor: '#FCEFF2',
                    icon: <Warning style={{width: 20}}/>,
                    statusText: 'Billing Failed'
                })
                break;
            case 'Pending':
            case 'PendingReview':
            case 'InReview':
            case 'PendingRejection':
            case 'Queued':
            case 'InProgress':
            case 'PendingGroupBilling':
                setColorsIconText({
                    textColor: '#149718',
                    backgroundColor: '#E3F9EA',
                    icon: <Pending style={{width: 20}}/>,
                    statusText: 'Pending'
                })
                break;
            case 'Cancelled':
            case 'Incomplete':
                setColorsIconText({
                    textColor: '#555555',
                    backgroundColor: '#F4F2F2',
                    icon: <Error style={{width: 20}}/>,
                    statusText: orderStatus
                })
                break;
            default:
                return
        }

    }, [props.sketchOrder])

    const { textColor, backgroundColor, icon, statusText } = colorsIconText;

    return (
        <Grid item container direction='column' justifyContent='space-between'>
            {('sketchOrderStatus' in props.sketchOrder && props.sketchOrder.rushed) ??
                <Grid item container justifyContent="center" alignItems="center" style={{ color: '#919191' }} alignContent='center'>
                    <img src="/assets/RushDelivered.png" alt="Rushed" style={{ height: 16,  marginBottom: 8 }} />
                </Grid>
            }
            <Grid
                item
                container
                direction='row'
                style={{
                    padding: '6px',
                    backgroundColor: backgroundColor,
                    color: textColor,
                    borderRadius: 4,
                    maxWidth: 240
                }}
                alignContent='center'
                spacing={1}
            >
                <Grid xs={2} item container alignContent='center' justifyContent='center'>
                    {icon}
                </Grid>
                <Grid xs={10} item container alignContent='center' justifyContent='center'>
                    <Typography style={{fontWeight: 500}} data-cy='order-status-chip'>
                        {statusText}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>


    )
}

export default OrderStatusChip;