import { CALL_API } from '../middleware/api';
import httpRequestMethods from '../middleware/httpRequestMethods';

export const CREATE_NEARMAP_ORDER_TRANSACTION_BATCH_REQUEST =
    'CREATE_NEARMAP_ORDER_TRANSACTION_BATCH_REQUEST';
export const CREATE_NEARMAP_ORDER_TRANSACTION_BATCH_SUCCESS =
    'CREATE_NEARMAP_ORDER_TRANSACTION_BATCH_SUCCESS';
export const CREATE_NEARMAP_ORDER_TRANSACTION_BATCH_FAILURE =
    'CREATE_NEARMAP_ORDER_TRANSACTION_BATCH_FAILURE';

export const createNearmapOrderTransactionBatch = (
    nearmapOrderIds,
    paymentMethodNonce,
    useSubscriptionPaymentMethod = false
) => ({
    [CALL_API]: {
        types: [
            CREATE_NEARMAP_ORDER_TRANSACTION_BATCH_REQUEST,
            CREATE_NEARMAP_ORDER_TRANSACTION_BATCH_SUCCESS,
            CREATE_NEARMAP_ORDER_TRANSACTION_BATCH_FAILURE,
        ],
        endpoint: 'nearmapordertransactionsbatch',
        method: httpRequestMethods.POST,
        body: {
            nearmapOrderIds,
            paymentMethodNonce,
            useSubscriptionPaymentMethod,
        },
        apiVersion: 'v1',
    },
});
