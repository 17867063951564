import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { red, green, deepPurple } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CalendarIcon from '@material-ui/icons/InsertInvitation';
import Repeat from '@material-ui/icons/Repeat';
import FooterField from './FooterField';


const styles = theme => ({
    root: {
        flexGrow: 1,
        minHeight: 275, // Because the card will appear blank before it's populated
        position: 'relative',
    },
    content: {
        padding: theme.spacing(3),
    },
    title: {
        marginBottom: theme.spacing(3),
    },
    amountLabel: {
        marginBottom: theme.spacing(3),
    },
    amount: {
        marginBottom: theme.spacing(4),
    },
    active: {
        color: green[500],
    },
    notActive: {
        color: red[500],
    },
    iconText: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: theme.spacing(1),
        },
    },
    trialFlag: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: 'white',
        backgroundColor: deepPurple[500],
        display: 'block',
        height: 30,
        lineHeight: '30px',
        textTransform: 'uppercase',
        textAlign: 'center',
        width: 100,
        '&::before': {
            content: "''",
            borderTop: `30px solid ${deepPurple[500]}`,
            borderLeft: '30px solid transparent',
            width: 0,
            height: 0,
            display: 'block',
            position: 'absolute',
            right: 100,
        },
    },
});

const SubscriptionSummaryCard = (props) => {
    const {
        classes,
        status,
        planName,
        termLength,
        nextBillingDate,
        amount,
        isInTrial,
    } = props;

    const Content = () => {
        // Bail if our props haven't been set yet
        if (!nextBillingDate || !amount || !planName || !status || !termLength) return null;

        // Format some values around next billing date
        const duration = moment.duration(nextBillingDate.diff(new Date()));
        const durationDays = duration.days();
        const nextBillingMonthDay = nextBillingDate.format('MMMM Do');

        // Format the next billing amount
        const currency = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
        const nextBillAmount = currency.format(amount);

        // Status
        const isActive = status === 'Active' ? 'active' : 'not active';

        // Term length
        const term = `Billed every ${termLength === 1 ? 'month' : `${termLength} months`} `;

        return (
            <CardContent className={classes.content}>
                <Typography className={classes.title} variant="h5">{`${planName} subscription plan is `}
                    {isActive && <span className={classes.active}>active</span>}
                    {!isActive && <span className={classes.notActive}>not active</span>}
                </Typography>
                <Typography className={classes.amountLabel} variant="subtitle1">Your next charge</Typography>
                <Typography className={classes.amount} variant="h2">{nextBillAmount}</Typography>
                <FooterField icon={<Repeat />} text={term} />
                <FooterField icon={<CalendarIcon />} text={`Your next payment will be charged on ${nextBillingMonthDay} (${durationDays} days)`} />
            </CardContent>
        );
    };

    return (
        <Card className={classes.root}>
            {/* Separated the inner contents so that the parent card could be rendered before the contents */}
            <Content />
            {isInTrial && <Typography className={classes.trialFlag}>Trial</Typography>}
        </Card>
    );
};

SubscriptionSummaryCard.propTypes = {
    amount: PropTypes.number,
    nextBillingDate: PropTypes.instanceOf(moment),
    status: PropTypes.string,
    planName: PropTypes.string,
    termLength: PropTypes.number,
    isInTrial: PropTypes.bool,
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        amount: PropTypes.string.isRequired,
        trialFlag: PropTypes.string.isRequired,
    }).isRequired,
};

SubscriptionSummaryCard.defaultProps = {
    planName: null,
    status: null,
    amount: null,
    nextBillingDate: null,
    termLength: null,
    isInTrial: null,
};

export default withStyles(styles)(SubscriptionSummaryCard);
