import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = () => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        '& tr:last-child td': {
            border: 'none',
        },
    },
});

const RecentTransactionsTable = ({ classes }) => (
    <Table className={classes.table}>
        <TableBody>
            <TableRow key="Apr 30">
                <TableCell align="left">Apr 30</TableCell>
                <TableCell align="right">$99</TableCell>
            </TableRow>
            <TableRow key="Mar 30">
                <TableCell align="left">Mar 30</TableCell>
                <TableCell align="right">$99</TableCell>
            </TableRow>
            <TableRow key="Feb 30">
                <TableCell align="left">Feb 30</TableCell>
                <TableCell align="right">$99</TableCell>
            </TableRow>
        </TableBody>
    </Table>

);

RecentTransactionsTable.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
        table: PropTypes.string.isRequired,
    }).isRequired,
};

export default withStyles(styles)(RecentTransactionsTable);
