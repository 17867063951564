import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SketchComponent } from 'roofsnap-sketchtool';
import { hideSketch } from '../../actions/SketchHost';
import GetHelpMenu from '../GetHelpMenu/GetHelpMenu.tsx';
import featureFlags from '../../lib/FeatureFlags.ts';

const SketchComponentHostComponent = (props) => {
    const onClose = () => {
        props.history.push(`/projects/${props.projectId}`);
        props.dispatch(hideSketch());
    };

    return (
        <div>
            <SketchComponent
                start={props.show}
                jwt={props.accessToken}
                baseApiUrl={props.baseApiUrl}
                baseFacetFindingApiUrl={props.baseFacetFindingApiUrl}
                baseMeasurementApiUrl={props.baseMeasurementApiUrl}
                apiKey={props.apiKey}
                projectId={props.projectId}
                cocosScript='https://roofsnapstaticassets.blob.core.windows.net/assets/cocos2d-js-v3.12-min.js'
                onClose={onClose}
                rsClient='DeceptiveDolphin'
                basePitchApiUrl={props.basePitchApiUrl}
                displayPitchTool={props.currentUserRoles.isAdmin}
                disablePitchTool
                saveImages
                leftSideItems={<GetHelpMenu enterFromLeft />}
                hideGutterPins={!featureFlags.diyGutters}
            />
        </div>
    );
};

SketchComponentHostComponent.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    accessToken: PropTypes.string,
    baseApiUrl: PropTypes.string.isRequired,
    baseFacetFindingApiUrl: PropTypes.string.isRequired,
    baseMeasurementApiUrl: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    apiKey: PropTypes.string.isRequired,
    projectId: PropTypes.string,
};

SketchComponentHostComponent.defaultProps = {
    accessToken: '',
    projectId: '',
};

const mapState = (state) => {
    const { auth, sketchHost, currentUserRoles } = state;
    const { accessToken } = auth;
    return {
        ...sketchHost,
        accessToken,
        currentUserRoles,
    };
};

export default connect(mapState)(SketchComponentHostComponent);
