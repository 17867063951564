import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Tile from '../Tile';

const styles = theme => ({
    tileContainer: {
        backgroundColor: 'transparent',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
});

const TileSelector = (props) => {
    const {
        classes,
        selectedTileId,
        handleTileSelect,
        tiles,
    } = props;
    return (
        <Grid container justify="center" spacing={1} className={classes.tileContainer}>
            {tiles.map(tile => (
                <Grid key={tile.id} item xs={12} sm={4}>
                    <Tile id={tile.id} title={tile.title} icon={tile.icon} selected={tile.id === selectedTileId} onClick={() => handleTileSelect(tile.id)} />
                </Grid>
            ))}
        </Grid>
    );
};

TileSelector.propTypes = {
    tiles: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        icon: PropTypes.node,
    })).isRequired,
    selectedTileId: PropTypes.string,
    handleTileSelect: PropTypes.func,
};

TileSelector.defaultProps = {
    handleTileSelect: () => { },
    selectedTileId: '',
};

export default withStyles(styles)(TileSelector);
