import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Button,Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

interface GetHelpMenuButtonProps extends WithStyles<typeof styles> {
    icon: any;
    buttonText: string;
    handleClick: any;
};

const styles = () => createStyles({
    root: {
        width: '25%',
        height: '55%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
    },
    contactUsGrid: {
        width: '100%',
        height: '50px',
        backgroundColor: '#E2E3E5',
        textAlign: 'center',
        padding: 0,
    },
    contactUsText: {
        fontSize: '18px',
        fontWeight: 'bold',
        fontFamily: 'Helvetica',
    },
    buttonContainer: {
        width: '100%',
    },
    button: {
        width: '100%',
        border: '1px solid #E9EBED',
        borderRadius: '18px',
        padding: '14px 8px',
        textTransform: 'none',
    },
    buttonText: {
        fontSize: '14px',
        fontFamily: 'Helvetica',
    },
    chevron: {
        verticalAlign: 'middle',
    }
})


const GetHelpMenuButton = (props: GetHelpMenuButtonProps) => {
    const { classes, buttonText, icon } = props;
    return (
        <Grid item className={classes.buttonContainer}>
            <Button onClick={props.handleClick} className={classes.button}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center" >
                    <Grid item xs={3}>
                        {icon}
                    </Grid>
                    <Grid container item xs={7}>
                        <Grid container direction="column" justifyContent="space-evenly" alignItems="center">
                            <Grid container item alignItems="flex-start">
                                <Typography className={classes.buttonText} >
                                    {buttonText}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <ChevronRight className={classes.chevron}/>
                    </Grid>
                </Grid>
            </Button>
        </Grid>
    )
}

export default (withStyles(styles)(GetHelpMenuButton));