import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { requestResetPassword } from '../../actions/ResetPassword';
import ForgotPasswordForm from './ForgotPasswordForm';
import ForgotPasswordComplete from './ForgotPasswordComplete';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';

const style = {
    wrapper: {
        justifyContent: 'center',
        display: 'flex',
        marginTop: 120,
    },
};

class ForgotPassword extends Component {
    state = {
        resetPasswordSuccessful: false,
    }

    requestResetPassword = async (email) => {
        try {
            const response = await this.props.dispatch(requestResetPassword(email));
            if (response.error) {
                this.props.dispatch(setReduxMessage('Oops... Something went wrong. Please try again'));
                this.setState({ resetPasswordSuccessful: false });
                return;
            }

            this.setState({ resetPasswordSuccessful: true });
        } catch (error) {
            this.props.dispatch(setReduxMessage('Oops... Something went wrong. Please try again'));
            this.setState({ resetPasswordSuccessful: false });
        }
    };

    goToLogin = () => {
        this.props.history.push('/login');
    };

    render() {
        return (
            <div className={this.props.classes.wrapper}>
                {
                    this.state.resetPasswordSuccessful ? (
                        <ForgotPasswordComplete onConfirmClick={this.goToLogin} />
                    ) : (
                        <ForgotPasswordForm
                            onResetPasswordClick={this.requestResetPassword}
                        />
                    )
                }
            </div>
        );
    }
}

ForgotPassword.propTypes = {
    classes: PropTypes.shape({
        wrapper: PropTypes.string.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default connect(null)(withStyles(style)(ForgotPassword));
