import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import filter from 'lodash/filter';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { Link } from 'react-router-dom';
import SketchOrderList from '../SketchOrders/SketchOrderList';
import {
    getBillingFailedSketchOrdersByOrganization,
    getRequestedOrOwnedBillingFailedSketchOrders,
    GET_ORGANIZATIONS_BILLING_FAILED_SKETCH_ORDERS_SUCCESS,
    GET_REQUESTED_OR_OWNED_BILLING_FAILED_SKETCH_ORDERS_SUCCESS,
} from '../../actions/BillingFailedSketchOrdersActions';
import {
    createSketchOrderTransactionBatch,
    CREATE_SKETCH_ORDER_TRANSACTION_BATCH_SUCCESS,
} from '../../actions/sketchOrderTransactionBatchActions';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';
import RetrySketchOrderBillingDialog from '../SketchOrder/RetrySketchOrderBillingDialog';
import Alert from '../Alert';
import httpStatusCodes from '../../middleware/httpStatusCodes';
import roofsnapTheme from '../../lib/theme';

const style = {
    root: {
        padding: 24,
    },
    card: {
        width: 900,
        margin: 'auto',
    },
    viewProjectsLink: {
        color: roofsnapTheme.palette.roofsnapBlue[100],
    },
};

export class BillingFailedSketchOrders extends Component {
    state = {
        sketchOrders: [],
        updatePaymentDialogOpen: false,
        errorMessage: null,
        isRetryingBilling: false,
        currentPage: 1,
    };

    componentDidMount() {
        this.getBillingFailedSketchOrders(this.state.currentPage);
    }

    onPaymentChange = (paymentType, paymentValid, getNonce) => {
        this.setState({
            paymentType,
            paymentValid,
            getNonce,
        });
    };

    onRetryBilling = () => {
        this.setState({
            updatePaymentDialogOpen: true,
        });
    };

    getBillingFailedSketchOrders = async (page) => {
        if (this.props.currentUserRoles.isAccountAdmin) {
            const result = await this.props.dispatch(getBillingFailedSketchOrdersByOrganization(
                this.props.organization.id,
                page,
            ));

            if (result.type === GET_ORGANIZATIONS_BILLING_FAILED_SKETCH_ORDERS_SUCCESS) {
                const sketchOrders = result.response.resourceList.map(sketchOrder => ({
                    ...sketchOrder,
                    selected: false,
                }));
                this.setState({
                    sketchOrders,
                });
            }
        } else {
            const result = await this.props.dispatch(getRequestedOrOwnedBillingFailedSketchOrders(
                this.props.currentUser.userId,
                page,
            ));

            if (result.type === GET_REQUESTED_OR_OWNED_BILLING_FAILED_SKETCH_ORDERS_SUCCESS) {
                const sketchOrders = result.response.resourceList.map(sketchOrder => ({
                    ...sketchOrder,
                    selected: false,
                }));
                this.setState({
                    sketchOrders,
                });
            }
        }
    };

    getNextPageOfOrders = async () => {
        const newPage = this.state.currentPage + 1;
        this.setState({ currentPage: newPage });

        await this.getBillingFailedSketchOrders(newPage);
    };

    getPrevPageOfOrders = async () => {
        const newPage = this.state.currentPage - 1;
        this.setState({ currentPage: newPage });

        await this.getBillingFailedSketchOrders(newPage);
    };

    goToSketchOrder = (id) => {
        this.props.history.push(`/billingfailedorders/${id}`);
    };

    handleOnSelectSketchOrderClick = (index, checked) => {
        const sketchOrders = [...this.state.sketchOrders];
        sketchOrders[index].selected = checked;

        this.setState({ sketchOrders });
    };

    handleOnSelectedAllClick = (checked) => {
        const sketchOrders = this.state.sketchOrders.map(sketchOrder => ({
            ...sketchOrder,
            selected: checked,
        }));
        this.setState({ sketchOrders });
    };

    handleUpdatePaymentDialogClose = () => {
        this.setState({
            updatePaymentDialogOpen: false,
        });
    };

    handleRetryBilling = async () => {
        this.setState({
            errorMessage: null,
            isRetryingBilling: true,
        });

        const selectedSketchOrdersToRetryBilling = filter(this.state.sketchOrders, 'selected');
        const selectedSketchOrderIds = selectedSketchOrdersToRetryBilling.map(sketchOrder => sketchOrder.id);
        const nonce = await this.state.getNonce();
        const result = await this.props.dispatch(createSketchOrderTransactionBatch(selectedSketchOrderIds, nonce));

        if (result.type === CREATE_SKETCH_ORDER_TRANSACTION_BATCH_SUCCESS) {
            const { response } = result;
            // If we have an empty response, all good
            if (response.status === httpStatusCodes.NoContent) {
                this.props.dispatch(setReduxMessage(
                    'Payment successful. Your projects are now available.',
                    <Link
                        to="/projects"
                        style={style.viewProjectsLink}
                    >
                        View Projects
                    </Link>,
                ));
            } else {
                let errorMessage = 'Payment method has been declined.';

                const error = response[0];
                if (error.processorResponseType === 'soft_declined') {
                    errorMessage += ' Please contact your bank before trying again.';
                }

                if (error.processorResponseType === 'hard_declined') {
                    errorMessage += ' Please use a different card.';
                }

                this.setState({
                    errorMessage,
                });
            }
        } else {
            this.setState({
                errorMessage: result.error,
            });
        }

        this.setState({
            updatePaymentDialogOpen: false,
            isRetryingBilling: false,
        });
        this.getBillingFailedSketchOrders();
    };

    areAllSketchOrdersSelected = () => {
        const selectedSketchOrdersToRetryBilling = filter(this.state.sketchOrders, 'selected');
        const result = this.state.sketchOrders.length === selectedSketchOrdersToRetryBilling.length;
        return result;
    };

    areAnySketchOrdersSelected = () => {
        const selectedSketchOrdersToRetryBilling = filter(this.state.sketchOrders, 'selected');
        return !!selectedSketchOrdersToRetryBilling.length;
    };

    dismissBillingFailedAlert = () => {
        this.setState({
            errorMessage: null,
        });
    };

    render() {
        const allSketchOrdersSelected = this.areAllSketchOrdersSelected();
        const anySketchOrdersSelected = this.areAnySketchOrdersSelected();
        return (
            <div style={style.root}>
                <Alert
                    title="Payment Failed"
                    message={this.state.errorMessage}
                    variant="error"
                    open={(!!this.state.errorMessage)}
                    dismissable
                    onDismiss={this.dismissBillingFailedAlert}
                />

                {
                    (this.props.isFetching === false && this.props.totalResults === 0) &&
                    <Card style={style.card}>
                        <CardHeader
                            title="Hooray! You are all caught up!"
                            subheader={
                                <p>
                                    Click <Link to="/projects">here</Link> to view your projects.
                                </p>
                            }
                        />
                    </Card>
                }

                {
                    (this.props.totalResults > 0) &&
                    <SketchOrderList
                        sketchOrders={this.state.sketchOrders}
                        totalResults={this.props.totalResults}
                        totalPages={this.props.totalPages}
                        currentPage={this.state.currentPage}
                        onListItemClick={this.goToSketchOrder}
                        onNextClick={this.getNextPageOfOrders}
                        onPrevClick={this.getPrevPageOfOrders}
                        titleSuffix="Have Failed to Bill"
                        isFreeAccount={this.props.organization.isFreeAccount}
                        selectable
                        onSelectSketchOrder={this.handleOnSelectSketchOrderClick}
                        selectedAction={
                            <Button
                                color="primary"
                                onClick={this.onRetryBilling}
                                disabled={!anySketchOrdersSelected}
                            >
                                Retry Billing
                            </Button>
                        }
                        onSelectAllClick={this.handleOnSelectedAllClick}
                        allSketchOrdersSelected={allSketchOrdersSelected}
                    />
                }

                <RetrySketchOrderBillingDialog
                    open={this.state.updatePaymentDialogOpen}
                    onClose={this.handleUpdatePaymentDialogClose}
                    onRetryBilling={this.handleRetryBilling}
                    onPaymentChange={this.onPaymentChange}
                    retryButtonDisabled={!(this.state.paymentType && this.state.paymentValid)}
                    isRetryingBilling={this.state.isRetryingBilling}
                    organization={this.props.organization}
                    currentUser={this.props.currentUser}
                    currentUserRoles={this.props.currentUserRoles}
                />
            </div>
        );
    }
}

BillingFailedSketchOrders.propTypes = {
    dispatch: PropTypes.func.isRequired,
    totalResults: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    isFetching: PropTypes.bool.isRequired,
    currentUser: PropTypes.shape({
        userId: PropTypes.number,
    }).isRequired,
    organization: PropTypes.shape({
        id: PropTypes.number,
        isFreeAccount: PropTypes.bool,
    }).isRequired,
    currentUserRoles: PropTypes.shape({
        isAccountAdmin: PropTypes.bool,
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

const mapStateToProps = (state) => {
    const {
        billingFailedSketchOrders: billingFailedSketchOrdersStore,
        currentUserRoles,
        currentUser,
        organization,
    } = state;

    const {
        totalResults,
        totalPages,
        currentPage,
        isFetching,
    } = billingFailedSketchOrdersStore;

    return {
        totalResults,
        totalPages,
        currentPage,
        currentUser,
        organization,
        currentUserRoles,
        isFetching,
    };
};

export default connect(mapStateToProps)(BillingFailedSketchOrders);
