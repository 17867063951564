import React from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';
import useBox, { PartialBoxProps } from 'hooks/useBox';

type FilterSelectorProps<
  TFilterMap extends { [key: string]: string[] },
  TMapKey
> = {
  keyValueMap: TFilterMap;
  selectedKey: TMapKey;
  onChange: (key: TMapKey) => void;
} & PartialBoxProps;

const FilterSelector = <
  TFilterMap extends { [key: string]: string[] },
  TMapKey
>(
  props: FilterSelectorProps<TFilterMap, TMapKey>
) => {
  const { keyValueMap, selectedKey, onChange } = props;

  const boxProps = useBox(props);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newKey = event.target.value as TMapKey;
    onChange(newKey);
  };

  return (
    <Box {...boxProps}>
      <FormControl fullWidth>
        <Select value={selectedKey} onChange={handleChange}>
          {Object.keys(keyValueMap).map((key) => (
            <MenuItem key={key} value={key}>
              {key === 'All' ? 'Filter by' : key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FilterSelector;
