import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showSketch } from '../../actions/SketchHost';

class SketchRoute extends React.Component {
    state = {
        showingSketch: false,
    };

    componentDidMount() {
        this.openSketch();
    }

    componentDidUpdate() {
        this.openSketch();
    }

    openSketch = () => {
        const {
            dispatch, currentProject, history, match,
        } = this.props;

        if (currentProject.id === match.params.id && !this.state.showingSketch) {
            if (currentProject.hasDrawing) {
                dispatch(showSketch(currentProject.id));

                /*
                this stops a never ending loop in the rendering cycle.  the state will be reset
                each time someone goes to the route, so this just stops the dispatch happening more than
                once per route render.
                 */
                this.setState({ showingSketch: true });
            } else {
                history.replace(`/projects/${currentProject.id}/imagery`);
            }
        }
    };

    render() {
        return null;
    }
}

SketchRoute.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    currentProject: PropTypes.shape({
        id: PropTypes.string,
        hasDrawing: PropTypes.bool,
    }).isRequired,
};

SketchRoute.defaultProps = {
};

const mapStateToProps = (state) => {
    const { currentProject } = state.projectHome;
    return { currentProject };
};

export default connect(mapStateToProps)(SketchRoute);
