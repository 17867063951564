import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/Auth';
import ForbiddenDialog from './ForbiddenDialog';

const AccountInactiveForbiddenDialog = ({ dispatch }) => {
    const logOut = () => {
        dispatch(logoutUser());
    };

    return (<ForbiddenDialog
        confirmationAction={logOut}
        forbiddenDialogTitle="Account Inactive"
        forbiddenDialogContentText="Your account is currently deactivated. Please contact your account administrator to have it activated."
    />);
};

AccountInactiveForbiddenDialog.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default connect(null)(AccountInactiveForbiddenDialog);
