import {
    GET_PROJECTS_REQUEST,
    GET_PROJECTS_SUCCESS,
    GET_PROJECTS_FAILURE,
    SELECT_STATUS,
    SELECT_OFFICE,
    SELECT_USER,
    CLEAR_FILTER,
} from '../actions/Projects';
import LocalStorageWrapper from '../lib/LocalStorageWrapper';

function projects(
    state = {
        totalResults: 0,
        totalPages: 0,
        currentPage: 1,
        data: [],
        isFetching: false,
        isFetchingText: '',
        selectedUser: LocalStorageWrapper.getItem('user') ? LocalStorageWrapper.getItem('user').userId : 0,
    },
    action,
) {
    switch (action.type) {
    case GET_PROJECTS_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
            isFetchingText: 'Fetching projects...',
        });
    case GET_PROJECTS_SUCCESS:
        return Object.assign({}, state, {
            totalResults: action.response.totalResults,
            totalPages: action.response.totalPages,
            currentPage: action.response.page,
            data: action.response.resourceList,
            isFetching: false,
            isFetchingText: '',
        });
    case GET_PROJECTS_FAILURE:
        return Object.assign({}, state, {
            totalResults: 0,
            totalPages: 0,
            currentPage: 0,
            data: [],
            errorMessage: action.message,
            isFetching: false,
            isFetchingText: '',
        });
    case SELECT_STATUS:
        return Object.assign({}, state, {
            selectedStatus: action.status,
        });
    case SELECT_OFFICE:
        return Object.assign({}, state, {
            selectedOffice: action.office,
        });
    case SELECT_USER:
        return Object.assign({}, state, {
            selectedUser: action.user,
        });
    case CLEAR_FILTER:
        return Object.assign({}, state, {
            selectedUser: 0,
            selectedStatus: '',
            selectedOffice: 0,
        });
    default:
        return state;
    }
}

export default projects;
