import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        padding: theme.spacing(2),
        textAlign: 'center',
        border: `solid 1px ${theme.palette.text.secondary}`,
        borderRadius: theme.shape.borderRadius,
        height: 150,
        width: '100%',
        margin: 'auto',
        color: `${theme.palette.text.secondary}`,
        '&:hover': {
            backgroundColor: `${theme.palette.secondary[50]}`,
            borderColor: `${theme.palette.secondary.main}`,
            cursor: 'pointer',
            color: `${theme.palette.secondary.main}`,
        },
    },
    selected: {
        backgroundColor: `${theme.palette.secondary[50]}`,
        borderColor: `${theme.palette.secondary.main}`,
        borderWidth: '3px',
        color: `${theme.palette.secondary.main}`,
    },
    titleText: {
        lineHeight: '1.1em',
        [theme.breakpoints.down('md')]: {
            fontSize: '14px',
        },
    },
});

const Tile = props => (
    <Grid
        id={props.id}
        spacing={1}
        container
        justify="center"
        alignItems="center"
        selected={props.selected}
        className={classNames(
            props.classes.root,
            props.selected ? props.classes.selected : '',
        )}
        onClick={props.onClick}
    >
        <Grid item xs={12}>
            {props.icon}
        </Grid>
        <Grid item xs={12}>
            <Typography className={props.classes.titleText} variant="body1" color="inherit">{props.title}</Typography>
        </Grid>
    </Grid>
);

Tile.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    id: PropTypes.string,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
};

Tile.defaultProps = {
    id: '',
    onClick: () => { },
    selected: false,
};

export default withStyles(styles)(Tile);
