import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import AddCircle from '@material-ui/icons/AddCircle';
import { getProjectEstimates } from '../../actions/ProjectEstimates';
import {
    deleteProjectEstimate,
    updateProjectEstimate,
} from '../../actions/ProjectEstimate';
import ProjectEstimateList from './ProjectEstimateList';
import CreateEstimate from '../CreateEstimate/CreateEstimate';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import RenameEstimateDialog from '../RenameEstimateDialog';
import { handleMissingOffices } from '../../actions/Offices';
import Notice from '../Messaging/Notice';

const style = {
    root: {
        padding: 24,
    },
    exportPdfButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: 24,
    },
};

class ProjectEstimates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteEstimateModalOpen: false,
            projectId: '',
            estimateId: '',
            estimateName: '',
            renameEstimateModalOpen: false,
            estimateToUpdate: {},
        };

        this.disableViewEstimateItems = false;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.handleMissingOffices();
        this.props.dispatch(getProjectEstimates(this.props.match.params.id));
    }

  onExportPdfClick = () =>
      this.props.history.push(`/projects/${this.props.match.params.id}/documents?createDocumentOpen=true`);

  nextEstimatesPageClick = () => {
      const nextPage = this.props.currentPage + 1;

      this.props.dispatch(getProjectEstimates(this.props.match.params.id, nextPage));
  };

  prevEstimatesPageClick = () => {
      const prevPage = this.props.currentPage - 1;

      this.props.dispatch(getProjectEstimates(this.props.match.params.id, prevPage));
  };

  openDeleteEstimateConfirmationDialog = (
      projectId,
      estimateId,
      estimateName,
  ) => {
      this.setState({
          deleteEstimateModalOpen: true,
          projectId,
          estimateId,
          estimateName,
      });
  };

  openRenameEstimateDialog = (projectId, estimateId, estimate) => {
      this.setState({
          renameEstimateModalOpen: true,
          projectId,
          estimateId,
          estimateToUpdate: estimate,
      });
  };

  handleDismissDeleteEstimateDialog = () => {
      this.setState({
          deleteEstimateModalOpen: false,
          projectId: '',
          estimateId: '',
          estimateName: '',
      });
  };

  handleEstimateItemClick = (estimateId) => {
      if (this.disableViewEstimateItems) {
          return;
      }
      this.props.history.push(`/projects/${this.props.match.params.id}/estimates/${estimateId}`);
  };

  handleDismissRenameEstimateDialog = () => {
      this.setState({
          renameEstimateModalOpen: false,
          projectId: '',
          estimateId: '',
          estimateToUpdate: {},
      });
  };

  deleteEstimate = () => {
      this.props
          .dispatch(deleteProjectEstimate(this.state.projectId, this.state.estimateId))
          .then(() => {
              this.setState({ deleteEstimateModalOpen: false });
              this.props.dispatch(getProjectEstimates(this.props.match.params.id));
          });
  };

  handleMissingOffices = () => {
      if (this.props.offices.length === 0) {
          this.props.dispatch(handleMissingOffices());
      }
  };
  handleRenameChange = (event) => {
      this.setState({
          estimateToUpdate: {
              ...this.state.estimateToUpdate,
              name: event.target.value,
          },
      });
  };
  handleRenameEstimate = () => {
      this.props
          .dispatch(updateProjectEstimate(
              this.state.projectId,
              this.state.estimateId,
              this.state.estimateToUpdate.version,
              this.state.estimateToUpdate,
          ))
          .then(() => {
              this.setState({ renameEstimateModalOpen: false });
              this.props.dispatch(getProjectEstimates(this.props.match.params.id));
          });
  };

  render() {
      const max5 = this.props.totalResults >= 5;

      return (
          <div style={style.root}>
              <Notice visible={max5}>
                  Projects are limited to five estimate options. Delete one or more
                  estimates to add more.
              </Notice>
              {this.props.totalResults > 0 && (
                  <div style={style.exportPdfButtonContainer}>
                      <Chip
                          id="exportPdfButton"
                          label="Export PDF"
                          icon={<AddCircle />}
                          component="button"
                          onClick={this.onExportPdfClick}
                          clickable
                          variant="outlined"
                      />
                  </div>
              )}
              <ProjectEstimateList
                  estimates={this.props.estimates}
                  totalResults={this.props.totalResults}
                  totalPages={this.props.totalPages}
                  currentPage={this.props.currentPage}
                  prevEstimatesPageClick={this.prevEstimatesPageClick}
                  nextEstimatesPageClick={this.nextEstimatesPageClick}
                  isFetching={this.props.isFetching}
                  onEstimateItemClick={this.handleEstimateItemClick}
                  onDeleteEstimate={this.openDeleteEstimateConfirmationDialog}
                  onEditEstimate={this.openRenameEstimateDialog}
              />
              <ConfirmationModal
                  title={`Delete ${this.state.estimateName}`}
                  description="Are you sure you want to remove this estimate?"
                  handleConfirm={this.deleteEstimate}
                  confirmText="Delete"
                  isOpen={this.state.deleteEstimateModalOpen}
                  handleClose={this.handleDismissDeleteEstimateDialog}
              />
              <CreateEstimate
                  projectId={this.props.match.params.id}
                  disabled={max5}
              />
              <RenameEstimateDialog
                  estimate={this.state.estimateToUpdate}
                  open={this.state.renameEstimateModalOpen}
                  onChange={this.handleRenameChange}
                  onCancelClick={this.handleDismissRenameEstimateDialog}
                  onSaveClick={this.handleRenameEstimate}
              />
          </div>
      );
  }
}

const projectEstimateProps = {
    id: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    templateId: PropTypes.string,
    subTotal: PropTypes.number.isRequired,
    taxes: PropTypes.number.isRequired,
    grandTotal: PropTypes.number.isRequired,
    discount: PropTypes.number.isRequired,
    isChosen: PropTypes.bool.isRequired,
    version: PropTypes.string.isRequired,
};

ProjectEstimates.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    estimates: PropTypes.arrayOf(PropTypes.shape(projectEstimateProps))
        .isRequired,
    totalResults: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    isFetching: PropTypes.bool,
    offices: PropTypes.arrayOf(PropTypes.shape),
};

ProjectEstimates.defaultProps = {
    isFetching: false,
    offices: [],
};

const mapStateToProps = (state) => {
    const { projectEstimates, auth, userOffices } = state;
    const {
        totalResults, totalPages, currentPage, data, isFetching,
    } =
    projectEstimates;
    const { data: offices } = userOffices;
    const { userRoles } = auth;

    return {
        totalResults,
        totalPages,
        currentPage,
        isFetching,
        estimates: data,
        offices,
        userRoles,
    };
};

export default connect(mapStateToProps)(ProjectEstimates);
