import React from "react";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

interface NavButtonProps extends WithStyles<typeof styles> {
  title: string;
  isActive: boolean;
  onClick: () => void;
  id: string;
  index: number;
  cypressId: string;
}

const styles = () =>
  createStyles({
    textButton: {
      font: "normal normal normal 15px/60px Roboto",
      letterSpacing: "0.56px",
      color: "#222222",
      padding: "0px 28px",
      margin: "0px 19px 0px 19px",
      width: "100%",
      justifyContent: "flex-start",
      borderRadius: "8px",
      textTransform: "none",
    },
  });

const NavButton = (props: NavButtonProps) => {
  const { classes, cypressId, title } = props;

  return (
    <Button
      id={props.id}
      className={classes.textButton}
      style={
        props.isActive
          ? { backgroundColor: "#1F87F026", color: "#1E79D6", fontWeight: 500 }
          : { color: "#222222" }
      }
      variant="text"
      onClick={() => {props.onClick()}}
      data-cy={cypressId}
    >
      {title}
    </Button>
  );
};

export default withStyles(styles)(NavButton);
