import React, { ReactNode } from 'react'
import { Grid, Typography } from '@material-ui/core';

interface SettingsRowProps {
    settingTitle?: string | null,
    settingDescription?: string | null,
    settingComponent?: ReactNode,
    middleSection?: ReactNode,
    rowStyle?: string,
    descriptionStyle?: string,
    'data-cy'?: string | undefined,
}

const SettingsRow: React.FC<SettingsRowProps> = (props: SettingsRowProps): JSX.Element => {

    return (
        <Grid
            className={props.rowStyle}
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            container
            item
            xs={12}
            data-cy={props['data-cy']}
        >

            <Grid item md={4} sm={12} container direction="column">
                <Grid item container direction="row" alignItems="center" >
                    <Grid item xs={12} data-cy="setting-title">
                        <Typography variant='body1'>{props.settingTitle}</Typography>
                    </Grid>
                </Grid>
                {
                    props.settingDescription &&
                    <Grid item xs={12}>
                        <Typography variant='caption' data-cy="setting-description">
                            {props.settingDescription}
                        </Typography>
                    </Grid>
                }
            </Grid>
            {props.middleSection &&
                <Grid item container md={props.settingComponent ? 4 : 8} sm={12} data-cy="row-middle-section">
                    {props.middleSection}
                </Grid>
            }
            <Grid md={4} sm={12} alignItems='center' item container spacing={1} justifyContent='flex-end' data-cy="setting-component">
                {props.settingComponent}
            </Grid>
        </Grid>
    )
}

export default SettingsRow;
export type { SettingsRowProps };