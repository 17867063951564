import { CALL_API, httpMethod } from '../middleware/api';
import { SET_REDUX_MESSAGE } from './ReduxMessagesActions';

export const CREATE_SKETCH_REPORT_REQUEST = 'CREATE_SKETCH_REPORT_REQUEST';
export const CREATE_SKETCH_REPORT_SUCCESS = 'CREATE_SKETCH_REPORT_SUCCESS';
export const CREATE_SKETCH_REPORT_FAILURE = 'CREATE_SKETCH_REPORT_FAILURE';

export const createSketchReport = (projectId, organizationId) => ({
    [CALL_API]: {
        types: [
            CREATE_SKETCH_REPORT_REQUEST,
            CREATE_SKETCH_REPORT_SUCCESS,
            CREATE_SKETCH_REPORT_FAILURE,
            SET_REDUX_MESSAGE,
        ],
        endpoint: 'documents',
        method: httpMethod.POST,
        body: {
            DataContextEntityId: projectId,
            OrganizationId: organizationId,
            // hard coded template ID for new sketch report
            TemplateId: process.env.REACT_APP_SKETCH_REPORT_TEMPLATE_ID,
        },
        apiVersion: 'v2',
    },
});
