import { CALL_API, httpMethod } from '../middleware/api';

export const GET_MATERIAL_ITEM_COLOR_OPTIONS_REQUEST = 'GET_MATERIAL_ITEM_COLOR_OPTIONS_REQUEST';
export const GET_MATERIAL_ITEM_COLOR_OPTIONS_SUCCESS = 'GET_MATERIAL_ITEM_COLOR_OPTIONS_SUCCESS';
export const GET_MATERIAL_ITEM_COLOR_OPTIONS_FAILURE = 'GET_MATERIAL_ITEM_COLOR_OPTIONS_FAILURE';

export const getMaterialItemColorOptions = (officeId, materialItemId, page = 1) => ({
    [CALL_API]: {
        types: [GET_MATERIAL_ITEM_COLOR_OPTIONS_REQUEST, GET_MATERIAL_ITEM_COLOR_OPTIONS_SUCCESS, GET_MATERIAL_ITEM_COLOR_OPTIONS_FAILURE],
        method: httpMethod.GET,
        endpoint: `offices/${officeId}/materialitems/${materialItemId}/coloroptions`,
        params: {
            page,
        },
    },
});
