import {
    GET_DOCUMENT_TEMPLATE_CATEGORIES_REQUEST,
    GET_DOCUMENT_TEMPLATE_CATEGORIES_SUCCESS,
    GET_DOCUMENT_TEMPLATE_CATEGORIES_FAILURE,
} from '../actions/documentTemplateCategoriesActions';

const initialState = {
    isFetching: false,
    totalPages: 0,
    totalResults: 0,
    currentPage: 0,
    documentTemplateCategories: [],
};

const documentTemplateCategoriesReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_DOCUMENT_TEMPLATE_CATEGORIES_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case GET_DOCUMENT_TEMPLATE_CATEGORIES_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            totalPages: action.response.totalPages,
            totalResults: action.response.totalResults,
            currentPage: action.response.currentPage,
            documentTemplateCategories: action.response.resourceList,
        });
    case GET_DOCUMENT_TEMPLATE_CATEGORIES_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
        });
    default:
        return state;
    }
};

export default documentTemplateCategoriesReducer;
