import { Box, Grid, IconButton, Menu, MenuItem, Stack } from '@mui/material';
import { Chip, Typography } from '@ui';
import { Estimate } from 'lib/Models/Estimate';
import { fromIsoUTCDateToString, USDollar } from 'lib/util/formatters';
import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const EstimatesListItem = ({ estimate }: { estimate: Estimate }) => {
    const ShingleAndTotal = () => {
        const title = estimate.name || 'Selected Roofing Material';

        return (
            <Grid item xs={6} md={5}>
                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    alignItems={{ xs: 'flex-start', md: 'center' }}
                    gap='0.9375rem'
                >
                    <img
                        src='/assets/project-detail-row-drawing-pending.svg'
                        alt='Drawing'
                    />
                    <Box
                        sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        <Typography variant='body-large' title={title}>
                            {title}
                        </Typography>
                        <br />
                        <Typography variant='body-large' title={title}>
                            {USDollar.format(estimate.grandTotal)}
                        </Typography>
                        <br />
                        <Typography
                            variant='body-medium'
                            sx={{ color: '#555' }}
                        >
                            Selected Color
                        </Typography>
                    </Box>
                </Stack>
            </Grid>
        );
    };

    const DisplayDate = () => (
        <Grid item md={4} xs={8} order={{ xs: 4, md: 2 }}>
            <Typography
                sx={{
                    color: '#555',
                    textAlign: { xs: 'right', md: 'left' },
                    mt: { xs: '45px', md: 0 },
                }}
            >
                {fromIsoUTCDateToString(estimate.updatedAt)}
            </Typography>
        </Grid>
    );

    const Status = () => (
        <Grid
            item
            md={2}
            xs={4}
            sx={{
                mt: { xs: '45px', md: 0 },
            }}
            order={3}
        >
            <Box sx={{ display: 'flex' }}>
                <Chip label='Saved' variant='green' />
            </Box>
        </Grid>
    );

    return (
        <Grid
            sx={{
                padding: { xs: '1.5rem', md: '1.25rem' },
                borderRadius: '0.75rem',
                border: '1px solid #d9d9d9',
                background: '#fff',
                alignItems: 'center',
            }}
            container
            xs={12}
        >
            <ShingleAndTotal />
            <DisplayDate />
            <Status />
            <ActionsMenu />
        </Grid>
    );
};

const ActionsMenu = () => {
    const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(
        null
    );

    const menuOpen = Boolean(menuAnchor);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    return (
        <Grid item md={1} xs={6} order={{ xs: 2, md: 4 }}>
            <Box
                justifyContent='flex-end'
                sx={{ display: 'flex', flexBasis: 1 }}
            >
                <IconButton onClick={handleMenuClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    open={menuOpen}
                    anchorEl={menuAnchor}
                    onClose={handleMenuClose}
                >
                    <MenuItem>Edit Estimate</MenuItem>
                    <MenuItem>Rename</MenuItem>
                    <MenuItem>Delete</MenuItem>
                </Menu>
            </Box>
        </Grid>
    );
};

export default EstimatesListItem;
