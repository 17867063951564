import { CALL_API } from '../middleware/api';

export const GET_PROJECT_ESTIMATES_REQUEST = 'GET_PROJECT_ESTIMATES_REQUEST';
export const GET_PROJECT_ESTIMATES_SUCCESS = 'GET_PROJECT_ESTIMATES_SUCCESS';
export const GET_PROJECT_ESTIMATES_FAILURE = 'GET_PROJECT_ESTIMATES_FAILURE';

export const getProjectEstimates = (projectId, page = 1) => (({
    [CALL_API]: {
        types: [GET_PROJECT_ESTIMATES_REQUEST, GET_PROJECT_ESTIMATES_SUCCESS, GET_PROJECT_ESTIMATES_FAILURE],
        endpoint: `projects/${projectId}/estimates?page=${page}`,
    },
}));
