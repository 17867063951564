import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker, InfoWindow,
} from 'react-google-maps';
import SearchBox from 'react-google-maps/lib/components/places/SearchBox';
import withParcelLines from './withParcelLines';

class Map extends Component {
    render() {
        return (
            <GoogleMap
                ref={this.props.onMapMounted}
                center={this.props.center}
                options={{
                    mapTypeId: 'hybrid',
                    mapTypeControl: false,
                    tilt: 0,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    gestureHandling: 'greedy',
                }}
                zoom={this.props.zoom}
                onClick={this.props.onClick}
                onZoomChanged={this.props.onZoomChanged}
                onCenterChanged={this.props.onCenterChanged}
            >
                <SearchBox
                    ref={this.props.onSearchBoxMounted}
                    onPlacesChanged={this.props.onPlacesChanged}
                    controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
                >
                    <input
                        id="sketch-order-search-input"
                        type="text"
                        placeholder="Search"
                        style={{
                            boxSizing: 'border-box',
                            border: '1px solid transparent',
                            width: '75%',
                            height: '29px',
                            marginTop: '10px',
                            marginLeft: '20px',
                            padding: '0 12px',
                            borderRadius: '2px',
                            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                            fontSize: '12px',
                            outline: 'none',
                            textOverflow: 'ellipses',
                        }}
                    />
                </SearchBox>
                {this.props.markers.map((marker, index) => (
                    <Marker
                        key={marker.id}
                        position={marker.coordinates}
                        draggable={marker.draggable}
                        label={marker.label || `${index + 1}`}
                        icon={marker.icon}
                        onDragEnd={e => this.props.onMarkerLocationChanged(e, marker)}
                        onDblClick={e => this.props.onMarkerDoubleClick(e, marker)}
                        onClick={e => this.props.onMarkerClick(e, marker)}
                    >
                        {marker.infoWindowContent &&
                            <InfoWindow onCloseClick={e => this.props.onMarkerInfoWindowClosed(e, marker)}>
                                {marker.infoWindowContent}
                            </InfoWindow>
                        }
                    </Marker>
                ))
                }
            </GoogleMap>
        );
    }
}

Map.propTypes = {
    onMapMounted: PropTypes.func.isRequired,
    onSearchBoxMounted: PropTypes.func.isRequired,
    zoom: PropTypes.number.isRequired,
    center: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    onPlacesChanged: PropTypes.func.isRequired,
    onMarkerLocationChanged: PropTypes.func.isRequired,
    onMarkerDoubleClick: PropTypes.func.isRequired,
    onMarkerClick: PropTypes.func,
    onMarkerInfoWindowClosed: PropTypes.func,
    onZoomChanged: PropTypes.func.isRequired,
    onCenterChanged: PropTypes.func.isRequired,
    markers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        coordinates: PropTypes.shape({
            lat: PropTypes.number.isRequired,
            lng: PropTypes.number.isRequired,
        }).isRequired,
        icon: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
        draggable: PropTypes.bool.isRequired,
        infoWindowContent: PropTypes.element,
    })),
};

Map.defaultProps = {
    markers: [],
    onMarkerClick: () => { },
    onMarkerInfoWindowClosed: () => { },
};

export default withScriptjs(withGoogleMap(withParcelLines(Map)));
