import React from 'react';
import { connect } from 'react-redux';
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid, { GridSize } from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import UserLocaleIsUS from '../../../../lib/UserLocaleIsUS';
import { PriceGrid, TierPrice } from 'lib/Models/PriceGrid';
import { PriceGridType } from 'lib/MeasurementOrders';
import { mapPricingStateToProps } from 'lib/util/PricingGridState';
import { Stack } from '@mui/material';

const styles = () =>
    createStyles({
        parentStack: {
            margin: '8px',
            width: '100%',
        },
        gridHeading: {
            fontSize: '20px',
            textAlign: 'left',
        },
        gridSubHeading: {
            fontSize: '15px',
            color: 'rgba(0,0,0,0.54)',
            textAlign: 'left',
        },
        gridNumber: {
            fontSize: '20px',
            textAlign: 'center',
            paddingTop: '12px',
        },
        gridNumberCaption: {
            fontSize: '12px',
            textAlign: 'center',
            color: 'rgba(0,0,0,0.54)',
        },
        expansionDivider: {
            width: '100%',
            marginBottom: '10px',
            marginTop: '10px',
        },
        salesEmailLink: {
            textDecoration: 'none',
        },
        gridDescription: {
            paddingLeft: '20px',
        },
    });

type PricingProps = {
    priceGrids: { [key in PriceGridType]: PriceGrid[] };
    show: PriceGridType;
} & WithStyles<typeof styles>;

const Pricing = (props: PricingProps) => {
    const { classes, priceGrids, show } = props;
    const appendUSD = !UserLocaleIsUS();

    const priceGrid = priceGrids[show];

    const gridLength = priceGrid.length - 1;

    const PriceRow = (props: {
        grid: PriceGrid;
        variant: 'Single' | 'Multi';
    }) => {
        const { grid, variant } = props;

        const descriptionLg = variant === 'Single' ? 7 : 3;
        const priceLg = variant === 'Single' ? 3 : 2;
        const getLabel = (tierPrice: TierPrice) => {
            if (tierPrice.label.includes('facets'))
                return (
                    <Stack>
                        <Typography
                            className={classes.gridNumber}
                            align='center'
                        >
                            ${tierPrice.price}
                            {appendUSD ? ' USD' : ''}
                        </Typography>
                        <Typography
                            className={classes.gridNumberCaption}
                            align='center'
                        >
                            {tierPrice.label.substring(
                                0,
                                tierPrice.label.length - 6
                            )}
                        </Typography>
                        <Typography
                            className={classes.gridNumberCaption}
                            align='center'
                        >
                            {'facets'}
                        </Typography>
                    </Stack>
                );
            else {
                return (
                    <>
                        <Typography
                            className={classes.gridNumber}
                            align='center'
                        >
                            ${tierPrice.price}
                            {appendUSD ? ' USD' : ''}
                        </Typography>
                        <Typography
                            className={classes.gridNumberCaption}
                            align='center'
                        >
                            {tierPrice.label}
                        </Typography>
                    </>
                );
            }
        };
        return (
            <Grid item>
                <Grid container direction='row' justifyContent='space-between'>
                    <Grid
                        item
                        className={classes.gridDescription}
                        lg={descriptionLg}
                    >
                        <Typography
                            className={classes.gridHeading}
                            align='left'
                        >
                            {grid.label}
                        </Typography>
                        <Typography
                            className={classes.gridSubHeading}
                            align='left'
                        >
                            {grid.subLabel}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        lg={(12 - descriptionLg) as GridSize}
                        justifyContent='flex-end'
                    >
                        {grid.tierPrices.map((value) => (
                            <Grid item xs={6} lg={priceLg}>
                                {getLabel(value)}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <Stack className={classes.parentStack}>
            {priceGrid.map((grid, index) => (
                <>
                    <PriceRow
                        grid={grid}
                        variant={
                            grid.tierPrices.length > 1 ? 'Multi' : 'Single'
                        }
                    />
                    {index < gridLength && (
                        <Divider className={classes.expansionDivider} />
                    )}
                </>
            ))}
        </Stack>
    );
};

export default connect(mapPricingStateToProps)(withStyles(styles)(Pricing));
