import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const SaveTemplateDialog = props => (
    <Dialog open={props.open}>
        <DialogTitle>Save estimate as template</DialogTitle>
        <DialogContent>
            <TextField
                id="name"
                value={props.name ? props.name : ''}
                onChange={props.onChange('name')}
                label="Name"
                type="text"
                fullWidth
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onCancelClick} color="primary">
                Cancel
            </Button>
            <Button color="primary" onClick={props.onSaveClick}>
                Save
            </Button>
        </DialogActions>
    </Dialog>
);

SaveTemplateDialog.propTypes = {
    open: PropTypes.bool,
    onSaveClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
};

SaveTemplateDialog.defaultProps = {
    open: false,
    name: '',
};

export default SaveTemplateDialog;
