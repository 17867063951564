import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ResetPasswordForm from '../ResetPasswordForm';
import ResetPasswordComplete from './ResetPasswordComplete';
import { resetPassword } from '../../actions/ResetPassword';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';

const style = {
    wrapper: {
        justifyContent: 'center',
        display: 'flex',
        marginTop: 120,
    },
};

class ResetPassword extends Component {
    state = {
        passwordHasBeenReset: false,
    }

    resetPassword = async (newPassword) => {
        try {
            await this.props.dispatch(resetPassword(this.props.match.params.token, newPassword));
            this.setState({ passwordHasBeenReset: true });
        } catch (error) {
            this.props.dispatch(setReduxMessage('Oops... Something went wrong. Please try again'));
            this.setState({ passwordHasBeenReset: false });
        }
    };

    goToLogin = () => {
        this.props.history.push('/login');
    };

    render() {
        return (
            <div className={this.props.classes.wrapper}>
                {
                    this.state.passwordHasBeenReset ? (
                        <ResetPasswordComplete onConfirmClick={this.goToLogin} />
                    ) : (
                        <ResetPasswordForm
                            onSubmitClick={this.resetPassword}
                            title="Change Password"
                            buttonText="Change Password"
                            label="New Password"
                            confirmLabel="Confirm New Password"
                        />
                    )
                }
            </div>
        );
    }
}

ResetPassword.propTypes = {
    classes: PropTypes.shape({
        wrapper: PropTypes.string.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default connect(null)(withStyles(style)(ResetPassword));
