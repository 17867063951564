const projectStatus = {
    NewLead: 'New Lead',
    InspectionScheduled: 'Inspection Scheduled',
    Inspected: 'Inspected',
    AdjustmentScheduled: 'Adjustment Scheduled',
    Approved: 'Approved',
    ReadyToBuild: 'Ready To Build',
    PunchOut: 'Punch Out',
    WorkCompleted: 'Work Completed',
    Dead: 'Dead',
    Closed: 'Closed',
    Deleted: 'Deleted',
};

export default projectStatus;
