import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { Typography, Link, Button, Grid, Card, CardContent } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { generatePaymentClientToken, updatePaymentMethod } from '../../actions/Payment';
import { getOrganizationUsers } from '../../actions/Users';
import { resetPageToolbar } from '../../actions/RoofSnapPageToolbarActions';
import BraintreeDropinUI from '../BraintreeDropinUI';
import Alert from '../Alert';
import { paymentErrorMessages } from '../../lib/updatePaymentErrorType';
import PaymentErrorContent from './PaymentErrorContent';
import LoggerWrapper from '../../lib/Logger';
import UserLocaleIsUS from '../../lib/UserLocaleIsUS';
import roofsnapTheme from '../../lib/theme';
import { appInitialize } from '../../actions/AppInit';

const styles = () => ({
    root: {
        padding: 24,
        '@media only screen and (max-width:350px)': {
            width: '100%',
            padding: '0px',
            lastChild: {
                width: '100%',
                padding: '3px',
            },
        },
    },
    braintreeCard: {
        '@media only screen and (max-width: 350px)': {
            padding: '3px',
        },
    },
    card: {
        minWidth: 275,
        maxWidth: '700px',
        margin: '0 auto',
        padding: '50px 20px 20px',
        '@media only screen and (max-width:500px)': {
            width: '100%',
            padding: '10px 5px',
        },
        '@media only screen and (max-width:350px)': {
            width: '100%',
            padding: '10px 0px',
        },
    },
    'data-braintree-id="card-sheet-header"': {
        '@media only screen and (max-width:350px)': {
            width: '100%',
            padding: '2px',
        },
    },
    mobilePadding: {
        '@media only screen and (max-width:350px)': {
            padding: '5px 10px',
        },
    },
    button: {
        width: '100%',
        '@media only screen and (max-width:350px)': {
            width: '90%',
            padding: '5px 10px',
        },
    },
    buttonContainer: {
        textAlign: 'center',
    },
    tosBlock: {
        padding: '10px 20px 20px',
    },
    pricingGrid: {
        backgroundColor: '#f5f5f5',
        padding: '15px',
        marginBottom: '15px',
        borderRadius: '4px',
        border: 'solid 1px',
        borderColor: roofsnapTheme.palette.backgroundGrey.main,
    },

    footerText: {
        maxWidth: '700px',
        margin: '0 auto',
    },

    pricingTotal: {
        paddingTop: '15px',
        fontWeight: 'bold',
        borderTop: 'solid 1px #bcbcbc',
    },
    headerTextGridContainerItem: {
        marginBottom: 20,
    },
});

const defaultErrorMessage = 'Something went wrong. Please verify that your payment information was entered correctly. If the problem persists, please contact support at support@roofsnap.com or (877) 766-3762.';

class PaymentDetails extends Component {
    state = {
        errorAlertOpen: false,
        errorMessage: '',
        errorAlertContent: null,
    };

    async componentDidMount() {
        await this.props.dispatch(generatePaymentClientToken(this.props.userId));
        this.props.dispatch(getOrganizationUsers(this.props.organizationId, 1, true));
    }

    componentWillUnmount() {
        this.props.dispatch(resetPageToolbar());
    }

    braintreeDropInRef = null;

    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    braintreeDropIn = (ref) => {
        this.braintreeDropInRef = ref;
    };

    activate = async () => {
        this.setState({
            errorAlertOpen: false,
            errorMessage: '',
            errorAlertContent: null,
        });

        try {
            const { nonce } = await this.braintreeDropInRef.requestPaymentMethod();
            const result = await this.props.dispatch(updatePaymentMethod(this.props.userId, nonce));
            if (result.error) {
                this.setState({
                    errorAlertOpen: true,
                    errorMessage: defaultErrorMessage,
                });
                return;
            }

            const { response } = result;
            if (response.error) {
                const errorMessage = paymentErrorMessages[response.error.type] || defaultErrorMessage;
                this.setState({
                    errorAlertOpen: true,
                    errorMessage,
                    errorAlertContent: <PaymentErrorContent verificationErrorCodes={response.error.verificationErrorCodes} />,
                });
                return;
            }

            await this.props.dispatch(appInitialize());

            try {
                window.Appcues.track('Activated Subscription');
            } catch (error) {
                LoggerWrapper.captureException(error);
            }

            this.props.history.push({
                pathname: '/activate/complete',
            });
        } catch (error) {
            LoggerWrapper.captureException(error);
            this.setState({
                errorAlertOpen: true,
                errorAlertContent: null,
                errorMessage: defaultErrorMessage,
            });
        }
    };

    render() {
        const { classes } = this.props;
        const appendUSD = !UserLocaleIsUS();

        return (
            <Grid container className={classes.root} justify="center" direction="column">
                <Grid item>
                    <Card className={classes.card}>
                        <CardContent className={classes.braintreeCard}>
                            <Typography
                                variant="h5"
                                className={classes.mobilePadding}
                            >
                                Subscribe
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.mobilePadding}
                            >
                                Activate subscription for order discounts and to unlock RoofSnap's full suite of tools - <strong>$99{appendUSD ? ' USD' : ''}/month</strong> per activated user.&nbsp;
                                <Link className={classes.link} component={RouterLink} to="/pricing" href="/pricing">Compare plans</Link> and <Link className={classes.link} component={RouterLink} to="/pricing" href="/pricing">pricing</Link>.
                            </Typography>
                            <br />

                            {this.props.activeUserCount > 1 &&
                                <Grid container direction="column" className={classes.pricingGrid}>
                                    <Grid item>
                                        <Grid container direction="row">
                                            <Grid item xs={8}>
                                                <Typography align="left">RoofSnap Subscription</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography align="right">$99{appendUSD ? ' USD' : ''}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row">
                                            <Grid item xs={8}>
                                                <Typography align="left">{this.props.activeUserCount - 1} Additonal User Seat{this.props.activeUserCount === 2 ? '' : 's'}</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography align="right">${(this.props.activeUserCount - 1) * 99}{appendUSD ? ' USD' : ''}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" className={classes.pricingTotal}>
                                            <Grid item xs={8}>
                                                <Typography align="left"><strong>Total</strong></Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography align="right"><strong>${this.props.activeUserCount * 99}{appendUSD ? ' USD' : ''}</strong></Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {
                                this.props.paymentClientToken &&
                                <BraintreeDropinUI
                                    options={{
                                        authorization: this.props.paymentClientToken,
                                    }}
                                    onInstance={this.braintreeDropIn}
                                />
                            }
                            <p className={classes.mobilePadding}>
                                <Typography variant="caption">
                                    Contact us at <a href="mailto:hello@roofsnap.com">hello@roofsnap.com</a> or <a href="tel:1-877-766-3762">(877) 766-3762</a> to upgrade to one of our discounted annual plans.
                                </Typography>
                            </p>
                            <Alert
                                variant="error"
                                message={this.state.errorMessage}
                                open={this.state.errorAlertOpen}
                                hasIcon={false}
                                content={this.state.errorAlertContent}
                                className={this.props.classes.alert}
                                style={{
                                    root: {
                                        marginTop: this.props.theme.spacing.unit * 2,
                                    },
                                }}
                            />
                            <Grid className={classes.buttonContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={this.activate}
                                >Activate Subscription
                                </Button>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item className={classes.footerText}>
                    <Typography
                        variant="caption"
                    >
                        <p className={classes.mobilePadding}>
                            Clicking activate subscription will immediately start your RoofSnap monthly subscription and you will be billed $99{appendUSD ? ' USD' : ''}/month per activated user. Your account has {this.props.activeUserCount} user{this.props.activeUserCount === 1 ? '' : 's'}, so you will bill for <strong>${this.props.activeUserCount * 99}{appendUSD ? ' USD' : ''} upon activation.</strong>
                        </p>
                        <p className={classes.mobilePadding}>You will receive an email confirmation upon subscription activation.</p>
                        <p className={classes.mobilePadding}>
                            Notice: You may choose to purchase additional roof measurement orders or premium imagery. These purchases are optional and will be billed to the credit card entered above.
                        </p>
                        <p className={classes.mobilePadding}>
                            If you need to cancel your account, simply log into your account at&nbsp;
                            <a href={process.env.REACT_APP_ROOFSNAP_ONLINE_HOST} target="_blank" rel="noopener noreferrer">
                                roofsnap.com
                            </a> and follow the instructions on the Account tab.
                        </p>
                    </Typography>
                </Grid>
            </Grid>
        );
    }
}

PaymentDetails.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
        card: PropTypes.string.isRequired,
        button: PropTypes.string.isRequired,
        alert: PropTypes.string.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    paymentClientToken: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

const mapStateToProps = (state) => {
    const {
        payment, organizationUsers, currentUser, features,
    } = state;
    const { paymentClientToken } = payment;
    const { userId, organizationId } = currentUser;
    const { data: users } = organizationUsers;
    return {
        paymentClientToken,
        userId,
        features,
        organizationId,
        activeUserCount: users.filter(user => user.active === true).length,
    };
};

export default connect(mapStateToProps)(withTheme(withStyles(styles)(PaymentDetails)));
