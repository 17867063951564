import { Toolbar, Typography, Button } from '@mui/material';
import { RoofSnapState } from 'lib/Models';
import React from 'react';
import { useSelector } from 'react-redux';

const env = `${process.env.REACT_APP_ENV}`;

export const AdminToolbar = () => {
    const isDev = env === 'development';
    const state = useSelector((state: RoofSnapState) => state);

    return state.currentUserRoles.isAdmin ? (
        <Toolbar sx={{backgroundColor: isDev ? 'green' : '#C41C1C'}}>
            <Typography variant='h6' color='inherit'>
                Admin Mode
            </Typography>
            <Button
                onClick={() => {
                    console.log(state);
                }}
                sx={{ ml: 'auto' }}
                color={ isDev ? 'success' : 'error' }
                variant='contained'
            >
                Log State to Console
            </Button>
        </Toolbar>
    ) : (
        <></>
    );
};

export default AdminToolbar;
