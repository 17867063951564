import React, { Component } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import DefaultMaterialIcon from '@material-ui/icons/Dashboard';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import { findIndex, groupBy, keys, find, isEqual } from 'lodash';
import Loader from '../Loader/Loader';
import Pager from '../Pager/Pager.tsx';
import MaterialItemModelFactory from '../../lib/Factories/MaterialItemModelFactory';
import MaterialItemColorOptionsDialog from '../MaterialItemColorOptionsDialog';

const enterCharacterCode = 13;

class MaterialItemsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: props.searchValue,
            materialItems: this.props.materialItems.map(materialItem =>
                MaterialItemModelFactory.create(
                    materialItem,
                    props.estimateItems,
                )),
            colorOptionsDialogOpen: false,
            currentlySelectedMaterialItem: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        if (isEqual(nextProps.estimateItems, this.props.estimateItems)) return;

        const materialItems = this.props.materialItems.map(materialItem =>
            MaterialItemModelFactory.create(
                materialItem,
                nextProps.estimateItems,
            ));
        this.setState({ materialItems });
    }

    handleSearchInputKeyPress = (event) => {
        if (event.charCode === enterCharacterCode) {
            this.props.onSearch(this.state.searchValue);
        }
    };

    handleSearchInputChange = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    searchMaterialItems = () => {
        this.props.onSearch(this.state.searchValue);
    };

    handleMaterialItemClick = (materialItem) => {
        const index = findIndex(this.state.materialItems, materialItem);
        const { materialItems } = this.state;
        this.setState({ currentlySelectedMaterialItem: materialItem });

        if (materialItem.isOnEstimate) {
            materialItems[index].isOnEstimate = false;
            this.setState({ materialItems });
            this.deleteEstimateItem(materialItem);
        } else {
            materialItems[index].isOnEstimate = true;
            this.setState({ materialItems });

            if (materialItem.colorOptionsCount > 1) {
                // Open the color options dialog. Selecting a color will then create the estimate item
                this.setState({ colorOptionsDialogOpen: true });
            }

            this.props.createEstimateItem(materialItem);
        }
    };

    deleteEstimateItem = (materialItem) => {
        const estimateItem = find(this.props.estimateItems, [
            'materialItemId',
            materialItem.id,
        ]);
        if (!estimateItem) return;

        this.props.deleteEstimateItem(estimateItem.id);
    };

    handleColorOptionsClick = (colorOption) => {
        this.props.onColorOptionClick(
            this.state.currentlySelectedMaterialItem.id,
            colorOption,
        );
        this.setState({ colorOptionsDialogOpen: false });
    };

    dismissColorOptionsDialog = () => {
        const { currentlySelectedMaterialItem } = this.state;
        currentlySelectedMaterialItem.isOnEstimate = false;
        this.setState({ colorOptionsDialogOpen: false });
    };

    clearSearchText = () => {
        this.setState({ searchValue: '' }, () =>
            this.props.onSearch(this.state.searchValue));
    };

    groupMaterialItemsByCategry = () => {
        const materialItemsGroupedByCategory = groupBy(
            this.state.materialItems,
            materialItem => materialItem.categoryName,
        );
        return materialItemsGroupedByCategory;
    };

    renderMaterialItems = materialItem => (
        <div key={materialItem.id}>
            <ListItem
                button
                onClick={() => this.handleMaterialItemClick(materialItem)}
            >
                <ListItemAvatar style={{ width: 120, height: 100, borderRadius: 0 }}>
                    <Avatar
                        src={materialItem.image}
                        style={{ width: 100, height: 100, borderRadius: 0 }}
                        alt={materialItem.description}
                    >
                        <DefaultMaterialIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={materialItem.description} />
                <ListItemSecondaryAction>
                    <Checkbox
                        checked={materialItem.isOnEstimate}
                        onClick={() =>
                            this.handleMaterialItemClick(materialItem)
                        }
                    />
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
        </div>
    );

    renderMaterialItemsGroupedByCategory = (category) => {
        const materialItems = this.materialItemsGroupedByCategory[category];
        return (
            <div key={category}>
                <ListItem>
                    <ListItemText
                        style={{ paddingTop: 20 }}
                        primary={
                            <Typography variant="h6">{category}</Typography>
                        }
                        disableTypography
                    />
                </ListItem>
                {materialItems.map(this.renderMaterialItems)}
            </div>
        );
    };

    render() {
        this.materialItemsGroupedByCategory = this.groupMaterialItemsByCategry();
        const categories = keys(this.materialItemsGroupedByCategory);
        return (
            <div>
                <List>
                    <ListItem>
                        <Input
                            placeholder="Search"
                            value={this.state.searchValue}
                            onChange={this.handleSearchInputChange}
                            onKeyPress={this.handleSearchInputKeyPress}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={this.clearSearchText}>
                                        <CloseIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <IconButton onClick={this.searchMaterialItems}>
                            <SearchIcon />
                        </IconButton>
                        <ListItemSecondaryAction>
                            <Pager
                                totalPages={this.props.totalPages}
                                currentPage={this.props.currentPage}
                                nextClick={this.props.onNextClick}
                                prevClick={this.props.onPrevClick}
                            />
                        </ListItemSecondaryAction>
                        <Divider />
                    </ListItem>
                    {categories.map(this.renderMaterialItemsGroupedByCategory)}
                    <div style={{ float: 'right' }}>
                        <Pager
                            totalPages={this.props.totalPages}
                            currentPage={this.props.currentPage}
                            nextClick={this.props.onNextClick}
                            prevClick={this.props.onPrevClick}
                        />
                    </div>
                </List>
                <MaterialItemColorOptionsDialog
                    open={this.state.colorOptionsDialogOpen}
                    materialItem={this.state.currentlySelectedMaterialItem}
                    colorOptions={this.props.colorOptions}
                    onColorOptionClick={this.handleColorOptionsClick}
                    onDismiss={this.dismissColorOptionsDialog}
                    totalResults={this.props.totalColorOptions}
                    totalPages={this.props.totalColorOptionsPages}
                    currentPage={this.props.colorOptionsCurrentPage}
                    onNextPageClick={this.props.onNextPageColorOptionsClick}
                    onPrevPageClick={this.props.onPrevPageColorOptionsClick}
                />
            </div>
        );
    }
}

const materialItemProps = {
    id: PropTypes.number.isRequired,
    officeId: PropTypes.string.isRequired,
    rootCategoryName: PropTypes.string.isRequired,
    categoryName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string,
    colorOptionsCount: PropTypes.number.isRequired,
};

const colorOptionProps = {
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.string,
    materialItemId: PropTypes.number.isRequired,
    isDeleted: PropTypes.bool,
    isCustom: PropTypes.bool,
    isHidden: PropTypes.bool,
};

MaterialItemsList.propTypes = {
    materialItems: PropTypes.arrayOf(PropTypes.shape(materialItemProps))
        .isRequired,
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    /* eslint-disable react/no-unused-prop-types */
    materielItemsFetching: PropTypes.bool.isRequired,
    onSearch: PropTypes.func.isRequired,
    searchValue: PropTypes.string.isRequired,
    onNextClick: PropTypes.func.isRequired,
    onPrevClick: PropTypes.func.isRequired,
    estimateItems: PropTypes.arrayOf(PropTypes.shape({
        materialItemId: PropTypes.number,
    })),
    createEstimateItem: PropTypes.func.isRequired,
    deleteEstimateItem: PropTypes.func.isRequired,
    onColorOptionClick: PropTypes.func.isRequired,
    colorOptions: PropTypes.arrayOf(PropTypes.shape(colorOptionProps)).isRequired,
    totalColorOptions: PropTypes.number.isRequired,
    totalColorOptionsPages: PropTypes.number.isRequired,
    colorOptionsCurrentPage: PropTypes.number.isRequired,
    onNextPageColorOptionsClick: PropTypes.func.isRequired,
    onPrevPageColorOptionsClick: PropTypes.func.isRequired,
};

MaterialItemsList.defaultProps = {
    estimateItems: [],
};

export default Loader('materielItemsFetching')(MaterialItemsList);
