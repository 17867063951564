/* eslint-disable no-undef,wrap-iife,no-param-reassign,prefer-rest-params,semi,space-infix-ops,no-sequences,prefer-destructuring,comma-spacing,dot-notation,space-before-function-paren,space-before-blocks,no-unused-expressions,block-spacing,brace-style,semi-spacing,no-multi-spaces */
import { GET_CURRENT_USER_ROLES_REQUEST } from '../actions/UserRoles';

export default store => next => (action) => {
    // Once this action fires, we know we have the current user info in Redux
    if (action.type === GET_CURRENT_USER_ROLES_REQUEST) {
        // We only want to track in production
        if (process.env.REACT_APP_ENV.endsWith('production')) {
            const { currentUser } = store.getState();
            if (currentUser) {
                const profitwell = window.profitwell;
                // Ensure the profitwell tracking code has been loaded in index.html before executing
                if (profitwell) profitwell('user_email', currentUser.userName);   // enter the Customer ID of the logged-in user
            }
        }
    }
    return next(action);
};
