import { SET_SKETCHOS_OFFICEID } from '../actions/SketchOSOfficeIdAction';

const defaultState = {
    sketchOSOfficeId: null,
};

function SketchOSOfficeIdReducer(state = defaultState, action) {
    switch (action.type) {
    case SET_SKETCHOS_OFFICEID:
        return Object.assign({}, state, {
            sketchOSOfficeId: action.officeId,
        });
    default:
        return state;
    }
}

export default SketchOSOfficeIdReducer;
