import { CALL_API } from '../middleware/api';
import httpRequestMethods from '../middleware/httpRequestMethods';

export const GET_SKETCH_ORDER_NOTIFICATIONS_REQUEST = 'GET_SKETCH_ORDER_NOTIFICATIONS_REQUEST';
export const GET_SKETCH_ORDER_NOTIFICATIONS_SUCCESS = 'GET_SKETCH_ORDER_NOTIFICATIONS_SUCCESS';
export const GET_SKETCH_ORDER_NOTIFICATIONS_FAILURE = 'GET_SKETCH_ORDER_NOTIFICATIONS_FAILURE';

export const CREATE_SKETCH_ORDER_NOTIFICATION_ACKNOWLEDGEMENT_REQUEST = 'CREATE_SKETCH_ORDER_NOTIFICATION_ACKNOWLEDGEMENT_REQUEST';
export const CREATE_SKETCH_ORDER_NOTIFICATION_ACKNOWLEDGEMENT_SUCCESS = 'CREATE_SKETCH_ORDER_NOTIFICATION_ACKNOWLEDGEMENT_SUCCESS';
export const CREATE_SKETCH_ORDER_NOTIFICATION_ACKNOWLEDGEMENT_FAILURE = 'CREATE_SKETCH_ORDER_NOTIFICATION_ACKNOWLEDGEMENT_REQUEST';


export const getSketchOrderNotifications = sketchOrderId => ({
    [CALL_API]: {
        types: [
            GET_SKETCH_ORDER_NOTIFICATIONS_REQUEST,
            GET_SKETCH_ORDER_NOTIFICATIONS_SUCCESS,
            GET_SKETCH_ORDER_NOTIFICATIONS_FAILURE,
        ],
        endpoint: `sketchadminsketchorders/${sketchOrderId}/notifications`,
        apiVersion: 'v2',
    },
});

export const createSketchOrderNotificationAcknowledgement = notificationId => (
    async (dispatch, getState) => {
        const state = getState();
        const { currentUser, sketchOrder } = state;
        const { id: sketchOrderId } = sketchOrder;
        const { userId } = currentUser;
        const createAcknowledgementAction = await dispatch({
            [CALL_API]: {
                types: [
                    CREATE_SKETCH_ORDER_NOTIFICATION_ACKNOWLEDGEMENT_REQUEST,
                    CREATE_SKETCH_ORDER_NOTIFICATION_ACKNOWLEDGEMENT_SUCCESS,
                    CREATE_SKETCH_ORDER_NOTIFICATION_ACKNOWLEDGEMENT_FAILURE,
                ],
                endpoint: `sketchadminsketchorders/${sketchOrderId}/notifications/${notificationId}/acknowledgements`,
                apiVersion: 'v2',
                method: httpRequestMethods.POST,
                body: {
                    acknowledgedByUserId: userId,
                },
            },
        });
        dispatch(getSketchOrderNotifications(sketchOrderId));
        return createAcknowledgementAction;
    }
);
