import React, { useState, ChangeEvent, useEffect } from 'react';
import { withStyles, WithStyles, createStyles,  } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
    Button,
    Tab,
    Tabs,
    Theme,
} from '@material-ui/core';
import User from 'lib/Models/User';
import { Search } from '@material-ui/icons';
import UserButton from '../UserSettings/UserButton';
import AddUserButton from '../UserSettings/AddUserButton';
import UserDataChip from '../UserSettings/UserDataChip';

interface SideMenuProps extends WithStyles<typeof styles> {
    setSelectedUser: (id: number) => void,
    setActiveInactive: (isActive: boolean) => void,
    users: User[]
    currentUser: User,
    menuSectionTitle?: string,
    isAccountAdmin: boolean
}

const styles = (theme: Theme) => createStyles({

    menu: {
        height: '100%',
        minWidth: 270,
        maxWidth: 320,
        backgroundColor: '#FFF',
        [theme.breakpoints.down('sm')]: {
            zIndex: 10,
            position: 'fixed',
            width: '80vw'
        }
    },
    menuGrid: {
        padding: '16px',
        borderBottom: '1px solid #E6E7E9'
    },
    menuSectionTitle: {
        color: '#999999',
        fontSize: '12px',
        fontFamily: 'Roboto',
        letterSpacing: '1.3px',
    },
    selectedButton: {
        color: '#1E79D6',
    },
    textButton: {
        font: 'normal normal normal 15px/60px Roboto',
        letterSpacing: '0.56px',
        color: '#222222',
        padding: '0px 28px',
        margin: '0px 19px 0px 19px',
        width: '100%',
        justifyContent: 'flex-start',
        borderRadius: '8px',
        textTransform: 'none',
    },
    button: {
        padding: '10px 0px'
    },
    activeTab: {
        borderBottom: '3px solid #056CF2',
        backgroundColor: '#EEF5FF',
        font: 'normal normal Roboto #056CF2 13px',
        height: 48
      },
    tab:{
        display:'flex',
        width: '50%',
        textTransform: 'none',
    }
});

const SideMenu = (props: SideMenuProps) => {
    const {
        classes,
        users,
        currentUser,
        isAccountAdmin,
        setSelectedUser,
        setActiveInactive
    } = props;

    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [activeTab, setActiveTab] = useState<number>(0);

    useEffect(() => {
        setSelectedIndex(0)
    }, [activeTab])

    const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
        setSelectedUser(users[0].userId)
        setActiveTab(newValue);
        setActiveInactive(newValue === 0)
      };

    const handleButtonClick = (index: number) => {
        setSelectedUser(users[index].userId)
        setSelectedIndex(index)
    }

    const filteredUsers = () => {
        if(activeTab === 0){
            return(
                users.filter((user: User) => user.active === true)
            )
        } else {
            return(
                users.filter((user: User) => user.active === false)
            )
        }
    }

    return (
        <Grid direction="column" container className={classes.menu}>
            {isAccountAdmin &&
                <Grid container item>
                    <Grid container item xs={12} justifyContent='center'>
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            style={{ display:'flex', width: '100%'}}
                        >
                            <Tab
                                label="Active"
                                className={`${activeTab === 0 ? classes.activeTab: ''} ${classes.tab}`}
                            />
                            <Tab
                                label="Inactive"
                                className={`${activeTab === 1 ? classes.activeTab: ''} ${classes.tab}`}
                            />
                        </Tabs>
                    </Grid>
                    <Grid item container className={classes.menuGrid} justifyContent='space-between'>
                        <Grid item xs={12} sm={3}>
                            <Button onClick={() => console.log('search')}>
                                <Search />
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <AddUserButton
                                handleClick={() => console.log('add user')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Grid item container className={classes.menuGrid}>
                <Typography className={classes.menuSectionTitle}>
                    USER PROFILE
                </Typography>
                <UserDataChip
                    firstName={currentUser.firstName}
                    lastName={currentUser.lastName}
                    isActive={false}
                    userRole={users.find((user) =>  currentUser.userId === user.userId)?.position}
                />
            </Grid>
            {isAccountAdmin &&
                <Grid item container direction='column' className={classes.menuGrid}>
                    <Typography className={classes.menuSectionTitle}>
                        TEAM
                    </Typography>
                    {filteredUsers().map((user: User, index) => {
                        return (
                            <Grid item container justifyContent='center' className={classes.button} data-cy={"user-button" + user.userId}>
                                <UserButton
                                    user={user}
                                    index={index}
                                    handleClick={handleButtonClick}
                                    isActive={selectedIndex === index}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            }
        </Grid >
    );
};


export default (withStyles(styles)(SideMenu));
