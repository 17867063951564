import LogRocket from 'logrocket';
import queryString from 'query-string';
import { LOGIN_SUCCESS } from '../actions/Auth';

const sanitizedValue = '*********';

class LoggerWrapper {
    static initialize() {
        LogRocket.init(`${process.env.REACT_APP_LOG_ROCKET_APP_ID}`, {
            network: {
                requestSanitizer: (request) => {
                    // Do not log the Authorization header since it has the jwt.
                    if (request.headers.Authorization) {
                        request.headers.Authorization = sanitizedValue;
                    }

                    // Do not log passwords
                    if (request.url === `https://${process.env.REACT_APP_AUTH_HOST}/oauth2/token` && request.body) {
                        const parsedRequestBody = queryString.parse(request.body);
                        if (parsedRequestBody.password) {
                            parsedRequestBody.password = sanitizedValue;
                            const sanitizedRequestBody = queryString.stringify(parsedRequestBody);
                            request.body = sanitizedRequestBody;
                        }
                    }

                    if (request.url.endsWith('password') && request.body) {
                        const parsedRequestBody = JSON.parse(request.body);
                        parsedRequestBody.newPassword = sanitizedValue;
                        request.body = JSON.stringify(parsedRequestBody);
                    }

                    if (request.url.includes('welcome-register')) {
                        request.url = request.url.replace(/password[=|%3D][^&]+/, 'password=*****');
                        request.name = request.name.replace(/password[=|%3D][^&]+/, 'password=*****');
                    }

                    if (request.url.endsWith('signup') && request.body) {
                        const parsedRequestBody = JSON.parse(request.body);
                        parsedRequestBody.password = sanitizedValue;
                        request.body = JSON.stringify(parsedRequestBody);
                    }

                    // Do not log jwts
                    const parsedUrl = queryString.parseUrl(request.url.toLowerCase());
                    if (parsedUrl.query.token) {
                        parsedUrl.query.token = sanitizedValue;
                        request.url = `${parsedUrl.url}?${queryString.stringify(parsedUrl.query)}`;
                    }
                    return request;
                },

                responseSanitizer: (response) => {
                    if (response.body) {
                        const parsedResponseBody = JSON.parse(response.body);
                        if (parsedResponseBody.access_token) {
                            parsedResponseBody.access_token = sanitizedValue;
                            response.body = JSON.stringify(parsedResponseBody);
                        }

                        if (parsedResponseBody.Password) {
                            parsedResponseBody.Password = sanitizedValue;
                            response.body = JSON.stringify(parsedResponseBody);
                        }
                    }

                    return response;
                },
            },
        });
    }

    static startNewSessison() {
        LogRocket.startNewSession();
    }

    static identify(userName, firstName, lastName) {
        LogRocket.identify(userName, {
            email: userName,
            name: `${firstName} ${lastName}`,
        });
    }

    static logRocketMiddleware() {
        return LogRocket.reduxMiddleware({
            stateSanitizer(state) {
                return {
                    ...state,
                    sketchHost: undefined,
                    auth: {
                        ...state.auth,
                        accessToken: undefined,
                    },
                };
            },
            actionSanitizer(action) {
                if (action.type === LOGIN_SUCCESS) {
                    return {
                        ...action,
                        accessToken: undefined,
                    };
                }
                return action;
            },
        });
    }

    static captureException(error) {
        LogRocket.captureException(error);
    }

    static captureMessage(message, options = null) {
        LogRocket.captureMessage(message, options);
    }

    static log(...props) {
        LogRocket.log(...props);
    }
}

export default LoggerWrapper;
