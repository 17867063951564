import {
    FormControl,
    FormHelperText,
    TextField,
    Typography,
} from '@mui/material';
import React from 'react';
import Section, { SectionProps } from './Section';
import useId from '@mui/material/utils/useId';

const CustomerSection = ({
    project,
    validationResults,
    onChange,
}: SectionProps) => {
    const customerEmailId = useId();

    const customerEmailHasError = validationResults &&
    !validationResults.fields.customerEmail.isValid;

    return (
        <Section id='customer-section'>
            <Typography variant='subtitle2'>
                Homeowner / Job-site Info
            </Typography>
            <FormControl fullWidth>
                <TextField
                    label='Name'
                    name='customerName'
                    value={project.customerName || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 100,
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    label='Address'
                    name='customerAddress'
                    value={project.customerAddress || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 255,
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    label='City'
                    name='customerCity'
                    value={project.customerCity || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 255,
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    label='State'
                    name='customerState'
                    value={project.customerState || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 100,
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    label='Zipcode'
                    name='customerZip'
                    value={project.customerZip || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 50,
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    label='Phone 1'
                    name='customerPhone'
                    value={project.customerPhone || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 50,
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    label='Phone 2'
                    name='customerPhone2'
                    value={project.customerPhone2 || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 50,
                    }}
                />
            </FormControl>
            <FormControl
                fullWidth
                error={customerEmailHasError}
            >
                <TextField
                    id={customerEmailId}
                    error={customerEmailHasError}
                    label='Email'
                    name='customerEmail'
                    value={project.customerEmail || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 255,
                    }}
                />
                {customerEmailHasError && (
                        <FormHelperText id={customerEmailId}>
                            Email is invalid
                        </FormHelperText>
                    )}
            </FormControl>
        </Section>
    );
};

export default CustomerSection;
