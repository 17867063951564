import {
    SET_CURRENT_PROJECT, GET_PROJECT_REQUEST, GET_PROJECT_SUCCESS, GET_PROJECT_FAILURE,
    DELETE_PROJECT_REQUEST, DELETE_PROJECT_SUCCESS, DELETE_PROJECT_FAILURE,
} from '../actions/ProjectActions';

function projectHome(state = {
    currentProject: {},
    isFetching: false,
    errorMessage: null,
}, action) {
    switch (action.type) {
    case SET_CURRENT_PROJECT:
        return Object.assign({}, state, {
            currentProject: action.currentProject,
        });
    case GET_PROJECT_REQUEST:
        return Object.assign({}, state, {
            currentProject: {},
            isFetching: true,
        });
    case GET_PROJECT_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            currentProject: action.response,
        });
    case GET_PROJECT_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            currentProject: {},
            errorMessage: action.message,
        });
    case DELETE_PROJECT_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case DELETE_PROJECT_SUCCESS:
        return Object.assign({}, state, {
            currentProject: {},
            isFetching: false,
            message: 'The project was successfully deleted',
        });
    case DELETE_PROJECT_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            message: 'Project was not able to be deleted, please try again',
        });
    default:
        return state;
    }
}

export default projectHome;
