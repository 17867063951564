import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

interface LockedDialogProps {
    dialogContent: React.ReactNode,
    open: boolean,
    maxWidth: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined,
    openDialog: () => void,
    closeButtonText?: string,
    secondButtonText?: string,
    hasCloseButton?: boolean,
    hasSecondButton?: boolean,
    secondButtonClick?: () => void;
    dialogStyleOverrides?: React.CSSProperties,
    dialogContentStyleOverrides?: React.CSSProperties,
    dialogTitle?: string,
    dialogTitleStyleOverrides?: React.CSSProperties,
    closeDialog?: () => void,
    fullWidth?: boolean,
}

const LockedDialog = (props: LockedDialogProps) => {

    return (
            <Dialog
                open={props.open}
                keepMounted
                onClose={props.closeDialog}
                aria-describedby="alert-dialog-slide-description"
                maxWidth={props.maxWidth}
                fullWidth={props.fullWidth}
                style={props.dialogStyleOverrides}
            >
                {props.dialogTitle && <DialogTitle style={props.dialogTitleStyleOverrides}>{props.dialogTitle}</DialogTitle>}
                <DialogContent style={props.dialogContentStyleOverrides} >
                    <DialogContentText style={{margin: 0}} id="alert-dialog-slide-description">
                        {props.dialogContent}
                    </DialogContentText>
                </DialogContent>
                {(props.hasCloseButton || props.hasSecondButton) && <DialogActions>
                    {props.hasCloseButton &&
                        <Button onClick={props.closeDialog}>{props.closeButtonText}</Button>
                    }
                    {props.hasSecondButton &&
                        <Button onClick={props.secondButtonClick}>{props.secondButtonText}</Button>
                    }
                </DialogActions>}
            </Dialog>
    );
}

export default LockedDialog;