import { SIGN_UP_FAILURE, SIGN_UP_REQUEST, SIGN_UP_SUCCESS } from '../actions/signUpActions';

const initialState = {
    isSigningUp: false,
};

const signUp = (state = initialState, action) => {
    switch (action.type) {
    case SIGN_UP_REQUEST:
        return Object.assign({}, state, {
            isSigningUp: true,
        });
    case SIGN_UP_SUCCESS:
        return Object.assign({}, state, {
            isSigningUp: false,
            isNewUser: action.isNewUser,
        });
    case SIGN_UP_FAILURE:
        return Object.assign({}, state, {
            isSigningUp: false,
            error: action.error,
        });
    default:
        return state;
    }
};

export default signUp;
