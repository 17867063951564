import {
    FormControl,
    FormHelperText,
    TextField,
    Typography,
} from '@mui/material';
import React from 'react';
import Section, { SectionProps } from './Section';
import useId from '@mui/material/utils/useId';

const InsurerSection = ({
    project,
    validationResults,
    onChange,
}: SectionProps) => {
    const adjusterEmailId = useId();

    const adjusterEmailHasError =
        validationResults && !validationResults.fields.adjusterEmail.isValid;

    return (
        <Section id='insurer-section'>
            <Typography variant='subtitle2'>Insurer Info</Typography>
            <FormControl fullWidth>
                <TextField
                    label='Insurer Name'
                    name='insurerName'
                    value={project.insurerName || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 255,
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    label='Adjuster Name'
                    name='adjusterName'
                    value={project.adjusterName || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 255,
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    label='Adjuster Contact Number'
                    name='adjusterContactNumber'
                    value={project.adjusterContactNumber || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 50,
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    label='Claim Number'
                    name='claimNumber'
                    value={project.claimNumber || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 100,
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <TextField
                    label='Claims Department Number'
                    name='claimsDeptContactNumber'
                    value={project.claimsDeptContactNumber || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 100,
                    }}
                />
            </FormControl>
            <FormControl fullWidth error={adjusterEmailHasError}>
                <TextField
                    id={adjusterEmailId}
                    error={adjusterEmailHasError}
                    label='Email'
                    name='adjusterEmail'
                    value={project.adjusterEmail || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 255,
                    }}
                />
                {adjusterEmailHasError && (
                    <FormHelperText id={adjusterEmailId}>
                        Email is invalid
                    </FormHelperText>
                )}
            </FormControl>
        </Section>
    );
};

export default InsurerSection;
