import jwtDecode from 'jwt-decode';

const decode = (authToken) => {
    if (!authToken) {
        return {
            userId: null,
            organizationId: null,
        };
    }

    const payload = jwtDecode(authToken);
    return {
        userId: parseInt(payload.name, 10),
        organizationId: payload.OrganizationId,
        expiresIn: payload.exp,
    };
};

export default decode;
