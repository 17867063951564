class MaterialItemModel {
    id;
    officeId;
    rootCategoryName;
    categoryName;
    description;
    image;
    colorOptionsCount;
    isOnEstimate;
}

export default MaterialItemModel;
