import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

const style = {
    labelMargin: {
        marginTop: '10px',
    },
};

const defaultValue = 0;

const wrapped = class extends React.Component {
    render() {
        return (
            <NumberFormat
                {...this.props}
                thousandSeparator
                decimalScale={2}
                // fixedDecimalScale This breaks the highlighting and typing. opened issue https://github.com/s-yadav/react-number-format/issues/159
            />
        );
    }
};

const MoneyInput = (props) => {
    const value = (props.value !== null) ? props.value : defaultValue;
    return (
        <FormControl required={props.required} style={{ width: '100%', ...style.labelMargin }}>
            <InputLabel htmlFor={props.value}>{props.label}</InputLabel>
            <Input
                id={`id${props.value}`}
                value={value}
                onChange={props.parentType ? props.handleChange(props.parentType, props.type) : props.handleChange(props.type)}
                inputComponent={wrapped}
                label={props.label}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                disabled={props.disabled}
            />
        </FormControl>
    );
};

MoneyInput.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    parentType: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
};

MoneyInput.defaultProps = {
    value: 0,
    parentType: '',
    required: false,
    disabled: false,
};

export default MoneyInput;
