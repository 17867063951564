import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const style = theme => ({
    formControl: {
        margin: theme.spacing(1),
        paddingTop: 0,
        marginTop: 0,
    },
});

class AddressVerification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isValid: {
                address: !!props.address,
                city: !!props.city,
                region: !!props.region,
                postcode: !!props.postcode,
            },
        };
        this.props.onFormValidate(this.isFormValid());
    }
    componentDidUpdate(prevProps) {
        if (this.didFormPropsChange(prevProps)) {
            this.setFormState();
            this.props.onFormValidate(this.isFormValid());
        }
    }
    setFormState = () => {
        this.setState({
            isValid: {
                address: !!this.props.address,
                city: !!this.props.city,
                region: !!this.props.region,
                postcode: !!this.props.postcode,
            },
        });
    };
    didFormPropsChange = prevProps => (
        prevProps.address !== this.props.address
        || prevProps.city !== this.props.city
        || prevProps.region !== this.props.region
        || prevProps.postcode !== this.props.postcode
    );
    handleFieldChange = (event) => {
        this.setState({
            isValid: Object.assign(this.state.isValid, { [event.target.name]: !!event.target.value }),
        });
        this.props.onChange(event);
    };
    isFormValid = () => {
        const {
            address,
            city,
            region,
            postcode,
        } = this.state.isValid;
        return address
            && city
            && region
            && postcode;
    };
    render() {
        const { isValid } = this.state;
        return (
            <Grid container item xs={12} spacing={1}>
                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12}>
                        <FormControl
                            className={this.props.classes.formControl}
                            error={!isValid.address}
                            fullWidth
                        >
                            <TextField
                                id="address"
                                type="text"
                                value={this.props.address}
                                onChange={this.handleFieldChange}
                                name="address"
                                inputProps={{
                                    maxLength: 100,
                                }}
                                size="small"
                            />
                            {!isValid.address &&
                                <FormHelperText id="address">Address is required</FormHelperText>
                            }
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12}>
                        <FormControl
                            className={this.props.classes.formControl}
                            error={!isValid.city}
                            fullWidth
                        >
                            <TextField
                                id="city"
                                type="text"
                                value={this.props.city}
                                onChange={this.handleFieldChange}
                                name="city"
                                inputProps={{
                                    maxLength: 100,
                                }}
                                size="small"
                                className={this.props.classes.cityTextField}
                            />
                            {!isValid.city &&
                                <FormHelperText id="city">City is required</FormHelperText>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            className={this.props.classes.formControl}
                            error={!isValid.region}
                            fullWidth
                        >
                            <TextField
                                id="region"
                                type="text"
                                value={this.props.region}
                                onChange={this.handleFieldChange}
                                name="region"
                                inputProps={{
                                    maxLength: 100,
                                }}
                                size="small"
                            />
                            {!isValid.region &&
                                <FormHelperText id="region">State/region is required</FormHelperText>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl
                            className={this.props.classes.formControl}
                            error={!isValid.postcode}
                            fullWidth
                        >
                            <TextField
                                id="postcode"
                                type="text"
                                value={this.props.postcode}
                                onChange={this.handleFieldChange}
                                name="postcode"
                                inputProps={{
                                    maxLength: 50,
                                }}
                                size="small"
                            />
                            {!isValid.postcode &&
                                <FormHelperText id="postcode">Postal code is required</FormHelperText>
                            }
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

AddressVerification.propTypes = {
    address: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    postcode: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onFormValidate: PropTypes.func.isRequired,
    classes: PropTypes.shape({
        formControl: PropTypes.string.isRequired,
    }).isRequired,
};

AddressVerification.defaultProps = {
    address: '',
    city: '',
    region: '',
    postcode: '',
};

export default withStyles(style)(AddressVerification);
