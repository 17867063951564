import { camelizeKeys } from 'humps';
import urlFactory from 'url-factory';
import httpRequestMethods from './httpRequestMethods';
import httpStatusCodes from './httpStatusCodes';
import LocalStorageWrapper from '../lib/LocalStorageWrapper';
import responseBodyTypes from './responseBodyTypes';

export const BASE_URL = `${process.env.REACT_APP_API_HOST}`;
const apiFactory = urlFactory(BASE_URL);

export const CALL_API = Symbol('Call API');

function buildApiConfigRequest(method, body, header, authRequired = true) {
    const config = {};
    config.method = method;
    config.headers = {
        'X-Api-Key': `${process.env.REACT_APP_API_KEY}`,
        'Content-Type': 'application/json',
        RSClient: 'DeceptiveDolphin',
    };

    const token = LocalStorageWrapper.getItem('accessToken') || null;
    if (authRequired) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    if (header) {
        config.headers['If-Match'] = `"${header.version}"`;
    }

    if (body !== undefined) {
        config.body = JSON.stringify(body);
    }

    return config;
}

// Any errors that need to be handled from the api
function handleApiErrors(response) {
    if (!response.ok) {
        switch (response.status) {
        case httpStatusCodes.Unauthorized: {
            // Clear everything, inlcuding the roofsnap access token, out of local storage
            LocalStorageWrapper.clear();
            window.location.pathname = '/login';
            return Promise.reject(response);
        }
        case httpStatusCodes.Forbidden: {
            // Navigate to the login page where the authentication logic is
            window.location.pathname = '/login';
            return Promise.reject(response);
        }
        case httpStatusCodes.NotFound: {
            return Promise.reject(response);
        }
        default:
            // If there is a json response, we want to return that, otherwise just reject the response
            try {
                return response.json().then(json => Promise.reject(json));
            } catch (error) {
                return Promise.reject(response);
            }
        }
    }

    return response;
}

class RoofSnapApiClient {
    constructor() {
        this.allResources = [];
    }

    callApi({
        endpoint,
        method = httpRequestMethods.GET,
        params,
        body = undefined,
        header,
        getAllPages,
        authRequired = true,
        responseBodyType = responseBodyTypes.JSON,
        apiVersion = 'v1',
    }) {
        const config = buildApiConfigRequest(method, body, header, authRequired);

        return fetch(apiFactory(`${apiVersion}/${endpoint}`, params), config)
            .then(handleApiErrors)
            .then((response) => {
                if (response.status === httpStatusCodes.NoContent) {
                    return response;
                }

                if (responseBodyType === responseBodyTypes.BLOB) {
                    return response.blob().then(blob => blob);
                }

                if (responseBodyType === responseBodyTypes.TEXT) {
                    return response.text().then(text => text);
                }

                return response.json().then((json) => {
                    const jsonResponse = camelizeKeys(json);
                    if (!getAllPages) {
                        return jsonResponse;
                    }

                    let { page } = jsonResponse;

                    while (page <= jsonResponse.totalPages) {
                        this.allResources = this.allResources.concat(jsonResponse.resourceList);
                        if (page === jsonResponse.totalPages) {
                            jsonResponse.resourceList = this.allResources;
                            return jsonResponse;
                        }

                        page += 1;
                        const newParams = params;
                        newParams.page = page;

                        return this.callApi({
                            endpoint,
                            method,
                            params: newParams,
                            body,
                            header,
                            getAllPages,
                        });
                    }

                    return jsonResponse;
                });
            });
    }
}

export default RoofSnapApiClient;
