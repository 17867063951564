import React, { ReactElement, useState } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import OfficeDropDown from './OfficeDropDown';
import NavButton from './NavButton';
import Office from '../../lib/Models/Office';

interface SideMenuProps extends WithStyles<typeof styles> {
    setOffice: (index: number) => any,
    offices: Office[],
    navButtonClicked: (component: any) => void,
    selectedOfficeIndex: number,
    navButtons: NavButtonType[],
}

interface NavButtonType {
    index: number;
    title: string;
    component: ReactElement;
}

const styles = () => createStyles({
    dropDownMenu: {
        paddingBottom: '20px',
    },
    menu: {
        height: '100%',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        padding: '20px 0px',
        opacity: 1,
        minWidth: 270,
        maxWidth: 320,
    },
    menuGrid: {
        padding: '20px 0px',
    },
    menuSectionTitle: {
        color: '#999999',
        fontSize: '12px',
        fontFamily: 'Roboto',
        letterSpacing: '1.3px',
        padding: '14px 28px 14px 47px',
        margin: '6px 0px',
    },
    middleGrid: {
        borderBottom: '2px solid #00112219',
        borderTop: '2px solid #00112219',
        margin: '0px',
        padding: '20px 0px',
    },
    selectedButton: {
        color: '#1E79D6',
    },
    textButton: {
        font: 'normal normal normal 15px/60px Roboto',
        letterSpacing: '0.56px',
        color: '#222222',
        padding: '0px 28px',
        margin: '0px 19px 0px 19px',
        width: '100%',
        justifyContent: 'flex-start',
        borderRadius: '8px',
        textTransform: 'none',
    },

});

const SideMenu = (props: SideMenuProps) => {
    const {
        classes,
    } = props;

    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleNavButtonClick = (navButton: NavButtonType) => {
        setSelectedIndex(navButton.index);
        props.navButtonClicked(navButton.component);
    }

    return (
        <Grid direction="column" container className={classes.menu}>
            <Grid item container direction="column">
                <Grid item container>
                    <Typography className={classes.menuSectionTitle}>
                        OFFICE
                    </Typography>
                </Grid>
                <Grid item container className={classes.dropDownMenu}>
                    <OfficeDropDown
                        setOffice={(index: number) => props.setOffice(index)}
                        offices={props.offices}
                        selectedOfficeIndex={props.selectedOfficeIndex}
                    />
                </Grid>

            </Grid>

            <Grid item container className={classes.menuGrid}>

                <Typography className={classes.menuSectionTitle}>
                    DOCUMENTS
                </Typography>
                {props.navButtons.map((navButton: NavButtonType) => {
                    const domId = navButton.title.replace(/\s+/g, '');

                    return (
                        <NavButton
                            {...navButton}
                            isActive={selectedIndex === navButton.index}
                            key={navButton.title}
                            id={domId}
                            onClick={() => handleNavButtonClick(navButton)}
                            cypressId={`docSettingsNav_${domId}`}
                        />
                )})}
            </Grid>
        </Grid >
    );
};


export default (withStyles(styles)(SideMenu));
