import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const style = {
    card: {
        padding: 20,
        maxWidth: 450,
    },
    completeCheckWrapper: {
        display: 'flex',
    },
    confirmButton: {
        width: '100%',
    },
};

const ChangePasswordComplete = props => (
    <Card className={props.classes.card}>
        <CardHeader
            title="Done!"
        />
        <CardContent>
            <Typography>
                Your password has been changed.
            </Typography>
        </CardContent>
        <CardActions>
            <Button
                id="confirmButton"
                variant="contained"
                color="primary"
                className={props.classes.confirmButton}
                onClick={props.onConfirmClick}
            >
                OK
            </Button>
        </CardActions>
    </Card>
);

ChangePasswordComplete.propTypes = {
    classes: PropTypes.shape({
        card: PropTypes.string.isRequired,
        completeCheckWrapper: PropTypes.string.isRequired,
        confirmButton: PropTypes.string.isRequired,
    }).isRequired,
    onConfirmClick: PropTypes.func.isRequired,
};

export default withStyles(style)(ChangePasswordComplete);
