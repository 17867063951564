import {
    GET_OFFICE_USERS_REQUEST,
    GET_OFFICE_USERS_SUCCESS,
    GET_OFFICE_USERS_FAILURE,
} from '../actions/Offices';

function officeUsers(state = {
    totalResults: 0,
    totalPages: 0,
    currentPage: 1,
    data: [],
}, action) {
    switch (action.type) {
    case GET_OFFICE_USERS_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case GET_OFFICE_USERS_SUCCESS:
        return Object.assign({}, state, {
            totalResults: action.response.totalResults,
            totalPages: action.response.totalPages,
            currentPage: action.response.page,
            data: action.response.resourceList,
            isFetching: false,
        });
    case GET_OFFICE_USERS_FAILURE:
        return Object.assign({}, state, {
            totalResults: 0,
            totalPages: 0,
            currentPage: 0,
            data: [],
            errorMessage: action.message,
            isFetching: false,
        });
    default:
        return state;
    }
}

export default officeUsers;
