import { Box, Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Chip, Typography } from '@ui';
import SectionTitle from './SectionTitle';
import { Project } from 'lib/Models';
import { OrderStatusChip } from '@roofsnap';
import { fromIsoUTCDateToString } from 'lib/util/formatters';
import {
    OrderTypes,
    OrderTypeTitle,
    OrderTypeToString,
} from 'lib/MeasurementOrders';
import SketchOrderStatus, {
    SketchOrderTypeStatus,
} from 'lib/SketchOrderStatus';
import { useDispatch } from 'react-redux';
import { getProjectDrawingById } from 'actions/ProjectDrawingActions';
import { DIYProjectImage, ProjectImagesMapping } from 'lib/ProjectImages';
import NumberFormat from 'react-number-format';
import { MeasurementSectionActions } from './MeasurementSectionActions';

type MeasurementSectionProps = {
    project: Project;
};

export const SketchOrderStatusImageName: { [key: string]: string } = {
    Pending: 'PendingSketchOrder.svg',
    InProgress: 'InProgressSketchOrder.svg',
    BillingFailed: 'BillingFailedSketchOrder.svg',
    Cancelled: 'CancelledSketchOrder.svg',
};

const MeasurementSection = ({ project }: MeasurementSectionProps) => {
    const projectHasOrder = Boolean(project.sketchOrder);

    let orderType = project?.sketchOrder?.sketchReportType.toLowerCase();

    let metalRoofPanelSize = project.sketchOrder!?.metalRoofPanelSize;
    let sketchOrderStatus = project.sketchOrder!?.sketchOrderStatus;

    const dispatch = useDispatch();

    const [imageUrl, setImageUrl] = useState<string>(
        `/assets/${SketchOrderStatusImageName.Pending}`
    );

    const [totalArea, setTotalArea] = useState<number>(0);
    const [eaveRuns, setEaveRuns] = useState<number | undefined>(undefined);

    const customImageConditions = [
        SketchOrderStatus.InProgress,
        SketchOrderStatus.Cancelled,
        SketchOrderStatus.BillingFailed,
        SketchOrderStatus.Pending,
    ];

    const projectImageConditions = [
        SketchOrderStatus.Billed,
        SketchOrderStatus.Complete,
        SketchOrderStatus.RevisionRequested,
        SketchOrderStatus.PendingGroupBilling,
    ];

    const getPanelSizeOrEaveRuns = () => {
        if (orderType === OrderTypes.Gutters) {
            return (
                eaveRuns !== undefined &&
                (eaveRuns > 1 ? `${eaveRuns} Runs` : `${eaveRuns} Run`)
            );
        } else if (orderType === OrderTypes.MetalRoof) {
            return `Metal Panel Size - ${metalRoofPanelSize} in.`;
        }
    };

    const getOrderStatus = () => {
        return projectHasOrder
            ? SketchOrderTypeStatus[sketchOrderStatus]
            : SketchOrderTypeStatus[SketchOrderStatus.Pending];
    };

    const getAreaOrPanelSizeOrEaveRuns = () => {
        let orderStatusType = SketchOrderTypeStatus[sketchOrderStatus];
        //the total area, panel size should only be visible for billed, complete, revision, pendinggroupbilling
        if (projectImageConditions.includes(orderStatusType)) {
            if (
                orderType === OrderTypes.MetalRoof ||
                orderType === OrderTypes.Gutters
            ) {
                return getPanelSizeOrEaveRuns();
            } else if (
                orderType === OrderTypes.FullSnap ||
                orderType === OrderTypes.HalfSnap
            ) {
                return getTotalArea();
            }
        }
    };

    const getProjectTitle = () => {
        let title = 'Draw-It-Yourself';
        if (
            orderType === OrderTypes.Gutters ||
            orderType === OrderTypes.MetalRoof
        )
            title = OrderTypeTitle[orderType];
        else if (
            orderType === OrderTypes.FullSnap ||
            orderType === OrderTypes.HalfSnap
        ) {
            title = OrderTypeToString[orderType];
        }
        return title;
    };

    const getImage = async () => {
        //show the custom image when orders are in Pending/In Progress/Cancelled/Billing Failed.
        //else show the actual image
        let img: string = `/assets/${SketchOrderStatusImageName.Pending}`;
        let orderStatusType = SketchOrderTypeStatus[sketchOrderStatus];

        if (!projectHasOrder) {
            // diy
            img = `${
                process.env.REACT_APP_BLOB_BASE_URL
            }${project.id.toLowerCase()}/${DIYProjectImage}`;
        } else if (projectImageConditions.includes(orderStatusType)) {
            //for billed, complete, revision and pending group billing
            img = `${
                process.env.REACT_APP_BLOB_BASE_URL
            }${project.id.toLowerCase()}/${ProjectImagesMapping[orderType]}`;
        } else if (customImageConditions.includes(orderStatusType)) {
            //for in progress, pending, billing failed and cancelled
            img = `/assets/${SketchOrderStatusImageName[sketchOrderStatus]}`;
        }
        setImageUrl(img);
    };

    const ProjectImage = () => {
        const onError = () =>
            setImageUrl(`/assets/${SketchOrderStatusImageName.Pending}`);
        return (
            <img
                src={imageUrl}
                onError={onError}
                width={'90px'}
                height={'70px'}
                style={{ borderRadius: '5px' }}
                alt={getOrderStatus()}
            />
        );
    };

    useEffect(() => {
        if (project.id) {
            getImage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project.id]);

    const getTotalArea = () => {
        return (
            !isNaN(totalArea) && (
                <NumberFormat
                    value={totalArea * 10}
                    decimalScale={2}
                    displayType='text'
                    suffix=' sq. ft.'
                    thousandSeparator
                    renderText={(value) => (
                        <span style={{ textTransform: 'none' }}>{value}</span>
                    )}
                />
            )
        );
    };

    const getMeasurements: any = async () => {
        const result = (await dispatch(
            getProjectDrawingById(project.id)
        )) as any;
        if (result && result.error) {
            return;
        }
        setTotalArea(result.response.measurements.totalSquares);
        setEaveRuns(result.response.gutterEaveRunsCount);
    };

    useEffect(() => {
        if (
            orderType === OrderTypes.FullSnap ||
            orderType === OrderTypes.HalfSnap ||
            orderType === OrderTypes.Gutters
        ) {
            getMeasurements();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project.id]);

    const TypeAndMeasurements = () => {
        return (
            <Grid item xs={6} md={5}>
                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    alignItems={{ xs: 'flex-start', md: 'center' }}
                    gap='0.9375rem'
                >
                    <ProjectImage />
                    <Box
                        sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            display: 'inline-block',
                        }}
                    >
                        <Typography variant='new-caps' color='#555'>
                            {getProjectTitle()}
                        </Typography>
                        <br />
                        <Typography variant='body-large'>
                            {getAreaOrPanelSizeOrEaveRuns()}
                        </Typography>
                    </Box>
                </Stack>
            </Grid>
        );
    };

    const DisplayDate = () => (
        <Grid item md={4} xs={8} order={{ xs: 4, md: 2 }}>
            <Typography
                sx={{
                    color: '#555',
                    textAlign: { xs: 'right', md: 'left' },
                    mt: { xs: '45px', md: 0 },
                }}
            >
                {fromIsoUTCDateToString(
                    !projectHasOrder && !project.hasDrawing
                        ? project.createdAt
                        : project.updatedAt
                )}
            </Typography>
        </Grid>
    );

    const Status = () => {
        return (
            <Grid
                item
                md={2}
                xs={4}
                sx={{
                    mt: { xs: '45px', md: 0 },
                }}
                order={3}
            >
                <Box sx={{ display: 'flex' }}>
                    {projectHasOrder && (
                        <OrderStatusChip
                            status={project.sketchOrder.sketchOrderStatus}
                        />
                    )}
                    {!projectHasOrder && (
                        <Chip
                            label={project.hasDrawing ? 'Saved' : 'New'}
                            variant={project.hasDrawing ? 'green' : 'orange'}
                        />
                    )}
                </Box>
            </Grid>
        );
    };

    return (
        <Box mt='3rem'>
            <Box sx={{ margin: '32px 16px 16px 2px' }}>
                <SectionTitle title='Measurements' />
            </Box>
            <ColumnTitles />
            <Grid
                sx={{
                    padding: { xs: '1.5rem', md: '1.25rem' },
                    borderRadius: '0.75rem',
                    border: '1px solid #d9d9d9',
                    background: '#fff',
                    alignItems: 'center',
                }}
                container
                xs={12}
            >
                <TypeAndMeasurements />
                <DisplayDate />
                <Status />
                <Grid item md={1} xs={6} order={{ xs: 2, md: 4 }}>
                    <MeasurementSectionActions projectId={project.id} />
                </Grid>
            </Grid>
        </Box>
    );
};

const ColumnTitles = () => (
    <Grid
        container
        sx={{ display: { xs: 'none', md: 'flex' }, mb: '0.6875rem' }}
    >
        <Grid item xs={5}>
            <Typography variant='new-caps' color='#555'>
                Report
            </Typography>
        </Grid>
        <Grid item xs={4}>
            <Typography variant='new-caps' color='#555'>
                Time
            </Typography>
        </Grid>
        <Grid item xs={2}>
            <Typography ml={-1} variant='new-caps' color='#555'>
                Status
            </Typography>
        </Grid>
        <Grid item xs={1} sx={{ pl: { xs: 0, xl: 5 } }}>
            <Typography variant='new-caps' color='#555'>
                Actions
            </Typography>
        </Grid>
    </Grid>
);

export default MeasurementSection;
