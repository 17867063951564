import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import red from '@material-ui/core/colors/red';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';

const style = () => ({
    root: {
        backgroundColor: red[500],
        cursor: 'pointer',
        maxWidth: 900,
        marginBottom: 15,
        margin: 'auto',
    },
    text: {
        color: '#FFFFFF',
    },
    warningIcon: {
        width: 45,
        height: 45,
        color: '#FFFFFF',
    },
});

export const BillingFailedSketchOrdersAlert = (props) => {
    const errorMessage = (props.totalSketchOrders === 1) ? 'sketch order has failed to bill' : 'sketch orders have failed to bill';

    return (
        props.totalSketchOrders === 0 ? (
            null
        ) : (
            <Card className={props.classes.root} onClick={props.onClick}>
                <CardHeader
                    avatar={
                        <WarningIcon
                            className={props.classes.warningIcon}
                        />
                    }
                    title={`${props.totalSketchOrders} ${errorMessage}`}
                    subheader="Please click here to resolve."
                    titleTypographyProps={{
                        variant: 'title',
                        className: props.classes.text,
                    }}
                    subheaderTypographyProps={{
                        className: props.classes.text,
                    }}
                />
            </Card>
        )
    );
};

BillingFailedSketchOrdersAlert.propTypes = {
    totalSketchOrders: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        warningIcon: PropTypes.string.isRequired,
    }).isRequired,
};

export default withStyles(style)(BillingFailedSketchOrdersAlert);
