import {
    Tab,
    Tabs,
    WithStyles,
    createStyles,
    styled,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OrderToolBar from 'components/OrderToolBar/OrderToolBar';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { setPageToolBarActiveNavTab } from '../../actions/RoofSnapPageToolbarActions';
import Breadcrumb from './Breadcrumb';

type StyledTabProps = {
    key: number;
    label: string;
};

const StyledTab = styled((props: StyledTabProps) => <Tab {...props} />)(() => ({
    textTransform: 'none',
    '&.Mui-selected': {
        color: '#2D6AFF',
    },
}));

const styles = (theme: any) =>
    createStyles({
        buttons: {
            margin: theme.spacing(1),
        },
        buttonsElementsRoot: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginLeft: 'auto',
        },
        root: {
            backgroundColor: '#ffffff',
            width: '100%',
        },
        toolBarRoot: {
            flexWrap: 'wrap',
            minHeight: '56px',
            paddingLeft: '16px',
            boxShadow: '0 1px 10px 0px rgba(0, 0, 0, 0.2)',
        },
        iconButton: {
            color: 'rgba(0,0,0,.53) !important',
        },
        moreMenu: {
            marginTop: 55,
        },
        navTabsRoot: {
            flex: '1 0 auto',
        },
        '&.Mui-selected': {
            color: '#056CF2',
        },
    });

type RoofSnapPageToolbarProps = {
    buttons?: {
        buttonText: string | any;
        buttonAction: () => void;
    }[];
    elements: React.ReactNode;
    navTabs?: {
        activeTabIndex: number;
        tabs: {
            index: number;
            title: string;
            route: string;
        }[];
    };
} & WithStyles<typeof styles>;

const RoofSnapPageToolbar = ({
    buttons = [],
    elements = null,
    navTabs,
    classes,
}: RoofSnapPageToolbarProps) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const history = useHistory();

    const [moreAnchor, setMoreAnchor] = useState<
        (EventTarget & HTMLButtonElement) | null
    >(null);

    const moreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMoreAnchor(event.currentTarget);
    };

    const moreClose = () => {
        setMoreAnchor(null);
    };

    const handleNavTabChange = (
        event: React.ChangeEvent<{}>,
        tabIndex: number
    ) => {
        dispatch(setPageToolBarActiveNavTab(tabIndex));

        const currentTab = navTabs?.tabs.find((t) => t.index === tabIndex);

        if (currentTab?.route) {
            history.replace(currentTab.route);
        }
    };

    const menuItem = (item: { buttonText: any; buttonAction: () => void }) => (
        <MenuItem
            key={item.buttonText}
            onClick={() => {
                moreClose();
                item.buttonAction();
            }}
        >
            {item.buttonText}
        </MenuItem>
    );

    const toolBarContent = () => {
        if (
            history.location.pathname.includes('/orders/create') &&
            history.location.pathname !== '/orders/create/complete'
        ) {
            return <OrderToolBar></OrderToolBar>;
        } else {
            return (
                <React.Fragment>
                    {!mobile && (
                        <div>
                            <Breadcrumb />
                        </div>
                    )}
                    {navTabs && navTabs.tabs && (
                        <div className={classes.navTabsRoot}>
                            <Tabs
                                onChange={handleNavTabChange}
                                value={navTabs.activeTabIndex}
                                centered
                            >
                                {navTabs.tabs.map((tab) => (
                                    <StyledTab
                                        key={tab.index}
                                        label={tab.title}
                                    />
                                ))}
                            </Tabs>
                        </div>
                    )}
                    <div className={classes.buttonsElementsRoot}>
                        {elements}
                        {buttons.length > 1 && (
                            <IconButton onClick={moreClick}>
                                <MoreVertIcon className={classes.iconButton} />
                            </IconButton>
                        )}
                        {buttons.length > 1 && (
                            <Menu
                                anchorEl={moreAnchor}
                                open={Boolean(moreAnchor)}
                                onClose={moreClose}
                                className={classes.moreMenu}
                            >
                                {buttons.map(menuItem)}
                            </Menu>
                        )}
                    </div>
                </React.Fragment>
            );
        }
    };
    return (
        <div className={classes.root}>
            <Toolbar classes={{ root: classes.toolBarRoot }}>
                {toolBarContent()}
            </Toolbar>
        </div>
    );
};

export default withStyles(styles)(RoofSnapPageToolbar);
