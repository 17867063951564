import React, { useState } from 'react';
import filter from 'lodash/filter';
import some from 'lodash/some';
import PropTypes from 'prop-types';
import { Link, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DocumentTemplate from '../DocumentTemplate';
import DocumentTemplateCategoryValidationMessage from '../DocumentTemplateCategoryValidationMessage';
import { DocumentDataContextType, getDocumentDataContextTypeFromDocumentTemplateCategory } from '../../lib/documentDataContextType';


const styles = theme => ({
    root: {
        marginBottom: theme.spacing(3),
    },
    listContainer: {
        display: 'block',
    },
    templateCategoryTitle: {
        marginBottom: theme.spacing(2),
        clear: 'both',
    },
});

const DocumentTemplatesList = (props) => {
    // Props
    const {
        onDocumentTemplateClick,
        documentTemplateCategory,
        documentTemplatesDisabled,
        currentUserRoleIds,
        officeId,
        classes,
        subscriptionExpired,
    } = props;

    // State hooks
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const [showSubscriptionMessage, setShowSubscriptionMessage] = useState(false);

    const handleOnDocumentTemplateClick = (documentTemplate) => {
        if (subscriptionExpired && (documentDataContextType === DocumentDataContextType.Estimate || documentDataContextType === DocumentDataContextType.MultiEstimate)) {
            setShowSubscriptionMessage(true);
            return;
        }

        if (documentTemplatesDisabled) {
            setShowValidationMessage(true);
            return;
        }

        onDocumentTemplateClick(documentTemplate, documentTemplateCategory);
    };

    const renderDocumentTemplate = (documentTemplate) => {
        const title = documentTemplate.displayName === documentTemplateCategory.name
            ? documentTemplate.displayName
            : `${documentTemplate.displayName} ${documentTemplateCategory.name}`;

        const titleNoSpaces = title.replace(/\s/g, '');
        return (
            <DocumentTemplate
                onClick={() => handleOnDocumentTemplateClick(documentTemplate)}
                thumbnail={documentTemplate.thumbnailUrl}
                title={title}
                id={titleNoSpaces}
                disabled={documentTemplatesDisabled}
            />
        );
    };

    const documentDataContextType = getDocumentDataContextTypeFromDocumentTemplateCategory(documentTemplateCategory);
    const generatableDocumentTemplates = filter(documentTemplateCategory.templates, (template) => {
        const applicablePermissions = filter(template.documentTemplatePermissions.resourceList, permission =>
            permission.applyToAllRoles ||
            currentUserRoleIds.includes(permission.roleId) ||
            permission.officeId === officeId);

        const { userGeneratable } = template;

        const documentTemplateGenerationDisabled = some(applicablePermissions, 'disableGenerate') || userGeneratable === false;

        return !documentTemplateGenerationDisabled;
    });

    return (
        <React.Fragment>
            {
                generatableDocumentTemplates.length > 0 &&
                <div
                    className={classes.root}
                >
                    <div
                        className={classes.templateCategoryTitle}
                    >
                        <Typography
                            variant="h6"
                            color="primary"
                        >
                            {documentTemplateCategory.name}
                        </Typography>
                        {
                            showValidationMessage &&
                            <Typography
                                color="error"
                            >
                                <DocumentTemplateCategoryValidationMessage
                                    documentDataContextType={documentDataContextType}
                                />
                            </Typography>
                        }
                        {
                            showSubscriptionMessage &&
                            <Typography
                                color="error"
                            >
                                <Link to="/settings/billing" href="/settings/billing">Subscribe</Link> for access to this document type and exclusive order discounts.
                            </Typography>
                        }
                    </div>
                    <div
                        className={classes.listContainer}
                    >
                        {
                            generatableDocumentTemplates.map(renderDocumentTemplate)
                        }
                    </div>
                </div>
            }
        </React.Fragment>
    );
};

DocumentTemplatesList.propTypes = {
    documentTemplateCategory: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        templates: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            documentDataContextType: PropTypes.string.isRequired,
            thumbnailUrl: PropTypes.string.isRequired,
        })).isRequired,
    }).isRequired,
    onDocumentTemplateClick: PropTypes.func,
    documentTemplatesDisabled: PropTypes.bool,
    currentUserRoleIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    officeId: PropTypes.number.isRequired,
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
        templateCategoryTitle: PropTypes.string.isRequired,
        listContainer: PropTypes.string.isRequired,
    }).isRequired,
    subscriptionExpired: PropTypes.bool,
};

DocumentTemplatesList.defaultProps = {
    onDocumentTemplateClick: () => {},
    documentTemplatesDisabled: false,
    subscriptionExpired: false,
};

export default withStyles(styles)(DocumentTemplatesList);
