/* eslint-disable react/no-string-refs,react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import isEmpty from 'lodash/isEmpty';
import { createPortal } from 'react-dom';
import { RoofSnapEdgeCTA, PendingSketchOrdersChip } from '@roofsnap';
import { setUsePortal } from '../../actions/RoofSnapPageToolbarActions';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';
import {
    getProjects,
    projectParams,
    projectRealm,
} from '../../actions/Projects';
import { getOrganizationOffices } from '../../actions/Offices';
import { getOrganizationUsers } from '../../actions/Users';
import { getOpenRequestedOrOwnedSketchOrders } from '../../actions/SketchOSActions';
import { getOrganizationOpenSketchOrders } from '../../actions/OrganizationFreeSketchOrdersActions';
import QueryStringBuilder from '../../lib/QueryStringBuilder';
import { projectMenusVisible } from '../../lib/ProjectMenu';
import Pager from '../Pager/Pager.tsx';
import ConnectedProjectFilter from '../ProjectFilter/ProjectFilter';
import CreateProject from '../CreateProject';
import './ProjectList.css';
import { closeSearchAppBar } from '../../actions/SearchAppBarActions';
import UserRoles from '../../lib/UserRoles';
import RoofSnapPageToolbar from '../NavigationComponents/RoofSnapPageToolbar.tsx';
import ProjectListGridItem from './ProjectListGridItem';

const urlPropsQueryConfig = {
    searchText: { type: UrlQueryParamTypes.string, queryParam: 'q' },
};

const style = {
    root: {
        padding: 24,
    },
};

class ProjectList extends Component {
    constructor(props) {
        super(props);
        this.props.dispatch(setUsePortal(true));

        this.state = {
            pageNumber: 1,
        };
        if (props.location.state && props.location.state.message) {
            this.props.dispatch(setReduxMessage(props.location.state.message));
        }

        const currentUserRoles = [];

        if (this.props.estimatesDisabled) {
            currentUserRoles.push(UserRoles.EstimatesDisabled);
        }

        this.primaryVisibleMenuItems = projectMenusVisible.filter(
            (item) =>
                item.isPrimary &&
                !(
                    item.hideForRoles &&
                    item.hideForRoles.some((r) => currentUserRoles.includes(r))
                )
        );
        this.secondaryVisibleMenuItems = projectMenusVisible.filter(
            (item) =>
                !item.isPrimary &&
                !(
                    item.hideForRoles &&
                    item.hideForRoles.some((r) => currentUserRoles.includes(r))
                )
        );
    }

    componentDidMount() {
        this.getProjects();
        this.getInProgressSketchOrders();

        if (isEmpty(this.props.filterOffices)) {
            this.props.dispatch(
                getOrganizationOffices(this.props.organizationId, 1, true)
            );
        }
        if (this.props.isAccountAdmin && isEmpty(this.props.filterUsers)) {
            this.props.dispatch(
                getOrganizationUsers(this.props.organizationId, 1, true)
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.searchText !== this.props.searchText ||
            prevState.pageNumber !== this.state.pageNumber ||
            prevProps.selectedStatus !== this.props.selectedStatus ||
            prevProps.selectedOffice !== this.props.selectedOffice ||
            prevProps.selectedUser !== this.props.selectedUser
        ) {
            window.scrollTo(0, 0);
            this.getProjects();
        }
    }

    componentWillUnmount() {
        this.props.dispatch(closeSearchAppBar());
        this.props.dispatch(setUsePortal(false));
    }

    getProjects = () => {
        const params = {};
        if (this.props.searchText) {
            params[projectParams.Search] = this.props.searchText;
        }
        if (this.state.pageNumber > 1) {
            params[projectParams.Page] = this.state.pageNumber;
        }
        if (this.props.selectedStatus) {
            params[projectParams.Status] = this.props.selectedStatus;
        }
        if (this.props.selectedOffice) {
            params[projectParams.Office] = this.props.selectedOffice;
        }

        let id = this.props.userId;
        let realm = projectRealm.User;

        if (this.props.isAccountAdmin) {
            if (this.props.selectedUser) {
                id = this.props.selectedUser;
            } else {
                realm = projectRealm.Organization;
                id = this.props.organizationId;
            }
        }
        this.props.dispatch(getProjects(realm, id, params));
    };

    getInProgressSketchOrders = async () => {
        if (this.props.isAccountAdmin) {
            await this.props.dispatch(
                getOrganizationOpenSketchOrders(this.props.organizationId)
            );
            this.setState({
                inProgressSketchOrders: this.props.adminNumberOfPendingOrders,
            });
        } else {
            await this.props.dispatch(
                getOpenRequestedOrOwnedSketchOrders(this.props.userId)
            );
            this.setState({
                inProgressSketchOrders:
                    this.props.userOwnedNumberOfPendingOrders,
            });
        }
    };

    handleLockedProjectClick = () => {
        this.props.dispatch(
            setReduxMessage(
                'Subscribe to Unlock',
                <Button
                    color='inherit'
                    onClick={() => this.props.history.push('/settings/billing')}
                >
                    Subscribe
                </Button>
            )
        );
    };

    goToOrdersPage = () => {
        this.props.history.push('/orders');
    };

    nextProjectsPage = () => {
        this.setState({ pageNumber: this.props.currentProjectsPage + 1 });
    };

    prevProjectsPage = () => {
        this.setState({ pageNumber: this.props.currentProjectsPage - 1 });
    };

    selectProject = (project) => {
        // If the project is not locked, take them to the project
        if (!project.locked) {
            this.props.history.push(`/projects/${project.id}`);
            return;
        }

        // If the project is locked, don't take them to the project. Pop up an error message.
        const callToAction = (
            <Button
                color='secondary'
                variant='contained'
                onClick={() => {
                    window.open(
                        `${process.env.REACT_APP_ROOFSNAP_ONLINE_HOST}/Account`,
                        '_blank'
                    );
                }}
            >
                Activate
                <OpenInNewIcon style={{ marginLeft: 10 }} />
            </Button>
        );

        this.props.dispatch(
            setReduxMessage(
                'Draw It Yourself projects have been locked since you do not have an active RoofSnap subscription. Click to activate your subscription and access this project.',
                callToAction
            )
        );
    };

    filterCallback = () => {
        // reset the page to get the first page
        this.setState({ pageNumber: 1 });

        const queryStringBuilder = new QueryStringBuilder();
        if (this.props.searchText) {
            queryStringBuilder.setQueryParameter('q', this.props.searchText);
        }
        const queryString = queryStringBuilder.buildQueryString();
        this.props.history.replace({
            pathname: '/projects',
            search: `${queryString}`,
        });
    };

    renderProject = (project) => (
        <Grid
            key={project.id}
            data-project-id={project.id}
            item
            xs={12}
            md={6}
            lg={4}
        >
            <ProjectListGridItem
                project={project}
                onProjectListItemClick={() => this.selectProject(project)}
                primaryVisibleMenuItems={this.primaryVisibleMenuItems}
                secondaryVisibleMenuItems={this.secondaryVisibleMenuItems}
                handleLockedElementClick={this.handleLockedProjectClick}
            />
        </Grid>
    );

    render() {
        const { data, totalProjects, totalProjectsPages, currentProjectsPage } =
            this.props;
        const filterOffices = this.props.filterOffices.map((office) => [
            office.id,
            office.officeName,
        ]);
        const filterUsers = this.props.filterUsers.map((user) => [
            user.userId,
            `${user.firstName} ${user.lastName} (${user.userName})`,
        ]);

        return (
            <div style={style.root}>
                {this.props.toolbar.ref &&
                    createPortal(
                        <RoofSnapPageToolbar
                            elements={
                                <>
                                    {this.state.inProgressSketchOrders > 0 && (
                                        <PendingSketchOrdersChip
                                            inProgressSketchOrders={
                                                this.state
                                                    .inProgressSketchOrders
                                            }
                                            onClick={this.goToOrdersPage}
                                        />
                                    )}
                                    <RoofSnapEdgeCTA />
                                </>
                            }
                        />,
                        this.props.toolbar.ref.current
                    )}
                <div className='project-list-card'>
                    <Grid container>
                        <Grid container>
                            <Grid item xs>
                                <ConnectedProjectFilter
                                    onChange={this.filterCallback}
                                    officeOptions={filterOffices}
                                    userOptions={filterUsers}
                                    defaultUserId={this.props.userId}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs>
                                <Typography
                                    style={{ width: '100%', margin: '20px 0' }}
                                    align='right'
                                >
                                    {totalProjects} Project(s)
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        id='projectList'
                        className='project-list-body'
                    >
                        {data.map(this.renderProject)}
                    </Grid>
                    <Pager
                        totalPages={totalProjectsPages}
                        currentPage={currentProjectsPage}
                        nextClick={this.nextProjectsPage}
                        prevClick={this.prevProjectsPage}
                    />
                </div>
                <CreateProject
                    clickedCreateProject={this.clickedCreateProject}
                />
            </div>
        );
    }
}

ProjectList.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
    organizationId: PropTypes.number.isRequired,
    isAccountAdmin: PropTypes.bool.isRequired,
    totalProjects: PropTypes.number,
    totalProjectsPages: PropTypes.number,
    currentProjectsPage: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    filterOffices: PropTypes.arrayOf(PropTypes.object),
    filterUsers: PropTypes.arrayOf(PropTypes.object),
    searchText: PropTypes.string,
    selectedStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedOffice: PropTypes.number,
    selectedUser: PropTypes.number,
    userOwnedNumberOfPendingOrders: PropTypes.number,
    adminNumberOfPendingOrders: PropTypes.number,
};

ProjectList.defaultProps = {
    totalProjects: 0,
    totalProjectsPages: 1,
    currentProjectsPage: 1,
    filterOffices: [],
    filterUsers: [],
    searchText: '',
    selectedStatus: '',
    selectedOffice: 0,
    selectedUser: 0,
    userOwnedNumberOfPendingOrders: 0,
    adminNumberOfPendingOrders: 0,
};

function mapStateToProps(state) {
    const {
        projects,
        organizationOffices,
        organizationUsers,
        currentUser,
        currentUserRoles,
        features,
        sketchOS,
        organizationFreeSketchOrders,
        roofSnapPageToolbar,
    } = state;
    const { userId, organizationId } = currentUser;
    const { data: filterOffices } = organizationOffices;
    const { data: filterUsers } = organizationUsers;
    const { openSketchOrders: adminNumberOfPendingOrders } =
        organizationFreeSketchOrders;
    const { totalResults: userOwnedNumberOfPendingOrders } = sketchOS;
    const {
        totalResults: totalProjects,
        totalPages: totalProjectsPages,
        currentPage: currentProjectsPage,
        data,
        selectedUser,
        selectedStatus,
        selectedOffice,
        isFetching,
    } = projects;
    const { isAccountAdmin, estimatesDisabled } = currentUserRoles;

    return {
        userId,
        isAccountAdmin,
        estimatesDisabled,
        organizationId,
        totalProjects,
        totalProjectsPages,
        currentProjectsPage,
        data,
        filterOffices,
        filterUsers,
        selectedUser,
        selectedStatus,
        selectedOffice,
        isFetching,
        features,
        adminNumberOfPendingOrders,
        userOwnedNumberOfPendingOrders,
        toolbar: roofSnapPageToolbar,
    };
}

export default addUrlProps({ urlPropsQueryConfig })(
    connect(mapStateToProps)(ProjectList)
);
