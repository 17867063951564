import { Button, Typography } from '@ui';
import { Popover, Stack } from '@mui/material';
import React, { useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { RoofSnapState } from 'lib/Models';
import useId from '@mui/material/utils/useId';
import LoggerWrapper from 'lib/Logger';
import httpRequestMethods from 'middleware/httpRequestMethods';
import RoofSnapApiClient from 'middleware/roofSnapApiClient';

const displayForStatuses = [
    null,
    'rewards_splash_screen_loaded',
    'rewards_everpro_signup_completed',
    'rewards_partner_code_completed',
];

export const RoofSnapEdgeCTA = () => {
    const { currentUser, organization } = useSelector(
        (state: RoofSnapState) => state
    );

    const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(
        null
    );

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setPopoverAnchor(event.currentTarget);
    };

    const handleClose = (event: { stopPropagation: () => void }) => {
        event.stopPropagation();

        setPopoverAnchor(null);
    };

    const handleStartButtonClick = async (): Promise<void> => {
        try {
            const roofsnapApiClient = new RoofSnapApiClient();
            const response = await roofsnapApiClient.callApi({
                endpoint: 'everproedge/appurl',
                method: httpRequestMethods.GET,
                params: {},
                header: {},
                getAllPages: false,
                apiVersion: 'v2',
            });

            const url = response.url;
            window.open(url, '_blank');
        } catch (error) {
            LoggerWrapper.log(error);
        } finally {
            handleClose({ stopPropagation: () => {} });
        }
    };

    const open = Boolean(popoverAnchor);
    const tempId = useId();
    const popoverId = open ? tempId : undefined;
    if (
        currentUser.userId !== organization.primaryUserId ||
        !displayForStatuses.includes(currentUser.edgeStatus)
    ) {
        return null;
    }

    return (
        <Stack
            direction='row'
            alignItems='center'
            gap={1}
            sx={{ ml: '30px', cursor: 'pointer' }}
            justifySelf='flex-end'
            onClick={handleClick}
            role='button'
        >
            <Typography variant='body-small'>
                Free Money, Free Rewards
            </Typography>
            <img src='/assets/home-depot-logo.svg' alt='The Home Depot' />
            <Popover
                id={popoverId}
                open={open}
                anchorEl={popoverAnchor}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ width: '400px' }}
                disableScrollLock={true}
            >
                <CTAContent onStartButtonClick={handleStartButtonClick} />
            </Popover>
        </Stack>
    );
};

interface CTAContentProps {
    onStartButtonClick: () => Promise<void>;
}
export const CTAContent: React.FC<CTAContentProps> = ({
    onStartButtonClick,
}) => {
    return (
        <Stack
            sx={{
                width: '300px',
                padding: '1.5625rem',
                gap: '0.9375rem',
                overflow: 'hidden',
            }}
        >
            <img
                style={{ width: '9.75rem', marginLeft: '-5px' }}
                src='/assets/everpro-edge-logo.svg'
                alt='RoofSnap Edge'
            />
            <Typography variant='title-medium'>
                Earn cash back with EverPro Edge! 
            </Typography>
            <Typography variant='body-medium'>
                Introducing EverPro Edge, a free RoofSnap rewards program that
                lets you earn money back on your Home Depot purchases.
            </Typography>
            <Button
                customVariant='md3-warning'
                sx={{ paddingTop: '0.875rem' }}
                onClick={onStartButtonClick}
            >
                Get Started
            </Button>
        </Stack>
    );
};

export default RoofSnapEdgeCTA;
