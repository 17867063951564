import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import { resetPassword } from '../../actions/ResetPassword';
import { login } from '../../actions/Auth';
import ResetPasswordForm from '../ResetPasswordForm';

const styles = theme => ({
    wrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: '100%',
        width: '100%',
        position: 'fixed',
        top: 0,
    },
    card: {
        padding: 20,
    },
    formControl: {
        margin: theme.spacing(1),
        marginLeft: 0,
    },
    resetPasswordButton: {
        width: '100%',
    },
    checkbox: {
        marginRight: 0,
    },
});

class SetPassword extends Component {
    state = {
        eulaChecked: false,
        showEulaErrorMessage: false,
    };

    validate = () => {
        if (!this.state.eulaChecked) {
            this.setState({ showEulaErrorMessage: true });
        }

        return this.state.eulaChecked;
    }

    resetPassword = async (password) => {
        const valid = this.validate();
        if (!valid) {
            return;
        }

        const result = await this.props.dispatch(resetPassword(this.props.match.params.token, password));
        if (result.error) {
            return;
        }

        const params = queryString.parse(this.props.location.search);
        await this.props.dispatch(login(params.email, password));
        this.props.history.push('/projects');
    };

    handleEulaCheckboxClick = () => {
        this.setState({ eulaChecked: !this.state.eulaChecked, showEulaErrorMessage: false });
    }

    render() {
        return (
            <div className={this.props.classes.wrapper}>
                <ResetPasswordForm
                    title="Complete Registration"
                    subheader="Set a password to complete registration"
                    label="Password"
                    confirmLabel="Confirm Password"
                    buttonText="Complete"
                    onSubmitClick={this.resetPassword}
                    onError={this.validate}
                    additionalFields={
                        <React.Fragment>
                            <FormGroup
                                row
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="tosCheckBox"
                                            checked={this.state.eulaChecked}
                                            onClick={this.handleEulaCheckboxClick}
                                            value="checked"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Typography variant="caption" align="center" style={{ lineHeight: '48px' }}>
                                            By checking this box, you are agreeing to our&nbsp;
                                            <a href={`${process.env.REACT_APP_ROOFSNAP_ONLINE_HOST}/Home/TermsAndConditions`} target="_blank" rel="noopener noreferrer">
                                                End User License Agreement.
                                            </a>
                                        </Typography>
                                    }
                                />

                            </FormGroup>
                            {
                                this.state.showEulaErrorMessage &&
                                <FormHelperText error>
                                    You must agree to the End User License Agreement to complete registration.
                                </FormHelperText>
                            }
                        </React.Fragment>
                    }
                />
            </div>
        );
    }
}

SetPassword.defaultProps = {
    classes: PropTypes.shape({
        card: PropTypes.string.isRequired,
        formControl: PropTypes.string.isRequired,
        resetPasswordButton: PropTypes.string.isRequired,
    }).isRequired,
};

export default connect(null)(withStyles(styles)(SetPassword));
