import React, { useEffect, useState, useRef, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { withStyles, WithStyles, createStyles } from "@material-ui/core/styles";
import {
  getUsersOffices,
  getOrganizationOffices,
} from "../../../actions/Offices";
import SideMenu from "../SideMenu";
import SaveSnackBar from "../SaveSnackbar";
import { Color as AlertColor } from "@material-ui/lab";
import MobileMenu from "../MobileMenu";
import OfficeContext from "../OfficeContext";
import Office from "../../../lib/Models/Office";
import LockedSettingsToolbar from "./LockedSettingsToolbar";
import LockableElement from "../../LockableElement/LockableElement";
import { Link } from "react-router-dom";
import LockIcon from "@material-ui/icons/Lock";
import LockedDialog from "../../CustomizableDialog/CustomizableDialog";
import ContractEditor from "./ContractEditor";
import Contracts from "./Contracts";
import Estimates from "./Estimates";
import MaterialOrder from "./MaterialOrder";
import SummaryReport from "./SummaryReport";
import LaborReport from "./LaborReport";

export interface DocumentSettingsPageProps extends WithStyles<typeof styles> { }

const styles = () =>
  createStyles({
    root: {
      height: "100%",
    },
    pageText: {
      fontSize: "40px",
    },
    office: {
      color: "#999999",
      textTransform: "uppercase",
      fontSize: "12px",
      fontFamily: "Roboto",
      letterSpacing: "1.3px",
    },
    onScreenComponent: {
      padding: "5%",
      width: "100%",
    },
    onScreenMobile: {
      padding: "0px 10px",
    },
    dialogText: {
      font: "17px Roboto",
      color: "#FFF",
    },
    mobileDialogText: {
      font: "12px Roboto",
      color: "#FFF",
    },
    mobileDialogButton: {
      font: "12px Roboto",
      color: "#FFF",
      backgroundColor: "#40ad48",
      height: "36px",
    },
    dialogButton: {
      font: "16px Roboto",
      color: "#FFF",
      backgroundColor: "#40ad48",
      height: "36px",
    },
    sideMenu: {
      backgroundColor: "#FFF",
      zIndex: 4,
    },
    mobileDialog: {
      height: 68,
    },
  });

const DocumentSettingsPage = ({ classes }: DocumentSettingsPageProps) => {
  const {
    userOffices: { data: offices },
    organizationOffices: { data: orgOffices },
    currentUser: { organizationId, userId },
    currentUserRoles: { isAccountAdmin },
    organization: { isFreeAccount },
  } = useSelector((state: any) => state);

  const dispatch = useDispatch();

  const userOffices = isAccountAdmin ? orgOffices : offices;
  const [officeIndex, setOfficeIndex] = useState(0);
  const officeVersionRef = useRef<string>("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [office, setOffice] = useState<Office>(userOffices[officeIndex]);
  const [editor, setEditor] = useState(<ContractEditor />);
  const [alertOpen, toggleAlert] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState<{
    severity: AlertColor;
    message: string;
    retryAction?: JSX.Element;
    autoHideDuration?: number;
  } | null>(null);

  const navButtons = [
    {
      index: 0,
      title: "Contract",
      component: <Contracts onSuccess={() => onSuccess()} editor={editor} />,
    },
    {
      index: 1,
      title: "Estimates",
      component: <Estimates onSuccess={() => onSuccess()} />,
    },
    {
      index: 2,
      title: "Material Order",
      component: <MaterialOrder onSuccess={() => onSuccess()} />,
    },
    {
      index: 3,
      title: "Summary Report",
      component: <SummaryReport onSuccess={() => onSuccess()} />,
    },
    {
      index: 4,
      title: "Labor Report",
      component: <LaborReport onSuccess={() => onSuccess()} />,
    },
  ];

  const [onScreenComponent, setComponent] = useState<ReactElement>(
    <Grid className="option-selected">{navButtons[0].component}</Grid>
  );

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const successMessage: string = "Your settings have been successfully saved.";

  useEffect(() => {
    if (userOffices.length !== 0) {
      return
    }
  });

  useEffect(() => {
    setEditor(<React.Fragment />);
    officeVersionRef.current = office?.version;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [office?.id]);

  useEffect(() => {
    setEditor(<ContractEditor />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeVersionRef.current]);

  useEffect(() => {
    setOffice(userOffices[officeIndex]);
  }, [userOffices, officeIndex]);

  useEffect(() => {
    if (isAccountAdmin) {
      dispatch(getOrganizationOffices(organizationId));
    } else {
      dispatch(getUsersOffices(userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeIndex, userId]);

  const handleOfficeChange = (index: number) => {
    setOfficeIndex(index);
    setOffice(userOffices[index]);
  };

  const set = (office: Office) => {
    setOffice(office);
  };

  const handleLockedClick = () => {
    isFreeAccount && toggleAlert(!alertOpen);
  };

  const onSuccess = () => {
    setSnackBarMessage({
      severity: "success",
      message: successMessage,
      autoHideDuration: 6000,
    });
    setIsAlertOpen(true);
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsAlertOpen(false);
  };

  const dialogContent = (
    <Grid
      item
      container
      direction="row"
      justifyContent="space-evenly"
      alignContent="center"
      className={mobile ? classes.mobileDialog : ""}
    >
      <Grid item xs={2} sm={1} container alignContent="center">
        <LockIcon
          className={mobile ? classes.mobileDialogText : classes.dialogText}
          style={{ fontSize: 28 }}
        />
      </Grid>
      <Grid
        item
        container
        md={9}
        sm={8}
        xs={6}
        alignContent="center"
        justifyContent="center"
      >
        <Typography
          className={mobile ? classes.mobileDialogText : classes.dialogText}
        >
          Subscribe for access to document settings and exclusive order
          discounts.
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={4}
        sm={3}
        md={2}
        alignContent="center"
        justifyContent="flex-end"
      >
        <Button
          component={Link}
          href="/settings/billing"
          to="/settings/billing"
          className={mobile ? classes.mobileDialogButton : classes.dialogButton}
          variant="contained"
          disableElevation
        >
          Subscribe
        </Button>
      </Grid>
    </Grid>
  );

  const dialogContentStyle = {
    minHeight: 38,
    maxHeight: 76,
    alignContent: "center",
    padding: "24px 32px",
    backgroundColor: "#000000DE",
  };

  const mobileDialogContentStyle = {
    alignContent: "center",
    padding: "0px 6px",
    backgroundColor: "#000000DE",
  };

  const dialogStyle = {
    margin: "168px 5% 0 30%",
  };
  const mobileDialogStyle = {
    marginTop: 276,
  };

  return (
    <Grid container className={classes.root}>
      {isFreeAccount && (
        <Grid item container>
          <LockedSettingsToolbar />
        </Grid>
      )}

      {!office ? (
        <Grid>
          <Typography>Loading...</Typography>
        </Grid>
      ) : mobile ? (
        <Grid container alignContent="flex-start">
          <Grid item xs={12}>
            <MobileMenu
              setOffice={(index: number) => handleOfficeChange(index)}
              setOption={(component: ReactElement) => setComponent(component)}
              offices={userOffices}
              selectedOfficeIndex={officeIndex}
              navButtons={navButtons}
            />
          </Grid>
          <LockableElement
            locked={isFreeAccount}
            onClick={() => handleLockedClick()}
            notLockedFullWidth={true}
          >
            <Grid container item className={classes.onScreenComponent}>
              <Typography id="office-selected" className={classes.office}>
                {userOffices[officeIndex].officeName}
              </Typography>
              <OfficeContext.Provider value={{ office, set, isFreeAccount }}>
                {onScreenComponent}
              </OfficeContext.Provider>
              <LockedDialog
                open={alertOpen}
                dialogContent={dialogContent}
                openDialog={handleLockedClick}
                dialogContentStyleOverrides={mobileDialogContentStyle}
                dialogStyleOverrides={mobileDialogStyle}
                maxWidth={undefined}
                fullWidth={true}
              />
            </Grid>
          </LockableElement>
        </Grid>
      ) : (
        // desktop
        <Grid
          direction="row"
          container
          item
          className={classes.root}
          alignContent="stretch"
        >
          <Grid item xs={3} className={classes.sideMenu}>
            <SideMenu
              navButtonClicked={(component: ReactElement) =>
                setComponent(component)
              }
              setOffice={(index: number) => handleOfficeChange(index)}
              offices={userOffices}
              selectedOfficeIndex={officeIndex}
              navButtons={navButtons}
            />
          </Grid>

          <Grid container item xs={9}>
            <LockableElement
              locked={isFreeAccount}
              onClick={() => handleLockedClick()}
              notLockedFullWidth={true}
            >
              <Grid
                container
                item
                xs={12}
                className={classes.onScreenComponent}
                justifyContent="flex-end"
              >
                <Grid item xs={12}>
                  <Typography className={classes.office} id="office-selected">
                    {userOffices[officeIndex].officeName}
                  </Typography>
                  <OfficeContext.Provider
                    value={{ office, set, isFreeAccount }}
                  >
                    {onScreenComponent}
                  </OfficeContext.Provider>
                  <LockedDialog
                    open={alertOpen}
                    dialogContent={dialogContent}
                    openDialog={handleLockedClick}
                    dialogContentStyleOverrides={dialogContentStyle}
                    dialogStyleOverrides={dialogStyle}
                    maxWidth="xl"
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </LockableElement>
          </Grid>
          <SaveSnackBar
            isAlertOpen={isAlertOpen}
            handleSnackbarClose={handleSnackbarClose}
            snackBarMessage={snackBarMessage}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(DocumentSettingsPage);
