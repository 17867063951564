import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfirmationSelectionModal from '../ConfirmationSelectionModal/ConfirmationSelectionModal';
import { getProjectSharesPerProject, deleteProjectShares, createProjectShares } from '../../actions/ProjectSharesActions';

class ProjectShareModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userIdsCurrentlyBeingSharedOrUnshared: [],
            officeUsersSortedDisplay: [],
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.officeUsers !== this.props.officeUsers) {
            this.setDisplayOfficeUsers();
        }
    }

    setDisplayOfficeUsers = () => {
        const { projectSharesForThisProject } = this.props;
        const officeUsersSortedDisplay = this.props.officeUsers.map(user => ({
            id: user.id,
            displayString: user.displayString,
            isChecked: projectSharesForThisProject.some(x => x.userId === user.id),
            isCurrentlyFetching: this.state.userIdsCurrentlyBeingSharedOrUnshared.some(x => x === user.id),
        }));

        this.setState({ officeUsersSortedDisplay });
    };


    handleShareSelectionClick = async (event, user) => {
        event.preventDefault();

        const userIdsInTransit = this.state.userIdsCurrentlyBeingSharedOrUnshared.concat(user.id);

        this.setState({ userIdsCurrentlyBeingSharedOrUnshared: userIdsInTransit });
        if (!user.isChecked) {
            await this.props.dispatch(createProjectShares(this.props.projectId, user.id));
        } else {
            const projectShareToRemove = this.props.projectSharesForThisProject.find(o => o.userId === user.id);
            await this.props.dispatch(deleteProjectShares(this.props.projectId, projectShareToRemove.id));
        }

        await this.props.dispatch(getProjectSharesPerProject(this.props.projectId, 1, true));
        this.setState({ userIdsCurrentlyBeingSharedOrUnshared: this.state.userIdsCurrentlyBeingSharedOrUnshared.filter(o => o !== user.id) });
        this.setDisplayOfficeUsers();
    };

    render() {
        return (
            <ConfirmationSelectionModal
                title="Share Project"
                description="Click on someone in your office to share the project with them"
                isOpen={this.props.shareProjectSelectionOpen}
                handleClose={this.props.handleShareProjectClose}
                selectionOptions={this.state.officeUsersSortedDisplay}
                handleSelectionClick={this.handleShareSelectionClick}
                isCheckable
                closingVerbiage="Done"
            />
        );
    }
}

ProjectShareModal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    officeUsers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        displayString: PropTypes.string,
    })),
    shareProjectSelectionOpen: PropTypes.bool,
    projectSharesForThisProject: PropTypes.arrayOf(PropTypes.shape),
    projectId: PropTypes.string,
    handleShareProjectClose: PropTypes.func.isRequired,
};

ProjectShareModal.defaultProps = {
    officeUsers: [],
    shareProjectSelectionOpen: false,
    projectSharesForThisProject: [],
    projectId: '',
};

export default ProjectShareModal;
