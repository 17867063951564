import { createNewUser } from './User';
import { login } from './Auth';


export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

const signUpRequest = () => ({
    type: SIGN_UP_REQUEST,
});

const signUpSuccess = isNewUser => ({
    type: SIGN_UP_SUCCESS,
    isNewUser,
});

const signUpFailure = error => ({
    type: SIGN_UP_FAILURE,
    error,
});

/**
 * Create a new user.
 * @param {Object} newUser - The user being created.
 * @param {string} newUser.firstName - The first name of the user.
 * @param {string} newUser.lastName - The last name of the user.
 * @param {string} newUser.email - The email of the user.
 * @param {string} newUser.companyName - The company/business name of the user
 * @param {string} newUser.phone - The phone number of the user
 * @param {string} newUser.country - The phone number of the user
 */
export const signUp = newUser => async (dispatch) => {
    dispatch(signUpRequest());
    try {
        // If sign up failed, return the failed action.
        const signUpResult = await dispatch(createNewUser(newUser));
        if (signUpResult.error) {
            return dispatch(signUpFailure(signUpResult.error));
        }

        // If we did not get a password, don't try to log in. Just return the successful action.
        const { response } = signUpResult;
        if (response.password === null) {
            return dispatch(signUpSuccess(false));
        }

        const loginResult = await dispatch(login(response.username, response.password, true));
        if (loginResult.error) {
            return dispatch(signUpFailure(loginResult.error));
        }

        return dispatch(signUpSuccess(true));
    } catch (error) {
        return dispatch(signUpFailure(error));
    }
};
