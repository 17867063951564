import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Info from '@material-ui/icons/Info';

const style = {
    notice: {
        fontSize: '14px',
        padding: 10,
        border: '1px rgba(0,0,0,.2) solid',
        color: 'rgba(0,0,0,.5)',
        marginBottom: 15,
    },
    noticeIcon: {
        width: 20,
        height: 20,
        color: 'rgba(0,0,0,.5)',
        verticalAlign: 'middle',
    },
};

const Notice = ({ children, visible }) => (
    visible && (
        <Typography style={style.notice}>
            <Info style={style.noticeIcon} /> {children}
        </Typography>
    )
);

Notice.propTypes = {
    children: PropTypes.node.isRequired,
    visible: PropTypes.bool,
};

Notice.defaultProps = {
    visible: true,
};

export default Notice;
