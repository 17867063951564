import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const SketchOsCompleteActivationDialog = (props) => {
    const goToActivatePage = () => {
        props.history.push('/activate/billinginformation');
    };

    return (
        <Dialog open={props.open}>
            <DialogTitle id="sketchos-finish-activation-dialog">
                Finish Activation
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To purchase more sketches, you need to complete your profile. Complete your profile to activate your account.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onDismiss}>
                    Dismiss
                </Button>
                <Button onClick={goToActivatePage} color="primary">
                    Activate
                </Button>
            </DialogActions>
        </Dialog>
    );
};

SketchOsCompleteActivationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onDismiss: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

export default withRouter(SketchOsCompleteActivationDialog);
