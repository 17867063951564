const roofsnapTheme = {
    palette: {
        roofsnapBlue: {
            50: '#E0EBF5',
            100: '#B3CEE6',
            200: '#80AED5',
            300: '#4D8DC4',
            400: '#2674B7',
            500: '#005CAA',
            600: '#0054A3',
            700: '#004A99',
            800: '#004190',
            900: '#00307F',
            A100: '#2D6AFF',
            A200: '#2D6AFF',
            A400: '#2D6AFF',
            A700: '#2D6AFF',
            contrastDefaultColor: 'light',
        },
        backgroundGrey: {
            main: '#F2F2F2',
        },
    },
};

export default roofsnapTheme;
