import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const EmailIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox='0 0 32 32'>
        <path d='M5.41932 26C4.84643 26 4.35565 25.7957 3.94698 25.387C3.53832 24.9783 3.33398 24.4876 3.33398 23.9147V8.08533C3.33398 7.51244 3.53832 7.02167 3.94698 6.613C4.35565 6.20433 4.84643 6 5.41932 6H26.582C27.1549 6 27.6457 6.20433 28.0543 6.613C28.463 7.02167 28.6673 7.51244 28.6673 8.08533V23.9147C28.6673 24.4876 28.463 24.9783 28.0543 25.387C27.6457 25.7957 27.1549 26 26.582 26H5.41932ZM26.992 9.188L16.5723 16.011C16.479 16.0601 16.3868 16.1013 16.2957 16.1347C16.2043 16.168 16.106 16.1847 16.0007 16.1847C15.8953 16.1847 15.797 16.168 15.7057 16.1347C15.6145 16.1013 15.5251 16.0601 15.4373 16.011L5.00932 9.188V23.9147C5.00932 24.0342 5.04776 24.1324 5.12465 24.2093C5.20154 24.2862 5.29976 24.3247 5.41932 24.3247H26.582C26.7015 24.3247 26.7998 24.2862 26.8767 24.2093C26.9535 24.1324 26.992 24.0342 26.992 23.9147V9.188ZM16.0007 14.6307L26.7237 7.67533H5.29965L16.0007 14.6307ZM5.00932 9.45467V8.24833V8.284V7.67533V8.27267V8.22967V9.45467Z' />
    </SvgIcon>
);

export default EmailIcon;
