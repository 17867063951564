import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import dateFormat from 'dateformat';

class BillingFailedSketchOrdersBreadcrumb extends Component {
    sketchOrdersCrumb = () => (
        <li>
            <Button color="primary" onClick={() => { this.props.history.push('/orders'); }}>
                <div><span>Sketch Orders</span></div>
            </Button>
        </li>
    );

    billingFailedSketchOrdersCrumb = () => (
        <li>
            <Button color="primary" onClick={() => this.props.history.push('/billingfailedorders')}>
                <div><span>Billing Failed Orders</span></div>
            </Button>
        </li>
    );

    sketchOrderCrumb = () => {
        const sketchOrderName = this.props.projectName || `Sketch Order from ${dateFormat(this.props.requestedDate, 'shortDate')}`;
        return (
            <li>
                <Button color="primary" onClick={() => this.props.history.push(`/billingfailedorders/${this.props.sketchOrderId}`)}>
                    <div><span>{sketchOrderName}</span></div>
                </Button>
            </li>
        );
    };

    render() {
        const { path } = this.props.match;
        const crumbs = [
            <Route key="0" path={`${path}`} component={this.sketchOrdersCrumb} />,
            <Route key="1" path={`${path}`} component={this.billingFailedSketchOrdersCrumb} />,
            <Route key="2" path={`${path}/:sketchOrderId`} component={this.sketchOrderCrumb} />,
        ];
        return (
            crumbs
        );
    }
}

BillingFailedSketchOrdersBreadcrumb.propTypes = {
    sketchOrderId: PropTypes.number,
    projectName: PropTypes.string,
    requestedDate: PropTypes.string,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }).isRequired,
};

BillingFailedSketchOrdersBreadcrumb.defaultProps = {
    sketchOrderId: 0,
    projectName: '',
    requestedDate: '',
};

const mapStateToProps = (state) => {
    const { id, projectName, requestedDate } = state.sketchOrder;

    return {
        sketchOrderId: id,
        projectName,
        requestedDate,
    };
};

export default withRouter(connect(mapStateToProps)(BillingFailedSketchOrdersBreadcrumb));
