import { Grid, GridJustification } from '@material-ui/core';
import React from 'react'
import { Node } from 'typescript';

interface CustomTabLabelProps {
    label?: string | Node | undefined,
    labelStyle?: string | undefined
    tabStyle?: string | undefined,
    badgeLabel?: string | Node | undefined,
    badgeStyle?: string | undefined,
    badgePosition?: GridJustification | undefined
}

const CustomTabLabel = (props: CustomTabLabelProps) => {
    const { label, badgeLabel, badgeStyle, badgePosition, labelStyle } = props;

    return (
        <Grid item container direction='column' data-cy='tab-label'>
            <Grid container item xs={12} style={{minHeight: 17}}>
                {badgeLabel &&
                    <Grid container item xs={12} justifyContent={badgePosition ?? 'flex-end'}>
                        <Grid item className={badgeStyle} data-cy='tab-badge'>
                            {badgeLabel}
                        </Grid>
                    </Grid>
                }
                </Grid>
                <Grid item container xs={12} justifyContent='center' className={labelStyle} alignItems='center' style={{ flexGrow: badgeLabel ? 0 : 1 }}>
                    {label}
                </Grid>
            </Grid>
            )
}

            export default CustomTabLabel;