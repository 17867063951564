import LoggerWrapper from '../lib/Logger';

let localStorageAvailable = true;
let storage = {};

export default class LocalStorageWrapper {
    static getItem(key) {
        // If we know local storage is not available,
        // then short circuit and return a value from our storage object
        // TODO: determine if this needs to be replaced or if it can just go away
        // if (!localStorageAvailable) {
        //     return storage[key];
        // }

        try {
            // Attempt to get object from localStorage
            const retrievedObject = localStorage.getItem(key);
            // Parse the object as a JSON object
            return JSON.parse(retrievedObject);
        } catch (error) {
            // If we fail, flip the localStorageAvailable flag to off
            // and log error
            localStorageAvailable = false;
            LoggerWrapper.captureException(error);

            // return what we have from our storage object
            return storage[key];
        }
    }

    static setItem(key, value) {
        // If we know local storage is not available,
        // then short circuit and set the value in our own storage object
        if (!localStorageAvailable) {
            storage[key] = value;
            return;
        }

        try {
            // Stringify our value to a json string
            const jsonString = JSON.stringify(value);

            // Attempt to set the item in local storage
            localStorage.setItem(key, jsonString);
        } catch (error) {
            // If we fail, flip the localStorageAvailable flag to off
            // and log error
            localStorageAvailable = false;
            LoggerWrapper.captureException(error);

            // Set the value in our own storage object
            storage[key] = value;
        }
    }

    static removeItem(key) {
        // If we know local storage is not available,
        // then short circuit and remove value from our storage object
        // TODO: determine if this needs to be replaced or if it can just go away
        // if (!localStorageAvailable) {
        //     delete storage[key];
        //     return;
        // }
        try {
            // attempt to remove kvp from localStorage
            localStorage.removeItem(key);
        } catch (error) {
            localStorageAvailable = false;
            LoggerWrapper.captureException(error);
        }
    }

    static clear() {
        // If we know local storage is not available,
        // then short circuit and reset our storage object
        if (!localStorageAvailable) {
            storage = {};
            return;
        }

        try {
            // Attempt to clear out localStorage
            localStorage.clear();
        } catch (error) {
            // If we fail, flip the localStorageAvailable flag to off
            // and log error
            localStorageAvailable = false;
            LoggerWrapper.captureException(error);

            // Reset our storage object
            storage = {};
        }
    }
}
