import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ConfirmationSelectionModal from '../ConfirmationSelectionModal/ConfirmationSelectionModal';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { updateProjectOwner } from '../../actions/ProjectActions';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';

class ProjectGiveModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userProjectIsBeingGivenTo: {
                userId: null,
                userDisplayName: null,
                isChecked: null,
            },
            giveProjectSelectionOpen: this.props.giveProjectModalIsOpen,
            confirmGiveProjectOpen: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.giveProjectModalIsOpen !== this.props.giveProjectModalIsOpen) {
            this.setGiveProjectModalIsOpen();
        }
    }

    setGiveProjectModalIsOpen = () => {
        this.setState({ giveProjectSelectionOpen: this.props.giveProjectModalIsOpen });
    };

    giveProject = async () => {
        const response = await this.props.dispatch(updateProjectOwner(this.props.projectId, this.state.userProjectIsBeingGivenTo.userId));
        if (response.error) {
            this.setState({ confirmGiveProjectOpen: false });
            return this.props.dispatch(setReduxMessage(`We were unable to give the project to ${this.state.userProjectIsBeingGivenTo.userDisplayName}, please make sure you are giving it to someone in the same office`));
        }
        this.props.dispatch(setReduxMessage(`The project was successfully given to ${this.state.userProjectIsBeingGivenTo.userDisplayName}`));
        this.setState({ confirmGiveProjectOpen: false });
        return this.props.handleCloseGiveModal(false);
    };

    handleGiveProjectSelectionClose = (e) => {
        e.preventDefault();
        this.setState({ giveProjectSelectionOpen: false });
        this.props.handleCloseGiveModal(true);
    };

    handleGiveProjectConfirmationClose = (e) => {
        e.preventDefault();
        this.setState({ confirmGiveProjectOpen: false });
        this.props.handleCloseGiveModal(true);
    };

    handleGiveSelectionClick = (e, user) => {
        e.preventDefault();
        this.setState({
            userProjectIsBeingGivenTo: {
                userId: user.id,
                userDisplayName: user.displayString,
            },
            giveProjectSelectionOpen: false,
            confirmGiveProjectOpen: true,
        });
    };

    render() {
        return (
            <div>
                <ConfirmationSelectionModal
                    title="Give Project"
                    description="Click on someone in your office to give the project to them"
                    isOpen={this.state.giveProjectSelectionOpen}
                    handleClose={this.handleGiveProjectSelectionClose}
                    selectionOptions={this.props.officeUsers}
                    handleSelectionClick={this.handleGiveSelectionClick}
                    isCheckable={false}
                />
                <ConfirmationModal
                    title="Give Project"
                    description={this.state.userProjectIsBeingGivenTo ? `Are you sure you want to give this project to ${this.state.userProjectIsBeingGivenTo.userDisplayName}` : ''}
                    handleConfirm={this.giveProject}
                    confirmText="Give"
                    isOpen={this.state.confirmGiveProjectOpen}
                    handleClose={this.handleGiveProjectConfirmationClose}
                />
            </div>
        );
    }
}

ProjectGiveModal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    giveProjectModalIsOpen: PropTypes.bool.isRequired,
    handleCloseGiveModal: PropTypes.func.isRequired,
    projectId: PropTypes.string,
    officeUsers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        displayString: PropTypes.string,
    })),
};

ProjectGiveModal.defaultProps = {
    officeUsers: [],
    projectId: '',
};

export default ProjectGiveModal;
