import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import dateFormat from 'dateformat';

interface MeasurementOrderBreadcrumbProps extends RouteComponentProps {
    measurementOrderId: number;
    projectName: string;
    requestedDate: string;
}

class MeasurementOrderBreadcrumb extends Component<MeasurementOrderBreadcrumbProps> {
    measurementOrdersCrumb = () => (
        <li>
            <Button
                color='primary'
                onClick={() => {
                    this.props.history.push('/orders');
                }}
            >
                <div>
                    <span>Measurement Orders</span>
                </div>
            </Button>
        </li>
    );

    measurementOrderCrumb = () => {
        const measurementOrderName =
            this.props.projectName ||
            `Measurement Order from ${dateFormat(
                this.props.requestedDate,
                'shortDate'
            )}`;
        return (
            <li>
                <Button
                    color='primary'
                    onClick={() => {
                        this.props.history.push(
                            `/orders/${this.props.measurementOrderId}`
                        );
                    }}
                >
                    <div>
                        <span>{measurementOrderName}</span>
                    </div>
                </Button>
            </li>
        );
    };

    createMeasurementOrderCrumb = (props: RouteComponentProps) => {
        return (
            <li>
                <Button
                    color='primary'
                    onClick={() => {
                        this.props.history.push(props.match.path);
                    }}
                >
                    <div>
                        <span>New Measurement Order</span>
                    </div>
                </Button>
            </li>
        );
    };

    renderCrumbs = () => {
        const { path } = this.props.match;
        const isV2 = this.props.location.pathname.startsWith('/orders/v2/');
        const crumbs = [
            <Route
                key='0'
                path={`${path}`}
                component={this.measurementOrdersCrumb}
            />,
        ];

        if (
            this.props.location.pathname.startsWith(`${path}/create`) ||
            this.props.location.pathname.startsWith(
                '/orders/v2/create/complete'
            )
        ) {
            let newPath = isV2 ? `${path}/v2/create` : `${path}/create`;
            crumbs.push(
                <Route
                    key='1'
                    path={newPath}
                    component={this.createMeasurementOrderCrumb}
                />
            );
        } else {
            crumbs.push(
                <Route
                    key='1'
                    path={`${path}/:measurementOrderId`}
                    component={this.measurementOrderCrumb}
                />
            );
        }

        return crumbs;
    };

    render() {
        return this.renderCrumbs();
    }
}

const mapStateToProps = (state: any) => {
    const { id, projectName, requestedDate } = state.sketchOrder;

    return {
        measurementOrderId: id,
        projectName,
        requestedDate,
    };
};

export default withRouter(connect(mapStateToProps)(MeasurementOrderBreadcrumb));
