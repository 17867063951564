import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';

const style = {
    div: {
        display: 'inline-block',
    },
};

const DeleteIconComponent = props => (
    <Tooltip
        title={props.disabled ? props.disabledText : props.tooltip}
        placement="bottom"
    >
        <div style={style.div}>
            <IconButton
                className="deleteButton"
                onClick={props.deleteCallBack}
                disabled={props.disabled}
                aria-label="Delete"
            >
                <DeleteIcon nativeColor={props.disabled ? 'rgb(128,128,128,0.54)' : props.color} />
            </IconButton>
        </div>
    </Tooltip>
);

DeleteIconComponent.propTypes = {
    tooltip: PropTypes.string,
    disabled: PropTypes.bool,
    disabledText: PropTypes.string,
    deleteCallBack: PropTypes.func.isRequired,
    color: PropTypes.string,
};

DeleteIconComponent.defaultProps = {
    tooltip: 'Delete',
    disabled: false,
    disabledText: 'Sorry, you are not allowed to delete this item.',
    color: 'rgb(0,0,0,0.54)',
};

export default DeleteIconComponent;
