import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    AccountExpiredSneakPeakForbiddenDialog,
    AccountExpiredForbiddenDialog,
    AccountInactiveForbiddenDialog,
    FeatureDisabledForbiddenDialog,
    NoAccessForbiddenDialog,
    SketchTechNoAccessForbiddenDialog,
} from '../ForbiddenDialog';
import { setDesiredRoute } from '../../actions/Routes';
import { isMobile } from '../../lib/HelperFunctions';
import MobileWarning from '../MobileWarning';
import OrganizationStatus from '../../lib/OrganizationStatus';
import SomethingWentWrong from '../SomethingWentWrong';
import LocalStorageWrapper from '../../lib/LocalStorageWrapper';
import { ExpiredDialogChoices } from '../../lib/ExpiredDialogChoices';

export class ProtectedRoute extends React.Component {
    componentDidMount() {
        this.props.dispatch(
            setDesiredRoute(
                this.props.location.pathname + this.props.location.search
            )
        );
    }

    isUserAllowedToAccessSketchOS = () =>
        this.props.organizationFeatures.sketchService ||
        this.props.organization.status === OrganizationStatus.SneakPeak;

    hasValidUserData = () =>
        this.props.currentUser &&
        this.props.currentUser.userId &&
        this.props.currentUser.active !== null;
    hasValidOrganizationData = () =>
        this.props.organization && this.props.organization.id;

    hasValidData = () => {
        if (!this.hasValidUserData()) {
            return false;
        }

        return this.hasValidOrganizationData();
    };
    shouldShowExpiredDialog = (expiredDialogSeen) => {
        // should we show the user the expired account dialog
        let shouldShowExpiredDialog = false;
        if (!expiredDialogSeen) shouldShowExpiredDialog = true;
        if (expiredDialogSeen) {
            if (
                expiredDialogSeen.userId !== this.props.currentUser.userId &&
                this.props.organization.subscriptionExpired &&
                (!expiredDialogSeen.dialogSeen ||
                    expiredDialogSeen.choice !==
                        ExpiredDialogChoices.OrderAsNeeded)
            )
                shouldShowExpiredDialog = true;
        }
        return shouldShowExpiredDialog;
    };

    render() {
        const { auth, organizationFeatures, currentUser } = this.props;
        const expiredDialogSeenObj = LocalStorageWrapper.getItem(
            'rs.expiredDialogSeen'
        );

        // User Not Authenticated
        if (!auth.isAuthenticated) {
            return <Redirect to='/login' />;
        }

        // Check and make sure we have valid user and organization data
        if (!this.hasValidData()) {
            return <SomethingWentWrong />;
        }

        // Is the organization a free account?
        if (this.props.organization.isFreeAccount) {
            const userAllowedToAccessApp =
                this.props.currentUser.allowedRoofSnapPlatforms &&
                this.props.currentUser.allowedRoofSnapPlatforms.indexOf(
                    'DeceptiveDolphin'
                ) > -1;
            // Can the user access this platform?
            if (!userAllowedToAccessApp) {
                return <AccountExpiredForbiddenDialog />;
            }
            if (!this.props.activationWorkflow) {
                if (this.shouldShowExpiredDialog(expiredDialogSeenObj)) {
                    return <Redirect to='/expired' />;
                }
            }
        } else if (this.props.organization.subscriptionExpired) {
            // If the organization is in the sneak peak status, redirect to get them to activate
            if (
                this.props.organization.status === OrganizationStatus.SneakPeak
            ) {
                // TODO: evaluate if it is even possible to hit this with the current definition of what constitutes isFreeAccount
                // If trial account is expired and they have not seen the expired dialog, show them the expired dialog
                if (!this.props.activationWorkflow) {
                    if (this.shouldShowExpiredDialog(expiredDialogSeenObj)) {
                        return <Redirect to='/expired' />;
                    }
                    return <AccountExpiredSneakPeakForbiddenDialog />;
                }
            } else {
                return <AccountExpiredForbiddenDialog />;
            }
        }

        // if the org is not expired, but they have seen the dialog, flip the bool so that if they go expired again they see it again
        if (
            !this.props.organization.subscriptionExpired &&
            expiredDialogSeenObj
        ) {
            LocalStorageWrapper.removeItem('rs.expiredDialogSeen');
        }

        // User Not Active
        if (currentUser.active === false) {
            return <AccountInactiveForbiddenDialog />;
        }

        // User or Organization does not have access to estimating feature.
        if (
            this.props.estimatingFeatureRequired &&
            !organizationFeatures.estimating
        ) {
            return (
                <FeatureDisabledForbiddenDialog
                    isAccountAdmin={this.props.currentUserRoles.isAccountAdmin}
                />
            );
        }
        if (
            this.props.estimatingFeatureRequired &&
            this.props.organization.isFreeAccount
        ) {
            return (
                <FeatureDisabledForbiddenDialog
                    isAccountAdmin={this.props.currentUserRoles.isAccountAdmin}
                />
            );
        }

        // Determine if User or Organization has access to sketchOs
        if (this.props.sketchFeatureRequired) {
            const userIsAllowedToAccessSketchOS =
                this.isUserAllowedToAccessSketchOS();

            if (!userIsAllowedToAccessSketchOS) {
                return (
                    <FeatureDisabledForbiddenDialog
                        isAccountAdmin={
                            this.props.currentUserRoles.isAccountAdmin
                        }
                    />
                );
            }
        }

        // User does not have access to this page.
        if (
            (this.props.accountAdminRequired &&
                !this.props.currentUserRoles.isAccountAdmin) ||
            (this.props.adminRequired && !this.props.currentUserRoles.isAdmin)
        ) {
            return <NoAccessForbiddenDialog />;
        }

        if (
            !this.props.currentUserRoles.isAdmin &&
            (this.props.currentUserRoles.isSketchAdmin ||
                this.props.currentUserRoles.isSketchTech)
        ) {
            return <SketchTechNoAccessForbiddenDialog />;
        }

        // Mobile Not Supported
        if (this.props.showMobileWarning && isMobile()) {
            return (
                <MobileWarning
                    path={this.props.path}
                    component={this.props.component}
                    projectId={this.props.computedMatch.params.id}
                />
            );
        }

        return (
            <Route
                exact={this.props.exact}
                path={this.props.path}
                component={this.props.component}
            />
        );
    }
}

ProtectedRoute.propTypes = {
    dispatch: PropTypes.func.isRequired,
    computedMatch: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired,
    }).isRequired,
    component: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    auth: PropTypes.shape({
        isAuthenticated: PropTypes.bool.isRequired,
    }).isRequired,
    organizationFeatures: PropTypes.shape({
        estimating: PropTypes.bool,
        sketchService: PropTypes.bool,
    }).isRequired,
    organization: PropTypes.shape({
        subscriptionExpired: PropTypes.bool,
        status: PropTypes.string,
        addOnsDisabled: PropTypes.bool,
        isFreeAccount: PropTypes.bool,
    }).isRequired,
    currentUser: PropTypes.shape({
        isActive: PropTypes.bool,
        allowedRoofSnapPlatforms: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    estimatingFeatureRequired: PropTypes.bool,
    sketchFeatureRequired: PropTypes.bool,
    showMobileWarning: PropTypes.bool,
    adminRequired: PropTypes.bool,
    accountAdminRequired: PropTypes.bool,
    activationWorkflow: PropTypes.bool,
    currentUserRoles: PropTypes.shape({
        isAccountAdmin: PropTypes.bool.isRequired,
        isAdmin: PropTypes.bool.isRequired,
        sketchosEnabled: PropTypes.bool.isRequired,
        isSketchTech: PropTypes.bool.isRequired,
        isSketchAdmin: PropTypes.bool.isRequired,
    }).isRequired,
    exact: PropTypes.bool,
};

ProtectedRoute.defaultProps = {
    sketchFeatureRequired: false,
    estimatingFeatureRequired: false,
    showMobileWarning: false,
    adminRequired: false,
    accountAdminRequired: false,
    activationWorkflow: false,
    exact: false,
};

const mapStateToProps = (state) => {
    const {
        auth,
        organizationFeatures,
        organization,
        currentUser,
        currentUserRoles,
        features,
    } = state;

    return {
        auth,
        organizationFeatures,
        organization,
        currentUser,
        currentUserRoles,
        features,
    };
};

export default connect(mapStateToProps)(ProtectedRoute);
