import {
  resetPageToolbar,
  setPageToolbarNavTabs,
} from "actions/RoofSnapPageToolbarActions";
import React, {
  PropsWithChildren,
  useEffect,
  CSSProperties,
  ReactElement,
} from "react";
import { useDispatch, useSelector } from "react-redux";

type PageToolbarNavTabsProps = {
  children?: ReactElement<TabPanelProps>[];
  navTabs: {
    activeTabIndex: number;
    tabs: {
      title: string;
      index: number;
    }[];
  };
};

export type TabPanelProps = PropsWithChildren<{
  index: number;
  style?: CSSProperties | undefined;
}>;

export const TabPanel = ({ children, index, style }: TabPanelProps) => {
  const activeTabIndex = useSelector(
    (state: any) => state.roofSnapPageToolbar.navTabs?.activeTabIndex
  );

  return (
    <div style={style} role="tabpanel" hidden={activeTabIndex !== index}>
      {children}
    </div>
  );
};

const PageToolbarNavTabs = (props: PageToolbarNavTabsProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageToolbarNavTabs(props.navTabs));

    return () => {
      dispatch(resetPageToolbar());
    };
  }, [props.navTabs, dispatch]);



  return (
    <>
      {props.children}
    </>
  );
};

export default PageToolbarNavTabs;
