import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { putEstimateItem } from '../../../actions/ProjectEstimateItemActions';
import { getProjectEstimateItems } from '../../../actions/ProjectEstimateItemsActions';
import { getProjectEstimate } from '../../../actions/ProjectEstimate';
import EditEstimateItemDialog from './EditEstimateItemDialog';
import EditEstimateItemModelFactory from '../../../lib/Factories/EditEstimateItemModelFactory';
import { getProjectDrawingById } from '../../../actions/ProjectDrawingActions';

class EditEstimateItems extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        // Everytime we get this component, we need to make sure we have the latest project drawing
        this.props.dispatch(getProjectDrawingById(props.projectId));
    }

    componentWillReceiveProps(props) {
        if (!isEmpty(props.estimateItem) && !props.editItemLoading) {
            const estimateItem = EditEstimateItemModelFactory.create(props.estimateItem, props.projectDrawing, props.allowedToEditEstimateItems);
            this.setState({ ...estimateItem }, () => {
                this.calculateGrossProfitMargin(
                    this.massageCurrency(this.state.totalPerUnit),
                    this.massageCurrency(this.state.laborCost),
                    this.massageCurrency(this.state.materialCost),
                    this.massageCurrency(this.state.subItemMaterialCost),
                );
            });
        }
    }

    submitEditEstimate = (e) => {
        e.preventDefault();
        this.props
            .dispatch(putEstimateItem(
                this.props.projectId,
                this.state.id,
                this.state,
                this.state.version,
            ))
            .then(() => {
                this.props.closeDialog();
                this.props.dispatch(getProjectEstimate(this.props.projectId, this.state.estimateId));
                this.props.dispatch(getProjectEstimateItems(
                    this.props.projectId,
                    this.state.estimateId,
                    1,
                    true,
                ));
            });
    };

    handleDialogChange = name => (event) => {
        this.setState(
            {
                [name]: event.target.value,
            },
            () => {
                if (
                    name === 'totalPerUnit' ||
                    name === 'laborCost' ||
                    name === 'materialCost'
                ) {
                    this.calculateGrossProfitMargin(
                        this.massageCurrency(this.state.totalPerUnit),
                        this.massageCurrency(this.state.laborCost),
                        this.massageCurrency(this.state.materialCost),
                        this.massageCurrency(this.state.subItemMaterialCost),
                    );
                }
            },
        );
    };

    massageCurrency = (currency) => {
        if (Number.isNaN(currency)) {
            return 0;
        } else if (typeof currency === 'string') {
            return currency ? parseFloat(currency.replace(/,/g, '')) : 0;
        }
        return currency;
    };
    switchInput = name => (event, checked) => {
        event.preventDefault();
        this.setState({ [name]: !checked });
    };

    calculateGrossProfitMargin = (
        totalPerUnit,
        laborCost,
        materialCost,
        subItemMaterialCost,
    ) => {
        if (totalPerUnit === 0) {
            return this.setState({ grossProfitMargin: '' });
        }
        let decimal =
            (totalPerUnit - (laborCost + materialCost + subItemMaterialCost)) /
            totalPerUnit;
        decimal *= 100;
        return this.setState({
            grossProfitMargin:
                Number.isNaN(decimal) || decimal < -99 || decimal === 100
                    ? ''
                    : Math.floor(decimal),
        });
    };

    render() {
        return (
            <div>
                <EditEstimateItemDialog
                    open={this.props.editEstimateItemDialogOpen}
                    handleClose={this.props.closeDialog}
                    handleSubmit={this.submitEditEstimate}
                    description={this.state.description}
                    handleChange={this.handleDialogChange}
                    handleCurrencyChange={this.handleCurrencyChange}
                    units={this.state.units}
                    laborCost={this.state.laborCost}
                    materialCost={this.state.materialCost}
                    totalPerUnit={this.state.totalPerUnit}
                    materialOrderDescription={
                        this.state.materialOrderDescription
                    }
                    coveragePerUnit={this.state.coveragePerUnit}
                    hideOnEstimate={!this.state.hideOnEstimate}
                    hideOnContract={!this.state.hideOnContract}
                    hideOnMaterialOrder={!this.state.hideOnMaterialOrder}
                    hideOnLaborReport={!this.state.hideOnLaborReport}
                    switchInput={this.switchInput}
                    grossProfitMargin={this.state.grossProfitMargin}
                    loading={this.props.editItemLoading}
                    canEditItem={this.state.canEditItem}
                    unitsEnabled={this.state.unitsEnabled}
                    laborCostVisible={this.state.laborCostVisible}
                    materialCostVisible={this.state.materialCostVisible}
                />
            </div>
        );
    }
}

const estimateItemProps = {
    id: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    version: PropTypes.string,
    projectId: PropTypes.string,
    unitType: PropTypes.string,
    categoryId: PropTypes.string,
    itemType: PropTypes.string,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    units: PropTypes.number,
    coveragePerUnit: PropTypes.number,
    total: PropTypes.number,
    materialItemId: PropTypes.number,
    estimateId: PropTypes.string,
    laborCost: PropTypes.number,
    materialCost: PropTypes.number,
    materialOrderDescription: PropTypes.string,
    hideOnEstimate: PropTypes.bool,
    hideOnContract: PropTypes.bool,
    hideOnMaterialOrder: PropTypes.bool,
    hideOnLaborReport: PropTypes.bool,
    totalPerUnit: PropTypes.number,
    subItemMaterialCost: PropTypes.number,
};

EditEstimateItems.propTypes = {
    estimateItem: PropTypes.shape(estimateItemProps),
    closeDialog: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    editItemLoading: PropTypes.bool.isRequired,
    projectId: PropTypes.string.isRequired,
    projectDrawing: PropTypes.shape({
        measurements: PropTypes.shape({
            overrideProjectMeasurements: PropTypes.bool,
        }),
    }),
    allowedToEditEstimateItems: PropTypes.bool,
    editEstimateItemDialogOpen: PropTypes.bool,
};

EditEstimateItems.defaultProps = {
    estimateItem: {},
    projectDrawing: {
        measurements: {
            overrideProjectMeasurements: false,
        },
    },
    allowedToEditEstimateItems: false,
    editEstimateItemDialogOpen: false,
};

const mapStateToProps = (state) => {
    const { currentUser } = state;
    const { allowedToEditEstimateItems } = currentUser;
    return {
        ...state.projectEstimateItem,
        ...state.projectDrawing,
        allowedToEditEstimateItems,
    };
};

export default connect(mapStateToProps)(EditEstimateItems);
