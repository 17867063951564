import { CALL_API, httpMethod } from '../middleware/api';
import { SET_REDUX_MESSAGE } from './ReduxMessagesActions';
import responseBodyTypes from '../middleware/responseBodyTypes';

export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';

export function setCurrentProject(project) {
    return (dispatch) => {
        dispatch({
            type: SET_CURRENT_PROJECT,
            currentProject: project,
        });
    };
}

export const GET_PROJECT_REQUEST = 'GET_PROJECT_REQUEST';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_FAILURE = 'GET_PROJECT_FAILURE';

export const getProjectById = id => ({
    [CALL_API]: {
        types: [GET_PROJECT_REQUEST, GET_PROJECT_SUCCESS, GET_PROJECT_FAILURE],
        endpoint: `projects/${id}`,
        method: httpMethod.GET,
    },
});

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';

export const deleteProjectById = id => ({
    [CALL_API]: {
        types: [
            DELETE_PROJECT_REQUEST,
            DELETE_PROJECT_SUCCESS,
            DELETE_PROJECT_FAILURE,
        ],
        endpoint: `projects/${id}`,
        method: httpMethod.DELETE,
    },
});

export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';

const fetchCreateProject = project => ({
    [CALL_API]: {
        types: [
            CREATE_PROJECT_REQUEST,
            CREATE_PROJECT_SUCCESS,
            CREATE_PROJECT_FAILURE,
            SET_REDUX_MESSAGE,
        ],
        endpoint: 'projects',
        method: httpMethod.POST,
        body: project,
    },
});

export function createProject(project) {
    return dispatch =>
        dispatch(fetchCreateProject(project)).then((result) => {
            if (result && result.response) {
                dispatch(setCurrentProject(result.response));
            }
            return result;
        });
}

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE';

export const putProjectDetails = project => ({
    [CALL_API]: {
        types: [
            UPDATE_PROJECT_REQUEST,
            UPDATE_PROJECT_SUCCESS,
            UPDATE_PROJECT_FAILURE,
            SET_REDUX_MESSAGE,
        ],
        endpoint: `projects/${project.id}`,
        method: httpMethod.PUT,
        body: project,
        header: { version: project.version },
    },
});

export function updateProjectDetails(project) {
    return dispatch =>
        dispatch(putProjectDetails(project)).then((result) => {
            if (!result.error) {
                dispatch(setCurrentProject(result.response));
            }
            return result;
        });
}

export const UPDATE_PROJECT_OWNER_REQUEST = 'UPDATE_PROJECT_OWNER_REQUEST';
export const UPDATE_PROJECT_OWNER_SUCCESS = 'UPDATE_PROJECT_OWNER_SUCCESS';
export const UPDATE_PROJECT_OWNER_FAILURE = 'UPDATE_PROJECT_OWNER_FAILURE';

const putProjectOwner = (projectId, userId) => ({
    [CALL_API]: {
        types: [
            UPDATE_PROJECT_OWNER_REQUEST,
            UPDATE_PROJECT_OWNER_SUCCESS,
            UPDATE_PROJECT_OWNER_FAILURE,
            SET_REDUX_MESSAGE,
        ],
        endpoint: `projects/${projectId}/owner`,
        method: httpMethod.PUT,
        body: { userId },
    },
});

export function updateProjectOwner(projectId, userId) {
    return async (dispatch) => {
        const result = await dispatch(putProjectOwner(projectId, userId));
        if (!result.error) {
            dispatch(setCurrentProject(result.response));
        }
        return result;
    };
}

export const UPDATE_PROJECT_MAP_IMAGE_REQUEST =
    'UPDATE_PROJECT_MAP_IMAGE_REQUEST';
export const UPDATE_PROJECT_MAP_IMAGE_SUCCESS =
    'UPDATE_PROJECT_MAP_IMAGE_SUCCESS';
export const UPDATE_PROJECT_MAP_IMAGE_FAILURE =
    'UPDATE_PROJECT_MAP_IMAGE_FAILURE';

export const updateProjectRoofImage = (
    projectId,
    version,
    image,
    scale,
    lat,
    lng,
) => ({
    [CALL_API]: {
        types: [
            UPDATE_PROJECT_MAP_IMAGE_REQUEST,
            UPDATE_PROJECT_MAP_IMAGE_SUCCESS,
            UPDATE_PROJECT_MAP_IMAGE_FAILURE,
            SET_REDUX_MESSAGE,
        ],
        endpoint: `projects/${projectId}/roofimage`,
        method: httpMethod.PUT,
        body: {
            ProjectId: projectId,
            MetersPerPixel: scale,
            CenterCoordinate: {
                Latitude: lat,
                Longitude: lng,
            },
            Image: image,
        },
        header: { version },
    },
});

export const EXPORT_PROJECT_CSV_REQUEST = 'EXPORT_PROJECT_CSV_REQUEST';
export const EXPORT_PROJECT_CSV_SUCCESS = 'EXPORT_PROJECT_CSV_SUCCESS';
export const EXPORT_PROJECT_CSV_FAILURE = 'EXPORT_PROJECT_CSV_FAILURE';

export const exportProjectAsCsv = projectId => ({
    [CALL_API]: {
        types: [EXPORT_PROJECT_CSV_REQUEST, EXPORT_PROJECT_CSV_SUCCESS, EXPORT_PROJECT_CSV_FAILURE],
        endpoint: `projects/${projectId}/exportcsv`,
        method: httpMethod.POST,
        responseBodyType: responseBodyTypes.BLOB,
    },
});

export const CLONE_PROJECT_REQUEST = 'CLONE_PROJECT_REQUEST';
export const CLONE_PROJECT_SUCCESS = 'CLONE_PROJECT_SUCCESS';
export const CLONE_PROJECT_FAILURE = 'CLONE_PROJECT_FAILURE';

export const cloneProject = (projectId, userId, projectName) => ({
    [CALL_API]: {
        types: [CLONE_PROJECT_REQUEST, CLONE_PROJECT_SUCCESS, CLONE_PROJECT_FAILURE],
        endpoint: `projects/${projectId}/clonedprojects`,
        method: httpMethod.POST,
        body: {
            UserId: userId,
            Name: projectName,
        },
    },
});
