import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    wrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        height: '100%',
        width: '100%',
        zIndex: theme.zIndex.appBar + 1,
        backgroundColor: theme.palette.primary.main,
        position: 'fixed',
        top: 0,
    },
    card: {
        padding: 20,
        margin: theme.spacing(2),
    },
    roofsnapLogoWrapper: {
        textAlign: 'center',
        maxWidth: 300,
        margin: 'auto',
    },
    roofsnapLogo: {
        paddingBottom: theme.spacing(4),
        width: '100%',
    },
    title: {
        paddingBottom: theme.spacing(2),
    },
});

const RegisterComplete = props => (
    <div className={props.classes.wrapper}>
        <Card className={props.classes.card}>
            <CardContent>
                <div className={props.classes.roofsnapLogoWrapper}>
                    <img className={props.classes.roofsnapLogo} src="/assets/register-email-sent.png" alt="RoofSnap logo" />
                </div>
                <Typography className={props.classes.title} variant="h5">
                    You&#39;re almost there!
                </Typography>
                <Typography gutterBottom>
                    Check your email for steps on completing your registration.
                </Typography>
                <br />
                <Typography variant="caption">
                    Didn&#39;t receive an email? <a href={`${process.env.REACT_APP_SUBMIT_SUPPORT_REQUEST_URL}`} rel="noopener noreferrer" target="_blank">Click here</a> to contact support.
                </Typography>
                <Typography variant="caption">
                    Or you can contact support at support@roofsnap.com or (877) 766-3762.
                </Typography>
            </CardContent>
        </Card>
    </div>
);

RegisterComplete.propTypes = {
    classes: PropTypes.shape({
        wrapper: PropTypes.string.isRequired,
        card: PropTypes.string.isRequired,
    }).isRequired,
};

export default withStyles(styles)(RegisterComplete);
