import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ForbiddenDialog from './ForbiddenDialog';

const NoAccessForbiddenDialog = ({ history }) => {
    const navigateBack = () => {
        history.push('/projects');
    };

    return (<ForbiddenDialog
        confirmationAction={navigateBack}
        forbiddenDialogTitle="No Access"
        forbiddenDialogContentText="You do not have access to this feature. Please contact your account administrator for assistance. "
    />);
};

NoAccessForbiddenDialog.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default withRouter(NoAccessForbiddenDialog);
