import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { createStyles, withStyles, WithStyles, Grid, Typography, useTheme, useMediaQuery, Theme } from '@material-ui/core';
import FormattedDate from 'lib/util/FormattedDate';
import OrderStatusChip from './OrderStatusChip';
import SubscriptionTransaction from 'lib/Models/SubscriptionTransaction';
import { DataRow } from 'components/DataTable/DataTable';
import { ChipStatusMap } from 'lib/BraintreeTransactionStatus';

interface SubscriptionRowProps
    extends RouteComponentProps,
    WithStyles<typeof styles> {
    transaction: SubscriptionTransaction;
    subscriptionId: string;
}

const styles = (theme: Theme) => createStyles({
    columnContainer: {
        maxWidth: '90vw',
        padding: 0,
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            padding: 10
        }
    },
    rowText: {
        color: '#000000DE',
        fontSize: 14,
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            padding: 4
        }
    },
})


const SubscriptionRow = (props: SubscriptionRowProps) => {
    const { classes, transaction, subscriptionId } = props
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <DataRow>
            <Grid container item className={classes.columnContainer} direction='row' xs={12}>
                <Grid container item direction="row" xs={12} sm={11}>
                    <Grid container item xs={12} sm={3} alignContent='center' className={classes.rowText} style={mobile ? { color: '#999999' } : {}}>
                        <FormattedDate date={transaction.date} />
                    </Grid>
                    {
                        !mobile &&
                        <Grid container item xs={12} sm={3} alignContent='center'>
                            <Typography className={classes.rowText}>
                                {subscriptionId.replace(/_/g, ' ')}
                            </Typography>
                        </Grid>
                    }
                    <Grid container item xs={12} sm={2} alignContent='center'>
                        <Typography className={classes.rowText}>
                            ${transaction.amount}
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} sm={2} alignContent='center'>
                        <Typography className={classes.rowText}>
                            {transaction.transactionId}
                        </Typography>
                    </Grid>
                    {
                        !mobile &&
                        <Grid container item xs={12} sm={2} alignContent='center'>
                            <OrderStatusChip
                                sketchOrder={
                                    {
                                        sketchOrderStatus: transaction.status ? ChipStatusMap[transaction.status as keyof typeof ChipStatusMap] : 'Pending',
                                        freeOrder: false,
                                        rushed: false
                                    }
                                }
                            />
                        </Grid>
                    }
                </Grid>
                <Grid container item xs={mobile ? 12 : 1} direction='row' justifyContent='space-between' style={mobile ? { padding: 8 } : {}}>
                    {
                        mobile &&
                        <Grid container item xs={9} alignContent='center'>
                            <OrderStatusChip
                                sketchOrder={
                                    {
                                        sketchOrderStatus: transaction.status ? ChipStatusMap[transaction.status as keyof typeof ChipStatusMap] : 'Pending',
                                        freeOrder: false,
                                        rushed: false
                                    }
                                }
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </DataRow >
    );
};

export default withRouter(withStyles(styles)(SubscriptionRow));

