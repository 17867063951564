import { InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import useId from '@mui/material/utils/useId';
import { getUsersOffices } from 'actions/Offices';
import { RoofSnapState } from 'lib/Models/RoofSnapState';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export type UserOfficeDropdownProps = {} & SelectProps<any>;

const UserOfficeDropdown = ({ ...rest }: UserOfficeDropdownProps) => {
    const officeSelectId = useId();

    const { userOffices, currentUser } = useSelector(
        (state: RoofSnapState) => state
    );

    const dispatch = useDispatch();

    useEffect(() => {
        if (userOffices.data && userOffices.data.length) {
            return;
        }

        dispatch(getUsersOffices(currentUser.userId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser.userId]);

    return (
        <>
            <InputLabel shrink htmlFor={officeSelectId}>
                {rest.label}
            </InputLabel>
            <Select {...rest}>
                {userOffices.data.map((office) => (
                    <MenuItem key={office.id} value={office.id}>
                        {office.officeName}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
};

export default UserOfficeDropdown;