import get from 'lodash/get';
import LocalStorageWrapper from '../../LocalStorageWrapper';

const parseCoordinates = (json) => {
    const path = 'resourceSets[0].resources[0].geocodePoints[0]';
    return {
        latitude: get(json, `${path}.coordinates[0]`),
        longitude: get(json, `${path}.coordinates[1]`),
    };
};

class RoofSnapGeocodeApiClient {
    constructor() {
        const accessToken = LocalStorageWrapper.getItem('accessToken');
        this.config = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };
    }

    geocodeAddress = async (address) => {
        const response = await fetch(`${process.env.REACT_APP_API_ROOFSNAP_GEOCODE_URL}/${encodeURIComponent(address)}`, this.config);
        if (!response || !response.ok) {
            throw new Error(`An error occurred geocoding address ${address}`);
        }
        const json = await response.json();
        return parseCoordinates(json);
    };
}

export default RoofSnapGeocodeApiClient;
