import React, { Component } from 'react';
import lodash from 'lodash';

/**
 * this will wrap a component and make it 'sticky' to where you want (position fixed).  it will adjust the height
 * based on the content of the component in the DOM and make sure it paints like it was position relative.
 * @param styles
 * @param WrappedComponent
 * @returns {React.Component}
 */
export default (styles, WrappedComponent) => (class extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        window.addEventListener('resize', this.tryUpdateHeight);
        this.tryUpdateHeight();
    }

    componentDidUpdate() {
        this.tryUpdateHeight();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.tryUpdateHeight);
    }

    setFixedContainerRef = (element) => {
        this.fixedContainerRef = element;
    };

    tryUpdateHeight = lodash.debounce(() => {
        if (!this.fixedContainerRef) {
            return;
        }
        const currentHeight = this.fixedContainerRef.offsetHeight;
        if (this.state.height !== currentHeight) {
            this.setState({ height: currentHeight });
        }
    }, 100, { leading: false, trailing: true });

    render() {
        return (
            <div>
                <div
                    style={Object.assign({ position: 'fixed' }, styles)}
                    ref={this.setFixedContainerRef}
                >
                    <WrappedComponent {...this.props} />
                </div>
                <div style={{ height: this.state.height }} />
            </div>
        );
    }
});
