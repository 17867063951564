import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const style = {
    labelMargin: {
        marginTop: '5px',
    },
};

const MarkupDialog = props => (
    <div>
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <TextField
                    id="markupDescription"
                    value={props.textFieldDescription}
                    onChange={props.handleChange(
                        props.type,
                        'markupDescription',
                    )}
                    label="Description"
                    type="text"
                    fullWidth
                    style={style.labelMargin}
                    required
                />
                <FormControl
                    required
                    style={{ width: '100%', ...style.labelMargin }}
                >
                    <InputLabel htmlFor="percent">{props.percentLabel}</InputLabel>
                    <Input
                        id="percent"
                        value={props.percentMarkUp}
                        onChange={props.handleChange(
                            props.type,
                            'percentMarkUp',
                        )}
                        label={props.percentLabel}
                        inputProps={{
                            'aria-label': 'Percent Markup',
                        }}
                        type="number"
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Cancel
                </Button>
                <Button
                    disabled={props.disabledCustomAdd}
                    onClick={() => props.handleAdd(props.itemType)}
                    color="primary"
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    </div>
);

MarkupDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    disabledCustomAdd: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleAdd: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    itemType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    percentLabel: PropTypes.string.isRequired,
    textFieldDescription: PropTypes.string.isRequired,
    percentMarkUp: PropTypes.string.isRequired,
};

export default MarkupDialog;
