import { SET_NEARMAP_API_KEY_FLAG } from '../actions';
import LocalStorageWrapper from '../lib/LocalStorageWrapper';
import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../actions/Auth';
import decode from '../lib/decodeRoofSnapAuthToken';

const accessToken = LocalStorageWrapper.getItem('accessToken');

const isAuthenticated = () => {
    if (!accessToken) {
        return false;
    }

    const payload = decode(accessToken);
    const expired = Date.UTC() >= payload.expiresIn;
    return !expired;
};

function auth(state = {
    isFetching: false,
    isFetchingText: '',
    isAuthenticated: isAuthenticated(),
    accessToken,
}, action) {
    switch (action.type) {
    case LOGIN_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
            isFetchingText: 'Logging in',
            isAuthenticated: false,
        });
    case LOGIN_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            isFetchingText: '',
            isAuthenticated: true,
            accessToken: action.accessToken,
            errorMessage: '',
        });
    case LOGIN_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            isFetchingText: '',
            isAuthenticated: false,
            errorMessage: action.message,
        });
    case LOGOUT_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            isAuthenticated: false,
        });
    case SET_NEARMAP_API_KEY_FLAG:
        return Object.assign({}, state, {
            user: {
                ...state.user,
                useNearMapApiKey: action.useNearMapApiKey,
            },
        });
    default:
        return state;
    }
}

export default auth;
