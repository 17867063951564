import {
    GET_MATERIAL_ITEM_COLOR_OPTIONS_REQUEST,
    GET_MATERIAL_ITEM_COLOR_OPTIONS_SUCCESS,
    GET_MATERIAL_ITEM_COLOR_OPTIONS_FAILURE,
} from '../actions/MaterialItemColorOptionsActions';

function materialItemColorOptionsReducer(state = {
    isFetching: false,
    colorOptions: [],
    totalResults: 0,
    totalPages: 0,
    currentPage: 0,
}, action) {
    switch (action.type) {
    case GET_MATERIAL_ITEM_COLOR_OPTIONS_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case GET_MATERIAL_ITEM_COLOR_OPTIONS_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            colorOptions: action.response.resourceList,
            totalPages: action.response.totalPages,
            totalResults: action.response.totalResults,
            currentPage: action.response.page,
        });
    case GET_MATERIAL_ITEM_COLOR_OPTIONS_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            colorOptions: [],
            totalResults: 0,
            totalPages: 0,
            currentPage: 0,
        });
    default:
        return state;
    }
}

export default materialItemColorOptionsReducer;
