import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Fab } from '@mui/material';
import roofsnapTheme from '../../lib/theme';

const styles = (theme) => ({
    root: {
        maxWidth: 530,
        height: '95vh',
        width: '100%',
        margin: 'auto',
        padding: theme.spacing(2),
    },
    welcomeImage: {
        width: '100%',
        marginBottom: theme.spacing(8),
    },
});

class WelcomeToRoofSnap extends Component {
    state = {
        expandText: false,
        showText: false,
    };

    componentDidMount() {
        this.showWelcome();
    }

    showWelcome = () => {
        setTimeout(() => {
            this.setState(
                {
                    expandText: true,
                },
                () => {
                    setTimeout(() => {
                        this.setState({ showText: true });
                    }, 800);
                }
            );
        }, 1000);
    };

    render() {
        return (
            <Fade in timeout={1000}>
                <Grid
                    container
                    spacing={1}
                    justifyContent='center'
                    alignItems='center'
                    className={this.props.classes.root}
                >
                    <Grid item xs={12}>
                        <img
                            src='/assets/welcome.jpeg'
                            alt='Documents'
                            className={this.props.classes.welcomeImage}
                        />
                        <Collapse in={this.state.expandText} timeout={800}>
                            <Fade in={this.state.showText} timeout={200}>
                                <Typography
                                    variant='h4'
                                    align='center'
                                    color='secondary'
                                >
                                    Welcome to RoofSnap!
                                </Typography>
                            </Fade>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    marginTop: '20px',
                                    textAlign: 'center',
                                }}
                            >
                                <Fade in={this.state.showText} timeout={200}>
                                    <Fab
                                        color='secondary'
                                        style={{
                                            backgroundColor:
                                                roofsnapTheme.palette
                                                    .roofsnapBlue.A100,
                                        }}
                                        variant='extended'
                                        onClick={this.props.onDismissClick}
                                        disabled={this.props.isLoading}
                                        id='getting-started-button'
                                    >
                                        Get Started
                                        {this.props.isLoading && (
                                            <CircularProgress
                                                size={16}
                                                color='inherit'
                                                style={{ marginLeft: '5px' }}
                                                className={
                                                    this.props.classes
                                                        .progressIndicator
                                                }
                                            />
                                        )}
                                    </Fab>
                                </Fade>
                            </Box>
                        </Collapse>
                    </Grid>
                </Grid>
            </Fade>
        );
    }
}

WelcomeToRoofSnap.propTypes = {
    onDismissClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
};

WelcomeToRoofSnap.defaultProps = {
    isLoading: false,
};

export default withStyles(styles)(WelcomeToRoofSnap);
