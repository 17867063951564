import React from 'react';
import { FormControl, TextField, Typography } from '@mui/material';
import { UserOfficeDropdown } from '@roofsnap';
import Section, { SectionProps } from './Section';

const ProjectSection = ({ project, onChange }: SectionProps) => {
    return (
        <Section id='project-section'>
            <Typography variant='subtitle2'>Project</Typography>
            <FormControl fullWidth>
                <TextField
                    name='projectName'
                    label='Project Name'
                    value={project.projectName || ''}
                    onChange={onChange}
                    inputProps={{
                        maxLength: 255,
                    }}
                />
            </FormControl>
            <FormControl fullWidth>
                <UserOfficeDropdown
                    name='officeId'
                    label='Office'
                    value={project.officeId || ''}
                    onChange={onChange}
                />
            </FormControl>
        </Section>
    );
};

export default ProjectSection;
