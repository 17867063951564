import React, { useContext } from 'react';
import OfficeContext from './OfficeContext';
import { updateOffice, UPDATE_OFFICE_SUCCESS, UPDATE_OFFICE_FAILURE } from 'actions/Office';
import Office from '../../lib/Models/Office';
import { useDispatch } from 'react-redux';

interface SwitchHookProps {
    onSuccess: () => void;
}

export const useOfficeSwitch = ({ onSuccess }: SwitchHookProps) => {
    const { office, set } = useContext(OfficeContext);
    const dispatch = useDispatch();

    const handleSwitch = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedOffice = {
            ...office,
            [event.target.name]: event.target.name === 'hideTotalPrice' ? event.target.checked : !event.target.checked,
        };
        const response: { type: string, response: Office } = await (dispatch(updateOffice(office.id, updatedOffice, office.version)) as any);
        if (response.type === UPDATE_OFFICE_SUCCESS) {
            set({
                ...updatedOffice,
                version: response.response.version
            });
            onSuccess();
        }
        if (response.type === UPDATE_OFFICE_FAILURE) {
            set(office);
        }
    };

    return { handleSwitch };
};
