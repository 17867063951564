import GenericApiClient, {
  ApiHeadersMap,
} from "lib/apiClients/genericApiClient";

const BASE_URL = `${process.env.REACT_APP_API_HOST}`;

export interface IRequestConfig {
  headers?: { [key: string]: string } | null;
  queryParams?: { [key: string]: any };
  requestBody?: any;
  responseBodyType?: string;
}

const useRoofSnapApi = (endpoint: string, useAuth = true) => {
  const client = new GenericApiClient(BASE_URL, useAuth);

  return {
    get: <TResponse>(headers?: ApiHeadersMap) =>
      client.get<TResponse>(endpoint, headers),
    post: <TResponse>(body: any, headers?: ApiHeadersMap) =>
      client.post<BodyInit, TResponse>(endpoint, body, headers),
    put: <TResponse>(body: any, headers?: ApiHeadersMap) =>
      client.put<BodyInit, TResponse>(endpoint, body, headers),
  };
};

export default useRoofSnapApi;
