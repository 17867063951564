import { SET_APP_REF } from '../actions/AppInit';

const defaultState = {
    ref: null,
};

function app(state = defaultState, action) {
    if (action.type !== SET_APP_REF) {
        return state;
    }

    return Object.assign({}, state, {
        ref: action.ref,
    });
}

export default app;
