import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ColorLens from '@material-ui/icons/ColorLens';
import { find } from 'lodash';
import DeleteIconComponent from '../DeleteIconComponent';

const styles = {
    paper: {
        width: '100%',
        maxWidth: 1350,
        margin: 'auto',
    },
    title: {
        padding: 12,
        margin: 0,
        fontWeight: 300,
    },
    item: {
        display: 'flex',
        borderBottom: 'solid 1px #dbdbdb',
    },
};

const currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const percent = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const decimal = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const EstimateItems = (props) => {
    const getImageUrl = (item) => {
        if (
            item.color &&
            item.color.image &&
            (item.color.image.endsWith('.jpg') ||
                item.color.image.endsWith('.png'))
        ) {
            return item.color.image;
        } else if (
            item.imageUrl &&
            (item.imageUrl.endsWith('.jpg') || item.imageUrl.endsWith('.png'))
        ) {
            return item.imageUrl;
        }

        return '/assets/empty.png';
    };

    const getTotal = (item) => {
        if (item.itemType === 'Markup' || item.itemType === 'Discount') {
            return '';
        }

        return currency.format(item.total);
    };

    const getSecondaryRow = (item, color) => {
        if (item.itemType === 'Markup' || item.itemType === 'Discount') {
            return `${percent.format(item.total * 0.01)} ${
                item.itemType === 'Markup' ? 'markup' : 'discount'
            }`;
        }

        switch (item.unitType) {
        case 'Squares':
            return `${decimal.format(item.units)} sq.${color}`;
        case 'SquareFeet':
            return `${item.units} ft.${color}`;
        case 'LinearFeet':
            return `${item.units} ft.${color}`;
        case 'Each':
            return `${item.units} ea.${color}`;
        default:
            break;
        }

        return '';
    };

    const renderEstimateItem = (item) => {
        const canDeleteAttribute = find(item.attributes, ['key', 'CanDelete']);
        const itemColor = item.color ? ` - ${item.color.description}` : '';
        return (
            <ListItem
                key={item.id}
                button
                onClick={() => props.clickEstimateItem(item)}
                style={styles.item}
                className="listItem"
            >
                <div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={getImageUrl(item)}
                            alt={item.description}
                            style={{
                                height: '50px',
                                width: '50px',
                                backgroundColor: 'LightGray',
                                margin: '0 20px 0 0',
                            }}
                        />
                    </div>
                </div>
                <div>
                    <ListItemText
                        style={{ width: '90%' }}
                        primary={item.description}
                        secondary={getSecondaryRow(item, itemColor)}
                    />
                </div>
                <ListItemSecondaryAction
                    style={{
                        display: 'flex',
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        style={{
                            padding: '15px 0',
                        }}
                    >
                        {getTotal(item)}
                    </Typography>
                    {item.colorOptionsCount > 1 ? (
                        <IconButton
                            onClick={() =>
                                props.onChangeColorOptionsClick(item)
                            }
                        >
                            <ColorLens />
                        </IconButton>
                    ) : (
                        <div />
                    )}
                    <DeleteIconComponent
                        deleteCallBack={() =>
                            props.onDeleteEstimateItem(
                                item.projectId,
                                item.id,
                                item.description,
                            )
                        }
                        disabled={!canDeleteAttribute.value}
                        disabledText="Unable to delete this item unless this pin is removed from the roof."
                    />
                </ListItemSecondaryAction>
            </ListItem>
        );
    };

    return (
        <Paper style={styles.paper}>
            <List>{props.estimateItems.map(renderEstimateItem)}</List>
        </Paper>
    );
};

const projectEstimateItemProps = {
    id: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    version: PropTypes.string,
    projectId: PropTypes.string,
    unitType: PropTypes.string,
    categoryId: PropTypes.string,
    itemType: PropTypes.string,
    description: PropTypes.string,
    imageUrl: PropTypes.string,
    units: PropTypes.number,
    coveragePerUnit: PropTypes.number,
    total: PropTypes.number,
    materialItemId: PropTypes.number,
    estimateId: PropTypes.string,
    laborCost: PropTypes.number,
    materialCost: PropTypes.number,
    materialOrderDescription: PropTypes.string,
    hideOnEstimate: PropTypes.bool,
    hideOnContract: PropTypes.bool,
    hideOnMaterialOrder: PropTypes.bool,
    hideOnLaborReport: PropTypes.bool,
    totalPerUnit: PropTypes.number,
    subItemMaterialCost: PropTypes.number,
};

EstimateItems.propTypes = {
    estimateItems: PropTypes.arrayOf(PropTypes.shape(projectEstimateItemProps))
        .isRequired,
    /* eslint-disable react/no-unused-prop-types */
    dispatch: PropTypes.func.isRequired,
    clickEstimateItem: PropTypes.func.isRequired,
    onDeleteEstimateItem: PropTypes.func.isRequired,
    onChangeColorOptionsClick: PropTypes.func.isRequired,
};

export default connect(null)(EstimateItems);
