import React from 'react';
import { Icon, WithStyles, createStyles, withStyles } from '@material-ui/core';

const styles = () => createStyles({
    imageIcon: {
        display: 'flex',
        height: 'inherit',
        width: 'inherit'
      },
      iconRoot: {
        textAlign: 'center'
      }
});

type SingleFamilyIconProps = {} & WithStyles<typeof styles>;

const SingleFamilyIcon = ({ classes }: SingleFamilyIconProps) => (
    <Icon classes={{root: classes.iconRoot}}>
        <img src='/assets/SingleFamilyIcon.svg' alt='Single Family' />
    </Icon>
);

export default withStyles(styles)(SingleFamilyIcon);
