import React from "react"
import User from "lib/Models/User";


export interface IUserContext {
    user: User,
    set: ( user: User) => void,
};

const UserContext = React.createContext({} as IUserContext);

export default UserContext;