import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ForbiddenDialog from './ForbiddenDialog';

const AccountExpiredSneakPeakForbiddenDialog = (props) => {
    const pushActivationPage = () => {
        props.history.push('/activate/billinginformation');
    };

    return (<ForbiddenDialog
        confirmationAction={pushActivationPage}
        forbiddenDialogTitle="Activate Your Account to Continue"
        forbiddenDialogContentText="Your trial access has expired. Activate your account by starting a subscription!"
    />);
};

AccountExpiredSneakPeakForbiddenDialog.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default withRouter(connect(null)(AccountExpiredSneakPeakForbiddenDialog));
