import { arrayToQueryString } from '../lib/util/ArrayUtilities.ts';
import { CALL_API, httpMethod } from '../middleware/api';
import SketchOrderStatus from '../lib/SketchOrderStatus.ts';

export const GET_SKETCHOS_BY_ORGANIZATION_REQUEST =
    'GET_SKETCHOS_BY_ORGANIZATION_REQUEST';
export const GET_SKETCHOS_BY_ORGANIZATION_SUCCESS =
    'GET_SKETCHOS_BY_ORGANIZATION_SUCCESS';
export const GET_SKETCHOS_BY_ORGANIZATION_FAILURE =
    'GET_SKETCHOS_BY_ORGANIZATION_FAILURE';

export const getSketchOSOrdersByOrganization = (
    organizationID,
    page = 1,
    reportType,
    latitude,
    longitude,
    radius,
    statuses = []
) => ({
    [CALL_API]: {
        types: [
            GET_SKETCHOS_BY_ORGANIZATION_REQUEST,
            GET_SKETCHOS_BY_ORGANIZATION_SUCCESS,
            GET_SKETCHOS_BY_ORGANIZATION_FAILURE,
        ],
        endpoint: `organizations/${organizationID}/sketchorders${arrayToQueryString(
            statuses,
            'status'
        )}`,
        method: httpMethod.GET,
        params: {
            page,
            latitude,
            longitude,
            radius,
            reportType,
        },
    },
});

export const GET_REQUESTED_OR_OWNED_SKETCH_ORDERS_REQUEST =
    'GET_REQUESTED_OR_OWNED_SKETCH_ORDERS_REQUEST';
export const GET_REQUESTED_OR_OWNED_SKETCH_ORDERS_SUCCESS =
    'GET_REQUESTED_OR_OWNED_SKETCH_ORDERS_SUCCESS';
export const GET_REQUESTED_OR_OWNED_SKETCH_ORDERS_FAILURE =
    'GET_REQUESTED_OR_OWNED_SKETCH_ORDERS_FAILURE';

export const getRequestedOrOwnedSketchOrders = (
    userId,
    page = 1,
    status = 'none'
) => ({
    [CALL_API]: {
        types: [
            GET_REQUESTED_OR_OWNED_SKETCH_ORDERS_REQUEST,
            GET_REQUESTED_OR_OWNED_SKETCH_ORDERS_SUCCESS,
            GET_REQUESTED_OR_OWNED_SKETCH_ORDERS_FAILURE,
        ],
        endpoint: `userprofiles/${userId}/requestedorownedsketchorders?page=${page}&status=${status}`,
        method: httpMethod.GET,
    },
});

export const getRequestedOrOwnedSketchOrdersV2 = (
    userId,
    page = 1,
    statuses = []
) => ({
    [CALL_API]: {
        types: [
            GET_REQUESTED_OR_OWNED_SKETCH_ORDERS_REQUEST,
            GET_REQUESTED_OR_OWNED_SKETCH_ORDERS_SUCCESS,
            GET_REQUESTED_OR_OWNED_SKETCH_ORDERS_FAILURE,
        ],
        endpoint: `userprofiles/${userId}/requestedorownedsketchorders${arrayToQueryString(
            statuses,
            'status'
        )}`,
        method: httpMethod.GET,
        apiVersion: 'v2',
        params: { page },
    },
});

export const GET_OPEN_REQUESTED_OR_OWNED_SKETCH_ORDERS_REQUEST =
    'GET_OPEN_REQUESTED_OR_OWNED_SKETCH_ORDERS_REQUEST';
export const GET_OPEN_REQUESTED_OR_OWNED_SKETCH_ORDERS_SUCCESS =
    'GET_OPEN_REQUESTED_OR_OWNED_SKETCH_ORDERS_SUCCESS';
export const GET_OPEN_REQUESTED_OR_OWNED_SKETCH_ORDERS_FAILURE =
    'GET_OPEN_REQUESTED_OR_OWNED_SKETCH_ORDERS_FAILURE';

const openStatusQueryParam = `status=${SketchOrderStatus.Pending}&status=${SketchOrderStatus.InProgress}&status=${SketchOrderStatus.PendingReview}&status=${SketchOrderStatus.PendingRejection}&status=${SketchOrderStatus.Queued}&status=${SketchOrderStatus.Exception}&status=${SketchOrderStatus.BillingFailed}&status=${SketchOrderStatus.InReview}`;

export const getOpenRequestedOrOwnedSketchOrders = (userId) => ({
    [CALL_API]: {
        types: [
            GET_OPEN_REQUESTED_OR_OWNED_SKETCH_ORDERS_REQUEST,
            GET_OPEN_REQUESTED_OR_OWNED_SKETCH_ORDERS_SUCCESS,
            GET_OPEN_REQUESTED_OR_OWNED_SKETCH_ORDERS_FAILURE,
        ],
        endpoint: `userprofiles/${userId}/requestedorownedsketchorders?${openStatusQueryParam}`,
    },
});
