import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';


class PublicDocumentDownload extends Component {
    constructor(props) {
        super(props);
        this.state = { sasUrlExpired: false };
    }
    componentDidMount = async () => {
        const sasUrl = atob(this.props.match.params.sharedAccessSignatureUrl.replace(/_/g, '/').replace(/-/g, '+'));
        let sasTokenIsValid = false;
        try {
            const response = await fetch(`https://${process.env.REACT_APP_MAD_MEGADOLON_HOST}///${sasUrl.replace('https://', '')}`, { method: 'HEAD' });
            sasTokenIsValid = response.ok;
        } catch (e) {
            sasTokenIsValid = false;
        }

        if (sasTokenIsValid) {
            window.location.replace(sasUrl);
        } else {
            this.setState({
                sasUrlExpired: true,
            });
        }
    }
    render() {
        if (this.state.sasUrlExpired) {
            return (
                <Redirect to={`/projects/${this.props.match.params.projectId}/documents/${this.props.match.params.documentId}/${this.props.match.params.renderingId}`} />
            );
        }
        return null;
    }
}

PublicDocumentDownload.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            projectId: PropTypes.string,
            documentId: PropTypes.string,
            renderingId: PropTypes.string,
            sharedAccessSignatureUrl: PropTypes.string,
        }),
    }).isRequired,
};

export default PublicDocumentDownload;
