import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const styles = theme => ({
    desktop: {
        position: 'fixed',
    },
    mobile: {

    },
    listItem: {
        borderTopRightRadius: theme.spacing(3),
        borderBottomRightRadius: theme.spacing(3),
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
});

const Navigation = (props) => {
    const {
        isMobile, links, activeLink, classes, onChange,
    } = props;

    const DesktopNav = () => (
        <List>
            {links.map((link, index) => (
                <ListItem
                    className={classes.listItem}
                    button
                    key={link.key}
                    selected={activeLink === index}
                    onClick={onChange}
                >
                    <ListItemIcon>{link.icon}</ListItemIcon>
                    <ListItemText primary={link.label} />
                </ListItem>
            ))}
        </List>
    );

    const MobileNav = () => (
        <Paper>
            <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={props.activeLink}
                onChange={props.onChange}
            >
                <Tab label="Subscription & services" />
                <Tab label="Offices" />
                <Tab label="Users" />
            </Tabs>
        </Paper>
    );

    return isMobile ? <MobileNav /> : <DesktopNav />;
};

Navigation.propTypes = {
    classes: PropTypes.shape({
        desktop: PropTypes.string.isRequired,
        mobile: PropTypes.string.isRequired,
        listItem: PropTypes.string.isRequired,
    }).isRequired,
    links: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        icon: PropTypes.node.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    isMobile: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    activeLink: PropTypes.number.isRequired,
};

Navigation.defaultProps = {
    isMobile: false,
};

export default withStyles(styles)(Navigation);
