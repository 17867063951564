import { OPEN_SEARCH_APP_BAR, CLOSE_SEARCH_APP_BAR } from '../actions/SearchAppBarActions';

const initialState = {
    searchOpen: false,
};

const searchAppBarReducer = (state = initialState, action) => {
    switch (action.type) {
    case OPEN_SEARCH_APP_BAR:
        return { ...state, searchOpen: true };
    case CLOSE_SEARCH_APP_BAR:
        return { ...state, searchOpen: false };
    default:
        return state;
    }
};

export default searchAppBarReducer;
