import React from 'react';
import {
    List,
    Home,
    Description,
    FormatListNumbered,
    EventNote,
    PlaylistAddCheck,
    InsertPhoto,
    ThreeDRotation,
} from '@material-ui/icons';
import SvgIcon from '@material-ui/core/SvgIcon';
import featureFlags from './FeatureFlags.ts';
import UserRoles from './UserRoles';

export const projectRoutes = {
    Details: 'details',
    Sketch: 'sketch',
    Documents: 'documents',
    Measurements: 'measurements',
    Notes: 'notes',
    Estimates: 'estimates',
    Images: 'images',
    Visualizer: '3dviewer',
    Imagery: 'imagery',
    ESignatures: 'esignatures',
};

export const projectMenus = [
    {
        id: 'project-details-card',
        Title: 'Details',
        Route: projectRoutes.Details,
        Icon: <List />,
        Color: '#005CAA',
        isPrimary: true,
    },
    {
        id: 'project-sketch-card',
        Title: 'Sketch',
        Route: projectRoutes.Sketch,
        Icon: <Home />,
        Color: '#DD5816',
        isPrimary: true,
    },
    {
        id: 'project-measurements-card',
        Title: 'Measurements',
        Route: projectRoutes.Measurements,
        Icon: <FormatListNumbered />,
        Color: '#25B546',
    },
    {
        id: 'project-documents-card',
        Title: 'Documents',
        ShortName: 'Docs',
        Route: projectRoutes.Documents,
        Icon: <Description />,
        Color: '#1B1C44',
        isPrimary: true,
    },
    {
        id: 'project-esignatures-card',
        Title: 'eSignatures',
        Route: projectRoutes.ESignatures,
        Icon: (
            <SvgIcon viewBox='0 0 16 16'>
                <path d='M0 16c2-6 7.234-16 16-16-4.109 3.297-6 11-9 11s-3 0-3 0l-3 5h-1z' />
            </SvgIcon>
        ),
        Color: '#03A9FF',
        Hidden: !featureFlags.eSignatures,
    },
    {
        id: 'project-estimates-card',
        Title: 'Estimates',
        Route: projectRoutes.Estimates,
        Icon: <PlaylistAddCheck />,
        Color: '#009688',
        isSubscriberOnly: true,
        hideForRoles: [UserRoles.EstimatesDisabled],
    },
    {
        id: 'project-images-card',
        Title: 'Images',
        Route: projectRoutes.Images,
        Icon: <InsertPhoto />,
        Color: '#2E7D32',
    },
    {
        id: 'project-notes-card',
        Title: 'Notes',
        Route: projectRoutes.Notes,
        Icon: <EventNote />,
        Color: '#CC1900',
        hideForRoles: [UserRoles.EstimatesDisabled],
    },
    {
        id: '3d-viewer-card',
        Title: '3D Viewer (Beta)',
        Route: projectRoutes.Visualizer,
        Icon: <ThreeDRotation />,
        Color: '#9C27B0',
    },
    {
        id: 'projects-imagery-card',
        Title: 'Imagery',
        Route: projectRoutes.Imagery,
        Hidden: true,
    },
];

export const projectMenusVisible = projectMenus.filter((menu) => !menu.Hidden);
