import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';

class Option extends Component {
    handleClick = (event) => {
        this.props.onSelect(this.props.option, event);
    };
    render() {
        const {
            children, isFocused, isSelected, onFocus,
        } = this.props;

        return (
            <MenuItem
                onFocus={onFocus}
                selected={isFocused}
                onClick={this.handleClick}
                component="div"
                style={{
                    fontWeight: isSelected ? 500 : 400,
                }}
            >
                {children}
            </MenuItem>
        );
    }
}

Option.propTypes = {
    option: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]).isRequired,
    }).isRequired,
    onSelect: PropTypes.func.isRequired,
    children: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    isFocused: PropTypes.bool.isRequired,
    onFocus: PropTypes.func.isRequired,
};

export default Option;
