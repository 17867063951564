export const DocumentDataContextType = {
    Project: 'Project',
    ProjectDrawing: 'ProjectDrawing',
    Estimate: 'Estimate',
    MultiEstimate: 'MultiEstimate',
};

export const getDocumentDataContextTypeFromDocumentTemplateCategory = (documentTemplateCategory) => {
    const { templates } = documentTemplateCategory;
    if (!templates || templates.length === 0) {
        return null;
    }

    return templates[0].documentDataContextType;
};

export default DocumentDataContextType;
