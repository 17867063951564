import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import LoggerWrapper from '../../lib/Logger';
import RoofSnapAppBar from './RoofSnapAppBar';
import RoofSnapDrawer from './RoofSnapDrawer';
import RoofSnapPageToolbar from './RoofSnapPageToolbar.tsx';
import { logoutUser } from '../../actions/Auth';
import stickyWithHeightAdjustment from './stickWithHeightAdjustment';
import ActivateAccountAlert from '../ActivateAccountAlert';
import hostConfig from '../../lib/hostConfig.ts';
import SearchAppBar from '../Search/SearchAppBar';
import {
    closeSearchAppBar,
    openSearchAppBar,
} from '../../actions/SearchAppBarActions';
import { setPageToolbarRef } from '../../actions/RoofSnapPageToolbarActions';

class RoofSnapNavigationComponents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDrawer: false,
        };
        this.toolbarContainerRef = React.createRef();
    }

    componentDidUpdate() {
        if (!this.props.pageToolbar.ref) {
            this.props.dispatch(setPageToolbarRef(this.toolbarContainerRef));
        }
    }

    onSearch = (value) =>
        this.props.history.push({
            pathname: '/projects',
            search: `?q=${value}`,
        });

    onSearchClear = () =>
        this.props.history.replace({
            search: '',
        });

    handleSearchBarOpen = () => {
        this.props.dispatch(openSearchAppBar());
    };

    handleSearchBarClose = () => {
        this.props.dispatch(closeSearchAppBar());
    };

    handleTitleClick = () => {
        this.props.history.push('/projects');
    };

    handleToggle = () => {
        try {
            window.Appcues.track('Clicked Drawer');
        } catch (error) {
            LoggerWrapper.captureException(error);
        }
        this.setState({ openDrawer: !this.state.openDrawer });
    };

    logOut = () => {
        this.props.dispatch(logoutUser());
        this.props.history.push('/login');
    };

    render() {
        return this.props.isAuthenticated ? (
            <div>
                <RoofSnapDrawer
                    open={this.state.openDrawer}
                    toggle={this.handleToggle}
                />
                <RoofSnapAppBar
                    {...this.props.appBar}
                    toggle={this.handleToggle}
                    titleClick={this.handleTitleClick}
                    signOutClick={this.logOut}
                    searchOpen={this.props.searchOpen}
                    openSearchBar={this.handleSearchBarOpen}
                    onSearch={this.onSearch}
                    onCancel={this.onSearchClear}
                />
                <Slide
                    unmountOnExit
                    mountOnEnter
                    direction='left'
                    in={this.props.searchOpen}
                >
                    <SearchAppBar
                        placeholder='Find Project'
                        onSearch={this.onSearch}
                        onCancel={this.onSearchClear}
                        closeSearchBar={this.handleSearchBarClose}
                        searchOpen={this.props.searchOpen}
                    />
                </Slide>
                <div ref={this.toolbarContainerRef}>
                    {!this.props.pageToolbar.hideToolbar &&
                        !this.props.pageToolbar.usePortal && (
                            <Route
                                render={() => (
                                    <RoofSnapPageToolbar
                                        {...this.props.pageToolbar}
                                    />
                                )}
                            />
                        )}
                </div>
                <ActivateAccountAlert />
            </div>
        ) : (
            <AppBar position='static'>
                <Toolbar>
                    <Typography
                        variant='h6'
                        color='inherit'
                        style={{ flex: 1 }}
                    >
                        {hostConfig.siteName}
                    </Typography>
                </Toolbar>
            </AppBar>
        );
    }
}

RoofSnapNavigationComponents.propTypes = {
    dispatch: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired,
    }).isRequired,
    pageToolbar: PropTypes.objectOf(PropTypes.shape).isRequired,
    appBar: PropTypes.objectOf(PropTypes.shape).isRequired,
    searchOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    const {
        projectHome,
        auth,
        roofSnapAppBar: appBar,
        roofSnapPageToolbar: pageToolbar,
        searchAppBar,
    } = state;
    const { currentProject } = projectHome;
    const { isAuthenticated } = auth;
    const { searchOpen } = searchAppBar;
    return {
        currentProject,
        isAuthenticated,
        pageToolbar,
        appBar,
        searchOpen,
    };
};

export default connect(mapStateToProps)(
    stickyWithHeightAdjustment(
        {
            zIndex: 1100,
            width: '100%',
        },
        RoofSnapNavigationComponents
    )
);

export const RoofSnapNavigationComponent = RoofSnapNavigationComponents;
