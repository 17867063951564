import { SET_APPBAR_BUTTONS, SET_APPBAR_TITLE, RESET_APPBAR } from '../actions/RoofSnapAppBarActions';

function roofSnapAppBar(state = {
    buttons: [],
    titleText: 'RoofSnap',
}, action) {
    switch (action.type) {
    case SET_APPBAR_BUTTONS:
        return Object.assign({}, state, {
            buttons: action.buttons,
        });
    case SET_APPBAR_TITLE:
        return Object.assign({}, state, {
            titleText: action.titleText,
        });
    case RESET_APPBAR:
        return Object.assign({}, state, {
            titleText: 'RoofSnap',
            buttons: [],
        });
    default:
        return state;
    }
}

export default roofSnapAppBar;
