import LocalStorageWrapper from 'lib/LocalStorageWrapper';
import { camelizeKeys } from 'humps';
import httpStatusCodes from 'middleware/httpStatusCodes';
import urlFactory from 'url-factory';

export interface ApiHeadersMap {
    [key: string]: string;
}

export default class GenericApiClient {
    private _urlFactory;
    private _useAuth;

    constructor(baseUrl: string, useRoofSnapAuth: boolean) {
        this._urlFactory = urlFactory(baseUrl);
        this._useAuth = useRoofSnapAuth;
    }

    private static handleHttpErrors = async (response: Response) => {
        switch (response.status) {
            case httpStatusCodes.Unauthorized: {
                // Clear everything, inlcuding the roofsnap access token, out of local storage
                LocalStorageWrapper.clear();
                window.location.pathname = '/login';
                return Promise.reject(response);
            }
            case httpStatusCodes.Forbidden: {
                // Navigate to the login page where the authentication logic is
                window.location.pathname = '/login';
                return Promise.reject(response);
            }
            case httpStatusCodes.PreconditionFailed:
            case httpStatusCodes.NotFound: {
                return Promise.reject(response);
            }
            default:
                // If there is a json response, we want to return that, otherwise just reject the response
                try {
                    const json = await response.json();
                    return await Promise.reject(json);
                } catch (error) {
                    return Promise.reject(response);
                }
        }
    };

    private async request<TResponse>(
        path: string,
        config: RequestInit = {}
    ): Promise<TResponse> {
        const url = this._urlFactory(path);

        const response = await fetch(url, config);

        if (!response.ok) {
            return GenericApiClient.handleHttpErrors(response);
        }

        // This client only handles json right now because it returns
        // TResponse which needs to be represantable by an interface
        const json = await response.json();

        return camelizeKeys(json) as unknown as TResponse;
    }

    protected buildHeaders(headersMap?: ApiHeadersMap): HeadersInit {
        const headers: HeadersInit = new Headers({
            'X-Api-Key': `${process.env.REACT_APP_API_KEY}`,
            'Content-Type': 'application/json',
            RSClient: 'DeceptiveDolphin',
            ...headersMap,
        });

        if (this._useAuth) {
            const token = LocalStorageWrapper.getItem('accessToken') || null;
            headers.set('Authorization', `Bearer ${token}`);
        }

        return headers;
    }

    public async get<TResponse>(
        path: string,
        headersMap?: ApiHeadersMap,
    ): Promise<TResponse> {
        return this.request(path, {
            headers: this.buildHeaders(headersMap),
        });
    }

    public async put<TBody extends BodyInit, TResponse>(
        path: string,
        body: TBody,
        headersMap?: ApiHeadersMap
    ): Promise<TResponse> {
        return this.request(path, {
            method: 'PUT',
            headers: this.buildHeaders(headersMap),
            body: JSON.stringify(body),
        });
    }

    public async post<TBody extends BodyInit, TResponse>(
        path: string,
        body: TBody,
        headersMap?: ApiHeadersMap
    ): Promise<TResponse> {
        return this.request(path, {
            method: 'POST',
            headers: this.buildHeaders(headersMap),
            body: JSON.stringify(body),
        });
    }
}
