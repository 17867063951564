import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProjectEstimateItems } from '../../actions/ProjectEstimateItemsActions';
import {
    deleteEstimateItem,
    putEstimateItem,
} from '../../actions/ProjectEstimateItemActions';
import OverrideMeasurementsSwitch from '../OverrideMeasurementsSwitch';
import { getProjectEstimate } from '../../actions/ProjectEstimate';
import EstimateItems from './EstimateItems';
import CreateEstimateItem from '../CreateEstimateItem';
import EditEstimateItem from './EditEstimateItem/EditEstimateItem';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import CustomItems from './CustomItems/CustomItems';
import CreateEstimateTemplate from '../CreateEstimateTemplate/CreateEstimateTemplate';
import PageToolbarActions from '../NavigationComponents/PageToolbarActions';
import {
    getProjectDrawingById,
    updateProjectDrawing,
} from '../../actions/ProjectDrawingActions';
import MaterialItemColorOptionsDialog from '../MaterialItemColorOptionsDialog/MaterialItemColorOptionsDialog';
import { getMaterialItemColorOptions } from '../../actions/MaterialItemColorOptionsActions';
import { getProjectById } from '../../actions/ProjectActions';
import EstimateTotal from './EstimateTotal';

const style = {
    root: {
        padding: 24,
    },
};

class Estimate extends Component {
    state = {
        selectedItem: {},
        editEstimateItemDialogOpen: false,
        deleteEstimateItemModalOpen: false,
        colorOptionsDialogOpen: false,
        scrollPosition: 0,
    };
    componentDidMount() {
        window.addEventListener('scroll', this.getScrollPosition, { passive: true });
        window.scrollTo(0, 0);
        this.getAllEstimateItems();
        this.props
            .dispatch(getProjectDrawingById(this.props.match.params.id));
    }

    componentDidUpdate() {
        window.scrollTo(0, this.state.scrollPosition);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.getScrollPosition);
    }

    onExportPdfClick = () => this.props.history.push(`/projects/${this.props.match.params.id}/documents?createDocumentOpen=true`);

    getScrollPosition = () => {
        const position = window.pageYOffset;
        return position;
    }

    getAllEstimateItems = (page = 1) => {
        this.props.dispatch(getProjectEstimateItems(
            this.props.match.params.id,
            this.props.match.params.estimateId,
            page,
            true,
        ));
    };

    setEstimateItem = (item) => {
        this.setState({
            selectedItem: item,
            editEstimateItemDialogOpen: true,
            scrollPosition: this.getScrollPosition(),
        });
    };

    getNextPageColorOptions = (estimateItem) => {
        const page = this.props.colorOptionsCurrentPage + 1;
        this.getColorOptions(estimateItem, page);
    };

    getPrevPageColorOptions = (estimateItem) => {
        const page = this.props.colorOptionsCurrentPage - 1;
        this.getColorOptions(estimateItem, page);
    };

    getColorOptions = (estimateItem, page = 1) => {
        this.props
            .dispatch(getProjectById(this.props.match.params.id))
            .then((result) => {
                if (!result.response) {
                    return;
                }
                this.props.dispatch(getMaterialItemColorOptions(
                    result.response.officeId,
                    estimateItem.materialItemId,
                    page,
                ));
            });
    };

    closeEditEstimateDialog = () => {
        this.setState({
            selectedItem: {},
            editEstimateItemDialogOpen: false,
        });
    };

    changeOverrideDrawingMeasurements = () => {
        const projectDrawingToUpdate = Object.assign({}, this.props.drawing);
        projectDrawingToUpdate.measurements.overrideDrawingMeasurements = !projectDrawingToUpdate
            .measurements.overrideDrawingMeasurements;

        this.props
            .dispatch(updateProjectDrawing(
                this.props.match.params.id,
                this.props.drawing.version,
                projectDrawingToUpdate,
            ))
            .then(() => {
                this.props
                    .dispatch(getProjectDrawingById(this.props.match.params.id));
            });
    };

    deleteEstimateItem = () => {
        this.props
            .dispatch(deleteEstimateItem(
                this.state.projectId,
                this.state.estimateItemId,
            ))
            .then(() => {
                this.setState({ deleteEstimateItemModalOpen: false });
                this.props.dispatch(getProjectEstimate(
                    this.props.match.params.id,
                    this.props.match.params.estimateId,
                ));
                this.props.dispatch(getProjectEstimateItems(
                    this.props.match.params.id,
                    this.props.match.params.estimateId,
                    1,
                    true,
                ));
            });
    };

    openDeleteEstimateItemConfirmationDialog = (
        projectId,
        estimateItemId,
        estimateItemDescription,
    ) => {
        this.setState({
            deleteEstimateItemModalOpen: true,
            projectId,
            estimateItemId,
            estimateItemDescription,
        });
    };

    handleDismissDeleteEstimateItemDialog = () => {
        this.setState({
            deleteEstimateItemModalOpen: false,
            projectId: '',
            estimateItemId: '',
            estimateItemDescription: '',
        });
    };

    openColorOptionsDialog = (estimateItem) => {
        this.setState({
            colorOptionsDialogOpen: true,
            selectedItem: estimateItem,
        });

        this.getColorOptions(estimateItem);
    };

    dismissColorOptionsDialog = () => {
        this.setState({ colorOptionsDialogOpen: false });
    };

    saveColorOption = (colorOption) => {
        const updatedEstimateItem = Object.assign({}, this.state.selectedItem);
        updatedEstimateItem.colorId = colorOption.id;
        this.props
            .dispatch(putEstimateItem(
                this.props.match.params.id,
                updatedEstimateItem.id,
                updatedEstimateItem,
                updatedEstimateItem.version,
            ))
            .then((result) => {
                if (!result.response) {
                    return;
                }
                this.setState({ colorOptionsDialogOpen: false });
                this.getAllEstimateItems();
            });
    };

    render() {
        const canOverrideMeasurements = Boolean(this.props.drawing.measurements);
        const overrideMeasurements = canOverrideMeasurements && this.props.drawing.measurements.overrideDrawingMeasurements;

        return (
            <div style={style.root}>
                <PageToolbarActions triggers={[canOverrideMeasurements, overrideMeasurements]}>
                    <OverrideMeasurementsSwitch
                        key="overrideswitch"
                        checked={overrideMeasurements}
                        handleConfirm={this.changeOverrideDrawingMeasurements}
                        disabled={!canOverrideMeasurements}
                        toolTip={!canOverrideMeasurements ? 'Sketch must be created first' : undefined}
                    />
                </PageToolbarActions>
                <EstimateTotal match={this.props.match} onExportPdfClick={this.onExportPdfClick} />
                <EstimateItems
                    estimateItems={this.props.estimateItems}
                    totalPages={this.props.totalPages}
                    currentPage={this.props.currentPage}
                    prevEstimateItemsPageClick={this.prevEstimateItemsPageClick}
                    nextEstimateItemsPageClick={this.nextEstimateItemsPageClick}
                    isFetching={this.props.isFetching}
                    clickEstimateItem={this.setEstimateItem}
                    onDeleteEstimateItem={
                        this.openDeleteEstimateItemConfirmationDialog
                    }
                    onChangeColorOptionsClick={this.openColorOptionsDialog}
                />
                <CreateEstimateItem
                    projectId={this.props.match.params.id}
                    estimateId={this.props.match.params.estimateId}
                />
                <EditEstimateItem
                    estimateItem={this.state.selectedItem}
                    closeDialog={this.closeEditEstimateDialog}
                    projectId={this.props.match.params.id}
                    editEstimateItemDialogOpen={
                        this.state.editEstimateItemDialogOpen
                    }
                />
                <ConfirmationModal
                    title={`Delete ${this.state.estimateItemDescription}`}
                    description="Are you sure you want to remove this estimate item?"
                    handleConfirm={this.deleteEstimateItem}
                    confirmText="Delete"
                    isOpen={this.state.deleteEstimateItemModalOpen}
                    handleClose={this.handleDismissDeleteEstimateItemDialog}
                />
                <CustomItems />
                <CreateEstimateTemplate />
                <MaterialItemColorOptionsDialog
                    open={this.state.colorOptionsDialogOpen}
                    colorOptions={this.props.colorOptions}
                    totalResults={this.props.totalColorOptions}
                    onColorOptionClick={this.saveColorOption}
                    onDismiss={this.dismissColorOptionsDialog}
                    materialItem={this.state.selectedItem}
                    totalPages={this.props.totalColorOptionsPages}
                    currentPage={this.props.colorOptionsCurrentPage}
                    onNextPageClick={this.getNextPageColorOptions}
                    onPrevPageClick={this.getPrevPageColorOptions}
                />
            </div>
        );
    }
}

Estimate.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            estimateId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    estimateItems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        version: PropTypes.string,
        projectId: PropTypes.string,
        unitType: PropTypes.string,
        categoryId: PropTypes.string,
        itemType: PropTypes.string,
        description: PropTypes.string,
        imageUrl: PropTypes.string,
        units: PropTypes.number,
        coveragePerUnit: PropTypes.number,
        total: PropTypes.number,
        materialItemId: PropTypes.number,
        estimateId: PropTypes.string,
        laborCost: PropTypes.number,
        materialCost: PropTypes.number,
        materialOrderDescription: PropTypes.string,
        hideOnEstimate: PropTypes.bool,
        hideOnContract: PropTypes.bool,
        hideOnMaterialOrder: PropTypes.bool,
        hideOnLaborReport: PropTypes.bool,
        totalPerUnit: PropTypes.number,
        subItemMaterialCost: PropTypes.number,
    })).isRequired,
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    isFetching: PropTypes.bool,
    drawing: PropTypes.shape({
        version: PropTypes.string,
        measurements: PropTypes.shape({
            overrideDrawingMeasurements: PropTypes.bool,
        }),
    }).isRequired,
    colorOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    totalColorOptions: PropTypes.number.isRequired,
    totalColorOptionsPages: PropTypes.number.isRequired,
    colorOptionsCurrentPage: PropTypes.number.isRequired,
};

Estimate.defaultProps = {
    isFetching: false,
};

const mapStateToProps = (state) => {
    const {
        projectEstimateItems,
        projectDrawing,
        materialItemColorOptionsReducer,
    } = state;
    const { projectDrawing: drawing } = projectDrawing;
    const {
        colorOptions,
        totalResults: totalColorOptions,
        totalPages: totalColorOptionsPages,
        currentPage: colorOptionsCurrentPage,
    } = materialItemColorOptionsReducer;

    const {
        totalResults,
        totalPages,
        currentPage,
        estimateItems,
        isFetching,
    } = projectEstimateItems;
    return {
        totalResults,
        totalPages,
        currentPage,
        isFetching,
        estimateItems,
        drawing,
        colorOptions,
        totalColorOptions,
        totalColorOptionsPages,
        colorOptionsCurrentPage,
    };
};

export default withRouter(connect(mapStateToProps)(Estimate));
