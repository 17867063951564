import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const SectionInfo = styled(Typography)({
    overflow: 'hidden',
    color: '#222',
    textOverflow: 'ellipsis',
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontsize: 'normal',
    fontWeight: 400,
    lineHeight: '1.25rem',
    letterSpacing: '0.01563rem',
});

export default SectionInfo;
