const MetalRoofOption: {
    [key: string]: { label: string; value: number | undefined };
} = {
    None: { label: 'Select an Option (Required)', value: undefined },
    TwelveInches: { label: '12 inches', value: 12 },
    FourteenInches: { label: '14 inches', value: 14 },
    SixteenInches: { label: '16 inches', value: 16 },
    EighteenInches: { label: '18 inches', value: 18 },
    ThirtySixInches: { label: '36 inches', value: 36 },
};

export default MetalRoofOption;
