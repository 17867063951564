import findIndex from 'lodash/findIndex';
import {
    GET_DOCUMENTS_REQUEST,
    GET_DOCUMENTS_SUCCESS,
    GET_DOCUMENTS_FAILURE,
} from '../actions/documentsActions';
import {
    GET_DOCUMENT_SUCCESS,
    DOWNLOAD_DOCUMENT_REQUEST,
    DOWNLOAD_DOCUMENT_SUCCESS,
    DOWNLOAD_DOCUMENT_FAILURE,
} from '../actions/documentActions';

function documents(
    state = {
        isFetching: false,
        totalResults: 0,
        data: [],
    },
    action,
) {
    switch (action.type) {
    case GET_DOCUMENTS_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case GET_DOCUMENTS_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            data: action.documents,
            totalResults: action.totalResults,
        });
    case GET_DOCUMENTS_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case GET_DOCUMENT_SUCCESS: {
        const { document } = action;
        const documentsData = [...state.data];
        const documentIndex = findIndex(documentsData, (doc) => {
            if (doc.renderingId) {
                return doc.renderingId === document.renderingId;
            }
            return doc.id === document.id;
        });
        documentsData[documentIndex] = document;
        return Object.assign({}, state, {
            data: documentsData,
        });
    }
    case DOWNLOAD_DOCUMENT_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case DOWNLOAD_DOCUMENT_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case DOWNLOAD_DOCUMENT_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
        });
    default:
        return state;
    }
}

export default documents;
