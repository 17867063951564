import {
    GET_ORGANIZATIONS_CREDITS_REQUEST,
    GET_ORGANIZATIONS_CREDITS_SUCCESS,
    GET_ORGANIZATIONS_CREDITS_FAILURE,
} from '../actions/OrganizationCreditsActions';

const initialState = {
    creditBalance: 0,
    isFetching: false,
};

const organizationCreditsReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_ORGANIZATIONS_CREDITS_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case GET_ORGANIZATIONS_CREDITS_SUCCESS:
        return Object.assign({}, state, {
            creditBalance: action.response.creditBalance,
            isFetching: false,
        });
    case GET_ORGANIZATIONS_CREDITS_FAILURE:
        return initialState;
    default:
        return state;
    }
};

export default organizationCreditsReducer;
