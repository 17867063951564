import {
    Box,
    createStyles,
    Divider,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core';

import React from 'react';
import GutterPanelSize from './GutterPanelSize';
import { AllowedOrderTypes } from 'lib/MeasurementOrders';

const useStyles = makeStyles(() =>
    createStyles({
        orderLabel: {
            color: '#222',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '1.25rem',
            letterSpacing: '0.00625rem',
            paddingBottom: '5px',
        },

        gutterRateLabel: {
            color: '#222',
            fontSize: '1.2rem',
            fontWeight: 700,
            lineHeight: '1.5rem',
            letterSpacing: '0.03125rem',
            textAlign: 'end',
            paddingRight: '10px',
        },
    })
);

type AddGutterOrderProps = {
    gutterPrice: string;
    onGutterOptionsChange?: (gutterOptions: number | undefined) => void;
    selectedGutterSize: number | undefined;
    selectedOrderType: AllowedOrderTypes;
    reportType: AllowedOrderTypes;
    includeGutters?: boolean;
    handleIncludeGutters: (includeGutters: boolean) => void;
};

const AddGutterOrder = ({
    gutterPrice,
    onGutterOptionsChange,
    selectedGutterSize,
    selectedOrderType,
    reportType,
    includeGutters,
    handleIncludeGutters,
}: AddGutterOrderProps) => {
    const classes = useStyles();

    const handleAddGutterOrder = (event: any) => {
        handleIncludeGutters(event.target.checked);
    };
    return (
        <Grid container direction='column'>
            <Divider
                variant='fullWidth'
                style={{ marginRight: '10px' }}
            ></Divider>
            <Grid item>
                <Box sx={{ marginTop: '11px' }}>
                    <Grid container direction='row'>
                        <Grid item style={{ display: 'flex' }}>
                            <Box style={{ marginRight: '2px' }}>
                                <input
                                    style={{
                                        width: '17px',
                                        height: '17px',
                                        marginTop: '1px',
                                    }}
                                    type='checkbox'
                                    onChange={handleAddGutterOrder}
                                ></input>
                            </Box>
                            <Typography className={classes.orderLabel}>
                                Add Gutter Report
                                <span style={{ color: '#555555' }}>
                                    {' '}
                                    (optional)
                                </span>
                            </Typography>
                        </Grid>
                        <Grid xs>
                            <Typography className={classes.gutterRateLabel}>
                                {gutterPrice}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>

            {includeGutters && (
                <Grid item>
                    <GutterPanelSize
                        reportType={reportType}
                        onGutterOptionsChange={onGutterOptionsChange}
                        selectedGutterSize={selectedGutterSize}
                        selectedOrderType={selectedOrderType}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default AddGutterOrder;
