import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import ProjectList from '../ProjectList/ProjectList';
import Project from './Project.tsx';
import DeletedProjects from '../DeletedProjects/DeletedProjects';

const Projects = ({ match }) => (
    <Switch>
        <Route exact path={`${match.path}`} component={ProjectList} />
        <Route
            exact
            path={`${match.path}/deleted`}
            component={DeletedProjects}
        />
        <Route path={`${match.path}/:id`} component={Project} />
    </Switch>
);

Projects.propTypes = {
    match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired,
};

export default Projects;
