import { Grid, Typography } from '@material-ui/core';
import { GridSize } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';
import { useTheme, useMediaQuery } from "@material-ui/core";


type DataRowProps = PropsWithChildren<{}>;

export const DataRow = ({ children }: DataRowProps) => {

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid
      container
      item
      xs={12}
      style={{
        backgroundColor: '#FFFFFF',
        border: '1px solid #00112219',
        padding: mobile ? 8 : 40
      }}
      
      alignItems='center'
      data-cy='data-row'
    >
      <Grid container item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

type ColumnHeader = {
  title: string;
  width: number;
};

type CheckboxTableProps = PropsWithChildren<{
  columnHeaders: ColumnHeader[];
  headerStyle: string;
  actionable?: boolean;
}>;

export const DataTable = ({
  columnHeaders,
  headerStyle,
  actionable = true,
  children,
}: CheckboxTableProps) => {


  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      {!mobile &&
        <Grid container item xs={12} style={{ padding: '16px 0px' }}>
          <Grid item container xs={12}>
            <Grid
              container
              item
              direction='row'
              xs={11}
              style={{ padding: '0px 10px' }}
            >
              {columnHeaders.map((header) => {
                return (
                  <Grid
                    container
                    item
                    xs={header.width as GridSize}
                    alignContent='center'
                  >
                    <Typography className={headerStyle}>
                      {header.title}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
            {actionable && 
              <Grid container item xs={1} alignContent='center'>
                <Typography style={{ margin: '0px auto' }} className={headerStyle}>
                  Actions
                </Typography>
              </Grid>
            }
          </Grid>
        </Grid>
      }
      {children}
    </>
  );
};
