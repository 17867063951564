import {
    CREATE_PROJECT_ESTIMATE_REQUEST,
    CREATE_PROJECT_ESTIMATE_SUCCESS,
    CREATE_PROJECT_ESTIMATE_FAILURE,
    DELETE_PROJECT_ESTIMATE_REQUEST,
    DELETE_PROJECT_ESTIMATE_SUCCESS,
    DELETE_PROJECT_ESTIMATE_FAILURE,
    UPDATE_PROJECT_ESTIMATE_REQUEST,
    UPDATE_PROJECT_ESTIMATE_SUCCESS,
    UPDATE_PROJECT_ESTIMATE_FAILURE,
    GET_PROJECT_ESTIMATE_SUCCESS,
    GET_PROJECT_ESTIMATE_REQUEST,
    GET_PROJECT_ESTIMATE_FAILURE,
} from '../actions/ProjectEstimate';

function projectEstimate(state = {
    estimate: {
        id: '',
        name: '',
        subTotal: 0,
        taxes: 0,
        grandTotal: 0,
        discount: 0,
        createdAt: '',
        updatedAt: '',
        isChosen: false,
    },
    isFetching: false,
}, action) {
    switch (action.type) {
    case GET_PROJECT_ESTIMATE_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
            estimate: {
                id: '',
                name: '',
                subTotal: 0,
                taxes: 0,
                grandTotal: 0,
                discount: 0,
                createdAt: '',
                updatedAt: '',
                isChosen: false,
            },
        });
    case GET_PROJECT_ESTIMATE_SUCCESS:
        return Object.assign({}, state, {
            estimate: action.response,
            isFetching: false,
        });
    case GET_PROJECT_ESTIMATE_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            estimate: {},
            errorMessage: action.message,
        });
    case CREATE_PROJECT_ESTIMATE_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case CREATE_PROJECT_ESTIMATE_SUCCESS:
        return Object.assign({}, state, {
            estimate: action.response,
            isFetching: false,
        });
    case CREATE_PROJECT_ESTIMATE_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case DELETE_PROJECT_ESTIMATE_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case DELETE_PROJECT_ESTIMATE_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case DELETE_PROJECT_ESTIMATE_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case UPDATE_PROJECT_ESTIMATE_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case UPDATE_PROJECT_ESTIMATE_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case UPDATE_PROJECT_ESTIMATE_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
        });
    default:
        return state;
    }
}

export default projectEstimate;
