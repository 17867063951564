import { createStyles, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

type OrderSummaryHeaderProps = {
    header: string;
    isRequired?: boolean;
};

const useStyles = makeStyles(() =>
    createStyles({
        buttonsContainer: {
            display: 'flex',
        },
        sectionTitle: {
            color: 'var(--color-black, #222)',
            fontSize: '1.125rem',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
            padding: '2.188rem 0rem 0.938rem 0rem',
        },
        required: {
            color: 'var(--color-negative-200, #EA1D3D)',
        },
    })
);

const OrderSummaryHeader = ({
    header,
    isRequired,
}: OrderSummaryHeaderProps) => {
    const classes = useStyles();

    return (
        <Typography className={classes.sectionTitle}>
            {header}
            {isRequired && (
                <Typography
                    variant='inherit'
                    display='inline'
                    className={classes.required}
                >
                    *
                </Typography>
            )}
        </Typography>
    );
};

export default OrderSummaryHeader;
