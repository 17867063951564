import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMaterialItemsByOfficeId } from '../../actions/MaterialItemsActions';
import {
    createEstimateItem,
    deleteEstimateItem,
} from '../../actions/ProjectEstimateItemActions';
import { getProjectEstimateItems } from '../../actions/ProjectEstimateItemsActions';
import { getMaterialItemColorOptions } from '../../actions/MaterialItemColorOptionsActions';
import MaterialItemsDialog from '../MaterialItemsDialog';
import FloatingActionButton from '../FloatingActionButton';
import { getProjectEstimate } from '../../actions/ProjectEstimate';

class CreateEstimateItem extends Component {
    state = {
        rootCategoryTabValue: 0,
        rootCategory: '',
        searchValue: '',
        materialItemsDialogOpen: false,
    };

    componentDidMount() {
        this.getMaterialItems();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.officeId === this.props.officeId) {
            return;
        }

        this.getMaterialItems();
    }

    getMaterialItems = (rootCategory = '', search = '', page = 1) => {
        if (!this.props.officeId) {
            return;
        }

        const params = {
            rootCategory,
            search,
            page,
        };

        this.props.dispatch(getMaterialItemsByOfficeId(this.props.officeId, params));
    };

    getMaterialItemColorOptions = (materialItemId, page = 1) => {
        this.props.dispatch(getMaterialItemColorOptions(this.props.officeId, materialItemId, page));
    };

    getNextPageColorOptions = (materialItem) => {
        const page = this.props.colorOptionsCurrentPage + 1;
        this.getMaterialItemColorOptions(materialItem.id, page);
    };

    getPrevPageColorOptions = (materialItem) => {
        const page = this.props.colorOptionsCurrentPage - 1;
        this.getMaterialItemColorOptions(materialItem.id, page);
    };

    changeRootCategoryFilter = (tabValue, rootCategory) => {
        this.setState({ rootCategoryTabValue: tabValue, rootCategory });
        this.props.dispatch(getProjectEstimateItems(
            this.props.projectId,
            this.props.estimateId,
            1,
            true,
        )).then(() => {
            this.getMaterialItems(rootCategory, this.state.searchValue);
        });
    };

    searchMaterialItems = (searchValue) => {
        this.setState({ searchValue });
        this.props.dispatch(getProjectEstimateItems(
            this.props.projectId,
            this.props.estimateId,
            1,
            true,
        )).then(() => {
            this.getMaterialItems(this.state.rootCategory, this.state.searchValue);
        });
    };

    nextMaterialItemsPageClick = () => {
        const page = this.props.currentPage + 1;
        this.props.dispatch(getProjectEstimateItems(
            this.props.projectId,
            this.props.estimateId,
            1,
            true,
        )).then(() => {
            this.getMaterialItems(
                this.state.rootCategory,
                this.state.searchValue,
                page,
            );
        });
    };

    prevMaterialItemsPageClick = () => {
        const page = this.props.currentPage - 1;
        this.props.dispatch(getProjectEstimateItems(
            this.props.projectId,
            this.props.estimateId,
            1,
            true,
        )).then(() => {
            this.getMaterialItems(
                this.state.rootCategory,
                this.state.searchValue,
                page,
            );
        });
    };

    handleCreateEstimateItem = (materialItem) => {
        if (!materialItem) {
            return;
        }

        if (materialItem.colorOptionsCount > 1) {
            this.getMaterialItemColorOptions(materialItem.id);
            return;
        }

        this.createEstimateItem(materialItem);
    };

    handleOnColorOptionClick = (materialItemId, colorOption) => {
        const materialItem = {
            id: materialItemId,
            colorId: colorOption.id,
            image: colorOption.image,
        };
        this.createEstimateItem(materialItem);
    };

    createEstimateItem = (materialItem) => {
        const newEstimateItem = {
            materialItemId: materialItem.id,
            colorId: materialItem.colorId,
            image: materialItem.image,
        };
        this.props
            .dispatch(createEstimateItem(
                this.props.projectId,
                this.props.estimateId,
                newEstimateItem,
            ))
            .then(() => {
                this.props.dispatch(getProjectEstimateItems(
                    this.props.projectId,
                    this.props.estimateId,
                    1,
                    true,
                ));
                this.props.dispatch(getProjectEstimate(
                    this.props.projectId,
                    this.props.estimateId,
                ));
            });
    };

    deleteEstimateItem = (estimateItemId) => {
        this.props
            .dispatch(deleteEstimateItem(this.props.projectId, estimateItemId))
            .then(() => {
                this.props.dispatch(getProjectEstimate(
                    this.props.projectId,
                    this.props.estimateId,
                ));
            });
    };

    openMaterialItemsDialog = () => {
        this.setState({ materialItemsDialogOpen: true });
    };

    closeMaterialItemsDialog = () => {
        this.setState({ materialItemsDialogOpen: false });
        this.props.dispatch(getProjectEstimateItems(
            this.props.projectId,
            this.props.estimateId,
            1,
            true,
        ));
    };

    render() {
        return (
            <div>
                <FloatingActionButton onClick={this.openMaterialItemsDialog} variant="extended" />
                <MaterialItemsDialog
                    open={this.state.materialItemsDialogOpen}
                    onDialogDismiss={this.closeMaterialItemsDialog}
                    materialItems={this.props.materialItems}
                    totalResults={this.props.totalResults}
                    totalPages={this.props.totalPages}
                    currentPage={this.props.currentPage}
                    rootCategoryFilter={this.state.rootCategoryTabValue}
                    onRootCategoryFilterSwitch={this.changeRootCategoryFilter}
                    materielItemsFetching={this.props.materielItemsFetching}
                    onSearch={this.searchMaterialItems}
                    searchValue={this.state.searchValue}
                    onPrevClick={this.prevMaterialItemsPageClick}
                    onNextClick={this.nextMaterialItemsPageClick}
                    estimateItems={this.props.estimateItems}
                    createEstimateItem={this.handleCreateEstimateItem}
                    deleteEstimateItem={this.deleteEstimateItem}
                    colorOptions={this.props.colorOptions}
                    onColorOptionClick={this.handleOnColorOptionClick}
                    totalColorOptions={this.props.totalColorOptions}
                    totalColorOptionsPages={this.props.totalColorOptionsPages}
                    colorOptionsCurrentPage={this.props.colorOptionsCurrentPage}
                    onNextPageColorOptionsClick={this.getNextPageColorOptions}
                    onPrevPageColorOptionsClick={this.getPrevPageColorOptions}
                />
            </div>
        );
    }
}

const materialItemProps = {
    id: PropTypes.number.isRequired,
    officeId: PropTypes.string.isRequired,
    rootCategoryName: PropTypes.string.isRequired,
    categoryName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string,
    colorOptionsCount: PropTypes.number.isRequired,
};

const colorOptionProps = {
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.string,
    materialItemId: PropTypes.number.isRequired,
    isDeleted: PropTypes.bool,
    isCustom: PropTypes.bool,
    isHidden: PropTypes.bool,
};

CreateEstimateItem.propTypes = {
    dispatch: PropTypes.func.isRequired,
    officeId: PropTypes.number,
    materialItems: PropTypes.arrayOf(PropTypes.shape(materialItemProps))
        .isRequired,
    totalPages: PropTypes.number.isRequired,
    totalResults: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    materielItemsFetching: PropTypes.bool.isRequired,
    estimateItems: PropTypes.arrayOf(PropTypes.shape({
        materialItemId: PropTypes.number,
    })),
    projectId: PropTypes.string.isRequired,
    estimateId: PropTypes.string.isRequired,
    colorOptions: PropTypes.arrayOf(PropTypes.shape(colorOptionProps))
        .isRequired,
    totalColorOptions: PropTypes.number.isRequired,
    totalColorOptionsPages: PropTypes.number.isRequired,
    colorOptionsCurrentPage: PropTypes.number.isRequired,
};

CreateEstimateItem.defaultProps = {
    estimateItems: [],
    officeId: 0,
};

const mapStateToProps = (state) => {
    const {
        projectHome,
        materialItemsReducer,
        projectEstimateItems,
        materialItemColorOptionsReducer,
    } = state;
    const { currentProject } = projectHome;
    const { officeId } = currentProject;
    const {
        totalResults,
        totalPages,
        currentPage,
        materialItems,
        materielItemsFetching,
    } = materialItemsReducer;
    const { estimateItems } = projectEstimateItems;
    const {
        colorOptions,
        totalResults: totalColorOptions,
        totalPages: totalColorOptionsPages,
        currentPage: colorOptionsCurrentPage,
    } = materialItemColorOptionsReducer;

    return {
        officeId,
        totalResults,
        totalPages,
        currentPage,
        materialItems,
        materielItemsFetching,
        estimateItems,
        colorOptions,
        totalColorOptions,
        totalColorOptionsPages,
        colorOptionsCurrentPage,
    };
};

export default connect(mapStateToProps)(CreateEstimateItem);
