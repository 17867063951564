import React, { useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Divider, Grid, Typography, Button, Link, useMediaQuery, useTheme } from '@material-ui/core';
import roofsnapTheme from '../../lib/theme';
import useLocalStorage from '../../hooks/useLocalStorageHook';
import { ExpiredDialogChoices } from '../../lib/ExpiredDialogChoices';
import OrganizationStatus from '../../lib/OrganizationStatus';

const styles = (theme: any) => createStyles({
    root: {
        [theme.breakpoints.up('md')]: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    card: {
        maxWidth: 800,
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2),
            marginTop: theme.spacing(4),
            bottom: theme.spacing(2),
            position: 'relative',
        },
    },
    cardContent: {
        [theme.breakpoints.up('md')]: {
            padding: '50px 130px 25px 130px',
        },
    },
    text1: {
        fontSize: 34,
        [theme.breakpoints.down('xs')]: {
            fontSize: 24,
        },
    },
    text2: {
        fontSize: 20,
    },
    text3: {
        fontSize: 16,
    },
    image: {
        maxWidth: '100%',
        marginTop: '20px',
    },
    imageContainer: {
        maxWidth: '530px',
        textAlign: 'center',
        margin: 'auto',
    },
    buttonContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    button: {
        width: 215,
    },
    mobileOrderButtonContainer: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    mobileOrderButton: {
        backgroundColor: '#ffffff',
        color: roofsnapTheme.palette.roofsnapBlue[500],
    },
    footerCardContent: {
        paddingBottom: '16px !important',
    },
    footer: {
        marginBottom: 0,
    },
    link: {
        color: roofsnapTheme.palette.roofsnapBlue[500],
        textDecoration: 'underline',
    },
});

type ExpiredAccountProps = {
    classes: any,
    history: any,
    status: string,
    currentUser: any,
};

const ExpiredAccount = (props: ExpiredAccountProps) => {
    const {
        classes, history, currentUser, status,
    } = props;
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [expiredDialogSeen, setExpiredDialogSeen] = useLocalStorage('rs.expiredDialogSeen', { dialogSeen: false, choice: null, userId: currentUser.userId });
    const handleButtonClick = (choice: string, route: string) => {
        setExpiredDialogSeen({ ...expiredDialogSeen, dialogSeen: true, choice });
        history.push(route);
    };
    const trialOrSubscription = status === OrganizationStatus.Subscription ? 'subscription' : 'free trial';
    // once user has seen this page, put marker in local storage
    useEffect(() => {
        setExpiredDialogSeen({ ...expiredDialogSeen, dialogSeen: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (

        <div className={classes.root}>
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Grid item>
                            <Typography className={classes.text1} paragraph variant="h4" align="center">Time to Roll Up Our Sleeves</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.text2} paragraph color="primary" variant="h6" align="center">Your {trialOrSubscription} has expired, but we're just getting started!</Typography>
                        </Grid>
                        {!mobile &&
                            <Grid item>
                                <Typography className={classes.text3} paragraph variant="body2" color="textSecondary" align="center"><strong>Subscribe</strong> now for order discounts and to unlock RoofSnap's full suite of tools or <strong>order measurements</strong> as needed without a subscription.</Typography>
                            </Grid>
                        }
                        <Grid className={classes.imageContainer} item>
                            <img
                                src="/assets/free-order.png"
                                alt="artwork"
                                className={props.classes.image}
                            />
                        </Grid>
                        <Grid className={classes.buttonContainer} container item justify="center" spacing={1}>
                            <Grid item>
                                <Button className={classes.button} variant="contained" color="primary" onClick={() => handleButtonClick(ExpiredDialogChoices.Subscribe, '/settings/billing')}>Subscribe Now</Button>
                            </Grid>
                            {!mobile &&
                                <Grid item>
                                    <Button className={classes.button} variant="contained" color="primary" onClick={() => handleButtonClick(ExpiredDialogChoices.OrderAsNeeded, '/orders/create')}>Order Measurements</Button>
                                </Grid>
                            }
                        </Grid>
                        {mobile &&
                            <React.Fragment>
                                <Grid item>
                                    <Typography className={classes.text3} paragraph variant="body2" color="textSecondary" align="center"><strong>Subscribe</strong> now for order discounts and to unlock RoofSnap's full suite of tools or <strong>order measurements</strong> as needed without a subscription.</Typography>
                                </Grid>
                                <Divider style={{ width: '100%' }} />
                                <Grid className={classes.mobileOrderButtonContainer} item>
                                    <Button className={classes.mobileOrderButton} variant="contained" onClick={() => handleButtonClick(ExpiredDialogChoices.OrderAsNeeded, '/orders/create')}>Order Measurements</Button>
                                </Grid>
                            </React.Fragment>
                        }
                        <Grid item>
                            <Typography variant="body2">
                                <Link className={classes.link} component={RouterLink} to="/pricing">Compare plans and pricing</Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardContent className={classes.footerCardContent}>
                    <Typography className={classes.footer} paragraph align="center" variant="body2">Questions or comments with your account? <a className={classes.link} href="https://roofsnap.com/contact-us/">Contact Us</a></Typography>
                </CardContent>
            </Card>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    const {
        organization,
        currentUser,
    } = state;
    const { status } = organization;
    return {
        status,
        currentUser,
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(ExpiredAccount)));
