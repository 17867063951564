import React, { useState, useEffect, useRef } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    FormControl,
    Input,
    InputLabel,
    FormHelperText,
    Button,
    Grid,
    Typography
} from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { Check, ErrorOutline } from '@material-ui/icons';

interface ResetPasswordFormProps extends WithStyles<typeof styles> {
    onSubmitClick: (password: string) => void,
    title: string,
    subheader: string,
    label: string,
    confirmLabel: string,
    buttonText: string,
    additionalFields: any,
    onError: () => void
}

const styles = (theme: any) => createStyles({
    card: {
        padding: theme.spacing(3),
    },
    formControl: {
        width: 300
    },
    requirement: {
        padding: 0,
        height: 18
    },
    icon: {
        width: 14,
        height: 14
    }

});

const ResetPasswordForm = (props: ResetPasswordFormProps) => {
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [confirmNewPasswordIsValid, setConfirmNewPasswordIsValid] = useState(true)
    const [confirmPasswordIsValid, setConfirmPasswordIsValid] = useState(true)
    const hasFocusedNew = useRef<boolean>(false)
    const hasFocusedConfirm = useRef<boolean>(false)
    const [passwordCheck, setPasswordCheck] = useState({
        charLength: true,
        lowerCase: true,
        upperCase: true,
        number: true,
        specialChar: true,
    })

    const handleNewPasswordChange = (event: any) => {
        setNewPassword(
            event.target.value
        )
        hasFocusedNew.current = true
    }

    const handleConfirmPassword = (event: any) => {
        setConfirmPassword(
            event.target.value
        )
        hasFocusedConfirm.current = true
    }

    const checkConfirmPassword = () => {
        newPassword === confirmPassword ?
            setConfirmPasswordIsValid(true) :
            setConfirmPasswordIsValid(false)
    }

    useEffect(() => {
        checkNewPassword(newPassword)
        if (hasFocusedConfirm.current) {
            checkConfirmPassword()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPassword])

    useEffect(() => {
        if (hasFocusedNew.current) {
            Object.values(passwordCheck).includes(false)
                ?
                setConfirmNewPasswordIsValid(false) :
                setConfirmNewPasswordIsValid(true)
        } else {
            return
        }
    }, [passwordCheck])

    useEffect(() => {
        if (hasFocusedConfirm.current) {
            checkConfirmPassword()
        } else {
            return
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmPassword])

    const checkNewPassword = (password: string) => {
        const lowerCaseLetters = /[a-z]/g
        const upperCaseLetters = /[A-Z]/g
        const numbers = /[0-9]/g
        const specialCharacters = /[^A-Za-z0-9]/g

        let length = false
        let charLower = false
        let charUpper = false
        let intChar = false
        let special = false

        password.length > 7 ?
            length = true : length = false

        password.match(lowerCaseLetters) ?
            charLower = true : charLower = false

        password.match(upperCaseLetters) ?
            charUpper = true : charUpper = false

        password.match(numbers) ?
            intChar = true : intChar = false

        password.match(specialCharacters) ?
            special = true : special = false

        setPasswordCheck({
            charLength: length,
            lowerCase: charLower,
            upperCase: charUpper,
            number: intChar,
            specialChar: special
        })
    }

    

    const handleOnSubmit = () => {

        if (!confirmPasswordIsValid || !confirmNewPasswordIsValid || newPassword.match(' ')) {
            props.onError();
            return;
        }

        props.onSubmitClick(newPassword);
    };

    const handleKeyPress = (event: any) => {
        if (event.charCode === 13) {
            handleOnSubmit();
        }
    };

    const requirements = [
        {
            id: "charLength",
            requirementIcon:
                newPassword === ''
                    && confirmPassword === ''
                    && !hasFocusedNew.current
                    && !hasFocusedConfirm.current ?
                    "•" : passwordCheck.charLength ?
                        <Check name="checkIcon" className={props.classes.icon} style={{ color: '#40ad48' }} /> :
                        <ErrorOutline name="errorIcon" className={props.classes.icon} style={{ color: '#f44336' }} />,
            requirementText: "At least 8 characters"
        },
        {
            id: "lowerCase",
            requirementIcon: newPassword === ''
                && confirmPassword === ''
                && !hasFocusedNew.current
                && !hasFocusedConfirm.current ?

                "•" : passwordCheck.lowerCase ?
                    <Check name="checkIcon" className={props.classes.icon} style={{ color: '#40ad48' }} /> :
                    <ErrorOutline name="errorIcon" className={props.classes.icon} style={{ color: '#f44336' }} />,
            requirementText: "A lowercase character"
        },
        {
            id: "upperCase",
            requirementIcon:
                newPassword === ''
                    && confirmPassword === ''
                    && !hasFocusedNew.current
                    && !hasFocusedConfirm.current ?
                    "•" : passwordCheck.upperCase ?
                        <Check name="checkIcon" className={props.classes.icon} style={{ color: '#40ad48' }} /> :
                        <ErrorOutline name="errorIcon" className={props.classes.icon} style={{ color: '#f44336' }} />,
            requirementText: "An uppercase letter"
        },
        {
            id: "number",
            requirementIcon:
                newPassword === ''
                    && confirmPassword === ''
                    && !hasFocusedNew.current
                    && !hasFocusedConfirm.current ?
                    "•" : passwordCheck.number ?
                        <Check name="checkIcon" className={props.classes.icon} style={{ color: '#40ad48' }} /> :
                        <ErrorOutline name="errorIcon" className={props.classes.icon} style={{ color: '#f44336' }} />,
            requirementText: "A number"
        },
        {
            id: "specialChar",
            requirementIcon:
                newPassword === ''
                    && confirmPassword === ''
                    && !hasFocusedNew.current
                    && !hasFocusedConfirm.current ?
                    "•" : passwordCheck.specialChar ?
                        <Check name="checkIcon" className={props.classes.icon} style={{ color: '#40ad48' }} /> :
                        <ErrorOutline name="errorIcon" className={props.classes.icon} style={{ color: '#f44336' }} />,
            requirementText: "A special character"
        },
    ]

    return (
        <Card className={props.classes.card}>
            <CardHeader
                title={props.title}
                subheader={props.subheader}
            />
            <CardContent className={props.classes.card}>
                <Grid container direction="column" spacing={2}>

                    <Grid item container style={{ padding: 0 }}>
                        <FormControl className={props.classes.formControl} error={!confirmNewPasswordIsValid}>
                            <InputLabel htmlFor="newPassword">{props.label}</InputLabel>
                            <Input
                                name="newPassword"
                                id="newPassword"
                                type="password"
                                value={newPassword}
                                onChange={handleNewPasswordChange}
                                onKeyPress={handleKeyPress}
                            />
                            {
                                !confirmNewPasswordIsValid &&
                                <FormHelperText id="newPasswordErrorText">Password doesn't meet requirements</FormHelperText>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item container style={{ padding: '20px 0px 0px 0px' }}>
                        <FormControl className={props.classes.formControl} error={!confirmPasswordIsValid}>
                            <InputLabel htmlFor="confirmNewPassword">{props.confirmLabel}</InputLabel>
                            <Input
                                name="confirmNewPassword"
                                id="confirmNewPassword"
                                type="password"
                                value={confirmPassword}
                                onChange={handleConfirmPassword}
                                onKeyPress={handleKeyPress}
                            />
                            {
                                !confirmPasswordIsValid &&
                                <FormHelperText id="confirmNewPasswordErrorText" >Passwords must match</FormHelperText>
                            }
                        </FormControl>
                    </Grid>
                    {props.additionalFields}
                    <Grid style={{ paddingTop: 20 }}>
                        <Typography style={{ fontSize: '0.75rem' }}>
                            New password must contain:
                        </Typography>
                    </Grid>
                    {
                        requirements.map(r => {
                            return (
                                <Grid id={r.id} style={{ padding: 4 }} item container justifyContent="space-evenly" alignItems="center" xs={12}>
                                    <Grid id={r.id + "Icon"} container item xs={2} justifyContent="center" style={{ fontSize: '0.75rem' }}>
                                        {r.requirementIcon}
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography style={{ fontSize: '0.75rem' }}>
                                            {r.requirementText}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </CardContent>
            <CardActions>
                <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={handleOnSubmit}
                    disabled={!confirmNewPasswordIsValid || !confirmPasswordIsValid || newPassword === '' || confirmPassword === ''}
                    id="changePasswordButton"
                >
                    {props.buttonText}
                </Button>
            </CardActions>
        </Card>
    );
}

export default withStyles(styles)(ResetPasswordForm);