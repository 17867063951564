import React from 'react'
import { Switch, Typography, Grid, useMediaQuery, Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

interface SettingSwitchProps extends WithStyles<typeof styles> {
    checked: boolean,
    onChange: (event: any) => void,
    name: string,
}

const styles = () =>
    createStyles({
        switch: {
            width: 50,
            height: 26,
            padding: 0,
            '& .MuiSwitch-switchBase': {
                padding: 0,
                margin: 3,
                transitionDuration: '300ms',
                '&.Mui-checked': {
                    transform: 'translateX(24px)',
                    color: '#fff',
                    '& + .MuiSwitch-track': {
                        backgroundColor: '#1E79D6',
                        opacity: 1,
                        border: 0,
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: 0.5,
                    },
                },
                '&.Mui-focusVisible .MuiSwitch-thumb': {
                    color: '#33cf4d',
                    border: '6px solid #fff',
                },
                '&.Mui-disabled .MuiSwitch-thumb': {
                    color: '#DDDDDD',
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: '#FFF'
                },
            },
            '& .MuiSwitch-thumb': {
                boxSizing: 'border-box',
            },
            '& .MuiSwitch-track': {
                borderRadius: 26 / 2,
                backgroundColor: '#DDDDDD',
                opacity: 1,
            },
        }
    })

const SettingSwitch = (props: SettingSwitchProps) => {
    const { classes } = props;

    const smallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down(390));

    return (
        <Grid
            alignItems='center'
            container
            item
            xs={12}
            justifyContent='flex-end'
            spacing={1}
            direction='row'
        >
            <Grid item>
                <Typography style={{ fontSize: smallScreen ? 13 : 16 }}>Hide</Typography>
            </Grid>
            <Grid item>
                <Switch
                    checked={
                        props.checked
                    }
                    onChange={(event: any) => props.onChange(event)}
                    inputProps={{
                        'aria-label': 'controlled',
                    }}
                    name={props.name}
                    className={classes.switch}
                    value={props.checked}
                />
            </Grid>
            <Grid item>
                <Typography style={{ fontSize: smallScreen ? 13 : 16 }}>Show</Typography>
            </Grid>
        </Grid>


    )
}

export default withStyles(styles)(SettingSwitch);
