import { some } from 'lodash';
import EditEstimateItemModel from '../Models/EditEstimateItemModel';

const mapEstimateItemToEditEstimateItemModel = (estimateItem) => {
    if (!estimateItem) return null;

    const newEditEstimateItem = new EditEstimateItemModel();
    newEditEstimateItem.id = estimateItem.id;
    newEditEstimateItem.description = estimateItem.description;
    newEditEstimateItem.materialItemId = estimateItem.materialItemId;
    newEditEstimateItem.units = estimateItem.units;
    newEditEstimateItem.unitType = estimateItem.unitType;
    newEditEstimateItem.coveragePerUnit = estimateItem.coveragePerUnit;
    newEditEstimateItem.color = estimateItem.color;
    newEditEstimateItem.laborCost = estimateItem.laborCost;
    newEditEstimateItem.materialCost = estimateItem.materialCost;
    newEditEstimateItem.subItemMaterialCost = estimateItem.subItemMaterialCost;
    newEditEstimateItem.materialOrderDescription = estimateItem.materialOrderDescription;
    newEditEstimateItem.totalPerUnit = estimateItem.totalPerUnit;
    newEditEstimateItem.hideOnEstimate = estimateItem.hideOnEstimate;
    newEditEstimateItem.hideOnContract = estimateItem.hideOnContract;
    newEditEstimateItem.hideOnMaterialOrder = estimateItem.hideOnMaterialOrder;
    newEditEstimateItem.hideOnLaborReport = estimateItem.hideOnLaborReport;
    newEditEstimateItem.version = estimateItem.version;
    newEditEstimateItem.estimateId = estimateItem.estimateId;
    newEditEstimateItem.colorOptionsCount = estimateItem.colorOptionsCount;

    return newEditEstimateItem;
};

const isItemAttachedToPinOnProjectDrawing = (estimateItem, projectDrawing) => {
    if (!estimateItem || !estimateItem.materialItemId) {
        return false;
    }

    if (!projectDrawing) {
        return false;
    }

    const { pins } = projectDrawing;

    const itemIsOnDrawing = some(pins, ['material', estimateItem.materialItemId.toString()]);

    return itemIsOnDrawing;
};

const EditEstimateItemModelFactory = {
    create(estimateItem, projectDrawing, allowedToEditEstimateItems) {
        const newEditEstimateItem = mapEstimateItemToEditEstimateItemModel(estimateItem);

        if (!newEditEstimateItem) return null;

        let overrideMeasurements = false;
        if (projectDrawing && projectDrawing.measurements) {
            overrideMeasurements = projectDrawing.measurements.overrideDrawingMeasurements;
        }

        const attachedToPinOnProjectDrawing = isItemAttachedToPinOnProjectDrawing(estimateItem, projectDrawing);

        // determine if we can edit the units of this item
        if (!newEditEstimateItem.materialItemId) {
            newEditEstimateItem.unitsEnabled = true;
        } else if (attachedToPinOnProjectDrawing && !overrideMeasurements) {
            newEditEstimateItem.unitsEnabled = false;
        } else if (overrideMeasurements || newEditEstimateItem.unitType === 'Each') {
            newEditEstimateItem.unitsEnabled = true;
        } else {
            newEditEstimateItem.unitsEnabled = false;
        }

        if (allowedToEditEstimateItems || !newEditEstimateItem.materialItemId) {
            newEditEstimateItem.canEditItem = true;
            newEditEstimateItem.laborCostVisible = true;
            newEditEstimateItem.materialCostVisible = true;
        } else {
            newEditEstimateItem.canEditItem = false;
            newEditEstimateItem.laborCostVisible = false;
            newEditEstimateItem.materialCostVisible = false;
        }

        return newEditEstimateItem;
    },
};

export default EditEstimateItemModelFactory;
