import * as ActionTypes from '../actions/BillingFailedSketchOrdersActions';

const initState = {
    isFetching: false,
    totalResults: 0,
    totalPages: 0,
    currentPage: 0,
    sketchOrders: [],
};

const billingFailedSketchOrdersReducer = (state = initState, action) => {
    switch (action.type) {
    case ActionTypes.GET_ORGANIZATIONS_BILLING_FAILED_SKETCH_ORDERS_REQUEST:
    case ActionTypes.GET_REQUESTED_OR_OWNED_BILLING_FAILED_SKETCH_ORDERS_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case ActionTypes.GET_ORGANIZATIONS_BILLING_FAILED_SKETCH_ORDERS_SUCCESS:
    case ActionTypes.GET_REQUESTED_OR_OWNED_BILLING_FAILED_SKETCH_ORDERS_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            totalResults: action.response.totalResults,
            totalPages: action.response.totalPages,
            currentPage: action.response.currentPage,
            sketchOrders: action.response.resourceList,
        });
    case ActionTypes.GET_ORGANIZATIONS_BILLING_FAILED_SKETCH_ORDERS_FAILURE:
    case ActionTypes.GET_REQUESTED_OR_OWNED_BILLING_FAILED_SKETCH_ORDERS_FAILURE:
        return Object.assign({}, state, initState);
    default:
        return state;
    }
};

export default billingFailedSketchOrdersReducer;
