import { Box } from '@mui/material';
import { styled } from '@mui/system';

const SectionContainer = styled(Box)({
    borderRadius: '0.75rem',
    border: '1px solid #CCC',
    backgroundColor: '#FFF',
    padding: '12px 25px 12px 25px',
    display: 'flex',
    minHeight: '13.6875rem',
    alignItems: 'center',
    gap: '1.875rem',
    flexShrink: 0,
});

export default SectionContainer;
