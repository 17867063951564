import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDocuments } from '../../actions/documentsActions';
import { getProjectById } from '../../actions/ProjectActions';
import { getDocumentTemplateCategories } from '../../actions/documentTemplateCategoriesActions';
import {
    createDocument,
    CREATE_DOCUMENT_FAILURE,
} from '../../actions/documentActions';
import FloatingActionButton from '../FloatingActionButton';
import CreateDocumentDialog from '../CreateDocumentDialog';
import FormHtmlDialog from './FormHtmlDialog.tsx';
import ChooseSingleEstimateDocumentDialog from './ChooseSingleEstimateDocumentDialog';
import ChooseMultiEstimateDialog from '../ChooseMultiEstimateDialog';
import { DocumentDataContextType } from '../../lib/documentDataContextType';
import { getDocumentTemplateCategoryByV1Type } from '../../lib/documentTemplateV1TypeToCategory';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';
import LoggerWrapper from '../../lib/Logger';
import DocumentCategoryNames from '../../lib/DocumentsCategories.ts';

class CreateDocument extends Component {
    state = {
        formHtmlDialogIsOpen: false,
        chooseSingleEstimateDialogIsOpen: false,
        newCreateDocumentDialogIsOpen: this.props.open,
        selectMultiEstimateDialogIsOpen: false,
        selectedDocumentTemplate: {},
        selectedDocumentTemplateCategoryName: '',
        selectedEstimateId: null,
        selectedDocumentId: '',
    };

    async componentDidMount() {
        await this.props.dispatch(getProjectById(this.props.projectId));
        await this.props.dispatch(
            getDocumentTemplateCategories(this.props.officeId)
        );
    }

    getDataContextEntityId = (documentDataContextType) => {
        switch (documentDataContextType) {
        case DocumentDataContextType.Project:
        case DocumentDataContextType.ProjectDrawing:
            return this.props.projectId;
        default:
            return null;
        }
    };

    openFormHtmlDialog = () => {
        this.setState({
            formHtmlDialogIsOpen: true,
            chooseSingleEstimateDialogIsOpen: false,
            newCreateDocumentDialogIsOpen: false,
            selectMultiEstimateDialogIsOpen: false,
        });
    };

    openChooseSingleEstimateDocumentDialog = () => {
        this.setState({
            chooseSingleEstimateDialogIsOpen: true,
            formHtmlDialogIsOpen: false,
            newCreateDocumentDialogIsOpen: false,
            selectMultiEstimateDialogIsOpen: false,
        });
    };

    openNewDocumentDialog = () => {
        this.setState({
            newCreateDocumentDialogIsOpen: true,
            formHtmlDialogIsOpen: false,
            chooseSingleEstimateDialogIsOpen: false,
            selectMultiEstimateDialogIsOpen: false,
        });
    };

    openChooseMultiEstimateDialog = () => {
        this.setState({
            selectMultiEstimateDialogIsOpen: true,
            newCreateDocumentDialogIsOpen: false,
            formHtmlDialogIsOpen: false,
            chooseSingleEstimateDialogIsOpen: false,
        });
    };

    resetState = () => {
        this.setState({
            formHtmlDialogIsOpen: false,
            chooseSingleEstimateDialogIsOpen: false,
            newCreateDocumentDialogIsOpen: false,
            selectMultiEstimateDialogIsOpen: false,
            selectedDocumentTemplate: {},
            selectedDocumentTemplateCategoryName: '',
            selectedEstimateId: null,
            selectedDocumentId: '',
        });
        this.generating = false;
    };

    handleFormHtmlDialogRequestClose = async () => {
        this.props.dispatch(getDocuments(this.props.projectId));
        this.resetState();
    };

    selectEstimate = (estimateId) => {
        this.setState({
            selectedEstimateId: estimateId,
        });
        if (
            this.state.selectedDocumentTemplate.hasHtmlForm &&
            this.state.selectedDocumentTemplate.usesLegacySigningWorkflow
        ) {
            this.openFormHtmlDialog();
            return;
        }

        const params = {
            template: this.state.selectedDocumentTemplate,
            dataContextEntityId: estimateId,
        };
        this.createDocument(params);
    };

    createDocument = async ({
        template,
        dataContextEntityId,
        estimateOptionIds,
    }) => {
        if (!this.props.organization.id) {
            this.props.dispatch(
                setReduxMessage(
                    'Sorry, the document failed to be created. Please try again.'
                )
            );
            return;
        }

        const hasV2HtmlForm =
            !template.usesLegacySigningWorkflow && template.hasHtmlForm;

        const params = {
            organizationId: this.props.organization.id,
            templateId: template.id,
            dataContextEntityId,
            estimateOptionIds,
            autoCreateRendering: !hasV2HtmlForm,
        };
        const result = await this.props.dispatch(createDocument(params));
        if (result.type === CREATE_DOCUMENT_FAILURE) {
            this.props.dispatch(
                setReduxMessage(
                    'Sorry, the document failed to be created. Please try again.'
                )
            );
        }

        if (hasV2HtmlForm) {
            this.setState({ selectedDocumentId: result.response.id });
            this.openFormHtmlDialog();
        } else {
            this.resetState();
        }
        this.props.dispatch(getDocuments(this.props.projectId));
    };

    selectDocumentTemplate = (template, templateCategory) => {
        if (this.generating) {
            return;
        }
        // Set state
        this.generating = true;
        this.setState({
            selectedDocumentTemplate: template,
            selectedDocumentTemplateCategoryName: templateCategory.name,
        });

        if (
            template.documentDataContextType ===
            DocumentDataContextType.Estimate
        ) {
            if (this.props.estimates.length === 1) {
                if (template.usesLegacySigningWorkflow) {
                    this.openFormHtmlDialog();
                    this.generating = false;
                    return;
                }

                const dataContextEntityId = this.props.estimates[0].id;
                this.createDocument({ template, dataContextEntityId });
            } else {
                this.openChooseSingleEstimateDocumentDialog();
            }

            try {
                if (
                    templateCategory.name ===
                    DocumentCategoryNames.MaterialOrder
                ) {
                    window.Appcues.track('Created Material Order Document');
                } else {
                    window.Appcues.track('Created Estimate Document');
                }
            } catch (error) {
                LoggerWrapper.captureException(error);
            }

            this.generating = false;
            return;
        }

        if (
            template.documentDataContextType ===
            DocumentDataContextType.MultiEstimate
        ) {
            this.openChooseMultiEstimateDialog();
            this.generating = false;
            return;
        }

        if (template.hasHtmlForm) {
            this.openFormHtmlDialog();
            this.generating = false;
            return;
        }

        const dataContextEntityId = this.getDataContextEntityId(
            template.documentDataContextType
        );
        this.createDocument({ template, dataContextEntityId });
    };

    handleMultiEstimateConfirmClick = (estimateOptionIds) => {
        this.createDocument({
            template: this.state.selectedDocumentTemplate,
            estimateOptionIds,
        });
    };

    render() {
        const disabledDocumentDataContextTypes = [];
        const { subscriptionExpired } = this.props.organization;

        if (!this.props.projectHasDrawing) {
            disabledDocumentDataContextTypes.push(
                DocumentDataContextType.ProjectDrawing
            );
        }

        if (
            this.props.estimates.length === 0 ||
            this.props.organization.subscriptionExpired
        ) {
            disabledDocumentDataContextTypes.push(
                DocumentDataContextType.Estimate,
                DocumentDataContextType.MultiEstimate
            );
        }

        const v1TemplateCategory = getDocumentTemplateCategoryByV1Type(
            this.state.selectedDocumentTemplateCategoryName
        );

        return (
            <div>
                <CreateDocumentDialog
                    documentTemplateCategories={
                        this.props.documentTemplateCategories
                    }
                    onDocumentTemplateClick={this.selectDocumentTemplate}
                    open={this.state.newCreateDocumentDialogIsOpen}
                    onDismissClick={this.resetState}
                    disabledDocumentDataContextTypes={
                        disabledDocumentDataContextTypes
                    }
                    currentUserRoleIds={this.props.currentUserRoleIds}
                    officeId={this.props.officeId}
                    subscriptionExpired={subscriptionExpired}
                />

                <FormHtmlDialog
                    open={this.state.formHtmlDialogIsOpen}
                    onRequestClose={this.handleFormHtmlDialogRequestClose}
                    projectId={this.props.projectId}
                    category={v1TemplateCategory}
                    estimateId={this.state.selectedEstimateId}
                    usesLegacySigning={
                        this.state.selectedDocumentTemplate
                            .usesLegacySigningWorkflow
                    }
                    documentId={this.state.selectedDocumentId}
                />

                <ChooseSingleEstimateDocumentDialog
                    estimates={this.props.estimates}
                    open={this.state.chooseSingleEstimateDialogIsOpen}
                    onListItemClick={this.selectEstimate}
                    onDismissClick={this.openNewDocumentDialog}
                />

                <ChooseMultiEstimateDialog
                    estimates={this.props.estimates}
                    open={this.state.selectMultiEstimateDialogIsOpen}
                    onConfirmClick={this.handleMultiEstimateConfirmClick}
                    onDismissClick={this.openNewDocumentDialog}
                />

                <FloatingActionButton
                    onClick={this.openNewDocumentDialog}
                    variant='extended'
                    id='addNewButton'
                />
            </div>
        );
    }
}

const documentTemplateCategoryProps = {
    id: PropTypes.string.isRequired,
};

const projectEstimateProps = {
    id: PropTypes.string.isRequired,
    isChosen: PropTypes.bool.isRequired,
};

CreateDocument.propTypes = {
    dispatch: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    projectHasDrawing: PropTypes.bool,
    documentTemplateCategories: PropTypes.arrayOf(
        PropTypes.shape(documentTemplateCategoryProps)
    ).isRequired,
    estimates: PropTypes.arrayOf(PropTypes.shape(projectEstimateProps))
        .isRequired,
    officeId: PropTypes.number.isRequired,
    currentUserRoleIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

CreateDocument.defaultProps = {
    projectHasDrawing: false,
};

function mapStateToProps(state) {
    const {
        organization,
        projectHome,
        documentTemplateCategories: documentTemplateCategoriesState,
        projectEstimates,
        currentUser,
    } = state;
    const { isFetching } = state.documents;
    const { currentProject } = projectHome;
    const { hasDrawing, officeId } = currentProject;
    const { documentTemplateCategories } = documentTemplateCategoriesState;
    const { data: estimates } = projectEstimates;
    const { userRoleIds: currentUserRoleIds } = currentUser;
    return {
        isFetching,
        organization,
        projectHasDrawing: hasDrawing,
        documentTemplateCategories,
        estimates,
        officeId,
        currentUserRoleIds,
    };
}

export default connect(mapStateToProps)(CreateDocument);
