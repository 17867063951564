import * as ActionTypes from '../actions/SketchOsGeocodingActions';

function sketchOsGeocoding(
    state = {
        address: {
            addressLine1: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
        },
        error: false,
        markers: [],
    },
    action,
) {
    switch (action.type) {
    case ActionTypes.GEOCODE_ADDRESS_REQUEST:
    case ActionTypes.REVERSE_GEOCODE_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case ActionTypes.GEOCODE_ADDRESS_SUCCESS:
    case ActionTypes.REVERSE_GEOCODE_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            address: action.address,
            markers: Object.assign([...state.markers, Object.assign(
                {},
                {
                    id: state.markers.length,
                },
                action.marker,
            )]),
        });
    case ActionTypes.GEOCODE_ADDRESS_FAILURE:
    case ActionTypes.REVERSE_GEOCODE_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            error: true,
        });
    case ActionTypes.CLEAR_ADDRESS:
        return Object.assign({}, state, {
            address: {
                addressLine1: '',
                city: '',
                state: '',
                postalCode: '',
                country: '',
            },
        });
    case ActionTypes.CLEAR_MARKERS:
        return Object.assign({}, state, {
            markers: [],
        });
    case ActionTypes.ADD_MARKER:
        return Object.assign({}, state, {
            markers: Object.assign([...state.markers, action.marker]),
        });
    case ActionTypes.UPDATE_MARKER:
        return Object.assign({}, state, {
            markers: Object.assign([...state.markers], {
                [state.markers.findIndex(item => (item.id === action.marker.id))]: action.marker,
            }),
        });
    case ActionTypes.REMOVE_MARKER:
        return Object.assign({}, state, {
            markers: state.markers.filter(item => item.id !== action.marker.id),
        });
    case ActionTypes.REMOVE_ALL_BUT_FIRST_MARKER:
        return {
            ...state,
            markers: state.markers.length > 0 ? [state.markers[0]] : state.markers,
        };
    default:
        return state;
    }
}

export default sketchOsGeocoding;
