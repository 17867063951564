import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import {
    setPageToolbarElements,
    resetPageToolbar,
} from '../../actions/RoofSnapPageToolbarActions';
import PageToolbarButton from './ToolbarButton';
import PageToolbarIconButton from './ToolbarIconButton';
import PageToolbarSaveButton from './ToolbarSaveButton';
import PageToolbarCancelButton from './ToolbarCancelButton';
import PageToolbarDeleteButton from './ToolbarDeleteButton';
import PageToolbarGiveProjectButton from './ToolbarGiveProjectButton';
import PageToolbarShareProjectButton from './ToolbarShareProjectButton';
import PageToolbarCloneProjectButton from './ToolbarCloneProjectButton';
import FreeSketchOrderIndicator from '../FreeSketchOrders/FreeSketchOrderIndicator';

export const ToolbarButton = PageToolbarButton;
export const ToolbarIconButton = PageToolbarIconButton;
export const ToolbarSaveButton = PageToolbarSaveButton;
export const ToolbarCancelButton = PageToolbarCancelButton;
export const ToolbarDeleteButton = PageToolbarDeleteButton;
export const ToolbarGiveProjectButton = PageToolbarGiveProjectButton;
export const ToolbarShareProjectButton = PageToolbarShareProjectButton;
export const ToolbarCloneProjectButton = PageToolbarCloneProjectButton;
export const ToolbarFreeSketchOrderIndicator = FreeSketchOrderIndicator;

class PageToolbarActions extends React.Component {
    componentDidMount() {
        this.props.dispatch(setPageToolbarElements(this.props.children));
    }

    componentWillReceiveProps(nextProps) {
        if (!isEqual(nextProps.triggers, this.props.triggers)) {
            this.props.dispatch(setPageToolbarElements(nextProps.children));
        }
    }

    componentWillUnmount() {
        this.props.dispatch(resetPageToolbar());
    }

    render() {
        return null;
    }
}

PageToolbarActions.propTypes = {
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.node,
    triggers: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
    ])),
};

PageToolbarActions.defaultProps = {
    children: null,
    triggers: [],
};

export default connect(null)(PageToolbarActions);
