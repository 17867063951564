import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MaterialItemColorOptionsList from '../MaterialItemColorOptionsList';
import Pager from '../Pager/Pager.tsx';

const MaterialItemColorOptionsDialog = (props) => {
    const handleOnNextPageClick = () => {
        props.onNextPageClick(props.materialItem);
    };

    const handleOnPrevPageClick = () => {
        props.onPrevPageClick(props.materialItem);
    };

    return (
        <Dialog open={props.open}>
            <DialogTitle>
                <Grid
                    container
                    alignItems="center"
                >
                    <Grid item>
                        {props.materialItem.description}
                    </Grid>
                    <Grid item>
                        <Pager
                            totalPages={props.totalPages}
                            prevClick={handleOnPrevPageClick}
                            nextClick={handleOnNextPageClick}
                            currentPage={props.currentPage}
                        />
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <MaterialItemColorOptionsList
                    colorOptions={props.colorOptions}
                    onColorOptionClick={props.onColorOptionClick}
                    totalResults={props.totalResults}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onDismiss}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const colorOptionProps = {
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.string,
    materialItemId: PropTypes.number.isRequired,
    isDeleted: PropTypes.bool,
    isCustom: PropTypes.bool,
    isHidden: PropTypes.bool,
};

MaterialItemColorOptionsDialog.propTypes = {
    open: PropTypes.bool,
    materialItem: PropTypes.shape({
        materialItemId: PropTypes.number,
        description: PropTypes.string,
    }),
    colorOptions: PropTypes.arrayOf(PropTypes.shape(colorOptionProps)).isRequired,
    totalResults: PropTypes.number.isRequired,
    onColorOptionClick: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onNextPageClick: PropTypes.func,
    onPrevPageClick: PropTypes.func,
};

MaterialItemColorOptionsDialog.defaultProps = {
    open: false,
    materialItem: {
        materialItemId: null,
        description: '',
    },
    onNextPageClick: () => {},
    onPrevPageClick: () => {},
};

export default MaterialItemColorOptionsDialog;
