import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import projectStatus from '../../lib/ProjectStatus';
import './ProjectListItem.css';

function buildProjectCustomerCityStateZip(project) {
    return project.customerCity !== '' ?
        `${project.customerCity}, ${project.customerState} ${project.customerZip}` :
        `${project.customerState} ${project.customerZip}`;
}

class ProjectListItem extends Component {
    render() {
        const { project } = this.props;

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
                className="project-list-item"
                key={project.Id}
                onClick={this.props.onProjectListItemClick}
                role="presentation"
            >
                <div className="container project-list-item-img-container">
                    <img className="project-list-item-img" src={project.roofSnapImageUrl} alt="" />
                </div>
                <div className="container project-list-item-main-container">
                    <div>
                        <Typography><b>{project.projectName || ''}</b></Typography>
                        {
                            project.projectOwner &&
                            <Typography>{`Project owned by: ${project.projectOwner}` || ''}</Typography>
                        }
                        <Typography>{project.createdAt ? new Date(project.createdAt).toLocaleString('en-US', { hour12: true }) : ''}</Typography>
                        <Typography>{project.customerAddress || ''} { project.customerAddress && buildProjectCustomerCityStateZip(project)}</Typography>
                    </div>
                </div>
                <div className="container project-list-item-status-container">
                    <Typography>{projectStatus[project.projectStatus]}</Typography>
                </div>
            </div>
        );
    }
}

ProjectListItem.propTypes = {
// eslint-disable-next-line react/forbid-prop-types
    project: PropTypes.object.isRequired,
    onProjectListItemClick: PropTypes.func.isRequired,
};

export default ProjectListItem;
