import { some } from 'lodash';
import MaterialItemModel from '../Models/MaterialItemModel';

const MaterialItemModelFactory = {
    /**
     * @param {Object} materialItem - the material item used to create a material item model. Should match materialItem resource from RoofSnap Api
     * @param {Array} estimateItems - items currently on an estimate
     */
    create(materialItem, estimateItems) {
        const newMaterialItemModel = new MaterialItemModel();
        newMaterialItemModel.id = materialItem.id;
        newMaterialItemModel.officeId = materialItem.officeId;
        newMaterialItemModel.categoryName = materialItem.categoryName;
        newMaterialItemModel.colorOptionsCount = materialItem.colorOptionsCount;
        newMaterialItemModel.description = materialItem.description;
        newMaterialItemModel.image = materialItem.image;
        newMaterialItemModel.rootCategoryName = materialItem.rootCategoryName;
        newMaterialItemModel.isOnEstimate = some(estimateItems, ['materialItemId', materialItem.id]);
        return newMaterialItemModel;
    },
};

export default MaterialItemModelFactory;
