import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    styled,
    Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@material-ui/icons';

type LoginProps = {
    errorMessage?: string;
    usernameInitialValue?: string;
    onLoginClick: ({
        username,
        password,
    }: {
        username: string;
        password: string;
    }) => void;
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    margin: theme.spacing(1),
    marginLeft: 0,
}));

const ErrorMessage = styled(FormHelperText)(({ theme }) => ({
    color: theme.palette.error.main,
}));

const LoginButton = styled(Button)({
    marginTop: 12,
    float: 'right',
});

const CreateAccountSection = styled(Box)({
    marginTop: 70,
});

const CreateAccountText = styled(Typography)({
    color: 'rgba(0, 0, 0, 0.54)',
});

const Login = ({
    errorMessage,
    usernameInitialValue,
    onLoginClick,
}: LoginProps) => {
    const [username, setUsername] = useState<string>(
        usernameInitialValue || ''
    );
    const [password, setPassword] = useState<string>('');
    const [usernameIsValid, setUsernameIsValid] = useState<boolean>(true);
    const [passwordIsValid, setPasswordIsValid] = useState<boolean>(true);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleUsernameChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const trimmed = event.target.value.trim();

        setUsername(trimmed);
        setUsernameIsValid(!!trimmed);
    };

    const handlePasswordChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const trimmed = event.target.value.trim();

        setPassword(trimmed);
        setPasswordIsValid(!!trimmed);
    };

    const login = () => {
        let valid = true;

        if (!!!username) {
            setUsernameIsValid(false);
            valid = false;
        }

        if (!!!password) {
            setPasswordIsValid(false);
            valid = false;
        }

        if (valid) onLoginClick({ username, password });
    };

    return (
        <div>
            <form onSubmit={login}>
                <StyledFormControl fullWidth>
                    <InputLabel htmlFor='username'>Email</InputLabel>
                    <Input
                        id='username'
                        type='text'
                        value={username}
                        error={!usernameIsValid}
                        onChange={handleUsernameChange}
                    />

                    {!usernameIsValid && (
                        <ErrorMessage id='userNameError'>
                            Email is required
                        </ErrorMessage>
                    )}
                </StyledFormControl>
                <br />

                <StyledFormControl fullWidth>
                    <InputLabel htmlFor='password'>Password</InputLabel>
                    <Input
                        id='password'
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        error={!passwordIsValid}
                        onChange={handlePasswordChange}
                        onKeyUp={(event) => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                login();
                            }
                        }}
                        endAdornment={
                            <InputAdornment position='end'>
                                <IconButton
                                    onClick={() => {
                                        setShowPassword(
                                            (oldValue) => !oldValue
                                        );
                                    }}
                                    onMouseDown={(event) => {
                                        event.preventDefault();
                                    }}
                                >
                                    {showPassword ? (
                                        <VisibilityOff />
                                    ) : (
                                        <Visibility />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                    />

                    {!passwordIsValid && (
                        <ErrorMessage id='passwordError'>
                            Password required
                        </ErrorMessage>
                    )}
                </StyledFormControl>
                <br />

                {errorMessage && (
                    <ErrorMessage id='loginError'>{errorMessage}</ErrorMessage>
                )}
                <Link to='/resetpassword' href='/resetpassword'>
                    <Typography variant='caption'>Forgot password?</Typography>
                </Link>
                <LoginButton
                    id='login'
                    variant='contained'
                    color='primary'
                    onClick={login}
                    fullWidth
                >
                    Log in
                </LoginButton>
            </form>
            <CreateAccountSection>
                <CreateAccountText>
                    If you don&#39;t have an account,&nbsp;
                    <a
                        href={`${process.env.REACT_APP_ROOFSNAP_ONLINE_HOST}/free-trial`}
                    >
                        create one now.
                    </a>
                </CreateAccountText>
            </CreateAccountSection>
        </div>
    );
};

export default Login;
