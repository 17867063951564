import React, { Component } from 'react';
import { withRouter, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import MobileWarningDialog from './MobileWarningDialog';

class MobileWarning extends Component {
    state = {
        showRoute: false,
        showDialog: true,
    };

    handleContinue = () => {
        this.setState({
            showDialog: false,
            showRoute: true,
        });
    };

    goBack = () => {
        this.props.history.replace(`/projects/${this.props.projectId}`);
    };
    render() {
        return (
            <div>
                <MobileWarningDialog
                    open={this.state.showDialog}
                    goBack={this.goBack}
                    continue={this.handleContinue}
                />
                {this.state.showRoute &&
                <Route
                    path={this.props.path}
                    component={this.props.component}
                />}
            </div>
        );
    }
}
/* eslint-disable react/forbid-prop-types */
MobileWarning.propTypes = {
    projectId: PropTypes.string.isRequired,
    component: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
};

export default withRouter(MobileWarning);
