
export const createProjectDocument = (document, rendering) => ({
    id: document.id,
    renderingId: rendering.id,
    renderingFileName: rendering.fileName,
    projectId: document.projectId,
    documentUrl: document.documentUrl,
    name: document.name,
    templateId: document.templateId,
    template: document.template,
    isGenerated: (rendering) ? rendering.isGenerated : true,
    createdAt: (rendering) ? rendering.createdAt : document.createdAt,
});

export default createProjectDocument;
