import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, withRouter, useHistory } from 'react-router-dom';
import {
    Button,
    Paper,
    Grid,
    Link,
    Typography,
    withStyles,
    WithStyles,
    createStyles
} from '@material-ui/core';
import CheckMarkAnimation from 'lib/Models/CheckMarkAnimation';
import Lottie from 'react-lottie-player';
import { getSketchOSOrdersByOrganization } from '../../actions/SketchOSActions';
import useQuery from 'hooks/useQueryHook';
import { ReportProblemOutlined } from '@material-ui/icons';
import { getSketchOrderPricing } from 'actions/SketchOrderPricingActions';
import LoggerWrapper from 'lib/Logger';


interface LandingPageProps extends WithStyles<typeof styles> {
    dispatch: (action: any) => any,
    organizationId: number,
    userName: string,
}

const styles = () => createStyles({
    root: {
        height: '100%',
        textAlign: 'center',
        font: 'Roboto',
        padding: '20px 10px'
    },
    notification: {
        width: 500,
        height: 580,
    },
    checkIcon: {
        height: 100,
        width: 100,
        color: '#40AD48',
    },
    freeReportText: {
        color: '#40AD48',
        font: '18pt Roboto',
        fontWeight: 500,
    },
    address: {
        font: '13pt Roboto',
        fontWeight: 500,
    },
    errorMessage: {
        marginTop: '2rem',
        marginBottom: '1rem',
        font: '13pt Roboto',
        fontWeight: 'bold',
    },
    bodyText: {
        font: '11pt Roboto',
        letterSpacing: 1.05,
    },
    button: {
        backgroundColor: "#007AFF",
        color: '#FFF'
    },
    errorButton: {
        marginTop: '2rem',
        color: '#000'
    },
    description: {
        font: '10pt Roboto',
        color: '#00000080',
        lineHeight: 1.6,
    },
    content: {
        height: 505,
        padding: '0px 28px',

    },
    footer: {
        height: 55,
        borderTop: '1px solid #00000080'
    },
    errorNotification: {
        padding: 10,
        width: 500,
        height: 350
    },
    errorIcon: {
        marginTop: '2rem',
        fontSize: '5rem',
    },
    tryAgainMessage: {
        color: '#999999',
    }
})


const LandingPage = (props: LandingPageProps) => {
    const [sketchOrder, setSketchOrder] = useState({
        address: "",
        city: "",
        region: "",
        postcode: "",
        country: null
    });

    const [startingPrice, setStartingPrice] = useState<string>('')

    const [error, setError] = useState(false)

    const history = useHistory();
    const failed = useQuery().get('orderfailed') !== null;

    useEffect(() => {
        if (failed) {
            setError(true);
        }

        const getOrders = async () => {
            const response = await props.dispatch(getSketchOSOrdersByOrganization(props.organizationId));
            response.response.resourceList[0] ? setSketchOrder(response.response.resourceList[0]) : setError(true)

        }
        const getPricing = async () => {
            try {
                const response = await props.dispatch(getSketchOrderPricing())
                setStartingPrice(response.response.halfSnapPriceGrid[0].tierPrices[0].price)
            } catch (error) {
                LoggerWrapper.captureException(error)
            }
        }

        getOrders()
        getPricing()
    }, [failed, props])

    return (
        !sketchOrder.country && !error ?
            <Typography>Loading...</Typography>
            : <Grid xs={12} className={props.classes.root} container justifyContent="center" alignContent="center">
                <Paper className={error ? props.classes.errorNotification : props.classes.notification}>
                    <Grid style={{ height: '100%' }} container direction="column" alignContent="center" justifyContent="space-between">
                        {error
                            ? //There should never be an error but in case the response returns null
                            <Grid item container justifyContent="center" alignContent="center">
                                <Grid container item xs={12} justifyContent="center" alignContent='center'>
                                    <ReportProblemOutlined className={props.classes.errorIcon} />
                                </Grid>
                                <Grid container item xs={10} justifyContent="center" alignContent="center">
                                    <Typography className={props.classes.errorMessage}>
                                        An error occurred while creating your report
                                    </Typography>
                                    <Typography className={props.classes.tryAgainMessage}>Please try again</Typography>
                                </Grid>
                                <Grid item xs={12} justifyContent="center">
                                    <Button id="try-again-button" variant="outlined" className={props.classes.errorButton} onClick={() => history.push('/orders/create/fullsnap')}>
                                        Try Again
                                    </Button>
                                </Grid>
                            </Grid>
                            :
                            <Grid>
                                <Grid className={props.classes.content} container direction="column" alignContent="center" justifyContent="space-evenly">
                                    <Grid item container justifyContent="center">
                                        <Lottie
                                            id="lottie-animation"
                                            animationData={CheckMarkAnimation}
                                            play
                                            loop={false}
                                            speed={0.8}
                                            style={{ width: 100, height: 100 }}
                                        />
                                    </Grid>
                                    <Grid item container direction="column" justifyContent="space-evenly" alignContent="center" spacing={2}>
                                        <Grid item >
                                            <Typography className={props.classes.freeReportText}>
                                                Free Report Ordered for
                                            </Typography>
                                        </Grid>
                                        <Grid item >
                                            <Typography id="address" className={props.classes.address}>
                                                {sketchOrder.address} {sketchOrder.city}, {sketchOrder.region} {sketchOrder.postcode}
                                            </Typography>
                                        </Grid>
                                        <Grid item >
                                            <Typography id="email" className={props.classes.bodyText}>
                                                We will send all notifcations to {props.userName}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Button id="explore-account-button" variant="contained" className={props.classes.button} onClick={() => history.push('/projects')}>
                                            Explore Your Free Trial Account
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Typography className={props.classes.description}>
                                            Measurement Orders are typically completed within 2 to 4 hours, or 60 minutes or less with Rush Delivery, when placed Monday-Saturday, between 9 am to 5 pm EST. Actual times may vary. You will receive an email and your amendable project when your order is completed.                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignContent="center" justifyContent="center" className={props.classes.footer}>
                                    <Typography className={props.classes.bodyText}>
                                        Place another roof <Link id="place-order-link" to="/orders/create" href="/orders/create" component={RouterLink}>measurement order</Link> starting at ${startingPrice}.
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Paper>
            </Grid >
    )
}

const mapStateToProps = (state: any) => {
    const {
        currentUser,
    } = state;

    const { userName, organizationId } = currentUser;

    return {
        userName,
        organizationId,
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(LandingPage)));