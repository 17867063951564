import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ChangePasswordComplete from './ChangePasswordComplete';
import { changePassword } from '../../actions/User';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';
import ResetPasswordForm from '../ResetPasswordForm/ResetPasswordForm.tsx';

const style = {
    wrapper: {
        justifyContent: 'center',
        display: 'flex',
        marginTop: 120,
    },
};

class ChangePassword extends Component {
    state = {
        passwordHasBeenChanged: false,
    }

    changePassword = async (newPassword) => {
        try {
            const response = await this.props.dispatch(changePassword(this.props.userId, newPassword));
            if (response.error) {
                this.props.dispatch(setReduxMessage('Oops... Something went wrong. Please try again'));
                return;
            }
            this.setState({ passwordHasBeenChanged: true });
        } catch (error) {
            this.props.dispatch(setReduxMessage('Oops... Something went wrong. Please try again'));
            this.setState({ passwordHasBeenChanged: false });
        }
    };

    handleOnContinueClick = () => {
        this.props.history.push('/projects');
    };

    render() {
        return (
            <div className={this.props.classes.wrapper}>
                {
                    this.state.passwordHasBeenChanged ? (
                        <ChangePasswordComplete onConfirmClick={this.handleOnContinueClick} />
                    ) : (
                        <ResetPasswordForm
                            onSubmitClick={this.changePassword}
                            title="Change Password"
                            buttonText="Change Password"
                            label="New Password"
                            confirmLabel="Confirm New Password"
                        />
                    )
                }
            </div>
        );
    }
}

ChangePassword.propTypes = {
    classes: PropTypes.shape({
        wrapper: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
    const { currentUser } = state;
    const { userId } = currentUser;
    return {
        userId,
    };
};

export default connect(mapStateToProps)(withStyles(style)(ChangePassword));
