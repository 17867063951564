import React, { PropsWithChildren, ReactNode } from 'react';
import { Dialog, Button } from '@ui';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
} from '@mui/material';
import { CloseIcon } from '@ui/Icons';
import { DownloadProjectCsvIconButton } from '@roofsnap';
import { CustomButtonVariants } from '@ui/Button';

type ProjectDetailsFormDialogProps = PropsWithChildren<{
    title: ReactNode;
    navigation?: ReactNode;
    open?: boolean;
    projectId?: string;
    projectName?: string;
    actions: {
        text: string;
        buttonVariant: CustomButtonVariants;
        onClick: () => void;
    }[];
    onClose: () => void;
}>;

const ProjectDialog = ({
    title,
    navigation,
    children,
    open,
    projectId,
    projectName,
    actions,
    onClose,
}: ProjectDetailsFormDialogProps) => {
    return (
        <Dialog open={!!open}>
            <DialogTitle>
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems={'center'}
                >
                    <Stack direction='row' alignItems='center'>
                        {title}
                    </Stack>
                    <Stack direction='row' alignItems='center'>
                        {projectName && projectId && (
                            <DownloadProjectCsvIconButton
                                projectName={projectName}
                                projectId={projectId}
                            />
                        )}
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </Stack>
            </DialogTitle>
            <Divider />
            {navigation}
            <DialogContent>{children}</DialogContent>
            <Divider />
            <DialogActions>
                {actions.map((action, index) => (
                    <Button
                        key={index}
                        customVariant={action.buttonVariant}
                        onClick={action.onClick}
                    >
                        {action.text}
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    );
};

export default ProjectDialog;
