export const OrderTypeInfo: { [key: string]: Array<String> } = {
    fullsnap: [
        'Detailed measurements.',
        'Linear measurements & surface area.',
        'Integrated with our estimating platform.',
    ],
    halfsnap: [
        'Basic measurements.',
        'Surface area & predominant pitch.',
        'Ideal for per-square estimating.',
    ],
    metalroof: [
        'Detailed measurements.',
        'Linear Measurements, Panel Count, & Cut Panel Diagram.',
        'Integrated with our estimating platform.',
    ],
    gutters: [
        'All eaves lengths.',
        'Total count for end caps, miters, other.',
        'Ideal for estimating gutters.',
    ],
};
