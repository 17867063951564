import React from 'react';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MeasurementActions } from 'lib/MeasurementsAction';
import { useHistory } from 'react-router';

type measurementSectionActionsProps = {
    projectId: string;
};

export const MeasurementSectionActions = ({
    projectId,
}: measurementSectionActionsProps) => {
    const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(
        null
    );

    const history = useHistory();

    const menuOpen = Boolean(menuAnchor);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleActionClick = (action: any) => () => {
        if (action.path) {
            history.push(action.path(projectId));
        }
    };

    return (
        <Box justifyContent='flex-end' sx={{ display: 'flex', flexBasis: 1 }}>
            <IconButton onClick={handleMenuClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                open={menuOpen}
                anchorEl={menuAnchor}
                onClose={handleMenuClose}
            >
                {MeasurementActions.map((action) => (
                    <MenuItem
                        key={action.id}
                        onClick={handleActionClick(action)}
                    >
                        {action.title}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};
