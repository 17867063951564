import {
    GET_ESTIMATE_TEMPLATES_REQUEST,
    GET_ESTIMATE_TEMPLATES_SUCCESS,
    GET_ESTIMATE_TEMPLATES_FAILURE,
    CREATE_ESTIMATE_TEMPLATE_REQUEST,
    CREATE_ESTIMATE_TEMPLATE_SUCCESS,
    CREATE_ESTIMATE_TEMPLATE_FAILURE,
} from '../actions/EstimateTemplatesActions';

function estimateTemplatesReducer(state = {
    isFetching: false,
    estimateTemplates: [],
    totalResults: 0,
    totalPages: 0,
    currentPage: 1,
}, action) {
    switch (action.type) {
    case GET_ESTIMATE_TEMPLATES_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case GET_ESTIMATE_TEMPLATES_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            estimateTemplates: action.response.resourceList,
            totalResults: action.response.totalResults,
            totalPages: action.response.totalPages,
            currentPage: action.response.page,
        });
    case GET_ESTIMATE_TEMPLATES_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            estimateTemplates: [],
            totalResults: 0,
            totalPages: 0,
            currentPage: 1,
        });
    case CREATE_ESTIMATE_TEMPLATE_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case CREATE_ESTIMATE_TEMPLATE_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case CREATE_ESTIMATE_TEMPLATE_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
        });
    default:
        return state;
    }
}

export default estimateTemplatesReducer;
