import React, { useEffect, useState } from 'react';
import { Input, MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import { getUsersOfficesForSOS } from '../../../../actions/SketchOSOfficeByUserActions';
import { setSketchOSOfficeId } from '../../../../actions/SketchOSOfficeIdAction';
import Office from '../../../../lib/Models/Office';
import { Select } from '@mui/material';

interface SketchOSOfficeDropdownProps {
    className?: string;
    classNameMenu?: string;
    selectedUserId?: number;
    dispatch: any;
    sosUserOffices: any;
    sketchOSOfficeId?: number;
}

const SketchOSOfficeDropdown = (props: SketchOSOfficeDropdownProps) => {
    const {
        className,
        classNameMenu,
        selectedUserId,
        dispatch,
        sketchOSOfficeId,
        sosUserOffices,
    } = props;
    const [offices, setOffices] = useState<Array<Office>>();

    useEffect(() => {
        if (
            sosUserOffices &&
            selectedUserId &&
            sosUserOffices[selectedUserId] &&
            sosUserOffices[selectedUserId].length > 0
        ) {
            // use cached user office
            setOffices(sosUserOffices[selectedUserId]);
        } else {
            const data = dispatch(getUsersOfficesForSOS(selectedUserId));
            data.then((r: any) => {
                setOffices(r.offices);
            });
        }
    }, [dispatch, selectedUserId, sosUserOffices]);

    useEffect(() => {
        if (
            offices &&
            offices[0] &&
            !offices.some((o) => o.id === sketchOSOfficeId)
        ) {
            dispatch(setSketchOSOfficeId(offices[0].id));
        }
    }, [offices, sketchOSOfficeId, dispatch]);

    return (
        <Select
            value={sketchOSOfficeId}
            variant='standard'
            disableUnderline={true}
            onChange={(e) => {
                const newOfficeId = e.target.value as number;
                dispatch(setSketchOSOfficeId(newOfficeId));
            }}
            input={<Input className={className} name='officeId' />}
        >
            {offices &&
                offices.map((office: Office) => (
                    <MenuItem
                        className={classNameMenu}
                        key={office.id}
                        value={office.id}
                    >
                        {office.officeName}
                    </MenuItem>
                ))}
        </Select>
    );
};

const mapStateToProps = (state: any) => {
    const { SketchOSOfficeByUserReducer, SketchOSOfficeIdReducer } = state;
    const { sosUserOffices } = SketchOSOfficeByUserReducer;
    const { sketchOSOfficeId } = SketchOSOfficeIdReducer;
    return {
        sosUserOffices,
        sketchOSOfficeId,
    };
};

export default connect(mapStateToProps)(SketchOSOfficeDropdown);
