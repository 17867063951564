import { CALL_API, httpMethod } from '../middleware/api';
import { SET_REDUX_MESSAGE } from './ReduxMessagesActions';


export const GET_PROJECT_SHARES_REQUEST = 'GET_PROJECT_SHARES_REQUEST';
export const GET_PROJECT_SHARES_SUCCESS = 'GET_PROJECT_SHARES_SUCCESS';
export const GET_PROJECT_SHARES_FAILURE = 'GET_PROJECT_SHARES_FAILURE';

export const getProjectSharesPerProject = (
    projectId,
    page = 1,
    getAllPages = false,
) => ({
    [CALL_API]: {
        types: [
            GET_PROJECT_SHARES_REQUEST,
            GET_PROJECT_SHARES_SUCCESS,
            GET_PROJECT_SHARES_FAILURE,
        ],
        endpoint: `projects/${projectId}/projectshares/`,
        getAllPages,
        params: {
            page,
        },
    },
});

export const DELETE_PROJECT_SHARES_REQUEST = 'DELETE_PROJECT_SHARES_REQUEST';
export const DELETE_PROJECT_SHARES_SUCCESS = 'DELETE_PROJECT_SHARES_SUCCESS';
export const DELETE_PROJECT_SHARES_FAILURE = 'DELETE_PROJECT_SHARES_FAILURE';

export const deleteProjectShares = (projectId, projectShareId) => ({
    [CALL_API]: {
        types: [
            DELETE_PROJECT_SHARES_REQUEST,
            DELETE_PROJECT_SHARES_SUCCESS,
            DELETE_PROJECT_SHARES_FAILURE,
            SET_REDUX_MESSAGE,
        ],
        endpoint: `projects/${projectId}/projectshares/${projectShareId}/`,
        method: httpMethod.DELETE,
    },
});

export const CREATE_PROJECT_SHARES_REQUEST = 'CREATE_PROJECT_SHARES_REQUEST';
export const CREATE_PROJECT_SHARES_SUCCESS = 'CREATE_PROJECT_SHARES_SUCCESS';
export const CREATE_PROJECT_SHARES_FAILURE = 'CREATE_PROJECT_SHARES_FAILURE';

export const createProjectShares = (projectId, userId) => ({
    [CALL_API]: {
        types: [
            CREATE_PROJECT_SHARES_REQUEST,
            CREATE_PROJECT_SHARES_SUCCESS,
            CREATE_PROJECT_SHARES_FAILURE,
            SET_REDUX_MESSAGE,
        ],
        endpoint: `projects/${projectId}/projectshares/`,
        method: httpMethod.POST,
        body: { userId },
    },
});
