import React, { Component } from 'react';
import { DateTime } from 'luxon';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import FeedbackIcon from '@material-ui/icons/Feedback';
import {
    changeSketchOrderStatus,
    getSketchOrderById,
    updateSketchOrder,
    UPDATE_SKETCH_ORDER_SUCCESS,
    CHANGE_SKETCH_ORDER_STATUS_SUCCESS,
    createRevision,
} from '../../actions/SketchOrderActions';
import {
    createSketchOrderTransactionBatch,
    CREATE_SKETCH_ORDER_TRANSACTION_BATCH_SUCCESS,
} from '../../actions/sketchOrderTransactionBatchActions';
import { getOrganizationUsers } from '../../actions/Users';
import {
    getSketchOrderNotifications,
    createSketchOrderNotificationAcknowledgement,
} from '../../actions/sketchOrderNotificationsActions';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';
import { downloadDocument } from '../../actions/documentActions';
import { getDocuments } from '../../actions/documentsActions';
import { createDocumentSharedAccessToken } from '../../actions/documentSharedAccessTokenActions';
import httpStatusCodes from '../../middleware/httpStatusCodes';
import PAYMENT_TYPE from '../../lib/PaymentType.ts';
import SketchOrderStatus from '../../lib/SketchOrderStatus.ts';
import roofsnapTheme from '../../lib/theme';
import hostConfig from '../../lib/hostConfig.ts';
import PageToolbarActions from '../NavigationComponents/PageToolbarActions';
import RetrySketchOrderBillingDialog from './RetrySketchOrderBillingDialog';
import RequestRevisionDialog from './RequestRevisionDialog.tsx';
import SketchOrderCard from './SketchOrderCard';
import OpenInExternalAppButton from '../OpenInExternalAppButton';
import SketchOrderNotificationsDrawer, {
    notificationsDrawerToolbarWidth,
    notificationsDrawerWidth,
    drawerHeaderHeight,
    drawerProps,
} from '../SketchOrderNotificationsDrawer';
import { DocumentCategoryNames } from '../../lib/DocumentsCategories.ts';

const styles = (theme) => ({
    root: {
        padding: 24,
    },
    rootRightToolbar: {
        marginRight: notificationsDrawerToolbarWidth,
    },
    rootRightDrawer: {
        marginRight: notificationsDrawerWidth,
    },
    drawer: {
        paddingTop: theme.spacing(1) + drawerHeaderHeight,
    },
    toolbarDrawer: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        zIndex: 1,
    },
    viewProjectLink: {
        color: roofsnapTheme.palette.roofsnapBlue[100],
    },
});

class SketchOrder extends Component {
    state = {
        sketchOrder: { deliveryOptions: [] },
        updatePaymentDialogOpen: false,
        requestRevisionDialogOpen: false,
        paymentType: false,
        paymentValid: false,
        retryBillingErrorMessage: null,
        notificationsDrawerOpen: false,
        notificationDetailsDrawerOpen: false,
        sketchOrderReport: null,
        revisionNoteText: '',
    };
    async componentDidMount() {
        this.props.dispatch(
            getOrganizationUsers(this.props.organizationId, 1, true)
        );
        this.props.dispatch(
            getSketchOrderById(this.props.match.params.sketchOrderId)
        );
        this.props.dispatch(
            getSketchOrderNotifications(this.props.match.params.sketchOrderId)
        );
        await this.getSketchOrderReport();
    }
    async componentDidUpdate(prevProps) {
        if (
            prevProps.notifications !== this.props.notifications &&
            this.props.notifications.length > 0
        ) {
            this.handleNotificationsUpdate();
        }
        if (
            this.props.projectId &&
            prevProps.projectId !== this.props.projectId
        ) {
            await this.getSketchOrderReport();
        }
    }
    onPaymentChange = (paymentType, paymentValid, getNonce) => {
        this.setState({
            paymentType,
            paymentValid,
            getNonce,
        });
    };
    getSketchOrderReport = async () => {
        if (this.props.projectClonedForDelivery) {
            await this.props.dispatch(getDocuments(this.props.projectId));
            const documents = [...this.props.documents.data];
            // template category ids are sent from api as the template category shortcode
            const sketchOrderReportDocument = documents.filter(
                (d) =>
                    d.template.templateCategory.name ===
                    DocumentCategoryNames.SketchOrderReport
            )[0];
            this.setState({
                sketchOrderReport: sketchOrderReportDocument,
            });
        }
    };
    setRevisionNoteText = (value) => {
        this.setState({ revisionNoteText: value });
    };
    downloadSketchOrderReport = async () => {
        await this.props.dispatch(
            createDocumentSharedAccessToken(
                this.state.sketchOrderReport.id,
                this.state.sketchOrderReport.renderingId
            )
        );
        await this.props.dispatch(
            downloadDocument(
                this.props.sharedAccessSignatureUrl,
                this.state.sketchOrderReport.renderingFileName
            )
        );
    };
    handleNotificationsUpdate = () => {
        this.setState({
            notificationsDrawerOpen: true,
        });
    };
    cancelOrder = async () => {
        const response = await this.props.dispatch(
            changeSketchOrderStatus(
                this.props.id,
                this.props.version,
                SketchOrderStatus.Cancelled
            )
        );
        if (response.type === CHANGE_SKETCH_ORDER_STATUS_SUCCESS) {
            await this.props.history.push('/orders');
            this.props.dispatch(setReduxMessage('Order cancelled.'));
        }
    };
    handleSubmit = async () => {
        let nonce;
        if (this.state.paymentType === PAYMENT_TYPE.USER) {
            nonce = await this.state.getNonce();
        }

        this.createSketchOrder(nonce);
        this.closeDialog();
    };

    updateOrder = async (sketchOrder) => {
        const updatedSketchOrder = {
            ...sketchOrder,
            projectName: this.props.projectName,
            address: this.props.address,
            city: this.props.city,
            region: this.props.region,
            postCode: this.props.postCode,
            country: this.props.country,
            location: this.props.location,
            sketchOrderStatus: this.props.sketchOrderStatus,
            orderPaymentType: this.props.sketchOrderPaymentType,
        };
        const response = await this.props.dispatch(
            updateSketchOrder(
                this.props.id,
                updatedSketchOrder,
                this.props.version
            )
        );
        if (response.type === UPDATE_SKETCH_ORDER_SUCCESS) {
            await this.props.history.push('/orders');
            this.props.dispatch(setReduxMessage('Order updated.'));
        }
    };

    retryBilling = async () => {
        // Reset billing error message
        this.setState({
            retryBillingErrorMessage: null,
            isRetryingBilling: true,
        });

        const nonce = await this.state.getNonce();
        const result = await this.props.dispatch(
            createSketchOrderTransactionBatch(
                [this.props.match.params.sketchOrderId],
                nonce
            )
        );
        if (result.type === CREATE_SKETCH_ORDER_TRANSACTION_BATCH_SUCCESS) {
            const { response } = result;
            // If we have an empty response, all good
            if (response.status === httpStatusCodes.NoContent) {
                this.props.dispatch(
                    setReduxMessage(
                        'Payment successful. Your project is now available.',
                        <Link
                            to={`/projects/${this.props.projectId}`}
                            className={this.props.classes.viewProjectLink}
                        >
                            View Project
                        </Link>
                    )
                );
            } else {
                let retryBillingErrorMessage =
                    'Payment method has been declined.';

                const error = response[0];
                if (error.processorResponseType === 'soft_declined') {
                    retryBillingErrorMessage +=
                        ' Please contact your bank before trying again.';
                }

                if (error.processorResponseType === 'hard_declined') {
                    retryBillingErrorMessage += ' Please use a different card.';
                }

                this.setState({
                    retryBillingErrorMessage,
                });
            }
        } else {
            this.setState({
                retryBillingErrorMessage: result.error,
            });
        }

        // Get the latest sketch order.
        await this.props.dispatch(
            getSketchOrderById(this.props.match.params.sketchOrderId)
        );
        this.setState({
            updatePaymentDialogOpen: false,
            isRetryingBilling: false,
        });
    };

    openUpdatePaymentDialog = () => {
        this.setState({ updatePaymentDialogOpen: true });
    };

    handleUpdatePaymentDialogClose = () => {
        this.setState({ updatePaymentDialogOpen: false });
    };

    requestRevisionButtonClick = () => {
        this.setState({
            requestRevisionDialogOpen: true,
            revisionNoteText: '',
        });
    };

    handleRequestRevision = (note) => {
        this.setState({ requestRevisionDialogOpen: false });
        this.props.dispatch(
            createRevision(
                this.props.match.params.sketchOrderId,
                note,
                this.props.version
            )
        );
    };

    handleRequestRevisionDialogClose = (note) => {
        this.setState({ requestRevisionDialogOpen: false });
    };
    openProject = () => {
        this.props.history.push(`/projects/${this.props.projectId}`);
    };
    handleOpenNotificationsButtonClick = () => {
        this.setState({
            notificationsDrawerOpen: true,
        });
    };
    handleCloseNotificationsButtonClick = () => {
        this.setState({
            notificationsDrawerOpen: false,
            notificationDetailsDrawerOpen: false,
        });
    };
    handleNotificationListItemClick = (event, id) => {
        event.preventDefault();
        const notification = find(this.props.notifications, ['id', id]);
        this.setState({
            notificationDetailsDrawerOpen: true,
            notification,
        });
    };
    handleBackButtonClick = () => {
        this.setState({
            notificationDetailsDrawerOpen: false,
        });
    };
    handleAcknowledgeButtonClick = () => {
        this.props.dispatch(
            createSketchOrderNotificationAcknowledgement(
                this.state.notification.id
            )
        );
        this.setState({
            notificationDetailsDrawerOpen: false,
        });
    };
    isOrderSlaCompliant() {
        const dueDate = DateTime.fromISO(this.props.dueDate);
        const completedDate = DateTime.fromISO(this.props.completedDate);

        // if an order is not rushed, does not have a due date, or does not have a completed date
        // it is safe to assume that there is no SLA to be non-compliant with
        if (!this.props.rushed || !dueDate || !completedDate) return true;

        if (completedDate <= dueDate) return true;
        return false;
    }

    render() {
        const { classes } = this.props;
        // wait if we are fetching a sketch order and NOT show another one that may have already been shown
        if (
            !this.props.id ||
            this.props.id.toString() !== this.props.match.params.sketchOrderId
        )
            return null;

        const hasNotifications =
            this.props.notifications && this.props.notifications.length > 0;
        const drawerIsOpen =
            this.state.notificationsDrawerOpen ||
            this.state.notificationDetailsDrawerOpen;
        return (
            <div
                id='sketch-order'
                className={classNames(classes.root, {
                    [classes.rootRightToolbar]: hasNotifications,
                    [classes.rootRightDrawer]: drawerIsOpen,
                })}
            >
                <PageToolbarActions triggers={[this.props.externalId]}>
                    <OpenInExternalAppButton
                        baseUrl={hostConfig.externalAppUrl}
                        externalId={this.props.externalId}
                        appName={hostConfig.externalAppName}
                        variant='contained'
                        color='primary'
                    />
                </PageToolbarActions>
                <SketchOrderCard
                    className='sketch-order-card'
                    sketchOrder={this.state.sketchOrder}
                    id={this.props.id}
                    projectName={this.props.projectName}
                    address={this.props.address}
                    city={this.props.city}
                    region={this.props.region}
                    postCode={this.props.postCode}
                    country={this.props.country}
                    coordinates={this.props.location.coordinates}
                    markers={
                        !this.props.sketchOrderGroup
                            ? null
                            : this.props.sketchOrderGroup.mapMarkerPoints
                    }
                    notes={this.props.notes}
                    sketchReportType={this.props.sketchReportType}
                    sketchOrderStatus={this.props.sketchOrderStatus}
                    adminNotes={this.props.adminNotes}
                    requestedDate={this.props.requestedDate}
                    requester={this.props.requester}
                    owner={this.props.owner}
                    cost={this.props.cost}
                    freeOrder={this.props.freeOrder}
                    users={this.props.users}
                    projectClonedForDelivery={
                        this.props.projectClonedForDelivery
                    }
                    onCancelOrder={this.cancelOrder}
                    onUpdateOrder={this.updateOrder}
                    onOpenProject={this.openProject}
                    onOpenUpdatePaymentDialog={this.openUpdatePaymentDialog}
                    onRequestRevision={this.requestRevisionButtonClick}
                    billingErrorMessage={this.state.retryBillingErrorMessage}
                    downloadSketchOrderReport={this.downloadSketchOrderReport}
                    sketchOrderReport={this.state.sketchOrderReport}
                    rushed={this.props.rushed}
                    rushCost={this.props.rushCost}
                    slaCompliance={this.isOrderSlaCompliant()}
                    revisionNotes={this.props.revisionNotes}
                />
                <RetrySketchOrderBillingDialog
                    open={this.state.updatePaymentDialogOpen}
                    onClose={this.handleUpdatePaymentDialogClose}
                    onRetryBilling={this.retryBilling}
                    onPaymentChange={this.onPaymentChange}
                    retryButtonDisabled={
                        !(this.state.paymentType && this.state.paymentValid)
                    }
                    isRetryingBilling={this.state.isRetryingBilling}
                    organization={this.props.organization}
                    currentUser={this.props.currentUser}
                    currentUserRoles={this.props.currentUserRoles}
                />
                <RequestRevisionDialog
                    open={this.state.requestRevisionDialogOpen}
                    onCancel={this.handleRequestRevisionDialogClose}
                    onRequestRevision={this.handleRequestRevision}
                    textValue={this.state.revisionNoteText}
                    setTextValue={this.setRevisionNoteText}
                />
                {hasNotifications && (
                    <React.Fragment>
                        <SketchOrderNotificationsDrawer
                            notifications={this.props.notifications}
                            notification={this.state.notification}
                            detailsDrawerOpen={
                                this.state.notificationDetailsDrawerOpen
                            }
                            notificationsDrawerOpen={
                                this.state.notificationsDrawerOpen
                            }
                            onNotificationListItemClick={
                                this.handleNotificationListItemClick
                            }
                            onCloseNotificationsButtonClick={
                                this.handleCloseNotificationsButtonClick
                            }
                            onAcknowledgeButtonClick={
                                this.handleAcknowledgeButtonClick
                            }
                            onBackButtonClick={this.handleBackButtonClick}
                        />

                        {/* Bottom navigation menu drawer */}
                        <Drawer
                            open
                            classes={{
                                paper: classNames(
                                    classes.drawer,
                                    classes.toolbarDrawer
                                ),
                            }}
                            {...drawerProps}
                        >
                            <IconButton
                                onClick={
                                    this.handleOpenNotificationsButtonClick
                                }
                            >
                                <FeedbackIcon />
                            </IconButton>
                        </Drawer>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

SketchOrder.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    id: PropTypes.number.isRequired,
    projectName: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    postCode: PropTypes.string,
    country: PropTypes.string,
    notes: PropTypes.string.isRequired,
    sketchReportType: PropTypes.string.isRequired,
    sketchOrderStatus: PropTypes.string.isRequired,
    requestedDate: PropTypes.string.isRequired,
    rushCost: PropTypes.number,
    rushed: PropTypes.bool,
    adminNotes: PropTypes.string,
    projectClonedForDelivery: PropTypes.bool,
    requester: PropTypes.shape({
        userName: PropTypes.string.isRequired,
    }).isRequired,
    owner: PropTypes.shape({
        userId: PropTypes.number.isRequired,
        userName: PropTypes.string.isRequired,
    }).isRequired,
    cost: PropTypes.number,
    freeOrder: PropTypes.bool,
    version: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    location: PropTypes.shape({
        coordinates: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    users: PropTypes.arrayOf(
        PropTypes.shape({
            userId: PropTypes.number.isRequired,
            userName: PropTypes.string.isRequired,
        })
    ).isRequired,
    organizationId: PropTypes.number.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({ sketchOrderId: PropTypes.string }).isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    organization: PropTypes.shape({
        isFreeAccount: PropTypes.bool,
    }).isRequired,
    currentUser: PropTypes.shape({
        userId: PropTypes.number.isRequired,
    }).isRequired,
    currentUserRoles: PropTypes.shape({
        sketchosEnabled: PropTypes.bool.isRequired,
    }).isRequired,
    sketchOrderPaymentType: PropTypes.string,
    notifications: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            notificationTemplate: PropTypes.shape({
                description: PropTypes.string.isRequired,
                infoLink: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
                notificationType: PropTypes.string.isRequired,
                priorityLevel: PropTypes.string.isRequired,
            }).isRequired,
        })
    ).isRequired,
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
        rootRightToolbar: PropTypes.string.isRequired,
        rootRightDrawer: PropTypes.string.isRequired,
        drawer: PropTypes.string.isRequired,
        toolbarDrawer: PropTypes.string.isRequired,
        viewProjectLink: PropTypes.string.isRequired,
    }).isRequired,
    revisionNotes: PropTypes.arrayOf({
        note: PropTypes.string,
        createdAt: PropTypes.string,
    }).isRequired,
};

SketchOrder.defaultProps = {
    country: '',
    postCode: '',
    adminNotes: '',
    sketchOrderPaymentType: '',
    projectClonedForDelivery: false,
    freeOrder: false,
    cost: 0,
    rushed: false,
    rushCost: 0,
};

const mapStateToProps = (state) => {
    const {
        currentUser,
        sketchOrder,
        organizationUsers,
        organization,
        currentUserRoles,
        sketchOrderNotifications,
        documents,
        documentSharedAccessToken,
    } = state;

    const { notifications } = sketchOrderNotifications;
    const { organizationId } = currentUser;
    const { data: users } = organizationUsers;
    const {
        id,
        projectName,
        address,
        city,
        region,
        postCode,
        country,
        notes,
        sketchReportType,
        sketchOrderStatus,
        requestedDate,
        adminNotes,
        projectClonedForDelivery,
        requester,
        owner,
        cost,
        version,
        location,
        sketchOrderGroup,
        freeOrder,
        projectId,
        sketchOrderPaymentType,
        externalId,
        rushCost,
        rushed,
        completedDate,
        dueDate,
        revisionNotes,
    } = sketchOrder;
    const { sharedAccessSignatureUrl } = documentSharedAccessToken;
    return {
        id,
        projectName,
        address,
        city,
        region,
        postCode,
        country: country || '',
        notes,
        sketchReportType,
        sketchOrderStatus,
        requestedDate,
        adminNotes: adminNotes || '',
        requester,
        owner,
        freeOrder,
        projectClonedForDelivery,
        cost: cost || 0,
        version,
        location,
        sketchOrderGroup,
        externalId,
        organization,
        currentUser,
        organizationId,
        users: orderBy(
            users,
            [(user) => user.userName.toLowerCase()],
            ['asc']
        ).filter((user) => user.active === true),
        currentUserRoles,
        projectId,
        sketchOrderPaymentType,
        notifications,
        documents,
        sharedAccessSignatureUrl,
        rushed,
        rushCost,
        completedDate,
        dueDate,
        revisionNotes,
    };
};

export default compose(
    connect(mapStateToProps),
    withStyles(styles)
)(SketchOrder);
