import React, { useEffect, useRef } from 'react';
import { updateProjectDetails } from 'actions/ProjectActions';
import { setReduxMessage } from 'actions/ReduxMessagesActions';
import { Project } from 'lib/Models';
import { useDispatch } from 'react-redux';
import ProjectDialog from '../ProjectDialog';
import ProjectForm from './ProjectForm';
import ProjectFormNavigation from './ProjectFormNavigation';
import validateProject from 'lib/util/ProjectValidator';
import { useMediaQuery, useTheme } from '@mui/material';

type ProjectFromDialogProps = {
    open?: boolean;
    project: Project;
    focusField?: keyof Project;
    onCancel?: () => void;
    onSave?: () => void;
};

const ProjectFormDialog = ({
    open,
    project,
    focusField,
    onSave,
    onCancel,
}: ProjectFromDialogProps) => {
    const dispatch = useDispatch();
    const projectRef = useRef<Partial<Project>>({});
    const theme = useTheme();
    const shouldCollapseNav = useMediaQuery(theme.breakpoints.down(400));

    const handleProjectEditSave = async () => {
        const validationResult = validateProject(projectRef.current);

        if (!validationResult.isValid) return;

        try {
            const result: { response: Project; error: any } = (await dispatch(
                updateProjectDetails(projectRef.current)
            )) as any;

            if (result.error) throw Error(result.error);

            dispatch(setReduxMessage('The project was successfully updated'));

            if (onSave) onSave();
        } catch (error) {
            dispatch(
                setReduxMessage('We were unable to save, please try again')
            );
        }
    };

    const handleProjectEditCancel = () => {
        projectRef.current = { ...project };
        if (onCancel) onCancel();
    };

    useEffect(() => {
        projectRef.current = { ...project };
    }, [project]);

    return (
        <ProjectDialog
            open={open}
            title='Edit Details'
            navigation={
                !shouldCollapseNav && (
                    <ProjectFormNavigation focusField={focusField} />
                )
            }
            projectId={projectRef.current.id}
            projectName={projectRef.current.projectName}
            actions={[
                {
                    text: 'Cancel',
                    buttonVariant: 'md3-secondary',
                    onClick: handleProjectEditCancel,
                },
                {
                    text: 'Save',
                    buttonVariant: 'md3-primary',
                    onClick: handleProjectEditSave,
                },
            ]}
            onClose={handleProjectEditCancel}
        >
            <ProjectForm projectRef={projectRef} />
        </ProjectDialog>
    );
};

export default ProjectFormDialog;
