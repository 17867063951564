import {
    GENERATE_PAYMENT_CLIENT_TOKEN_REQUEST,
    GENERATE_PAYMENT_CLIENT_TOKEN_SUCCESS,
    GENERATE_PAYMENT_CLIENT_TOKEN_FAILURE,
    UPDATE_PAYMENT_METHOD_REQUEST,
    UPDATE_PAYMENT_METHOD_SUCCESS,
    UPDATE_PAYMENT_METHOD_FAILURE,
} from '../actions/Payment';

function Payment(state = {
    paymentClientToken: '',
    isFetching: false,
    isFetchingText: '',
}, action) {
    switch (action.type) {
    case GENERATE_PAYMENT_CLIENT_TOKEN_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case GENERATE_PAYMENT_CLIENT_TOKEN_SUCCESS:
        return Object.assign({}, state, {
            paymentClientToken: action.response.paymentClientToken,
            isFetching: false,
        });
    case GENERATE_PAYMENT_CLIENT_TOKEN_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            paymentClientToken: '',
        });
    case UPDATE_PAYMENT_METHOD_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
            isFetchingText: 'Completing Profile!',
        });
    case UPDATE_PAYMENT_METHOD_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            isFetchingText: '',
        });
    case UPDATE_PAYMENT_METHOD_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            isFetchingText: '',
        });
    default:
        return state;
    }
}

export default Payment;
