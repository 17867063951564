import {
    ADD_ITEM_TO_CART,
    CART_ITEM_COUNT,
    REMOVE_ITEM_FROM_CART,
    TOGGLE_CART_OPEN,
    REMOVE_ALL_BUT_FIRST_ITEM,
    UPDATE_ITEM_IN_CART,
    CLEAR_CART,
} from '../actions/CartActions';

export default function cart(state = {
    sketchOrderCount: 0,
    open: false,
    sketchOrders: [],
}, action) {
    switch (action.type) {
    case ADD_ITEM_TO_CART:
        return {
            ...state,
            sketchOrders: [...state.sketchOrders, action.item],
        };
    case CART_ITEM_COUNT:
        return {
            ...state,
            sketchOrderCount: state.sketchOrders.length,
        };
    case REMOVE_ITEM_FROM_CART:
        return {
            ...state,
            sketchOrders: state.sketchOrders.filter(item => item !== action.item),
        };
    case UPDATE_ITEM_IN_CART:
        return Object.assign({}, state, {
            sketchOrders: Object.assign([...state.sketchOrders], {
                [state.sketchOrders.findIndex(item => (item === action.item))]: action.update,
            }),
        });
    case TOGGLE_CART_OPEN:
        return {
            ...state,
            open: action.open ?? !state.open,
        };
    case REMOVE_ALL_BUT_FIRST_ITEM: {
        return {
            ...state,
            sketchOrders: state.sketchOrders.length > 0 ? [state.sketchOrders[0]] : state.sketchOrders,
        };
    }
    case CLEAR_CART: {
        return {
            ...state,
            sketchOrders: [],
        };
    }
    default:
        return state;
    }
}
