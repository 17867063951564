import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { emailValidation } from '../../lib/HelperFunctions';
import AutocompleteSelect from '../AutocompleteSelect';

const style = theme => ({
    headerStyle: {
        marginBottom: 0,
        marginTop: 40,
    },
    textFieldStyle: {
        width: '100%',
    },
    hide: {
        visibility: 'hidden',
    },
    formControl: {
        margin: theme.spacing(1),
        marginLeft: 0,
    },
    controls: {
        width: '100%',
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
});

class CreateProjectForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMoreDetails: false,
            validator: {
                billingEmail: true,
                customerEmail: true,
                adjusterEmail: true,
            },
        };
    }
    componentDidMount() {
        if (this.props.offices.length === 0) {
            this.props.showMissingOfficesMessage();
        }
    }
    getOfficeSelectItems = () => this.props.offices.map(i =>
        ({ title: i.label, value: i.value }
        ));
    handleEmailChange = (event) => {
        const emailIsValid = !emailValidation(event.target.value);
        this.setState({
            validator: {
                ...this.state.validator,
                [event.target.name]: emailIsValid,
            },
        });
        this.props.validate(emailIsValid);
        if (emailIsValid) {
            this.props.onInputChange(event);
        }
    };
    handleMoreLessClick = () => {
        this.setState({
            showMoreDetails: !this.state.showMoreDetails,
        });
    };
    render() {
        const { classes } = this.props;
        const { validator } = this.state;
        return (
            <div id="create-project-form">
                <div>
                    <Typography variant="h6" className={classes.headerStyle}>Project Info</Typography>
                    <FormControl
                        className={this.props.classes.formControl}
                        fullWidth
                    >
                        <InputLabel shrink>Project name</InputLabel>
                        <Input
                            id="projectName"
                            type="text"
                            onChange={this.props.onInputChange}
                            name="projectName"
                            inputProps={{
                                maxLength: 100,
                            }}
                        />
                    </FormControl>
                    <FormControl
                        className={classes.formControl}
                        fullWidth
                        style={{ zindex: 1000 }}
                    >
                        <Autocomplete
                            name="office"
                            value={this.getOfficeSelectItems().find(o => o.value === this.props.officeId)}
                            getOptionSelected={(option, value) => option.value === value.value}
                            options={this.getOfficeSelectItems()}
                            onChange={(event, newValue) => {
                                if (newValue != null) {
                                    this.props.onOfficeChange(newValue.value);
                                }
                            }}
                            getOptionLabel={option => option.title}
                            renderInput={params => <TextField {...params} label="Office" variant="outlined" />}
                        />
                    </FormControl>
                    <FormControl
                        className={classes.formControl}
                        fullWidth
                        style={{ zindex: 1000 }}
                    >
                        <InputLabel shrink>Status</InputLabel>
                        <Input
                            id="projectStatus"
                            fullWidth
                            value={this.props.projectStatus}
                            onChange={this.props.onStatusChange}
                            inputComponent={AutocompleteSelect}
                            inputProps={{
                                name: 'projectStatus',
                                instanceId: 'projectStatus',
                                simpleValue: true,
                                options: this.props.statuses,
                                disableItemRemoval: true,
                            }}
                        />
                    </FormControl>
                </div>
                <div>
                    <Typography variant="h6" className={classes.headerStyle}>Customer/Job Site Info</Typography>
                    <FormControl
                        className={this.props.classes.formControl}
                        fullWidth
                    >
                        <InputLabel shrink>Name</InputLabel>
                        <Input
                            id="customerName"
                            type="text"
                            onChange={this.props.onInputChange}
                            name="customerName"
                            inputProps={{
                                maxLength: 100,
                            }}
                        />
                    </FormControl>
                    <FormControl
                        className={this.props.classes.formControl}
                        fullWidth
                    >
                        <InputLabel shrink>Address</InputLabel>
                        <Input
                            id="customerAddress"
                            type="text"
                            onChange={this.props.onInputChange}
                            name="customerAddress"
                            inputProps={{
                                maxLength: 100,
                            }}
                        />
                    </FormControl>
                    <FormControl
                        className={this.props.classes.formControl}
                        fullWidth
                    >
                        <InputLabel shrink>City</InputLabel>
                        <Input
                            id="customerCity"
                            type="text"
                            onChange={this.props.onInputChange}
                            name="customerCity"
                            inputProps={{
                                maxLength: 100,
                            }}
                        />
                    </FormControl>
                    <FormControl
                        className={this.props.classes.formControl}
                        fullWidth
                    >
                        <InputLabel shrink>State</InputLabel>
                        <Input
                            id="customerState"
                            type="text"
                            onChange={this.props.onInputChange}
                            name="customerState"
                            inputProps={{
                                maxLength: 100,
                            }}
                        />
                    </FormControl>
                    <FormControl
                        className={this.props.classes.formControl}
                        fullWidth
                    >
                        <InputLabel shrink>Zip</InputLabel>
                        <Input
                            id="customerZip"
                            type="text"
                            onChange={this.props.onInputChange}
                            name="customerZip"
                            inputProps={{
                                maxLength: 100,
                            }}
                        />
                    </FormControl>
                    <FormControl
                        className={this.props.classes.formControl}
                        fullWidth
                    >
                        <InputLabel shrink>Phone</InputLabel>
                        <Input
                            id="customerPhone"
                            type="text"
                            onChange={this.props.onInputChange}
                            name="customerPhone"
                            inputProps={{
                                maxLength: 100,
                            }}
                        />
                    </FormControl>
                    <FormControl
                        className={this.props.classes.formControl}
                        fullWidth
                    >
                        <InputLabel shrink>Phone 2</InputLabel>
                        <Input
                            id="customerPhone2"
                            type="text"
                            onChange={this.props.onInputChange}
                            name="customerPhone2"
                            inputProps={{
                                maxLength: 100,
                            }}
                        />
                    </FormControl>
                    <FormControl
                        className={this.props.classes.formControl}
                        fullWidth
                        error={!validator.customerEmail}
                    >
                        <InputLabel shrink>Email</InputLabel>
                        <Input
                            id="customerEmail"
                            type="text"
                            onChange={this.handleEmailChange}
                            name="customerEmail"
                            inputProps={{
                                maxLength: 100,
                            }}
                        />
                        {!validator.customerEmail &&
                        <FormHelperText id="customerEmail">Email is invalid</FormHelperText>
                        }
                    </FormControl>
                </div>
                <Button
                    variant="text"
                    color="default"
                    className={classes.button}
                    onClick={this.handleMoreLessClick}
                    style={{
                        margin: '20px 0',
                    }}
                >
                    {!this.state.showMoreDetails ? 'More' : 'Less' }
                    {!this.state.showMoreDetails
                        ? <ExpandMoreIcon className={classes.rightIcon} />
                        : <ExpandLessIcon className={classes.rightIcon} />
                    }
                </Button>
                {this.state.showMoreDetails && (
                    <div>
                        <div>
                            <Typography variant="h6" className={classes.headerStyle}>Billing Info</Typography>
                            <FormControl
                                className={this.props.classes.formControl}
                                fullWidth
                            >
                                <InputLabel shrink>Billing name</InputLabel>
                                <Input
                                    id="billingName"
                                    type="text"
                                    onChange={this.props.onInputChange}
                                    name="billingName"
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                className={this.props.classes.formControl}
                                fullWidth
                            >
                                <InputLabel shrink>Billing company</InputLabel>
                                <Input
                                    id="billingCompany"
                                    type="text"
                                    onChange={this.props.onInputChange}
                                    name="billingCompany"
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                className={this.props.classes.formControl}
                                fullWidth
                            >
                                <InputLabel shrink>Billing address</InputLabel>
                                <Input
                                    id="billingAddress"
                                    type="text"
                                    onChange={this.props.onInputChange}
                                    name="billingAddress"
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                className={this.props.classes.formControl}
                                fullWidth
                            >
                                <InputLabel shrink>Billing city</InputLabel>
                                <Input
                                    id="billingCity"
                                    type="text"
                                    onChange={this.props.onInputChange}
                                    name="billingCity"
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                className={this.props.classes.formControl}
                                fullWidth
                            >
                                <InputLabel shrink>Billing state</InputLabel>
                                <Input
                                    id="billingState"
                                    type="text"
                                    onChange={this.props.onInputChange}
                                    name="billingState"
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                className={this.props.classes.formControl}
                                fullWidth
                            >
                                <InputLabel shrink>Billing zip</InputLabel>
                                <Input
                                    id="billingZip"
                                    type="text"
                                    onChange={this.props.onInputChange}
                                    name="billingZip"
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                className={this.props.classes.formControl}
                                fullWidth
                            >
                                <InputLabel shrink>Billing phone</InputLabel>
                                <Input
                                    id="billingPhone"
                                    type="text"
                                    onChange={this.props.onInputChange}
                                    name="billingPhone"
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                className={this.props.classes.formControl}
                                fullWidth
                            >
                                <InputLabel shrink>Billing phone 2</InputLabel>
                                <Input
                                    id="billingPhone2"
                                    type="text"
                                    onChange={this.props.onInputChange}
                                    name="billingPhone2"
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                className={this.props.classes.formControl}
                                fullWidth
                                error={!validator.billingEmail}
                            >
                                <InputLabel shrink>Billing email</InputLabel>
                                <Input
                                    id="billingEmail"
                                    type="text"
                                    onChange={this.handleEmailChange}
                                    name="billingEmail"
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                                {!validator.billingEmail &&
                                <FormHelperText id="billingEmail">Email is invalid</FormHelperText>
                                }
                            </FormControl>

                        </div>
                        <div>
                            <Typography variant="h6" className={classes.headerStyle}>Insurer Details</Typography>
                            <FormControl
                                className={this.props.classes.formControl}
                                fullWidth
                            >
                                <InputLabel shrink>Insurer name</InputLabel>
                                <Input
                                    id="insurerName"
                                    type="text"
                                    onChange={this.props.onInputChange}
                                    name="insurerName"
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                className={this.props.classes.formControl}
                                fullWidth
                            >
                                <InputLabel shrink>Adjuster name</InputLabel>
                                <Input
                                    id="adjusterName"
                                    type="text"
                                    onChange={this.props.onInputChange}
                                    name="adjusterName"
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                className={this.props.classes.formControl}
                                fullWidth
                            >
                                <InputLabel shrink>Adjuster contact number</InputLabel>
                                <Input
                                    id="adjusterContactNumber"
                                    type="text"
                                    onChange={this.props.onInputChange}
                                    name="adjusterContactNumber"
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                className={this.props.classes.formControl}
                                fullWidth
                            >
                                <InputLabel shrink>Claim number</InputLabel>
                                <Input
                                    id="claimNumber"
                                    type="text"
                                    onChange={this.props.onInputChange}
                                    name="claimNumber"
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                className={this.props.classes.formControl}
                                fullWidth
                            >
                                <InputLabel shrink>Claims department number</InputLabel>
                                <Input
                                    id="claimsDeptContactNumber"
                                    type="text"
                                    onChange={this.props.onInputChange}
                                    name="claimsDeptContactNumber"
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                className={this.props.classes.formControl}
                                fullWidth
                                error={!validator.adjusterEmail}
                            >
                                <InputLabel shrink>Email</InputLabel>
                                <Input
                                    id="adjusterEmail"
                                    type="text"
                                    onChange={this.handleEmailChange}
                                    name="adjusterEmail"
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                                {!validator.adjusterEmail &&
                                <FormHelperText id="adjusterEmail">Email is invalid</FormHelperText>
                                }
                            </FormControl>

                        </div>
                    </div>
                )}
            </div>
        );
    }
}

CreateProjectForm.propTypes = {
    onOfficeChange: PropTypes.func.isRequired,
    onStatusChange: PropTypes.func.isRequired,
    onInputChange: PropTypes.func.isRequired,
    validate: PropTypes.func.isRequired,
    offices: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    statuses: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    officeId: PropTypes.number,
    projectStatus: PropTypes.string,
    showMissingOfficesMessage: PropTypes.func.isRequired,
    classes: PropTypes.shape({
        formControl: PropTypes.string.isRequired,
    }).isRequired,
};

CreateProjectForm.defaultProps = {
    officeId: null,
    projectStatus: 'NewLead',
};

export default withStyles(style)(CreateProjectForm);
