import React, { ReactElement } from "react";
import { useLocation } from "react-router";
import featureFlags from "lib/FeatureFlags";
import PageToolbarNavTabs, {
  TabPanel,
} from "../NavigationComponents/PageToolbarNavTabs";
import DocumentSettingsPage from "./DocumentSettings";
import Billing from "./BillingSettings/Billing";
import { TabPanelProps } from "../NavigationComponents/PageToolbarNavTabs";
import Users from "./UserSettings/Users";

const SettingsPage = () => {
  const location = useLocation();
  const pages = [
    ...(featureFlags.v2UsersPage ? [{ title: "Users", component: <Users />}] : []),
    { title: "Documents", component: <DocumentSettingsPage /> },
    ...(featureFlags.v2Billing ? [{ title: "Billing", component: <Billing />}] : []),
  ]
  const requestedSettingsPageIndex = () => {
    const requestedRouteName = location.pathname.replace("/settings", "").toLowerCase();
    if (requestedRouteName !== "") {
      const page = pages.find(p => requestedRouteName.includes(p.title.toLowerCase()));
      
      if (page) {
        const activeTabIndex = pages.indexOf(page);
        return activeTabIndex;
      }
    }
    
    return 0;
  }
  const getTabs = () => {
    let tabs: {
      title: string,
      index: number,
      route: string,
    }[] = [];
    pages.forEach((p, index) => {
      const tab = {
        title: p.title,
        index: index,
        route: `/settings/${p.title.toLowerCase().split(" ").join("")}`
      };
      tabs.push(tab);
    });
    return tabs;
  };
  const getSettingsTabPanels = () => {
    let panels: ReactElement<TabPanelProps>[] = [];
    pages.forEach((p, index) => {
      panels.push(
        <TabPanel index={index} style={{height: "100%"}} data-cy={p.title.toLowerCase() + "-settings-nav-tab"}>
          {p.component}
        </TabPanel>
      );
    });
    return panels;
  };
  return (
    <PageToolbarNavTabs
      navTabs={{
        activeTabIndex: requestedSettingsPageIndex(),
        tabs: getTabs(),
      }}
    >
      {getSettingsTabPanels()}
    </PageToolbarNavTabs>
  );
};

export default SettingsPage;
