import {
    ButtonGroup,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox,
    Grid,
    createStyles,
    Typography,
    makeStyles,
} from '@material-ui/core';
import CommercialIcon from '@ui/Icons/CommercialIcon';
import MultiFamilyIcon from '@ui/Icons/MultiFamilyIcon';
import SingleFamilyIcon from '@ui/Icons/SingleFamilyIcon';
import BuildingTypeCategories from 'lib/BuildingTypeCategories';
import BuildingTypes from 'lib/BuildingTypes';
import React, { useEffect, useState } from 'react';
import BuildingTypeButton from '../OrderType/LargeIconButton';
import classNames from 'classnames';

type BuildingTypeSelectorProps = {
    onChange: (
        buildingType: BuildingTypes,
        notesRequired: boolean,
        buildingTypeCategory: BuildingTypeCategories
    ) => void;
    buildingTypeCategory?: BuildingTypeCategories;
    value?: BuildingTypes;
};

const useStyles = makeStyles(() =>
    createStyles({
        buttonsContainer: {
            display: 'flex',
        },
        sectionTitle: {
            color: 'var(--color-black, #222)',
            fontSize: '1.125rem',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
            padding: '2.188rem 0rem 0.938rem 0rem',
        },
        inputLabel: {
            color: '#999',
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            letterSpacing: '0.00625rem',
        },
        inputCheckedLabel: {
            color: '#222',
        },
        required: {
            color: 'var(--color-negative-200, #EA1D3D)',
        },
    })
);

const BuildingTypeSelector = ({
    onChange,
    value,
    buildingTypeCategory,
}: BuildingTypeSelectorProps) => {
    const classes = useStyles();

    const [buildingType, setBuildingType] = useState<BuildingTypes>(
        value ?? BuildingTypes.MainBuildingOnly
    );

    const [activeBuildingTypeCategory, setActiveBuildingTypeCategory] =
        useState<BuildingTypeCategories>(
            buildingTypeCategory ?? 'Single Family'
        );

    const showOutbuilding = activeBuildingTypeCategory === 'Single Family';

    const showStructureOptions =
        activeBuildingTypeCategory === 'Multi-Family' ||
        activeBuildingTypeCategory === 'Commercial';

    const handleBuildingTypeChange = (
        buildingType: BuildingTypes,
        buildingTypeCategory: BuildingTypeCategories
    ) => {
        setBuildingType(buildingType);
        setActiveBuildingTypeCategory(buildingTypeCategory);
    };

    const handleOutBuildingChecked = (
        event: React.ChangeEvent<HTMLInputElement>
    ) =>
        event.target.checked
            ? setBuildingType(BuildingTypes.MainBuildingWithGarage)
            : setBuildingType(BuildingTypes.MainBuildingOnly);

    useEffect(() => {
        const notesRequired = buildingType === BuildingTypes.PartialStructure;
        onChange(buildingType, notesRequired, activeBuildingTypeCategory);
    }, [buildingType, activeBuildingTypeCategory, onChange]);

    const OutbuildingCheckbox = () => (
        <FormControlLabel
            control={
                <Checkbox
                    checked={
                        buildingType === BuildingTypes.MainBuildingWithGarage
                    }
                    onChange={handleOutBuildingChecked}
                />
            }
            label={
                <Typography
                    className={classNames(classes.inputLabel, {
                        [classes.inputCheckedLabel]:
                            buildingType ===
                            BuildingTypes.MainBuildingWithGarage,
                    })}
                >
                    Include outbuilding
                </Typography>
            }
        />
    );

    const StructureOptions = () => (
        <RadioGroup
            row
            value={buildingType}
            onChange={(e) => setBuildingType(parseInt(e.target.value))}
        >
            <FormControlLabel
                value={BuildingTypes.WholeStructure}
                control={<Radio />}
                label={
                    <Typography
                        className={classNames(classes.inputLabel, {
                            [classes.inputCheckedLabel]:
                                buildingType === BuildingTypes.WholeStructure,
                        })}
                    >
                        Whole Building
                    </Typography>
                }
            />
            <FormControlLabel
                value={BuildingTypes.PartialStructure}
                control={<Radio />}
                label={
                    <Typography
                        className={classNames(classes.inputLabel, {
                            [classes.inputCheckedLabel]:
                                buildingType === BuildingTypes.PartialStructure,
                        })}
                    >
                        Partial Structure
                    </Typography>
                }
            />
        </RadioGroup>
    );

    const BuildingTypeButtons = () => (
        <ButtonGroup>
            <BuildingTypeButton
                onClick={() =>
                    handleBuildingTypeChange(
                        BuildingTypes.MainBuildingOnly,
                        'Single Family'
                    )
                }
                icon={<SingleFamilyIcon />}
                text={'Single Family'}
                active={buildingTypeCategory === 'Single Family'}
                applyLeftRadius
            />
            <BuildingTypeButton
                onClick={() =>
                    handleBuildingTypeChange(
                        BuildingTypes.WholeStructure,
                        'Multi-Family'
                    )
                }
                icon={<MultiFamilyIcon />}
                text={'Multi-Family'}
                active={buildingTypeCategory === 'Multi-Family'}
            />
            <BuildingTypeButton
                onClick={() =>
                    handleBuildingTypeChange(
                        BuildingTypes.WholeStructure,
                        'Commercial'
                    )
                }
                icon={<CommercialIcon />}
                text={'Commercial'}
                active={buildingTypeCategory === 'Commercial'}
                applyRightRadius
            />
        </ButtonGroup>
    );

    return (
        <Grid item container xs={12}>
            <Grid container item>
                <Typography className={classes.sectionTitle}>
                    Select Building Type
                    <Typography
                        variant='inherit'
                        display='inline'
                        className={classes.required}
                    >
                        *
                    </Typography>
                </Typography>
                <Grid
                    item
                    xs={12}
                    className={classes.buttonsContainer}
                    justifyContent='space-around'
                >
                    <BuildingTypeButtons />
                </Grid>
            </Grid>
            {showOutbuilding && (
                <Grid item>
                    <OutbuildingCheckbox />
                </Grid>
            )}
            {showStructureOptions && (
                <Grid item>
                    <StructureOptions />
                </Grid>
            )}
        </Grid>
    );
};

export default BuildingTypeSelector;
