import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Pager from '../Pager/Pager.tsx';
import SketchOrderStatus from '../../lib/SketchOrderStatus.ts';
import FreeSketchOrderIndicator from '../FreeSketchOrders';
import SketchOrderListItem from './SketchOrderListItem';

const style = () => ({
    title: {
        flex: 1,
    },
    selectAllFormControl: {
        flex: 1,
    },
});

const getPrice = (cost, sketchOrderStatus) => {
    let costUI = '';

    if (
        sketchOrderStatus === SketchOrderStatus.Complete ||
        sketchOrderStatus === SketchOrderStatus.Billed
    ) {
        if (typeof cost !== 'undefined' && cost !== null) {
            costUI = cost.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            });
        }
    }

    return costUI;
};

class SketchOrderList extends Component {
    renderSketchOrder = (sketchOrder, index) => (
        <SketchOrderListItem
            sketchOrder={sketchOrder}
            index={index}
            getPrice={getPrice}
            onListItemClick={this.props.onListItemClick}
            onOpenProjectClick={this.props.onOpenProjectClick}
            onSelectSketchOrder={this.props.onSelectSketchOrder}
            downloadSketchOrderReport={this.props.downloadSketchOrderReport}
            getSketchOrderReport={this.props.getSketchOrderReport}
            onUnableToDownloadDocument={this.props.onUnableToDownloadDocument}
            onClickDocumentsList={this.props.onClickDocumentsList}
        />
    );

    render() {
        return (
            <Paper>
                <List>
                    <ListItem>
                        <Typography
                            variant='h6'
                            id='sketch-order-count'
                            className={this.props.classes.title}
                        >
                            {this.props.totalResults} Sketch Order(s){' '}
                            {this.props.titleSuffix}
                        </Typography>
                        <FreeSketchOrderIndicator
                            freeSketchOrderBalance={
                                this.props.freeSketchOrderBalance
                            }
                            openSketchOrders={this.props.openSketchOrders}
                        />
                        <Pager
                            nextClick={this.props.onNextClick}
                            prevClick={this.props.onPrevClick}
                            currentPage={this.props.currentPage}
                            totalPages={this.props.totalPages}
                        />
                    </ListItem>
                    {this.props.selectable && (
                        <ListItem>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(event, checked) =>
                                            this.props.onSelectAllClick(checked)
                                        }
                                        checked={
                                            this.props.allSketchOrdersSelected
                                        }
                                    />
                                }
                                label='Select All'
                                className={
                                    this.props.classes.selectAllFormControl
                                }
                            />
                            {this.props.selectedAction}
                        </ListItem>
                    )}
                    {this.props.sketchOrders.map(this.renderSketchOrder)}
                    <ListItem>
                        <ListItemText />
                        <Pager
                            nextClick={this.props.onNextClick}
                            prevClick={this.props.onPrevClick}
                            currentPage={this.props.currentPage}
                            totalPages={this.props.totalPages}
                        />
                    </ListItem>
                </List>
            </Paper>
        );
    }
}

SketchOrderList.propTypes = {
    totalResults: PropTypes.number.isRequired,
    sketchOrders: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            projectname: PropTypes.string.isRequired,
            requesteddate: PropTypes.string.isRequired,
            completeddate: PropTypes.string,
            address: PropTypes.string.isRequired,
            city: PropTypes.string.isRequired,
            region: PropTypes.string.isRequired,
            postcode: PropTypes.string.isRequired,
            sketchOrderStatus: PropTypes.string.isRequired,
            freeOrder: PropTypes.bool.isRequired,
            selected: PropTypes.bool,
            rushedCost: PropTypes.number,
            rushed: PropTypes.bool,
        })
    ).isRequired,
    onListItemClick: PropTypes.func.isRequired,
    onOpenProjectClick: PropTypes.func.isRequired,
    onNextClick: PropTypes.func.isRequired,
    onPrevClick: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    titleSuffix: PropTypes.string,
    selectable: PropTypes.bool,
    onSelectSketchOrder: PropTypes.func,
    onSelectAllClick: PropTypes.func,
    allSketchOrdersSelected: PropTypes.bool,
    selectedAction: PropTypes.node,
    freeSketchOrderBalance: PropTypes.number.isRequired,
    openSketchOrders: PropTypes.number.isRequired,
    classes: PropTypes.shape({
        paper: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        statusIconRoot: PropTypes.string.isRequired,
        statusIconLabel: PropTypes.string.isRequired,
        selectAllFormControl: PropTypes.string.isRequired,
    }).isRequired,
};

SketchOrderList.defaultProps = {
    titleSuffix: '',
    selectable: false,
    onSelectSketchOrder: () => {},
    onSelectAllClick: () => {},
    allSketchOrdersSelected: false,
    selectedAction: PropTypes.node,
};

export default withStyles(style)(SketchOrderList);
