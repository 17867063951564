import React from 'react';

const ImageImportStyle = {
    fileInputStyle: {
        display: 'none',
    },
};

type ImageImportProps = {
    imageImportRef: React.RefObject<HTMLInputElement>;
    callBack: (image: string, mimeType: string) => void;
}

const ImageImport = ({imageImportRef, callBack}: ImageImportProps) => {
    const importImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files)
            return;

        const imageFile = event.target.files[0];
        const reader = new FileReader();

        reader.readAsBinaryString(imageFile);

        reader.onload = (onLoadEvent) => {
            if (!onLoadEvent.target) return;

            const imageAsBinaryString = onLoadEvent.target.result as string;
            const image = btoa(imageAsBinaryString);

            callBack(image, imageFile.type);
        };
    };

    return (
        <input
            ref={imageImportRef}
            name="importImage"
            type="file"
            style={ImageImportStyle.fileInputStyle}
            accept="image/*"
            onChange={importImage}
        />
    );
}

export default ImageImport;
