export enum DocumentTemplateCategories {
    SketchOrderReport = 'gr',
    SketchReport = 'vr',
    Estimate = '4y',
    Contract = 'jd',
    PreStartChecklist = '82',
    SummaryReport = 'd6',
    LaborReport = '36',
    ComparisonEstimate = 'zd',
}
export default DocumentTemplateCategories;
