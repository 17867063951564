import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import LockIcon from '@material-ui/icons/Lock';

const style = () => ({
    root: {
        position: 'relative',
        width: '100%',
    },
    grayedOutBox: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: '#363636',
        opacity: 0.4,
        cursor: 'default',
        zIndex: 1,
        borderRadius: 4,
    },
    lockIcon: {
        color: '#FFFFFF',
        position: 'absolute',
        borderRadius: '50%',
        padding: 13,
        top: -10,
        left: -10,
        opacity: 1,
        zIndex: 5,
    },
});

const LockableElement = props => (
    props.locked ?
        <div className={props.classes.root} onClick={props.onClick} role="presentation">
            {
                props.showLockIcon &&
                <LockIcon
                    size="small"
                    style={{ backgroundColor: props.lockIconBackgroundColor }}
                    className={props.classes.lockIcon}
                />
            }
            <div
                className={props.classes.grayedOutBox}
                style={props.grayedOutBoxStyleWithOverrides}
            />
            {props.children}
        </div>
        :
        <div style={props.notLockedFullWidth ? { width: '100%' } : {}} onClick={props.onClick} role="presentation">{props.children}</div>
);

LockableElement.propTypes = {
    children: PropTypes.node.isRequired,
    locked: PropTypes.bool,
    onClick: PropTypes.func,
    grayedOutBoxOverrideStyles: PropTypes.shape({}),
    showLockIcon: PropTypes.bool,
    lockIconBackgroundColor: PropTypes.string,
    notLockedFullWidth: PropTypes.bool,
};

LockableElement.defaultProps = {
    locked: false,
    onClick: () => {},
    grayedOutBoxOverrideStyles: {},
    showLockIcon: true,
    lockIconBackgroundColor: '#bcbcbc',
    notLockedFullWidth: false,
};

export default withStyles(style)(LockableElement);
