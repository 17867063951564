import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const styles = theme => ({
    button: {
        boxShadow: 'none',
    },
    icon: {
        marginLeft: theme.spacing(1),
    },
});

const OpenInExternalAppButton = (props) => {
    const handleOnClick = () => {
        window.location.href = `${props.baseUrl}/${props.externalId}`;
    };

    return (
        <React.Fragment>
            {
                (props.baseUrl && props.externalId) &&
                <Button
                    onClick={handleOnClick}
                    variant={props.variant}
                    color={props.color}
                    className={props.classes.button}
                >
                    Open in {props.appName} <ExitToAppIcon className={props.classes.icon} />
                </Button>
            }
        </React.Fragment>
    );
};

OpenInExternalAppButton.propTypes = {
    baseUrl: PropTypes.string.isRequired,
    externalId: PropTypes.string.isRequired,
    appName: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
        'default',
        'inherit',
        'primary',
        'secondary',
    ]),
    variant: PropTypes.oneOf([
        'text',
        'flat',
        'outlined',
        'contained',
        'raised',
        'fab',
        'extendedFab',
    ]),
    classes: PropTypes.shape({
        button: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
    }).isRequired,
};

OpenInExternalAppButton.defaultProps = {
    color: 'default',
    variant: 'text',
};

export default withStyles(styles)(OpenInExternalAppButton);
