import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { projectAddressSingleLine } from '../../lib/ProjectFormatter';

const styles = () => ({
    card: {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        padding: 0,
        paddingBottom: '0 !important',
        whiteSpace: 'normal',
    },
    cardContent: {
        padding: 5,
        paddingBottom: '10px !important',
    },
    image: {
        width: '100%',
        height: '100%',
    },
    imageWrapper: {
        minHeight: 150,
        width: 120,
    },
    projectInformation: {
        padding: 5,
        width: 200,
        display: 'flex',
        flexDirection: 'column',
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    root: {
        height: 14,
        width: 14,
        padding: 0,
        minWidth: 'auto',
        minHeight: 'auto',
    },
});

class ProjectCard extends React.Component {
    static propTypes ={
        classes: PropTypes.shape({
            card: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
            cardContent: PropTypes.string.isRequired,
            image: PropTypes.string.isRequired,
            imageWrapper: PropTypes.string.isRequired,
            projectInformation: PropTypes.string.isRequired,
            titleWrapper: PropTypes.string.isRequired,
            root: PropTypes.string.isRequired,
        }).isRequired,
        projectName: PropTypes.string.isRequired,
        roofSnapImageUrl: PropTypes.string.isRequired,
        openProjectDialog: PropTypes.func.isRequired,
    };
    state = { expanded: false };

    getOfficeName = (offices, officeId) => {
        const office = offices.find(elem => elem.id === officeId);
        return office && office.officeName;
    };
    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };
    _openProjectView = (e) => {
        e.stopPropagation();
        this.props.history.push(`/projects/${this.props.id}`);
    };

    _openDeleteConfirmation = (e) => {
        e.stopPropagation();
        this.props.openDeleteConfirmation(this.props.id);
    };
    renderInfoBlurb = (label, value, isDate) =>
        value && (
            <Typography variant="caption" className={this.props.classes.blurb}>
                <b>{label} </b>
                {isDate ? new Date(value).toDateString() : value}
            </Typography>
        );
    render() {
        const {
            classes,
            projectName,
            roofSnapImageUrl,
        } = this.props;

        return (
            <Card className={classes.card}>
                <div>
                    <CardContent className={classes.content}>
                        <div className={classes.projectInformation}>
                            <div className={classes.titleWrapper}>
                                <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    paragraph
                                    className="card-title"
                                    onClick={() =>
                                        this.props.openProjectDialog(this.props)
                                    }
                                >
                                    {projectName || 'No Project Name Provided'}
                                </Typography>
                            </div>
                            <Typography variant="body1" color="textSecondary">
                                {projectAddressSingleLine(this.props)}
                            </Typography>
                        </div>
                        <div className={classes.imageWrapper}>
                            <CardMedia
                                className={classes.image}
                                image={roofSnapImageUrl || '/assets/empty.png'}
                            />
                        </div>
                    </CardContent>
                </div>
            </Card>
        );
    }
}

export default withStyles(styles)(ProjectCard);
