import { isValidEmail } from 'lib/HelperFunctions';
import { Project } from 'lib/Models';

export type ValidationResult = {
    isValid: boolean;
};

export type ProjectValidationResult = {
    fields: {
        customerEmail: ValidationResult;
        billingEmail: ValidationResult;
        adjusterEmail: ValidationResult;
    };
} & ValidationResult;

const validateProject = (
    project: Partial<Project>
): ProjectValidationResult => {
    const customerEmailValid = isValidEmail(project.customerEmail);
    const billingEmailValid = isValidEmail(project.billingEmail);
    const adjusterEmailValid = isValidEmail(project.adjusterEmail);

    return {
        fields: {
            customerEmail: {
                isValid: customerEmailValid,
            },
            billingEmail: {
                isValid: billingEmailValid,
            },
            adjusterEmail: {
                isValid: adjusterEmailValid,
            },
        },
        isValid: customerEmailValid && billingEmailValid && adjusterEmailValid,
    };
};

export default validateProject;
