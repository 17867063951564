const identifyUser = (user) => {
    window.zE('webWidget', 'identify', {
        name: user.name,
        email: user.userName,
        organization: user.orgName,
    });
};

export const openWidget = (user = {}) => {
    if (user) {
        identifyUser(user);
    }
    window.zE('webWidget', 'show');
    window.zE('webWidget', 'open');
};

export const closeWidget = () => {
    window.zE('webWidget', 'hide');
};
