enum BuildingTypes {
    None = 0,
    MainBuildingOnly = 2,
    MainBuildingWithGarage = 1,
    WholeStructure = 4,
    PartialStructure = 8,
    Other = 16,
}

export default BuildingTypes;
