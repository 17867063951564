import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const styles = {
    imageView: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    },
    leftBlackBar: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: '100',
    },
    rightBlackBar: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: '100',
        justifyContent: 'flex-end',
    },
    centerDiv: {
        height: '93vh',
    },
    centerImage: {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
        overflow: 'hidden',
    },
};

const ImageView = props => (
    <div style={styles.imageView}>
        <div style={styles.leftBlackBar}>
            <KeyboardArrowLeft
                onClick={props.arrowLeft}
                className="keyBoardArrowLeft"
            />
        </div>
        <div style={styles.centerDiv}>
            <img
                id="image"
                style={styles.centerImage}
                src={props.image.imageUrl}
                alt="Selected"
            />
        </div>
        <div style={styles.rightBlackBar}>
            <KeyboardArrowRight
                className="keyBoardArrowRight"
                onClick={props.arrowRight}
            />
        </div>
    </div>
);

const ImageViewShape = {
    imageUrl: PropTypes.string,
    id: PropTypes.string,
    projectId: PropTypes.string,
};

ImageView.propTypes = {
    arrowRight: PropTypes.func.isRequired,
    arrowLeft: PropTypes.func.isRequired,
    image: PropTypes.shape(ImageViewShape),
};

ImageView.defaultProps = {
    image: {},
};

export default ImageView;
