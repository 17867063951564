import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { ArrowDropDown } from '@material-ui/icons';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { EstimateTemplatesDialog } from '../EstimateTemplates';

const style = {
    formControl: {
        marginBottom: 25,
    },
    templateButton: {
        textTransform: 'none',
        marginTop: 15,
        justifyContent: 'left',
        paddingLeft: 0,
        fontSize: 16,
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    hide: {
        visibility: 'hidden',
    },
};

const SelectTemplateText = 'None';

class CreateEstimateDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            templateId: '',
            templateName: SelectTemplateText,
            templatesDialogOpen: false,
        };
    }

    onChangeName = (e) => {
        this.setState({ name: e.currentTarget.value });
    };

    onClickTemplate = () => {
        this.setState({ templatesDialogOpen: true });
    };

    onSelectedTemplate = (id, name) => {
        this.setState({
            templatesDialogOpen: false,
            templateId: id,
            templateName: id ? name : SelectTemplateText,
        });
    };

    onCancelTemplate = () => {
        this.setState({ templatesDialogOpen: false });
    };

    onCreate = (e) => {
        e.preventDefault();
        this.props.onCreateClick({
            name: this.state.name,
            templateId: this.state.templateId,
        });
    };

    onCancel = () => {
        this.setState({ name: '', templateId: '' });
        this.props.onCancelClick();
    };

    blankTemplate = () =>
        (this.state.templateId
            ? { id: '', name: '-- No Selected Template --' }
            : undefined);

    render() {
        const { name, templateName, templatesDialogOpen } = this.state;

        return (
            <Dialog open={this.props.open}>
                <DialogTitle>Create Estimate</DialogTitle>
                <DialogContent>
                    <div>
                        <form onSubmit={this.onCreate}>
                            <button type="submit" style={style.hide} />
                            <FormControl
                                style={style.formControl}
                                fullWidth
                                required
                            >
                                <InputLabel shrink htmlFor="name">
                                    Name
                                </InputLabel>
                                <Input
                                    id="name"
                                    autoFocus
                                    value={name}
                                    onChange={this.onChangeName}
                                />
                            </FormControl>
                            <FormControl style={style.formControl} fullWidth>
                                <InputLabel shrink htmlFor="template">
                                    Template
                                </InputLabel>
                                <Button
                                    style={style.templateButton}
                                    onClick={this.onClickTemplate}
                                >
                                    {templateName} <ArrowDropDown />
                                </Button>
                            </FormControl>
                        </form>
                        <EstimateTemplatesDialog
                            open={templatesDialogOpen}
                            onCancel={this.onCancelTemplate}
                            onSelected={this.onSelectedTemplate}
                            blankTemplate={this.blankTemplate()}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onCancel}>Cancel</Button>
                    <Button onClick={this.onCreate} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

CreateEstimateDialog.propTypes = {
    open: PropTypes.bool,
    onCreateClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
};

CreateEstimateDialog.defaultProps = {
    open: false,
};

export default CreateEstimateDialog;
