import React from 'react';
import { CALL_API } from '../middleware/api';
import roofsnapTheme from '../lib/theme';
import { setReduxMessage } from './ReduxMessagesActions';

export const GET_OFFICES_BY_USER_REQUEST = 'GET_OFFICES_BY_USER_REQUEST';
export const GET_OFFICES_BY_USER_SUCCESS = 'GET_OFFICES_BY_USER_SUCCESS';
export const GET_OFFICES_BY_USER_FAILURE = 'GET_OFFICES_BY_USER_FAILURE';

const classicUrl = process.env.REACT_APP_ROOFSNAP_ONLINE_HOST;

export const getUsersOffices = userId => ({
    [CALL_API]: {
        types: [
            GET_OFFICES_BY_USER_REQUEST,
            GET_OFFICES_BY_USER_SUCCESS,
            GET_OFFICES_BY_USER_FAILURE,
        ],
        endpoint: `userprofiles/${userId}/offices?all=true`,
    },
});

export const GET_OFFICES_BY_ORG_REQUEST = 'GET_OFFICES_BY_ORG_REQUEST';
export const GET_OFFICES_BY_ORG_SUCCESS = 'GET_OFFICES_BY_ORG_SUCCESS';
export const GET_OFFICES_BY_ORG_FAILURE = 'GET_OFFICES_BY_ORG_FAILURE';

export const getOrganizationOffices = (
    organizationId,
    page = 1,
    getAllPages = false,
) => ({
    [CALL_API]: {
        types: [
            GET_OFFICES_BY_ORG_REQUEST,
            GET_OFFICES_BY_ORG_SUCCESS,
            GET_OFFICES_BY_ORG_FAILURE,
        ],
        endpoint: `organizations/${organizationId}/offices`,
        getAllPages,
        params: {
            page,
        },
    },
});

export const handleMissingOffices = () => (dispatch, state) => {
    const { userId } = state().currentUser;
    const { isAccountAdmin } = state().currentUserRoles;
    return dispatch(getUsersOffices(userId)).then((result) => {
        if (result && result.type !== 'GET_OFFICES_BY_USER_FAILURE') {
            return;
        }
        if (isAccountAdmin) {
            dispatch(setReduxMessage(
                'You are not assigned to an office.',
                <a
                    style={{
                        color: roofsnapTheme.palette.roofsnapBlue['100'],
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${classicUrl}/Account`}
                >
                    Offices & Users
                </a>,
            ));
        } else {
            dispatch(setReduxMessage('You are not assigned to an office. Please contact an account administrator.'));
        }
    });
};

export const GET_OFFICE_USERS_REQUEST = 'GET_OFFICE_USERS_REQUEST';
export const GET_OFFICE_USERS_SUCCESS = 'GET_OFFICE_USERS_SUCCESS';
export const GET_OFFICE_USERS_FAILURE = 'GET_OFFICE_USERS_FAILURE';

export const getOfficeUsers = (
    officeId,
    page = 1,
    getAllPages = false,
) => ({
    [CALL_API]: {
        types: [
            GET_OFFICE_USERS_REQUEST,
            GET_OFFICE_USERS_SUCCESS,
            GET_OFFICE_USERS_FAILURE,
        ],
        endpoint: `offices/${officeId}/userprofiles/`,
        getAllPages,
        params: {
            page,
        },
    },
});
