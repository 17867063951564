import { Dialog as MuiDialog, styled } from '@mui/material';

const Dialog = styled(MuiDialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: '20px',
        maxHeight: '50rem',
    },
    '& .MuiDialogTitle-root': {
        color: '#222',
        fontSize: 22,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '28px',
    },
    '& .MuiDialogContent-root': {},
    '& .MuiDialogActions-root': {
        overflowY: 'hidden',
        padding: '1.5rem',
    },
}));

export default Dialog;
