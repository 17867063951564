import {
    SET_PAGETOOLBAR_BUTTONS,
    SET_PAGETOOLBAR_ELEMENTS,
    SET_PAGETOOLBAR_NAVTABS,
    SET_PAGETOOLBAR_ACTIVE_NAV_TAB,
    RESET_PAGETOOLBAR,
    TOGGLE_TOOLBAR,
    SET_PAGETOOLBAR_REF,
    SET_PAGETOOLBAR_USE_PORTAL,
} from '../actions/RoofSnapPageToolbarActions';

const roofSnapPageToolbar = (
    state = {
        buttons: [],
        elements: null,
        navTabs: {},
        hideToolbar: false,
        usePortal: false,
        ref: null,
    },
    action,
) => {
    switch (action.type) {
    case SET_PAGETOOLBAR_BUTTONS:
        return Object.assign({}, state, {
            buttons: state.buttons.concat(action.buttons),
        });
    case SET_PAGETOOLBAR_ELEMENTS:
        return Object.assign({}, state, {
            elements: action.elements,
        });
    case SET_PAGETOOLBAR_NAVTABS:
        return Object.assign({}, state, {
            navTabs: action.navTabs,
        });
    case SET_PAGETOOLBAR_ACTIVE_NAV_TAB:
        return Object.assign({}, state, {
            navTabs: Object.assign({}, state.navTabs, {
                activeTabIndex: action.activeTabIndex,
            }),
        });
    case RESET_PAGETOOLBAR:
        return Object.assign({}, state, {
            buttons: [],
            elements: null,
            navTabs: null,
        });
    case TOGGLE_TOOLBAR:
        return Object.assign({}, state, {
            hideToolbar: !state.hideToolbar,
        });
    case SET_PAGETOOLBAR_REF:
        return Object.assign({}, state, {
            ref: action.ref,
        })
    case SET_PAGETOOLBAR_USE_PORTAL:
        return Object.assign({}, state, {
            usePortal: action.usePortal,
        })
    default:
        return state;
    }
};

export default roofSnapPageToolbar;
