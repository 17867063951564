import { CALL_API } from '../middleware/api';
import LocalStorageWrapper from '../lib/LocalStorageWrapper';
import decode from '../lib/decodeRoofSnapAuthToken';

export const GET_PRICING_DATA_REQUEST = 'GET_PRICING_DATA_REQUEST';
export const GET_PRICING_DATA_SUCCESS = 'GET_PRICING_DATA_SUCCESS';
export const GET_PRICING_DATA_FAILURE = 'GET_PRICING_DATA_FAILURE';

export const GET_PRICING_DATA_REQUEST_BACKGROUND = 'GET_PRICING_DATA_REQUEST_BACKGROUND';
export const GET_PRICING_DATA_SUCCESS_BACKGROUND = 'GET_PRICING_DATA_SUCCESS_BACKGROUND';
export const GET_PRICING_DATA_FAILURE_BACKGROUND = 'GET_PRICING_DATA_FAILURE_BACKGROUND';

export const getSketchOrderPricing = (requestInBackground = false) => {
    const accessToken = LocalStorageWrapper.getItem('accessToken');
    const { organizationId } = decode(accessToken);
    if (!organizationId) {
        return {
            type: GET_PRICING_DATA_FAILURE,
        };
    }

    const requestTypes = [GET_PRICING_DATA_REQUEST, GET_PRICING_DATA_SUCCESS, GET_PRICING_DATA_FAILURE];
    const backgroundRequestTypes = [GET_PRICING_DATA_REQUEST_BACKGROUND, GET_PRICING_DATA_SUCCESS_BACKGROUND, GET_PRICING_DATA_FAILURE_BACKGROUND];

    return {
        [CALL_API]: {
            types: requestInBackground ? backgroundRequestTypes : requestTypes,
            endpoint: `organizations/${organizationId}/sketchospricegrid`,
            apiVersion: 'v1',
        },
    };
};
