import React from 'react';
import {
    ListItemIcon,
    ListItemText,
    MenuItem,
    makeStyles,
    ITheme,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { OpenInNew } from '@material-ui/icons';

const useStyles = makeStyles((theme: ITheme) => ({
    subMenuItem: {
        padding: 12,
    },
    active: {
        color: theme.palette.primary[500],
    },
    inactive: {
        color: 'rgba(0, 0, 0, 0.87)',
    }
}));

interface DrawerMenuItemProps {
    title: string,
    path: string,
    icon?: Element,
    isActive?: boolean,
    isExternal?: boolean,
    onClick: () => void,
}

const DrawerMenuItem = ({
    title,
    path,
    icon,
    isActive = false,
    isExternal = false,
    onClick,
}: DrawerMenuItemProps) => {
    const classes = useStyles();
    const menuItemIdString = `${title
        .replace(/\s+/g, '-')
        .toLowerCase()}-drawer-menu-item`;

    const itemProps = isExternal ? { href: path, component: 'a', target: '_blank' } : { to: path, component: Link, target: '_self' };

    return (
        <MenuItem
            onClick={onClick}
            className={classes.subMenuItem}
            id={menuItemIdString}
            {...itemProps}
        >
            {icon && (
                <ListItemIcon
                    className={isActive ? classes.active : classes.inactive}
                >
                    {icon}
                </ListItemIcon>
            )}
            <ListItemText
                className={isActive ? classes.active : classes.inactive}
                style={{
                    marginLeft: icon ? 0 : 58,
                    textTransform: 'none'
                }}
            >
                {title}
            </ListItemText>
            {isExternal &&
                <ListItemIcon>
                    <OpenInNew />
                </ListItemIcon>
            }
        </MenuItem>
    );
};

export default DrawerMenuItem;