import {
    GET_PROJECT_ESTIMATE_ITEMS_REQUEST,
    GET_PROJECT_ESTIMATE_ITEMS_SUCCESS,
    GET_PROJECT_ESTIMATE_ITEMS_FAILURE,
} from '../actions/ProjectEstimateItemsActions';

function projectEstimateItems(
    state = {
        totalResults: 0,
        totalPages: 0,
        currentPage: 1,
        estimateItems: [],
        isFetching: false,
    },
    action,
) {
    switch (action.type) {
    case GET_PROJECT_ESTIMATE_ITEMS_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
            totalResults: 0,
            totalPages: 0,
            currentPage: 1,
            estimateItems: [],
        });
    case GET_PROJECT_ESTIMATE_ITEMS_SUCCESS:
        return Object.assign({}, state, {
            totalResults: action.response.totalResults,
            totalPages: action.response.totalPages,
            currentPage: action.response.page,
            estimateItems: action.response.resourceList,
            isFetching: false,
        });
    case GET_PROJECT_ESTIMATE_ITEMS_FAILURE:
        return Object.assign({}, state, {
            totalResults: 0,
            totalPages: 0,
            currentPage: 1,
            estimateItems: [],
            isFetching: false,
        });
    default:
        return state;
    }
}

export default projectEstimateItems;
