import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Search from '../Search/Search';
import Pager from '../Pager/Pager.tsx';
import { getEstimateTemplatesByOfficeId, templatesParams } from '../../actions/EstimateTemplatesActions';

const style = {
    content: {
        paddingLeft: 16,
        paddingRight: 16,
    },
};

const transition = props => <Slide direction="up" {...props} />;

class EstimateTemplatesDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            pageNumber: 1,
        };
    }

    componentDidMount() {
        this.getEstimateTemplates();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.searchText !== this.state.searchText ||
            prevState.pageNumber !== this.state.pageNumber) {
            this.getEstimateTemplates();
        }
    }

    getEstimateTemplates = () => {
        const params = {};
        if (this.state.searchText) params[templatesParams.Search] = this.state.searchText;
        if (this.state.pageNumber > 1) params[templatesParams.Page] = this.state.pageNumber;
        this.props.dispatch(getEstimateTemplatesByOfficeId(this.props.officeId, params));
    };

    listItemClick = (e) => {
        this.props.onSelected(e.currentTarget.dataset.id, e.currentTarget.textContent);
    };

    searchTemplates = (value) => {
        this.setState({ searchText: value, pageNumber: 1 });
    };

    nextProjectsPage = () => {
        this.setState({ pageNumber: this.props.currentPage + 1 });
    };

    prevProjectsPage = () => {
        this.setState({ pageNumber: this.props.currentPage - 1 });
    };

    renderEstimateTemplateListItem = template => (
        <ListItem key={template.id} data-id={template.id} button onClick={this.listItemClick}>
            <ListItemText primary={template.name} />
        </ListItem>
    );

    render() {
        const {
            open, estimateTemplates, blankTemplate, totalPages, currentPage,
        } = this.props;

        return (
            <Dialog open={open} transition={transition}>
                <DialogTitle>
                    <Search placeholder="Find Template" onSearch={this.searchTemplates} onCancel={this.searchTemplates} value={this.state.searchText} />
                </DialogTitle>
                <DialogContent style={style.content}>
                    <List>
                        {blankTemplate && this.renderEstimateTemplateListItem(blankTemplate)}
                        {estimateTemplates.map(this.renderEstimateTemplateListItem)}
                    </List>
                    <Pager
                        totalPages={totalPages}
                        currentPage={currentPage}
                        nextClick={this.nextProjectsPage}
                        prevClick={this.prevProjectsPage}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onCancel}>Cancel</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const estimateTemplateProps = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

EstimateTemplatesDialog.propTypes = {
    dispatch: PropTypes.func.isRequired,
    officeId: PropTypes.number.isRequired,
    estimateTemplates: PropTypes.arrayOf(PropTypes.shape(estimateTemplateProps))
        .isRequired,
    blankTemplate: PropTypes.shape(estimateTemplateProps),
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    open: PropTypes.bool,
    onSelected: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

EstimateTemplatesDialog.defaultProps = {
    open: false,
    blankTemplate: undefined,
};

const mapStateToProps = (state) => {
    const { estimateTemplatesReducer, projectHome } = state;
    const { officeId } = projectHome.currentProject;
    const {
        estimateTemplates,
        isFetching,
        totalPages,
        currentPage,
    } = estimateTemplatesReducer;

    return {
        estimateTemplates,
        isFetching,
        totalPages,
        currentPage,
        officeId,
    };
};

export default connect(mapStateToProps)(EstimateTemplatesDialog);
