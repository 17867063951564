import React, { useEffect } from 'react';

import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import Login from '../Login';
import { login as loginAction, LOGIN_SUCCESS } from '../../actions/Auth';
import { setDesiredRoute } from '../../actions/Routes';
import { useHistory, useLocation } from 'react-router';
import { RoofSnapState } from 'lib/Models/RoofSnapState';
import { Box, Card, CardHeader, styled } from '@mui/material';

const Root = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: '100%',
    width: '100%',
    zIndex: theme.zIndex.appBar + 1,
    backgroundColor: theme.palette.primary.main,
    position: 'fixed',
    top: 0,
}));

const LoginArea = styled(Card)({
    padding: 40,
});

const LoginHeader = styled(CardHeader)({
    padding: 0,
});

const Auth = () => {
    const location = useLocation();
    const history = useHistory();

    const dispatch = useDispatch();

    const { auth, routes } = useSelector(
        (state: RoofSnapState) => state
    );

    const queryParams = queryString.parse(location.search);

    useEffect(() => {
        if (queryParams.returnurl) {
            dispatch(setDesiredRoute(queryParams.returnurl));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams.returnurl]);

    const getDesiredRoute = () => {
        if (routes && routes.route) {
            return routes.route;
        }
        return '/';
    };

    const login = async ({
        username,
        password,
    }: {
        username: string;
        password: string;
    }) => {
        const result = (await dispatch(loginAction(username, password))) as any;

        if (result.type === LOGIN_SUCCESS) {
            history.push(getDesiredRoute());
        }
    };

    return (
        <Root>
            <LoginArea>
                <LoginHeader title='RoofSnap' />
                <Login
                    errorMessage={auth.errorMessage}
                    onLoginClick={(creds) => login(creds)}
                    usernameInitialValue={queryParams.username as string}
                />
            </LoginArea>
        </Root>
    );
};

export default Auth;
