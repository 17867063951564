import get from 'lodash/get';

const baseUrl = `https://${process.env.REACT_APP_MAD_MEGADOLON_HOST}///dev.virtualearth.net/REST/v1/Locations`;

const parseCoordinates = (json) => {
    const path = 'resourceSets[0].resources[0].geocodePoints[0]';
    return {
        latitude: get(json, `${path}.coordinates[0]`),
        longitude: get(json, `${path}.coordinates[1]`),
    };
};


const parseAddress = (json) => {
    const path = 'resourceSets[0].resources[0].address';
    return {
        addressLine1: get(json, `${path}.addressLine`) || '',
        city: get(json, `${path}.locality`) || '',
        state: get(json, `${path}.adminDistrict`) || '',
        postalCode: get(json, `${path}.postalCode`) || '',
        country: get(json, `${path}.countryRegion`) || '',
    };
};


class BingLocationsApiClient {
    constructor(key) {
        this.key = key;
    }

    /**
     * Returns the address of a set of coordinates
     * @param {number} latitude
     * @param {number} longitude
    */
    reverseGeocodeLocation = async (latitude, longitude) => {
        const response = await fetch(`${baseUrl}/${latitude},${longitude}?key=${this.key}`);
        if (!response || !response.ok) {
            throw new Error(`An error has occured reverse geocoding location ${latitude},${longitude}`);
        }

        const json = await response.json();
        const address = parseAddress(json);
        const coordinates = parseCoordinates(json);

        return {
            address,
            coordinates,
        };
    };
}

export default BingLocationsApiClient;
