import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import SubscriptionSummaryCard from '../SubscriptionSummaryCard';
import TransactionsCard from '../TransactionsCard';
import HowYouPayCard from '../HowYouPayCard/HowYouPayCard';
import SubscriptionStatusCard from '../SubscriptionStatusCard';

const styles = {
    pageTitle: {
        marginTop: 32,
        marginBottom: 24,
    },
};

const SubscriberContent = ({
    classes, nextBillAmount, nextBillingDate, status, planName, termLength, isInTrial,
}) => (
    <React.Fragment>
        <Typography className={classes.pageTitle} variant="h5">Subscription</Typography>
        <Grid container spacing={3} direction="column">
            <Grid item>
                <SubscriptionSummaryCard
                    amount={nextBillAmount}
                    nextBillingDate={nextBillingDate}
                    status={status}
                    planName={planName}
                    termLength={termLength}
                    isInTrial={isInTrial}
                />
            </Grid>
            <Grid container item spacing={2}>
                <Grid item xs={12} sm={6} lg={4}>
                    <TransactionsCard onActionButtonClick={() => {}} />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <HowYouPayCard onActionButtonClick={() => {}} />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <SubscriptionStatusCard onActionButtonClick={() => {}} />
                </Grid>
            </Grid>
        </Grid>
    </React.Fragment>
);

SubscriberContent.propTypes = {
    classes: PropTypes.shape({
        pageTitle: PropTypes.string.isRequired,
    }).isRequired,
    nextBillingDate: PropTypes.instanceOf(moment),
    nextBillAmount: PropTypes.number,
    status: PropTypes.string,
    planName: PropTypes.string,
    termLength: PropTypes.number,
    isInTrial: PropTypes.bool,
};

SubscriberContent.defaultProps = {
    nextBillingDate: null,
    nextBillAmount: null,
    status: null,
    planName: null,
    termLength: null,
    isInTrial: null,
};

export default withStyles(styles)(SubscriberContent);

