import { Box, Button, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RoofSnapState } from 'lib/Models';
import { getProjectEstimates } from 'actions/ProjectEstimates';
import EstimateCTA from './EstimateCTA';
import SectionTitle from '../SectionTitle';
import EstimatesListItem from './EstimatesListItem';
import CreateEstimateDialog from 'components/CreateEstimate/CreateEstimateDialog';
import { createProjectEstimate } from 'actions/ProjectEstimate';
import LoggerWrapper from 'lib/Logger';
import { useHistory } from 'react-router';
import AddIcon from '@mui/icons-material/Add';

type EstimatesListSectionProps = {
    projectId: string;
};

type EstimateData = {
    name: string;
    templateId: string;
};

const EstimatesListSection = ({ projectId }: EstimatesListSectionProps) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        projectEstimates: { data: estimates },
    } = useSelector((state: RoofSnapState) => state);

    const [createEstimateDialogOpen, setCreateEstimateDialogOpen] =
        useState(false);

    useEffect(() => {
        dispatch(getProjectEstimates(projectId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    const useCTA = !Boolean(estimates?.length);

    const handleCreateEstimateClicked = async (estimateData: EstimateData) => {
        setCreateEstimateDialogOpen(false);
        try {
            let result: any = await dispatch(
                createProjectEstimate(projectId, estimateData)
            );
            if (result.response.id) {
                history.push(
                    `/projects/${projectId}/estimates/${result.response.id}`
                );
            }
        } catch (error) {
            LoggerWrapper.log(error);
        }
    };
    const handleCancelCreatingEstimate = () => {
        setCreateEstimateDialogOpen(false);
    };

    const AddEstimateButton = () => (
        <Box sx={{ pr: '12px' }} alignSelf='flex-end'>
            <Button
                onClick={() => setCreateEstimateDialogOpen(true)}
                variant='text'
                sx={{ textTransform: 'none' }}
            >
                <AddIcon sx={{ paddingRight: '8px' }} /> Add
            </Button>
        </Box>
    );

    return (
        <Box mt='30px'>
            <Box mb={1.5}>
                <SectionTitle title='Estimates' />
            </Box>
            {useCTA && (
                <EstimateCTA
                    onTryEstimate={() => setCreateEstimateDialogOpen(true)}
                />
            )}
            {!useCTA && (
                <Stack gap='0.625rem'>
                    {estimates?.map((estimate) => (
                        <EstimatesListItem estimate={estimate} />
                    ))}
                    <AddEstimateButton />
                </Stack>
            )}
            <CreateEstimateDialog
                open={createEstimateDialogOpen}
                onCreateClick={handleCreateEstimateClicked}
                onCancelClick={handleCancelCreatingEstimate}
            />
        </Box>
    );
};

export default EstimatesListSection;
