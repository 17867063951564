import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Link } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import PriceOptionsModel from '../../lib/Models/PriceOptionsModel';
import PriceOption from './PriceOption';
import MobileSlider from './MobileSlider';
import PricingDialog from './PricingDialog';
import { getSubscriptionPlans } from '../../actions/subscriptionPlanActions';

const styles = () => ({
    root: {
        overflow: 'hidden',
        display: 'flex',
        flexWrap: 'wrap',
        height: '750px',
        padding: '0px 10px 0px 0px',
    },
    header: {
        padding: '20px 0px 30px 0px',
    },
    contact: {
        textAlign: 'right',
        paddingRight: '16px',
    },
    title: {
        color: '#000000DE',
        textAlign: 'center',
        fontSize: '18px',
        fontWeight: 'light',
    },
    parentGrid: {
        padding: '40px 50px 0px 40px',
    },
    gridHeading: {
        fontSize: '20px',
        textAlign: 'left',
    },
    gridSubHeading: {
        fontSize: '15px',
        color: 'rgba(0,0,0,0.54)',
        textAlign: 'left',
    },
    gridNumber: {
        fontSize: '20px',
        textAlign: 'center',
        paddingTop: '12px',
    },
    gridNumberCaption: {
        fontSize: '12px',
        textAlign: 'center',
        color: 'rgba(0,0,0,0.54)',
    },
    expansionDivider: {
        width: '100%',
        marginBottom: '30px',
        marginTop: '10px',
    },
    salesEmailLink: {
        textDecoration: 'none',
    },
    gridDescription: {
        paddingLeft: '20px',
        maxHeight: '80px',
    },
    customPlans: {
        color: '#005CAA',
        fontSize: '8px',
        letterSpacing: '1.5',
        paddingRight: '10px',
    },
    phoneNumber: {
        color: '#012B50',
        fontSize: '14px',
        fontWeight: 'bold',
        paddingRight: '10px',
    },
    phoneIcon: {
        color: '#012B50',
        fontSize: '16px',
        fontWeight: 'bold',
        verticalAlign: 'text-bottom',
    },
    scrollArea: {
        overflow: 'auto',
        flexWrap: 'noWrap',
        padding: '0px 24px 10px 30px',
        minWidth: '1200px',
    },
    customPlansGrid: {
        backgroundColor: '#FAFAFA',
        border: '1px solid #00000019',
        padding: '16px 20px',
        height: '71px',
    },
    customPlansAvailable: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#012B50',
        padding: '4px 0px',
    },
    moreThanTenUsers: {
        fontSize: '11px',
        padding: '4px 0px',
        color: '#10283E',
    },
    emailIcon: {
        fontSize: '12px',
        color: '#005CAA',
        padding: '3px',
    },
    email: {
        fontSize: '10px',
        color: '#005CAA',
        padding: '3px',
    },
    bottomPhoneIcon: {
        fontSize: '12px',
        color: '#10283E',
        padding: '3px',
    },
    bottomPhoneNumber: {
        fontSize: '11px',
        color: '#10283E',
        padding: '3px',
        fontWeight: 'bold',
    },
    hours: {
        fontSize: '8px',
        color: '#012B50',
        opacity: '0.6',
        padding: '3px 0px 3px 21px',
    },
    contactEmailPhoneHours: {
        paddingLeft: '24px',
    },
});

const Pricing = (props) => {
    const [dialogOpen, handleDialog] = useState(false);
    const [dialogId, setDialogId] = useState('');
    const { classes, dispatch, plans } = props;

    const handleMoreClick = (id) => {
        handleDialog(true);
        setDialogId(id);
    };

    const handlePricingDialogClose = () => {
        handleDialog(false);
        setDialogId('');
    };

    const renderDialog = () => (
        <PricingDialog
            open={dialogOpen}
            onClose={handlePricingDialogClose}
            id={dialogId}
        />
    );

    const optionsArray = () => {
        const options = PriceOptionsModel.slice(1, PriceOptionsModel.length);
        plans.forEach((plan, i) => {
            if (plan.billingFrequency !== 0) {
                options[i].monthlyPrice = plan.price / plan.billingFrequency;
            }
        });
        return options;
    };
    useEffect(() => {
        dispatch(getSubscriptionPlans());
    }, [dispatch]);

    return (
        <>
            {plans.length > 0 && (
                <Grid
                    container
                    direction='column'
                    className={
                        window.innerWidth > 950 ? classes.parentGrid : null
                    }
                    xs={12}
                    alignItems='center'
                >
                    {window.innerWidth < 950 ? (
                        <MobileSlider />
                    ) : (
                        <Grid container direction='column'>
                            <Paper
                                justifyContent='center'
                                className={classes.root}
                                elevation={0}
                            >
                                <Grid container item direction='column'>
                                    <Grid
                                        container
                                        item
                                        direction='row'
                                        className={classes.header}
                                    >
                                        <Grid
                                            container
                                            item
                                            justify='center'
                                            xs={3}
                                        >
                                            <Typography />
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            xs={6}
                                            justify='center'
                                        >
                                            <Typography
                                                className={classes.title}
                                            >
                                                Plans and Pricing
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            container
                                            item
                                            direction='column'
                                            className={classes.contact}
                                            xs={3}
                                        >
                                            <Grid item xs>
                                                <Typography
                                                    className={
                                                        classes.customPlans
                                                    }
                                                >
                                                    FOR CUSTOM PLANS
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                container
                                                direction='row'
                                                justify='flex-end'
                                                spacing={1}
                                            >
                                                <Grid item>
                                                    <PhoneIcon
                                                        className={
                                                            classes.phoneIcon
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    className={classes.contact}
                                                >
                                                    <Link
                                                        href='tel:877-766-3762'
                                                        underline='none'
                                                        color='inherit'
                                                    >
                                                        <Typography
                                                            className={
                                                                classes.phoneNumber
                                                            }
                                                        >
                                                            877.766.3762
                                                        </Typography>
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        item
                                        direction='row'
                                        className={classes.scrollArea}
                                        columns={15}
                                        spacing={1}
                                    >
                                        <Grid item container xs={3}>
                                            <Grid item container xs={12}>
                                                <PriceOption
                                                    {...PriceOptionsModel[0]}
                                                    key={1}
                                                    handleMoreClick={() =>
                                                        handleMoreClick(
                                                            PriceOptionsModel[0]
                                                                .id
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            item
                                            direction='column'
                                            xs={12}
                                            spacing={1}
                                        >
                                            <Grid
                                                container
                                                item
                                                direction='row'
                                                justify='space-evenly'
                                                spacing={1}
                                            >
                                                {optionsArray().map((opts) => (
                                                    <Grid item xs={3}>
                                                        <PriceOption
                                                            {...opts}
                                                            key={
                                                                opts.monthlyPrice
                                                            }
                                                            handleMoreClick={
                                                                handleMoreClick
                                                            }
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>

                                            <Grid container item>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{ padding: '0px' }}
                                                >
                                                    <Paper
                                                        className={
                                                            classes.customPlansGrid
                                                        }
                                                    >
                                                        <Grid
                                                            item
                                                            container
                                                            direction='row'
                                                            xs={12}
                                                        >
                                                            <Grid
                                                                item
                                                                container
                                                                direction='column'
                                                                xs={10}
                                                                alignItems='center'
                                                                justify='center'
                                                            >
                                                                <Grid item>
                                                                    <Typography
                                                                        className={
                                                                            classes.customPlansAvailable
                                                                        }
                                                                    >
                                                                        Custom
                                                                        Plans
                                                                        Available
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography
                                                                        className={
                                                                            classes.moreThanTenUsers
                                                                        }
                                                                    >
                                                                        Have
                                                                        more
                                                                        than 10
                                                                        users?
                                                                        Contact
                                                                        us for
                                                                        custom
                                                                        plans
                                                                        and
                                                                        pricing.
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                container
                                                                direction='column'
                                                                xs={2}
                                                                alignContent='center'
                                                                justify='flex-start'
                                                                className={
                                                                    classes.contactEmailPhoneHours
                                                                }
                                                            >
                                                                <Grid
                                                                    container
                                                                    item
                                                                    direction='row'
                                                                    justify='flex-start'
                                                                    alignContent='center'
                                                                >
                                                                    <Grid item>
                                                                        <EmailIcon
                                                                            className={
                                                                                classes.emailIcon
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Link
                                                                            href='mailto:Hello@roofsnap.com'
                                                                            color='inherit'
                                                                            underline='none'
                                                                        >
                                                                            <Typography
                                                                                className={
                                                                                    classes.email
                                                                                }
                                                                            >
                                                                                Hello@roofsnap.com
                                                                            </Typography>
                                                                        </Link>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid
                                                                    container
                                                                    item
                                                                    direction='row'
                                                                    justify='flex-start'
                                                                    alignContent='center'
                                                                >
                                                                    <Grid item>
                                                                        <PhoneIcon
                                                                            className={
                                                                                classes.bottomPhoneIcon
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Link
                                                                            href='tel:877-766-3762'
                                                                            underline='none'
                                                                            color='inherit'
                                                                        >
                                                                            <Typography
                                                                                className={
                                                                                    classes.bottomPhoneNumber
                                                                                }
                                                                            >
                                                                                877.766.3762
                                                                            </Typography>
                                                                        </Link>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid
                                                                    container
                                                                    direction='row'
                                                                    item
                                                                    justify='flex-start'
                                                                >
                                                                    <Typography
                                                                        className={
                                                                            classes.hours
                                                                        }
                                                                    >
                                                                        9AM -
                                                                        5PM
                                                                        (EST)
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    )}
                    {renderDialog()}
                </Grid>
            )}
        </>
    );
};

Pricing.propTypes = {
    classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
    const { subscriptionPlans } = state;
    const { plans, isFetching } = subscriptionPlans;
    return {
        plans,
        isFetching,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(Pricing));
