export const MeasurementActions = [
    {
        id: 'project-sketch-card',
        title: 'Edit Diagram',
        path: (projectId: string) => `/projects/${projectId}/sketch`,
    },
    {
        id: 'project-measurements-card',
        title: 'Measurements',
    },
    {
        id: '3d-viewer-card',
        title: '3D Viewer (Beta)',
    },
];
