import {
    CREATE_DOCUMENT_REQUEST,
    CREATE_DOCUMENT_SUCCESS,
    CREATE_DOCUMENT_FAILURE,
    DELETE_DOCUMENT_REQUEST,
    DELETE_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_FAILURE,
} from '../actions/documentActions';

const initialState = {
    isFetching: false,
};

const documentReducer = (state = initialState, action) => {
    switch (action.type) {
    case CREATE_DOCUMENT_REQUEST:
    case DELETE_DOCUMENT_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case CREATE_DOCUMENT_SUCCESS:
    case CREATE_DOCUMENT_FAILURE:
    case DELETE_DOCUMENT_SUCCESS:
    case DELETE_DOCUMENT_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
        });
    default:
        return state;
    }
};

export default documentReducer;
