import {
    Box,
    createStyles,
    Grid,
    makeStyles,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core';
import MetalRoofOption from 'lib/MetalRoofOption';
import React from 'react';

type MetalPanelSizeProps = {
    onMetalSizeOptionsChange?: (metalOptions: number | undefined) => void;
    selectedMetalPanelSize: number | undefined;
};

const useStyles = makeStyles(() =>
    createStyles({
        selectMenu: {
            color: '#222',
            fontSize: '.9rem',
            fontWeight: 400,
            fontStyle: 'normal',
            lineHeight: '1.5rem',
            letterSpacing: '0.03125rem',
        },
        selectTitle: {
            color: '#222',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '1.25rem',
            letterSpacing: '0.00625rem',
            paddingTop: '10px',
            paddingBottom: '5px',
        },
        menuStyle: {
            fontSize: '.9rem',
            whiteSpace: 'nowrap',
        },
        transparentFocusColor: {
            '&:focus': {
                backgroundColor: 'transparent',
            },
        },
        boxContainer: {
            border: 'solid',
            borderWidth: 1,
            borderRadius: 4,
            borderColor: '#d3d3d3',
            display: 'flex',
            flexWrap: 'nowrap',
            padding: '.2rem .7rem',
            height: '1.5rem',
            width: '15rem',
            marginTop: '.4rem',
            marginBottom: '.5rem',
        },
    })
);

const MetalPanelSize = ({
    selectedMetalPanelSize,
    onMetalSizeOptionsChange,
}: MetalPanelSizeProps) => {
    const classes = useStyles();

    const handleMetalOptionChange = (value: number | undefined) => {
        if (onMetalSizeOptionsChange) {
            onMetalSizeOptionsChange(value);
        }
    };

    return (
        <Grid container direction='column'>
            <Typography className={classes.selectTitle}>
                Select metal panel size
                <span style={{ color: '#B3261E' }}>*</span>
            </Typography>
            <Box className={classes.boxContainer}>
                <Select
                    fullWidth
                    variant='standard'
                    disableUnderline={true}
                    style={{ width: '15rem', fontSize: '.9rem' }}
                    classes={{ root: classes.transparentFocusColor }}
                    value={selectedMetalPanelSize}
                    onChange={(e) =>
                        handleMetalOptionChange(
                            e.target.value as number | undefined
                        )
                    }
                    id='metal-options'
                    displayEmpty
                    name='metalOptions'
                >
                    {Object.keys(MetalRoofOption).map((key) => {
                        const option = MetalRoofOption[key];
                        return (
                            <MenuItem
                                style={{ minHeight: 'auto' }}
                                className={classes.menuStyle}
                                key={key}
                                value={option.value}
                            >
                                {MetalRoofOption[key].label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Box>
        </Grid>
    );
};

export default MetalPanelSize;
