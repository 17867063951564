export const ADD_ITEM_TO_CART = Symbol('ADD_ITEM_TO_CART');
export const REMOVE_ITEM_FROM_CART = Symbol('REMOVE_ITEM_FROM_CART');
export const UPDATE_ITEM_IN_CART = Symbol('UPDATE_ITEM_IN_CART');
export const TOGGLE_CART_OPEN = Symbol('TOGGLE_CART_OPEN');
export const CART_ITEM_COUNT = Symbol('CART_ITEM_COUNT');
export const REMOVE_ALL_BUT_FIRST_ITEM = Symbol('REMOVE_ALL_BUT_FIRST_ITEM');
export const CLEAR_CART = Symbol('CLEAR_CART');

export const addItemToCart = item => ({
    type: ADD_ITEM_TO_CART,
    item,
});

export const removeItemFromCart = item => ({
    type: REMOVE_ITEM_FROM_CART,
    item,
});

export const updateItemInCart = (item, update) => ({
    type: UPDATE_ITEM_IN_CART,
    item,
    update,
});

export const toggleCartOpen = open => ({
    type: TOGGLE_CART_OPEN,
    open,
});

export const countSketchOrders = () => ({
    type: CART_ITEM_COUNT,
});

export const removeAllButFirstItemFromCart = () => ({
    type: REMOVE_ALL_BUT_FIRST_ITEM,
});

export const clearCart = () => ({
    type: CLEAR_CART,
});
