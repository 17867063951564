import { CALL_API } from '../middleware/api';
import httpRequestMethods from '../middleware/httpRequestMethods';
import { SET_REDUX_MESSAGE } from './ReduxMessagesActions';

export const GET_SKETCH_ORDER_BY_ID_REQUEST = 'GET_SKETCH_ORDER_BY_ID_REQUEST';
export const GET_SKETCH_ORDER_BY_ID_SUCCESS = 'GET_SKETCH_ORDER_BY_ID_SUCCESS';
export const GET_SKETCH_ORDER_BY_ID_FAILURE = 'GET_SKETCH_ORDER_BY_ID_FAILURE';

export const getSketchOrderById = (id) => ({
    [CALL_API]: {
        types: [
            GET_SKETCH_ORDER_BY_ID_REQUEST,
            GET_SKETCH_ORDER_BY_ID_SUCCESS,
            GET_SKETCH_ORDER_BY_ID_FAILURE,
        ],
        endpoint: `sketchorders/${id}`,
        apiVersion: 'v2',
    },
});

export const CHANGE_SKETCH_ORDER_STATUS_REQUEST =
    'CHANGE_SKETCH_ORDER_STATUS_REQUEST';
export const CHANGE_SKETCH_ORDER_STATUS_SUCCESS =
    'CHANGE_SKETCH_ORDER_STATUS_SUCCESS';
export const CHANGE_SKETCH_ORDER_STATUS_FAILURE =
    'CHANGE_SKETCH_ORDER_STATUS_FAILURE';

export const changeSketchOrderStatus = (id, version, status) => ({
    [CALL_API]: {
        types: [
            CHANGE_SKETCH_ORDER_STATUS_REQUEST,
            CHANGE_SKETCH_ORDER_STATUS_SUCCESS,
            CHANGE_SKETCH_ORDER_STATUS_FAILURE,
            SET_REDUX_MESSAGE,
            'Unable to cancel this order.  Try refreshing the page.',
        ],
        endpoint: `sketchorders/${id}/status/${status}`,
        method: httpRequestMethods.PUT,
        header: {
            version,
        },
    },
});

export const UPDATE_SKETCH_ORDER_REQUEST = 'UPDATE_SKETCH_ORDER_REQUEST';
export const UPDATE_SKETCH_ORDER_SUCCESS = 'UPDATE_SKETCH_ORDER_SUCCESS';
export const UPDATE_SKETCH_ORDER_FAILURE = 'UPDATE_SKETCH_ORDER_FAILURE';

export const updateSketchOrder = (id, sketchOrder, version) => ({
    [CALL_API]: {
        types: [
            UPDATE_SKETCH_ORDER_REQUEST,
            UPDATE_SKETCH_ORDER_SUCCESS,
            UPDATE_SKETCH_ORDER_FAILURE,
            SET_REDUX_MESSAGE,
            'Unable to update this order. Try refreshing the page.',
        ],
        endpoint: `sketchorders/${id}`,
        method: httpRequestMethods.PUT,
        header: {
            version,
        },
        body: sketchOrder,
        apiVersion: 'v2',
    },
});

export const CREATE_SKETCH_ORDER_REQUEST = 'CREATE_SKETCH_ORDER_REQUEST';
export const CREATE_SKETCH_ORDER_SUCCESS = 'CREATE_SKETCH_ORDER_SUCCESS';
export const CREATE_SKETCH_ORDER_FAILURE = 'CREATE_SKETCH_ORDER_FAILURE';

export const createSketchOrder = (sketchOrder) => ({
    [CALL_API]: {
        types: [
            CREATE_SKETCH_ORDER_REQUEST,
            CREATE_SKETCH_ORDER_SUCCESS,
            CREATE_SKETCH_ORDER_FAILURE,
        ],
        endpoint: 'sketchorders',
        method: httpRequestMethods.POST,
        body: sketchOrder,
        apiVersion: 'v2',
    },
});

export const CREATE_BULK_SKETCH_ORDER_REQUEST =
    'CREATE_BULK_SKETCH_ORDER_REQUEST';
export const CREATE_BULK_SKETCH_ORDER_SUCCESS =
    'CREATE_BULK_SKETCH_ORDER_SUCCESS';
export const CREATE_BULK_SKETCH_ORDER_FAILURE =
    'CREATE_BULK_SKETCH_ORDER_FAILURE';

export const createSketchOrders = (
    sketchOrders,
    paymentType,
    paymentNonce
) => ({
    [CALL_API]: {
        types: [
            CREATE_BULK_SKETCH_ORDER_REQUEST,
            CREATE_BULK_SKETCH_ORDER_SUCCESS,
            CREATE_BULK_SKETCH_ORDER_FAILURE,
        ],
        endpoint: 'sketchorders/bulk',
        method: httpRequestMethods.POST,
        body: {
            sketchOrders,
            sketchOrderPaymentType: paymentType,
            paymentMethodNonce: paymentNonce,
        },
        apiVersion: 'v2',
    },
});

export const UPDATE_SKETCH_ORDER_PAYMENT_METHOD_REQUEST =
    'UPDATE_SKETCH_ORDER_PAYMENT_METHOD_REQUEST';
export const UPDATE_SKETCH_ORDER_PAYMENT_METHOD_SUCCESS =
    'UPDATE_SKETCH_ORDER_PAYMENT_METHOD_SUCCESS';
export const UPDATE_SKETCH_ORDER_PAYMENT_METHOD_FAILURE =
    'UPDATE_SKETCH_ORDER_PAYMENT_METHOD_FAILURE';

export const updateSketchOrderPaymentMethod = (
    orderId,
    paymentMethodNonce
) => ({
    [CALL_API]: {
        endpoint: `sketchorders/${orderId}/paymentmethod`,
        types: [
            UPDATE_SKETCH_ORDER_PAYMENT_METHOD_REQUEST,
            UPDATE_SKETCH_ORDER_PAYMENT_METHOD_SUCCESS,
            UPDATE_SKETCH_ORDER_PAYMENT_METHOD_FAILURE,
        ],
        method: httpRequestMethods.PUT,
        body: {
            paymentMethodNonce,
        },
        apiVersion: 'v2',
    },
});

export const GET_SKETCH_ORDER_BY_EXTERNAL_ID_REQUEST =
    'GET_SKETCH_ORDER_BY_EXTERNAL_ID_REQUEST';
export const GET_SKETCH_ORDER_BY_EXTERNAL_ID_SUCCESS =
    'GET_SKETCH_ORDER_BY_EXTERNAL_ID_SUCCESS';
export const GET_SKETCH_ORDER_BY_EXTERNAL_ID_FAILURE =
    'GET_SKETCH_ORDER_BY_EXTERNAL_ID_FAILURE';

export const getSketchOrderByExternalId = (organizationId, externalId) => ({
    [CALL_API]: {
        endpoint: `organizations/${organizationId}/sketchorders/external/${externalId}`,
        types: [
            GET_SKETCH_ORDER_BY_EXTERNAL_ID_REQUEST,
            GET_SKETCH_ORDER_BY_EXTERNAL_ID_SUCCESS,
            GET_SKETCH_ORDER_BY_EXTERNAL_ID_FAILURE,
        ],
    },
});

export const CREATE_REVISION_REQUEST = 'CREATE_REVISION_REQUEST';
export const CREATE_REVISION_SUCCESS = 'CREATE_REVISION_SUCCESS';
export const CREATE_REVISION_FAILURE = 'CREATE_REVISION_FAILURE';

export const createRevision = (orderId, note, version) => ({
    [CALL_API]: {
        types: [
            CREATE_REVISION_REQUEST,
            CREATE_REVISION_SUCCESS,
            CREATE_REVISION_FAILURE,
        ],
        endpoint: `sketchorders/${orderId}/revisionnotes`,
        method: httpRequestMethods.POST,
        body: { note },
        header: {
            version,
        },
        apiVersion: 'v2',
    },
});
