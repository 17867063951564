import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { createStyles, withStyles, WithStyles, Grid, Button, Typography, useTheme, useMediaQuery, Theme } from '@material-ui/core';
import SketchOrder from 'lib/Models/SketchOrder';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OptionsPopover from './OptionsPopover';
import UpdateOrderModal from './UpdateOrderModal';
import FormattedDate from 'lib/util/FormattedDate';
import OrderStatusChip from './OrderStatusChip';
import { DataRow } from 'components/DataTable/DataTable';
import { OrderTypes } from './OrderTypes';

interface MeasurementRowProps
  extends RouteComponentProps,
  WithStyles<typeof styles> {
  sketchOrder: SketchOrder;
  onCancelled: () => void;
}


const styles = (theme: Theme) => createStyles({
  columnContainer: {
    maxWidth: '90vw',
    padding: 0,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      padding: 10
    }
  },
  rowText: {
    color: '#000000DE',
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      padding: 4
    }
  },
})

const MeasurementRow = (props: MeasurementRowProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false);
  const [updatedSketchOrder, setUpdatedSketchOrder] = useState<SketchOrder>(
    props.sketchOrder
  );
  const { classes, sketchOrder } = props;

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setUpdatedSketchOrder(props.sketchOrder)
  }, [props.sketchOrder])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onOrderCanceled = (response: SketchOrder) => {
    setUpdatedSketchOrder(response);
    handleClose();
    props.onCancelled();
  };

  const handleModalClose = (response: SketchOrder) => {
    setUpdatedSketchOrder((prevSketchOrder) => ({
      ...prevSketchOrder,
      response,
    }));
    setUpdateModalOpen(false);
    handleClose();
  };

  

  return (
    <DataRow>
      <Grid container item className={classes.columnContainer} direction='row' xs={12}>
        <Grid container item xs={12} sm={11} alignContent='center'>
          <Grid container item xs={12} sm={3} alignContent='center' className={classes.rowText} style={mobile ? { color: '#999999' } : {}}>
            <FormattedDate date={updatedSketchOrder.createdAt} />
          </Grid>

          <Grid container item xs={12} sm={4} alignContent='center'>
            <Typography className={classes.rowText} noWrap={true} data-cy='order-address'>
              {!mobile ? updatedSketchOrder.address : updatedSketchOrder.address + ', ' + updatedSketchOrder.city + ' ' + updatedSketchOrder.region + ', ' + updatedSketchOrder.postcode}
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={2} alignContent='center'>
            <Typography className={classes.rowText} data-cy='order-cost'>
              ${updatedSketchOrder.cost ?? 0}.00
            </Typography>
          </Grid>
          {
            !mobile &&
            <Grid container item xs={12} sm={3} alignContent='center'>
              <OrderStatusChip
                sketchOrder={updatedSketchOrder}
              />
            </Grid>
          }
        </Grid>
        <Grid container item xs={mobile ? 12 : 1} direction='row' style={mobile ? { padding: 8 } : {}} justifyContent='space-between'>
          {
            mobile &&
            <Grid item xs={9} alignContent='center' >
              <OrderStatusChip
                sketchOrder={updatedSketchOrder}
              />
            </Grid>
          }
          <Grid container item xs={mobile ? 3 : 12} justifyContent={mobile ? 'flex-end' : 'center'} alignContent='center'>
            <Button onClick={handleClick} data-cy='options-button'>
              <MoreVertIcon />
            </Button>
          </Grid>
        </Grid>

        <OptionsPopover
          handleClose={handleClose}
          anchorEl={anchorEl}
          order={sketchOrder}
          orderType={OrderTypes.Sketch}
          setUpdateModalOpen={setUpdateModalOpen}
          onOrderCancelled={onOrderCanceled}
        />
        {'sketchOrderStatus' in sketchOrder &&
          <UpdateOrderModal
            open={updateModalOpen}
            onClose={handleModalClose}
            sketchOrder={sketchOrder}
          />
        }
      </Grid>
    </DataRow>
  );
};

export default withRouter(withStyles(styles)(MeasurementRow));
