export const ProjectImages: { [key: string]: string } = {
    EdgesV2: 'aerial_edges2.jpg',
    MetalRoof: 'drawing_metal_roof.jpg',
    Gutter: 'drawing_gutters.jpg',
};

export const ProjectImagesMapping: { [key: string]: string } = {
    halfsnap: ProjectImages.EdgesV2,
    fullsnap: ProjectImages.EdgesV2,
    metalroof: ProjectImages.MetalRoof,
    gutters: ProjectImages.Gutter,
};

export const DIYProjectImage = ProjectImages.EdgesV2;
