import React, { useEffect, useState } from 'react';
import { TabbedArea, TabbedAreaPanel } from 'components/TabbedArea';
import SettingsContainer from '../../SettingsComponents/SettingsContainer';
import MeasurementOrders from './MeasurementOrders';
import SubscriptionBilling from './SubscriptionBilling';
import NearmapBilling from './NearmapBilling';
import { useSelector } from 'react-redux';
import {
  useMediaQuery,
  useTheme,
  withStyles,
  WithStyles,
  createStyles,
} from '@material-ui/core';
import CustomTabLabel from './CustomTabLabel';

type PartialState = {
  currentUserRoles: { isAccountAdmin: boolean };
  currentUser: { organizationId: number; userId: number };
};

export type FailedTransactionsContainer = {
  measurementOrderIds: number[];
  nearmapTransactionIds: string[];
  isSubscriptionPastDue: boolean;
};

interface TransactionHistoryProps extends WithStyles<typeof styles> {
  sectionTitleStyle?: string | undefined;
  tabStyle?: string | undefined;
  onFailedTransactions: (
    failedTransactions: FailedTransactionsContainer
  ) => void;
  onTabChange?: (tabIndex: number) => void;
  failedTransactionsResolved: boolean
}

const styles = createStyles({
  badgeStyle: {
    backgroundColor: '#DB1F3C',
    font: 'Roboto',
    fontSize: '9px',
    fontWeight: 500,
    borderRadius: '9px',
    color: '#FFFFFF',
    padding: 3,
    minWidth: 17,
  },
});

const TransactionHistory = ({
  sectionTitleStyle,
  tabStyle,
  classes,
  onFailedTransactions,
  onTabChange,
  failedTransactionsResolved
}: TransactionHistoryProps) => {
  const {
    currentUserRoles: { isAccountAdmin },
  } = useSelector((state: PartialState) => state);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [failedMeasurementOrderIds, setFailedMeasurementOrderIds] = useState<
    number[]
  >([]);

  const [failedNearmapTransactionIds, setFailedNearmapTransactionIds] = useState<string[]>(
    []
  );

  const [subscriptionIsPastDue, setSubscriptionIsPastDue] = useState<boolean>(false);

  useEffect(() => {
    if (failedMeasurementOrderIds.length > 0 || failedNearmapTransactionIds.length > 0 || subscriptionIsPastDue) {
      onFailedTransactions({
        measurementOrderIds: failedMeasurementOrderIds,
        nearmapTransactionIds: failedNearmapTransactionIds,
        isSubscriptionPastDue: subscriptionIsPastDue,
      });
    }
  }, [failedMeasurementOrderIds, failedNearmapTransactionIds, subscriptionIsPastDue, onFailedTransactions]);

  useEffect(() => {
    setFailedMeasurementOrderIds([]);
    setFailedNearmapTransactionIds([]);
    setSubscriptionIsPastDue(false);
  }, [failedTransactionsResolved]);

  return (
    <SettingsContainer
      title='Recent Transactions'
      sectionTitleStyle={sectionTitleStyle}
    >
      <TabbedArea tabStyle={tabStyle} onTabChange={onTabChange}>
        {isAccountAdmin && (
          <TabbedAreaPanel
            label={
              <CustomTabLabel
                label='Subscription'
                badgeLabel={
                  subscriptionIsPastDue
                    ? '!'
                    : ''
                }
                badgePosition='flex-end'
                badgeStyle={classes.badgeStyle}
              />
            }
          >
            <SubscriptionBilling
              requestIsAccountPastDue={setSubscriptionIsPastDue}
            />
          </TabbedAreaPanel>
        )}
        <TabbedAreaPanel
          label={
            <CustomTabLabel
              label={mobile ? 'Orders' : 'Measurement Orders'}
              badgeLabel={
                failedMeasurementOrderIds.length > 0
                  ? failedMeasurementOrderIds.length.toString()
                  : ''
              }
              badgePosition='flex-end'
              badgeStyle={classes.badgeStyle}
            />
          }
        >
          <MeasurementOrders
            onReceivedFailedTransactions={setFailedMeasurementOrderIds}
            failedTransactionsResolved={failedTransactionsResolved}
          />
        </TabbedAreaPanel>
        <TabbedAreaPanel
          label={
            <CustomTabLabel
              label='Nearmap'
              badgeLabel={
                failedNearmapTransactionIds.length > 0
                  ? failedNearmapTransactionIds.length.toString()
                  : ''
              }
              badgePosition='flex-end'
              badgeStyle={classes.badgeStyle}
            />
          }
        >
          <NearmapBilling
            onReceivedFailedTransactions={setFailedNearmapTransactionIds}
            failedTransactionsResolved={failedTransactionsResolved}
          />
        </TabbedAreaPanel>
      </TabbedArea>
    </SettingsContainer>
  );
};

export default withStyles(styles)(TransactionHistory);
