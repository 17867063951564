import {
    GET_PROJECT_DRAWING_REQUEST,
    GET_PROJECT_DRAWING_SUCCESS,
    GET_PROJECT_DRAWING_FAILURE,
    CREATE_PROJECT_DRAWING_REQUEST,
    CREATE_PROJECT_DRAWING_SUCCESS,
    CREATE_PROJECT_DRAWING_FAILURE,
    UPDATE_PROJECT_DRAWING_REQUEST,
    UPDATE_PROJECT_DRAWING_SUCCESS,
    UPDATE_PROJECT_DRAWING_FAILURE,
} from '../actions/ProjectDrawingActions';

function projectDrawing(
    state = {
        isFetching: false,
        projectDrawing: {},
    },
    action,
) {
    switch (action.type) {
    case GET_PROJECT_DRAWING_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case GET_PROJECT_DRAWING_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            projectDrawing: action.response,
        });
    case GET_PROJECT_DRAWING_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            projectDrawing: {},
            errorMessage: action.message,
        });
    case CREATE_PROJECT_DRAWING_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case CREATE_PROJECT_DRAWING_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case CREATE_PROJECT_DRAWING_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case UPDATE_PROJECT_DRAWING_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case UPDATE_PROJECT_DRAWING_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case UPDATE_PROJECT_DRAWING_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
        });
    default:
        return state;
    }
}

export default projectDrawing;
