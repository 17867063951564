import flatten from 'lodash/flatten';
import RoofSnapApiClient from '../middleware/roofSnapApiClient';
import httpRequestMethods from '../middleware/httpRequestMethods';
import httpStatusCodes from '../middleware/httpStatusCodes';
import { createProjectDocument } from '../lib/Factories/projectDocumentFactory';

export const GET_DOCUMENTS_REQUEST = 'GET_DOCUMENTS_REQUEST';
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';
export const GET_DOCUMENTS_FAILURE = 'GET_DOCUMENTS_FAILURE';

const getDocumentsRequest = () => ({
    type: GET_DOCUMENTS_REQUEST,
});

const getDocumentsSuccess = (documents, totalResults) => ({
    type: GET_DOCUMENTS_SUCCESS,
    documents,
    totalResults,
});

const getDocumentsFailure = () => ({
    type: GET_DOCUMENTS_FAILURE,
});

const massageDocuments = (documents) => {
    const documentsWithRenderings = documents.map(document => document.renderings.resourceList.map(rendering => createProjectDocument(document, rendering)));

    return flatten(documentsWithRenderings);
};

export const getDocuments = projectId => async (dispatch) => {
    dispatch(getDocumentsRequest());
    let documents = [];

    try {
        const documentsClient = new RoofSnapApiClient();
        const documentsResponse = await documentsClient.callApi({
            endpoint: `projects/${projectId}/v2.1/documents`,
            method: httpRequestMethods.GET,
            params: {
                page: 1,
            },
            getAllPages: true,
        });

        const { resourceList: v2ResourceList } = documentsResponse;
        documents = massageDocuments(v2ResourceList);
    } catch (error) {
        if (error.status !== httpStatusCodes.NotFound) {
            return dispatch(getDocumentsFailure());
        }
    }

    return dispatch(getDocumentsSuccess(documents, documents.length));
};
