import React from 'react';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import { Chip, useTheme, useMediaQuery } from '@mui/material';
import { amber } from '@mui/material/colors';

type PendingSketchOrdersChipProps = {
    inProgressSketchOrders: number;
    onClick: () => void;
}

const PendingSketchOrdersChip = ({ inProgressSketchOrders, onClick }: PendingSketchOrdersChipProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const composeLabel = () => {
        if (isMobile) return 'Orders';

        return `${inProgressSketchOrders} ${
            inProgressSketchOrders > 1 ? 'Orders' : 'Order'
        } In Progress`;
    };

    return (
        <Chip
            label={composeLabel()}
            icon={<TimelapseIcon style={{ fill: '#ffffff' }} />}
            style={{
                color: '#ffffff',
                backgroundColor: amber[500],
            }}
            onClick={onClick}
        />
    );
};

export default PendingSketchOrdersChip;
