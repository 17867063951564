import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ForbiddenDialog from './ForbiddenDialog';
import { logoutUser } from '../../actions/Auth';

const SketchTechNoAccessForbiddenDialog = ({ dispatch }) => {
    const logOut = () => {
        dispatch(logoutUser());
    };

    return (<ForbiddenDialog
        confirmationAction={logOut}
        forbiddenDialogTitle="No Access"
        forbiddenDialogContentText="You do not have access to this feature."
    />);
};

SketchTechNoAccessForbiddenDialog.propTypes = {
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default connect(null)(SketchTechNoAccessForbiddenDialog);
