import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { grey } from '@material-ui/core/colors';
import PriorityLevelIcon from './PriorityLevelIcon';

const styles = theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    description: {
        fontSize: 12,
        color: grey[600],
        maxHeight: 32,
        overflow: 'hidden',
    },
});

const NotificationListItem = props => (
    <ListItem button onClick={e => props.onClick(e, props.id)}>
        <Grid container>
            <Grid item>
                <PriorityLevelIcon
                    acknowledged={props.acknowledged}
                    priorityLevel={props.priorityLevel}
                    className={props.classes.icon}
                />
            </Grid>
            <Grid item xs>
                <Typography
                    style={{
                        textDecoration: props.acknowledged ? 'line-through' : 'inherit',
                    }}
                    className={props.classes.description}
                >
                    {props.description}
                </Typography>
            </Grid>
        </Grid>
    </ListItem>
);

NotificationListItem.propTypes = {
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    priorityLevel: PropTypes.oneOf(['Critical', 'Warning', 'Info', 'None']).isRequired,
    acknowledged: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    classes: PropTypes.shape({
        icon: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
};

export default withStyles(styles)(NotificationListItem);
