import { CALL_API } from '../middleware/api';
import httpRequestMethods from '../middleware/httpRequestMethods';
import LocalStorageWrapper from '../lib/LocalStorageWrapper';
import decode from '../lib/decodeRoofSnapAuthToken';

export const GET_CURRENT_USER_REQUEST = 'GET_CURRENT_USER_REQUEST';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILURE = 'GET_CURRENT_USER_FAILURE';

export const GET_CURRENT_USER_REQUEST_BACKGROUND = 'GET_CURRENT_USER_REQUEST_BACKGROUND';
export const GET_CURRENT_USER_SUCCESS_BACKGROUND = 'GET_CURRENT_USER_SUCCESS_BACKGROUND';
export const GET_CURRENT_USER_FAILURE_BACKGROUND = 'GET_CURRENT_USER_FAILURE_BACKGROUND';

export const getCurrentUser = (requestInBackground = false) => {
    const accessToken = LocalStorageWrapper.getItem('accessToken');
    const { userId } = decode(accessToken);

    if (!userId) {
        return {
            type: GET_CURRENT_USER_FAILURE,
        };
    }

    const requestTypes = [GET_CURRENT_USER_REQUEST, GET_CURRENT_USER_SUCCESS, GET_CURRENT_USER_FAILURE];
    const backgroundRequestTypes = [GET_CURRENT_USER_REQUEST_BACKGROUND, GET_CURRENT_USER_SUCCESS_BACKGROUND, GET_CURRENT_USER_FAILURE_BACKGROUND];

    return {
        [CALL_API]: {
            types: requestInBackground ? backgroundRequestTypes : requestTypes,
            endpoint: `userprofiles/${userId}`,
        },
    };
};

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const getUser = userId => ({
    [CALL_API]: {
        types: [GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_FAILURE],
        endpoint: `userprofiles/${userId}`,
    },
});

export const CREATE_NEW_USER_SUCCESS = 'CREATE_NEW_USER_SUCCESS';
export const CREATE_NEW_USER_FAILURE = 'CREATE_NEW_USER_FAILURE';
export const CREATE_NEW_USER_REQUEST = 'CREATE_NEW_USER_REQUEST';

/**
 * Create a new user.
 * @param {Object} newUser - The user being created.
 * @param {string} newUser.firstName - The first name of the user.
 * @param {string} newUser.lastName - The last name of the user.
 * @param {string} newUser.email - The email of the user.
 * @param {string} newUser.companyName - The company/business name of the user
 * @param {string} newUser.phone - The phone number of the user
 * @param {string} newUser.country - The phone number of the user
 */
export const createNewUser = newUser => ({
    [CALL_API]: {
        method: httpRequestMethods.POST,
        types: [CREATE_NEW_USER_REQUEST, CREATE_NEW_USER_SUCCESS, CREATE_NEW_USER_FAILURE],
        endpoint: 'signup',
        apiVersion: 'v3',
        body: {
            ...newUser,
        },
        authRequired: false,
    },
});

export const COMPLETE_NEW_USER_SUCCESS = 'COMPLETE_NEW_USER_SUCCESS';
export const COMPLETE_NEW_USER_FAILURE = 'COMPLETE_NEW_USER_FAILURE';
export const COMPLETE_NEW_USER_REQUEST = 'COMPLETE_NEW_USER_REQUEST';

export const completeNewUser = (body, userId) => ({
    [CALL_API]: {
        method: httpRequestMethods.POST,
        types: [COMPLETE_NEW_USER_REQUEST, COMPLETE_NEW_USER_SUCCESS, COMPLETE_NEW_USER_FAILURE],
        endpoint: `signup/complete?userId=${userId}`,
        body,
    },
});

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const changePassword = (userId, newPassword) => ({
    [CALL_API]: {
        method: httpRequestMethods.PUT,
        types: [CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE],
        endpoint: `userprofiles/${userId}/password`,
        body: {
            newPassword,
        },
    },
});
