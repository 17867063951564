import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import DescriptionIcon from '@material-ui/icons/Description';
import LockableElement from '../LockableElement';

const documentTemplateWidth = 155;
const documentTemplateHeight = 200;

const style = theme => ({
    root: {
        width: documentTemplateWidth,
        transition: 'all 0.30s ease-in-out',
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(6),
        float: 'left',
    },
    container: {
        position: 'relative',
        border: '1px solid #bababa',
        overflow: 'hidden',
        cursor: 'pointer',
        background: '#eaeaea',
        width: '100%',
        maxWidth: documentTemplateWidth,
        height: documentTemplateHeight,
        marginBottom: theme.spacing(1),
    },
    thumbnail: {
        width: '100%',
        maxWidth: documentTemplateWidth,
        height: '100%',
        position: 'absolute',
    },
    defaultThumbnail: {
        width: '100%',
        maxWidth: 75,
        display: 'block',
        margin: 'auto',
        color: '#bababa',
        height: '100%',
    },
});

const DocumentTemplate = ({
    classes,
    thumbnail,
    title,
    isActive,
    onClick,
    disabled,
    id,
}) => (
    <div
        className={classNames(classes.root)}
    >
        <LockableElement
            locked={disabled}
            grayedOutBoxStyleWithOverrides={{
                left: 1,
                minWidth: documentTemplateWidth,
                backgroundColor: 'none',
                borderRadius: 0,
            }}
            onClick={onClick}
        >
            <div
                className={classNames(
                    classes.container,
                    isActive ? classes.active : '',
                )}
            >
                {
                    thumbnail ? (
                        <img src={thumbnail} alt="" className={classes.thumbnail} id={id} />
                    ) : (
                        <DescriptionIcon className={classes.defaultThumbnail} />
                    )
                }
            </div>
        </LockableElement>
        <Typography
            variant="subtitle2"
        >
            {title}
        </Typography>
    </div>
);

DocumentTemplate.propTypes = {
    thumbnail: PropTypes.string,
    isActive: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

DocumentTemplate.defaultProps = {
    thumbnail: null,
    isActive: false,
    disabled: false,
};

export default withStyles(style)(DocumentTemplate);
