import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { downloadBlobAsFile } from '../lib/HelperFunctions';
import { exportProjectAsCsv } from '../actions/ProjectActions';
import { useDispatch } from 'react-redux';
import { DownloadIcon } from '@ui/Icons';

type DownloadProjectCsvIconButtonProps = {
    projectName: string;
    projectId: string;
};

const DownloadProjectCsvIconButton = (props: DownloadProjectCsvIconButtonProps) => {
    const dispatch = useDispatch();
    let safeFilename: string = 'default';
    if (props.projectName !== undefined) {
        // strip out forbidden characters from projectName for safe file download name
        safeFilename = props.projectName.replace(/[/\\?%*:|"<>]/g, '-');
    }
    const exportProject = async () => {
        const result: { response: Blob; type: string } = await (dispatch(
            exportProjectAsCsv(props.projectId)
        ) as any);
        const { response: blob } = result;
        downloadBlobAsFile(blob, `${safeFilename}.csv`);
    };

    return (
        <Tooltip title='Export as CSV'>
            <IconButton onClick={exportProject}>
                <DownloadIcon style={{ color: 'rbga(0,0,0,.53)' }} />
            </IconButton>
        </Tooltip>
    );
};

export default DownloadProjectCsvIconButton;
