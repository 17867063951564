import React from 'react';
import { Icon, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        imageIcon: {
            display: 'flex',
            height: 'inherit',
            width: 'inherit',
        },
        iconRoot: {
            textAlign: 'center',
        },
    })
);

const StandardIcon = () => {
    const classes = useStyles();

    return (
        <Icon classes={{ root: classes.iconRoot }}>
            <img src='/assets/StandardIcon.svg' alt='Standard' />
        </Icon>
    );
};

export default StandardIcon;
