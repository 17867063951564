import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import NativeSelect from '@material-ui/core/NativeSelect';
import { isValidEmail } from '../../lib/HelperFunctions';
import { signUp } from '../../actions/signUpActions';
import { ReactComponent as RoofSnapLogo } from './RoofSnapLogo2TonedSmall.svg';
import LoggerWrapper from '../../lib/Logger';

const style = theme => ({
    wrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        zIndex: theme.zIndex.appBar + 1,
        backgroundColor: theme.palette.primary.main,
        position: 'fixed',
        overflowY: 'auto',
        top: 0,
    },
    card: {
        padding: theme.spacing(2),
        margin: `${theme.spacing(2)}px auto`,
        [theme.breakpoints.up('md')]: {
            maxWidth: 1024,
        },
        [theme.breakpoints.down('sm')]: {
            margin: 'auto',
            borderRadius: 0,
        },
    },
    roofsnapLogoWrapper: {
        width: 150,
        margin: 'auto',
    },
    roofsnapLogo: {
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    form: {
        marginTop: theme.spacing(1),
    },
    registerButton: {
        margin: `${theme.spacing(2)}px 0`,
    },
    inputLabel: {
        backgroundColor: theme.palette.white,
        paddingLeft: 2,
        paddingRight: 2,
    },
    infoSection: {
        marginTop: theme.spacing(3),
    },
    infoSectionText: {
        margin: `${theme.spacing(2)}px 0`,
    },
    cardAvatars: {
        borderRadius: 0,
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    openingText: {
        marginBottom: theme.spacing(3),
    },
    titleText: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem',
        },
    },
    testimonialTop: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    testimonialBottom: {
        marginTop: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    hiddenForm: {
        display: 'none',
    },
    sectionTitle: {
        paddingBottom: theme.spacing(2),
        minHeight: theme.spacing(9),
    },
});

class RegisterNew extends Component {
    constructor(props) {
        super(props);
        this.register = this.register.bind(this);

        const queryParams = queryString.parse(props.location.search);
        this.state = {
            firstName: queryParams.firstName,
            lastName: queryParams.lastName,
            email: queryParams.email,
            phone: queryParams.phone,
            companyName: queryParams.companyname || queryParams.companyName,
            country: queryParams.country || 'United States',
            firstNameIsValid: true,
            lastNameIsValid: true,
            emailIsValid: true,
            phoneIsValid: true,
            companyNameIsValid: true,
            countryIsValid: true,
            eulaCheckedIsValid: true,
            eulaChecked: false,
            optInCommunications: false,
            password: '',
            passwordIsValid: true,
        };
    }

    componentDidMount() {
        const ecScript = document.createElement('script');
        ecScript.id = 'ecScript';
        ecScript.src = 'https://evercommercemarketing.s3.amazonaws.com/ec.min.js';
        ecScript.async = true;
        document.body.appendChild(ecScript);

        const marketoScript = document.createElement('script');
        marketoScript.id = 'marketoScript';
        marketoScript.src = 'https://app-sj32.marketo.com/js/forms2/js/forms2.min.js';
        marketoScript.async = true;
        document.body.appendChild(marketoScript);
    }

    componentWillUnmount() {
        const ecScript = document.getElementById('ecScript');
        document.body.removeChild(ecScript);
        const marketoScript = document.getElementById('marketoScript');
        document.body.removeChild(marketoScript);
    }

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            [`${event.target.name}IsValid`]: true,
        });
    }

    handleCheckBoxChanged = (event) => {
        this.setState({
            [event.target.name]: event.target.checked,
        });
    }

    register = async () => {
        const firstNameIsValid = (this.state.firstName);
        const lastNameIsValid = (this.state.lastName);
        const emailIsValid = (this.state.email) && isValidEmail(this.state.email);
        const phoneIsValid = (this.state.phone);
        const companyNameIsValid = (this.state.companyName);
        const countryIsValid = (this.state.country);
        const eulaCheckedIsValid = (this.state.eulaChecked);
        const passwordIsValid = (this.state.password);

        this.setState({
            firstNameIsValid,
            lastNameIsValid,
            emailIsValid,
            phoneIsValid,
            companyNameIsValid,
            countryIsValid,
            eulaCheckedIsValid,
            passwordIsValid,
        });

        if (!firstNameIsValid ||
            !lastNameIsValid ||
            !emailIsValid ||
            !phoneIsValid ||
            !companyNameIsValid ||
            !countryIsValid ||
            !eulaCheckedIsValid ||
            !passwordIsValid) {
            return;
        }

        try {
            if (process.env.REACT_APP_SYNC_MARKETO) { this.sendMarketoForm(); }

            this.props.dispatch(signUp({
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                companyName: this.state.companyName,
                phone: this.state.phone,
                country: this.state.country,
                password: this.state.password,
            }));
        } catch (error) {
            LoggerWrapper.log(error);
        }

        this.props.history.push('/welcome');
    };

    handleEnterKeyPressed = (event) => {
        if (event.keyCode === 13) {
            this.register();
        }
    };

    sendMarketoForm = () => {
        // eslint-disable-next-line no-undef
        const firstTouchVals = $EC.getFirst();
        // eslint-disable-next-line no-undef
        const lastTouchVals = $EC.getLast();
        const attributionFields = {
            ec_utm_campaign_first__c: firstTouchVals.utm_campaign || 'NULL',
            ec_utm_source_first__c: firstTouchVals.utm_source || 'NULL',
            ec_utm_medium_first__c: firstTouchVals.utm_medium || 'NULL',
            ec_utm_content_first__c: firstTouchVals.utm_content || 'NULL',
            ec_utm_term_first__c: firstTouchVals.utm_term || 'NULL',
            ec_utm_device_first__c: firstTouchVals.utm_device || 'NULL',
            ec_msid_first__c: firstTouchVals.msid || 'NULL',
            ec_referrer_first__c: firstTouchVals.referrer || 'NULL',
            gclid__c: firstTouchVals.gclid || 'NULL',
            ec_utm_campaign_last__c: lastTouchVals.utm_campaign || 'NULL',
            ec_utm_source_last__c: lastTouchVals.utm_source || 'NULL',
            ec_utm_medium_last__c: lastTouchVals.utm_medium || 'NULL',
            ec_utm_content_last__c: lastTouchVals.utm_content || 'NULL',
            ec_utm_term_last__c: lastTouchVals.utm_term || 'NULL',
            ec_utm_device_last__c: lastTouchVals.utm_device || 'NULL',
            ec_msid_last__c: lastTouchVals.msid || 'NULL',
            ec_referrer_last__c: lastTouchVals.referrer || 'NULL',
            gclid_last__c: lastTouchVals.gclid || 'NULL',
        };
        // eslint-disable-next-line no-undef
        MktoForms2.loadForm('https://app-sj32.marketo.com', '714-HWY-368', 1068, (form) => {
            form.addHiddenFields(attributionFields);
        });
        const {
            firstName, lastName, email, phone, companyName, country, optInCommunications,
        } = this.state;
        const data = {
            FirstName: firstName,
            LastName: lastName,
            Email: email,
            Company: companyName,
            Country: country,
            Phone: phone,
            Explicit_Opt_In__c: optInCommunications ? 'yes' : 'no',
        };
        // eslint-disable-next-line no-undef
        MktoForms2.whenReady((form) => {
            form.vals(data);
            form.submit();
            form.onSuccess(() => false);
        });
    };

    render() {
        if (!this.props.features.useNewSignupFlow && window.location.href.includes('signup')) {
            window.location.href = `${process.env.REACT_APP_GET_STARTED_URL}`;
            return null;
        }

        return (
            <Paper className={this.props.classes.wrapper}>
                <form id="mktoForm_1068" className={this.props.classes.hiddenForm} />
                <Card className={this.props.classes.card} id="registerForm">
                    <CardContent>
                        <div className={this.props.classes.roofsnapLogoWrapper}>
                            <RoofSnapLogo className={this.props.classes.roofsnapLogo} />
                        </div>
                        <Grid container spacing={2} justify="center" className={this.props.classes.form}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h4" className={this.props.classes.titleText}>
                                    Take Our Software for a Spin
                                </Typography>
                                <Typography variant="subtitle1" className={this.props.classes.openingText} paragraph>
                                    Ready for roofing made easy? Then you’re ready for RoofSnap!
                                </Typography>
                                <Typography variant="subtitle1" className={this.props.classes.openingText} paragraph>
                                    Optimize your measurements, estimates, contracts and more. Experience how thousands of contractors have sped up their sales process and brought fast, affordable measurements to their company.
                                </Typography>
                                <Typography variant="h5" className={this.props.classes.openingText}>
                                    Activate a {this.props.features.freeTrialDurationInDays && `${this.props.features.freeTrialDurationInDays}-day `}Free Trial Today!
                                </Typography>
                                <Grid container spacing={4} item xs={12} className={this.props.classes.testimonialTop}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            “RoofSnap is like having an extra estimator. It&apos;s one of the best programs I&apos;ve come across in my 30+ years in the roofing business.” - Mason N.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} item xs={12} md={6}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        name="firstName"
                                        id="firstName"
                                        label="First Name"
                                        placeholder="Joe"
                                        helperText={(!this.state.firstNameIsValid) ? 'First Name is required' : ''}
                                        variant="outlined"
                                        fullWidth
                                        onChange={this.handleInputChange}
                                        value={this.state.firstName}
                                        error={!this.state.firstNameIsValid}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        name="lastName"
                                        id="lastName"
                                        label="Last Name"
                                        placeholder="Smith"
                                        helperText={(!this.state.lastNameIsValid) ? 'Last Name is required' : ''}
                                        variant="outlined"
                                        fullWidth
                                        onChange={this.handleInputChange}
                                        value={this.state.lastName}
                                        error={!this.state.lastNameIsValid}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        name="companyName"
                                        id="companyName"
                                        label="Company Name"
                                        placeholder="Joe's Roofing"
                                        helperText={(!this.state.companyNameIsValid) ? 'Company Name is required' : ''}
                                        variant="outlined"
                                        fullWidth
                                        onChange={this.handleInputChange}
                                        value={this.state.companyName}
                                        error={!this.state.companyNameIsValid}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        name="phone"
                                        id="phone"
                                        label="Phone"
                                        placeholder="+1 123-456-7890"
                                        helperText={(!this.state.phoneIsValid) ? 'Phone is required' : ''}
                                        variant="outlined"
                                        fullWidth
                                        onChange={this.handleInputChange}
                                        value={this.state.phone}
                                        error={!this.state.phoneIsValid}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="Email"
                                        type="email"
                                        placeholder="joe@sample.com"
                                        helperText={(!this.state.emailIsValid) ? 'Email is required' : ''}
                                        variant="outlined"
                                        fullWidth
                                        onChange={this.handleInputChange}
                                        value={this.state.email}
                                        error={!this.state.emailIsValid}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        fullWidth
                                        variant="outlined"
                                        error={!this.state.countryIsValid}
                                    >
                                        <InputLabel shrink htmlFor="country">Country</InputLabel>
                                        <NativeSelect
                                            value={this.state.country}
                                            onChange={this.handleInputChange}
                                            labelWidth={55}
                                            input={
                                                <OutlinedInput
                                                    name="country"
                                                    id="country"
                                                    label="Country"
                                                />
                                            }
                                        >
                                            <option value="United States">United States</option>
                                            <option value="Canada">Canada</option>
                                            <option value="Australia">Australia</option>
                                            <option value="Åland Islands">Åland Islands</option>
                                            <option value="Albania">Albania</option>
                                            <option value="Algeria">Algeria</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Angola">Angola</option>
                                            <option value="Anguilla">Anguilla</option>
                                            <option value="Antarctica">Antarctica</option>
                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                            <option value="Argentina">Argentina</option>
                                            <option value="Armenia">Armenia</option>
                                            <option value="Aruba">Aruba</option>
                                            <option value="Austria">Austria</option>
                                            <option value="Azerbaijan">Azerbaijan</option>
                                            <option value="Bahamas">Bahamas</option>
                                            <option value="Bahrain">Bahrain</option>
                                            <option value="Bangladesh">Bangladesh</option>
                                            <option value="Barbados">Barbados</option>
                                            <option value="Belarus">Belarus</option>
                                            <option value="Belgium">Belgium</option>
                                            <option value="Belize">Belize</option>
                                            <option value="Benin">Benin</option>
                                            <option value="Bermuda">Bermuda</option>
                                            <option value="Bhutan">Bhutan</option>
                                            <option value="Bolivia">Bolivia</option>
                                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                            <option value="Botswana">Botswana</option>
                                            <option value="Bouvet Island">Bouvet Island</option>
                                            <option value="Brazil">Brazil</option>
                                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                                            <option value="Bulgaria">Bulgaria</option>
                                            <option value="Burkina Faso">Burkina Faso</option>
                                            <option value="Burundi">Burundi</option>
                                            <option value="Cambodia">Cambodia</option>
                                            <option value="Cameroon">Cameroon</option>
                                            <option value="Cape Verde">Cape Verde</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Central African Republic">Central African Republic</option>
                                            <option value="Chad">Chad</option>
                                            <option value="Chile">Chile</option>
                                            <option value="China">China</option>
                                            <option value="Christmas Island">Christmas Island</option>
                                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                            <option value="Colombia">Colombia</option>
                                            <option value="Comoros">Comoros</option>
                                            <option value="Congo">Congo</option>
                                            <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                                            <option value="Cook Islands">Cook Islands</option>
                                            <option value="Costa Rica">Costa Rica</option>
                                            <option value="Cote D'ivoire">Cote D&#39;ivoire</option>
                                            <option value="Croatia">Croatia</option>
                                            <option value="Cuba">Cuba</option>
                                            <option value="Cyprus">Cyprus</option>
                                            <option value="Czech Republic">Czech Republic</option>
                                            <option value="Denmark">Denmark</option>
                                            <option value="Djibouti">Djibouti</option>
                                            <option value="Dominica">Dominica</option>
                                            <option value="Dominican Republic">Dominican Republic</option>
                                            <option value="Ecuador">Ecuador</option>
                                            <option value="Egypt">Egypt</option>
                                            <option value="El Salvador">El Salvador</option>
                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                            <option value="Eritrea">Eritrea</option>
                                            <option value="Estonia">Estonia</option>
                                            <option value="Ethiopia">Ethiopia</option>
                                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                            <option value="Faroe Islands">Faroe Islands</option>
                                            <option value="Fiji">Fiji</option>
                                            <option value="Finland">Finland</option>
                                            <option value="France">France</option>
                                            <option value="French Guiana">French Guiana</option>
                                            <option value="French Polynesia">French Polynesia</option>
                                            <option value="French Southern Territories">French Southern Territories</option>
                                            <option value="Gabon">Gabon</option>
                                            <option value="Gambia">Gambia</option>
                                            <option value="Georgia">Georgia</option>
                                            <option value="Germany">Germany</option>
                                            <option value="Ghana">Ghana</option>
                                            <option value="Gibraltar">Gibraltar</option>
                                            <option value="Greece">Greece</option>
                                            <option value="Greenland">Greenland</option>
                                            <option value="Grenada">Grenada</option>
                                            <option value="Guadeloupe">Guadeloupe</option>
                                            <option value="Guam">Guam</option>
                                            <option value="Guatemala">Guatemala</option>
                                            <option value="Guernsey">Guernsey</option>
                                            <option value="Guinea">Guinea</option>
                                            <option value="Guinea-bissau">Guinea-bissau</option>
                                            <option value="Guyana">Guyana</option>
                                            <option value="Haiti">Haiti</option>
                                            <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                            <option value="Honduras">Honduras</option>
                                            <option value="Hong Kong">Hong Kong</option>
                                            <option value="Hungary">Hungary</option>
                                            <option value="Iceland">Iceland</option>
                                            <option value="India">India</option>
                                            <option value="Indonesia">Indonesia</option>
                                            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                            <option value="Iraq">Iraq</option>
                                            <option value="Ireland">Ireland</option>
                                            <option value="Isle of Man">Isle of Man</option>
                                            <option value="Israel">Israel</option>
                                            <option value="Italy">Italy</option>
                                            <option value="Jamaica">Jamaica</option>
                                            <option value="Japan">Japan</option>
                                            <option value="Jersey">Jersey</option>
                                            <option value="Jordan">Jordan</option>
                                            <option value="Kazakhstan">Kazakhstan</option>
                                            <option value="Kenya">Kenya</option>
                                            <option value="Kiribati">Kiribati</option>
                                            <option value="Korea, Democratic People's Republic of">Korea, Democratic People&#39;s Republic of</option>
                                            <option value="Korea, Republic of">Korea, Republic of</option>
                                            <option value="Kuwait">Kuwait</option>
                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                            <option value="Lao People's Democratic Republic">Lao People&#39;s Democratic Republic</option>
                                            <option value="Latvia">Latvia</option>
                                            <option value="Lebanon">Lebanon</option>
                                            <option value="Lesotho">Lesotho</option>
                                            <option value="Liberia">Liberia</option>
                                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                            <option value="Liechtenstein">Liechtenstein</option>
                                            <option value="Lithuania">Lithuania</option>
                                            <option value="Luxembourg">Luxembourg</option>
                                            <option value="Macao">Macao</option>
                                            <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                            <option value="Madagascar">Madagascar</option>
                                            <option value="Malawi">Malawi</option>
                                            <option value="Malaysia">Malaysia</option>
                                            <option value="Maldives">Maldives</option>
                                            <option value="Mali">Mali</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Marshall Islands">Marshall Islands</option>
                                            <option value="Martinique">Martinique</option>
                                            <option value="Mauritania">Mauritania</option>
                                            <option value="Mauritius">Mauritius</option>
                                            <option value="Mayotte">Mayotte</option>
                                            <option value="Mexico">Mexico</option>
                                            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                                            <option value="Monaco">Monaco</option>
                                            <option value="Mongolia">Mongolia</option>
                                            <option value="Montenegro">Montenegro</option>
                                            <option value="Montserrat">Montserrat</option>
                                            <option value="Morocco">Morocco</option>
                                            <option value="Mozambique">Mozambique</option>
                                            <option value="Myanmar">Myanmar</option>
                                            <option value="Namibia">Namibia</option>
                                            <option value="Nauru">Nauru</option>
                                            <option value="Nepal">Nepal</option>
                                            <option value="Netherlands">Netherlands</option>
                                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                                            <option value="New Caledonia">New Caledonia</option>
                                            <option value="New Zealand">New Zealand</option>
                                            <option value="Nicaragua">Nicaragua</option>
                                            <option value="Niger">Niger</option>
                                            <option value="Nigeria">Nigeria</option>
                                            <option value="Niue">Niue</option>
                                            <option value="Norfolk Island">Norfolk Island</option>
                                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                            <option value="Norway">Norway</option>
                                            <option value="Oman">Oman</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Palau">Palau</option>
                                            <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                            <option value="Panama">Panama</option>
                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                            <option value="Paraguay">Paraguay</option>
                                            <option value="Peru">Peru</option>
                                            <option value="Philippines">Philippines</option>
                                            <option value="Pitcairn">Pitcairn</option>
                                            <option value="Poland">Poland</option>
                                            <option value="Portugal">Portugal</option>
                                            <option value="Puerto Rico">Puerto Rico</option>
                                            <option value="Qatar">Qatar</option>
                                            <option value="Reunion">Reunion</option>
                                            <option value="Romania">Romania</option>
                                            <option value="Russian Federation">Russian Federation</option>
                                            <option value="Rwanda">Rwanda</option>
                                            <option value="Saint Helena">Saint Helena</option>
                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                            <option value="Saint Lucia">Saint Lucia</option>
                                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                            <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                                            <option value="Samoa">Samoa</option>
                                            <option value="San Marino">San Marino</option>
                                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                            <option value="Senegal">Senegal</option>
                                            <option value="Serbia">Serbia</option>
                                            <option value="Seychelles">Seychelles</option>
                                            <option value="Sierra Leone">Sierra Leone</option>
                                            <option value="Singapore">Singapore</option>
                                            <option value="Slovakia">Slovakia</option>
                                            <option value="Slovenia">Slovenia</option>
                                            <option value="Solomon Islands">Solomon Islands</option>
                                            <option value="Somalia">Somalia</option>
                                            <option value="South Africa">South Africa</option>
                                            <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                                            <option value="Spain">Spain</option>
                                            <option value="Sri Lanka">Sri Lanka</option>
                                            <option value="Sudan">Sudan</option>
                                            <option value="Suriname">Suriname</option>
                                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                            <option value="Swaziland">Swaziland</option>
                                            <option value="Sweden">Sweden</option>
                                            <option value="Switzerland">Switzerland</option>
                                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                                            <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                                            <option value="Tajikistan">Tajikistan</option>
                                            <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                            <option value="Thailand">Thailand</option>
                                            <option value="Timor-leste">Timor-leste</option>
                                            <option value="Togo">Togo</option>
                                            <option value="Tokelau">Tokelau</option>
                                            <option value="Tonga">Tonga</option>
                                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                            <option value="Tunisia">Tunisia</option>
                                            <option value="Turkey">Turkey</option>
                                            <option value="Turkmenistan">Turkmenistan</option>
                                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                            <option value="Tuvalu">Tuvalu</option>
                                            <option value="Uganda">Uganda</option>
                                            <option value="Ukraine">Ukraine</option>
                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="United States">United States</option>
                                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                            <option value="Uruguay">Uruguay</option>
                                            <option value="Uzbekistan">Uzbekistan</option>
                                            <option value="Vanuatu">Vanuatu</option>
                                            <option value="Venezuela">Venezuela</option>
                                            <option value="Viet Nam">Viet Nam</option>
                                            <option value="Virgin Islands, British">Virgin Islands, British</option>
                                            <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                                            <option value="Western Sahara">Western Sahara</option>
                                            <option value="Yemen">Yemen</option>
                                            <option value="Zambia">Zambia</option>
                                            <option value="Zimbabwe">Zimbabwe</option>
                                        </NativeSelect>
                                        {
                                            !this.state.countryIsValid &&
                                            <FormHelperText id="name">Country is required</FormHelperText>
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        id="password"
                                        label="Create a password"
                                        type="password"
                                        variant="outlined"
                                        name="password"
                                        helperText={(!this.state.passwordIsValid) ? 'Password is required' : ''}
                                        fullWidth
                                        onChange={this.handleInputChange}
                                        value={this.state.password}
                                        error={!this.state.passwordIsValid}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormGroup
                                        row
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    id="tosCheckBox"
                                                    name="eulaChecked"
                                                    checked={this.state.eulaChecked}
                                                    onClick={this.handleCheckBoxChanged}
                                                    value={this.state.eulaChecked}
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <Typography>
                                                    By checking this box, you are agreeing to our&nbsp;
                                                    <a href={`${process.env.REACT_APP_ROOFSNAP_ONLINE_HOST}/Home/TermsAndConditions`} target="_blank" rel="noopener noreferrer">
                                                        End User License Agreement.
                                                    </a> (required)
                                                </Typography>
                                            }
                                        />
                                        {
                                            !this.state.eulaCheckedIsValid &&
                                            <FormHelperText error id="tos-helper-text">
                                                You must agree to the End User License Agreement to complete registration.
                                            </FormHelperText>
                                        }
                                    </FormGroup>
                                    <FormGroup
                                        row
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    id="optInCommunications"
                                                    name="optInCommunications"
                                                    checked={this.state.optInCommunications}
                                                    onClick={this.handleCheckBoxChanged}
                                                    value={this.state.optInCommunications}
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <Typography>
                                                    Opt-In to receive general communications and product updates. (optional)
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        id="register"
                                        variant="contained"
                                        color="primary"
                                        className={this.props.classes.registerButton}
                                        onClick={this.register}
                                        fullWidth
                                    >
                                        Start Trial
                                    </Button>
                                    <Typography align="center" variant="subtitle1">
                                        Already have an account? <Link to="/login" href="/login">Log In</Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} item xs={12} className={this.props.classes.infoSection}>
                                <Grid item xs={12} md={4}>
                                    <Avatar variant="square" src="/assets/blueprinticon.png" className={this.props.classes.cardAvatars} />
                                    <Typography variant="body1" className={this.props.classes.infoSectionText}>
                                        Lightning Fast Measurements
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary" className={this.props.classes.infoSectionText}>
                                        Measure for yourself with instant results or request measurement reports from our Sketch Ordering Service for fast-turnaround at affordable prices.
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary" className={this.props.classes.infoSectionText}>
                                        However you get your measurements, RoofSnap integrates them with your estimates, reports, and contracts!
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Avatar variant="square" src="/assets/search.png" className={this.props.classes.cardAvatars} />
                                    <Typography variant="body1" className={this.props.classes.infoSectionText}>
                                        Detailed Documents
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary" className={this.props.classes.infoSectionText}>
                                        Accurate measurement reports, clean material orders, labor and profit summaries; all available instantly.
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary" className={this.props.classes.infoSectionText}>
                                        Customize your account with your company&apos;s contract language and start selling faster than ever.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Avatar variant="square" src="/assets/moneyreport.png" className={this.props.classes.cardAvatars} />
                                    <Typography variant="body1" className={this.props.classes.infoSectionText}>
                                        Easy Estimates
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary" className={this.props.classes.infoSectionText}>
                                        RoofSnap makes building estimates fast and easy.
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary" className={this.props.classes.infoSectionText}>
                                        Build by line item or apply templates for speed and consistency. Most importantly, your RoofSnap estimates use your preferred product list and pricing.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={4} item xs={12} className={this.props.classes.testimonialBottom}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" align="center">
                                        “RoofSnap is like having an extra estimator. It&apos;s one of the best programs I&apos;ve come across in my 30+ years in the roofing business.”
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" align="center">
                                        Mason N.
                                    </Typography>
                                    <Typography variant="subtitle1" align="center">
                                        via Facebook
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={12}>
                                    <Typography variant="caption" align="center">
                                        &copy; RoofSnap, LLC | <a href={`${process.env.REACT_APP_ROOFSNAP_ONLINE_HOST}/Home/Privacy`} target="_blank" rel="noopener noreferrer">Privacy Policy</a> | <a href={`${process.env.REACT_APP_ROOFSNAP_ONLINE_HOST}/Home/TermsAndConditions`} target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Paper>
        );
    }
}

RegisterNew.propTypes = {
    classes: PropTypes.shape({
        card: PropTypes.string.isRequired,
        formControl: PropTypes.string.isRequired,
        registerButton: PropTypes.string.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

const mapStateToProps = state => ({
    features: state.features,
});

export default connect(mapStateToProps)(withStyles(style)(RegisterNew));
