import React from 'react';
import PropTypes from 'prop-types';
import { getDocumentDataContextTypeFromDocumentTemplateCategory } from '../../lib/documentDataContextType';
import DocumentTemplatesList from '../DocumentTemplatesList';

const DocumentTemplateSelection = (props) => {
    // Props
    const {
        documentTemplateCategories,
        onDocumentTemplateClick,
        disabledDocumentDataContextTypes,
        currentUserRoleIds,
        officeId,
        subscriptionExpired,
    } = props;

    const renderDocumentTemplateCategory = (documentTemplateCategory) => {
        const documentDataContextType = getDocumentDataContextTypeFromDocumentTemplateCategory(documentTemplateCategory);
        const disabled = disabledDocumentDataContextTypes.includes(documentDataContextType);
        return (
            <DocumentTemplatesList
                documentTemplateCategory={documentTemplateCategory}
                onDocumentTemplateClick={onDocumentTemplateClick}
                documentTemplatesDisabled={disabled}
                currentUserRoleIds={currentUserRoleIds}
                officeId={officeId}
                subscriptionExpired={subscriptionExpired}
            />
        );
    };

    return (
        <React.Fragment>
            {
                documentTemplateCategories.map(renderDocumentTemplateCategory)
            }
        </React.Fragment>
    );
};

DocumentTemplateSelection.propTypes = {
    documentTemplateCategories: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        templates: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            documentDataContextType: PropTypes.string.isRequired,
            thumbnailUrl: PropTypes.string.isRequired,
        })).isRequired,
    })).isRequired,
    onDocumentTemplateClick: PropTypes.func,
    disabledDocumentDataContextTypes: PropTypes.arrayOf(PropTypes.string),
    currentUserRoleIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    officeId: PropTypes.number.isRequired,
    subscriptionExpired: PropTypes.bool,
};

DocumentTemplateSelection.defaultProps = {
    onDocumentTemplateClick: () => {},
    disabledDocumentDataContextTypes: [],
    subscriptionExpired: false,
};

export default DocumentTemplateSelection;
