import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { hideReduxMessage } from '../../actions/ReduxMessagesActions';

const ReduxMessages = class extends React.Component {
    handleClose = () => {
        this.props.dispatch(hideReduxMessage());
    };
    render() {
        return (
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={this.props.showManagedMessage}
                onClose={this.handleClose}
                autoHideDuration={6000}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={
                    <span id="message-id">
                        {this.props.reduxManagedMessage}
                    </span>
                }
                action={this.props.link}
            />
        );
    }
};

ReduxMessages.propTypes = {
    dispatch: PropTypes.func.isRequired,
    reduxManagedMessage: PropTypes.string.isRequired,
    showManagedMessage: PropTypes.bool.isRequired,
    link: PropTypes.node,
};

ReduxMessages.defaultProps = {
    link: null,
};

const mapState = state => ({ ...state.reduxMessages });

export default connect(mapState)(ReduxMessages);
