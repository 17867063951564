/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import { Grid, Button, Typography, Menu, MenuItem } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { LockOutlined } from '@material-ui/icons';
import ProjectPlaceholderImage from './ProjectPlaceholder.png';
import { projectAddressSingleLine } from '../../lib/ProjectFormatter';
import projectStatus from '../../lib/ProjectStatus';
import LockableElement from '../LockableElement/LockableElement';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';
import MeasurementOrderChip from './MeasurementOrderChip.tsx';


const style = theme => ({
    quickActionsToolbar: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        justifyContent: 'space-between',
    },
    roofSnapImage: {
        height: 300,
        position: 'relative',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    cardContent: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    sketchOsChipWrapper: {
        position: 'absolute',
        bottom: 10,
        right: 15,
    },
    closeIcon: {
        padding: theme.spacing(1),
    },
});

export const ProjectListGridItem = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleActionClick = (route, onClick, locked = false) => {
        if (onClick) return onClick();
        return props.history.push(`/projects/${props.project.id}/${route}`);
    };
    const handleLockedClicked = () => {
        props.dispatch(setReduxMessage('Subscribe for access to estimates and exclusive order discounts.', <Button color="inherit" onClick={() => props.history.push('/settings/billing')}>Subscribe</Button>));
    };

    const handleMoreClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMoreClose = () => {
        setAnchorEl(null);
    };

    const renderPrimaryQuickActions = action => (
        <Button
            key={action.Title}
            size="small"
            onClick={() => handleActionClick(action.Route, action.OnClick)}
            color="inherit"
            id={action.Title}
        >
            {action.Icon}
            {action.ShortName || action.Title}
        </Button>
    );

    const renderSecondaryQuickActions = (action) => {
        const isLocked = action.isSubscriberOnly && props.organization.subscriptionExpired;

        return (
            <div role="presentation" onClick={isLocked ? handleLockedClicked : null} className="project-list-grid-menu-item">
                <MenuItem
                    onClick={isLocked ? () => handleLockedClicked() : () => handleActionClick(action.Route || null, action.OnClick || null)}
                    key={action.Title}
                    disabled={isLocked}
                    id={props.project.id}
                >
                    <Grid container spacing={3}>
                        <Grid item>{action.Icon}</Grid>
                        <Grid item xs>
                            {action.Title}
                        </Grid>
                        <Grid item>{isLocked && <LockOutlined />}</Grid>
                    </Grid>
                </MenuItem>
            </div>
        );
    };

    const renderToolbar = () => (
        <Toolbar
            variant="dense"
            className={props.classes.quickActionsToolbar}
        >
            {
                props.primaryVisibleMenuItems.map(renderPrimaryQuickActions)
            }
            {props.secondaryVisibleMenuItems &&
            <React.Fragment>
                <Button
                    size="small"
                    onClick={handleMoreClick}
                    color="inherit"
                >
                    <ArrowDropDown /> More
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMoreClose}
                >
                    {props.secondaryVisibleMenuItems.map(renderSecondaryQuickActions)}
                </Menu>
            </React.Fragment>
            }
        </Toolbar>
    );

    return (
        <LockableElement
            locked={props.project.locked}
            onClick={props.project.locked ? props.handleLockedElementClick : null}
        >
            <Card>
                {(props.primaryVisibleMenuItems || props.secondaryVisibleMenuItems) && renderToolbar()}
                <CardMedia
                    image={props.project.roofSnapImageUrl || ProjectPlaceholderImage}
                    className={props.classes.roofSnapImage}
                    onClick={props.onProjectListItemClick}
                >
                    {props.project.sketchOrder && (
                        <div className={props.classes.sketchOsChipWrapper}>
                            <MeasurementOrderChip orderType={props.project.sketchOrder.sketchReportType} />
                        </div>
                    )}
                </CardMedia>
                <CardContent onClick={props.onProjectListItemClick} className={props.classes.cardContent}>
                    <Typography variant="h5" noWrap>
                        {props.project.projectName}
                    </Typography>
                    <Typography variant="caption">{projectAddressSingleLine(props.project)}</Typography>
                    <Grid container wrap="nowrap" spacing={2}>
                        <Grid item xs>
                            <Typography variant="body1">{projectStatus[props.project.projectStatus]}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">{dateFormat(props.project.UpdatedAt || props.project.createdAt, 'mmm d, yyyy h:MM TT')}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </LockableElement>
    );
};
ProjectListGridItem.propTypes = {
    project: PropTypes.shape({
        roofSnapImageUrl: PropTypes.string,
    }).isRequired,
    handleLockedElementClick: PropTypes.func,
    organization: PropTypes.shape({}).isRequired,
};

ProjectListGridItem.defaultProps = {
    handleLockedElementClick: () => {},
};

const mapStateToProps = (state) => {
    const {
        organization,
    } = state;

    return {
        organization,
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(style)(ProjectListGridItem)));
