import React from 'react';
import { Route } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ProjectBreadcrumb from '../Projects/ProjectBreadcrumb';
import './Breadcrumb.css';
import MeasurementOrderBreadcrumb from '../MeasurementOrderBreadcrumb/MeasurementOrderBreadcrumb.tsx';
import BillingFailedSketchOrdersBreadcrumb from '../BillingFailedSketchOrderBreadcrumb';
import PaygoPricingPageCrumb from '../PaygoPricing/PaygoPricingPageBreadcrumb';
import SettingsPageBreadcrumb from '../Settings/SettingsPageBreadcrumb';

const crumb = (name) => (
    <li>
        <Button disabled>{name}</Button>
    </li>
);

const Breadcrumb = () => (
    <ul className='breadcrumb'>
        <Route path='/projects' component={ProjectBreadcrumb} />
        <Route path='/expired' component={ProjectBreadcrumb} />
        <Route path='/help' render={() => crumb('Help')} />
        <Route path='/orders' component={MeasurementOrderBreadcrumb} />
        <Route
            path='/billingfailedorders'
            component={BillingFailedSketchOrdersBreadcrumb}
        />
        <Route path='/pricing' component={PaygoPricingPageCrumb} />
        <Route path='/settings' component={SettingsPageBreadcrumb} />
        <Route path='/ordercompleted' component={ProjectBreadcrumb} />
    </ul>
);

export default Breadcrumb;
