import { CALL_API, httpMethod } from '../middleware/api';
import { SET_REDUX_MESSAGE } from './ReduxMessagesActions';

export const GET_ESTIMATE_TEMPLATES_REQUEST = 'GET_ESTIMATE_TEMPLATES_REQUEST';
export const GET_ESTIMATE_TEMPLATES_SUCCESS = 'GET_ESTIMATE_TEMPLATES_SUCCESS';
export const GET_ESTIMATE_TEMPLATES_FAILURE = 'GET_ESTIMATE_TEMPLATES_FAILURE';

export const getEstimateTemplatesByOfficeId = (officeId, params) => ({
    [CALL_API]: {
        types: [GET_ESTIMATE_TEMPLATES_REQUEST, GET_ESTIMATE_TEMPLATES_SUCCESS, GET_ESTIMATE_TEMPLATES_FAILURE],
        endpoint: `offices/${officeId}/estimatetemplates`,
        params,
    },
});

export const templatesParams = {
    Search: 'search',
    Page: 'page',
};

export const CREATE_ESTIMATE_TEMPLATE_REQUEST = 'CREATE_ESTIMATE_TEMPLATE_REQUEST';
export const CREATE_ESTIMATE_TEMPLATE_SUCCESS = 'CREATE_ESTIMATE_TEMPLATE_SUCCESS';
export const CREATE_ESTIMATE_TEMPLATE_FAILURE = 'CREATE_ESTIMATE_TEMPLATE_FAILURE';

export const createEstimateTemplate = (projectId, estimateId, template) => (({
    [CALL_API]: {
        types: [CREATE_ESTIMATE_TEMPLATE_REQUEST, CREATE_ESTIMATE_TEMPLATE_SUCCESS, CREATE_ESTIMATE_TEMPLATE_FAILURE, SET_REDUX_MESSAGE],
        endpoint: `estimatetemplates/?projectId=${projectId}&estimateId=${estimateId}`,
        method: httpMethod.POST,
        body: template,
    },
}));
