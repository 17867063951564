import {
    CREATE_PROJECT_SHARES_REQUEST,
    CREATE_PROJECT_SHARES_SUCCESS,
    CREATE_PROJECT_SHARES_FAILURE,
    DELETE_PROJECT_SHARES_REQUEST,
    DELETE_PROJECT_SHARES_SUCCESS,
    DELETE_PROJECT_SHARES_FAILURE,
} from '../actions/ProjectSharesActions';

function projectShares(state = {
    isFetching: false,
    offices: null,
}, action) {
    switch (action.type) {
    case CREATE_PROJECT_SHARES_REQUEST:
        return Object.assign({}, state, {
        });
    case CREATE_PROJECT_SHARES_SUCCESS:
        return Object.assign({}, state, {
        });
    case CREATE_PROJECT_SHARES_FAILURE:
        return Object.assign({}, state, {
        });
    case DELETE_PROJECT_SHARES_REQUEST:
        return Object.assign({}, state, {
        });
    case DELETE_PROJECT_SHARES_SUCCESS:
        return Object.assign({}, state, {
        });
    case DELETE_PROJECT_SHARES_FAILURE:
        return Object.assign({}, state, {
        });
    default:
        return state;
    }
}

export default projectShares;
