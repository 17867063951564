import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Link, withRouter } from 'react-router-dom';
import { DocumentDataContextType } from '../../lib/documentDataContextType';

const DocumentTemplateCategoryValidationMessage = ({ documentDataContextType, match }) => {
    switch (documentDataContextType) {
    case DocumentDataContextType.Project:
        return (
            <Typography>
                <Link to="/projects">Create a project</Link> to create this document.
            </Typography>
        );
    case DocumentDataContextType.ProjectDrawing:
        return (
            <Typography>
                <Link to={`/projects/${match.params.id}/sketch`}>Create a sketch</Link> to create this document.
            </Typography>
        );

    case DocumentDataContextType.Estimate:
        return (
            <Typography>
                <Link to={`/projects/${match.params.id}/estimates`}>Create an estimate option</Link> to create this document.
            </Typography>
        );
    case DocumentDataContextType.MultiEstimate:
        return (
            <Typography>
                <Link to={`/projects/${match.params.id}/estimates`}>Create multiple estimate options</Link> to create this document.
            </Typography>
        );
    default:
        return <Typography>These documents are not generatable at the moment. Please try again later.</Typography>;
    }
};

DocumentTemplateCategoryValidationMessage.props = {
    documentDataContextType: PropTypes.string.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
};

export default withRouter(DocumentTemplateCategoryValidationMessage);
