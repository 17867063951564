import React from 'react';
import { Icon, WithStyles, createStyles, withStyles } from '@material-ui/core';

const styles = () => createStyles({
    imageIcon: {
        display: 'flex',
        height: 'inherit',
        width: 'inherit'
      },
      iconRoot: {
        textAlign: 'center'
      }
});

type MultiFamilyIconProps = {} & WithStyles<typeof styles>;

const MultiFamilyIcon = ({ classes }: MultiFamilyIconProps) => (
    <Icon classes={{root: classes.iconRoot}}>
        <img src='/assets/MultiFamilyIcon.svg' alt='Multi-Family' />
    </Icon>
);

export default withStyles(styles)(MultiFamilyIcon);
