import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';

const ConfirmationModal = props => (
    <div>
        <Dialog
            fullScreen={props.fullScreen}
            open={props.isOpen}
            onClose={props.handleClose}
        >
            <DialogTitle id="responsive-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{props.description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button id="confirmation-delete-button" onClick={props.handleConfirm}>{props.confirmText}</Button>
            </DialogActions>
        </Dialog>
    </div>
);
ConfirmationModal.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    handleConfirm: PropTypes.func.isRequired,
    confirmText: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

ConfirmationModal.defaultProps = {
    description: '',
};
export default withMobileDialog()(ConfirmationModal);
