import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ForbiddenDialog = ({ forbiddenDialogTitle, forbiddenDialogContentText, confirmationAction }) => (
    <Dialog
        open
    >
        <DialogTitle>{forbiddenDialogTitle}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {forbiddenDialogContentText}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={confirmationAction} color="primary">
                Ok
            </Button>
        </DialogActions>
    </Dialog>
);

ForbiddenDialog.propTypes = {
    forbiddenDialogTitle: PropTypes.string,
    forbiddenDialogContentText: PropTypes.string,
    confirmationAction: PropTypes.func.isRequired,
};

ForbiddenDialog.defaultProps = {
    forbiddenDialogTitle: 'Oops! Something Is Wrong With Your Account',
    forbiddenDialogContentText: 'Oops! Something is wrong with your account. Please contact RoofSnap at (877) 766-3762.',
};

export default ForbiddenDialog;
