class QueryStringBuilder {
    constructor() {
        this.queryString = '';
    }

    setQueryParameter(key, value) {
        const concatonater = (this.queryString.startsWith('?') ? '&' : '?');
        const newQueryStringParameter = `${concatonater}${key}=${value}`;

        this.queryString += newQueryStringParameter;
    }

    buildQueryString() {
        return this.queryString;
    }
}

export default QueryStringBuilder;
