import { CALL_API, httpMethod } from '../middleware/api';
import { SET_REDUX_MESSAGE } from './ReduxMessagesActions';

export const GET_PROJECT_ESTIMATE_REQUEST = 'GET_PROJECT_ESTIMATE_REQUEST';
export const GET_PROJECT_ESTIMATE_SUCCESS = 'GET_PROJECT_ESTIMATE_SUCCESS';
export const GET_PROJECT_ESTIMATE_FAILURE = 'GET_PROJECT_ESTIMATE_FAILURE';

export const getProjectEstimate = (projectId, estimateId) => ({
    [CALL_API]: {
        types: [GET_PROJECT_ESTIMATE_REQUEST, GET_PROJECT_ESTIMATE_SUCCESS, GET_PROJECT_ESTIMATE_FAILURE],
        endpoint: `projects/${projectId}/estimates/${estimateId}`,
        method: httpMethod.GET,
    },
});

export const CREATE_PROJECT_ESTIMATE_REQUEST = 'CREATE_PROJECT_ESTIMATE_REQUEST';
export const CREATE_PROJECT_ESTIMATE_SUCCESS = 'CREATE_PROJECT_ESTIMATE_SUCCESS';
export const CREATE_PROJECT_ESTIMATE_FAILURE = 'CREATE_PROJECT_ESTIMATE_FAILURE';

export const createProjectEstimate = (projectId, estimate) => (({
    [CALL_API]: {
        types: [CREATE_PROJECT_ESTIMATE_REQUEST, CREATE_PROJECT_ESTIMATE_SUCCESS, CREATE_PROJECT_ESTIMATE_FAILURE, SET_REDUX_MESSAGE],
        endpoint: `projects/${projectId}/estimates`,
        method: httpMethod.POST,
        body: estimate,
    },
}));

export const DELETE_PROJECT_ESTIMATE_REQUEST = 'DELETE_PROJECT_ESTIMATE_REQUEST';
export const DELETE_PROJECT_ESTIMATE_SUCCESS = 'DELETE_PROJECT_ESTIMATE_SUCCESS';
export const DELETE_PROJECT_ESTIMATE_FAILURE = 'DELETE_PROJECT_ESTIMATE_FAILURE';

export const deleteProjectEstimate = (projectId, estimateId) => (({
    [CALL_API]: {
        types: [DELETE_PROJECT_ESTIMATE_REQUEST, DELETE_PROJECT_ESTIMATE_SUCCESS, DELETE_PROJECT_ESTIMATE_FAILURE],
        endpoint: `projects/${projectId}/estimates/${estimateId}`,
        method: httpMethod.DELETE,
    },
}));

export const UPDATE_PROJECT_ESTIMATE_REQUEST = 'UPDATE_PROJECT_ESTIMATE_REQUEST';
export const UPDATE_PROJECT_ESTIMATE_SUCCESS = 'UPDATE_PROJECT_ESTIMATE_SUCCESS';
export const UPDATE_PROJECT_ESTIMATE_FAILURE = 'UPDATE_PROJECT_ESTIMATE_FAILURE';

export const updateProjectEstimate = (projectId, estimateId, version, updatedEstimate) => (({
    [CALL_API]: {
        types: [UPDATE_PROJECT_ESTIMATE_REQUEST, UPDATE_PROJECT_ESTIMATE_SUCCESS, UPDATE_PROJECT_ESTIMATE_FAILURE],
        endpoint: `projects/${projectId}/estimates/${estimateId}`,
        method: httpMethod.PUT,
        body: updatedEstimate,
        header: {
            version,
        },
    },
}));
