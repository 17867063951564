import React, { Component } from 'react';

export class CreateESignature extends Component {
    componentDidMount() {
    }
    render() {
        return <p>CREATE YOUR ESIGNATURE HERE</p>;
    }
}

export default CreateESignature;
