import React from 'react';
import PropTypes from 'prop-types';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import MeasurementsInput from './MeasurementsInput';

const style = {
    page: {
        width: '600px',
        margin: '0 auto',
    },
    card: {
        marginBottom: '30px',
    },
    Input: {
        width: '100px',
        marginRight: '20px',
    },
    label: {
        fontSize: 14,
        display: 'inline-block',
        width: '125px',
        textAlign: 'right',
        marginRight: '20px',
    },
    labelRight: {
        display: 'inline-block',
    },
};

const Measurements = (props) => {
    const {
        actualSquares,
        wastePercentage,
        totalSquares,
        lowSlope,
        twoStory,
        secondLayer,
        eaves,
        rakes,
        ridges,
        hips,
        valleys,
        step,
        wall,
        pitchChange,
        iceWaterShield,
        ridgeVent,
        rakeEdge,
        eaveEdge,
        stepFlashing,
        apronFlashing,
        gutters,
        gutterToppers,
        downSpouts,
        pitch1In12,
        pitch2In12,
        pitch3In12,
        pitch4In12,
        pitch5In12,
        pitch6In12,
        pitch7In12,
        pitch8In12,
        pitch9In12,
        pitch10In12,
        pitch11In12,
        pitch12In12,
        pitch0In12,
        pitch13In12,
        pitch14In12,
        pitch15In12,
        pitch16In12,
        pitch17In12,
        pitch18In12,
        pitch19In12,
        pitch20In12,
        pitch21In12,
        pitch22In12,
        pitch23In12,
        pitch24In12,
        overrideDrawingMeasurements,
    } = props.measurements;

    return (
        <div style={style.page}>

            {/* Squares */}
            <Card style={style.card}>
                <CardContent>
                    <Typography variant="h5">Squares</Typography>
                    <div>
                        <Typography style={style.label}>Actual</Typography>
                        <MeasurementsInput id="actualSquares" style={style.Input} value={actualSquares} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq. *excludes low slope</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Waste Percentage</Typography>
                        <MeasurementsInput id="wastePercentage" style={style.Input} value={wastePercentage} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>%</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Total</Typography>
                        <MeasurementsInput id="totalSquares" style={style.Input} value={totalSquares} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq. *excludes low slope</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Low Slope</Typography>
                        <MeasurementsInput id="lowSlope" style={style.Input} value={lowSlope} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Two-Story</Typography>
                        <MeasurementsInput id="twoStory" style={style.Input} value={twoStory} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Second-Layer</Typography>
                        <MeasurementsInput id="secondLayer" style={style.Input} value={secondLayer} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                </CardContent>
            </Card>

            {/* Line Measurements */}
            <Card style={style.card}>
                <CardContent>
                    <Typography variant="h5">Line Measurements</Typography>
                    <div>
                        <Typography style={style.label}>Eaves</Typography>
                        <MeasurementsInput id="eaves" style={style.Input} value={eaves} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Ft.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Rakes</Typography>
                        <MeasurementsInput id="rakes" style={style.Input} value={rakes} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Ft.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Ridges</Typography>
                        <MeasurementsInput id="ridges" style={style.Input} value={ridges} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Ft.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Hips</Typography>
                        <MeasurementsInput id="hips" style={style.Input} value={hips} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Ft.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Valleys</Typography>
                        <MeasurementsInput id="valleys" style={style.Input} value={valleys} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Ft.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Step</Typography>
                        <MeasurementsInput id="step" style={style.Input} value={step} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Ft.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Wall</Typography>
                        <MeasurementsInput id="wall" style={style.Input} value={wall} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Ft.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Pitch Change</Typography>
                        <MeasurementsInput id="pitchChange" style={style.Input} value={pitchChange} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Ft.</Typography>
                    </div>
                </CardContent>
            </Card>

            {/* Category Measurements */}
            <Card style={style.card}>
                <CardContent>
                    <Typography variant="h5">Category Measurements</Typography>
                    <div>
                        <Typography style={style.label}>Ice &amp; Watershield</Typography>
                        <MeasurementsInput id="iceWaterShield" style={style.Input} value={iceWaterShield} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Ft.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Ridge Vent</Typography>
                        <MeasurementsInput id="ridgeVent" style={style.Input} value={ridgeVent} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Ft.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Rake Edge</Typography>
                        <MeasurementsInput id="rakeEdge" style={style.Input} value={rakeEdge} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Ft.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Eave Edge</Typography>
                        <MeasurementsInput id="eaveEdge" style={style.Input} value={eaveEdge} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Ft.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Step Flashing</Typography>
                        <MeasurementsInput id="stepFlashing" style={style.Input} value={stepFlashing} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Ft.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Apron Flashing</Typography>
                        <MeasurementsInput id="apronFlashing" style={style.Input} value={apronFlashing} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Ft.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Gutters</Typography>
                        <MeasurementsInput id="gutters" style={style.Input} value={gutters} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Ft.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Gutter Toppers</Typography>
                        <MeasurementsInput id="gutterToppers" style={style.Input} value={gutterToppers} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Ft.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>Down Spouts</Typography>
                        <MeasurementsInput id="downSpouts" style={style.Input} value={downSpouts} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Ft.</Typography>
                    </div>
                </CardContent>
            </Card>

            {/* Pitch Measurements */}
            <Card style={style.card}>
                <CardContent>
                    <Typography variant="h5">Pitch Measurements</Typography>
                    <div>
                        <Typography style={style.label}>0 in 12</Typography>
                        <MeasurementsInput id="pitch0In12" style={style.Input} value={pitch0In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>1 in 12</Typography>
                        <MeasurementsInput id="pitch1In12" style={style.Input} value={pitch1In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>2 in 12</Typography>
                        <MeasurementsInput id="pitch2In12" style={style.Input} value={pitch2In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>3 in 12</Typography>
                        <MeasurementsInput id="pitch3In12" style={style.Input} value={pitch3In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>4 in 12</Typography>
                        <MeasurementsInput id="pitch4In12" style={style.Input} value={pitch4In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>5 in 12</Typography>
                        <MeasurementsInput id="pitch5In12" style={style.Input} value={pitch5In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>6 in 12</Typography>
                        <MeasurementsInput id="pitch6In12" style={style.Input} value={pitch6In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>7 in 12</Typography>
                        <MeasurementsInput id="pitch7In12" style={style.Input} value={pitch7In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>8 in 12</Typography>
                        <MeasurementsInput id="pitch8In12" style={style.Input} value={pitch8In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>9 in 12</Typography>
                        <MeasurementsInput id="pitch9In12" style={style.Input} value={pitch9In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>10 in 12</Typography>
                        <MeasurementsInput id="pitch10In12" style={style.Input} value={pitch10In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>11 in 12</Typography>
                        <MeasurementsInput id="pitch11In12" style={style.Input} value={pitch11In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>12 in 12</Typography>
                        <MeasurementsInput id="pitch12In12" style={style.Input} value={pitch12In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>13 in 12</Typography>
                        <MeasurementsInput id="pitch13In12" style={style.Input} value={pitch13In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>14 in 12</Typography>
                        <MeasurementsInput id="pitch14In12" style={style.Input} value={pitch14In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>15 in 12</Typography>
                        <MeasurementsInput id="pitch15In12" style={style.Input} value={pitch15In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>16 in 12</Typography>
                        <MeasurementsInput id="pitch16In12" style={style.Input} value={pitch16In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>17 in 12</Typography>
                        <MeasurementsInput id="pitch17In12" style={style.Input} value={pitch17In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>18 in 12</Typography>
                        <MeasurementsInput id="pitch18In12" style={style.Input} value={pitch18In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>19 in 12</Typography>
                        <MeasurementsInput id="pitch19In12" style={style.Input} value={pitch19In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>20 in 12</Typography>
                        <MeasurementsInput id="pitch20In12" style={style.Input} value={pitch20In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>21 in 12</Typography>
                        <MeasurementsInput id="pitch21In12" style={style.Input} value={pitch21In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>22 in 12</Typography>
                        <MeasurementsInput id="pitch22In12" style={style.Input} value={pitch22In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>23 in 12</Typography>
                        <MeasurementsInput id="pitch23In12" style={style.Input} value={pitch23In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                    <div>
                        <Typography style={style.label}>24 in 12</Typography>
                        <MeasurementsInput id="pitch24In12" style={style.Input} value={pitch24In12} disabled={!overrideDrawingMeasurements} handleChange={props.handleChange} />
                        <Typography style={style.labelRight}>Sq.</Typography>
                    </div>
                </CardContent>
            </Card>
        </div>

    );
};

Measurements.propTypes = {
    measurements: PropTypes.shape({
        actualSquares: PropTypes.number.isRequired,
        wastePercentage: PropTypes.number.isRequired,
        totalSquares: PropTypes.number.isRequired,
        lowSlope: PropTypes.number.isRequired,
        twoStory: PropTypes.number.isRequired,
        secondLayer: PropTypes.number.isRequired,
        eaves: PropTypes.number.isRequired,
        rakes: PropTypes.number.isRequired,
        ridges: PropTypes.number.isRequired,
        hips: PropTypes.number.isRequired,
        valleys: PropTypes.number.isRequired,
        step: PropTypes.number.isRequired,
        wall: PropTypes.number.isRequired,
        pitchChange: PropTypes.number.isRequired,
        iceWaterShield: PropTypes.number.isRequired,
        ridgeVent: PropTypes.number.isRequired,
        rakeEdge: PropTypes.number.isRequired,
        eaveEdge: PropTypes.number.isRequired,
        stepFlashing: PropTypes.number.isRequired,
        apronFlashing: PropTypes.number.isRequired,
        gutters: PropTypes.number.isRequired,
        gutterToppers: PropTypes.number.isRequired,
        downSpouts: PropTypes.number.isRequired,
        pitch0In12: PropTypes.number.isRequired,
        pitch1In12: PropTypes.number.isRequired,
        pitch2In12: PropTypes.number.isRequired,
        pitch3In12: PropTypes.number.isRequired,
        pitch4In12: PropTypes.number.isRequired,
        pitch5In12: PropTypes.number.isRequired,
        pitch6In12: PropTypes.number.isRequired,
        pitch7In12: PropTypes.number.isRequired,
        pitch8In12: PropTypes.number.isRequired,
        pitch9In12: PropTypes.number.isRequired,
        pitch10In12: PropTypes.number.isRequired,
        pitch11In12: PropTypes.number.isRequired,
        pitch12In12: PropTypes.number.isRequired,
        pitch13In12: PropTypes.number.isRequired,
        pitch14In12: PropTypes.number.isRequired,
        pitch15In12: PropTypes.number.isRequired,
        pitch16In12: PropTypes.number.isRequired,
        pitch17In12: PropTypes.number.isRequired,
        pitch18In12: PropTypes.number.isRequired,
        pitch19In12: PropTypes.number.isRequired,
        pitch20In12: PropTypes.number.isRequired,
        pitch21In12: PropTypes.number.isRequired,
        pitch22In12: PropTypes.number.isRequired,
        pitch23In12: PropTypes.number.isRequired,
        pitch24In12: PropTypes.number.isRequired,
        overrideDrawingMeasurements: PropTypes.bool.isRequired,
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
};

export default Measurements;
