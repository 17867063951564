import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Grid, ListItemText, Paper, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Check, Close, ArrowForwardIos } from '@material-ui/icons';

const styles = () => ({
    root: {
        textAlign: 'center',
        fontFamily: 'Roboto sans-serif',
        minWidth: '15vw',
    },
    column: {
        minWidth: '15vw',
        height: '532px',
        borderRadius: '5px',
    },
    columnMobile: {
        minWidth: '16vw',
        height: '532px',
        borderRadius: '5px',
    },
    leftColumn: {
        minWidth: '15vw',
        height: '646px',
        borderRadius: '5px',
    },
    nameDark: {
        color: '#012B50',
        fontSize: '13px',
        fontWeight: 500,
        borderRadius: '5px',

    },
    nameBlue: {
        color: '#005CAA',
        fontSize: '13px',
        fontWeight: 500,
        borderRadius: '5px',

    },
    nameWhite: {
        color: '#F0F9FF',
        fontSize: '13px',
        fontWeight: 500,
        borderRadius: '5px',
    },
    upTo: {
        color: '#10283E',
        fontSize: '10px',
        opacity: '0.5',
    },
    upToSpacing: {
        height: '15px',
    },
    users: {
        color: '#012B50',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    price: {
        color: '#005CAA',
        fontSize: '44px',
        fontWeight: 'bold',
    },
    included: {
        color: '#005CAA',
        fontSize: '8px',
        letterSpacing: '1.5pt',
        textTransform: 'uppercase',
    },
    orderCost: {
        color: '#10283E',
        fontSize: '11px',
    },
    orderCostGrid: {
        padding: '10px 5px 10px 5px',
        borderTop: '1px solid #0000001f',
        borderBottom: '1px solid #0000001f',
    },
    grayBackground: {
        backgroundColor: '#FAFAFA',
        padding: '10px 0px 10px 0px',
        borderBottom: '1px solid #0000001f',
        borderTop: '1px solid #0000001f',
        borderRadius: '5px 5px 0px 0px',
    },
    grayBackgroundName: {
        backgroundColor: '#FAFAFA',
        padding: '10px 0px 10px 0px',
        borderBottom: '1px solid #0000001f',
        borderRadius: '5px 5px 0px 0px',
    },
    lightBlueBackground: {
        backgroundColor: '#F0F9FF',
        borderColor: '#00000017',
        padding: '10px 0px 10px 0px',
        borderBottom: '1px solid #0000001f',
        borderRadius: '5px 5px 0px 0px',
    },
    darkBlueBackground: {
        backgroundColor: '#005CAA',
        borderColor: '#00000017',
        padding: '10px 0px 10px 0px',
        borderBottom: '1px solid #0000001f',
        borderRadius: '5px 5px 0px 0px',
    },
    userAndCost: {
        height: '150px',
        padding: '10px 5px 10px 5px',
    },
    more: {
        color: '#005CAA',
        fontSize: '11px',
        fontWeight: 600,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    moreIcon: {
        color: '#005CAA',
        fontSize: '11px',
        fontWeight: 600,
    },
    orderMeasurementsButton: {
        color: '#40AD48',
        fontSize: '10px',
        border: '1px solid #40AD48',
        '&:hover': {
            color: '#FFFFFF',
            backgroundColor: '#40AD48',
        },
    },
    solidButton: {
        color: '#FFFFFF',
        backgroundColor: '#40AD48',
        fontSize: '10px',
        '&:hover': {
            color: '#40AD48',
            backgroundColor: '#FFFFFF',
            border: '1px solid #40AD48',
        },
    },
    shortBottomGrid: {
        height: '42px',
    },
    bottomGrid: {
        height: '54px',
    },
});

const PriceOption = (props) => {
    const {
        name,
        id,
        numberOfUsers,
        monthlyPrice,
        description,
        displayNotIncluded,
        includedOptions,
        notIncludedOptions,
        sketchOrderPriceRange,
        buttonText,
    } = props;

    const renderList = (list, included, price) => (
        <Grid container item direction="column" style={id === 'Non-Subscriber' ? { padding: '10px 0px 10px 0px' } : { padding: '10px 0px 10px 0px', height: '202px' }}>
            {list.map(opt => (
                <Grid container item direction="row" justify="flex-start" alignItems="center" style={window.innerWidth < 950 ? { width: '310px' } : null}>
                    {included &&
                        <Grid xs={2} item>
                            <Check style={{
                                color: '#40AD48', paddingLeft: '5px', textAlign: 'left', fontSize: '16px',
                            }}
                            />
                        </Grid>}
                    {!included &&
                        <Grid xs={2} item>
                            <Close style={{
                                color: 'red', paddingLeft: '5px', textAlign: 'left', fontSize: '16px',
                            }}
                            />
                        </Grid>}
                    <Grid item xs={10}>
                        <ListItemText xs primary={opt} key={price} style={{ padding: '0px 10px 0px 5px', textAlign: 'left' }} />
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
    const handleClick = () => {
        switch (id) {
        case 'Non-Subscriber':
            return (
                props.history.push('/orders/create')
            );
        case 'Monthly':
            return (
                props.history.push('/settings/billing')
            );
        default:
            return (
                window.open('https://roofsnap.com/contact-us/', '_blank')
            );
        }
    };

    const columnHeader = () => {
        switch (id) {
        case 'Non-Subscriber':
            return (
                <Grid item className={props.classes.grayBackground}>
                    <Typography className={props.classes.nameDark} id={id}>
                        {name}
                    </Typography>
                </Grid>);
        case 'Enterprise':
            return (
                <Grid item className={props.classes.darkBlueBackground}>
                    <Typography className={props.classes.nameWhite} id={id}>
                        {name}
                    </Typography>
                </Grid>);
        default:
            return (
                <Grid item className={props.classes.lightBlueBackground}>
                    <Typography className={props.classes.nameBlue} id={id}>
                        {name}
                    </Typography>
                </Grid>);
        }
    };

    return (
        <Grid className={props.classes.root} container item direction="column">
            <Paper variant="outlined" className={id === 'Non-Subscriber' ? props.classes.leftColumn : props.classes.column}>
                {columnHeader()}
                <div className={props.classes.userAndCost}>
                    <Grid item container direction="column" justify="center" alignItems="center">
                        {numberOfUsers > 1 ?
                            <Grid item><Typography className={props.classes.upTo}>Up To</Typography></Grid>
                            :
                            <Grid item className={props.classes.upToSpacing} />
                        }
                        <Grid item><Typography className={props.classes.users}>{numberOfUsers} {numberOfUsers > 1 ? 'Users' : 'User'}</Typography></Grid>
                        <Grid item><Typography className={props.classes.price}>${monthlyPrice}</Typography></Grid>
                        <Grid item>{description}</Grid>
                    </Grid>
                </div>
                <Grid item className={props.classes.grayBackground} style={{ padding: '5px 0px', borderRadius: '0px' }}>
                    {
                        displayNotIncluded === true ?
                            <Typography className={props.classes.included}>
                                Roof Measurement Orders:
                            </Typography>
                            :
                            <Typography className={props.classes.included}>
                                Subscription Includes:
                            </Typography>}
                </Grid>
                {renderList(includedOptions, true, monthlyPrice)}
                {displayNotIncluded && <Grid item className={props.classes.grayBackground} style={{ padding: '5px 0px', borderRadius: '0px' }}><Typography className={props.classes.included}>Not Included:</Typography></Grid>}
                {displayNotIncluded && renderList(notIncludedOptions, false, monthlyPrice)}
                <Grid item container direction="row" className={props.classes.orderCostGrid} alignItems="center">
                    <Grid item xs={9} >
                        <Typography className={props.classes.orderCost}>
                            Measurement Orders {`$${sketchOrderPriceRange[0]}-$${sketchOrderPriceRange[1]}`}
                        </Typography>
                    </Grid>

                    <Grid container style={{ cursor: 'pointer' }} direction="row" item xs={3} alignItems="center" onClick={() => props.handleMoreClick()}>
                        <Grid>
                            <Typography className={props.classes.orderCost} style={{ color: '#005CAA' }}>
                                MORE
                            </Typography>
                        </Grid>
                        <Grid>
                            <ArrowForwardIos className={props.classes.moreIcon} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container justify="center" alignItems="center" direction="row" className={name === 'Non-Subscriber' ? props.classes.shortBottomGrid : props.classes.bottomGrid}>
                    <Button
                        className={name === 'Non-Subscriber' ?
                            props.classes.orderMeasurementsButton :
                            props.classes.solidButton}
                        variant={name === 'Non-Subscriber' ?
                            'outlined' :
                            'contained'}
                        onClick={handleClick}
                    >
                        {buttonText}
                    </Button>
                </Grid>
            </Paper>
        </Grid>
    );
};

PriceOption.propTypes = {
    name: PropTypes.string.isRequired,
    numberOfUsers: PropTypes.number.isRequired,
    monthlyPrice: PropTypes.number.isRequired,
    description: PropTypes.element.isRequired,
    displayNotIncluded: PropTypes.bool,
    includedOptions: PropTypes.arrayOf(PropTypes.oneOf([PropTypes.string, PropTypes.element])).isRequired,
    notIncludedOptions: PropTypes.arrayOf(PropTypes.oneOf([PropTypes.string, PropTypes.element])),
    sketchOrderPriceRange: PropTypes.arrayOf(PropTypes.number).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

PriceOption.defaultProps = {
    displayNotIncluded: false,
    notIncludedOptions: [],
};

export default withRouter(withStyles(styles)(PriceOption));
