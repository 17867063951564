import React from 'react';
import { Snackbar, Paper } from '@material-ui/core';
import { Alert, Color as AlertColor } from '@material-ui/lab';
import { Warning as WarningIcon, CheckCircle as CheckCircleIcon } from '@material-ui/icons';

interface SaveSnackBarProps {
    isAlertOpen: boolean,
    handleSnackbarClose: (event?: React.SyntheticEvent, reason?: string) => void,
    snackBarMessage: {
        severity: AlertColor,
        message: string,
        retryAction?: JSX.Element;
        autoHideDuration?: number;
    } | null,
}

const errorStyle: React.CSSProperties = {
    border: 'none',
    color: '#f44336',
    fontWeight: 'bold',
    backgroundColor: 'white',
};

const successStyle: React.CSSProperties = {};

const SaveSnackbar = (props: SaveSnackBarProps) => {

    return(
        <Snackbar
                open={props.isAlertOpen}
                onClose={props.handleSnackbarClose}
                autoHideDuration={props.snackBarMessage?.autoHideDuration}
            >
                <>
                    {props.snackBarMessage?.severity === 'error' && (
                        <Alert
                            icon={<WarningIcon />}
                            component={Paper}
                            elevation={3}
                            severity='error'
                            onClose={props.handleSnackbarClose}
                            action={props.snackBarMessage?.retryAction}
                            variant='outlined'
                            style={errorStyle}
                            id='error-alert'
                        >
                            {props.snackBarMessage?.message}
                        </Alert>
                    )}
                    {props.snackBarMessage?.severity === 'success' && (
                        <Alert
                            icon={<CheckCircleIcon />}
                            severity='success'
                            onClose={props.handleSnackbarClose}
                            variant='filled'
                            style={successStyle}
                            id='success-alert'
                        >
                            {props.snackBarMessage?.message}
                        </Alert>
                    )}
                </>
            </Snackbar>
    )
}

export default SaveSnackbar;