import { CALL_API } from '../middleware/api';

export const GET_DOCUMENT_TEMPLATE_CATEGORIES_REQUEST = 'GET_DOCUMENT_TEMPLATE_CATEGORIES_REQUEST';
export const GET_DOCUMENT_TEMPLATE_CATEGORIES_SUCCESS = 'GET_DOCUMENT_TEMPLATE_CATEGORIES_SUCCESS';
export const GET_DOCUMENT_TEMPLATE_CATEGORIES_FAILURE = 'GET_DOCUMENT_TEMPLATE_CATEGORIES_FAILUE';

export const getDocumentTemplateCategories = officeId => ({
    [CALL_API]: {
        types: [
            GET_DOCUMENT_TEMPLATE_CATEGORIES_REQUEST,
            GET_DOCUMENT_TEMPLATE_CATEGORIES_SUCCESS,
            GET_DOCUMENT_TEMPLATE_CATEGORIES_FAILURE,
        ],
        endpoint: `offices/${officeId}/documenttemplatecategories/?sortByPopularity=true`,
        apiVersion: 'v1',
    },
});
