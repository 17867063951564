import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { MapImageryOptions } from 'roofsnap-map-imagery';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RoofSnapGeocodeApiClient from '../../lib/apiClients/RoofSnapGeocodeApiClient';
import ImageImport from '../ImageImport/ImageImport.tsx';
import {
    getProjectById,
    setCurrentProject,
    updateProjectRoofImage,
} from '../../actions/ProjectActions';
import { getOrganization } from '../../actions/Organization';
import { getOrganizationsCredits } from '../../actions/OrganizationCreditsActions';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';
import PageToolbarActions from '../NavigationComponents/PageToolbarActions';
import featureFlags from '../../lib/FeatureFlags.ts';

const steps = {
    confirmLocation: 'ConfirmLocation',
    imagerySelection: 'ImagerySelection',
    imageRotation: 'ImageRotation',
};

const repurchaseMessage =
    'If you leave this page without saving the image, you will have to repurchase the Premium imagery.';
const redeemMessage =
    'If you leave this page without saving the image, you will have to redeem another credit for the Premium imagery.';

const styles = () => ({
    root: {
        position: 'relative',
    },
    appBar: {
        position: 'relative',
        zIndex: 100,
        backgroundColor: 'white',
        marginTop: 1,
    },
    ourImageryTab: {
        maxWidth: 500,
        textAlign: 'left',
        textTransform: 'inherit',
    },
    importImageryTab: {
        maxWidth: 400,
        textAlign: 'left',
        textTransform: 'inherit',
    },
    confirmLocationInstructions: {
        width: 'calc(1.33*80vh)',
        margin: '30px auto',
    },
    confirmLocationInstructionsTitle: {
        fontWeight: 'bold',
        margin: '10px 0',
    },
    tabTitle: {
        fontWeight: 'bold',
    },
});

class MapImageryOptionsHost extends Component {
    // Default lat/lng to the geographic center of The United States
    state = {
        projectId: null,
        latitude: 39.8283,
        longitude: -98.5795,
        step: steps.confirmLocation,
        showNavigateAwayPrompt: false,
        hostConfirmLocationClick: false,
        activeTab: 0,
        nearmapSelected: false,
    };
    async componentDidMount() {
        await this.init();
    }
    getCoordinates = async () => {
        // Don't geocode if we already have the map center lat/lng on the project
        const { latitude, longitude } = this.props;
        if (latitude && longitude) return { latitude, longitude };

        const client = new RoofSnapGeocodeApiClient();
        const { customerAddress, customerCity, customerState, customerZip } =
            this.props.currentProject;
        const address = `${customerAddress}, ${customerCity}, ${customerState} ${customerZip}`;
        const response = await client.geocodeAddress(address);
        return response;
    };
    hasAddress = () => !!this.props.currentProject.customerAddress;
    loadProject = async () => {
        const { id: projectId } = this.props.match.params;
        await this.props.dispatch(getProjectById(projectId));
        this.props.dispatch(getOrganizationsCredits(this.props.organizationId));
        this.props.dispatch(getOrganization(this.props.organizationId));
        this.setState({ projectId });
    };
    init = async () => {
        await this.loadProject();
        const { projectId } = this.state;
        if (this.props.hasDrawing) {
            this.props.history.push(`/projects/${projectId}/sketch`);
            return;
        }
        // Display project location
        if (this.hasAddress()) {
            const coordinates = await this.getCoordinates();
            const { latitude, longitude } = coordinates;
            this.setState({
                latitude,
                longitude,
            });
            return;
        }
        // Couldn't determine location
        this.props.dispatch(
            setReduxMessage(
                'Cannot display location. Please confirm project address.'
            )
        );
        // Project doesn't have an address.  Try displaying user's location.
        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                this.setState({
                    latitude,
                    longitude,
                });
            });
            this.props.dispatch(
                setReduxMessage(
                    'Unable to locate project address. Displaying current location.'
                )
            );
        }
    };
    handleSaveImageComplete = async () => {
        await this.loadProject();
        if (this.props.hasDrawing) {
            this.setState({ showNavigateAwayPrompt: false });
            this.props.history.push(`/projects/${this.props.projectId}/sketch`);
        }
    };
    importImage = () => {
        this.imageImportComponent.click();
    };
    refImageImport = (component) => {
        this.imageImportComponent = component;
    };
    uploadRoofImageImport = (image) => {
        this.props
            .dispatch(
                updateProjectRoofImage(
                    this.props.currentProject.id,
                    this.props.currentProject.version,
                    image
                )
            )
            .then((result) => {
                if (result.error) {
                    return;
                }
                const project = result.response;
                this.props.dispatch(setCurrentProject(project));
                this.props.history.replace({
                    pathname: `/projects/${project.id}/`,
                });
            });
    };
    handleImagerySelected = async (image) => {
        const { source } = image;
        this.setState({
            nearmapSelected: source === 'nearmap',
        });
    };
    handleStepChange = async (step) => {
        // We need to refresh the available credit balance once they've chosen an image.
        if (step === steps.imageRotation) {
            await this.props.dispatch(
                getOrganizationsCredits(this.props.organizationId)
            );
        }

        this.setState({
            step,
            showNavigateAwayPrompt:
                step === steps.imageRotation &&
                this.state.nearmapSelected &&
                !this.props.nearmapApiKey,
        });
    };
    handleError = (e) => {
        this.props.dispatch(
            setReduxMessage(`${e.message} Please contact support.`)
        );
    };
    handleNextClick = () => {
        this.setState({
            hostConfirmLocationClick: true,
        });
    };
    handleTabChange = (event, value) => {
        event.preventDefault();
        if (value === 1) {
            this.importImage();
        }

        // No reason to change the active tab for image uploading until we have separate content for the image uploading
        // tab. This could possibly include a drag and drop target like we use for pins image uploading.
        // this.setState({
        //     activeTab: value,
        // });
    };

    render() {
        const { creditBalance, nearmapApiKey, classes, accessToken } =
            this.props;
        const {
            projectId,
            latitude,
            longitude,
            step,
            showNavigateAwayPrompt,
            hostConfirmLocationClick,
            activeTab,
        } = this.state;
        return (
            <div
                className={classes.root}
                style={{
                    padding: step !== steps.confirmLocation ? 24 : 0,
                }}
            >
                {step === steps.confirmLocation && (
                    <div>
                        <AppBar
                            position='static'
                            color='default'
                            className={classes.appBar}
                        >
                            <Tabs
                                value={activeTab}
                                onChange={this.handleTabChange}
                                indicatorColor='primary'
                                textColor='primary'
                                centered
                                variant='fullWidth'
                            >
                                <Tab
                                    className={classes.ourImageryTab}
                                    label={
                                        <div>
                                            <Typography
                                                className={classes.tabTitle}
                                            >
                                                Select from our imagery options
                                            </Typography>
                                            <Typography variant='caption'>
                                                Choose an image provided with
                                                your subscription or purchase a
                                                premium image.
                                            </Typography>
                                        </div>
                                    }
                                />
                                <Tab
                                    className={classes.importImageryTab}
                                    label={
                                        <div>
                                            <Typography
                                                className={classes.tabTitle}
                                            >
                                                Upload your image
                                            </Typography>
                                            <Typography variant='caption'>
                                                Import an image of blueprints, a
                                                screenshot or a drone image.
                                            </Typography>
                                        </div>
                                    }
                                />
                            </Tabs>
                        </AppBar>
                        <div className={classes.confirmLocationInstructions}>
                            <Grid container direction='column'>
                                <Grid item>
                                    <Typography
                                        className={
                                            classes.confirmLocationInstructionsTitle
                                        }
                                    >
                                        Confirm location and zoom in
                                    </Typography>
                                </Grid>
                                <Grid item container direction='row'>
                                    <Grid xs item>
                                        <Typography>
                                            Position the image so that the
                                            entire roof is centered and
                                            completely zoomed in. When finished,
                                            click next to view available images
                                            for this location.
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            id='preview-imagery-button'
                                            color='primary'
                                            variant='contained'
                                            size='large'
                                            onClick={this.handleNextClick}
                                        >
                                            Next: Preview imagery options
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                )}
                {step === steps.imagerySelection && (
                    <PageToolbarActions>
                        <Typography variant='body1'>
                            Credits Available: {creditBalance}
                        </Typography>
                    </PageToolbarActions>
                )}
                {projectId && (
                    <MapImageryOptions
                        jwt={accessToken}
                        roofSnapApiBaseUrl={`${process.env.REACT_APP_API_HOST}/`}
                        nearmapApiBaseUrl={`${process.env.REACT_APP_NEARMAP_PROXY_HOST}/`}
                        hexagonApiBaseUrl={`${process.env.REACT_APP_HEXAGON_PROXY_HOST}/`}
                        googleMapsApiKey={process.env.REACT_APP_API_MAPS_KEY}
                        latitude={latitude}
                        longitude={longitude}
                        projectId={projectId}
                        onSaveImageComplete={this.handleSaveImageComplete}
                        nearmapApiKey={nearmapApiKey}
                        onError={this.handleError}
                        onStepChange={this.handleStepChange}
                        onImagerySelected={this.handleImagerySelected}
                        hostConfirmLocationClick={hostConfirmLocationClick}
                        hideConfirmLocationButton
                        hideGeocodeMarker
                        rsClient='DeceptiveDolphin'
                        nearmapDisabled={!this.props.canAccessNearmap}
                        address={this.props.address}
                        vexcelDisabled={!featureFlags.vexcelImagery}
                        roofsnapImageryApiBaseUrl={`${process.env.REACT_APP_ROOFSNAP_IMAGERY_HOST}/`}
                        disableParcelLines={!featureFlags.parcelLines}
                        parcelSdkUrl={process.env.REACT_APP_PARCEL_SDK_URL}
                        spatialStreamUrl={
                            process.env.REACT_APP_SPATIAL_STREAM_URL
                        }
                        jwtProxyUrl={process.env.REACT_APP_ROOFSNAP_JWT_PROXY}
                    />
                )}
                <ImageImport
                    imageImportRef={this.refImageImport}
                    callBack={this.uploadRoofImageImport}
                />
                <Prompt
                    when={showNavigateAwayPrompt}
                    message={
                        creditBalance > 0 ? redeemMessage : repurchaseMessage
                    }
                />
            </div>
        );
    }
}

MapImageryOptionsHost.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
        replace: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
        appBar: PropTypes.string.isRequired,
        ourImageryTab: PropTypes.string.isRequired,
        importImageryTab: PropTypes.string.isRequired,
        confirmLocationInstructions: PropTypes.string.isRequired,
        confirmLocationInstructionsTitle: PropTypes.string.isRequired,
        tabTitle: PropTypes.string.isRequired,
    }).isRequired,
    accessToken: PropTypes.string.isRequired,
    nearmapApiKey: PropTypes.string,
    creditBalance: PropTypes.number.isRequired,
    currentProject: PropTypes.shape({
        id: PropTypes.string,
        customerAddress: PropTypes.string,
        version: PropTypes.string,
    }).isRequired,
    projectId: PropTypes.string,
    hasDrawing: PropTypes.bool,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    organizationId: PropTypes.number.isRequired,
};

MapImageryOptionsHost.defaultProps = {
    projectId: null,
    hasDrawing: null,
    latitude: null,
    longitude: null,
    nearmapApiKey: null,
};

const mapStateToProps = (state) => {
    const {
        auth,
        currentUser,
        organizationCredits,
        projectHome,
        currentUserRoles,
        features,
    } = state;
    const { accessToken } = auth;
    const { nearmapApiKey, organizationId } = currentUser;
    const { creditBalance } = organizationCredits;
    const { currentProject } = projectHome;
    const {
        id: projectId,
        hasDrawing,
        mapCenterLat: latitude,
        mapCenterLon: longitude,
        customerAddress,
        customerCity,
        customerState,
        customerZip,
    } = currentProject;
    const { canAccessNearmap } = currentUserRoles;
    const address = `${customerAddress}, ${customerCity}, ${customerState} ${customerZip}`;
    return {
        accessToken,
        nearmapApiKey,
        creditBalance,
        currentProject,
        projectId,
        hasDrawing,
        latitude,
        longitude,
        organizationId,
        canAccessNearmap,
        features,
        address,
    };
};

export default connect(mapStateToProps)(
    withStyles(styles)(MapImageryOptionsHost)
);
