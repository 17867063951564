import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';
import remove from 'lodash/remove';

const style = () => ({
    dialog: {
        minWidth: 300,
    },
    flex: {
        flexGrow: 1,
    },
});

const ChooseMultiEstimateDialog = (props) => {
    const [selectedEstimates, setSelectedEstimates] = useState([]);

    const handleOnListItemClick = (estimateId) => {
        const updateSelectedEstimates = [...selectedEstimates];
        if (updateSelectedEstimates.includes(estimateId)) {
            remove(updateSelectedEstimates, id => id === estimateId);
        } else {
            updateSelectedEstimates.push(estimateId);
        }

        setSelectedEstimates(updateSelectedEstimates);
    };

    const renderEstimate = (estimate) => {
        const isSelected = selectedEstimates.includes(estimate.id);
        return (
            <ListItem
                key={estimate.id}
                button
                onClick={() => handleOnListItemClick(estimate.id)}
            >
                <Checkbox
                    checked={isSelected}
                    tabIndex={-1}
                    disableRipple
                />
                <ListItemText
                    primary={estimate.name}
                />
                <Typography
                    align="right"
                    variant="subtitle1"
                >
                    {
                        estimate.grandTotal
                            ? estimate.grandTotal.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })
                            : '$0.00'
                    }
                </Typography>
            </ListItem>
        );
    };

    const handleOnConfirmClick = () => {
        props.onConfirmClick(selectedEstimates);
        setSelectedEstimates([]); // Clear selected estimates
    };

    return (
        <Dialog
            classes={{
                paper: props.classes.dialog,
            }}
            fullScreen={props.fullScreen}
            open={props.open}
        >
            <DialogTitle>
                Choose Estimate Options
            </DialogTitle>
            <DialogContent>
                <Typography gutterBottom>Select at least one estimate option.</Typography>
                <List>
                    {props.estimates.map(renderEstimate)}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onDismissClick}>
                    Back
                </Button>
                <Button onClick={handleOnConfirmClick} disabled={selectedEstimates.length === 0}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ChooseMultiEstimateDialog.propTypes = {
    estimates: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        grandTotal: PropTypes.number.isRequired,
    })).isRequired,
    onDismissClick: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool,
};

ChooseMultiEstimateDialog.defaultProps = {
    open: false,
};

export default withStyles(style)(withMobileDialog()(ChooseMultiEstimateDialog));
