import {
    GET_CURRENT_USER_ROLES_FAILURE, GET_CURRENT_USER_ROLES_FAILURE_BACKGROUND,
    GET_CURRENT_USER_ROLES_REQUEST,
    GET_CURRENT_USER_ROLES_REQUEST_BACKGROUND,
    GET_CURRENT_USER_ROLES_SUCCESS,
    GET_CURRENT_USER_ROLES_SUCCESS_BACKGROUND,
} from '../actions/UserRoles';
import UserRoles from '../lib/UserRoles';
import userRolesHelper from '../lib/UserRolesHelper';

const initialState = {
    isFetching: false,
    isAdmin: false,
    isAccountAdmin: false,
    canViewSummaryReports: false,
    sketchosEnabled: false,
    canAccessNearmap: false,
    isSketchTech: false,
    isSketchAdmin: false,
    estimatesDisabled: false,
};

function currentUserRoles(
    state = initialState,
    action,
) {
    switch (action.type) {
    case GET_CURRENT_USER_ROLES_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case GET_CURRENT_USER_ROLES_REQUEST_BACKGROUND:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case GET_CURRENT_USER_ROLES_SUCCESS:
    case GET_CURRENT_USER_ROLES_SUCCESS_BACKGROUND:
        return Object.assign({}, state, {
            isAdmin: userRolesHelper(action.response, UserRoles.Admin),
            isAccountAdmin: userRolesHelper(action.response, UserRoles.AccountAdmin),
            canViewSummaryReports: !userRolesHelper(action.response, UserRoles.HideSummaryReports),
            sketchosEnabled: userRolesHelper(action.response, UserRoles.SketchosEnabled) ||
                userRolesHelper(action.response, UserRoles.SketchosAdmin) ||
                userRolesHelper(action.response, UserRoles.SketchTech),
            isSketchTech: userRolesHelper(action.response, UserRoles.SketchTech),
            isSketchAdmin: userRolesHelper(action.response, UserRoles.SketchosAdmin),
            canAccessNearmap: !userRolesHelper(action.response, UserRoles.NearmapDisabled),
            estimatesDisabled: userRolesHelper(action.response, UserRoles.EstimatesDisabled),
            isFetching: false,
        });
    case GET_CURRENT_USER_ROLES_FAILURE:
    case GET_CURRENT_USER_ROLES_FAILURE_BACKGROUND:
        return initialState;
    default:
        return state;
    }
}

export default currentUserRoles;
