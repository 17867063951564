import {
    GET_PRICING_DATA_REQUEST,
    GET_PRICING_DATA_SUCCESS,
    GET_PRICING_DATA_FAILURE,
    GET_PRICING_DATA_REQUEST_BACKGROUND,
    GET_PRICING_DATA_SUCCESS_BACKGROUND,
    GET_PRICING_DATA_FAILURE_BACKGROUND,
} from '../actions/SketchOrderPricingActions';

const initialState = {
    isFetching: false,
    halfSnapPriceGrid: [
        {
            label: 'An outline of the roof with the predominant pitch',
            subLabel: 'One flat rate',
            tierPrices: [
                {
                    price: 12.0,
                    label: '',
                },
            ],
        },
    ],
    fullSnapPriceGrid: [
        {
            label: 'Up to 80 squares',
            subLabel: 'Most residential roofs',
            tierPrices: [
                {
                    price: 30.0,
                    label: '< 80 sq',
                },
            ],
        },
        {
            label: 'Over 80 squares',
            subLabel: 'Large residential & commercial buildings',
            tierPrices: [
                {
                    price: 30.0,
                    label: '1 - 15 facets',
                },
                {
                    price: 50.0,
                    label: '> 16 facets',
                },
            ],
        },
    ],
    metalRoofPriceGrid: [
        {
            label: '',
            subLabel: '',
            tierPrices: [
                {
                    price: 40.0,
                    label: '1 - 5 facets',
                },
                {
                    price: 50.0,
                    label: '6 - 10 facets',
                },
                {
                    price: 60.0,
                    label: '11 - 15 facets',
                },
                {
                    price: 72.0,
                    label: '16 - 25 facets',
                },
                {
                    price: 85.0,
                    label: '26 - 40 facets',
                },
                {
                    price: 100.0,
                    label: '41 + facets',
                },
            ],
        },
    ],
    gutterReportPriceGrid: [
        {
            label: '',
            subLabel: '',
            tierPrices: [
                {
                    price: 10.0,
                    label: 'Any size',
                },
            ],
        },
    ],
};

const sketchOrderPricingReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_PRICING_DATA_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case GET_PRICING_DATA_REQUEST_BACKGROUND:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case GET_PRICING_DATA_SUCCESS:
    case GET_PRICING_DATA_SUCCESS_BACKGROUND:
        return Object.assign({}, state, {
            isFetching: false,
            halfSnapPriceGrid: action.response.halfSnapPriceGrid,
            fullSnapPriceGrid: action.response.fullSnapPriceGrid,
            metalRoofPriceGrid: action.response.metalRoofPriceGrid,
            gutterReportPriceGrid: action.response.guttersPriceGrid,
        });
    case GET_PRICING_DATA_FAILURE:
    case GET_PRICING_DATA_FAILURE_BACKGROUND:
        return Object.assign({}, state, {
            isFetching: false,
            halfSnapPriceGrid: initialState.halfSnapPriceGrid,
            fullSnapPriceGrid: initialState.fullSnapPriceGrid,
        });
    default:
        return state;
    }
};

export default sketchOrderPricingReducer;
