import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    Box,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    styled,
} from '@mui/material';
import React from 'react';
import { ReactComponent as EllipseSvg } from '../../../@ui/Icons/Ellipse.svg';
import Typography from '../../../@ui/Typography';
import ProjectStatus from '../../../lib/Models/ProjectStatus';

const ProjectStatusDropdown = styled(Select)({
    minWidth: 129,
    height: 32,
    backgroundColor: 'white',
    borderRadius: '8px',
    border: '1px solid #CCC',
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #222',
    },
});
interface ProjectTitleBarProps {
    title: string;
    status: string;
    onStatusChange: (value: keyof ProjectStatus) => void;
}
const colorCodes: { [key: string]: string } = {
    NewLead: '#056CF2',
    InspectionScheduled: '#F8D849',
    Inspected: '#E58F35',
    AdjustmentScheduled: '#E58F35',
    Approved: '#38BF41',
    ReadyToBuild: '#DEAA17',
    PunchOut: '#969696',
    WorkCompleted: '#38BF41',
    Dead: '#B3261E',
    Closed: '#B3261E',
};
const Ellipse = styled(EllipseSvg)<{ status: string }>(({ status }) => ({
    width: 8,
    height: 8,
    fill: colorCodes[status],
}));
const ProjectTitleBar: React.FC<ProjectTitleBarProps> = ({
    title,
    status,
    onStatusChange,
}) => {
    const [selectedOption, setSelectedOption] = React.useState<string>(status);
    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const value: string = event.target.value as string;
        setSelectedOption(value);
        onStatusChange(value as keyof ProjectStatus);
    };
    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            width='100%'
            position='relative'
        >
            <ProjectStatusDropdown
                value={selectedOption}
                onChange={handleChange}
                IconComponent={KeyboardArrowDownIcon}
            >
                {Object.entries(ProjectStatus)
                    .filter(([key, value]) => key !== 'Deleted')
                    .map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                            <Stack direction='row' alignItems='center' mt='3px'>
                                <Ellipse status={key} />
                                <Typography
                                    variant='title-small'
                                    sx={{
                                        paddingLeft: '8px',
                                        paddingRight: '10px',
                                    }}
                                >
                                    {value}
                                </Typography>
                            </Stack>
                        </MenuItem>
                    ))}
            </ProjectStatusDropdown>

            <Typography
                variant='title-medium'
                sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                {title}
            </Typography>
        </Box>
    );
};

export default ProjectTitleBar;
