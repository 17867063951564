export enum SketchOrderStatus {
    Cancelled = 'Cancelled',
    Complete = 'Complete',
    Pending = 'Pending',
    InProgress = 'InProgress',
    Incomplete = 'Incomplete',
    PendingReview = 'PendingReview',
    Billed = 'Billed',
    PendingRejection = 'PendingRejection',
    Queued = 'Queued',
    Exception = 'Exception',
    BillingFailed = 'BillingFailed',
    InReview = 'InReview',
    RevisionRequested = 'RevisionRequested',
    PendingGroupBilling = 'PendingGroupBilling',
}

export const SketchOrderTypeStatus: { [key: string]: SketchOrderStatus } = {
    Pending: SketchOrderStatus.Pending,
    InProgress: SketchOrderStatus.InProgress,
    Billed: SketchOrderStatus.Billed,
    BillingFailed: SketchOrderStatus.BillingFailed,
    RevisionRequested: SketchOrderStatus.RevisionRequested,
    Cancelled: SketchOrderStatus.Cancelled,
    Complete: SketchOrderStatus.Complete,
};

export const SketchOrderStatusMap = {
    All: [],
    Cancelled: [SketchOrderStatus.Cancelled],
    Pending: [
        SketchOrderStatus.Pending,
        SketchOrderStatus.Queued,
        SketchOrderStatus.InProgress,
        SketchOrderStatus.PendingRejection,
        SketchOrderStatus.Exception,
        SketchOrderStatus.PendingReview,
        SketchOrderStatus.InReview,
        SketchOrderStatus.PendingGroupBilling,
    ],
    RevisionRequested: [SketchOrderStatus.RevisionRequested],
    Incomplete: [SketchOrderStatus.Incomplete],
    Complete: [SketchOrderStatus.Complete],
    Billed: [SketchOrderStatus.Billed],
    'Billing Failed': [SketchOrderStatus.BillingFailed],
};

export type SketchOrderStatusType = `${SketchOrderStatus}`;

export default SketchOrderStatus;
