import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Create from '@material-ui/icons/Create';
import DeleteIconComponent from '../DeleteIconComponent';
import Pager from '../Pager/Pager.tsx';

const ProjectEstimateListStyle = {
    paperStyle: {
        width: '100%',
        maxWidth: 1350,
        margin: 'auto',
    },
    projectEstimateListHeaderStyle: {
        padding: 8,
        borderBottom: 'solid 1px #dbdbdb',
    },
    projectEstimateListHeaderTitleStyle: {
        padding: 12,
        margin: 0,
        fontWeight: 300,
    },
    estimateListItemStyle: {
        borderBottom: 'solid 1px #dbdbdb',
    },
    estimateListTitle: {
        paddingRight: 200,
    },
};

class ProjectEstimateList extends Component {
    renderProjectEstimate = estimate => (
        <ListItem
            key={estimate.id}
            button
            style={ProjectEstimateListStyle.estimateListItemStyle}
            onClick={() => this.props.onEstimateItemClick(estimate.id)}
        >
            <ListItemText
                style={ProjectEstimateListStyle.estimateListTitle}
                primary={estimate.name}
            />
            <ListItemSecondaryAction>
                <Typography component="span" style={{ display: 'inline' }}>
                    {estimate.grandTotal
                        ? estimate.grandTotal.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })
                        : '$0.00'}
                </Typography>
                <IconButton
                    onClick={() =>
                        this.props.onEditEstimate(
                            estimate.projectId,
                            estimate.id,
                            estimate,
                        )
                    }
                >
                    <Create />
                </IconButton>
                <DeleteIconComponent
                    deleteCallBack={() =>
                        this.props.onDeleteEstimate(
                            estimate.projectId,
                            estimate.id,
                            estimate.name,
                        )
                    }
                />
            </ListItemSecondaryAction>
        </ListItem>
    );

    render() {
        return (
            <div>
                <Paper style={ProjectEstimateListStyle.paperStyle}>
                    <div
                        style={
                            ProjectEstimateListStyle.projectEstimateListHeaderStyle
                        }
                    >
                        <Typography
                            component="h2"
                            variant="h5"
                            style={
                                ProjectEstimateListStyle.projectEstimateListHeaderTitleStyle
                            }
                        >
                            {this.props.totalResults} Estimate(s)
                        </Typography>
                    </div>
                    <List id="projectEstimatesList">
                        {this.props.estimates.map(this.renderProjectEstimate)}
                    </List>
                    <Pager
                        totalPages={this.props.totalPages}
                        currentPage={this.props.currentPage}
                        nextClick={this.props.nextEstimatesPageClick}
                        prevClick={this.props.prevEstimatesPageClick}
                    />
                </Paper>
            </div>
        );
    }
}

const projectEstimateProps = {
    id: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    templateId: PropTypes.string,
    subTotal: PropTypes.number.isRequired,
    taxes: PropTypes.number.isRequired,
    grandTotal: PropTypes.number.isRequired,
    discount: PropTypes.number.isRequired,
    isChosen: PropTypes.bool.isRequired,
    version: PropTypes.string.isRequired,
};

ProjectEstimateList.propTypes = {
    estimates: PropTypes.arrayOf(PropTypes.shape(projectEstimateProps))
        .isRequired,
    totalResults: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    nextEstimatesPageClick: PropTypes.func.isRequired,
    prevEstimatesPageClick: PropTypes.func.isRequired,
    onDeleteEstimate: PropTypes.func.isRequired,
    onEditEstimate: PropTypes.func.isRequired,
    onEstimateItemClick: PropTypes.func.isRequired,
    /* eslint-disable react/no-unused-prop-types */
    isFetching: PropTypes.bool,
};

ProjectEstimateList.defaultProps = {
    isFetching: false,
};

export default ProjectEstimateList;
