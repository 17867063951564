import { CALL_API, httpMethod } from '../middleware/api';
import SketchOrderStatus from '../lib/SketchOrderStatus.ts';

export const GET_ORGANIZATIONS_BILLING_FAILED_SKETCH_ORDERS_REQUEST = 'GET_BILLING_FAILED_SKETCH_ORDERS_REQUEST';
export const GET_ORGANIZATIONS_BILLING_FAILED_SKETCH_ORDERS_SUCCESS = 'GET_BILLING_FAILED_SKETCH_ORDERS_SUCCESS';
export const GET_ORGANIZATIONS_BILLING_FAILED_SKETCH_ORDERS_FAILURE = 'GET_BILLING_FAILED_SKETCH_ORDERS_FAILURE';

export const getBillingFailedSketchOrdersByOrganization = (
    organizationId,
    page = 1,
) => ({
    [CALL_API]: {
        types: [
            GET_ORGANIZATIONS_BILLING_FAILED_SKETCH_ORDERS_REQUEST,
            GET_ORGANIZATIONS_BILLING_FAILED_SKETCH_ORDERS_SUCCESS,
            GET_ORGANIZATIONS_BILLING_FAILED_SKETCH_ORDERS_FAILURE,
        ],
        endpoint: `organizations/${organizationId}/sketchorders?page=${page}&status=${SketchOrderStatus.BillingFailed}`,
        method: httpMethod.GET,
    },
});

export const GET_REQUESTED_OR_OWNED_BILLING_FAILED_SKETCH_ORDERS_REQUEST = 'GET_REQUESTED_OR_OWNED_BILLING_FAILED_SKETCH_ORDERS_REQUEST';
export const GET_REQUESTED_OR_OWNED_BILLING_FAILED_SKETCH_ORDERS_SUCCESS = 'GET_REQUESTED_OR_OWNED_BILLING_FAILED_SKETCH_ORDERS_SUCCESS';
export const GET_REQUESTED_OR_OWNED_BILLING_FAILED_SKETCH_ORDERS_FAILURE = 'GET_REQUESTED_OR_OWNED_BILLING_FAILED_SKETCH_ORDERS_FAILURE';

export const getRequestedOrOwnedBillingFailedSketchOrders = (userId, page = 1) => ({
    [CALL_API]: {
        types: [
            GET_REQUESTED_OR_OWNED_BILLING_FAILED_SKETCH_ORDERS_REQUEST,
            GET_REQUESTED_OR_OWNED_BILLING_FAILED_SKETCH_ORDERS_SUCCESS,
            GET_REQUESTED_OR_OWNED_BILLING_FAILED_SKETCH_ORDERS_FAILURE,
        ],
        endpoint: `userprofiles/${userId}/requestedorownedsketchorders?page=${page}&status=${SketchOrderStatus.BillingFailed}`,
        method: httpMethod.GET,
    },
});
