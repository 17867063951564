import {
  Grid,
  Typography,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { DataTable } from 'components/DataTable';
import FilterSelector from 'components/FilterSelector';
import Pager from 'components/Pager';
import {
  BraintreeTransactionStatusMapKey,
  BraintreeTransactionStatusMap,
  BraintreeTransactionStatus,
} from 'lib';
import SubscriptionTransaction from 'lib/Models/SubscriptionTransaction';
import { MicroServiceClient } from 'lib/apiClients/microServiceClient';
import { arrayToQueryString } from 'lib/util/ArrayUtilities';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SubscriptionRow from './SubscriptionRow';
import LoggerWrapper from 'lib/Logger';
import { DateTime } from 'luxon';

const styles = createStyles({
  orderType: {
    color: '#222222',
    fontSize: 18,
  },
  rowText: {
    color: '#000000DE',
    fontSize: 14,
  },
  headerText: {
    color: '#999999',
    fontSize: 13,
    textTransform: 'uppercase',
  },
});

type PartialState = {
  organization: { id: number; subscriptionId: string, subscriptionExpires: string, subscriptionExpired: boolean };
};

const getBillingApi = (organizationId: number) =>
  MicroServiceClient('billing', 1, `Orgs/${organizationId}`);

type SubscriptionBillingProps = {
  requestIsAccountPastDue?: (pastDue: boolean) => void;
} & WithStyles<typeof styles>;

const SubscriptionBilling = ({ classes, requestIsAccountPastDue }: SubscriptionBillingProps) => {
  const {
    organization: { id: organizationId, subscriptionId, subscriptionExpires, subscriptionExpired },
  } = useSelector((state: PartialState) => state);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionIsPastDue, setSubscriptionIsPastDue] = useState<boolean>(false)
  const [transactions, setTransactions] = useState<SubscriptionTransaction[]>(
    []
  );

  const [status, setStatus] = useState<{
    key: BraintreeTransactionStatusMapKey;
    values: string[];
  }>({ key: 'All', values: [] });

  const handleStatusChange = (statusKey: BraintreeTransactionStatusMapKey) => {
    setStatus({
      key: statusKey,
      values: BraintreeTransactionStatusMap[statusKey],
    });

    setPage(1);
  };

  const failedTransactionStatuses = [
    BraintreeTransactionStatus.AuthorizationExpired,
    BraintreeTransactionStatus.Failed,
    BraintreeTransactionStatus.GatewayRejected,
    BraintreeTransactionStatus.ProcessorDeclined,
    BraintreeTransactionStatus.SettlementDeclined,
    BraintreeTransactionStatus.Unrecognized,
  ]

  const isSubscriptionPastDue: () => boolean = () => {
    if (!transactions || transactions.length === 0) return false; // if they have no transactions they can't be past due
    const now = DateTime.now();
    const expirationDate = DateTime.fromISO(subscriptionExpires);
    const gracePeriodStart = expirationDate.minus({ days: 3 })
    const mostRecentBillingDate = DateTime.fromISO(transactions[0]?.date?.toString())
    const mostRecentBillingStatus = transactions[0]?.status?.toString();
    const pastDue = (!subscriptionExpired 
      && gracePeriodStart < now // they are in the "grace period" before their subscription expires for failed billing
      && mostRecentBillingDate >= gracePeriodStart // their most recent failed billing attempt occurred after the grace period started
      && (mostRecentBillingStatus ? failedTransactionStatuses.includes(mostRecentBillingStatus) : false)) // their most recent billing transaction exists and was a failed one
    return pastDue;
  }

  useEffect(() => {
    setIsLoading(true);

    const getTransactions = async () => {
      const statusQuery = arrayToQueryString(status.values, 'statuses', false);

      const data: any = await getBillingApi(organizationId).get(
        `SubscriptionTransactions?page=${page}${statusQuery}`
      );
      setTransactions(data.resourceList as SubscriptionTransaction[]);
      setSubscriptionIsPastDue(isSubscriptionPastDue())
      setTotalPages(data.totalPages);
      setIsLoading(false);
    };

    getTransactions().catch((e) => {
      LoggerWrapper.captureException(e);
      setIsLoading(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, organizationId, status]);

  useEffect(() => {
    if (requestIsAccountPastDue && transactions) {
      setSubscriptionIsPastDue(isSubscriptionPastDue());
      requestIsAccountPastDue(subscriptionIsPastDue);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions, subscriptionIsPastDue, requestIsAccountPastDue])

  const columnHeaders = [
    { title: 'Date', width: 3 },
    { title: 'Item', width: 3 },
    { title: 'Amount', width: 2 },
    { title: 'Order Number', width: 2 },
    { title: 'Status', width: 2 },
  ];

  return (
    <>
      {/* TODO: Replace this loading thing with a spinner maybe? */}
      {isLoading && <Typography>Loading ...</Typography>}
      {!isLoading && (
        <Grid container xs={12}>
          <Grid item xs={12}>
            <FilterSelector
              keyValueMap={BraintreeTransactionStatusMap}
              selectedKey={status.key}
              onChange={handleStatusChange}
              sx={{ width: '10em', marginLeft: 'auto' }}
            />
          </Grid>
          <Grid item xs={12}>
            <DataTable
              columnHeaders={columnHeaders}
              headerStyle={classes.headerText}
              actionable={false}
            >
              {transactions.map((transaction) => (
                <SubscriptionRow
                  transaction={transaction}
                  subscriptionId={subscriptionId}
                />
              ))}
            </DataTable>
            <Pager
              totalPages={totalPages}
              currentPage={page}
              nextClick={() => {
                setPage(page + 1);
              }}
              prevClick={() => {
                setPage(page - 1);
              }}
              outerStyle={{}}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withStyles(styles)(SubscriptionBilling);
