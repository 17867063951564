import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";

const style = {
  icons: {
    verticalAlign: "middle",
  },
  text: {
    display: "inline",
  },
};

type PagerProps = {
  totalPages: number;
  currentPage: number;
  nextClick: () => void;
  prevClick: () => void;
  outerStyle?: React.CSSProperties | undefined;
};

const Pager = ({
  totalPages = 1,
  currentPage = 1,
  nextClick,
  prevClick,
  outerStyle = {},
}: PagerProps) => {
  return totalPages > 1 ? (
    <div style={outerStyle}>
      <IconButton
        onClick={prevClick}
        disabled={currentPage <= 1}
        style={style.icons}
      >
        <ChevronLeft />
      </IconButton>
      <Typography style={style.text}>
        page {currentPage} of {totalPages}
      </Typography>
      <IconButton
        onClick={nextClick}
        disabled={currentPage >= totalPages}
        style={style.icons}
      >
        <ChevronRight />
      </IconButton>
    </div>
  ) : null;
};

export default Pager;
