import {
    CREATE_NEW_USER_SUCCESS,
    CREATE_NEW_USER_FAILURE,
    COMPLETE_NEW_USER_REQUEST,
    COMPLETE_NEW_USER_SUCCESS,
    COMPLETE_NEW_USER_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
} from '../actions/User';

function user(
    state = {
        userName: '',
        temporaryPassword: '',
        firstName: '',
        lastName: '',
        contactNumber: '',
        businessName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        region: '',
        zip: '',
        country: '',
        isFetching: false,
        isFetchingText: '',
    },
    action,
) {
    switch (action.type) {
    case (CREATE_NEW_USER_SUCCESS):
        return Object.assign({}, state, {
            userName: action.response.username,
            temporaryPassword: action.response.password,
            isFetching: false,
            isFetchingText: '',
        });
    case (CREATE_NEW_USER_FAILURE):
        return Object.assign({}, state, {
            userName: '',
            temporaryPassword: '',
            isFetching: false,
            isFetchingText: '',
        });
    case (COMPLETE_NEW_USER_REQUEST):
        return Object.assign({}, state, {
            isFetching: true,
            isFetchingText: '',
        });
    case (COMPLETE_NEW_USER_SUCCESS):
        return Object.assign({}, state, {
            firstName: action.response.firstName,
            lastName: action.response.lastName,
            contactNumber: action.response.contactNumber,
            businessName: action.response.businessName,
            addressLine1: action.response.addressLine1,
            addressLine2: action.response.addressLine2,
            city: action.response.city,
            region: action.response.region,
            zip: action.response.zip,
            country: action.response.country,
            isFetching: false,
            isFetchingText: '',
        });
    case (COMPLETE_NEW_USER_FAILURE):
        return Object.assign({}, state, {
            firstName: '',
            lastName: '',
            contactNumber: '',
            businessName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            region: '',
            zip: '',
            country: '',
            isFetching: false,
            isFetchingText: '',
        });
    case CHANGE_PASSWORD_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
            isFetchingText: 'Completing step two!',
        });
    case CHANGE_PASSWORD_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            isFetchingText: '',
        });
    case CHANGE_PASSWORD_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            isFetchingText: '',
        });
    default:
        return state;
    }
}

export default user;
