import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const SectionInfoTitle = styled(Typography)({
    color: '#1D1B20',
    fontFamily: 'Roboto',
    fontSize: '1.375rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.75rem' /* 150% */,
});

export default SectionInfoTitle;
