import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _filter from 'lodash/filter';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import { connect } from 'react-redux';
import { completeNewUser } from '../../actions/User';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';

const styles = {
    root: {
        padding: 24,
    },
    card: {
        minWidth: 275,
        maxWidth: '700px',
        margin: '0 auto',
        padding: '50px 20px 20px',
    },
    button: {
        width: '100%',
    },
    formControl: {
        margin: '16px 0 8px',
    },
};

class ConfirmAccountInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            businessName: this.props.businessName,
            contactNumber: this.props.contactNumber,
            addressLine1: this.props.addressLine1,
            addressLine2: this.props.addressLine2,
            city: this.props.city,
            region: this.props.region,
            zip: this.props.zip,
            country: 'United States',
            completeButtonHasBeenPressed: false,
        };
    }

    confirm = async () => {
        const completeButtonHasBeenPressed = true;
        const incompleteItems = _filter(this.listOfAccountInformationItems(), item => item.state === '' && item.id !== 'addressLine2');
        if (incompleteItems.length > 0) {
            this.setState({ completeButtonHasBeenPressed });
            return;
        }

        this.setState({ accountInformationFromUser: this.state });
        const result = await this.props.dispatch(completeNewUser(this.state, this.props.userId));
        if (result.error) {
            this.props.dispatch(setReduxMessage(
                'There was an error confirming your account information. Try again or contact support.',
                <Button
                    onClick={() => {
                        this.window.open(`${process.env.REACT_APP_SUBMIT_SUPPORT_REQUEST_URL}`, '_blank');
                    }}
                    color="secondary"
                    variant="contained"
                    size="small"
                >
                    Contact Support
                </Button>,
            ));
            return;
        }

        this.props.history.push('/account/confirm/complete');
    };

    handleChange = name => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleKeyPressed = (event) => {
        if (event.charCode === 13) {
            this.confirm();
        }
    };

    listOfAccountInformationItems = () => ([
        {
            id: 'firstName',
            label: 'First Name',
            state: this.state.firstName,
        },
        {
            id: 'lastName',
            label: 'Last Name',
            state: this.state.lastName,
        },
        {
            id: 'businessName',
            label: 'Business Name',
            state: this.state.businessName,
        },
        {
            id: 'contactNumber',
            label: 'Contact Number',
            state: this.state.contactNumber,
        },
        {
            id: 'addressLine1',
            label: 'Address Line 1',
            state: this.state.addressLine1,
        },
        {
            id: 'addressLine2',
            label: 'Address Line 2',
            state: this.state.addressLine2,
        },
        {
            id: 'city',
            label: 'City',
            state: this.state.city,
        },
        {
            id: 'region',
            label: 'State',
            state: this.state.region,
        },
        {
            id: 'zip',
            label: 'Zip',
            state: this.state.zip,
        },
    ]);

    generateTextField = (id, label, completedWhileBlank = false) => (<TextField
        key={`${id}Key`}
        id={`${id}Id`}
        label={`${label}${completedWhileBlank ? ' is required' : ''}`}
        value={this.state[id]}
        onChange={this.handleChange(id)}
        margin="normal"
        fullWidth
        error={completedWhileBlank}
        onKeyPress={this.handleKeyPressed}
    />);

    render() {
        const items = this.listOfAccountInformationItems();
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Card className={classes.card}>
                    <CardHeader
                        title="Confirm Account Information"
                    />
                    <CardContent>
                        <form noValidate autoComplete="off">
                            {items.map(item => this.generateTextField(item.id, item.label, this.state.completeButtonHasBeenPressed && item.state === '' && item.id !== 'addressLine2'))}
                            <FormControl
                                className={classes.formControl}
                                fullWidth
                            >
                                <InputLabel htmlFor="country">Country</InputLabel>
                                <Select
                                    open={this.state.open}
                                    value={this.state.country}
                                    onChange={this.handleChange('country')}
                                    inputProps={{
                                        name: 'country',
                                        id: 'country',
                                    }}
                                >
                                    <MenuItem value="Åland Islands">Åland Islands</MenuItem>
                                    <MenuItem value="Albania">Albania</MenuItem>
                                    <MenuItem value="Algeria">Algeria</MenuItem>
                                    <MenuItem value="American Samoa">American Samoa</MenuItem>
                                    <MenuItem value="Andorra">Andorra</MenuItem>
                                    <MenuItem value="Angola">Angola</MenuItem>
                                    <MenuItem value="Anguilla">Anguilla</MenuItem>
                                    <MenuItem value="Antarctica">Antarctica</MenuItem>
                                    <MenuItem value="Antigua and Barbuda">Antigua and Barbuda</MenuItem>
                                    <MenuItem value="Argentina">Argentina</MenuItem>
                                    <MenuItem value="Armenia">Armenia</MenuItem>
                                    <MenuItem value="Aruba">Aruba</MenuItem>
                                    <MenuItem value="Australia">Australia</MenuItem>
                                    <MenuItem value="Austria">Austria</MenuItem>
                                    <MenuItem value="Azerbaijan">Azerbaijan</MenuItem>
                                    <MenuItem value="Bahamas">Bahamas</MenuItem>
                                    <MenuItem value="Bahrain">Bahrain</MenuItem>
                                    <MenuItem value="Bangladesh">Bangladesh</MenuItem>
                                    <MenuItem value="Barbados">Barbados</MenuItem>
                                    <MenuItem value="Belarus">Belarus</MenuItem>
                                    <MenuItem value="Belgium">Belgium</MenuItem>
                                    <MenuItem value="Belize">Belize</MenuItem>
                                    <MenuItem value="Benin">Benin</MenuItem>
                                    <MenuItem value="Bermuda">Bermuda</MenuItem>
                                    <MenuItem value="Bhutan">Bhutan</MenuItem>
                                    <MenuItem value="Bolivia">Bolivia</MenuItem>
                                    <MenuItem value="Bosnia and Herzegovina">Bosnia and Herzegovina</MenuItem>
                                    <MenuItem value="Botswana">Botswana</MenuItem>
                                    <MenuItem value="Bouvet Island">Bouvet Island</MenuItem>
                                    <MenuItem value="Brazil">Brazil</MenuItem>
                                    <MenuItem value="British Indian Ocean Territory">British Indian Ocean Territory</MenuItem>
                                    <MenuItem value="Brunei Darussalam">Brunei Darussalam</MenuItem>
                                    <MenuItem value="Bulgaria">Bulgaria</MenuItem>
                                    <MenuItem value="Burkina Faso">Burkina Faso</MenuItem>
                                    <MenuItem value="Burundi">Burundi</MenuItem>
                                    <MenuItem value="Cambodia">Cambodia</MenuItem>
                                    <MenuItem value="Cameroon">Cameroon</MenuItem>
                                    <MenuItem value="Canada">Canada</MenuItem>
                                    <MenuItem value="Cape Verde">Cape Verde</MenuItem>
                                    <MenuItem value="Cayman Islands">Cayman Islands</MenuItem>
                                    <MenuItem value="Central African Republic">Central African Republic</MenuItem>
                                    <MenuItem value="Chad">Chad</MenuItem>
                                    <MenuItem value="Chile">Chile</MenuItem>
                                    <MenuItem value="China">China</MenuItem>
                                    <MenuItem value="Christmas Island">Christmas Island</MenuItem>
                                    <MenuItem value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</MenuItem>
                                    <MenuItem value="Colombia">Colombia</MenuItem>
                                    <MenuItem value="Comoros">Comoros</MenuItem>
                                    <MenuItem value="Congo">Congo</MenuItem>
                                    <MenuItem value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</MenuItem>
                                    <MenuItem value="Cook Islands">Cook Islands</MenuItem>
                                    <MenuItem value="Costa Rica">Costa Rica</MenuItem>
                                    <MenuItem value="Cote D'ivoire">Cote D&#39;ivoire</MenuItem>
                                    <MenuItem value="Croatia">Croatia</MenuItem>
                                    <MenuItem value="Cuba">Cuba</MenuItem>
                                    <MenuItem value="Cyprus">Cyprus</MenuItem>
                                    <MenuItem value="Czech Republic">Czech Republic</MenuItem>
                                    <MenuItem value="Denmark">Denmark</MenuItem>
                                    <MenuItem value="Djibouti">Djibouti</MenuItem>
                                    <MenuItem value="Dominica">Dominica</MenuItem>
                                    <MenuItem value="Dominican Republic">Dominican Republic</MenuItem>
                                    <MenuItem value="Ecuador">Ecuador</MenuItem>
                                    <MenuItem value="Egypt">Egypt</MenuItem>
                                    <MenuItem value="El Salvador">El Salvador</MenuItem>
                                    <MenuItem value="Equatorial Guinea">Equatorial Guinea</MenuItem>
                                    <MenuItem value="Eritrea">Eritrea</MenuItem>
                                    <MenuItem value="Estonia">Estonia</MenuItem>
                                    <MenuItem value="Ethiopia">Ethiopia</MenuItem>
                                    <MenuItem value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</MenuItem>
                                    <MenuItem value="Faroe Islands">Faroe Islands</MenuItem>
                                    <MenuItem value="Fiji">Fiji</MenuItem>
                                    <MenuItem value="Finland">Finland</MenuItem>
                                    <MenuItem value="France">France</MenuItem>
                                    <MenuItem value="French Guiana">French Guiana</MenuItem>
                                    <MenuItem value="French Polynesia">French Polynesia</MenuItem>
                                    <MenuItem value="French Southern Territories">French Southern Territories</MenuItem>
                                    <MenuItem value="Gabon">Gabon</MenuItem>
                                    <MenuItem value="Gambia">Gambia</MenuItem>
                                    <MenuItem value="Georgia">Georgia</MenuItem>
                                    <MenuItem value="Germany">Germany</MenuItem>
                                    <MenuItem value="Ghana">Ghana</MenuItem>
                                    <MenuItem value="Gibraltar">Gibraltar</MenuItem>
                                    <MenuItem value="Greece">Greece</MenuItem>
                                    <MenuItem value="Greenland">Greenland</MenuItem>
                                    <MenuItem value="Grenada">Grenada</MenuItem>
                                    <MenuItem value="Guadeloupe">Guadeloupe</MenuItem>
                                    <MenuItem value="Guam">Guam</MenuItem>
                                    <MenuItem value="Guatemala">Guatemala</MenuItem>
                                    <MenuItem value="Guernsey">Guernsey</MenuItem>
                                    <MenuItem value="Guinea">Guinea</MenuItem>
                                    <MenuItem value="Guinea-bissau">Guinea-bissau</MenuItem>
                                    <MenuItem value="Guyana">Guyana</MenuItem>
                                    <MenuItem value="Haiti">Haiti</MenuItem>
                                    <MenuItem value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</MenuItem>
                                    <MenuItem value="Holy See (Vatican City State)">Holy See (Vatican City State)</MenuItem>
                                    <MenuItem value="Honduras">Honduras</MenuItem>
                                    <MenuItem value="Hong Kong">Hong Kong</MenuItem>
                                    <MenuItem value="Hungary">Hungary</MenuItem>
                                    <MenuItem value="Iceland">Iceland</MenuItem>
                                    <MenuItem value="India">India</MenuItem>
                                    <MenuItem value="Indonesia">Indonesia</MenuItem>
                                    <MenuItem value="Iran, Islamic Republic of">Iran, Islamic Republic of</MenuItem>
                                    <MenuItem value="Iraq">Iraq</MenuItem>
                                    <MenuItem value="Ireland">Ireland</MenuItem>
                                    <MenuItem value="Isle of Man">Isle of Man</MenuItem>
                                    <MenuItem value="Israel">Israel</MenuItem>
                                    <MenuItem value="Italy">Italy</MenuItem>
                                    <MenuItem value="Jamaica">Jamaica</MenuItem>
                                    <MenuItem value="Japan">Japan</MenuItem>
                                    <MenuItem value="Jersey">Jersey</MenuItem>
                                    <MenuItem value="Jordan">Jordan</MenuItem>
                                    <MenuItem value="Kazakhstan">Kazakhstan</MenuItem>
                                    <MenuItem value="Kenya">Kenya</MenuItem>
                                    <MenuItem value="Kiribati">Kiribati</MenuItem>
                                    <MenuItem value="Korea, Democratic People's Republic of">Korea, Democratic People&#39;s Republic of</MenuItem>
                                    <MenuItem value="Korea, Republic of">Korea, Republic of</MenuItem>
                                    <MenuItem value="Kuwait">Kuwait</MenuItem>
                                    <MenuItem value="Kyrgyzstan">Kyrgyzstan</MenuItem>
                                    <MenuItem value="Lao People's Democratic Republic">Lao People&#39;s Democratic Republic</MenuItem>
                                    <MenuItem value="Latvia">Latvia</MenuItem>
                                    <MenuItem value="Lebanon">Lebanon</MenuItem>
                                    <MenuItem value="Lesotho">Lesotho</MenuItem>
                                    <MenuItem value="Liberia">Liberia</MenuItem>
                                    <MenuItem value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</MenuItem>
                                    <MenuItem value="Liechtenstein">Liechtenstein</MenuItem>
                                    <MenuItem value="Lithuania">Lithuania</MenuItem>
                                    <MenuItem value="Luxembourg">Luxembourg</MenuItem>
                                    <MenuItem value="Macao">Macao</MenuItem>
                                    <MenuItem value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</MenuItem>
                                    <MenuItem value="Madagascar">Madagascar</MenuItem>
                                    <MenuItem value="Malawi">Malawi</MenuItem>
                                    <MenuItem value="Malaysia">Malaysia</MenuItem>
                                    <MenuItem value="Maldives">Maldives</MenuItem>
                                    <MenuItem value="Mali">Mali</MenuItem>
                                    <MenuItem value="Malta">Malta</MenuItem>
                                    <MenuItem value="Marshall Islands">Marshall Islands</MenuItem>
                                    <MenuItem value="Martinique">Martinique</MenuItem>
                                    <MenuItem value="Mauritania">Mauritania</MenuItem>
                                    <MenuItem value="Mauritius">Mauritius</MenuItem>
                                    <MenuItem value="Mayotte">Mayotte</MenuItem>
                                    <MenuItem value="Mexico">Mexico</MenuItem>
                                    <MenuItem value="Micronesia, Federated States of">Micronesia, Federated States of</MenuItem>
                                    <MenuItem value="Moldova, Republic of">Moldova, Republic of</MenuItem>
                                    <MenuItem value="Monaco">Monaco</MenuItem>
                                    <MenuItem value="Mongolia">Mongolia</MenuItem>
                                    <MenuItem value="Montenegro">Montenegro</MenuItem>
                                    <MenuItem value="Montserrat">Montserrat</MenuItem>
                                    <MenuItem value="Morocco">Morocco</MenuItem>
                                    <MenuItem value="Mozambique">Mozambique</MenuItem>
                                    <MenuItem value="Myanmar">Myanmar</MenuItem>
                                    <MenuItem value="Namibia">Namibia</MenuItem>
                                    <MenuItem value="Nauru">Nauru</MenuItem>
                                    <MenuItem value="Nepal">Nepal</MenuItem>
                                    <MenuItem value="Netherlands">Netherlands</MenuItem>
                                    <MenuItem value="Netherlands Antilles">Netherlands Antilles</MenuItem>
                                    <MenuItem value="New Caledonia">New Caledonia</MenuItem>
                                    <MenuItem value="New Zealand">New Zealand</MenuItem>
                                    <MenuItem value="Nicaragua">Nicaragua</MenuItem>
                                    <MenuItem value="Niger">Niger</MenuItem>
                                    <MenuItem value="Nigeria">Nigeria</MenuItem>
                                    <MenuItem value="Niue">Niue</MenuItem>
                                    <MenuItem value="Norfolk Island">Norfolk Island</MenuItem>
                                    <MenuItem value="Northern Mariana Islands">Northern Mariana Islands</MenuItem>
                                    <MenuItem value="Norway">Norway</MenuItem>
                                    <MenuItem value="Oman">Oman</MenuItem>
                                    <MenuItem value="Pakistan">Pakistan</MenuItem>
                                    <MenuItem value="Palau">Palau</MenuItem>
                                    <MenuItem value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</MenuItem>
                                    <MenuItem value="Panama">Panama</MenuItem>
                                    <MenuItem value="Papua New Guinea">Papua New Guinea</MenuItem>
                                    <MenuItem value="Paraguay">Paraguay</MenuItem>
                                    <MenuItem value="Peru">Peru</MenuItem>
                                    <MenuItem value="Philippines">Philippines</MenuItem>
                                    <MenuItem value="Pitcairn">Pitcairn</MenuItem>
                                    <MenuItem value="Poland">Poland</MenuItem>
                                    <MenuItem value="Portugal">Portugal</MenuItem>
                                    <MenuItem value="Puerto Rico">Puerto Rico</MenuItem>
                                    <MenuItem value="Qatar">Qatar</MenuItem>
                                    <MenuItem value="Reunion">Reunion</MenuItem>
                                    <MenuItem value="Romania">Romania</MenuItem>
                                    <MenuItem value="Russian Federation">Russian Federation</MenuItem>
                                    <MenuItem value="Rwanda">Rwanda</MenuItem>
                                    <MenuItem value="Saint Helena">Saint Helena</MenuItem>
                                    <MenuItem value="Saint Kitts and Nevis">Saint Kitts and Nevis</MenuItem>
                                    <MenuItem value="Saint Lucia">Saint Lucia</MenuItem>
                                    <MenuItem value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</MenuItem>
                                    <MenuItem value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</MenuItem>
                                    <MenuItem value="Samoa">Samoa</MenuItem>
                                    <MenuItem value="San Marino">San Marino</MenuItem>
                                    <MenuItem value="Sao Tome and Principe">Sao Tome and Principe</MenuItem>
                                    <MenuItem value="Saudi Arabia">Saudi Arabia</MenuItem>
                                    <MenuItem value="Senegal">Senegal</MenuItem>
                                    <MenuItem value="Serbia">Serbia</MenuItem>
                                    <MenuItem value="Seychelles">Seychelles</MenuItem>
                                    <MenuItem value="Sierra Leone">Sierra Leone</MenuItem>
                                    <MenuItem value="Singapore">Singapore</MenuItem>
                                    <MenuItem value="Slovakia">Slovakia</MenuItem>
                                    <MenuItem value="Slovenia">Slovenia</MenuItem>
                                    <MenuItem value="Solomon Islands">Solomon Islands</MenuItem>
                                    <MenuItem value="Somalia">Somalia</MenuItem>
                                    <MenuItem value="South Africa">South Africa</MenuItem>
                                    <MenuItem value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</MenuItem>
                                    <MenuItem value="Spain">Spain</MenuItem>
                                    <MenuItem value="Sri Lanka">Sri Lanka</MenuItem>
                                    <MenuItem value="Sudan">Sudan</MenuItem>
                                    <MenuItem value="Suriname">Suriname</MenuItem>
                                    <MenuItem value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</MenuItem>
                                    <MenuItem value="Swaziland">Swaziland</MenuItem>
                                    <MenuItem value="Sweden">Sweden</MenuItem>
                                    <MenuItem value="Switzerland">Switzerland</MenuItem>
                                    <MenuItem value="Syrian Arab Republic">Syrian Arab Republic</MenuItem>
                                    <MenuItem value="Taiwan, Province of China">Taiwan, Province of China</MenuItem>
                                    <MenuItem value="Tajikistan">Tajikistan</MenuItem>
                                    <MenuItem value="Tanzania, United Republic of">Tanzania, United Republic of</MenuItem>
                                    <MenuItem value="Thailand">Thailand</MenuItem>
                                    <MenuItem value="Timor-leste">Timor-leste</MenuItem>
                                    <MenuItem value="Togo">Togo</MenuItem>
                                    <MenuItem value="Tokelau">Tokelau</MenuItem>
                                    <MenuItem value="Tonga">Tonga</MenuItem>
                                    <MenuItem value="Trinidad and Tobago">Trinidad and Tobago</MenuItem>
                                    <MenuItem value="Tunisia">Tunisia</MenuItem>
                                    <MenuItem value="Turkey">Turkey</MenuItem>
                                    <MenuItem value="Turkmenistan">Turkmenistan</MenuItem>
                                    <MenuItem value="Turks and Caicos Islands">Turks and Caicos Islands</MenuItem>
                                    <MenuItem value="Tuvalu">Tuvalu</MenuItem>
                                    <MenuItem value="Uganda">Uganda</MenuItem>
                                    <MenuItem value="Ukraine">Ukraine</MenuItem>
                                    <MenuItem value="United Arab Emirates">United Arab Emirates</MenuItem>
                                    <MenuItem value="United Kingdom">United Kingdom</MenuItem>
                                    <MenuItem value="United States">United States</MenuItem>
                                    <MenuItem value="United States Minor Outlying Islands">United States Minor Outlying Islands</MenuItem>
                                    <MenuItem value="Uruguay">Uruguay</MenuItem>
                                    <MenuItem value="Uzbekistan">Uzbekistan</MenuItem>
                                    <MenuItem value="Vanuatu">Vanuatu</MenuItem>
                                    <MenuItem value="Venezuela">Venezuela</MenuItem>
                                    <MenuItem value="Viet Nam">Viet Nam</MenuItem>
                                    <MenuItem value="Virgin Islands, British">Virgin Islands, British</MenuItem>
                                    <MenuItem value="Virgin Islands, U.S.">Virgin Islands, U.S.</MenuItem>
                                    <MenuItem value="Wallis and Futuna">Wallis and Futuna</MenuItem>
                                    <MenuItem value="Western Sahara">Western Sahara</MenuItem>
                                    <MenuItem value="Yemen">Yemen</MenuItem>
                                    <MenuItem value="Zambia">Zambia</MenuItem>
                                    <MenuItem value="Zimbabwe">Zimbabwe</MenuItem>
                                </Select>
                            </FormControl>
                        </form>
                    </CardContent>
                    <CardActions>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={this.confirm}
                        >
                            Confirm
                        </Button>
                    </CardActions>
                </Card>
            </div>
        );
    }
}

ConfirmAccountInformation.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
        card: PropTypes.string.isRequired,
        form: PropTypes.string.isRequired,
        button: PropTypes.string.isRequired,
        formControl: PropTypes.string.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    accountInformationFromUser: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        contactNumber: PropTypes.string.isRequired,
        businessName: PropTypes.string.isRequired,
        addressLine1: PropTypes.string.isRequired,
        addressLine2: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        region: PropTypes.string.isRequired,
        zip: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
    }).isRequired,
    userId: PropTypes.number.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

const mapStateToProps = (state) => {
    const { currentUser, organization } = state;

    return {
        ...currentUser,
        businessName: organization.name,
        userId: currentUser.userId,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(ConfirmAccountInformation));
