import { SET_REDUX_MESSAGE, HIDE_REDUX_MESSAGE } from '../actions/ReduxMessagesActions';

function reduxMessages(state = {
    reduxManagedMessage: '',
    showManagedMessage: false,
    link: null,
}, action) {
    switch (action.type) {
    case SET_REDUX_MESSAGE:
        return Object.assign({}, state, {
            reduxManagedMessage: action.message,
            link: action.link,
            showManagedMessage: true,
        });
    case HIDE_REDUX_MESSAGE:
        return Object.assign({}, state, {
            showManagedMessage: false,
            link: null,
        });
    default:
        return state;
    }
}

export default reduxMessages;
