import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/Auth';
import ForbiddenDialog from './ForbiddenDialog';

const SomethingWentWrongForbiddenDialog = ({ dispatch }) => {
    const logOut = () => {
        dispatch(logoutUser());
    };

    return (<ForbiddenDialog
        confirmationAction={logOut}
        forbiddenDialogTitle="Oops! Something Went Wrong"
        forbiddenDialogContentText="Please try to login again. If this problem persists please contact support at (877) 766-3762."
    />);
};

SomethingWentWrongForbiddenDialog.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default connect(null)(SomethingWentWrongForbiddenDialog);
