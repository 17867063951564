import React, { ReactElement } from 'react';
import {
    Grid,
} from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import OfficeDropDown from './OfficeDropDown';
import MenuDropDown from '../MenuDropDown';
import Office from '../../lib/Models/Office';

interface MobileMenuProps extends WithStyles<typeof styles> {
    className?: string,
    setOffice: (index: number) => void,
    setOption: (component: ReactElement) => void,
    offices: Office[],
    selectedOfficeIndex: number,
    navButtons: NavButtonType[]
}
interface NavButtonType {
    index: number;
    title: string;
    component: ReactElement;
}

const styles = (theme: any) => createStyles({
    mobileGrid: {
        height: '70px',
        background: '#FFF',
        width: '100%',
        margin: '0',
        padding: '5px',
        [theme.breakpoints.down('sm')]: {
            height: 100
        }
    },
    dropDownMenu: {
        padding: '5px 10px',
    },
});

const MobileMenu = (props: MobileMenuProps) => {
    const {
        classes,
    } = props;

    return (
        <Grid
            container
            className={classes.mobileGrid}
            justifyContent="center"
        >
            <Grid container item direction="row" justifyContent="center" >
                <Grid item container className={classes.dropDownMenu} xs={6} justifyContent="center">
                    <OfficeDropDown
                        setOffice={(index: number) => props.setOffice(index)}
                        offices={props.offices}
                        selectedOfficeIndex={props.selectedOfficeIndex}
                    />
                </Grid>
                <Grid item container className={classes.dropDownMenu} xs={6} justifyContent="center">
                    <MenuDropDown
                        setOption={(component: ReactElement) => props.setOption(component)}
                        navButtons={props.navButtons}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(MobileMenu);
