import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { verificationErrorMessages } from '../../lib/updatePaymentVerificationErrorCode';

const styles = () => ({
    root: {
        marginTop: 0,
    },
    itemRoot: {
        color: '#ffffff',
    },
    item: {
        color: '#ffffff',
    },
});

const PaymentErrorContent = (props) => {
    const { verificationErrorCodes } = props;

    if (!verificationErrorCodes) {
        return null;
    }

    const renderErrorMessages = (code) => {
        const message = verificationErrorMessages[code];
        return (
            <li key={code} className={props.classes.itemRoot}>
                <Typography className={props.classes.item}>
                    {message}
                </Typography>
            </li>
        );
    };

    return (
        <ul className={props.classes.root}>
            {verificationErrorCodes.map(renderErrorMessages)}
        </ul>
    );
};

PaymentErrorContent.propTypes = {
    verificationErrorCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
    classes: PropTypes.shape({
        root: PropTypes.string,
        itemRoot: PropTypes.string,
        item: PropTypes.string,
    }).isRequired,
};

export default withStyles(styles)(PaymentErrorContent);
