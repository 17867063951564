import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import isObject from 'lodash/isObject';
import some from 'lodash/some';
import ProjectNotesCard from './ProjectNotesCard';
import './ProjectNotes.css';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import PageToolbarActions, { ToolbarSaveButton } from '../NavigationComponents/PageToolbarActions';
import {
    getProjectById,
    updateProjectDetails,
} from '../../actions/ProjectActions';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';
import {
    getOfficeByOfficeId,
    GET_OFFICE_BY_ID_SUCCESS,
} from '../../actions/Office';

const style = {
    root: {
        padding: 24,
    },
};

class ProjectNotes extends Component {
    state = {
        notes: {
            text: this.props.currentProject.notes,
            modified: false,
            cardTitle: 'General Notes',
            type: 'notes',
        },
        notesContract: {
            text: this.props.currentProject.notesContract,
            modified: false,
            cardTitle: 'Contract Notes',
            type: 'notesContract',
        },
        notesDelivery: {
            text: this.props.currentProject.notesDelivery,
            modified: false,
            cardTitle: 'Delivery Notes',
            type: 'notesDelivery',
        },
        notesInstallation: {
            text: this.props.currentProject.notesInstallation,
            modified: false,
            cardTitle: 'Installation Notes',
            type: 'notesInstallation',
        },
        notesPreStart: {
            text: this.props.currentProject.notesPreStart,
            modified: false,
            cardTitle: 'PreStart Notes',
            type: 'notesPreStart',
        },
        message: '',
        open: false,
        type: '',
        isBlocking: false,
    };

    async componentDidMount() {
        await this.loadProject();
    }

    componentDidUpdate(prevProps) {
        // We wanna update state as soon as the project is loaded
        if (!prevProps.currentProject.id && this.props.currentProject.id) {
            this.mapNotesPropsToState();
            this.getOfficePreStartNotes();
        }
    }

    getProjectCards = () => {
        const results = Object.keys(this.state).filter(elem =>
            isObject(this.state[elem]));
        return results.map(elem => (
            <ProjectNotesCard
                cardTitle={this.state[elem].cardTitle}
                type={this.state[elem].type}
                cardText={this.state[elem].text}
                handleReset={this.handleReset}
                handleSave={this.handleSave}
                textChange={this.handleChange}
                modified={this.state[elem].modified}
                key={this.state[elem].type}
            />
        ));
    };

    getOfficePreStartNotes = async () => {
        const { currentProject } = this.props;

        // First, check if the project already has pre-start notes
        if (currentProject.notesPreStart) {
            this.setState({
                notesPreStart: Object.assign({}, this.state.notesPreStart, {
                    text: currentProject.notesPreStart,
                }),
            });
            return;
        }

        // If we made it here, the project doesn't already have notes saved.  Grab defaults from the office.
        const result = await this.props.dispatch(getOfficeByOfficeId(currentProject.officeId));
        const isLoaded = result && result.type === GET_OFFICE_BY_ID_SUCCESS;

        if (!isLoaded) return;

        this.setState({
            notesPreStart: Object.assign({}, this.state.notesPreStart, {
                text: result.response.preStartChecklist,
            }),
        });
    };

    mapNotesPropsToState = () => {
        const { currentProject } = this.props;

        this.setState({
            notes: Object.assign({}, this.state.notes, {
                text: currentProject.notes,
            }),
            notesContract: Object.assign({}, this.state.notesContract, {
                text: currentProject.notesContract,
            }),
            notesDelivery: Object.assign({}, this.state.notesDelivery, {
                text: currentProject.notesDelivery,
            }),
            notesInstallation: Object.assign({}, this.state.notesInstallation, {
                text: currentProject.notesInstallation,
            }),
            notesPreStart: Object.assign({}, this.state.notesPreStart, {
                text: currentProject.notesPreStart,
            }),
        });
    };

    handleChange = (e) => {
        e.persist();
        const { name, value } = e.target;

        this.setState({
            [name]: Object.assign({}, this.state[name], {
                text: value,
                modified: value !== this.props.currentProject[name],
            }),
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    toggleFromModified = (obj) => {
        forEach(obj, (elem, iterator) => {
            if (elem.modified) {
                this.setState({
                    [iterator]: Object.assign({}, this.state[iterator], {
                        modified: false,
                    }),
                });
            }
        });
    };

    loadProject = async () => {
        await this.props.dispatch(getProjectById(this.props.match.params.id));
    };

    handleReset = (type) => {
        this.setState({
            open: true,
            type,
        });
    };

    handleClearForm = () => {
        const { type } = this.state;

        this.setState({
            open: false,
            [type]: Object.assign({}, this.state[type], {
                text: this.props.currentProject[type],
                modified: false,
            }),
        });
    };

    handleSave = (type, title) => {
        const submitObject = Object.assign({}, this.props.currentProject, {
            [type]: this.state[type].text,
        });
        this.props.dispatch(updateProjectDetails(submitObject)).then(() => {
            this.setState({
                [type]: Object.assign({}, this.state[type], {
                    modified: false,
                }),
            });
            this.props.dispatch(setReduxMessage(`The ${title} were succesfully updated`));
        });
    };

    submitAll = () => {
        const notes = { notes: this.state.notes.text };
        const notesContract = { notesContract: this.state.notesContract.text };
        const notesDelivery = { notesDelivery: this.state.notesDelivery.text };
        const notesInstallation = {
            notesInstallation: this.state.notesInstallation.text,
        };
        const notesPreStart = { notesPreStart: this.state.notesPreStart.text };
        const submitObject = Object.assign(
            {},
            this.props.currentProject,
            notes,
            notesDelivery,
            notesContract,
            notesInstallation,
            notesPreStart,
        );
        this.props.dispatch(updateProjectDetails(submitObject)).then(() => {
            this.props.dispatch(setReduxMessage('All notes were saved'));
            this.toggleFromModified(this.state);
        });
    };

    areAnyModified = obj => !some(obj, 'modified');

    render() {
        return (
            <div className="projectNotesContainer" style={style.root}>
                <PageToolbarActions>
                    <ToolbarSaveButton onClick={this.submitAll} tooltip="Save All Notes" />
                </PageToolbarActions>
                {isEmpty(this.props.currentProject) ? null : this.getProjectCards()}
                <ConfirmationModal
                    title="Reset Notes"
                    description="Doing this will reset this text box to the initial value, losing any unsaved changes"
                    handleConfirm={this.handleClearForm}
                    confirmText="Reset"
                    isOpen={this.state.open}
                    handleClose={this.handleClose}
                />
                <Prompt
                    when={!this.areAnyModified(this.state)}
                    message="You have some unsaved data, are you sure you want to continue?"
                />
            </div>
        );
    }
}
/* eslint-disable react/forbid-prop-types */
ProjectNotes.propTypes = {
    match: PropTypes.object.isRequired,
    currentProject: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
};

ProjectNotes.defaultProps = {
    currentProject: {},
};

const mapStateToProps = (state) => {
    const { projectHome } = state;
    const { currentProject } = projectHome;

    return { currentProject };
};

export default connect(mapStateToProps)(ProjectNotes);
