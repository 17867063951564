import FormHtmlDialog from 'components/CreateDocument/FormHtmlDialog';
import React from 'react';
import { useParams } from 'react-router';

type ESignUrlParams = {
    projectId: string;
    documentId: string;
};

const ESign = () => {
    const { projectId, documentId } = useParams<ESignUrlParams>();
    return (
        <FormHtmlDialog
            open
            projectId={projectId}
            documentId={documentId}
            usesLegacySigning={false}
            usesESignHtml
        />
    );
};

export default ESign;
