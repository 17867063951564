import React, { ReactElement } from 'react'
import SettingsRow, { SettingsRowProps } from './SettingsRow';
import ContractEditor from '../DocumentSettings/ContractEditor';

interface SettingsComponentProps {
    componentType: 'switch' | 'textEditor',
    settingsRowProps?: SettingsRowProps,
    children?: any,
    'data-cy'?: string | undefined,
}

const SettingsComponent = (props: SettingsComponentProps) => {
 const { 'data-cy': dataCy } = props
    if (props.componentType === 'switch') {
        return (
            <>
                {
                    props.settingsRowProps &&
                    <SettingsRow {...props.settingsRowProps} data-cy={dataCy} />
                }
            </>
        );
    }

    if (props.componentType === 'textEditor') {
        return (
            <ContractEditor />
        )
    }

    return props.children as ReactElement
}

export default SettingsComponent;