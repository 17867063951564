import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Color as AlertColor } from '@material-ui/lab';
import {
    Grid,
    Typography,
    Button,
    WithStyles,
    CircularProgress,
} from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import {
    UPDATE_PAYMENT_METHOD_SUCCESS,
    generatePaymentClientToken,
    updatePaymentMethod,
} from '../../../actions/Payment';
import SettingsContainer from '../SettingsComponents/SettingsContainer';
import RowContainer from '../SettingsComponents/RowContainer';
import SettingsComponent from '../SettingsComponents/SettingsComponent';
import BraintreeDropinUI from 'components/BraintreeDropinUI';
import { getUser } from 'actions/User';
import SaveSnackBar from '../SaveSnackbar';
import { getOrganization } from 'actions/Organization';
import TransactionHistory, {
    FailedTransactionsContainer,
} from './TransactionHistory/TransactionHistory';
import Alert from '../../Alert';
import ActivateSubscriptionConfirmationDialog from './ActivateSubscriptionConfirmationDialog';
import PaymentDialogButton from './TransactionHistory/PaymentDialogButton';
import {
    CREATE_SKETCH_ORDER_TRANSACTION_BATCH_SUCCESS,
    createSketchOrderTransactionBatchV2,
} from 'actions/sketchOrderTransactionBatchActions';
import LoggerWrapper from 'lib/Logger';
import httpStatusCodes from 'middleware/httpStatusCodes';
import {
    CREATE_NEARMAP_ORDER_TRANSACTION_BATCH_SUCCESS,
    createNearmapOrderTransactionBatch,
} from 'actions/nearMapOrderTransactionBatchActions';
import { paymentErrorMessages } from 'lib/updatePaymentErrorType';
import OrganizationStatus from 'lib/OrganizationStatus';
import { DateTime } from 'luxon';

const styles = ({ breakpoints }: any) =>
    createStyles({
        root: {
            padding: '60px 15px',
            margin: 'auto',
            maxWidth: 1000,
            [breakpoints.down('sm')]: {
                padding: 15,
            },
        },
        header: {
            font: 'normal normal bold 34px/20px Roboto',
            letterSpacing: 0.3,
            color: '#222222',
            padding: '20px 0px',
        },
        tabStyle: {
            font: 'normal normal 14px Roboto',
            color: '#999999',
            height: 61,
            backgroundColor: '#F2F2F2',
            '&.Mui-selected': {
                backgroundColor: '#FFFFFF',
            },
            [breakpoints.down('sm')]: {
                width: 100,
            },
        },
        subtitle: {
            paddingBottom: 16,
            [breakpoints.down('sm')]: {
                fontSize: 14,
            },
        },
        sectionTitle: {
            font: 'normal normal bold 20px Roboto',
            letterSpacing: 0.21,
            color: '#222222',
            padding: '35px 0px 15px 0px',
        },
        settingsRow: {
            padding: '25px 15px 25px 20px',
            font: '16px Roboto',
            borderBottom: '1px solid #00112219',
            [breakpoints.down('sm')]: {
                fontSize: 14,
            },
        },
        settingDescription: {
            font: 'normal normal normal 16px Roboto',
            letterSpacing: '0.5px',
            color: '#000000DE',
            opacity: '0.5',
            paddingTop: '10px',
            [breakpoints.down('sm')]: {
                fontSize: 14,
            },
        },
        middleSection: {
            fontSize: 16,
            [breakpoints.down('sm')]: {
                fontSize: 14,
            },
        },
        button: {
            borderWidth: 2,
            borderColor: '#222222',
            textTransform: 'none',
            padding: '4px 18px',
            marginTop: 10,
            '& :disabled': {
                borderColor: 'initial',
            },
        },
        rowContainer: {
            padding: '0 10px',
            border: 'none',
            [breakpoints.down('sm')]: {
                padding: 'unset',
            },
        },
        sketchOrderTab: {
            padding: '10px 15px',
            backgroundColor: '#F2F2F2',
            border: '1px solid #00112219',
            borderRadius: '2px',
            color: '#999999',
        },
        circle: {
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            marginRight: 10,
        },
        braintreeWrapper: {
            '& .braintree-methods': {
                backgroundColor: '#f2f2f2',
            },
        },
        alertIcon: {
            color: '#FFFFFF',
            height: 22,
            width: 22,
        },
        spinnerBackground: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 10000,
            backgroundColor: 'rgba(0,0,0,0.5)',
        },
        spinnerContainer: {
            position: 'relative',
            textAlign: 'center',
            width: 300,
            height: 300,
        },
        spinner: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 10001,
        },
    });

const Billing = (props: WithStyles<typeof styles>) => {
    const successMessage: string = 'Payment method successfully updated';

    const dispatch = useDispatch();

    const { organization, currentUser } = useSelector((state: any) => {
        const { organization, currentUserRoles, currentUser } = state;
        return {
            organization,
            currentUserRoles,
            currentUser,
        };
    });

    const { classes } = props;

    const [clientToken, setClientToken] = useState<string>('');
    const [instance, setInstance] = useState<any>(null);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [primaryEmail, setPrimaryEmail] = useState<string>('');
    const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
    const [braintreeError, setBraintreeError] = useState<boolean>(false);
    const [braintreeErrorMessage, setBraintreeErrorMessage] =
        useState<string>('');
    const [confirmationDialogOpen, setConfirmationDialogOpen] =
        useState<boolean>(false);
    const [snackBarMessage, setSnackBarMessage] = useState<{
        severity: AlertColor;
        message: string;
        retryAction?: JSX.Element;
        autoHideDuration?: number;
    } | null>(null);
    const [safeToDisplayBraintreeInDialog, setSafeToDisplayBraintreeInDialog] =
        useState(false);
    const [failedTransactions, setFailedTransactions] = useState<
        FailedTransactionsContainer | undefined
    >(undefined);
    const [activeTab, setActiveTab] = useState(0);
    const [waitingOnResponse, setWaitingOnResponse] = useState<boolean>(false);
    const [failedTransactionsResolved, setFailedTransactionsResolved] =
        useState<boolean>(false);
    const [isSubscriptionActive, setIsSubscriptionActive] = useState<boolean>(
        !organization.isFreeAccount &&
            organization.status === OrganizationStatus.Subscription
    );

    const isAccountPrimary = currentUser.userId === organization.primaryUserId;

    const isSubscriptionInTrial = () => {
        const now = DateTime.now();
        const expirationDate = DateTime.fromISO(
            organization.subscriptionExpires
        );
        const isExpirationDateAfterNow = now < expirationDate;
        return (
            organization.status === OrganizationStatus.SneakPeak &&
            isExpirationDateAfterNow
        );
    };

    const handleUpdateCard = async () => {
        if (confirmationDialogOpen) setConfirmationDialogOpen(false);
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { nonce } = await instance.requestPaymentMethod();

            await dispatch(updatePaymentMethod(currentUser.userId, nonce));

            // need to refresh org data after card update so state has up to date subscription info
            dispatch(getOrganization());

            setSnackBarMessage({
                severity: 'success',
                message: successMessage,
                autoHideDuration: 6000,
            });
            setIsAlertOpen(true);
            //If they re-enter payment info after updating their card failed and it succeeds, remove the Alert component
            if (braintreeError) setBraintreeError(false);
        } catch (err) {
            console.error(err);
            setErrorMessage('Error updating card');
            setBraintreeError(true);
            setBraintreeErrorMessage(
                'There was an error updating your card. Please verify that your payment information was entered correctly and try again. If the problem persists, please contact RoofSnap Support.'
            );
        }
    };

    const formattedSubscriptionExpirationDate = (
        expirationDate: string
    ): string => {
        const date = new Date(expirationDate);
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate;
    };

    const handleSnackbarClose = (
        event?: React.SyntheticEvent,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsAlertOpen(false);
    };

    const handlePaymentDialogOpened = (open: boolean) => {
        setSafeToDisplayBraintreeInDialog(open);
    };

    const handlePaymentMethodRequestable = () => setBraintreeError(false);

    const handleFailedMeasurementOrders = async (
        nonce: string,
        useSubscriptionPaymentMethod: boolean | undefined
    ) => {
        // TODO: We need to refresh the table after a payment is successfully submitted.
        try {
            if ((failedTransactions?.measurementOrderIds ?? []).length > 0) {
                setWaitingOnResponse(true);
                const result: any = await dispatch(
                    createSketchOrderTransactionBatchV2(
                        failedTransactions?.measurementOrderIds,
                        nonce ? nonce : '',
                        useSubscriptionPaymentMethod
                    )
                );
                const { response } = result;
                if (
                    result.type ===
                    CREATE_SKETCH_ORDER_TRANSACTION_BATCH_SUCCESS
                ) {
                    if (response.status === httpStatusCodes.NoContent) {
                        setFailedTransactionsResolved(true);
                        setWaitingOnResponse(false);
                        setSnackBarMessage({
                            severity: 'success',
                            message: 'Your payment was successful.',
                            autoHideDuration: 6000,
                        });
                        setIsAlertOpen(true);
                    } else {
                        setWaitingOnResponse(false);

                        let retryBillingErrorMessage =
                            'Payment method has been declined.';

                        const error = response[0];
                        if (error.processorResponseType === 'soft_declined') {
                            retryBillingErrorMessage +=
                                ' Please contact your bank before trying again.';
                        }

                        if (error.processorResponseType === 'hard_declined') {
                            retryBillingErrorMessage +=
                                ' Please use a different card.';
                        }

                        setSnackBarMessage({
                            severity: 'error',
                            message: retryBillingErrorMessage,
                            autoHideDuration: 6000,
                        });
                        setIsAlertOpen(true);
                    }
                } else {
                    setWaitingOnResponse(false);
                    if (response.error) {
                        setWaitingOnResponse(false);
                        throw new Error(result.error);
                    }
                }
            } else {
                setSnackBarMessage({
                    severity: 'error',
                    message: 'There are no Billing Failed Orders.',
                    autoHideDuration: 6000,
                });
                setIsAlertOpen(true);
            }
        } catch (err) {
            setSnackBarMessage({
                severity: 'error',
                message:
                    'An error occurred updating your payment information. If the problem persists, please contact Roofsnap Support.',
                autoHideDuration: 20 * 1000,
            });
            setIsAlertOpen(true);
            LoggerWrapper.log(err);
        }
    };

    const handleFailedNearmapOrders = async (
        nonce: string,
        useSubscriptionPaymentMethod: boolean | undefined
    ) => {
        try {
            if ((failedTransactions?.nearmapTransactionIds ?? []).length > 0) {
                setWaitingOnResponse(true);
                const result: any = await dispatch(
                    createNearmapOrderTransactionBatch(
                        failedTransactions?.nearmapTransactionIds,
                        nonce ? nonce : '',
                        useSubscriptionPaymentMethod
                    )
                );
                const { response } = result;
                if (
                    result.type ===
                    CREATE_NEARMAP_ORDER_TRANSACTION_BATCH_SUCCESS
                ) {
                    if (response.status === httpStatusCodes.NoContent) {
                        setFailedTransactionsResolved(true);
                        setWaitingOnResponse(false);
                        setSnackBarMessage({
                            severity: 'success',
                            message: 'Your payment was successful.',
                            autoHideDuration: 6000,
                        });
                        setIsAlertOpen(true);
                    } else {
                        setWaitingOnResponse(false);

                        let retryBillingErrorMessage =
                            'Payment method has been declined.';

                        const error = response[0];
                        if (error.processorResponseType === 'soft_declined') {
                            retryBillingErrorMessage +=
                                ' Please contact your bank before trying again.';
                        }

                        if (error.processorResponseType === 'hard_declined') {
                            retryBillingErrorMessage +=
                                ' Please use a different card.';
                        }

                        setSnackBarMessage({
                            severity: 'error',
                            message: retryBillingErrorMessage,
                            autoHideDuration: 6000,
                        });
                        setIsAlertOpen(true);
                    }
                } else {
                    setWaitingOnResponse(false);
                    if (response.error) {
                        setWaitingOnResponse(false);
                        throw new Error(result.error);
                    }
                }
            } else {
                setSnackBarMessage({
                    severity: 'error',
                    message: 'There are no Billing Failed Orders.',
                    autoHideDuration: 6000,
                });
                setIsAlertOpen(true);
            }
        } catch (err) {
            setSnackBarMessage({
                severity: 'error',
                message:
                    'An error occurred updating your payment information. If the problem persists, please contact Roofsnap Support.',
                autoHideDuration: 20 * 1000,
            });
            setIsAlertOpen(true);
            LoggerWrapper.log(err);
        }
    };

    const handleFailedSubscriptionPayment = async (nonce: string) => {
        const defaultErrorMessage =
            'An error occurred updating your payment information. If the problem persists, please contact Roofsnap Support.';
        try {
            if (failedTransactions?.isSubscriptionPastDue) {
                setWaitingOnResponse(true);
                const result: any = await dispatch(
                    updatePaymentMethod(currentUser.userId, nonce)
                );
                const { response } = result;
                if (response?.type === UPDATE_PAYMENT_METHOD_SUCCESS) {
                    setFailedTransactionsResolved(true);
                    setWaitingOnResponse(false);
                    setSnackBarMessage({
                        severity: 'success',
                        message: 'Your payment was successful.',
                        autoHideDuration: 6000,
                    });
                    setIsAlertOpen(true);
                } else {
                    setWaitingOnResponse(false);
                    if (response.error) {
                        setWaitingOnResponse(false);
                        const errorMessage =
                            paymentErrorMessages[response.error.type] ||
                            defaultErrorMessage;
                        setSnackBarMessage({
                            severity: 'error',
                            message: errorMessage,
                            autoHideDuration: 20 * 1000,
                        });
                        setIsAlertOpen(true);
                    }
                }
            } else {
                setWaitingOnResponse(false);
                setSnackBarMessage({
                    severity: 'error',
                    message: 'Subscription is not past due.',
                    autoHideDuration: 6000,
                });
                setIsAlertOpen(true);
            }
        } catch (err) {
            setSnackBarMessage({
                severity: 'error',
                message: defaultErrorMessage,
                autoHideDuration: 20 * 1000,
            });
            setIsAlertOpen(true);
            LoggerWrapper.log(err);
        }
    };

    useEffect(() => {}, [failedTransactionsResolved]);

    useEffect(() => {
        // Fetch client token from server to initialize Braintree
        async function getClientToken() {
            try {
                const response: any = await dispatch(
                    generatePaymentClientToken(currentUser.userId)
                );
                const paymentClientToken = response.response.paymentClientToken;
                setClientToken(paymentClientToken);
            } catch (err) {
                console.error(err);
                setErrorMessage('Error fetching client token');
                setBraintreeError(true);
                setBraintreeErrorMessage(
                    'There was an error fetching your payment information. If the problem persists, please contact Roofsnap Support.'
                );
                LoggerWrapper.log(err);
            }
        }

        async function getPrimaryEmail() {
            try {
                const response: any = await dispatch(
                    getUser(organization.primaryUserId)
                );
                setPrimaryEmail(response.response.userName);
            } catch (err) {
                console.error(err);
            }
        }
        getPrimaryEmail();
        getClientToken();
    }, [
        currentUser.userId,
        dispatch,
        organization.primaryUserId,
        errorMessage,
    ]);

    useEffect(() => {
        setIsSubscriptionActive(
            !organization.isFreeAccount &&
                organization.status === OrganizationStatus.Subscription
        );
    }, [organization.isFreeAccount, organization.status]);

    return (
        <Grid
            item
            container
            direction='column'
            xs={12}
            className={classes.root}
        >
            {/*TODO add failed billing popup here */}
            <SettingsContainer
                title='Billing'
                description='Manage billing information for your Subscription, Measurement Orders and Nearmap imagery purchases.'
                sectionTitleStyle={classes.header}
                descriptionStyle={classes.subtitle}
            >
                <RowContainer rowContainerStyle={classes.rowContainer}>
                    <SettingsComponent
                        componentType='switch'
                        settingsRowProps={{
                            settingTitle: 'Primary Email',
                            rowStyle: classes.settingsRow,
                            descriptionStyle: classes.settingDescription,
                            middleSection: primaryEmail,
                        }}
                        data-cy='email-row'
                    />
                    <SettingsComponent
                        componentType='switch'
                        settingsRowProps={{
                            settingTitle: 'Account Type',
                            rowStyle: classes.settingsRow,
                            descriptionStyle: classes.settingDescription,
                            middleSection: (
                                <Typography className={classes.middleSection}>
                                    {/* If we don't have a subscription id, they are likely in a Sneak Peek (trial) but could be in another state, so show the status instead */}
                                    {organization.subscriptionId
                                        ? organization.subscriptionId.replace(
                                              /_/g,
                                              ' '
                                          )
                                        : organization.status}{' '}
                                    expires{' '}
                                    {formattedSubscriptionExpirationDate(
                                        organization.subscriptionExpires
                                    ) ?? ''}
                                </Typography>
                            ),

                            // add this change button when we're ready to implement self-service of account type
                            // settingComponent:
                            //     <Button className={classes.button}>
                            //         <span style={{ textTransform: 'uppercase' }}>c</span><span>hange</span>
                            //     </Button>
                        }}
                        data-cy='account-row'
                    />
                </RowContainer>
            </SettingsContainer>
            <SettingsContainer
                title='Payment Method'
                description={
                    !isSubscriptionActive && !isSubscriptionInTrial() ? (
                        <div
                            style={{
                                paddingBottom: 10,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    className={classes.circle}
                                    style={{ backgroundColor: '#DB1F3C' }}
                                />
                                <span> Expired Account</span>
                            </div>
                            <Typography paragraph style={{ color: '#DB1F3C' }}>
                                {isAccountPrimary
                                    ? 'Your subscription is not active. To access Roofsnap’s full suite of tools, enter a valid credit card below and activate your account.'
                                    : 'Your subscription is not active. Please contact your account admin to activate your subscription.'}
                            </Typography>
                        </div>
                    ) : (
                        <div
                            style={{
                                paddingBottom: 10,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    className={classes.circle}
                                    style={{ backgroundColor: '#38BF41' }}
                                    data-cy='account-status-circle'
                                />
                                <span data-cy='account-status'>
                                    {' '}
                                    Active Account
                                </span>
                            </div>
                            {isSubscriptionInTrial() && (
                                <Typography style={{ color: '#38BF41' }}>
                                    You are currently in trial.
                                </Typography>
                            )}
                        </div>
                    )
                }
                //Add account status with red or green circle
                sectionTitleStyle={classes.sectionTitle}
            >
                {isAccountPrimary ? (
                    clientToken &&
                    !safeToDisplayBraintreeInDialog && (
                        <div className={classes.braintreeWrapper}>
                            <BraintreeDropinUI
                                options={{ authorization: clientToken }}
                                onInstance={(instance: any) =>
                                    setInstance(instance)
                                }
                                onPaymentMethodRequestable={
                                    handlePaymentMethodRequestable
                                }
                            />
                        </div>
                    )
                ) : (
                    <Typography paragraph>
                        Subscription payment method can only be updated by the
                        primary user. Contact your organization's primary
                        account holder listed above.
                    </Typography>
                )}
            </SettingsContainer>
            <Alert
                variant='error'
                icon={<WarningIcon className={classes.alertIcon} />}
                title={''}
                message={braintreeErrorMessage}
                open={braintreeError}
                style={{
                    root: {
                        maxWidth: 1000,
                    },
                }}
            />
            {isAccountPrimary && clientToken && (
                <Grid item container justifyContent='flex-end'>
                    {isSubscriptionActive ? (
                        <Button
                            variant='outlined'
                            disabled={braintreeError}
                            className={classes.button}
                            onClick={handleUpdateCard}
                            data-cy='change-card-button'
                        >
                            Change
                        </Button>
                    ) : (
                        <Button
                            variant='outlined'
                            disabled={braintreeError}
                            className={classes.button}
                            onClick={() => setConfirmationDialogOpen(true)}
                        >
                            Subscribe
                        </Button>
                    )}
                </Grid>
            )}
            <ActivateSubscriptionConfirmationDialog
                open={confirmationDialogOpen}
                onSubscribe={handleUpdateCard}
                onCancel={setConfirmationDialogOpen}
                isAccountInTrial={isSubscriptionInTrial()}
                formattedExpirationDate={formattedSubscriptionExpirationDate(
                    organization.subscriptionExpires
                )}
            />
            <SaveSnackBar
                isAlertOpen={isAlertOpen}
                handleSnackbarClose={handleSnackbarClose}
                snackBarMessage={snackBarMessage}
            />
            {activeTab === 0 && (
                <PaymentDialogButton
                    dialogTitle='Renew Subscription'
                    sx={{ margin: '1em 0 -3.5em auto' }}
                    safeToDisplayBraintree={safeToDisplayBraintreeInDialog}
                    onDialogOpened={handlePaymentDialogOpened}
                    onPaymentMethodChosen={handleFailedSubscriptionPayment}
                    disabled={!failedTransactions?.isSubscriptionPastDue}
                    useMakeDefaultCheckbox={false}
                />
            )}
            {activeTab === 1 && (
                <PaymentDialogButton
                    dialogTitle='Pay Measurement Orders'
                    sx={{ margin: '1em 0 -3.5em auto' }}
                    safeToDisplayBraintree={safeToDisplayBraintreeInDialog}
                    onDialogOpened={handlePaymentDialogOpened}
                    onPaymentMethodChosen={handleFailedMeasurementOrders}
                    disabled={
                        (failedTransactions?.measurementOrderIds ?? []).length <
                        1
                    }
                    useMakeDefaultCheckbox={!organization.isFreeAccount}
                />
            )}
            {activeTab === 2 && (
                <PaymentDialogButton
                    dialogTitle='Pay Nearmap Orders'
                    sx={{ margin: '1em 0 -3.5em auto' }}
                    safeToDisplayBraintree={safeToDisplayBraintreeInDialog}
                    onDialogOpened={handlePaymentDialogOpened}
                    onPaymentMethodChosen={handleFailedNearmapOrders}
                    disabled={
                        (failedTransactions?.nearmapTransactionIds ?? [])
                            .length < 1
                    }
                    useMakeDefaultCheckbox={!organization.isFreeAccount}
                />
            )}
            <TransactionHistory
                onFailedTransactions={setFailedTransactions}
                sectionTitleStyle={classes.header}
                tabStyle={classes.tabStyle}
                onTabChange={setActiveTab}
                failedTransactionsResolved={failedTransactionsResolved}
            />
            {waitingOnResponse && (
                <div className={classes.spinnerBackground}>
                    <div className={classes.spinnerContainer}>
                        <CircularProgress
                            className={classes.spinner}
                            size={300}
                            thickness={2}
                        />
                    </div>
                </div>
            )}
        </Grid>
    );
};

export default withStyles(styles)(Billing);
