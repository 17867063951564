import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Snackbar } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import LoggerWrapper from '../../lib/Logger';

function minutesToMS(minutes) {
    return minutes * 1000 * 60;
}

const getScriptScrs = () => {
    const srcs = [];
    const scriptTags = document.querySelectorAll('script');
    Array.prototype.forEach.call(scriptTags, (s) => {
        srcs.push(s.src);
    });
    return srcs;
};

const isSketchRoute = /.*\/sketch$/;
const isBundleScript = /.*\/static\/js\/main\.\w*\.js/;
const CHECKVERSION_INTERVAL = minutesToMS(process.env.REACT_APP_CHECKVERSION_INTERVAL || 15);
const UPDATE_TEXT_OR_REFRESH_INTERVAL = minutesToMS(1);
const MINUTES_BEFORE_REFRESH = process.env.REACT_APP_CHECKVERSION_COUNTDOWN_INTERVAL || 30;

const styles = {
    text: {
        flex: '1 1 auto',
        alignSelf: 'center',
    },
    closeButton: {
        flex: '0 1 auto',
    },
    root: {
        display: 'flex',
    },
};


class AppVersionCheckerComponent extends Component {
    state = {
        newVersionAvailable: false,
        showSnackbar: false,
        minutesLeft: MINUTES_BEFORE_REFRESH,
        inSketchScene: false,
    };

    componentDidMount() {
        const bundleScript = getScriptScrs().find(s => isBundleScript.test(s));
        if (bundleScript) {
            setInterval(this.checkVersion(bundleScript), CHECKVERSION_INTERVAL);
        } else {
            LoggerWrapper.log('skipping version checking functionality', { bundleScript });
        }
    }

    componentWillReceiveProps(nextProps) {
        const inSketchScene = nextProps.location && isSketchRoute.test(nextProps.location.pathname);
        this.setState({ inSketchScene });
    }


    /**
     * this will fire off every 15 minutes by default.  it is never stopped
     * @param src
     * @returns {function()}
     */
    checkVersion(src) {
        return async () => {
            try {
                const resp = await fetch(src, {
                    method: 'HEAD',
                    headers: {
                        'Cache-Control': 'no-cache',
                    },
                });

                if (resp.status !== 200) {
                    // start a new timer to update count down time...
                    // since this can be called again lets not start another timeer...
                    if (!this.state.newVersionAvailable) {
                        setInterval(this.updateCountdownOrRefresh, UPDATE_TEXT_OR_REFRESH_INTERVAL);
                    }

                    this.setState({
                        newVersionAvailable: true,
                        showSnackbar: true,
                    });
                }
            } catch (err) {
                LoggerWrapper.captureException(err);
            }
        };
    }

    /**
     * this should be fired off every minute AFTER a new version is found
     */
    updateCountdownOrRefresh = () => {
        // if we are in skecth scene we "pause" the countdown
        if (!this.state.inSketchScene) {
            const minutesLeft = this.state.minutesLeft - 1;
            if (minutesLeft === 0) {
                window.document.location.reload();
            } else {
                this.setState({ minutesLeft });
            }
        }
    };

    handleClose = () => {
        this.setState({ showSnackbar: false });
    };

    render() {
        return (
            <div>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={this.state.showSnackbar}
                    autoHideDuration={null}
                    message={
                        <div className={this.props.classes.root}>
                            <div className={this.props.classes.text}>
                                <span>
                                    A new version of RoofSnap is available! Please refresh to update.
                                    <br />
                                    This page will automatically refresh in {this.state.minutesLeft} minutes.
                                </span>
                            </div>
                            <div className={this.props.classes.closeButton}>
                                <IconButton
                                    onClick={this.handleClose}
                                >
                                    <CloseIcon style={{ fill: 'white' }} />
                                </IconButton>
                            </div>
                        </div>
                    }
                />
            </div>);
    }
}

AppVersionCheckerComponent.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        closeButton: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default withRouter(withStyles(styles)(AppVersionCheckerComponent));
