import { CALL_API } from '../middleware/api';

export const GET_ORGANIZATIONS_CREDITS_REQUEST = 'GET_ORGANIZATIONS_CREDITS_REQUEST';
export const GET_ORGANIZATIONS_CREDITS_SUCCESS = 'GET_ORGANIZATIONS_CREDITS_SUCCESS';
export const GET_ORGANIZATIONS_CREDITS_FAILURE = 'GET_ORGANIZATIONS_CREDITS_FAILURE';

export const getOrganizationsCredits = organizationId => ({
    [CALL_API]: {
        types: [
            GET_ORGANIZATIONS_CREDITS_REQUEST,
            GET_ORGANIZATIONS_CREDITS_SUCCESS,
            GET_ORGANIZATIONS_CREDITS_FAILURE,
        ],
        endpoint: `organizations/${organizationId}/credits`,
    },
});
