// small component to replicate the old CardTitle from material-ui
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const CardTitle = (props) => {
    const styles = { lineHeight: '36px', fontSize: '24px' };
    if (props.padding) {
        styles.padding = '16px';
    }

    return (
        <Typography style={styles}>{props.title}</Typography>);
};

CardTitle.propTypes = {
    title: PropTypes.string.isRequired,
    padding: PropTypes.bool,
};

CardTitle.defaultProps = {
    padding: false,
};

export default CardTitle;
