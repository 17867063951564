import { CALL_API } from '../middleware/api';
import httpRequestMethods from '../middleware/httpRequestMethods';

export const REQUEST_RESET_PASSWORD_REQUEST = 'REQUEST_RESET_PASSWORD_REQUEST';
export const REQUEST_RESET_PASSWORD_SUCCESS = 'REQUEST_RESET_PASSWORD_SUCCESS';
export const REQUEST_RESET_PASSWORD_FAILURE = 'REQUEST_RESET_PASSWORD_FAILURE';

export const requestResetPassword = username => ({
    [CALL_API]: {
        endpoint: 'password/reset',
        method: httpRequestMethods.POST,
        types: [REQUEST_RESET_PASSWORD_REQUEST, REQUEST_RESET_PASSWORD_SUCCESS, REQUEST_RESET_PASSWORD_FAILURE],
        body: {
            username,
        },
        authRequired: false,
    },
});

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const resetPassword = (passwordResetToken, newPassword) => ({
    [CALL_API]: {
        endpoint: `password/reset/${passwordResetToken}`,
        method: httpRequestMethods.POST,
        types: [REQUEST_RESET_PASSWORD_REQUEST, REQUEST_RESET_PASSWORD_SUCCESS, REQUEST_RESET_PASSWORD_FAILURE],
        body: {
            newPassword,
        },
        authRequired: false,
    },
});
