import {
    CREATE_DOCUMENT_SHARED_ACCESS_TOKEN_REQUEST,
    CREATE_DOCUMENT_SHARED_ACCESS_TOKEN_SUCCESS,
    CREATE_DOCUMENT_SHARED_ACCESS_TOKEN_FAILURE,
} from '../actions/documentSharedAccessTokenActions';

const initialState = {
    id: '',
    sharedAccessSignatureUrl: '',
    isFetching: false,
};

const documentSharedAccessToken = (state = initialState, action) => {
    switch (action.type) {
    case CREATE_DOCUMENT_SHARED_ACCESS_TOKEN_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case CREATE_DOCUMENT_SHARED_ACCESS_TOKEN_SUCCESS:
        return Object.assign({}, state, {
            id: action.response.id,
            sharedAccessSignatureUrl: action.response.sharedAccessSignatureUrl,
            isFetching: false,
        });
    case CREATE_DOCUMENT_SHARED_ACCESS_TOKEN_FAILURE:
        return Object.assign({}, state, initialState);
    default:
        return state;
    }
};

export default documentSharedAccessToken;
