import { Tabs, Tab } from "@material-ui/core";
import React, {
  PropsWithChildren,
  useEffect,
  useState,
  ReactNode,
  ChangeEvent
} from "react";
import { useTheme, useMediaQuery } from "@material-ui/core";

type TabbedAreaProps = {
  children: ReactNode[],
  tabStyle?: string | undefined,
  onTabChange?: (tabIndex: number) => void;
} & PropsWithChildren<{}>;

export const TabbedArea = ({ children, tabStyle, onTabChange }: TabbedAreaProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    setSelectedTabIndex(newValue);
    
    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  return (
    <>
      <Tabs value={selectedTabIndex} onChange={handleTabChange} textColor="primary" variant={mobile ? "fullWidth" : "standard"} style={{height: 61}} >
        {React.Children.map(children, (child, index) => {
          const { label} = (child as any).props;
          return (
            <Tab 
              label={label}
              tabIndex={index}
              className={tabStyle} />
          )
        })}
      </Tabs>
      {React.Children.map(children, (child, index) => {
        return (
          <div style={{ display: selectedTabIndex === index ? 'block' : 'none' }}>
            {React.cloneElement(child as React.ReactElement)}
          </div>
        );
      })}
    </>
  );
};

type TabbedAreaPanelProps = PropsWithChildren<{
  index?: number | undefined;
  label: ReactNode;
  onLoad?: (tabLabel: ReactNode) => void | undefined;
  value?: number | undefined;
}>;

export const TabbedAreaPanel = ({
  children,
  index,
  label,
  onLoad = () => { },
  value,
}: TabbedAreaPanelProps) => {
  useEffect(() => {
    onLoad(label);
  }, [label, onLoad]);

  return index !== value ? <></> : <div>{children}</div>;
};
