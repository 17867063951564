import {
    CREATE_SKETCH_REPORT_REQUEST,
    CREATE_SKETCH_REPORT_SUCCESS,
    CREATE_SKETCH_REPORT_FAILURE,
} from '../actions/SketchReportDocumentsAction';

const initialState = {
    isFetching: false,
};

const sketchReportDocument = (state = initialState, action) => {
    switch (action.type) {
    case CREATE_SKETCH_REPORT_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
            isFetchingText: 'Creating sketch report...',
        });
    case CREATE_SKETCH_REPORT_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
        });
    case CREATE_SKETCH_REPORT_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
        });
    default:
        return state;
    }
};

export default sketchReportDocument;
