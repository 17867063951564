import React, { useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import {
  createStyles, withStyles, WithStyles, Grid, Typography, Button, useTheme, useMediaQuery, Theme
} from '@material-ui/core';
import NearmapOrder from 'lib/Models/NearmapOrder';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OptionsPopover from './OptionsPopover';
// import UpdateOrderModal from './UpdateOrderModal';
import OrderStatusChip from './OrderStatusChip';
import FormattedDate from 'lib/util/FormattedDate';
import { DataRow } from 'components/DataTable/DataTable';
import { OrderTypes } from './OrderTypes';

interface NearmapRowProps
  extends RouteComponentProps,
  WithStyles<typeof styles> {
  nearmapOrder: NearmapOrder;
}

const styles = (theme: Theme) => createStyles({
  columnContainer: {
    maxWidth: '90vw',
    padding: 0,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      padding: 10
    }
  },
  rowText: {
    color: '#000000DE',
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      padding: 4
    }
  },
})

const NearmapRow = (props: NearmapRowProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { classes, nearmapOrder } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <DataRow>
      <Grid
        container
        item
        className={classes.columnContainer}
        direction='row'
        xs={12}
        data-cy='nearmap-row'
      >
        <Grid container item xs={12} sm={11} alignContent='center'>
          <Grid container item xs={12} sm={3} alignContent='center' className={classes.rowText} style={mobile ? { color: '#999999' } : {}}>
            <FormattedDate date={nearmapOrder.date} />
          </Grid>

          <Grid container item xs={12} sm={4} alignContent='center'>
            <Typography className={classes.rowText} data-cy='order-address'>
              {nearmapOrder.jobAddress}
            </Typography>
          </Grid>
          <Grid container item xs={12} sm={2} alignContent='center'>
            <Typography className={classes.rowText} data-cy='order-cost'>
              ${nearmapOrder.cost ?? 0}.00
            </Typography>
          </Grid>
          {
            !mobile &&
            <Grid container item xs={12} sm={3} alignContent='center'>
              <OrderStatusChip
                sketchOrder={nearmapOrder}
              />
            </Grid>
          }
        </Grid>
        <Grid container item xs={mobile ? 12 : 1} direction='row' style={mobile ? { padding: 8 } : {}} justifyContent='space-between'>
          {
            mobile &&
            <Grid item xs={9} alignContent='center' >
              <OrderStatusChip
                sketchOrder={nearmapOrder}
              />
            </Grid>
          }
          <Grid container item xs={mobile ? 3 : 12} justifyContent={mobile ? 'flex-end' : 'center'} alignContent='center'>
            <Button onClick={handleClick}>
              <MoreVertIcon />
            </Button>
          </Grid>
        </Grid>
        <OptionsPopover
          handleClose={handleClose}
          anchorEl={anchorEl}
          // setUpdateModalOpen={setUpdateModalOpen}
          order={nearmapOrder}
          orderType={OrderTypes.Nearmap}
          onOrderCancelled={handleClose}
        />
        {/* TODO: nearmapOrder is no longer compatible enough with sketchOrder */}
        {/* {'sketchOrderStatus' in sketchOrder && (
        <UpdateOrderModal
          open={updateModalOpen}
          onClose={handleModalClose}
          sketchOrder={sketchOrder}
        />
      )} */}
      </Grid>
    </DataRow>
  );
};

export default withRouter(withStyles(styles)(NearmapRow));
