import { GET_OFFICES_BY_ORG_REQUEST, GET_OFFICES_BY_ORG_SUCCESS, GET_OFFICES_BY_ORG_FAILURE } from '../actions/Offices';

function organizationOffices(state = {
    totalResults: 0,
    totalPages: 0,
    currentPage: 1,
    data: [],
}, action) {
    switch (action.type) {
    case GET_OFFICES_BY_ORG_REQUEST:
        return Object.assign({}, state, {
        });
    case GET_OFFICES_BY_ORG_SUCCESS:
        return Object.assign({}, state, {
            totalResults: action.response.totalResults,
            totalPages: action.response.totalPages,
            currentPage: action.response.page,
            data: action.response.resourceList,
        });
    case GET_OFFICES_BY_ORG_FAILURE:
        return Object.assign({}, state, {
            totalResults: 0,
            totalPages: 0,
            currentPage: 0,
            data: [],
            errorMessage: action.message,
        });
    default:
        return state;
    }
}

export default organizationOffices;
