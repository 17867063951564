import React from 'react';
import { Chip, withStyles, WithStyles, createStyles } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Avatar from '@material-ui/core/Avatar';
import { OrderTypes } from 'lib/MeasurementOrders';

interface MeasurementOrderChipProps extends WithStyles<typeof styles> {
    orderType: string,
}

const styles = createStyles({
    sketchOsChip: {
        backgroundColor: amber[500],
        color: '#FFFFFF',
    },
})

const getOrderTypeLabelFromId = (type: string) => {
    // order types on the lib file are in all lower case
    const typeLowerCase = type.toLowerCase();
    if (typeLowerCase === OrderTypes.FullSnap) return 'Full Snap';
    if (typeLowerCase === OrderTypes.HalfSnap) return 'Half Snap';
    if (typeLowerCase === OrderTypes.MetalRoof) return 'Metal Roof';
    if (typeLowerCase === OrderTypes.Gutters) return 'Gutters';
    return 'Measurement Order';
}

const MeasurementOrderChip = (props: MeasurementOrderChipProps) => {
    const { classes, orderType } = props; 
    return (
        <Chip
            avatar={
                <Avatar className={classes.sketchOsChip}>
                    <CheckCircleIcon className={classes.sketchOsChip} />
                </Avatar>
            }
            className={classes.sketchOsChip}
            label={getOrderTypeLabelFromId(orderType)}
        />
  )
}

export default withStyles(styles)(MeasurementOrderChip)
