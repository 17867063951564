/* eslint-disable import/no-named-as-default */
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import AdminToolbar from '@roofsnap/AdminToolbar.tsx';
import RoofSnapLoggedInMenu from './RoofSnapLoggedInMenu';
import hostConfig from '../../lib/hostConfig.ts';
import Search from '../Search/Search';
import GetHelpMenu from '../GetHelpMenu/GetHelpMenu.tsx';


const style = () => ({
    buttons: {
        backgroundColor: 'transparent',
        color: '#ffffff',
    },
    buttonGroup: {
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
    },
    iconRight: {
        marginRight: 0,
        marginTop: 13,
    },
    title: {
        cursor: 'pointer',
    },
    menuButton: {
        marginLeft: -12,
    },
    searchButton: {
        padding: 0,
    },
    searchComponent: {
        position: 'absolute',
        width: '35%',
        left: '200px',
        top: '13px',
    },
});


class RoofSnapAppBar extends Component {
    render() {
        const { classes } = this.props;

        const rightButtons = (
            <div className={classes.buttonGroup}>
                <Hidden smUp>
                    <IconButton className={classes.searchButton} onClick={this.props.openSearchBar}>
                        <SearchIcon style={{ fill: '#ffffff' }} />
                    </IconButton>
                </Hidden>
                {this.props.buttons.map(elem => (
                    <Button
                        primary="false"
                        key={elem.buttonText}
                        onClick={elem.buttonAction}
                        className={classes.buttons}
                    >
                        {elem.buttonText}
                    </Button>
                ))}
                <RoofSnapLoggedInMenu signOutClick={this.props.signOutClick} />
                <GetHelpMenu />
            </div>
        );

        return (
            <AppBar position="static">
                <AdminToolbar />
                <Toolbar>
                    <IconButton aria-label="Menu" id="roofsnap-drawer-button" className={classes.menuButton} onClick={this.props.toggle}>
                        {/* TODO: Figure out how to get icons to use theme styling */}
                        <MenuIcon style={{ fill: '#ffffff' }} />
                    </IconButton>
                    <Typography variant="h6" color="inherit" className={classes.title} onClick={this.props.titleClick}>{hostConfig.siteName}</Typography>
                    <Hidden xsDown>
                        <div className={classes.searchComponent}>
                            <Search
                                placeholder="Find Project"
                                onSearch={this.props.onSearch}
                                onCancel={this.props.onCancel}
                            />
                        </div>
                    </Hidden>
                    {rightButtons}
                </Toolbar>
            </AppBar>
        );
    }
}

RoofSnapAppBar.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.shape({
        buttonText: PropTypes.string.isRequired,
        buttonAction: PropTypes.func.isRequired,
    })),
    toggle: PropTypes.func.isRequired,
    titleClick: PropTypes.func.isRequired,
    signOutClick: PropTypes.func.isRequired,
    classes: PropTypes.shape({
        buttons: PropTypes.string,
        buttonGroup: PropTypes.string,
        iconRight: PropTypes.string,
        title: PropTypes.string,
        menuButton: PropTypes.string,
        searchContainer: PropTypes.string,
    }).isRequired,
    onSearch: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

RoofSnapAppBar.defaultProps = {
    buttons: [],
};


export default withRouter(withStyles(style)(RoofSnapAppBar));
