import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RoofSnapState } from 'lib/Models/RoofSnapState';
import ProjectImage from 'lib/Models/ProjectImage';
import { getProjectImages, selectImage } from 'actions/ProjectImagesActions';
import Pager from 'components/Pager';

import { Box, Stack, styled, Typography } from '@mui/material';
import ImageCell from './ImageCell';
import AddImageButton from './AddImageButton';
import SectionTitle from '../SectionTitle';

type ProjectImagesProps = {
    projectId: string;
};

const Root = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin-bottom: 16px;
`;

const ImageGrid = styled('ul')`
    border-radius: 0.75rem;
    border: 1px solid var(--color-grey-200, #d9d9d9);
    background: #fff;
    padding: 1.25rem;
    background-color: #fff;
    display: flex;
    gap: 15px;
    justify-content: center;
    flex-wrap: wrap;
`;

const Subtitle = styled(Typography)`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
`;

const ProjectImagesSection = ({ projectId }: ProjectImagesProps) => {
    const dispatch = useDispatch();

    const {
        projectImages: { currentPage, totalPages, images },
    } = useSelector((state: RoofSnapState) => state);

    const changePage = (pageNumber: number) => {
        dispatch(getProjectImages(projectId, pageNumber, 12));
    };

    useEffect(() => {
        if (!projectId) return;

        changePage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    return (
        <Root>
            <Box sx={{ ml: '2px' }}>
                <SectionTitle title='Images' />
                <Subtitle>Add photos or file attachments</Subtitle>
            </Box>
            <ImageGrid>
                {images.length > 0 ? (
                    images.map((item) => (
                        <ImageCell
                            key={item.id}
                            projectImage={item}
                            selectImage={(image: ProjectImage) => {
                                dispatch(selectImage(image));
                            }}
                        />
                    ))
                ) : (
                    <Box>
                        <Typography>
                            You have no images for this project
                        </Typography>
                    </Box>
                )}
            </ImageGrid>
            <Stack
                alignItems='center'
                direction='row'
                justifyContent='space-between'
                width='100%'
            >
                <Box>
                    <Pager
                        totalPages={totalPages}
                        currentPage={currentPage}
                        nextClick={() => changePage(currentPage + 1)}
                        prevClick={() => changePage(currentPage - 1)}
                    />
                </Box>
                <AddImageButton projectId={projectId} pageSize={12} />
            </Stack>
        </Root>
    );
};

export default ProjectImagesSection;
