import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import featureFlags from 'lib/FeatureFlags';
import { RoofSnapState } from 'lib/Models/RoofSnapState';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const REPORT_TYPE_ALL = 0;
const REPORT_TYPE_FULLSNAP = 1;
const REPORT_TYPE_HALFSNAP = 2;
const REPORT_TYPE_METALROOF = 3;
const REPORT_TYPE_GUTTERS = 4;

const reportTypes = [
    {
        key: 'All',
        value: REPORT_TYPE_ALL,
    },
    {
        key: 'Full Snap',
        value: REPORT_TYPE_FULLSNAP,
    },
    {
        key: 'Half Snap',
        value: REPORT_TYPE_HALFSNAP,
    },
];

const metalRoofReportType = {
    key: 'Metal Roof',
    value: REPORT_TYPE_METALROOF,
};

const gutterReportType = {
    key: 'Gutters',
    value: REPORT_TYPE_GUTTERS,
};

const getReportTypeMenuItems = (
    enableGutters: boolean,
    enableMetalRoof: boolean
) => {
    const menuItems = [...reportTypes];

    if (enableMetalRoof) menuItems.push(metalRoofReportType);

    if (enableGutters) menuItems.push(gutterReportType);

    return menuItems;
};

const SketchOrderTypeFilter = ({
    onChange,
}: {
    onChange: (reportType: number) => void;
}) => {
    const [selectedReportType, setSelectedReportType] =
        useState<number>(REPORT_TYPE_ALL);

    const handleReportTypeChange = (event: SelectChangeEvent<number>) => {
        setSelectedReportType(event.target.value as number);
    };

    const {
        organizationFeatures: { enableMetalRoof, enableGutters },
    } = useSelector((state: RoofSnapState) => state);

    const menuItems = getReportTypeMenuItems(
        enableGutters && featureFlags.gutters,
        enableMetalRoof && featureFlags.metalRoof
    );

    useEffect(() => {
        onChange(selectedReportType);
    }, [selectedReportType, onChange]);

    return (
        <FormControl variant='standard' sx={{ width: 120, mb: 1 }}>
            <InputLabel id='filter-report-type-label'>
                Filter Report Type
            </InputLabel>
            <Select
                labelId='filter-report-type-label'
                id='filter-report-select'
                value={selectedReportType}
                onChange={handleReportTypeChange}
            >
                {menuItems.map((item) => (
                    <MenuItem key={item.key} value={item.value}>
                        {item.key}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SketchOrderTypeFilter;
