import { CALL_API, httpMethod } from '../middleware/api';

export const GET_ORGANIZATION_DELETED_PROJECTS_REQUEST = 'GET_ORGANIZATION_DELETED_PROJECTS_REQUEST';
export const GET_ORGANIZATION_DELETED_PROJECTS_SUCCESS = 'GET_ORGANIZATION_DELETED_PROJECTS_SUCCESS';
export const GET_ORGANIZATION_DELETED_PROJECTS_FAILURE = 'GET_ORGANIZATION_DELETED_PROJECTS_FAILURE';
export const RESTORE_DELETED_PROJECT_REQUEST = 'RESTORE_DELETED_PROJECT_REQUEST';
export const RESTORE_DELETED_PROJECT_SUCCESS = 'RESTORE_DELETED_PROJECT_SUCCESS';
export const RESTORE_DELETED_PROJECT_FAILURE = 'FAILURE';

export const getOrganizationDeletedProjects = (orgId, pageNumber) => ({
    [CALL_API]: {
        types: [
            GET_ORGANIZATION_DELETED_PROJECTS_REQUEST,
            GET_ORGANIZATION_DELETED_PROJECTS_SUCCESS,
            GET_ORGANIZATION_DELETED_PROJECTS_FAILURE,
        ],
        method: httpMethod.GET,
        endpoint: `organizations/${orgId}/deletedprojects?page=${pageNumber}`,
    },
});

export const restoreOrganizationDeletedProject = projectId => ({
    [CALL_API]: {
        types: [
            RESTORE_DELETED_PROJECT_REQUEST,
            RESTORE_DELETED_PROJECT_SUCCESS,
            RESTORE_DELETED_PROJECT_FAILURE,
        ],
        method: httpMethod.POST,
        endpoint: `projects/${projectId}/restore`,
    },
});
