import {
    GET_ORGANIZATION_DELETED_PROJECTS_REQUEST,
    GET_ORGANIZATION_DELETED_PROJECTS_SUCCESS,
    GET_ORGANIZATION_DELETED_PROJECTS_FAILURE,
    RESTORE_DELETED_PROJECT_REQUEST,
    RESTORE_DELETED_PROJECT_SUCCESS,
    RESTORE_DELETED_PROJECT_FAILURE,
} from '../actions/OrganizationDeletedProjectsActions';

function organizationDeletedProjects(
    state = {
        totalResults: 0,
        totalPages: 0,
        currentPage: 1,
        data: [],
        isFetching: false,
        isFetchingText: '',
    },
    action,
) {
    switch (action.type) {
    case GET_ORGANIZATION_DELETED_PROJECTS_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
            isFetchingText: 'Fetching deleted projects...',
        });
    case GET_ORGANIZATION_DELETED_PROJECTS_SUCCESS:
        return Object.assign({}, state, {
            totalResults: action.response.totalResults,
            totalPages: action.response.totalPages,
            currentPage: action.response.page,
            data: action.response.resourceList,
            isFetching: false,
            isFetchingText: '',
        });
    case GET_ORGANIZATION_DELETED_PROJECTS_FAILURE:
        return Object.assign({}, state, {
            totalResults: 0,
            totalPages: 0,
            currentPage: 0,
            data: [],
            errorMessage: action.message,
            isFetching: false,
            isFetchingText: '',
        });
    case RESTORE_DELETED_PROJECT_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
            isFetchingText: 'Restoring deleted project...',
        });
    case RESTORE_DELETED_PROJECT_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            isFetchingText: '',
        });
    case RESTORE_DELETED_PROJECT_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            isFetchingText: '',
        });
    default: return state;
    }
}

export default organizationDeletedProjects;
