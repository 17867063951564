import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@roofsnap/ckeditor5';
import LoggerWrapper from '../../lib/Logger';

// No typings were available for CKEditor so that's why this isn't typescript
const HTMLEditor = (props) => {
    const {
        content,
        onSave,
        onSaved,
        onSaveError,
        onChange,
        onReady,
        uploadConfiguration,
    } = props;

    // const [ckEditor, setCKEditor] = useState(null);

    const toolbarRef = useRef(null);

    // useEffect(() => { return () => {
    //     if (!ckEditor) {
    //         return;
    //     }
    //     ckEditor.ui.view.toolbar.element.remove();
    //     ckEditor.destroy();
    // }}, [ckEditor]);

    return (
        <div className="document-editor">
            <div className="document-editor__toolbar" ref={toolbarRef} />
            <div className="document-editor__editable-container">
                <CKEditor
                    editor={DecoupledEditor}
                    data={content}
                    config={{
                        tokens: [
                            '{TEXTINPUT}',
                            '{CHECKBOX}',
                            '{TOTAL}',
                            '{INSURERNAME}',
                            '{CLAIMNUMBER}',
                            '{ADJUSTORNAME}',
                            '{ADJUSTORCONTACT}',
                            '{SIGNATURE}',
                            '{CUSTOMERNAME}',
                            '{SUBTOTAL}',
                            '{TAXES}',
                        ],
                        autosave: {
                            waitingTime:
                                process.env
                                    .REACT_APP_HTML_EDITOR_AUTOSAVE_TIMEOUT,
                            save(editor) {
                                return onSave(editor.getData()).then(onSaved).catch(onSaveError);
                            },
                        },
                        simpleUpload: uploadConfiguration,
                    }}
                    onChange={onChange}
                    onReady={(editor) => {
                        // Because we are using the decoupled editor you have to add the toolbar manually
                        // https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html#using-the-document-editor-build
                        // https://ckeditor.com/docs/ckeditor5/latest/framework/guides/deep-dive/ui/document-editor.html#styles

                        toolbarRef.current.appendChild(editor.ui.view.toolbar.element);
                        // setCKEditor(editor);
                        onReady(editor);
                    }}
                    onError={(error, { willEditorRestart }) => {
                        LoggerWrapper.captureException(error);

                        if (willEditorRestart) {
                            this.editor.ui.view.toolbar.element.remove();
                        }
                    }}
                />
            </div>
        </div>
    );
};

HTMLEditor.propTypes = {
    content: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    onSaved: PropTypes.func,
    onSaveError: PropTypes.func,
    onChange: PropTypes.func,
    onReady: PropTypes.func,
    uploadConfiguration: PropTypes.shape({
        uploadUrl: PropTypes.string.isRequired,
        headers: PropTypes.objectOf(PropTypes.string),
    }),
};

HTMLEditor.defaultProps = {
    content: '',
    onSaved: () => {},
    onSaveError: () => {},
    onChange: () => {},
    onReady: () => {},
    uploadConfiguration: null,
};

export default HTMLEditor;
