import moment from 'moment';
import {
    GET_SUBSCRIPTION_REQUEST,
    GET_SUBSCRIPTION_SUCCESS,
    GET_SUBSCRIPTION_FAILURE,
} from '../actions/subscriptionActions';

const initialState = {
    isFetching: false,
};

const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_SUBSCRIPTION_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case GET_SUBSCRIPTION_SUCCESS: {
        const {
            firstBillingDate,
            id,
            name,
            nextBillAmount,
            nextBillingDate,
            organizationId,
            planId,
            status,
            termLength,
            trialDuration,
            isInTrial,
        } = action.response;
        return Object.assign({}, state, {
            id,
            planName: name,
            nextBillAmount,
            organizationId,
            planId,
            status,
            termLength,
            trialDuration,
            firstBillingDate: moment.utc(firstBillingDate),
            nextBillingDate: moment.utc(nextBillingDate),
            isInTrial,
            isFetching: false,
        });
    }
    case GET_SUBSCRIPTION_FAILURE:
        return Object.assign({}, initialState);
    default:
        return state;
    }
};

export default subscriptionReducer;
