import * as ActionTypes from '../actions/SketchOSActions';

const defaultState = {
    totalResults: 0,
    totalPages: 0,
    currentPage: 1,
    sketchOSOrders: [],
    failedSketchOrderIds: [],
};

function sketchOS(state = defaultState, action) {
    switch (action.type) {
    case ActionTypes.GET_SKETCHOS_BY_ORGANIZATION_REQUEST:
    case ActionTypes.GET_REQUESTED_OR_OWNED_SKETCH_ORDERS_REQUEST:
    case ActionTypes.GET_OPEN_REQUESTED_OR_OWNED_SKETCH_ORDERS_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
            isFetchingText: 'Loading Sketch Orders...',
            ...state,
        });
    case ActionTypes.GET_SKETCHOS_BY_ORGANIZATION_SUCCESS:
    case ActionTypes.GET_REQUESTED_OR_OWNED_SKETCH_ORDERS_SUCCESS:
    case ActionTypes.GET_OPEN_REQUESTED_OR_OWNED_SKETCH_ORDERS_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            isFetchingText: '',
            totalResults: action.response.totalResults,
            totalPages: action.response.totalPages,
            currentPage: action.response.page,
            sketchOSOrders: action.response.resourceList,
            failedSketchOrderIds: action.response.failedSketchOrderIds,
        });
    case ActionTypes.GET_SKETCHOS_BY_ORGANIZATION_FAILURE:
    case ActionTypes.GET_REQUESTED_OR_OWNED_SKETCH_ORDERS_FAILURE:
    case ActionTypes.GET_OPEN_REQUESTED_OR_OWNED_SKETCH_ORDERS_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            isFetchingText: '',
            totalResults: 0,
            totalPages: 0,
            currentPage: 1,
            sketchOSOrders: [],
            failedSketchOrderIds: [],
        });
    default:
        return state;
    }
}

export default sketchOS;
