import React from 'react';
import PropTypes from 'prop-types';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from 'react-google-maps';

const PinConfirmationMap = props => (
    <GoogleMap
        center={props.center}
        options={{
            mapTypeId: 'hybrid',
            mapTypeControl: false,
            tilt: 0,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            panControl: false,
            zoomControl: false,
            gestureHandling: 'none',
            draggableCursor: 'pointer',
        }}
        zoom={20}
    >
        <Marker position={props.center} onClick={() => props.handlePinClicked()} />
    </GoogleMap>
);
PinConfirmationMap.propTypes = {
    center: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }).isRequired,
    handlePinClicked: PropTypes.func,
};

PinConfirmationMap.defaultProps = {
    handlePinClicked: () => {},
};

export default (withScriptjs(withGoogleMap(PinConfirmationMap)));
