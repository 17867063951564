import React, { useContext } from 'react'
import { connect } from 'react-redux';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core'
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles'
import SettingsContainer from '../SettingsComponents/SettingsContainer';
import SettingsComponent from '../SettingsComponents/SettingsComponent';
import RowContainer from '../SettingsComponents/RowContainer';
import SettingSwitch from '../SettingsComponents/SettingSwitch';
import OfficeContext from '../OfficeContext';
import { ReactElement } from 'react';
import { useOfficeSwitch } from '../useOfficeSwitch';


interface ContractsProps extends WithStyles<typeof styles> {
    onSuccess: () => void
    editor: ReactElement
    dispatch: any;
}

const styles = () =>
    createStyles({
        root: {
            height: '100%',
            font: 'Roboto',
        },
        header: {
            font: 'normal normal bold 34px/20px Roboto',
            letterSpacing: 0.3,
            color: '#222222',
            padding: '20px 0px',
        },
        termsDescription: {
            font: 'normal normal normal 16px/24px Roboto',
            letterSpacing: 0.62,
            color: '#222222',
        },
        section: {
            padding: '10px 0px',
        },
        sectionTitle: {
            font: 'normal normal bold 20px Roboto',
            letterSpacing: 0.21,
            color: '#222222',
            padding: '25px 0px 15px 0px',
        },
        editorMobile: {
            width: '90vw',
        },

        documentTitle: {
            font: '20px Roboto',
            letterSpacing: 0.18,
            fontWeight: 600,
            color: '#222222',
        },
        settingsRow: {
            padding: '25px 15px 25px 20px',
            font: '17px Roboto',
            borderBottom: '1px solid #00112219'
        },
        settingDescription: {
            font: 'normal normal normal 13px Roboto',
            letterSpacing: '0.5px',
            color: '#000000DE',
            opacity: '0.5',
            paddingTop: '10px',
        },
    })

const Contracts = (props: ContractsProps) => {

    const { office } = useContext(OfficeContext);
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { classes } = props
    const { handleSwitch } = useOfficeSwitch({ onSuccess: props.onSuccess });

    return (
        <Grid item container direction='column' xs={12}>
            <Grid
                item
                container
                direction='row'
                className={classes.section}
            >

                <SettingsContainer
                    title='Contract'
                    description={`The options below are specific to contracts generated for ${office.officeName}.`}
                    sectionTitleStyle={classes.header}
                    descriptionStyle={classes.termsDescription}
                />
            </Grid>

            <Grid
                item
                container
                direction='row'
                className={classes.section}>
                <SettingsContainer
                    title='Cover Page'
                    sectionTitleStyle={classes.sectionTitle}
                >
                    <RowContainer>
                        <SettingsComponent
                            componentType='switch'
                            settingsRowProps={{
                                settingTitle: 'Project Name',
                                rowStyle: classes.settingsRow,
                                descriptionStyle: classes.settingDescription,
                                settingComponent:
                                    <SettingSwitch
                                        checked={!office.hideContractProjectName ?? false}
                                        onChange={(event: any) => handleSwitch(event)}
                                        name='hideContractProjectName'
                                    />
                            }}
                        />
                    </RowContainer>
                </SettingsContainer>
            </Grid>

            <Grid
                item
                container
                direction='row'
                className={classes.section}>
                <SettingsContainer
                    title='Contract Visibility Settings'
                    description='Choose which details to show or to hide in your Contract Documents'
                    sectionTitleStyle={classes.sectionTitle}
                >
                    <RowContainer>
                        <SettingsComponent
                            componentType='switch'
                            settingsRowProps={{
                                settingTitle: 'All Material and Labor Quantities',
                                rowStyle: classes.settingsRow,
                                descriptionStyle: classes.settingDescription,
                                settingComponent:
                                    <SettingSwitch
                                        checked={!office.hideContractQty ?? false}
                                        onChange={(event: any) => handleSwitch(event)}
                                        name='hideContractQty'
                                    />
                            }}
                        />
                        <SettingsComponent
                            componentType='switch'
                            settingsRowProps={{
                                settingTitle: 'Insurance Proceeds Instead of Costs',
                                rowStyle: classes.settingsRow,
                                descriptionStyle: classes.settingDescription,
                                settingComponent:
                                    <SettingSwitch
                                        checked={office.hideTotalPrice ?? false}
                                        onChange={(event: any) => handleSwitch(event)}
                                        name='hideTotalPrice'
                                    />
                            }}
                        />
                    </RowContainer>
                </SettingsContainer>
            </Grid>

            <Grid container>
                <SettingsContainer
                    title='Contract Terms'
                    description={`The terms you input here will populate on the contract PDFs generated in your projects' Documents menu. Insert images, page breaks, and dictate text formatting from the toolbar. Tokens, such as text input fields, signature blocks, and the contract Total, can be inserted by selecting from the Tokens drop down list.`}
                    sectionTitleStyle={classes.sectionTitle}
                    descriptionStyle={classes.termsDescription}
                >
                    <Grid item container>
                        <Grid item xs={12} className={mobile ? classes.editorMobile : classes.section}>
                            {props.editor}
                        </Grid>
                    </Grid>
                </SettingsContainer>
            </Grid>
        </Grid >
    )
}

export default connect()(withStyles(styles)(Contracts))