interface Heap {
    track: (event: string, properties?: Object) => void;

    identify: (identity: string) => void;

    resetIdentity: () => void;

    addUserProperties: (properties: Object) => void;

    addEventProperties: (properties: Object) => void;

    removeEventProperty: (property: string) => void;

    clearEventProperties: () => void;

    appid: string;

    userId: string;

    identity: string | null;
}

const heap = (window as any).heap as Heap | undefined;

export default heap;
