export const mobileOperatingSystem = {
    Android: 'Android',
    iOS: 'iOS',
};

export default function getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/Android/i.test(userAgent)) {
        return mobileOperatingSystem.Android;
    }

    if (/iPhone|iPad|iPod/i.test(userAgent)) {
        return mobileOperatingSystem.iOS;
    }

    return null;
}
