import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import Button from '@material-ui/core/Button';
import MobileBadges from '../MobileBadges';

const MobileWarningDialog = props => (
    <Dialog
        fullScreen={props.fullScreen}
        open={props.open}
        onClose={props.continue}
        aria-labelledby="responsive-dialog-title"
    >
        <DialogTitle id="responsive-dialog-title">
            This feature is not yet optimized for mobile devices.
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                The engineers at RoofSnap are working to make this the best
                possible experience for you. This feature is not up to par to
                our standards for user experience yet, so we suggest using the
                mobile app instead.
            </DialogContentText>
            <MobileBadges />
        </DialogContent>
        <DialogActions>
            <Button onClick={props.goBack} color="primary">
                Go Back
            </Button>
            <Button onClick={props.continue} color="primary" autoFocus>
                Continue
            </Button>
        </DialogActions>
    </Dialog>
);

MobileWarningDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    continue: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
};

export default withMobileDialog()(MobileWarningDialog);
