import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import RepeatIcon from '@material-ui/icons/Repeat';
import LocationIcon from '@material-ui/icons/LocationCity';
import GroupIcon from '@material-ui/icons/Group';
import Navigation from '../../components/Account/Navigation';
import SubscriberContent from '../../components/Account/SubscriberContent';
import NonSubscriberContent from '../../components/Account/NonSubscriberContent';
import { getSubscription } from '../../actions/subscriptionActions';

const styles = theme => ({
    root: {
    },
    nav: {
        position: 'fixed',
        [theme.breakpoints.down('sm')]: {
            position: 'inherit',
        },
    },
    contentRoot: {
        marginLeft: 280,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'inherit',
        },
    },
    contentLayout: {
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingRight: theme.spacing(6),
        paddingLeft: theme.spacing(6),
        maxWidth: 1200,
        [theme.breakpoints.down('sm')]: {
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
        },
    },
    content: {
        maxWidth: 920,
    },
});

class Account extends Component {
    state = {
        activeNavigationLink: 0,
    };

    componentWillMount() {
        this.props.dispatch(getSubscription(this.props.organizationId, this.props.subscriptionId));
    }

    handleActivateButtonClick = () => {
        this.props.history.push('/activate');
    };

    handleNavigationChange = () => {
        // Don't worry about commenting on this.  It'll get handled in a subsequent PR.
    };

    render() {
        const {
            nextBillAmount,
            nextBillingDate,
            width,
            classes,
            status,
            planName,
            termLength,
            isSneakPeek,
            isInTrial,
        } = this.props;
        const isMobile = width === 'sm' || width === 'xs';
        return (

            <div className={classes.root}>
                <div className={classes.nav}>
                    <Navigation
                        isMobile={isMobile}
                        links={[
                            { icon: <RepeatIcon />, label: 'Subscription & services', key: 'subscription' },
                            { icon: <LocationIcon />, label: 'Offices', key: 'offices' },
                            { icon: <GroupIcon />, label: 'Users', key: 'users' },
                        ]}
                        onChange={this.handleNavigationChange}
                        activeLink={this.state.activeNavigationLink}
                    />
                </div>
                <div className={classes.contentRoot}>
                    <div className={classes.contentLayout}>
                        <div className={classes.content}>
                            {
                                isSneakPeek ?
                                    <NonSubscriberContent
                                        onActivateButtonClick={this.handleActivateButtonClick}
                                    />
                                    :
                                    <SubscriberContent
                                        nextBillingDate={nextBillingDate}
                                        nextBillAmount={nextBillAmount}
                                        status={status}
                                        planName={planName}
                                        termLength={termLength}
                                        isInTrial={isInTrial}
                                    />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Account.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
    }).isRequired,
    width: PropTypes.string.isRequired,
    organizationId: PropTypes.number.isRequired,
    subscriptionId: PropTypes.string.isRequired,
    nextBillingDate: PropTypes.instanceOf(moment),
    nextBillAmount: PropTypes.number,
    status: PropTypes.string,
    planName: PropTypes.string,
    termLength: PropTypes.number,
    isSneakPeek: PropTypes.bool,
    isInTrial: PropTypes.bool,
};

Account.defaultProps = {
    nextBillAmount: null,
    nextBillingDate: null,
    status: null,
    planName: null,
    isSneakPeek: null,
    isInTrial: null,
    termLength: null,
};

const mapStateToProps = (state) => {
    const { organization } = state;
    const { subscription } = state;

    const {
        id: organizationId,
        paymentSubscriptionId: subscriptionId,
        status,
    } = organization;
    return {
        organizationId,
        subscriptionId,
        isSneakPeek: status === 'SneakPeak',
        ...subscription,
    };
};

export default withRouter(connect(mapStateToProps)(withWidth()(withStyles(styles)(Account))));
