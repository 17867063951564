import React, { ChangeEvent, MutableRefObject, useState } from 'react';
import { SelectChangeEvent, Stack } from '@mui/material';
import { Project } from 'lib/Models';
import formSections from './FormSections';
import validateProject, {
    ProjectValidationResult,
} from 'lib/util/ProjectValidator';

type ProjectFormProps = {
    projectRef: MutableRefObject<Partial<Project>>;
};

const ProjectForm = ({ projectRef }: ProjectFormProps) => {
    const [validationResult, setValidationResult] =
        useState<ProjectValidationResult>();

    const handleChange = (
        event:
            | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | SelectChangeEvent<any>
    ) => {
        projectRef.current = {
            ...projectRef.current,
            [event.target.name]: event.target.value,
        };

        setValidationResult(validateProject(projectRef.current));
    };

    return projectRef ? (
        <>
            {formSections.map((Section, i) => (
                <Section
                    key={`ProjectDetailsFormSection${i}`}
                    project={projectRef.current}
                    validationResults={validationResult}
                    onChange={handleChange}
                />
            ))}
        </>
    ) : (
        <Stack />
    );
};

export default ProjectForm;
