import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Board from 'react-trello';
import Typography from '@material-ui/core/Typography';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import Button from '@material-ui/core/Button';
import ProjectCard from './ProjectCard';
import './ProjectBoard.css';
import {
    CREATE_PROJECT_SUCCESS,
    DELETE_PROJECT_SUCCESS,
} from '../../actions/ProjectActions';
import CreateProjectDialog from '../CreateProject/CreateProjectDialog';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import ProjectDialog from './ProjectDialog';

const style = {
    laneHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
};

const getBoardContractBase = () => ({
    lanes: [
        {
            id: 'NewLead',
            title: 'New Lead',
            cards: [],
        },
        {
            id: 'InspectionScheduled',
            title: 'Inspection Scheduled',
            cards: [],
        },
        {
            id: 'Inspected',
            title: 'Inspected',
            cards: [],
        },
        {
            id: 'AdjustmentScheduled',
            title: 'Adjustment Scheduled',
            cards: [],
        },
        {
            id: 'Approved',
            title: 'Approved',
            cards: [],
        },
        {
            id: 'ReadyToBuild',
            title: 'Ready to Build',
            cards: [],
        },
        {
            id: 'PunchOut',
            title: 'Punch Out',
            cards: [],
        },
        {
            id: 'WorkCompleted',
            title: 'Work Completed',
            cards: [],
        },
        {
            id: 'Dead',
            title: 'Dead',
            cards: [],
        },
        {
            id: 'Closed',
            title: 'Closed',
            cards: [],
        },
    ],
});

const massageProjectData = (projects) => {
    const boardContract = getBoardContractBase();
    projects.forEach((project) => {
        const proj = Object.assign({}, project);
        const idx = findIndex(
            boardContract.lanes,
            element => element.id === project.projectStatus,
        );
        if (idx !== -1) {
            proj.laneId = boardContract.lanes[idx].id;
            boardContract.lanes[idx].cards.push(proj);
        }
    });
    return boardContract;
};

const CustomLaneHeader = ({ title, openProject, id }) => (
    <div style={style.laneHeader}>
        <Typography variant="subtitle1">{title}</Typography>
        <Button color="secondary" onClick={() => openProject(id)}>
            Create Project
        </Button>
    </div>
);

CustomLaneHeader.propTypes = {
    title: PropTypes.string.isRequired,
    openProject: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
};

class ProjectBoard extends Component {
    state = {
        cardData: getBoardContractBase(),
        showCreate: false,
        projectStatus: '',
        showConfirmationModal: false,
    };
    componentDidMount() {
        this.handleDataLoad();
        this.props.toggleToolbar();
    }

    componentWillUnmount() {
        this.props.toggleToolbar();
    }

    handleOpenProject = (laneId) => {
        this.setState(prevState => ({
            showCreate: !prevState.showCreate,
            projectStatus: laneId,
        }));
    };
    handleDataLoad = async () => {
        this.props.getOrganizationOffices(this.props.organizationId);
        const projects = await this.props.getProjects(this.props.organizationId);
        this.setState({ cardData: massageProjectData(projects), projects });
    };

    handleCardDrag = async (
        cardId,
        sourceLaneId,
        targetLaneId,
        position,
        cardDetails,
    ) => {
        let projectFromLocalState;
        if (sourceLaneId === targetLaneId) {
            return null;
        }
        if (this.state.updatedProjects) {
            projectFromLocalState = find(
                this.state.updatedProjects,
                obj => obj.id === cardDetails.id,
            );
        }
        const updatedProject = Object.assign({}, cardDetails, {
            projectStatus: targetLaneId,
            version: projectFromLocalState
                ? projectFromLocalState.version
                : cardDetails.version,
        });
        const projectUpdate = await this.props.updateProjectStatus(updatedProject);
        if (projectUpdate.type === 'UPDATE_PROJECT_SUCCESS') {
            const projectList = await this.props.getProjects(this.props.organizationId);
            if (projectList.length > 0) {
                this.setState({
                    updatedProjects: projectList,
                    projects: projectList,
                });
            }
        }
        return projectUpdate;
    };

    handleMissingOfficesCallback = () => {
        if (this.props.offices.length === 0) {
            this.props.handleMissingOffices();
        }
    };

    handleCloseDialog = () => {
        this.setState({
            showCreate: false,
            projectStatus: '',
        });
    };

    handleCreateClick = async (project) => {
        const newProject = Object.assign({}, project);
        newProject.Owner = this.props.userName;
        newProject.OrganizationId = this.props.organizationId;
        const newProjectResult = await this.props.createProject(newProject);
        if (newProjectResult.type === CREATE_PROJECT_SUCCESS) {
            const projectList = [
                ...this.state.projects,
                newProjectResult.response,
            ];
            this.setState({
                cardData: massageProjectData(projectList),
                projects: projectList,
            });
            this.handleCloseDialog();
        }
    };

    toggleConfirmationModal = (id) => {
        this.setState(prevState => ({
            showConfirmationModal: !prevState.showConfirmationModal,
            projectIdToDelete: id,
        }));
    };

    toggleProjectDialog = () => {
        this.props.history.push({
            pathname: '/project-board',
        });
    };

    openProjectDialogRoute = (project) => {
        const {
            cardStyle,
            classes,
            dragStyle,
            links,
            onClick,
            onDelete,
            openProjectDialog,
            removeCard,
            ...currentProject
        } = project;
        this.props.history.push({
            pathname: `/project-board/${currentProject.id}`,
            state: { currentProject },
        });
    };

    deleteProject = async () => {
        const deleteProjectResponse = await this.props.deleteProjectById(this.state.projectIdToDelete);

        if (deleteProjectResponse.type === DELETE_PROJECT_SUCCESS) {
            const alteredProjectList = this.removeFromData(
                this.state.projectIdToDelete,
                this.state.projects,
            );
            this.setState({
                cardData: massageProjectData(alteredProjectList),
                projects: alteredProjectList,
            });
        }
        this.toggleConfirmationModal(null);
        this.toggleProjectDialog();
    };

    removeFromData = (id, data) => data.filter(project => project.id !== id);

    render() {
        return (
            <div>
                <Board
                    data={this.state.cardData}
                    customCardLayout
                    cardDraggable
                    draggable
                    laneDraggable={false}
                    handleDragEnd={this.handleCardDrag}
                    customLaneHeader={
                        <CustomLaneHeader
                            openProject={this.handleOpenProject}
                        />
                    }
                >
                    <ProjectCard
                        openProjectDialog={this.openProjectDialogRoute}
                    />
                </Board>
                {this.state.showCreate && (
                    <CreateProjectDialog
                        open={this.state.showCreate}
                        onCreateClick={this.handleCreateClick}
                        onCancelClick={this.handleCloseDialog}
                        offices={this.props.offices}
                        showMissingOfficesMessage={
                            this.handleMissingOfficesCallback
                        }
                        projectStatus={this.state.projectStatus}
                    />
                )}
                <ConfirmationModal
                    title="Delete Project"
                    description="Are you sure you want to delete this project?"
                    handleConfirm={this.deleteProject}
                    confirmText="Delete"
                    isOpen={this.state.showConfirmationModal}
                    handleClose={this.toggleConfirmationModal}
                />
                {this.props.location.state &&
                    this.props.location.state.currentProject && (
                    <ProjectDialog
                        offices={this.props.offices}
                        currentProject={
                            this.props.location.state.currentProject
                        }
                        isOpen
                        handleClose={() => this.toggleProjectDialog()}
                        openDeleteConfirmation={
                            this.toggleConfirmationModal
                        }
                        history={this.props.history}
                    />
                )}
            </div>
        );
    }
}

ProjectBoard.propTypes = {
    createProject: PropTypes.func.isRequired,
    deleteProjectById: PropTypes.func.isRequired,
    toggleToolbar: PropTypes.func.isRequired,
    getProjects: PropTypes.func.isRequired,
    getOrganizationOffices: PropTypes.func.isRequired,
    handleMissingOffices: PropTypes.func.isRequired,
    updateProjectStatus: PropTypes.func.isRequired,
    userName: PropTypes.string.isRequired,
    organizationId: PropTypes.number.isRequired,
    offices: PropTypes.arrayOf(PropTypes.shape).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.string.isRequired,
    }).isRequired,
};

export default ProjectBoard;
