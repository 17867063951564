import { CALL_API } from '../middleware/api';

export const GET_SUBSCRIPTION_REQUEST = 'GET_SUBSCRIPTION_REQUEST';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_FAILURE = 'GET_SUBSCRIPTION_FAILURE';

export const getSubscription = (organizationId, subscriptionId) => ({
    [CALL_API]: {
        types: [
            GET_SUBSCRIPTION_REQUEST,
            GET_SUBSCRIPTION_SUCCESS,
            GET_SUBSCRIPTION_FAILURE,
        ],
        endpoint: `organizations/${organizationId}/account/subscriptions/${subscriptionId}`,
        apiVersion: 'v1',
    },
});
