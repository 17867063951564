import { CALL_API } from '../middleware/api';
import LocalStorageWrapper from '../lib/LocalStorageWrapper';
import decode from '../lib/decodeRoofSnapAuthToken';

export const GET_ORGANIZATION_FEATURES_REQUEST = 'GET_ORGANIZATION_FEATURES_REQUEST';
export const GET_ORGANIZATION_FEATURES_SUCCESS = 'GET_ORGANIZATION_FEATURES_SUCCESS';
export const GET_ORGANIZATION_FEATURES_FAILURE = 'GET_ORGANIZATION_FEATURES_FAILURE';

export const GET_ORGANIZATION_FEATURES_REQUEST_BACKGROUND = 'GET_ORGANIZATION_FEATURES_REQUEST_BACKGROUND';
export const GET_ORGANIZATION_FEATURES_SUCCESS_BACKGROUND = 'GET_ORGANIZATION_FEATURES_SUCCESS_BACKGROUND';
export const GET_ORGANIZATION_FEATURES_FAILURE_BACKGROUND = 'GET_ORGANIZATION_FEATURES_FAILURE_BACKGROUND';

export const getOrganizationFeatures = (requestInBackground = false) => {
    const accessToken = LocalStorageWrapper.getItem('accessToken');
    const { organizationId } = decode(accessToken);

    if (!organizationId) {
        return {
            type: GET_ORGANIZATION_FEATURES_FAILURE,
        };
    }

    const requestTypes = [GET_ORGANIZATION_FEATURES_REQUEST, GET_ORGANIZATION_FEATURES_SUCCESS, GET_ORGANIZATION_FEATURES_FAILURE];
    const backgroundRequestTypes = [GET_ORGANIZATION_FEATURES_REQUEST_BACKGROUND, GET_ORGANIZATION_FEATURES_SUCCESS_BACKGROUND, GET_ORGANIZATION_FEATURES_FAILURE_BACKGROUND];

    return {
        [CALL_API]: {
            types: requestInBackground ? backgroundRequestTypes : requestTypes,
            endpoint: `organizations/${organizationId}/features`,
        },
    };
};
