import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { createDocumentSharedAccessToken } from '../../actions/documentSharedAccessTokenActions';

class DocumentDownload extends Component {
    constructor(props) {
        super(props);
        this.state = { sasTokenFailed: false };
    }

    componentDidMount = async () => {
        await this.props.dispatch(createDocumentSharedAccessToken(
            this.props.match.params.documentId,
            this.props.match.params.renderingId,
        ));
        if (this.props.documentSharedAccessToken.sharedAccessSignatureUrl) {
            window.location.replace(this.props.documentSharedAccessToken.sharedAccessSignatureUrl);
        } else {
            this.setState({
                sasTokenFailed: true,
            });
        }
    }
    render() {
        if (this.state.sasTokenFailed) {
            return (
                <Redirect to={`/projects/${this.props.match.params.id}/documents`} />
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => {
    const {
        documentSharedAccessToken,
    } = state;
    return {
        documentSharedAccessToken,
    };
};

DocumentDownload.defaultProps = {
    documentSharedAccessToken: { sharedAccessSignatureUrl: '' },
};

DocumentDownload.propTypes = {
    dispatch: PropTypes.func.isRequired,
    documentSharedAccessToken: PropTypes.shape({
        sharedAccessSignatureUrl: PropTypes.string.isRequired,
    }),
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            id: PropTypes.string,
            documentId: PropTypes.string,
            renderingId: PropTypes.string,
        }),
    }).isRequired,
};

export default connect(mapStateToProps)(DocumentDownload);
