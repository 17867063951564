import React from 'react';
import { Link } from 'react-router-dom';
import ProjectImage from 'lib/Models/ProjectImage';
import { ImageListItem, ImageListItemBar, styled } from '@mui/material';
import { DateTime } from 'luxon';
import { Typography } from '@material-ui/core';

type ImageCellProps = {
    projectImage: ProjectImage;
    selectImage: (image: ProjectImage) => void;
};

const Image = styled('img')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        height: '9rem',
        width: '9rem',
    },
    [theme.breakpoints.up('sm')]: {
        height: '10rem',
        width: '10rem',
    },
    [theme.breakpoints.up('xl')]: {
        height: '13.5rem',
        width: '13.5rem',
    },
    borderRadius: '0.25rem',
    border: '1px solid var(--color-grey-200, #ccc)',
    objectFit: 'cover',
    objectPosition: 'center',
}));

const ImageTitleBar = styled(ImageListItemBar)({
    textOverflow: 'ellipsis',
    '& .MuiImageListItemBar-titleWrap': {
        padding: 0,
    },
});

const ImageCell = ({ projectImage, selectImage }: ImageCellProps) => {
    const createdDate = DateTime.fromISO(projectImage.createdAt);
    const updatedDate = DateTime.fromISO(projectImage.updatedAt);

    return (
        <ImageListItem
            onClick={() => selectImage(projectImage)}
            sx={{ width: { xs: '9rem', sm: '10rem', xl: '13.5rem' } }}
        >
            <Link
                to={{
                    pathname: `/projects/${projectImage.projectId}/images/${projectImage.id}`,
                }}
                href={`/projects/${projectImage.projectId}/images/${projectImage.id}`}
            >
                <Image
                    src={`${
                        projectImage.imageUrl
                    }?cacheBuster9000=${updatedDate.toUnixInteger()}`}
                    alt='Project'
                />
            </Link>
            <ImageTitleBar
                subtitle={
                    <Typography variant='body2'>
                        {createdDate.toLocaleString(DateTime.DATE_SHORT)}{' '}
                        &mdash;{' '}
                        {createdDate.toLocaleString(DateTime.TIME_SIMPLE)}
                    </Typography>
                }
                position='below'
            />
        </ImageListItem>
    );
};

export default ImageCell;
