import { ChangeEvent } from 'react';
import { Box, SelectChangeEvent, styled } from '@mui/material';
import { Project } from 'lib/Models';
import { ProjectValidationResult } from 'lib/util/ProjectValidator';

export type SectionProps = {
    project: Partial<Project>;
    validationResults?: ProjectValidationResult;
    onChange: (
        event:
            | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | SelectChangeEvent<any>
    ) => void;
};

const Section = styled(Box)({
    '& *': {
        margin: '0.625rem 0',
    },
});

export default Section;
