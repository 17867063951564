import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const PhoneIcon = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox='0 0 32 32'>
        <path d='M25.8703 27.3337C23.4566 27.3337 20.99 26.7412 18.4707 25.5563C15.9514 24.3714 13.6132 22.6983 11.4563 20.537C9.29924 18.3759 7.62824 16.0378 6.44335 13.5227C5.25846 11.0075 4.66602 8.5431 4.66602 6.12933C4.66602 5.71155 4.80413 5.36344 5.08035 5.08499C5.35635 4.80632 5.70146 4.66699 6.11568 4.66699H10.1013C10.4453 4.66699 10.74 4.7791 10.9853 5.00333C11.2305 5.22755 11.3972 5.51321 11.4857 5.86033L12.2703 9.54966C12.3188 9.89521 12.3077 10.201 12.237 10.467C12.1663 10.733 12.0347 10.9567 11.842 11.138L8.71802 14.1797C9.28002 15.1628 9.88757 16.0792 10.5407 16.929C11.194 17.7788 11.9008 18.5812 12.661 19.3363C13.4586 20.1488 14.307 20.8943 15.2063 21.573C16.1055 22.2517 17.0703 22.873 18.101 23.437L21.1157 20.356C21.3332 20.1207 21.5832 19.9602 21.8657 19.8747C22.1481 19.7893 22.4422 19.7689 22.748 19.8133L26.1393 20.5397C26.4909 20.6228 26.7777 20.8005 26.9997 21.073C27.2217 21.3452 27.3327 21.6538 27.3327 21.9987V25.884C27.3327 26.2982 27.1933 26.6433 26.9147 26.9193C26.6362 27.1955 26.2881 27.3337 25.8703 27.3337ZM7.89835 12.6347L10.526 10.096C10.5629 10.0618 10.5878 10.0148 10.6007 9.95499C10.6136 9.89522 10.6142 9.83966 10.6027 9.78833L9.92402 6.54732C9.91268 6.47888 9.88424 6.42755 9.83868 6.39332C9.79313 6.35932 9.73613 6.34233 9.66768 6.34233H6.52768C6.47635 6.34233 6.43357 6.35932 6.39935 6.39332C6.36513 6.42755 6.34802 6.47033 6.34802 6.52166C6.38335 7.44188 6.52879 8.40744 6.78435 9.41832C7.0399 10.4292 7.41124 11.5013 7.89835 12.6347ZM19.683 24.2347C20.585 24.6704 21.5572 25.0079 22.5997 25.247C23.6421 25.4859 24.6016 25.6213 25.478 25.6533C25.5293 25.6533 25.5721 25.6362 25.6064 25.602C25.6404 25.5678 25.6573 25.525 25.6573 25.4737V22.3577C25.6573 22.2892 25.6404 22.2322 25.6064 22.1867C25.5721 22.1411 25.5208 22.1127 25.4524 22.1013L22.5037 21.496C22.4524 21.4847 22.4075 21.4854 22.369 21.4983C22.3306 21.511 22.29 21.5359 22.2474 21.573L19.683 24.2347Z' />
    </SvgIcon>
);

export default PhoneIcon;
