import dateFormat from 'dateformat';

export const projectAddressSingleLine = (project) => {
    let address = (!project.customerAddress || project.customerAddress.length === 0) ? '' : `${project.customerAddress.trim()}, `;
    address += (!project.customerCity || project.customerCity.length === 0) ? '' : `${project.customerCity.trim()}, `;
    address += (!project.customerState || project.customerState.length === 0) ? '' : `${project.customerState.trim()} `;
    address += (!project.customerZip || project.customerZip.length === 0) ? '' : project.customerZip.trim();
    return address.length === 0 ? '[no address]' : address;
};

export const projectName = project => project.projectName || `Project from ${dateFormat(project.createdAt, 'shortDate')}`;
