import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Container } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Close from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import LockableElement from '../LockableElement';
import { ReactComponent as CreateProjectSVG } from '../../create-project.svg';
import { ReactComponent as OrderSketchSVG } from '../../sketch-order.svg';

const style = (theme) => ({
    root: {
        [theme.breakpoints.down('xs')]: {
            height: '100vh',
            position: 'fixed',
            bottom: 0,
        },
        [theme.breakpoints.up('sm')]: {
            height: '60vh',
            width: '70vw',
        },
        [theme.breakpoints.up('md')]: {
            height: '60vh',
            width: '50vw',
        },
        [theme.breakpoints.up('lg')]: {
            height: '550px',
            width: '40vw',
        },
    },
    gridContainer: {
        justifyContent: 'space-around',
        marginBottom: 8,
        marginTop: 12,
    },
    gridItem: {
        [theme.breakpoints.down('sm')]: {
            width: 140,
        },
        [theme.breakpoints.up('md')]: {
            padding: 12,
            width: 216,
        },
    },
    darkerText: {
        fontWeight: 500,
    },
    dialogTitle: {
        paddingBottom: 0,
    },
    dialogContent: {
        marginTop: 30,
        paddingTop: 0,
        display: 'grid',
        alignContent: 'center',
    },
    mobileClose: {
        marginLeft: -16,
    },
    optionCardRoot: {
        width: '100%',
        marginBottom: 8,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    optionCardMedia: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#C5DCF0',
        height: 172,
    },
    optionCardTitle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },
    },
    optionCardText: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
});

const OptionCard = (props) => (
    <Card
        className={props.classes.cardRoot}
        id={props.id}
        raised
        onClick={props.onClick}
    >
        <CardMedia className={props.classes.cardMedia}>{props.image}</CardMedia>
        <CardContent style={{ paddingBottom: 16 }}>
            <Typography className={props.classes.cardTitle} noWrap variant='h6'>
                {props.title}
            </Typography>
            <Typography
                className={props.classes.cardText}
                noWrap
                variant='body2'
            >
                {props.text}
            </Typography>
        </CardContent>
    </Card>
);

const CreateProjectOptionDialog = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Dialog
            PaperProps={{ className: props.classes.root }}
            fullScreen={fullScreen}
            open={props.open}
        >
            <DialogTitle className={props.classes.dialogTitle}>
                {fullScreen && (
                    <IconButton
                        className={props.classes.mobileClose}
                        onClick={props.onCancelClick}
                    >
                        <Close style={{ fill: '#000' }} />
                    </IconButton>
                )}
                Add New Measurement
            </DialogTitle>
            <DialogContent className={props.classes.dialogContent}>
                <Container>
                    <Grid
                        container
                        className={props.classes.gridContainer}
                        spacing={4}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className={props.classes.gridItem}
                        >
                            <LockableElement
                                onClick={
                                    !props.isFreeAccount
                                        ? props.onCreateProjectClick
                                        : null
                                }
                                locked={props.isFreeAccount}
                                lockIconBackgroundColor='#333234'
                            >
                                <OptionCard
                                    id='create-project-option-card'
                                    classes={{
                                        cardRoot: props.classes.optionCardRoot,
                                        cardMedia:
                                            props.classes.optionCardMedia,
                                        cardTitle:
                                            props.classes.optionCardTitle,
                                        cardText: props.classes.optionCardText,
                                    }}
                                    image={<CreateProjectSVG />}
                                    title='Create Project'
                                    text='Draw-It-Yourself'
                                />
                            </LockableElement>
                            {props.isFreeAccount && (
                                <React.Fragment>
                                    <Typography
                                        align='left'
                                        variant='caption'
                                        className={props.classes.darkerText}
                                    >
                                        <Link
                                            to='/activate/billinginformation'
                                            href='/activate/billinginformation'
                                        >
                                            Activate a subscription
                                        </Link>{' '}
                                        here.
                                    </Typography>
                                    <Typography
                                        align='left'
                                        variant='caption'
                                        color='textSecondary'
                                    >
                                        (Unlock Draw it Yourself tools, custom
                                        documents, exclusive discounts)
                                    </Typography>
                                </React.Fragment>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className={props.classes.gridItem}
                        >
                            <OptionCard
                                id='create-sketch-order-option-card'
                                classes={{
                                    cardRoot: props.classes.optionCardRoot,
                                    cardMedia: props.classes.optionCardMedia,
                                    cardTitle: props.classes.optionCardTitle,
                                    cardText: props.classes.optionCardText,
                                }}
                                onClick={props.onCreateSketchOrderClick}
                                image={<OrderSketchSVG />}
                                title='Measurement Order'
                                text='Order Measurements'
                            />
                            {props.isFreeAccount && (
                                <React.Fragment>
                                    <Typography align='left' variant='caption'>
                                        Non-Member pricing available.
                                    </Typography>
                                    <Typography
                                        align='left'
                                        variant='caption'
                                        color='textSecondary'
                                    >
                                        (No subscription required)
                                    </Typography>
                                </React.Fragment>
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </DialogContent>
            {!fullScreen && (
                <DialogActions>
                    <Button onClick={props.onCancelClick}>Cancel</Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

CreateProjectOptionDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onCreateProjectClick: PropTypes.func.isRequired,
    onCreateSketchOrderClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    isFreeAccount: PropTypes.bool.isRequired,
    classes: PropTypes.shape({
        dialogContent: PropTypes.string.isRequired,
        gridContainer: PropTypes.string.isRequired,
    }).isRequired,
};

export default withStyles(style)(CreateProjectOptionDialog);
