import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import { Link, withRouter } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import { Stack, Box } from '@mui/material';
import { ReactComponent as WeMeasureForYou } from './measure-for-you.svg';

const styles = (theme) => ({
    root: {
        maxWidth: 530,
        height: '100vh',
        width: '100%',
        margin: 'auto',
        padding: theme.spacing(2),
    },
    image: {
        width: '100%',
        height: 300,
        marginBottom: theme.spacing(3),
    },
    textField: {
        padding: 0,
    },
    startAdornment: {
        marginLeft: theme.spacing(1),
    },
});

class WelcomeSketchOS extends Component {
    onGetFreeReportClick = () => {
        this.props.history.push('/orders/create');
    };
    render() {
        return (
            <Fade in timeout={1000}>
                <Grid
                    container
                    justify-content='center'
                    alignItems='center'
                    className={this.props.classes.root}
                >
                    <Grid
                        item
                        container
                        spacing={2}
                        justify-content='center'
                        alignItems='center'
                    >
                        <Grid item xs={12}>
                            <WeMeasureForYou
                                className={this.props.classes.image}
                            />
                            <Typography
                                variant='h6'
                                align='center'
                                color='secondary'
                            >
                                Would You Like Us to Measure a Roof For You?
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant='body1'
                                align='center'
                                color='textSecondary'
                            >
                                Place an order with SketchOS - our measurement
                                service - and receive your first order today for
                                free!
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container justifyContent='center'>
                            <Stack spacing={1}>
                                <Fab
                                    color='secondary'
                                    variant='extended'
                                    onClick={this.onGetFreeReportClick}
                                    id='getting-started-button'
                                    mb={1}
                                    width={50}
                                >
                                    Get Free Report
                                </Fab>
                                <Box sx={{ 'text-align': 'center' }}>
                                    <Link to='/projects'>
                                        <Typography
                                            variant='caption'
                                            align='center'
                                        >
                                            No, thanks
                                        </Typography>
                                    </Link>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Fade>
        );
    }
}

export default withRouter(withStyles(styles)(WelcomeSketchOS));
