import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import ReactGA from 'react-ga';
import LoggerWrapper from '../../lib/Logger';
import { appInitialize, setAppRef } from '../../actions/AppInit';
import LoadingSpinner from '../../components/LoadingSpinner';
import { getFeatures } from '../../actions/featuresActions';

const refreshTime = 300000; // 5 minutes

class App extends Component {
    state = {
        showApp: false,
    };

    componentDidMount() {
        this.init();
        setInterval(this.initInBackground, refreshTime);
        ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_ID}`);
    }

    componentDidUpdate(prevProps) {
        const { location: { pathname } } = this.props;
        const previousLocation = prevProps.location.pathname;
        if (pathname !== previousLocation) {
            try {
                ReactGA.pageview(pathname);
                window.Appcues.page();
            } catch (error) {
                LoggerWrapper.captureException(error);
            }
        }
    }

    setAppRef = () => {
        const parsed = queryString.parse(this.props.location.search);
        const { ref } = parsed;
        if (ref) {
            this.props.dispatch(setAppRef(ref));
        }
    };

    getInitData = async (requestInBackground = false) => {
        await this.props.dispatch(getFeatures());

        if (!this.props.isAuthenticated) {
            this.setState({ showApp: true });
            return;
        }

        await this.props.dispatch(appInitialize(requestInBackground));
        this.setState({ showApp: true });
    };

    init = () => {
        this.setAppRef();
        this.getInitData();
    };

    initInBackground = async () => {
        this.getInitData(true);
    };
    render() {
        return (this.state.showApp) ? this.props.children : (
            <LoadingSpinner showSpinner />
        );
    }
}

App.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    const { auth } = state;
    const { isAuthenticated } = auth;
    return {
        isAuthenticated,
    };
};

export default withRouter(connect(mapStateToProps)(App));
