import _ from 'lodash';
import { setReduxMessage } from './ReduxMessagesActions';
import LocalStorageWrapper from '../lib/LocalStorageWrapper';
import BingLocationsApiClient from '../lib/apiClients/BingLocationsApiClient';

export const GEOCODE_ADDRESS_REQUEST = 'GEOCODE_ADDRESS_REQUEST';
export const GEOCODE_ADDRESS_SUCCESS = 'GEOCODE_ADDRESS_SUCCESS';
export const GEOCODE_ADDRESS_FAILURE = 'GEOCODE_ADDRESS_FAILURE';
const geocodeAddressRequest = () => ({
    type: GEOCODE_ADDRESS_REQUEST,
});
const geocodeAddressSuccess = (address, marker) => ({
    type: GEOCODE_ADDRESS_SUCCESS,
    address,
    marker,
});
const geocodeAddressFailure = error => ({
    type: GEOCODE_ADDRESS_FAILURE,
    error,
});
export const parseCoordinates = (json) => {
    const path = 'resourceSets[0].resources[0].geocodePoints[0]';
    return {
        lat: _.get(json, `${path}.coordinates[0]`),
        lng: _.get(json, `${path}.coordinates[1]`),
    };
};
const parseAddress = (json, address, userDefined) => {
    const path = 'resourceSets[0].resources[0].address';
    return {
        // if the user selected a non-autocomplete address use that otherwise use the geocoded address
        addressLine1: userDefined ? address : _.get(json, `${path}.addressLine`) || '',
        city: _.get(json, `${path}.locality`) || '',
        state: _.get(json, `${path}.adminDistrict`) || '',
        postalCode: _.get(json, `${path}.postalCode`) || '',
        country: _.get(json, `${path}.countryRegion`) || '',
    };
};

export const ADD_MARKER = 'ADD_MARKER';
export const addMarker = marker => (dispatch, getState) => {
    const state = getState().sketchOsGeocoding;
    const id = Math.max(...state.markers.map(item => (item.id))) + 1;
    dispatch({
        type: ADD_MARKER,
        marker: Object.assign({ id, draggable: true }, marker),
    });
};

export const UPDATE_MARKER = 'UPDATE_MARKER';
export const updateMarker = marker => (dispatch, getState) => {
    const state = getState().sketchOsGeocoding;
    const index = state.markers.findIndex(item => (item.id === marker.id));
    dispatch({
        type: UPDATE_MARKER,
        marker: Object.assign({}, state.markers[index], marker),
    });
};

export const CLEAR_ADDRESS = 'CLEAR_ADDRESS';
export const clearAddress = () => ({
    type: CLEAR_ADDRESS,
});

export const CLEAR_MARKERS = 'CLEAR_MARKERS';
export const clearMarkers = () => ({
    type: CLEAR_MARKERS,
});

export const REMOVE_MARKER = 'REMOVE_MARKER';
export const removeMarker = marker => ({
    type: REMOVE_MARKER,
    marker,
});

export const REMOVE_ALL_BUT_FIRST_MARKER = 'REMOVE_ALL_BUT_FIRST_MARKER';
export const removeAllButFirstMarkerFromMap = () => ({
    type: REMOVE_ALL_BUT_FIRST_MARKER,
});

export const geocodeAddress = (address, userDefined = false) => async (dispatch) => {
    dispatch(geocodeAddressRequest());
    let clonedResponse;
    try {
        const accessToken = LocalStorageWrapper.getItem('accessToken');
        const config = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };
        const response = await fetch(`${process.env.REACT_APP_API_ROOFSNAP_GEOCODE_URL}/${encodeURIComponent(address)}`, config);
        clonedResponse = response.clone();

        if (!response.ok) throw new Error(response.status);

        const json = await response.json();
        dispatch(clearMarkers());
        // NOTE: this is silly but has to be this way or errors... :\
        dispatch(geocodeAddressSuccess(parseAddress(json, address, userDefined), {
            coordinates: parseCoordinates(json),
            draggable: true,
        }));
    } catch (e) {
        dispatch(setReduxMessage('There was an error getting the location.'));
        dispatch(geocodeAddressFailure(e));
    }
    return clonedResponse;
};

export const REVERSE_GEOCODE_REQUEST = 'REVERSE_GEOCODE_REQUEST';
export const REVERSE_GEOCODE_SUCCESS = 'REVERSE_GEOCODE_SUCCESS';
export const REVERSE_GEOCODE_FAILURE = 'REVERSE_GEOCODE_FAILURE';

const reverseGeocodeRequest = () => ({
    type: REVERSE_GEOCODE_REQUEST,
});

const reverseGeocodeSuccess = (address, marker) => ({
    type: REVERSE_GEOCODE_SUCCESS,
    address,
    marker,
});

const reverseGeocodeFailure = error => ({
    type: REVERSE_GEOCODE_FAILURE,
    error,
});

export const reverseGeocodeLocation = (latitude, longitude) => async (dispatch) => {
    dispatch(reverseGeocodeRequest());
    try {
        const bingApiClient = new BingLocationsApiClient(process.env.REACT_APP_SKETCHOS_BING_MAPS_API_KEY);
        const result = await bingApiClient.reverseGeocodeLocation(latitude, longitude);

        dispatch(clearMarkers());

        const { address, coordinates } = result;
        const marker = {
            coordinates: {
                lat: coordinates.latitude,
                lng: coordinates.longitude,
            },
            draggable: true,
        };
        return dispatch(reverseGeocodeSuccess(address, marker));
    } catch (error) {
        dispatch(setReduxMessage('There was an error getting the location.'));
        return dispatch(reverseGeocodeFailure(error));
    }
};
