import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { green, grey, red } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
    },
    gridRoot: {
        height: '100%',
    },
    content: {
        padding: theme.spacing(3),
    },
    title: {
        marginBottom: theme.spacing(3),
    },
    active: {
        color: green[500],
    },
    notActive: {
        color: red[500],
    },
    action: {
        borderTop: `1px solid ${grey[300]}`,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
    },
});

const SubscriptionStatus = props => (
    <Card className={props.classes.root}>
        <Grid className={props.classes.gridRoot} container direction="column">
            <Grid item xs>
                <CardContent className={props.classes.content}>
                    <Typography className={props.classes.title} variant="subtitle1">Subscription status</Typography>
                    <Typography variant="body1">Status</Typography>
                    <Typography className={props.classes.active} variant="caption">Active</Typography>
                    <Typography variant="body1">Account type</Typography>
                    <Typography variant="caption">Starter (annual)</Typography>
                </CardContent>
            </Grid>
            <Grid container item>
                <div className={props.classes.action}>
                    <Button color="primary" onClick={props.onActionButtonClick}>Cancel subscription</Button>
                </div>
            </Grid>
        </Grid>
    </Card>
);

SubscriptionStatus.propTypes = {
    onActionButtonClick: PropTypes.func.isRequired,
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
        gridRoot: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        action: PropTypes.string.isRequired,
        active: PropTypes.string.isRequired,
    }).isRequired,
};

export default withStyles(styles)(SubscriptionStatus);
