import React from 'react';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import DocumentListItem from './DocumentListItem';

const documentCategoryListStyle = {
    categoryHeaderStyle: {
        borderBottom: 'solid 1px #dbdbdb',
    },
    categoryHeaderTextStyle: {
        padding: 16,
        marginBottom: 0,
        marginTop: 20,
    },
};

const DocumentCategoryList = ({
    deleteDocument,
    onDownloadDocumentClick,
    onOpenDocumentClick,
    category,
    documents,
}: documentProps) => {
    const renderProjectDocument = (document: any) => (
        <DocumentListItem
            deleteDocument={deleteDocument}
            onDownloadDocumentClick={onDownloadDocumentClick}
            onOpenDocumentClick={onOpenDocumentClick}
            document={document}
        />
    );

    return (
        <React.Fragment>
            {documents.length > 0 && (
                <List>
                    <div style={documentCategoryListStyle.categoryHeaderStyle}>
                        <Typography
                            variant='subtitle1'
                            style={
                                documentCategoryListStyle.categoryHeaderTextStyle
                            }
                            id='documents'
                        >
                            {category.name}
                        </Typography>
                    </div>
                    {documents.map(renderProjectDocument)}
                </List>
            )}
        </React.Fragment>
    );
};

type documentProps = {
    deleteDocument: (document: any) => void;
    onDownloadDocumentClick: (document: any) => void;
    onOpenDocumentClick: (document: any) => void;
    documents: any;
    category: any;
};

export default DocumentCategoryList;
