import { Box, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PaymentDialog from './PaymentDialog';
import useBox, { PartialBoxProps } from 'hooks/useBox';

type PaymentDialogButtonProps = {
  safeToDisplayBraintree?: boolean | undefined;
  onDialogOpened?: (open: boolean) => void;
  onPaymentMethodChosen: (nonce: string, useSubscriptionPaymentMethod: boolean | undefined) => void;
  disabled?: boolean | undefined;
  useMakeDefaultCheckbox?: boolean | undefined;
  dialogTitle: string;
} & PartialBoxProps;

const PaymentDialogButton = (props: PaymentDialogButtonProps) => {
  const { safeToDisplayBraintree, onDialogOpened, onPaymentMethodChosen, disabled, useMakeDefaultCheckbox } = {
    safeToDisplayBraintree: true,
    ...props,
  };

  const [paymentModalOpen, setPaymentModalOpen] = useState(false);

  const handlePayNowClicked = () => {
    setPaymentModalOpen(true);
  };

  const handlePaymentMethodChosen = (nonce: string, useSubscriptionPaymentMethod: boolean | undefined) => {
    setPaymentModalOpen(false);
    onPaymentMethodChosen(nonce, useSubscriptionPaymentMethod);
  };

  const boxProps = useBox(props);

  useEffect(() => {
    if (onDialogOpened) onDialogOpened(paymentModalOpen);
  }, [paymentModalOpen, onDialogOpened]);

  return (
    <Box {...boxProps}>
      {safeToDisplayBraintree && (
        <PaymentDialog
          dialogTitle={props.dialogTitle}
          onChoose={handlePaymentMethodChosen}
          open={paymentModalOpen}
          onCancel={() => setPaymentModalOpen(false)}
          useMakeDefaultCheckbox={useMakeDefaultCheckbox}
        />
      )}
      <Button disabled={disabled} variant='contained' color='primary' onClick={handlePayNowClicked}>
        Pay Orders
      </Button>
    </Box>
  );
};

export default PaymentDialogButton;
