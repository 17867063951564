import {
    GET_MATERIAL_ITEMS_REQUEST,
    GET_MATERIAL_ITEMS_SUCCESS,
    GET_MATERIAL_ITEMS_FAILURE,
} from '../actions/MaterialItemsActions';

function materialItemsReducer(state = {
    materielItemsFetching: false,
    materialItems: [],
    totalResults: 0,
    totalPages: 0,
    currentPage: 1,
}, action) {
    switch (action.type) {
    case GET_MATERIAL_ITEMS_REQUEST:
        return Object.assign({}, state, {
            materielItemsFetching: true,
        });
    case GET_MATERIAL_ITEMS_SUCCESS:
        return Object.assign({}, state, {
            materielItemsFetching: false,
            materialItems: action.response.resourceList,
            totalResults: action.response.totalResults,
            totalPages: action.response.totalPages,
            currentPage: action.response.page,
        });
    case GET_MATERIAL_ITEMS_FAILURE:
        return Object.assign({}, state, {
            materielItemsFetching: false,
            materialItems: [],
            totalResults: 0,
            totalPages: 0,
            currentPage: 1,
        });
    default:
        return state;
    }
}

export default materialItemsReducer;
