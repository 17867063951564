import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import RecentTransactionsTable from './RecentTransactionsTable';

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
    },
    gridRoot: {
        height: '100%',
    },
    title: {
        padding: theme.spacing(3),
        borderBottom: `1px solid ${grey[300]}`,
    },
    action: {
        borderTop: `1px solid ${grey[300]}`,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
    },
});

const TransactionsCard = props => (
    <Card className={props.classes.root}>
        <Grid className={props.classes.gridRoot} container direction="column">
            <Grid item xs>
                <Typography className={props.classes.title} variant="subtitle1">Transactions</Typography>
                <RecentTransactionsTable />
            </Grid>
            <Grid container item>
                <div className={props.classes.action}>
                    <Button color="primary" onClick={props.onActionButtonClick}>View transactions</Button>
                </div>
            </Grid>
        </Grid>
    </Card>
);

TransactionsCard.propTypes = {
    onActionButtonClick: PropTypes.func.isRequired,
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
        gridRoot: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        action: PropTypes.string.isRequired,
    }).isRequired,
};

export default withStyles(styles)(TransactionsCard);
