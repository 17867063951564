import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';
import { withStyles } from '@material-ui/core/styles';
import WelcomeToRoofSnap from './WelcomeToRoofSnap';
import SignUpError from './SignUpError';
import WelcomeSketchOS from './WelcomeSketchOS';
import { getOrganization } from '../../actions/Organization';
import {
    getOrganizationsFreeSketchOrdersBalance,
    getOrganizationOpenSketchOrders,
} from '../../actions/OrganizationFreeSketchOrdersActions';
import LocalStorageWrapper from '../../lib/LocalStorageWrapper';

// kick off the polyfill. This ensures that the smooth scrolling works on all browsers
smoothscroll.polyfill();

const styles = (theme) => ({
    root: {
        height: '100vh',
        position: 'fixed',
        overflow: 'scroll',
        top: 0,
        backgroundColor: '#ffffff',
        zIndex: theme.zIndex.appBar + 1,
        width: '100%',
        margin: 'auto',
    },
});

class Onboarding extends Component {
    state = {
        renderWelcome: true,
        showSketchOs: false,
    };

    componentDidUpdate() {
        this.props.dispatch(getOrganization(this.props.organizationId));
        // make sure there is a stored jwt token or this check will send user to projects page
        if (LocalStorageWrapper.getItem('accessToken'))
            this.getFreeSketchOrders();
    }

    getFreeSketchOrders() {
        this.props.dispatch(
            getOrganizationsFreeSketchOrdersBalance(this.props.organizationId)
        );
        this.props.dispatch(
            getOrganizationOpenSketchOrders(this.props.organizationId)
        );
    }

    hasAvailableFreeSketchOrders() {
        return (
            this.props.freeSketchOrderBalance - this.props.openSketchOrders > 0
        );
    }

    handleDismissSlidesClick = () => {
        this.setState({
            renderWelcome: false,
        });

        const { signUp } = this.props;
        const continueToSketchos = this.hasAvailableFreeSketchOrders();

        if (signUp.error) {
            this.setState({
                showError: true,
                renderWelcome: false,
            });
            return;
        }

        // If we are no longer signing up and this is not a new user, direct them to their email.
        if (signUp.isNewUser === false) {
            this.props.history.push('/username-already-registered');
            return;
        }

        if (continueToSketchos) {
            this.setState({
                showSketchOs: true,
            });
            return;
        }

        this.props.history.push('/projects');
    };

    render() {
        return (
            <div className={this.props.classes.root}>
                {this.state.renderWelcome && (
                    <WelcomeToRoofSnap
                        onDismissClick={this.handleDismissSlidesClick}
                        isLoading={this.props.signUp.isSigningUp}
                    />
                )}

                {this.state.showError && <SignUpError />}
                {this.state.showSketchOs && <WelcomeSketchOS />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { signUp, currentUser, organizationFreeSketchOrders } = state;
    const { userId, organizationId } = currentUser;
    const { freeSketchOrderBalance, openSketchOrders } =
        organizationFreeSketchOrders;
    return {
        signUp,
        currentUserId: userId,
        organizationId,
        freeSketchOrderBalance,
        openSketchOrders,
    };
};

export default connect(mapStateToProps)(
    withRouter(withStyles(styles)(Onboarding))
);
