import React, { useContext } from 'react'
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core'
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles'
import SettingsContainer from '../SettingsComponents/SettingsContainer';
import SettingsComponent from '../SettingsComponents/SettingsComponent';
import RowContainer from '../SettingsComponents/RowContainer';
import SettingSwitch from '../SettingsComponents/SettingSwitch';
import OfficeContext from '../OfficeContext';
import { useOfficeSwitch } from '../useOfficeSwitch';

interface LaborReportProps extends WithStyles<typeof styles> {
    onSuccess: () => void,
    dispatch: any,
}

const styles = () =>
    createStyles({
        root: {
            height: '100%',
            font: 'Roboto',
        },
        header: {
            font: 'normal normal bold 34px/20px Roboto',
            letterSpacing: 0.3,
            color: '#222222',
            padding: '20px 0px',
        },
        section: {
            padding: '10px 0px',
        },
        sectionTitle: {
            font: 'normal normal bold 20px Roboto',
            letterSpacing: 0.21,
            color: '#222222',
            padding: '25px 0px 15px 0px',
        },
        editorMobile: {
            width: '90vw',
        },
        termsDescription: {
            font: 'normal normal normal 16px/24px Roboto',
            letterSpacing: 0.62,
            color: '#222222',
        },
        documentTitle: {
            font: '20px Roboto',
            letterSpacing: 0.18,
            fontWeight: 600,
            color: '#222222',
        },
        settingsRow: {
            padding: '25px 15px 25px 20px',
            font: '17px Roboto',
            borderBottom: '1px solid #00112219'
        },
        settingDescription: {
            font: 'normal normal normal 13px Roboto',
            letterSpacing: '0.5px',
            color: '#000000DE',
            opacity: '0.5',
            paddingTop: '10px',
        },
    })

const LaborReport = (props: LaborReportProps) => {
    const { office } = useContext(OfficeContext);
    const { classes } = props;
    const { handleSwitch } = useOfficeSwitch({ onSuccess: props.onSuccess });

    return (
        <Grid item container direction='column' xs={12}>
            <Grid
                item
                container
                direction='row'
                className={classes.section}
            >

                <SettingsContainer
                    title='Labor Report'
                    description='Choose to show or hide Labor Reports in the application.'
                    sectionTitleStyle={classes.header}
                    descriptionStyle={classes.settingDescription}
                />
            </Grid>


            <RowContainer>
                <SettingsComponent
                    componentType='switch'
                    settingsRowProps={{
                        settingTitle: 'Labor Reports Document',
                        rowStyle: classes.settingsRow,
                        descriptionStyle: classes.settingDescription,
                        settingComponent:
                            <SettingSwitch
                                checked={!office.hideLaborSummaryReport ?? false}
                                onChange={(event: any) => handleSwitch(event)}
                                name='hideLaborSummaryReport'
                            />
                    }}
                />
            </RowContainer>
            <Grid item>
                <Typography className={classes.settingDescription} data-cy="labor-report-switch-description">
                    {
                        office.hideLaborSummaryReport ?
                            "Labor Reports are currently set to \"Hide\". To show document, set switch to Show."
                            :
                            "Labor Reports are currently set to \"Show\". To hide document, set switch to Hide."
                    }
                </Typography>
            </Grid>
        </Grid>
    )
}

export default connect()(withStyles(styles)(LaborReport));