import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const MaterialItemColorOptionsList = ({ colorOptions, onColorOptionClick, totalResults }) => {
    const renderColorOptionListItem = colorOption => (
        !colorOption.isHidden &&
            <ListItem
                key={colorOption.id}
                onClick={() => onColorOptionClick(colorOption)}
                button
            >
                <ListItemAvatar style={{ width: 80, height: 60 }}>
                    <Avatar src={colorOption.image} style={{ width: 60, height: 60 }} alt={colorOption.description} />
                </ListItemAvatar>
                <ListItemText primary={colorOption.description} />
            </ListItem>
    );

    return (
        <List>
            {
                (totalResults) ?
                    (
                        colorOptions.map(renderColorOptionListItem)
                    ) : <Typography align="center" variant="subtitle1">This item has no colors</Typography>
            }

        </List>
    );
};

const colorOptionProps = {
    id: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.string,
    materialItemId: PropTypes.number.isRequired,
    isDeleted: PropTypes.bool,
    isCustom: PropTypes.bool,
    isHidden: PropTypes.bool,
};

MaterialItemColorOptionsList.propTypes = {
    colorOptions: PropTypes.arrayOf(PropTypes.shape(colorOptionProps)).isRequired,
    onColorOptionClick: PropTypes.func.isRequired,
    totalResults: PropTypes.number.isRequired,
};

export default MaterialItemColorOptionsList;
