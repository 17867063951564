import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';

const SelectableListItem = props => (
    <Grid
        container
        alignItems="center"
    >
        <Grid item>
            <Checkbox
                checked={props.selected}
                // eslint-disable-next-line no-unused-vars
                onChange={(event, checked) => props.onSelectClick(checked)}
            />
        </Grid>
        <Grid item xs>
            <ListItem
                button={props.button}
                onClick={props.onClick}
            >
                {props.children}
            </ListItem>
        </Grid>
    </Grid>
);

SelectableListItem.defaultProps = {
    children: null,
    button: false,
    onClick: () => {},
    selected: false,
};

SelectableListItem.propTypes = {
    children: PropTypes.node,
    button: PropTypes.bool,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    onSelectClick: PropTypes.func.isRequired,
};

export default SelectableListItem;
