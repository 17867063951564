import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Button, ButtonGroup, Stack } from '@mui/material';
import { Project } from 'lib/Models';

const FormSections = [
    'project-section',
    'customer-section',
    'billing-section',
    'insurer-section',
] as const;

export type FormSection = (typeof FormSections)[number];

export type ProjectFormField = keyof Project;

export type ProjectFormNavigationProps = {
    focusField?: ProjectFormField;
};

const ProjectFormNavigation = ({ focusField }: ProjectFormNavigationProps) => {
    const [focusedSection, setFocusedSection] =
        useState<FormSection>('project-section');

    useEffect(() => {
        if (!focusField) return;

        const focusFieldElem = document.querySelector(
            `[name="${focusField}"]`
        ) as HTMLInputElement;

        if (focusFieldElem) {
            focusFieldElem.scrollIntoView();
            focusFieldElem.focus();
        }
    }, [focusField]);

    const focusedSectionElem = document.querySelector(`#${focusedSection}`);

    if (focusedSectionElem) focusedSectionElem.scrollIntoView();

    const NavButton = ({
        children,
        section,
    }: PropsWithChildren<{ section: FormSection }>) => {
        const handleClick = () => {
            setFocusedSection(section);
        };

        return (
            <Button
                sx={{ textTransform: 'none' }}
                color={focusedSection === section ? 'primary' : 'inherit'}
                onClick={handleClick}
            >
                {children}
            </Button>
        );
    };

    return (
        <Stack sx={{ m: '1rem 0' }} alignItems='center'>
            <ButtonGroup color='inherit' variant='outlined'>
                <NavButton section='project-section'>Project</NavButton>
                <NavButton section='customer-section'>Customer</NavButton>
                <NavButton section='billing-section'>Billing</NavButton>
                <NavButton section='insurer-section'>Insurer</NavButton>
            </ButtonGroup>
        </Stack>
    );
};

export default ProjectFormNavigation;
