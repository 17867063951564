import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    dialogPaper: {
        height: '100%',
        width: '100%',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    progressSpinner: {
        margin: 4,
    },
    subheading: {
        margin: '10px 0',
    },
});
class ConfirmationSelectionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchByText: '',
        };
    }

    SetSearchByText = (e) => {
        this.setState({ searchByText: e.currentTarget.value });
    };

    renderListItem = option => (
        <ListItem
            button
            onClick={e => this.props.handleSelectionClick(e, option)}
            key={option.id}
        >
            {
                this.props.isCheckable && !option.isCurrentlyFetching &&
                <Checkbox
                    checked={option.isChecked}
                    disableRipple
                />
            }
            {
                this.props.isCheckable && option.isCurrentlyFetching &&
                <CircularProgress className={this.props.classes.progressSpinner} />
            }
            <ListItemText
                primary={option.displayString}
            />
        </ListItem>);

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.isOpen}
                    onClose={this.props.handleClose}
                    maxWidth="sm"
                    classes={{
                        paper: this.props.classes.dialogPaper,
                    }}
                >
                    <DialogTitle id="responsive-dialog-title">
                        {this.props.title}
                        <Typography variant="subtitle1" className={this.props.classes.subheading}>{this.props.description}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Input
                            placeholder="Filter by user..."
                            onChange={this.SetSearchByText}
                            value={this.state.searchByText}
                            fullWidth
                        />
                        <div className={this.props.classes.root}>
                            <List component="nav">
                                {
                                    this.props.selectionOptions.filter(o => o.displayString.toLowerCase().includes(this.state.searchByText.toLowerCase())).map(this.renderListItem)
                                }

                            </List>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose}>{this.props.closingVerbiage}</Button>,
                    </DialogActions>
                </Dialog>
            </div>

        );
    }
}
ConfirmationSelectionModal.propTypes = {
    classes: PropTypes.shape({
        dialogPaper: PropTypes.string.isRequired,
        formControl: PropTypes.string.isRequired,
        form: PropTypes.string.isRequired,
        root: PropTypes.string.isRequired,
        subheading: PropTypes.string.isRequired,
        progressSpinner: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    isCheckable: PropTypes.bool.isRequired,
    selectionOptions: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        displayString: PropTypes.string,
        isChecked: PropTypes.bool,
        isCurrentlyFetching: PropTypes.bool,
    })),
    handleSelectionClick: PropTypes.func,
    closingVerbiage: PropTypes.string,
};

ConfirmationSelectionModal.defaultProps = {
    description: '',
    selectionOptions: [],
    handleSelectionClick: () => {},
    closingVerbiage: 'Cancel',
};
export default withStyles(styles)(ConfirmationSelectionModal);
