import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    Typography,
    createStyles,
    makeStyles,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import StandardRushOrderButton from '../OrderType/LargeIconButton';
import {
    AllowedOrderTypes,
    OrderTypes,
    PriceGridType,
    OrderTypeToString,
} from 'lib/MeasurementOrders';
import StandardIcon from '@ui/Icons/StandardIcon';
import RushOrderIcon from '@ui/Icons/RushOrderIcon';
import { mapPricingStateToProps } from 'lib/util/PricingGridState';
import { connect } from 'react-redux';
import { PriceGrid } from 'lib/Models/PriceGrid';
import useRoofSnapApi from 'hooks/useApiHook';
import LoggerWrapper from 'lib/Logger';
import PricingPopover from '../PricingPopover/PricingPopover';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { dueDateText } from 'lib/OrderDueDate';
import OrderCreditInfo from './OrderCreditInfo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '60rem',
        },

        orderContainer: {
            display: 'flex',
            padding: '1.2rem',
            flexDirection: 'column',
            borderRadius: '.5rem',
            backgroundColor: '#FFF',
        },
        orderTopPadding: {
            paddingTop: '.9rem',
        },

        orderTitleContainer: {
            display: 'flex',
        },

        orderTitle: {
            color: '#222',
            fontSize: '1.5rem',
            fontWeight: 400,
            lineHeight: '2rem',
        },

        addressLabel: {
            color: '#888',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '1.5rem',
            letterSpacing: '0.00938rem',
        },

        deliveryTimeText: {
            color: '#38BF41',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '1.25rem',
            letterSpacing: '0.01563rem',
            flexGrow: 1,
        },

        btnCheckout: {
            backgroundColor: '#38BF41',
            color: '#FFF',
            fontSize: '0.875rem',
            fontWeight: 500,
            borderRadius: '6.25rem',
            textTransform: 'none',
            lineHeight: '1.25rem',
            letterSpacing: '0.00625rem',
            '&:hover': {
                backgroundColor: '#30B439',
                color: '#FFFFFF',
            },
        },

        reportLabels: {
            color: '#222',
            fontWeight: 400,
            lineHeight: '1.5rem',
            letterSpacing: '0.03125rem',
            flexGrow: 1,
        },

        priceLabels: {
            color: '#222',
            fontSize: '0.875rem',
            fontWeight: 700,
            lineHeight: '1.5rem',
            letterSpacing: '0.03125rem',
            alignSelf: 'end',
        },

        panelLabels: {
            color: '#888',
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.25rem',
            letterSpacing: '0.01563rem',
        },
        panelLabelsError: {
            color: '#EA1D3D',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '1.25rem',
            letterSpacing: '0.01563rem',
        },
        paddingTop: {
            paddingTop: '1rem',
        },

        businessHourText: {
            color: '#888',
            fontSize: '0.875rem',
            fontWeight: 400,
            lineHeight: '1.25rem',
            letterSpacing: '0.01563rem',
            padding: '0.625rem',
            textAlign: 'center',
        },

        deliveryText: {
            color: '#888',
            fontWeight: 400,
            lineHeight: 'normal',
        },
    })
);

interface IRushSettingsResponse {
    organizationId: number;
    rushCost: number;
    halfSnapRushCost: number;
    orderCommitmentIntervalMinutes: number;
}

interface IFormattedRushSettings {
    rushCost: number;
    halfSnapRushCost: number;
    orderCommitmentIntervalMinutes: number;
}

type EstimateRangeType = {
    lowerBound: number;
    higherBound: number | undefined;
};

type OrderSummaryProps = {
    address: string;
    city: string;
    region: string;
    postcode: string;
    country: string;
    sketchReportType: AllowedOrderTypes;
    includeGutters?: boolean;
    selectedMetalPanelSize: number | undefined;
    selectedGutterSize: number | undefined;
    rushed: boolean;
    rushOrdering: boolean;
    orgId: number;
    isBusinessHours: boolean;
    isRushAvailable: boolean;
    numberOfOrdersBeingPlaced: number;
    freeSketchOrderBalance: number;
    openSketchOrders: number;
    show?: PriceGridType;
    handleRushedChanged: (rushed: boolean) => void;
    checkoutDisabled: boolean;
    priceGrids: { [key in PriceGridType]: PriceGrid[] };
    onCheckoutClick: () => void;
};

const OrderSummary = (props: OrderSummaryProps) => {
    const {
        address,
        city,
        region,
        postcode,
        country,
        sketchReportType,
        includeGutters,
        selectedMetalPanelSize,
        selectedGutterSize,
        rushed,
        rushOrdering,
        orgId,
        priceGrids,
        isBusinessHours,
        isRushAvailable,
        numberOfOrdersBeingPlaced,
        handleRushedChanged,
        checkoutDisabled,
        freeSketchOrderBalance,
        openSketchOrders,
    } = props;

    const styles = useStyles();

    let sketchOrdersAvailable = freeSketchOrderBalance - openSketchOrders;
    if (sketchOrdersAvailable == null || sketchOrdersAvailable < 0) {
        sketchOrdersAvailable = 0;
    }

    const [orgRushSettings, setOrgRushSettings] =
        useState<IFormattedRushSettings>({
            rushCost: 0,
            halfSnapRushCost: 0,
            orderCommitmentIntervalMinutes: 0,
        });
    const [duringBusinessHours, setDuringBusinessHours] =
        useState<boolean>(isBusinessHours);
    const showDeliveryTime = sketchReportType === OrderTypes.MetalRoof;
    const api = useRoofSnapApi(`v1/organizations/${orgId}/rushsettings`);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [pricingType, setPricingType] = useState<PriceGridType>('FullSnap');
    const pricingPopoverOpen = Boolean(anchorEl);
    const openPricingPopover = (
        event: React.MouseEvent<HTMLElement>,
        pricingType: PriceGridType
    ) => {
        setPricingType(pricingType);
        setAnchorEl(event.currentTarget);
    };

    const openPriceTable = (
        event: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
        if (sketchReportType === OrderTypes.FullSnap) {
            openPricingPopover(event, 'FullSnap');
        } else if (sketchReportType === OrderTypes.MetalRoof) {
            openPricingPopover(event, 'MetalRoof');
        }
    };

    const handlePageRushChange = (value: boolean) => {
        if (!isRushAvailable) {
            if (value === false) return;
        } else {
            handleRushedChanged(value);
        }
    };

    const getReportPrice = (reportType: string): EstimateRangeType => {
        let lowerBound = 0;
        let higherBound = undefined;
        switch (reportType) {
            case OrderTypes.MetalRoof:
                let totalTiers = priceGrids.MetalRoof[0].tierPrices.length;
                lowerBound = priceGrids.MetalRoof[0].tierPrices[0].price;
                higherBound =
                    priceGrids.MetalRoof[0].tierPrices[totalTiers - 1].price;
                return { lowerBound, higherBound };
            case OrderTypes.Gutters:
                lowerBound = priceGrids.Gutters[0].tierPrices[0].price;
                return { lowerBound, higherBound };
            case OrderTypes.FullSnap:
                lowerBound = priceGrids.FullSnap[0].tierPrices[0].price;
                return { lowerBound, higherBound };
            case OrderTypes.HalfSnap:
                lowerBound = priceGrids.HalfSnap[0].tierPrices[0].price;
                return { lowerBound, higherBound };
            default:
                return { lowerBound, higherBound };
        }
    };

    const getReportPriceLabel = (reportType: string) => {
        let { lowerBound, higherBound } = getReportPrice(reportType);
        return (
            '$' +
            lowerBound +
            (higherBound !== undefined ? ' - $' + higherBound : '')
        );
    };

    const getEstimateTotal = () => {
        let { lowerBound: lowRange, higherBound: highRange } =
            getReportPrice(sketchReportType);
        if (includeGutters) {
            let { lowerBound: gutterPrice } = getReportPrice(
                OrderTypes.Gutters
            );
            lowRange += gutterPrice;
            highRange = highRange && highRange + gutterPrice;
        }
        if (numberOfOrdersBeingPlaced) {
            lowRange *= numberOfOrdersBeingPlaced;
            highRange = highRange && highRange * numberOfOrdersBeingPlaced;
        }
        if (rushed) {
            lowRange += orgRushSettings.rushCost;
            highRange = highRange && highRange + orgRushSettings.rushCost;
        }
        return (
            <>
                <NumberFormat
                    value={lowRange}
                    displayType='text'
                    prefix='$'
                    thousandSeparator
                    renderText={(value) => <span>{value}</span>}
                />
                {highRange && (
                    <>
                        {' - '}
                        <NumberFormat
                            value={highRange}
                            prefix='$'
                            displayType='text'
                            thousandSeparator
                            renderText={(value) => <span>{value}</span>}
                        />
                    </>
                )}
            </>
        );
    };

    useEffect(() => {
        const getRushSettingsAsync = async () => {
            let response: IRushSettingsResponse;

            try {
                response = await api.get();
                const rushCost = Number(response.rushCost.toFixed(0));
                const halfSnapRushCost = Number(
                    response.halfSnapRushCost.toFixed(0)
                );
                const orderCommitmentIntervalMinutes = Number(
                    response.orderCommitmentIntervalMinutes
                );
                setOrgRushSettings({
                    rushCost,
                    halfSnapRushCost,
                    orderCommitmentIntervalMinutes,
                });
            } catch (error: any) {
                LoggerWrapper.log(error);
            }
        };
        getRushSettingsAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (duringBusinessHours !== isBusinessHours)
            setDuringBusinessHours(isBusinessHours);
    }, [duringBusinessHours, isBusinessHours]);

    useEffect(() => {
        if (!isRushAvailable) handleRushedChanged(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRushAvailable]);

    const RushOrderDeliveryText = () => {
        return (
            <>
                <Box display='flex' flexDirection='row'>
                    <Typography className={styles.reportLabels}>
                        Delivery Option
                    </Typography>
                    <Typography className={styles.priceLabels}>
                        {'$' +
                            (sketchReportType === 'halfsnap'
                                ? orgRushSettings.halfSnapRushCost
                                : orgRushSettings.rushCost)}
                    </Typography>
                </Box>
                <Typography className={styles.deliveryTimeText}>
                    {dueDateText(
                        true,
                        orgRushSettings.orderCommitmentIntervalMinutes,
                        duringBusinessHours
                    )}
                </Typography>
            </>
        );
    };

    const StandardOrderDeliveryText = () => {
        return (
            <>
                <Box display='flex' flexDirection='row'>
                    <Typography className={styles.reportLabels}>
                        Delivery Option
                    </Typography>
                </Box>
                <Typography className={styles.deliveryTimeText}>
                    {dueDateText(
                        false,
                        orgRushSettings.orderCommitmentIntervalMinutes,
                        duringBusinessHours
                    )}
                </Typography>
            </>
        );
    };

    const MetalOrderDeliveryText = () => {
        return (
            <>
                <Box display='flex' flexDirection='row'>
                    <Typography className={styles.reportLabels}>
                        Delivery Option
                    </Typography>
                </Box>
                <PaddingTop />
                <Typography
                    style={{ flexGrow: 1 }}
                    className={styles.deliveryText}
                >
                    This order has a minimum 24-hour turnaround time. Standard
                    and Rush Order delivery not available. No additional cost.
                </Typography>
            </>
        );
    };

    const ShowDeliveryOptionText = () => {
        if (showDeliveryTime) {
            return <MetalOrderDeliveryText />;
        }
        return rushed ? (
            <RushOrderDeliveryText />
        ) : (
            <StandardOrderDeliveryText />
        );
    };

    const SelectPanelSizeComponent = (props: {
        reportType: string;
        panelSize: number | undefined;
    }) => {
        return (
            <>
                {props.panelSize !== undefined && props.panelSize > 0 ? (
                    <Typography
                        style={{ flexGrow: 1 }}
                        className={styles.panelLabels}
                    >
                        Panel Size: {props.panelSize} inches
                    </Typography>
                ) : (
                    <Typography
                        style={{
                            letterSpacing: '0.01563rem',
                            flexGrow: 1,
                        }}
                        className={styles.panelLabelsError}
                    >
                        Select{' '}
                        {props.reportType === 'gutters' ? 'Gutter' : 'Panel'}{' '}
                        Size
                    </Typography>
                )}
            </>
        );
    };

    const ReportComponentCombined = (props: { index: number | undefined }) => {
        let panelSize =
            sketchReportType === OrderTypes.Gutters
                ? selectedGutterSize
                : selectedMetalPanelSize;
        return (
            <>
                <ReportComponent
                    reportType={sketchReportType}
                    panelSize={panelSize}
                    index={props.index}
                ></ReportComponent>
                {includeGutters && (
                    <ReportComponent
                        reportType={'gutters'}
                        panelSize={selectedGutterSize}
                        index={undefined}
                    ></ReportComponent>
                )}
                <PaddingTop />
                <PaddingTop />
                <Divider
                    style={{ height: '.1rem', backgroundColor: '#D1D1D1' }}
                />
            </>
        );
    };

    const ShowStandardRushButtons = () => {
        return (
            <Box
                display='flex'
                flexDirection='row'
                className={styles.orderTopPadding}
                justifyContent={'center'}
                max-width={'850px'}
            >
                <ButtonGroup style={{ flexGrow: 1, display: 'flex' }}>
                    <StandardRushOrderButton
                        onClick={() => {
                            handlePageRushChange(false);
                        }}
                        icon={<StandardIcon />}
                        text={'Standard'}
                        subText={'4 hrs.+'}
                        active={!rushed}
                        customStyle={{
                            flexGrow: 1,
                            height: '5rem',
                            borderRight:
                                rushOrdering && showDeliveryTime
                                    ? '0.063rem solid #D1D1D1'
                                    : '',
                            borderRadius:
                                rushOrdering && showDeliveryTime
                                    ? '0.25rem'
                                    : '0.25rem 0rem 0rem 0.25rem',
                        }}
                    />
                    {rushOrdering && !showDeliveryTime && (
                        <StandardRushOrderButton
                            onClick={() => {
                                handlePageRushChange(true);
                            }}
                            icon={<RushOrderIcon />}
                            text={'Rush'}
                            subText={'1 hr.'}
                            active={rushed}
                            customStyle={{
                                flexGrow: 1,
                                height: '5rem',
                            }}
                            disabled={!isRushAvailable}
                            applyRightRadius
                        />
                    )}
                </ButtonGroup>
            </Box>
        );
    };

    const ReportComponent = (props: {
        reportType: string;
        panelSize: number | undefined;
        index: number | undefined;
    }) => {
        return (
            <>
                {props.index && (
                    <>
                        <Typography
                            style={{ paddingTop: '.8rem' }}
                            className={styles.addressLabel}
                        >
                            Building {props.index + 1}
                        </Typography>
                    </>
                )}

                <Box
                    display='flex'
                    flexDirection='row'
                    className={styles.paddingTop}
                >
                    <Typography className={styles.reportLabels}>
                        {props.reportType === ''
                            ? 'Select Report Type'
                            : OrderTypeToString[props.reportType] + ' Report'}
                    </Typography>
                    <Typography className={styles.priceLabels}>
                        {(props.reportType === 'metalroof' ||
                            props.reportType === 'fullsnap') && (
                            <div
                                style={{ display: 'inline-block' }}
                                onClick={(e) => openPriceTable(e)}
                            >
                                <InfoOutlinedIcon
                                    sx={{
                                        ml: 1,
                                        fontSize: 16,
                                        color: '#999A9A',
                                        marginBottom: '-3px',
                                        marginRight: '8px',
                                        cursor: 'pointer',
                                    }}
                                />
                            </div>
                        )}
                        {getReportPriceLabel(props.reportType)}
                    </Typography>
                </Box>
                {(props.reportType === 'metalroof' ||
                    props.reportType === 'gutters') && (
                    <SelectPanelSizeComponent
                        reportType={props.reportType}
                        panelSize={props.panelSize}
                    ></SelectPanelSizeComponent>
                )}
            </>
        );
    };

    const MultipinOrders = () => {
        let reportComponents = [];
        for (let i = 0; i < numberOfOrdersBeingPlaced; ++i) {
            reportComponents.push(
                <ReportComponentCombined index={i === 0 ? undefined : i} />
            );
        }
        return <>{reportComponents}</>;
    };

    const PaddingTop = () => {
        return <div style={{ paddingTop: '.85rem' }}></div>;
    };

    return (
        <Box
            display='flex'
            flexDirection='column'
            style={{
                overflowY: 'hidden',
                overflowX: 'hidden',
            }}
            className={styles.paper}
        >
            <Paper elevation={2} className={classNames(styles.orderContainer)}>
                <Box className={styles.orderTitleContainer}>
                    <Typography className={styles.orderTitle}>
                        Order Summary
                    </Typography>
                </Box>
                <Box
                    className={classNames(
                        styles.orderTitleContainer,
                        styles.paddingTop
                    )}
                >
                    <Typography className={styles.addressLabel}>
                        {address !== '' && address}
                        {city !== '' && ', ' + city}
                        {region !== '' && ', ' + region}
                        {postcode !== '' && ', ' + postcode}
                        {country !== '' && ', ' + country}
                    </Typography>
                </Box>
                <PaddingTop />
                <Box>
                    {numberOfOrdersBeingPlaced !== undefined &&
                    numberOfOrdersBeingPlaced > 0 ? (
                        <MultipinOrders />
                    ) : (
                        <ReportComponentCombined index={undefined} />
                    )}
                    <PaddingTop />
                    <PaddingTop />
                    <ShowDeliveryOptionText />
                    <PaddingTop />
                    {!showDeliveryTime && <ShowStandardRushButtons />}
                    <PaddingTop />
                    <PaddingTop />
                    <Divider
                        style={{
                            height: '.25rem',
                            backgroundColor: '#222',
                        }}
                    />
                    <Box
                        display='flex'
                        flexDirection='row'
                        className={styles.orderTopPadding}
                    >
                        <Typography className={styles.reportLabels}>
                            Estimated Total
                        </Typography>
                        <Typography className={styles.priceLabels}>
                            {getEstimateTotal()}
                        </Typography>
                    </Box>
                    <Button
                        fullWidth
                        disabled={checkoutDisabled}
                        variant='contained'
                        className={styles.btnCheckout}
                        style={{ marginTop: '2.5rem', marginBottom: '.5rem' }}
                        onClick={() => props.onCheckoutClick()}
                    >
                        Checkout Now
                    </Button>
                </Box>
                <Box>
                    <OrderCreditInfo
                        sketchOrdersAvailable={sketchOrdersAvailable}
                        numberOfOrdersBeingPlaced={numberOfOrdersBeingPlaced}
                    ></OrderCreditInfo>
                </Box>
            </Paper>
            <Box display='flex' flexDirection='row' justifyContent={'center'}>
                <Typography className={styles.businessHourText}>
                    *Business hours are Mon. - Sat. 9am-5pm ET. Orders placed
                    outside of business hours will be completed next business
                    day. Rush and Express Deliver not available on orders with 2
                    or more buildings or pins.
                </Typography>
            </Box>
            <PricingPopover
                open={pricingPopoverOpen}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                priceGridType={pricingType}
            ></PricingPopover>
        </Box>
    );
};

export default connect(mapPricingStateToProps)(OrderSummary);
