import React, { useState, useEffect } from 'react';
import { withStyles, Button, ListItem, ListItemText } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { CheckCircle } from '@material-ui/icons';

const styles = theme => ({
    listItem: {
        border: `1px solid ${grey[200]}`,
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        boxSizing: 'border-box',
    },
    cloneButton: {
        boxShadow: 'none',
        textTransform: 'initial',
    },
});

const CloneProjectModalListItem = (props) => {
    const [successfullyCloned, setSuccessfullyCloned] = useState(props.successfullyCloned);
    useEffect(() => () => {
        setSuccessfullyCloned(props.successfullyCloned);
    }, [props.successfullyCloned]);
    return (
        <ListItem
            key={props.key}
            className={props.classes.listItem}
        >
            <ListItemText
                primary={props.option.displayString}
            />
            {successfullyCloned && <CheckCircle style={{ fill: 'green' }} />}
            {!successfullyCloned ?
                <Button
                    onClick={e => props.handleCloneSelectionClick(e, props.option)}
                    color="primary"
                    variant="contained"
                    size="small"
                    className={props.classes.cloneButton}
                >
                    Clone project
                </Button>
                :
                <Button
                    onClick={e => props.handleGoToClonedProject(e, props.clonedProject.projectId)}
                    color="primary"
                    variant="contained"
                    size="small"
                    className={props.classes.cloneButton}
                >
                    Go to Cloned Project
                </Button>
            }
        </ListItem>
    );
};

export default withStyles(styles)(CloneProjectModalListItem);
