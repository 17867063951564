import { ObjectValues } from "./util/typeUtilities";

const PAYMENT_TYPE: { [key: string]: string } = {
    NONE: 'NONE',
    USER: 'USER',
    ORGANIZATION: 'ORGANIZATION',
};

export type PaymentType = ObjectValues<typeof PAYMENT_TYPE>

export default PAYMENT_TYPE;
