import ReactGA from 'react-ga';
import { LOGIN_SUCCESS } from '../actions/Auth';
import { GET_CURRENT_USER_SUCCESS, GET_CURRENT_USER_SUCCESS_BACKGROUND } from '../actions/User';

export default () => next => (action) => {
    if (action.type === GET_CURRENT_USER_SUCCESS || action.type === GET_CURRENT_USER_SUCCESS_BACKGROUND) {
        const { userId } = action.response;
        ReactGA.set({ userId });
    }
    if (action.type === LOGIN_SUCCESS) {
        const { userId } = action.user;
        ReactGA.set({ userId });
    }

    return next(action);
};
