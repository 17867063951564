import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';

const style = theme => ({
    button: {
        position: 'fixed',
        bottom: 30,
        right: 30,
        zIndex: 10,
        backgroundColor: '#40ad48',
        [theme.breakpoints.down('xs')]: {
            width: '95%',
            bottom: 20,
            right: 0,
            left: 0,
            margin: 'auto',
        },
        '&:hover': {
            backgroundColor: '#79c57e',
        },
    },
    extendedIcon: {
        fill: 'white',
        marginRight: theme.spacing(1),
    },
});

const FloatingActionButton = props => (
    <Fab
        id={props.id}
        className={props.classes.button}
        color="secondary"
        onClick={props.onClick}
        disabled={props.disabled}
        variant={props.variant}
    >
        {
            props.children || (
                (props.variant === 'extended') ? (
                    <React.Fragment> <AddIcon className={props.classes.extendedIcon} /> Add New </React.Fragment>
                ) : (
                    <AddIcon />
                )
            )
        }
    </Fab>
);


FloatingActionButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    variant: PropTypes.oneOf(['round', 'extended']),
};

FloatingActionButton.defaultProps = {
    children: null,
    disabled: false,
    id: null,
    variant: 'round',
};

export default withStyles(style)(FloatingActionButton);
