const NearmapOrderStatus = {
    Billed: 'Billed',
    Pending: 'Pending',
    BillingFailed: 'BillingFailed',
}

export const NearmapOrderStatusMap = {
    All: [],
    Pending: [NearmapOrderStatus.Pending],
    Billed: [NearmapOrderStatus.Billed, 'PaidWithCredit'],
    'Billing Failed': [NearmapOrderStatus.BillingFailed],
}

export default NearmapOrderStatus