import {
  getRequestedOrOwnedSketchOrdersV2,
  getSketchOSOrdersByOrganization,
} from 'actions/SketchOSActions';
import Pager from 'components/Pager';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'components/DataTable';
import SketchOrder from 'lib/Models/SketchOrder';
import { Grid } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import MeasurementRow from './MeasurementRow';
import FilterSelector from 'components/FilterSelector';
import { SketchOrderStatusMapKey, SketchOrderStatusMap } from 'lib';

type PartialState = {
  currentUser: { userId: number };
  currentUserRoles: { isAccountAdmin: boolean };
  organization: { id: number };
  sketchOS: {
    currentPage: number;
    sketchOSOrders: SketchOrder[];
    totalPages: number;
    totalResults: number;
    failedSketchOrderIds: number[];
  };
};

type MeasurementOrdersProps = {
  onReceivedFailedTransactions?: (sketchOrderIds: number[]) => void;
  reloadRequested?: boolean | undefined;
  onReloadAcknowledged?: () => void;
  failedTransactionsResolved: boolean
} & WithStyles<typeof styles>;

const styles = createStyles({
  orderType: {
    color: '#222222',
    fontSize: 16,
  },
  rowText: {
    color: '#000000DE',
    fontSize: 14,
  },
  headerText: {
    color: '#999999',
    fontSize: 13,
    textTransform: 'uppercase',
  },
});

const MeasurementOrders = (props: MeasurementOrdersProps) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const { classes, onReceivedFailedTransactions, failedTransactionsResolved } = props;

  const [status, setStatus] = useState<{
    key: SketchOrderStatusMapKey;
    values: string[];
  }>({ key: 'All', values: [] });

  const handleStatusChange = (statusKey: SketchOrderStatusMapKey) => {
    setStatus({ key: statusKey, values: SketchOrderStatusMap[statusKey] });
    setPage(1);
  };

  const {
    currentUser: { userId },
    currentUserRoles: { isAccountAdmin },
    organization: { id: organizationId },
    sketchOS: { sketchOSOrders, totalPages, failedSketchOrderIds },
  } = useSelector((state: PartialState) => state);

  useEffect(() => {
    if (isAccountAdmin) {
      dispatch(
        getSketchOSOrdersByOrganization(
          organizationId,
          page,
          undefined,
          undefined,
          undefined,
          status.values
        )
      );
    } else {
      dispatch(getRequestedOrOwnedSketchOrdersV2(userId, page, status.values));
    }
  }, [isAccountAdmin, dispatch, organizationId, userId, page, status]);

  const getOrders = () => {
    dispatch(getSketchOSOrdersByOrganization(organizationId, page));
  };

  useEffect(() => {
    getOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failedTransactionsResolved])

  useEffect(() => {
    if (onReceivedFailedTransactions && failedSketchOrderIds.length > 0)
      onReceivedFailedTransactions(failedSketchOrderIds);
  }, [failedSketchOrderIds, onReceivedFailedTransactions]);

  const columnHeaders = [
    { title: 'Date', width: 3 },
    { title: 'Job Address', width: 4 },
    { title: 'Amount', width: 2 },
    { title: 'Status', width: 3 },
  ];

  return (
    <Grid container xs={12} direction='column'>
      <Grid item xs={12}>
        <FilterSelector
          keyValueMap={SketchOrderStatusMap}
          selectedKey={status.key}
          onChange={handleStatusChange}
          sx={{ width: '10em', marginLeft: 'auto' }}
        />
      </Grid>
      <Grid item xs={12}>
        <DataTable
          columnHeaders={columnHeaders}
          headerStyle={classes.headerText}
        >
          {sketchOSOrders.map((item) => (
            <MeasurementRow key={item.id} sketchOrder={item} onCancelled={getOrders} />
          ))}
        </DataTable>
        <Pager
          totalPages={totalPages}
          currentPage={page}
          nextClick={() => {
            setPage(page + 1);
          }}
          prevClick={() => {
            setPage(page - 1);
          }}
          outerStyle={{}}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(MeasurementOrders);
