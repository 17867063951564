import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const style = () => ({
    dialog: {
        minWidth: 300,
    },
    flex: {
        flexGrow: 1,
    },
});

const ChooseSingleEstimateDocumentDialog = (props) => {
    const handleOnListItemClick = (estimateId) => {
        props.onListItemClick(estimateId);
    };

    const renderEstimate = estimate => (
        <ListItem
            key={estimate.id}
            button
            onClick={() => handleOnListItemClick(estimate.id)}
        >
            <ListItemText
                primary={estimate.name}
            />
            <Typography
                align="right"
                variant="subtitle1"
            >
                {
                    estimate.grandTotal
                        ? estimate.grandTotal.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })
                        : '$0.00'
                }
            </Typography>
        </ListItem>
    );

    return (
        <Dialog
            classes={{
                paper: props.classes.dialog,
            }}
            fullScreen={props.fullScreen}
            open={props.open}
        >
            <DialogTitle>
                Choose Estimate Option
            </DialogTitle>
            <DialogContent>
                <List>
                    {props.estimates.map(renderEstimate)}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onDismissClick}>
                    Back
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ChooseSingleEstimateDocumentDialog.defaultProps = {
    open: false,
};

ChooseSingleEstimateDocumentDialog.propTypes = {
    estimates: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        grandTotal: PropTypes.number.isRequired,
    })).isRequired,
    onListItemClick: PropTypes.func.isRequired,
    onDismissClick: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool,
    classes: PropTypes.shape({
        dialog: PropTypes.string.isRequired,
        flex: PropTypes.string.isRequired,
    }).isRequired,
};

export default withStyles(style)(withMobileDialog()(ChooseSingleEstimateDocumentDialog));
