import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBack from '@material-ui/icons/ArrowBack';

// Inspired by:
// https://github.com/TeamWertarbyte/material-ui-search-bar/blob/master/src/components/SearchBar/SearchBar.js

const getStyles = (props, state) => {
    const { disabled } = props;
    const { value } = state;
    const nonEmpty = value.length > 0;

    return {
        root: {
            backgroundColor: 'white',
        },
        iconButtonClear: {
            style: {
                opacity: disabled && 0.38,
                transform: nonEmpty ? 'scale(1, 1)' : 'scale(0, 0)',
                transition: 'transform 200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
            },
        },
        iconButtonBack: {
            style: {
                marginLeft: -12,
            },
        },
    };
};

class SearchAppBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            lastSearch: '',
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({ ...this.state, value: nextProps.value });
        }
    }

    handleBlur = () => {
        if (this.state.value.trim().length === 0) {
            this.setState({ value: '' });
        }
    }

    handleInput = (e) => {
        this.setState({ value: e.target.value });
    }

    handleKeyPressed = (e) => {
        if (e.charCode === 13) {
            this.runSearch();
        }
    }

    runSearch = () => {
        if (this.state.value !== this.state.lastSearch) {
            if (this.state.value.length) {
                this.setState({ lastSearch: this.state.value });
                this.props.onSearch(this.state.value);
            } else if (this.state.lastSearch.length) {
                this.clearSearch();
            }
        }
    }

    clearSearch = () => {
        this.setState({ value: '', lastSearch: '' });
        this.props.onCancel();
    }

    render() {
        const styles = getStyles(this.props, this.state);
        const { value } = this.state;
        const { disabled } = this.props;

        const InputProps = {
            disableUnderline: true,
            classes: {
                input: this.props.classes.input,
            },
        };

        return (
            <AppBar>
                <Toolbar
                    style={{
                        ...styles.root,
                    }}
                >
                    <IconButton style={styles.iconButtonBack.style} onClick={this.props.closeSearchBar}>
                        <ArrowBack />
                    </IconButton>
                    {/* This seems to need to be unmounted until the bar is opened so it can be set to autofocus */}
                    {this.props.searchOpen &&
                        <TextField
                            onBlur={this.handleBlur}
                            value={value}
                            onChange={this.handleInput}
                            onKeyPress={this.handleKeyPressed}
                            fullWidth
                            placeholder={this.props.placeholder}
                            InputProps={InputProps}
                            disabled={disabled}
                            autoFocus
                        />
                    }
                    <IconButton
                        onClick={this.clearSearch}
                        style={styles.iconButtonClear.style}
                        disabled={disabled}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        );
    }
}

SearchAppBar.propTypes = {
    disabled: PropTypes.bool,
    onSearch: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    closeSearchBar: PropTypes.func.isRequired,
    value: PropTypes.string,
    classes: PropTypes.objectOf(PropTypes.shape).isRequired,
    placeholder: PropTypes.string,
};

SearchAppBar.defaultProps = {
    disabled: false,
    onCancel: () => {
    },
    value: '',
    placeholder: 'Search',
};

const staticStyles = {
    input: {
        height: '38px',
        padding: 0,
    },
};

export default withStyles(staticStyles)(SearchAppBar);
export { SearchAppBar };
