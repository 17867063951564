import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import dateFormat from 'dateformat';
import NumberFormat from 'react-number-format';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import AddCircle from '@material-ui/icons/AddCircle';
import {
    getProjectEstimate,
} from '../../actions/ProjectEstimate';

const style = {
    estimateHeader: {
        width: '100%',
        maxWidth: 1350,
        margin: 'auto',
        marginBottom: 10,
    },
    exportPdfButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
    },
    subTitle: {
        fontSize: 12,
        color: 'rgba(0,0,0,.5)',
        textTransform: 'uppercase',
    },
    totalsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 20,
    },
    total: {
        marginRight: 80,
    },
    totalLabel: {
        fontSize: 14,
        color: 'rgba(0,0,0,.5)',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        display: 'block',
    },
    totalText: {
        fontSize: 22,
    },
    grangeTotalText: {
        fontSize: 22,
        fontWeight: 'bold',
    },
    count: {
        color: 'rgba(0,0,0,.5)',
        alignSelf: 'flex-end',
        textAlign: 'right',
        flex: 1,
    },
};

const currencyFormat = {
    isNumericString: true,
    displayType: 'text',
    thousandSeparator: true,
    prefix: '$',
    decimalScale: 2,
    fixedDecimalScale: true,
};

class EstimateTotal extends Component {
    componentDidMount() {
        this.props.dispatch(getProjectEstimate(
            this.props.match.params.id,
            this.props.match.params.estimateId,
        ));
    }

    render() {
        const {
            estimate,
            totalResults,
            onExportPdfClick,
        } = this.props;
        return (
            <div style={style.estimateHeader}>
                <div style={style.exportPdfButtonContainer}>
                    <Chip id="exportPdfButton" label="Export PDF" icon={<AddCircle />} component="button" onClick={() => onExportPdfClick()} clickable variant="outlined" />
                </div>
                <Typography style={style.title}>
                    <span>{estimate.name}</span>
                </Typography>
                <Typography style={style.subTitle}>
                    created:{' '}
                    {dateFormat(estimate.createdAt, 'mmm d, yyyy h:MM TT')}
                    {estimate.updatedAt && (
                        <span>
                            {' '}
                            | updated:{' '}
                            {dateFormat(
                                estimate.updatedAt,
                                'mmm d, yyyy h:MM TT',
                            )}
                        </span>
                    )}
                </Typography>
                <div style={style.totalsContainer}>
                    <Typography style={style.total}>
                        <span style={style.totalLabel}>Subtotal</span>
                        <NumberFormat
                            style={style.totalText}
                            value={estimate.subTotal ? estimate.subTotal : 0}
                            {...currencyFormat}
                        />
                    </Typography>
                    <Typography style={style.total}>
                        <span style={style.totalLabel}>Taxes</span>
                        <NumberFormat
                            style={style.totalText}
                            value={estimate.taxes ? estimate.taxes : 0}
                            {...currencyFormat}
                        />
                    </Typography>
                    <Typography style={style.total}>
                        <span style={style.totalLabel}>Total</span>
                        <NumberFormat
                            style={style.grangeTotalText}
                            value={
                                estimate.grandTotal ? estimate.grandTotal : 0
                            }
                            {...currencyFormat}
                        />
                    </Typography>
                    <Typography style={style.count}>{totalResults} Item(s)</Typography>
                </div>
            </div>
        );
    }
}

EstimateTotal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            estimateId: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    totalResults: PropTypes.number.isRequired,
    estimate: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        subTotal: PropTypes.number,
        taxes: PropTypes.number,
        grandTotal: PropTypes.number,
        discount: PropTypes.number,
        createdAt: PropTypes.string,
        updatedAt: PropTypes.string,
        isChosen: PropTypes.bool,
    }).isRequired,
};

const mapStateToProps = (state) => {
    const { projectEstimateItems, projectEstimate } = state;
    const { estimate } = projectEstimate;
    const { totalResults } = projectEstimateItems;
    return {
        totalResults,
        estimate,
    };
};

export default connect(mapStateToProps)(EstimateTotal);
