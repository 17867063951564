import React from 'react'
import { Button, withStyles, createStyles, WithStyles, Typography } from '@material-ui/core'
import { PersonAdd } from '@material-ui/icons'

interface AddUserButtonProps extends WithStyles<typeof styles> {
    handleClick: () => void
}

const styles = createStyles({
    button: {
        borderRadius: 25,
        border: '2px solid #006AFB',
    },
    icon: {
        width: 24,
        color: '#006AFB',
        paddingRight: 8
    },
    buttonText: {
        font: 'bold 16px Roboto',
        color: '#006AFB',
        textTransform: 'none'
    }
})

const AddUserButton = (props: AddUserButtonProps) => {

    const { handleClick, classes } = props;

    return (
        <Button
            onClick={handleClick}
            variant='outlined'
            className={classes.button}
        >
            <PersonAdd className={classes.icon} />
            <Typography className={classes.buttonText}>Add Member</Typography>
        </Button>
    )
}

export default withStyles(styles)(AddUserButton)