import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    resetPageToolbar,
    setPageToolbarButtons,
} from '../../actions/RoofSnapPageToolbarActions';
import { createEstimateTemplate } from '../../actions/EstimateTemplatesActions';
import { setReduxMessage } from '../../actions/ReduxMessagesActions';
import SaveTemplateDialog from './SaveTemplateDialog';

class CreateEstimateTemplate extends Component {
    state = {
        open: false,
    };

    componentDidMount() {
        this.props.dispatch(setPageToolbarButtons([
            {
                buttonText: 'Save As Template',
                buttonAction: this.openDialog,
            },
        ]));
    }

    componentWillUnmount() {
        this.props.dispatch(resetPageToolbar());
    }

    saveTemplate = () => {
        this.props
            .dispatch(createEstimateTemplate(
                this.props.match.params.id,
                this.props.match.params.estimateId,
                {
                    name: this.state.name,
                    organizationId: this.props.organizationId,
                    officeId: this.props.officeId,
                },
            ))
            .then((result) => {
                if (
                    result &&
                    result.type === 'CREATE_ESTIMATE_TEMPLATE_SUCCESS'
                ) {
                    this.props.dispatch(setReduxMessage('Template Saved Successfully'));
                }
                this.setState({ open: false });
            });
    };

    openDialog = () => {
        this.setState({ open: true });
    };

    closeDialog = () => {
        this.setState({ open: false });
    };

    handleDialogChange = name => (event) => {
        this.setState({ [name]: event.target.value });
    };

    render() {
        return (
            <div>
                <SaveTemplateDialog
                    open={this.state.open}
                    onCancelClick={this.closeDialog}
                    onSaveClick={this.saveTemplate}
                    onChange={this.handleDialogChange}
                    name={this.state.name}
                />
            </div>
        );
    }
}

CreateEstimateTemplate.propTypes = {
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            estimateId: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
        }),
    }).isRequired,
    organizationId: PropTypes.number,
    officeId: PropTypes.number,
};

CreateEstimateTemplate.defaultProps = {
    organizationId: 0,
    officeId: 0,
};

const mapStateToProps = (state) => {
    const { projectHome } = state;
    const { currentProject } = projectHome;
    const { organizationId, officeId } = currentProject;
    return {
        organizationId,
        officeId,
    };
};

export default withRouter(connect(mapStateToProps)(CreateEstimateTemplate));
