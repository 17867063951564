import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { resetPageToolbar } from '../../actions/RoofSnapPageToolbarActions';
import { getOrganizationFeatures } from '../../actions/OrganizationFeatures';

import getMobileOperatingSystem from '../../lib/MobileOperatingSystemChecker';

const styles = {
    root: {
        padding: 24,
    },
    card: {
        minWidth: 275,
        maxWidth: '450px',
        margin: '0 auto',
        padding: '50px 20px 20px',
    },
    button: {
        width: '100%',
    },
    completeCheckWrapper: {
        display: 'flex',
    },
    completeButton: {
        width: '100%',
    },
    mobileBadges: {
        paddingTop: 20,
    },
    link: {
        textDecoration: 'none',
    },
};

class CompleteMessage extends Component {
    componentWillUnmount() {
        this.props.dispatch(resetPageToolbar());
    }
    handleContinueClick = async () => {
        await this.props.dispatch(getOrganizationFeatures(this.props.organizationId));
        this.props.history.push({
            pathname: '/projects',
        });
    };

    render() {
        const { classes } = this.props;
        const isMobile = getMobileOperatingSystem();
        return (
            <div className={classes.root}>
                <Card className={classes.card}>
                    <div className={this.props.classes.completeCheckWrapper}>
                        <CheckCircleIcon
                            style={{
                                flex: 1,
                                fill: this.props.theme.palette.primary['500'],
                                width: 100,
                                height: 100,
                            }}
                        />
                    </div>
                    <CardHeader
                        title="Subscription Activated"
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>Welcome to RoofSnap!</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    !isMobile &&
                                    <Button
                                        id="continue"
                                        variant="contained"
                                        color="primary"
                                        className={this.props.classes.completeButton}
                                        onClick={this.handleContinueClick}
                                    >
                                        Continue
                                    </Button>
                                }
                            </Grid>
                            <Grid item xs={6}>
                                <Link className={this.props.classes.link} to="/projects">
                                    <Button variant="outlined" color="secondary" fullWidth>
                                        View Projects
                                    </Button>
                                </Link>
                            </Grid>
                            <Grid item xs={6}>
                                <Link className={this.props.classes.link} to="/orders/create">
                                    <Button variant="outlined" color="secondary" fullWidth>
                                        Order A Sketch
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

CompleteMessage.propTypes = {
    organizationId: PropTypes.number,
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired,
        card: PropTypes.string.isRequired,
        completeCheckWrapper: PropTypes.string.isRequired,
        button: PropTypes.string.isRequired,
        completeButton: PropTypes.string.isRequired,
        mobileBadges: PropTypes.string.isRequired,
    }).isRequired,
    theme: PropTypes.shape({
        palette: PropTypes.shape({
            primary: PropTypes.shape({
                500: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
};

CompleteMessage.defaultProps = {
    organizationId: null,
};

const mapStateToProps = (state) => {
    const { organization, features } = state;
    const { id: organizationId } = organization;
    return {
        organizationId,
        features,
    };
};

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(CompleteMessage));
