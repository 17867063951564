import React from 'react'
import { Grid, Typography, withStyles, createStyles, WithStyles, Theme } from '@material-ui/core'


interface UserDataProps extends WithStyles<typeof styles> {
    firstName: string,
    lastName: string,
    userRole?: string | null,
    isActive: boolean,
}

const styles = (theme: Theme) => createStyles({
    root: {
        height: '80px',
        width: '100%',
        backgroundColor: '#FFF',
    },
    circle: {
        width: '44px',
        height: '44px',
        backgroundColor: '#FFF',
        border: '1px solid #E6E7E9',
        borderRadius: '50%',
        font: '16px Roboto medium',
        color: '#212121',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        margin: '0px 11px'
    },
    nameText: {
        font: '18px Roboto',
        color: '#222222',
        padding: '6px 0px',
        textAlign: 'left',
        textTransform: 'none'
    },
    roleText: {
        font: '16px Roboto',
        color: '#999999',
        textAlign: 'left',
        textTransform: 'none',
        padding: '6px 0px',

    },
})

const UserDataChip = (props: UserDataProps) => {
    const { classes, firstName, lastName, userRole, isActive } = props;
    const initials = firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()

    return (
        <Grid
            item
            container
            className={classes.root}
            alignItems='center'
            alignContent='center'
            style={isActive ? { backgroundColor: '#EEF5FF' } : {}}
        >
            <Grid container item className={classes.circle} >
                <Typography >{initials}</Typography>
            </Grid>
            <Grid container item direction="column" xs={8}>
                <Typography className={classes.nameText} style={isActive ? { color: '#056CF2' } : {}}>
                    {firstName + ' ' + lastName}
                </Typography>
                <Typography className={classes.roleText}>{userRole}</Typography>
            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(UserDataChip)