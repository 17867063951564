import {
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
} from '../actions/User';
import LocalStorageWrapper from '../lib/LocalStorageWrapper';

function users(state = {
    isFetching: false,
    organizationId: LocalStorageWrapper.getItem('user') ? LocalStorageWrapper.getItem('user').organizationId : null,
}, action) {
    switch (action.type) {
    case GET_USER_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case GET_USER_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            organizationId: action.response.organizationId,
        });
    case GET_USER_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
        });
    default:
        return state;
    }
}

export default users;
