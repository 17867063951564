import React, { ReactElement, PropsWithChildren } from 'react';
import {
    Grid,
    Typography,
} from '@material-ui/core';
interface SettingsContainerProps {
    title?: string,
    description?: string | ReactElement,
    sectionTitleStyle?: string,
    descriptionStyle?: string,
}


const SettingsContainer = (props: PropsWithChildren<SettingsContainerProps>) => {

    return (
        <Grid container item xs={12}>
            <Grid item xs={12}>
                <Typography className={props.sectionTitleStyle} data-cy="settings-section-title">
                    {props.title}
                </Typography>
            </Grid>
            <Grid xs={12}>
                <Typography className={props.descriptionStyle}>
                    {props.description}
                </Typography>
            </Grid>
            <Grid xs={12}>
                {props.children}
            </Grid>
        </Grid>
    )
}

export default SettingsContainer;