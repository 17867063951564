import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { head } from 'lodash';
import Map from './Map';

class Location extends Component {
    addMarker = (marker) => {
        if (this.props.multiMarkerDisabled) return;
        this.props.onMarkerAdded(marker);
    };
    updateMarker = (marker) => {
        this.props.onMarkerUpdated(marker);
    };
    handleMapMounted = (ref) => {
        this.map = ref;
    };
    handleSearchBoxMounted = (ref) => {
        this.searchBox = ref;
    };
    handleMapClick = (event) => {
        if (this.props.markers.length === 0) return;
        this.addMarker({
            coordinates: {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            },
        });
    };
    handleZoomChanged = () => {
        this.props.onZoomChanged(this.map.getZoom());
    };
    handleCenterChanged = () => {
        this.props.onCenterChanged(this.map.getCenter());
    };
    handlePlacesChanged = () => {
        const places = this.searchBox.getPlaces();
        const firstPlace = head(places);
        if (!firstPlace) return;
        this.props.onAddressChanged(firstPlace.formatted_address);
    };
    handleMarkerLocationChanged = (event, marker) => {
        this.updateMarker({
            id: marker.id,
            coordinates: {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            },
        });
    };
    handleMarkerDoubleClick = (event, marker) => {
        // There must be at least one marker on the map
        if (this.props.markers.length === 1) return;
        if (this.props.multiMarkerDisabled) return;
        this.props.onMarkerRemoved(marker);
    };
    render() {
        return (
            <Map
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${
                    process.env.REACT_APP_SKETCHOS_GOOGLE_MAPS_API_KEY
                }&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={this.props.loadingElement}
                containerElement={this.props.containerElement}
                mapElement={this.props.mapElement}
                onMapMounted={this.handleMapMounted}
                onSearchBoxMounted={this.handleSearchBoxMounted}
                onClick={this.handleMapClick}
                onPlacesChanged={this.handlePlacesChanged}
                onMarkerLocationChanged={this.handleMarkerLocationChanged}
                onMarkerDoubleClick={this.handleMarkerDoubleClick}
                onMarkerInfoWindowClosed={this.props.onMarkerInfoWindowClosed}
                onMarkerClick={this.props.onMarkerClick}
                center={this.props.center}
                markers={this.props.markers}
                zoom={this.props.zoom}
                onZoomChanged={this.handleZoomChanged}
                onCenterChanged={this.handleCenterChanged}
            />
        );
    }
}

Location.defaultProps = {
    onMarkerAdded: null,
    onMarkerRemoved: null,
    multiMarkerDisabled: false,
    onMarkerClick: () => {},
    onMarkerInfoWindowClosed: () => {},
    containerElement: React.cloneElement(<div style={{ height: '350px' }} />),
    mapElement: React.cloneElement(<div id="googleMap" style={{ height: '100%' }} />),
    loadingElement: React.cloneElement(<div />),
};

Location.propTypes = {
    onAddressChanged: PropTypes.func.isRequired,
    markers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        coordinates: PropTypes.shape({
            lat: PropTypes.number.isRequired,
            lng: PropTypes.number.isRequired,
        }).isRequired,
    })).isRequired,
    onMarkerAdded: PropTypes.func,
    onMarkerUpdated: PropTypes.func.isRequired,
    onMarkerRemoved: PropTypes.func,
    onMarkerClick: PropTypes.func,
    onMarkerInfoWindowClosed: PropTypes.func,
    onZoomChanged: PropTypes.func.isRequired,
    onCenterChanged: PropTypes.func.isRequired,
    center: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }).isRequired,
    zoom: PropTypes.number.isRequired,
    multiMarkerDisabled: PropTypes.bool,
    containerElement: PropTypes.element,
    loadingElement: PropTypes.element,
    mapElement: PropTypes.element,
};

export default Location;
