import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    ListItemIcon,
    MenuList,
    Typography,
    MenuItem as MatMenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Home,
    DescriptionOutlined,
    ExpandMore,
    Settings,
} from '@material-ui/icons';
import LockIcon from '@material-ui/icons/Lock';
import Drawer from '@material-ui/core/Drawer';
import featureFlags from '../../lib/FeatureFlags.ts';
import hostConfig from '../../lib/hostConfig.ts';
import DrawerMenuItem from './DrawerMenuItem.tsx';

const style = {
    roofSnapMenuItem: {
        backgroundColor: hostConfig.theme.palette.primary[500],
        color: '#ffffff',
        fontSize: 24,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        width: 260,
        padding: 12,
    },
    lockIcon: {
        color: '#000000',
        opacity: 0.5,
    },
};

class RoofSnapDrawer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeRoute: window.location.pathname,
        };
    }

    componentDidUpdate() {
        if (this.state.activeRoute !== window.location.pathname) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                activeRoute: window.location.pathname,
            });
        }
    }

    render() {
        const { currentUserRoles, open, toggle } = this.props;
        const { isAccountAdmin } = currentUserRoles;
        const classicUrl = process.env.REACT_APP_ROOFSNAP_ONLINE_HOST;

        return (
            <Drawer open={open} onClose={toggle}>
                <MatMenuItem style={style.roofSnapMenuItem} onClick={toggle}>
                    {hostConfig.siteName}
                </MatMenuItem>
                <DrawerMenuItem
                    title='Projects'
                    path='/projects'
                    icon={<Home />}
                    isActive={this.state.activeRoute === '/projects'}
                    onClick={this.props.toggle}
                />
                <DrawerMenuItem
                    title='Sketch Orders'
                    path='/orders'
                    icon={<DescriptionOutlined />}
                    isActive={this.state.activeRoute === '/orders'}
                    onClick={this.props.toggle}
                />
                {featureFlags.projectBoard && (
                    <DrawerMenuItem
                        title='Project Board'
                        path='/project-board'
                        isActive={this.state.activeRoute === '/project-board'}
                        onClick={this.props.toggle}
                    />
                )}
                {isAccountAdmin && (
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <ListItemIcon>
                                <Settings />
                            </ListItemIcon>
                            <Typography>Settings</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: 0 }}>
                            <MenuList style={{ width: '100%' }}>
                                {featureFlags.v2UsersPage && (
                                    <>
                                        <DrawerMenuItem
                                            title='Offices'
                                            path={`${classicUrl}/Account`}
                                            onClick={this.props.toggle}
                                            isExternal
                                        />
                                        <DrawerMenuItem
                                            title='Users'
                                            path='/settings/users'
                                            icon={
                                                this.props.isFreeAccount ? (
                                                    <LockIcon
                                                        style={style.lockIcon}
                                                    />
                                                ) : null
                                            }
                                            isActive={
                                                this.state.activeRoute ===
                                                '/settings/users'
                                            }
                                            onClick={this.props.toggle}
                                        />
                                    </>
                                )}

                                {!featureFlags.v2UsersPage && (
                                    <DrawerMenuItem
                                        title='Offices & Users'
                                        path={`${classicUrl}/Account`}
                                        onClick={this.props.toggle}
                                        isExternal
                                    />
                                )}

                                <DrawerMenuItem
                                    title='Documents'
                                    path='/settings/documents'
                                    icon={
                                        this.props.isFreeAccount ? (
                                            <LockIcon style={style.lockIcon} />
                                        ) : null
                                    }
                                    isActive={
                                        this.state.activeRoute ===
                                        '/settings/documents'
                                    }
                                    onClick={this.props.toggle}
                                />

                                <DrawerMenuItem
                                    title='Billing'
                                    path='/settings/billing'
                                    isActive={
                                        this.state.activeRoute ===
                                        '/settings/billing'
                                    }
                                    onClick={this.props.toggle}
                                />

                                <DrawerMenuItem
                                    title='Materials'
                                    path={`${classicUrl}/Materials`}
                                    isExternal
                                    onClick={this.props.toggle}
                                />
                            </MenuList>
                        </AccordionDetails>
                    </Accordion>
                )}
            </Drawer>
        );
    }
}

RoofSnapDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    currentUserRoles: PropTypes.shape({
        isAccountAdmin: PropTypes.bool,
        isAdmin: PropTypes.bool,
        sketchosEnabled: PropTypes.bool,
    }).isRequired,
    isFreeAccount: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    const { currentUserRoles, organizationFeatures, organization } = state;
    const { isFreeAccount } = organization;

    return {
        currentUserRoles,
        organizationFeatures,
        isFreeAccount,
    };
};

export default connect(mapStateToProps)(RoofSnapDrawer);
