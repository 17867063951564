import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Collapse from '@material-ui/core/Collapse';
import roofsnapTheme from '../../lib/theme';

const style = theme => ({
    toolbar: {
        display: 'flex',
        backgroundColor: '#ECECEC',
        [theme.breakpoints.down('md')]: {
            paddingBottom: theme.spacing(1),
        },
        borderTop: '1px solid #dbdbdb',
        borderBottom: '1px solid #dbdbdb',
        minHeight: '46px',
    },
    activateHeading: {
        textAlign: 'center',
        fontSize: '14px',
        color: 'rgb(0,0,0,0.54)',
    },
    activateLink: {
        color: roofsnapTheme.palette.roofsnapBlue['500'],
        textDecoration: 'inherit',
        fontWeight: 'bold',
    },
});

function HideOnScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger();
    return (
        <Collapse appear={false} direction="down" in={!trigger}>
            {children}
        </Collapse>
    );
}

class ActivateAccountAlert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: (props.organizationIsExpired &&
                this.checkForActiveUrls()),
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.organizationIsExpired !== prevProps.organizationIsExpired || this.props.location.pathname !== prevProps.location.pathname) {
            this.updateActivationBarToggleBasedOnNewUrl();
        }
    }

    updateActivationBarToggleBasedOnNewUrl = () => {
        const open = (this.props.organizationIsExpired &&
             this.checkForActiveUrls());
        this.setState({ open });
    };

    checkForActiveUrls = () => (this.props.location.pathname.includes('projects'))

    render() {
        return (
            <HideOnScroll {...this.props}>
                {
                    this.state.open &&
                        <Toolbar className={this.props.classes.toolbar}>
                            <Grid container spacing={1} justify="center" alignItems="center">
                                <Grid item xs={12}>
                                    <Typography className={this.props.classes.activateHeading}>
                                        <Link to="/orders/create" href="/orders/create" className={this.props.classes.activateLink}>
                                            Order measurements
                                        </Link> without a subscription, or&nbsp;
                                        <Link to="/settings/billing" href="/settings/billing" className={this.props.classes.activateLink}>
                                            subscribe
                                        </Link> for order discounts and access to DIY projects.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Toolbar>
                }
            </HideOnScroll>
        );
    }
}

ActivateAccountAlert.propTypes = {
    classes: PropTypes.shape({
        toolbar: PropTypes.string,
        text: PropTypes.string,
    }),
    organizationIsExpired: PropTypes.bool.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    organization: PropTypes.shape({
        isFreeAccount: PropTypes.bool.isRequired,
    }).isRequired,
};

ActivateAccountAlert.defaultProps = {
    classes: {}
}

const mapStateToProps = (state) => {
    const { organization, features } = state;
    const { isFreeAccount } = organization;
    return {
        organizationIsExpired: isFreeAccount,
        organization,
        features,
    };
};

export default withRouter(connect(mapStateToProps)(withStyles(style)(ActivateAccountAlert)));
