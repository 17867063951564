import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';

type ActivateSubscriptionConfirmationDialogProps = {
    open: boolean,
    onSubscribe: () => void,
    onCancel: (open: boolean) => void,
    isAccountInTrial: boolean,
    formattedExpirationDate?: string,
    cost?: number,
}

const ActivateSubscriptionConfirmationDialog = (props: ActivateSubscriptionConfirmationDialogProps) => {
  return (
    <Dialog open={props.open}>
        <DialogTitle>Subscription Billing</DialogTitle>
        <DialogContent>
            {props.isAccountInTrial && <Typography paragraph variant='h6' color='error'>You do not need to subscribe right now. You are in trial {props.formattedExpirationDate ? `until ${props.formattedExpirationDate}.` : ''}</Typography>}
            <Typography variant='subtitle2' color='primary'>Important Note</Typography>
            <Typography paragraph variant='body2'>You have selected to activate a RoofSnap subscription. <span style={{fontWeight: 'bold'}}>Your card will be charged* now and then again each month until cancelled.</span></Typography>
            <Typography paragraph variant='body2'>If you would like to add a new card for user-billed measurement orders, you can do this on the payment details section of the order form.</Typography>
            <Typography paragraph variant='caption'>*Subscription cost may vary based on subscription type and number of sub-users. See <Link to='/pricing'>pricing page</Link> for details. For questions about a custom subscription or annual plan, please <a href='mailto:hello@roofsnap.com'>contact our sales team.</a></Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => props.onCancel(false)}>Cancel</Button>
            <Button onClick={props.onSubscribe}>Continue</Button>
        </DialogActions>
    </Dialog>
  )
}

export default ActivateSubscriptionConfirmationDialog
