export const dueDateText: (
    isRushed: boolean,
    orderCommitmentIntervalMinutes: number,
    duringBusinessHours: boolean
) => string = (
    isRushed: boolean,
    orderCommitmentIntervalMinutes: number,
    duringBusinessHours: boolean
) => {
    if (duringBusinessHours != null) {
        return duringBusinessHours
            ? `${getDueDate(isRushed, orderCommitmentIntervalMinutes).day} by ${
                  getDueDate(isRushed, orderCommitmentIntervalMinutes).time
              }`
            : 'Order will be completed next business day';
    } else {
        return '';
    }
};

export const getDueDate = (
    isRushed: boolean,
    orderCommitmentIntervalMinutes: number
) => {
    const now = new Date(Date.now());
    const rushDueDate = new Date(
        Date.now() + orderCommitmentIntervalMinutes * 60000
    );
    const standardDueDate = new Date(Date.now() + 60000 * 240);
    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];

    if (isRushed) {
        let dueDate = {
            day:
                rushDueDate.getDay() === now.getDay()
                    ? 'Today'
                    : days[rushDueDate.getDay()],
            time: rushDueDate.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            }),
        };
        return dueDate;
    } else {
        let dueDate = {
            day:
                standardDueDate.getDay() === now.getDay()
                    ? 'Today'
                    : days[standardDueDate.getDay()],
            time: standardDueDate.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            }),
        };
        return dueDate;
    }
};
