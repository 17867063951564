import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { OpenInNew, Person, ExitToApp } from '@material-ui/icons';
import Hidden from '@material-ui/core/Hidden';

const style = theme => ({
    iconText: {
        color: '#ffffff',
        fontWeight: 500,
        display: 'inline',
    },
    profileButton: {
        paddingLeft: '10px',
    },
    icon: {
        verticalAlign: 'middle',
        color: '#ffffff !important',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 12,
        },
    },
    header: {
        outline: 'none !important',
        padding: '0 15px 15px',
        borderBottom: '1px solid grey',
        minWidth: 250,
    },
    menuHeaderOrg: {
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: 1.9,
    },
    menuHeader: {
        fontSize: 16,
    },
});

export class RoofSnapLoggedInMenu extends Component {
    state = {
        anchorEl: null,
    };

    handleMenuClick = (e) => {
        this.setState({ anchorEl: e.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    render() {
        const orgEdit = this.props.currentUserRoles.isAccountAdmin;
        const classicUrl = process.env.REACT_APP_ROOFSNAP_ONLINE_HOST;
        const { firstName, classes } = this.props;
        const { anchorEl } = this.state;

        return (
            <React.Fragment>
                <Hidden smUp>
                    <IconButton
                        className={classes.profileButton}
                        onClick={this.handleMenuClick}
                    >
                        <Person className={classes.icon} />
                    </IconButton>
                </Hidden>
                <Hidden xsDown>
                    <Button onClick={this.handleMenuClick} className={classes.profileButton}>
                        <Typography className={classes.iconText}>{firstName}</Typography>
                        <Person className={classes.icon} />
                    </Button>
                </Hidden>
                <Menu
                    id="user-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <div className={classes.header}>
                        <Typography className={classes.menuHeaderOrg}>{this.props.orgName}</Typography>
                        <Typography className={classes.menuHeader}>{`${this.props.firstName} ${this.props.lastName}`}</Typography>
                        <Typography className={classes.menuSubheader}>{this.props.userName}</Typography>
                    </div>
                    <MenuItem
                        component="a"
                        href={`${classicUrl}/Account/Profile`}
                        target="_blank"
                        onClick={this.handleClose}
                    >
                        <Grid container>
                            <Grid item xs>My Profile</Grid>
                            <Grid item><OpenInNew /></Grid>
                        </Grid>
                    </MenuItem>
                    <MenuItem component={Link} to="/changepassword" onClick={this.handleClose}>Change Password</MenuItem>
                    {orgEdit && (
                        <MenuItem
                            component="a"
                            href={`${classicUrl}/Account/OrgProfile`}
                            target="_blank"
                            onClick={this.handleClose}
                        >
                            <Grid container>
                                <Grid item xs>Organization Profile</Grid>
                                <Grid item><OpenInNew /></Grid>
                            </Grid>
                        </MenuItem>
                    )}
                    {orgEdit && (
                        <MenuItem
                            component="a"
                            href={`${classicUrl}/Account`}
                            target="_blank"
                            onClick={this.handleClose}
                        >
                            <Grid container>
                                <Grid item xs>Subscription</Grid>
                                <Grid item><OpenInNew /></Grid>
                            </Grid>
                        </MenuItem>
                    )}
                    <MenuItem onClick={this.props.signOutClick} id="menuSignOut">
                        <Grid container>
                            <Grid item xs>Sign Out</Grid>
                            <Grid item><ExitToApp /></Grid>
                        </Grid>
                    </MenuItem>
                </Menu>
            </React.Fragment>
        );
    }
}

RoofSnapLoggedInMenu.propTypes = {
    signOutClick: PropTypes.func.isRequired,
    currentUserRoles: PropTypes.shape({
        isAccountAdmin: PropTypes.bool,
    }).isRequired,
    userName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    orgName: PropTypes.string,
    classes: PropTypes.shape({
        profileButton: PropTypes.string,
        icon: PropTypes.string,
    }).isRequired,
};

RoofSnapLoggedInMenu.defaultProps = {
    userName: '',
    firstName: '',
    lastName: '',
    orgName: '',
};

const mapStateToProps = (state) => {
    const {
        organization,
        currentUser,
        currentUserRoles,
    } = state;
    const { userName, firstName, lastName } = currentUser;
    const { name: orgName } = organization;

    return {
        currentUserRoles,
        userName,
        firstName,
        lastName,
        orgName,
    };
};

export default connect(mapStateToProps)(withStyles(style)(RoofSnapLoggedInMenu));
