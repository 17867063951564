import { GET_OFFICE_BY_ID_REQUEST, GET_OFFICE_BY_ID_SUCCESS, GET_OFFICE_BY_ID_FAILURE, UPDATE_OFFICE_REQUEST, UPDATE_OFFICE_SUCCESS, UPDATE_OFFICE_FAILURE } from '../actions/Office';

function office(state = {
    officeByIdInformation: {},
}, action) {
    switch (action.type) {
    case GET_OFFICE_BY_ID_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
            officeByIdInformation: {},
        });
    case GET_OFFICE_BY_ID_SUCCESS:
    case UPDATE_OFFICE_SUCCESS:
        return Object.assign({}, state, {
            isFetching: false,
            officeByIdInformation: action.response,
        });
    case GET_OFFICE_BY_ID_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
            officeByIdInformation: {},
        });
    case UPDATE_OFFICE_REQUEST:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case UPDATE_OFFICE_FAILURE:
        return Object.assign({}, state, {
            isFetching: false,
        });
    default:
        return state;
    }
}

export default office;
