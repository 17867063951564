import { combineReducers } from 'redux';
import appReducer from './appReducer';
import auth from './Auth';
import cart from './cartReducer';
import projects from './Projects';
import project from './ProjectReducer';
import projectDrawing from './ProjectDrawingReducer';
import roofSnapAppBar from './RoofSnapAppBarReducer';
import roofSnapPageToolbar from './RoofSnapPageToolbarReducer';
import projectHome from './ProjectHomeReducer';
import projectSharesPerProject from './ProjectSharesPerProjectReducer';
import projectShares from './ProjectSharesReducer';
import users from './Users';
import userOffices from './userOffices';
import officeUsers from './OfficeUsersReducer';
import organizationOffices from './OrganizationOffices';
import organizationUsers from './OrganizationUsers';
import sketchHost from './SketchHost';
import documents from './documentsReducer';
import projectImages from './ProjectImagesReducer';
import routes from './Routes';
import projectEstimate from './ProjectEstimate';
import projectEstimates from './ProjectEstimates';
import projectEstimateItems from './ProjectEstimateItemsReducer';
import projectEstimateItem from './ProjectEstimateItemReducer';
import organizationFeatures from './OrganizationFeatures.ts';
import organization from './Organization';
import estimateTemplatesReducer from './EstimateTemplatesReducer';
import materialItemsReducer from './MaterialItemsReducer';
import materialItemColorOptionsReducer from './MaterialItemColorOptionsReducer';
import sketchOS from './SketchOSReducer';
import sketchOrder from './SketchOrderReducer';
import sketchReportDocument from './SketchReportDocumentsReducer';
import reduxMessages from './ReduxMessagesReducer';
import office from './OfficeReducer';
import trialActivation from './TrialActivationReducer';
import user from './User';
import resetPassword from './ResetPassword';
import payment from './Payment';
import currentUser from './CurrentUser';
import currentUserRoles from './CurrentUserRoles';
import noMatch from './NoMatchReducer';
import sketchOsGeocoding from './SketchOsGeocodingReducer';
import organizationCredits from './OrganizationCreditsReducer';
import billingFailedSketchOrders from './BillingFailedSketchOrdersReducer';
import projectBoard from './ProjectBoardReducer';
import subscription from './subscriptionReducer';
import subscriptionPlans from './subscriptionPlanReducer';
import sketchOrderNotifications from './sketchOrderNotificationsReducer';
import organizationFreeSketchOrders from './OrganizationFreeSketchOrdersReducer';
import documentSharedAccessToken from './documentSharedAccessTokenReducer';
import documentTemplateCategories from './documentTemplateCategoriesReducer';
import document from './documentReducer';
import featuresReducer from './featuresReducer';
import signUp from './signUpReducer';
import searchAppBarReducer from './SearchAppBarReducer';
import sketchOrderPricingReducer from './SketchOrderPricingReducer';
import organizationDeletedProjects from './OrganizationDeletedProjectsReducer';
import SketchOSOfficeByUserReducer from './SketchOSOfficeByUserReducer';
import SketchOSOfficeIdReducer from './SketchOSOfficeIdReducer';

import { LOGOUT_SUCCESS } from '../actions/Auth';

const app = combineReducers({
    app: appReducer,
    auth,
    cart,
    projects,
    project,
    projectDrawing,
    users,
    roofSnapAppBar,
    roofSnapPageToolbar,
    projectHome,
    projectSharesPerProject,
    projectShares,
    userOffices,
    organizationOffices,
    office,
    officeUsers,
    organizationUsers,
    sketchHost,
    documents,
    projectImages,
    routes,
    projectEstimate,
    projectEstimates,
    projectEstimateItems,
    organizationFeatures,
    organization,
    estimateTemplatesReducer,
    projectEstimateItem,
    materialItemsReducer,
    materialItemColorOptionsReducer,
    reduxMessages,
    sketchOS,
    sketchOrder,
    sketchReportDocument,
    trialActivation,
    user,
    payment,
    resetPassword,
    currentUser,
    currentUserRoles,
    noMatch,
    sketchOsGeocoding,
    organizationCredits,
    billingFailedSketchOrders,
    projectBoard,
    subscription,
    subscriptionPlans,
    sketchOrderNotifications,
    organizationFreeSketchOrders,
    documentSharedAccessToken,
    documentTemplateCategories,
    document,
    features: featuresReducer,
    signUp,
    searchAppBar: searchAppBarReducer,
    sketchOrderPricingReducer,
    organizationDeletedProjects,
    SketchOSOfficeByUserReducer,
    SketchOSOfficeIdReducer,
});

const rootReducer = (state, action) => {
    let appState = state;
    if (action.type === LOGOUT_SUCCESS) {
        const { features } = appState;
        appState = { features };
    }

    return app(appState, action);
};

export default rootReducer;
