import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopy from '@material-ui/icons/FileCopy';

const ToolbarCloneProjectButton = (props) => {
    const title = props.disabled ? props.disabledTooltip : props.tooltip;

    const button = (
        <div>
            <IconButton onClick={props.onClick} disabled={props.disabled}>
                <FileCopy style={{ color: props.color }} />
            </IconButton>
        </div>);

    return (title ? <Tooltip title={title}>{button}</Tooltip> : button);
};

ToolbarCloneProjectButton.propTypes = {
    tooltip: PropTypes.string,
    disabled: PropTypes.bool,
    disabledTooltip: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string,
};

ToolbarCloneProjectButton.defaultProps = {
    tooltip: 'Share Project',
    disabled: false,
    disabledTooltip: '',
    color: 'rbga(0,0,0,.53)',
};

export default ToolbarCloneProjectButton;
