import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { createProjectImage } from '../../../../actions/ProjectImagesActions';

import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import ImageImport from 'components/ImageImport';

type AddImageButtonProps = {
    projectId: string;
    pageSize?: number;
};

const AddImageButton = ({ projectId, pageSize }: AddImageButtonProps) => {
    const dispatch = useDispatch();
    const imageImportRef = useRef<HTMLInputElement>(null);

    const onImport = (image: string, mimeType: string) => {
        const newProjectImage = {
            image,
            mimeType,
        };
        dispatch(createProjectImage(projectId, newProjectImage, pageSize));
    };

    const selectImage = () => {
        if (imageImportRef.current) imageImportRef.current.click();
    };

    return (
        <Box sx={{ pr: '12px' }}>
            <Button
                onClick={selectImage}
                variant='text'
                sx={{ textTransform: 'none' }}
            >
                <AddIcon sx={{ paddingRight: '8px' }} /> Add
            </Button>
            <ImageImport imageImportRef={imageImportRef} callBack={onImport} />
        </Box>
    );
};

export default AddImageButton;
