import * as React from 'react';
import {
    Box,
    Popover,
    Stack,
    PopoverPosition,
    Typography,
    IconButton,
} from '@mui/material';
import { PriceGridType } from '../../../../lib/MeasurementOrders';
import Pricing from './Pricing';
import CloseIcon from '@mui/icons-material/Close';

interface PricingPopoverProps {
    open: boolean;
    anchorEl: HTMLElement | null;
    setAnchorEl: (anchorEl: HTMLElement | null) => void;
    priceGridType: PriceGridType;
}
const PricingPopover = (props: PricingPopoverProps) => {
    const { anchorEl, setAnchorEl, priceGridType, open } = props;
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getAnchorPosition = (): PopoverPosition | undefined => {
        if (!anchorEl) {
            return undefined;
        } else {
            return {
                top: anchorEl.offsetTop - 200,
                left: anchorEl.offsetLeft - 105,
            };
        }
    };
    const generateContent = () => {
        return (
            priceGridType && (
                <Box
                    sx={{
                        p: 2,
                        // width: '45em',
                        // height: '23em',
                        width: { xs: 320, sm: 600 },
                        fontFamily: 'Roboto',
                    }}
                >
                    <Stack>
                        <Stack sx={{ mb: 2 }} justifyContent={'space-between'}>
                            <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                            >
                                <Typography variant='h6'>
                                    {priceGridType === 'FullSnap'
                                        ? 'Detailed Report'
                                        : 'Metal Roof'}{' '}
                                    pricing details
                                </Typography>
                                <IconButton
                                    aria-label='Close'
                                    onClick={handleClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Stack>
                            <Typography sx={{ color: '#999A9A' }}>
                                Pricing is based on roof size and complexity.
                            </Typography>
                        </Stack>
                        <Pricing show={priceGridType} />
                    </Stack>
                </Box>
            )
        );
    };
    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorReference='anchorPosition'
            anchorPosition={getAnchorPosition()}
        >
            {generateContent()}
        </Popover>
    );
};
export default PricingPopover;
