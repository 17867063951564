import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';

const style = {
    spinner: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 10001,
    },
    loadingText: {
        lineHeight: '300px',
        zIndex: 10001,
        color: '#ffffff',
        textShadow: '0 0 3px #000000',
    },
    background: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 10000,
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    container: {
        position: 'relative',
        textAlign: 'center',
        width: 300,
        height: 300,
    },
};

const LoadingSpinner = props =>
    props.showSpinner && (
        <div style={style.background}>
            <div style={style.container} id="loading-spinner">
                <Typography id="spinnerText" variant="h6" style={style.loadingText}>{props.spinnerText}</Typography>
                <CircularProgress style={style.spinner} size={300} thickness={2} />
            </div>
        </div>
    );

LoadingSpinner.propTypes = {
    showSpinner: PropTypes.bool.isRequired,
    spinnerText: PropTypes.string,
};

LoadingSpinner.defaultProps = {
    spinnerText: '',
};

const mapState = (state) => {
    const found = find(state, 'isFetching');
    if (found) {
        return {
            showSpinner: found.isFetching,
            spinnerText: found.isFetchingText,
        };
    }
    return { showSpinner: false };
};

export default connect(mapState)(LoadingSpinner);
